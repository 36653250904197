import React from 'react';
import styled from 'styled-components';
import maintenanceImg from 'legacy/assets/maintenance.png';
import FormattedMessage from 'legacy/components/FormattedMessage';
import MerchantSiteLink from 'legacy/components/MerchantSiteLink';
import { getChannel } from 'legacy/helpers/payment';

const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex: 1;
`;

const ImageWrapper = styled('footer')`
  text-align: center;
  margin-bottom: 10px;
`;

const LinkWrapper = styled('div')`
  text-align: center;
  margin-top: 20px;
`;

const Maintenance: React.SFC<{ message?: string }> = (props) => {
  const { isEcommerceChannel } = getChannel();
  const { message } = props;

  return <ContentWrapper>
    <ImageWrapper>
      <img src={maintenanceImg} alt="maintenance image" width="100" height="100" />
    </ImageWrapper>
    <FormattedMessage messageId="common:maintenance-description" className="fs-23" />
    {
      isEcommerceChannel &&
      <LinkWrapper>
        <MerchantSiteLink />
      </LinkWrapper>
    }
  </ContentWrapper>;
};

export default Maintenance;
