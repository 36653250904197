import { autorun } from 'mobx';
import React, { useEffect, useMemo, useState } from 'react';
import ui from 'store/ui';

function styledObserver<T>(
  Component: React.ComponentType<T>,
  storesToObserve = [ui.styles]
): React.FC<T> {
  const StyledObserverComponent: React.FC<any> = props => {
    const [comp, setComp] = useState(<Component {...props} />);

    function render() {
      setComp(<Component {...props} />);
    }

    useEffect(() => {
      const disposer = autorun(() => {
        console.log('Changing!');
        JSON.stringify(storesToObserve);

        render();
      });

      return disposer;
    }, []);

    useEffect(() => {
      render();
    }, [props]);

    return comp;
  };

  return StyledObserverComponent;
}

export default styledObserver;
