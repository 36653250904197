import React, { useState, useEffect } from 'react';

import { AppComponentProps, AppProps } from 'types/apps';
import { ParentProps } from '../types';

import useSubmitHandler from 'apps/UXFlow/utils/hooks/useSubmitHandler';
import flow from 'apps/UXFlow/store/flow';
import ui from 'store/ui';
import DEView from './DE';
import DefaultView from './Default';
import paymentAPI from 'api/paymentAPI';
import { notify } from 'helpers/notify';
import useAsyncEffect from 'utils/hooks/useAsyncEffect';
import Loader from 'components/Loader';

import { DESIGN } from 'types/design';

const DEPayment: React.FC<AppComponentProps & ParentProps & AppProps> = (
  props: AppComponentProps & ParentProps & AppProps
) => {
  const { onSubmit, loading, onGetFlow } = useSubmitHandler(props);
  const [isPolling, setIsPolling] = useState<boolean>(true);

  const { errors, data } = flow;

  const delay = (ms: number): Promise<void> =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, ms);
    });

  const runPolling = async (): Promise<void> => {
    // no need to poll, status is clear
    if (flow.data.status === 'order_attached' || flow.data.status === 'authorized') {
      setIsPolling(false);
      return;
    }

    setIsPolling(true);

    try {
      for (let i = 0; i < 60; i++) {
        const result = await paymentAPI.getState();

        // ready to show contract page
        if (result.data.paymentStatus === 'order_attached') {
          await onGetFlow();
          setIsPolling(false);
          return;
        }

        // ready to show payment complete page
        if (result.data.paymentStatus === 'authorized') {
          await onGetFlow();
          setIsPolling(false);
          return;
        }

        // payment is failed
        if (result.data.statusCategory === 'failed') {
          await onGetFlow();
          setIsPolling(false);
          return;
        }

        await delay(1000);
      }
    } catch (err) {
      notify('error', undefined, 'Error happened.');
    }
  };

  useAsyncEffect(runPolling, []);

  const handleSubmit = async (data: any) => {
    // Analytics.sendEvent('Login OTP Sent by user');
    await onSubmit(data);
    await runPolling();
  };

  const PaymentComponents = {
    [DESIGN.DE]: DEView,
    [DESIGN.default]: DefaultView,
  };

  const Payment = PaymentComponents[ui.design];

  return isPolling ? (
    <Loader />
  ) : (
    <Payment
      goBack={props.goBack}
      backBtnEnabled={props.backBtnEnabled}
      onClose={props.onClose}
      data={data}
      errors={errors}
      loading={loading}
      handleSubmit={handleSubmit}
      setComponentOnDemand={props.setComponentOnDemand}
    />
  );
};

export default DEPayment;
