import React from 'react';
import styled from 'styled-components';
import spinnerImage from 'legacy/assets/card-loader.gif';

const LoaderWrapper = styled('div')`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: white;
  opacity: 0.5;
`;

const SpinnerImage = styled('img')`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Spinner: React.FC = () => {
  return (
    <>
      <LoaderWrapper>
        <SpinnerImage src={spinnerImage} width="50px" height="50px"></SpinnerImage>
      </LoaderWrapper>
    </>
  );
};

export default Spinner;
