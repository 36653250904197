import React, { useState } from 'react';
import Input from 'components/InputForm';
import styled from 'styled-components';

import flow from 'apps/UXFlow/store/flow';
import useSubmitHandler from 'apps/UXFlow/utils/hooks/useSubmitHandler';
import useEventListener from 'apps/UXFlow/utils/hooks/useEventListener';
import Text from 'components/Text';
import { AppComponentProps } from 'types/apps';

import { ParentProps } from '../../../types';
import Button from 'components/Button';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(Text)`
  margin-top: 30px;
`;

const Content = styled(Text)`
  margin: 15px 0;
`;

const DEEMailAddressForm: React.FC<AppComponentProps & ParentProps> = (
  props: AppComponentProps & ParentProps
) => {
  const { data, errors } = flow;
  const { onSubmit, loading } = useSubmitHandler(props);

  const [city, setCity] = useState<string>(data?.city || '');
  const [address, setAddress] = useState<string>(data?.address || '');
  const [zipcode, setZipcode] = useState<string>(data?.zipcode || '');

  const handleSubmit = (): void => {
    onSubmit({ city, address, zipcode });
  };

  useEventListener('keypress', handleSubmit);

  return (
    <Wrapper>
      <Title messageId="title" type="subtitle" />
      <Content type="content" />
      <Input
        value={city}
        changeHandler={setCity}
        placeholderMessageId="cityLabel"
        error={errors.city?.message}
      />
      <Input
        value={address}
        changeHandler={setAddress}
        placeholderMessageId="addressLabel"
        error={errors.address?.message}
      />
      <Input
        value={zipcode}
        changeHandler={setZipcode}
        placeholderMessageId="zipLabel"
        error={errors.zipcode?.message}
      />
      <Button messageId="continue" onClick={handleSubmit} isLoading={loading} />
    </Wrapper>
  );
};

export default DEEMailAddressForm;
