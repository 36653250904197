import React from 'react';
import styled from 'styled-components';

import useSubmitHandler from 'apps/UXFlow/utils/hooks/useSubmitHandler';
import { AppComponentProps } from 'types/apps';
import Loader from 'components/Loader';

import PaymentMethodButton from './PaymentMethodBtn';
import { PaymentMethod } from '../types';
import { ParentProps } from '../../types';

import VisaLogo from 'assets/logos/visa.svg';
import MasterCardLogo from 'assets/logos/mastercard.png';
import SEPALogo from 'assets/logos/sepa.svg';

const PaymentMethodSelection: React.FC<AppComponentProps & ParentProps> = (
  props: AppComponentProps & ParentProps
) => {
  const { onSubmit, loading } = useSubmitHandler(props);

  const mkHandleSubmit = (paymentMethod: PaymentMethod) => () =>
    onSubmit({ paymentMethod: paymentMethod });

  return (
    <Column>
      <PaymentMethodButton
        messageId="creditCard"
        onClick={mkHandleSubmit(PaymentMethod.PAYMENT_CARD)}
        isLoading={loading}
      >
        <VisaImg src={VisaLogo} />
        <MasterCardImg src={MasterCardLogo} />
      </PaymentMethodButton>

      <PaymentMethodButton
        messageId="sepaDebit"
        onClick={mkHandleSubmit(PaymentMethod.SEPA_DIRECT_DEBIT)}
        isLoading={loading}
      >
        <img src={SEPALogo} />
      </PaymentMethodButton>

      {loading && <Loader centered={false} />}
    </Column>
  );
};

const MasterCardImg = styled('img')`
  height: 1.25rem;
  margin: 0.125rem 0 0.125rem 0;
`;

const VisaImg = styled('img')`
  height: 1rem;
  margin: 0.25rem 0.25rem 0.25rem 0;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  margin-top: 46px;
`;

export default PaymentMethodSelection;
