import React from 'react';
import styled from 'styled-components';
import { BsX } from 'react-icons/bs';

import Text from 'components/Text';

import uploadIcon from 'assets/icon-upload-small.svg';
import bulletIcon from 'assets/icon-bullet.svg';

const InfoWrapper = styled('div')`
  position: fixed;
  top: 43%;
  left: 54%;
  z-index: 99;
  width: 37%;
  background-color: #fff;
  border: 1px solid #000;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  .hidden {
    display: none;
  }

  @media (max-width: 500px) {
    width: 70%;
    left: 50%;
    transform: translateX(-50%);
  }

  ul {
    list-style-image: url(${bulletIcon});
  }

  li {
    margin-bottom: 10px;
  }
`;

const CloseBtn = styled('div')`
  font-size: 15px;
  cursor: pointer;
  width: 17px;
  float: right;
`;

const TextWrapper = styled('div')`
  padding-top: 10px;
`;

interface ParentProps {
  toggleInfo: () => void;
}

const Info: React.FC<ParentProps> = ({ toggleInfo }: ParentProps) => (
  <InfoWrapper>
    <div>
      <CloseBtn onClick={toggleInfo}>
        <BsX />
      </CloseBtn>
      <TextWrapper>
        <ul>
          <li>
            <Text className="light inline" messageId="tooltipText11" />
            <img src={uploadIcon} alt="upload icon" width="20px" height="20px" />
            <Text className="light inline" messageId="tooltipText12" />
          </li>
          <li>
            <Text className="light" messageId="tooltipText2" />
          </li>
          <li>
            <Text className="light" messageId="tooltipText3" />
          </li>
          <li>
            <Text className="light" messageId="tooltipText4" />
          </li>
        </ul>
      </TextWrapper>
    </div>
  </InfoWrapper>
);

export default Info;
