import React from 'react';

import { SideBarStyled, SideBarImage } from './sidebar.styles';

import img1 from 'legacy/assets/sidebar-images/1.jpg';
import img2 from 'legacy/assets/sidebar-images/2.jpg';
import img3 from 'legacy/assets/sidebar-images/3.jpg';
import img4 from 'legacy/assets/sidebar-images/4.jpg';
import img5 from 'legacy/assets/sidebar-images/5.jpg';

const SideBar: React.FC = () => {
  const defaultSidebarImages = [img1, img2, img3, img4, img5];
  const defaultImg = defaultSidebarImages[Math.floor(defaultSidebarImages.length * Math.random())];

  return (
    <SideBarStyled>
      <SideBarImage src={defaultImg} alt="side-bar-img" />
    </SideBarStyled>
  );
};

export default SideBar;
