import { ValidatedUser } from 'legacy/types/ValidatedUser';

export interface UserValidationData {
  pending: boolean;
  error: boolean;
  user: ValidatedUser | null;
  isLoggedIn: boolean | null;
}

export const initialUserValidationData: UserValidationData = {
  pending: false,
  error: false,
  user: null,
  isLoggedIn: null,
};
