import React from 'react';

const ContactUs: React.FC = () => {
  return (
    <>
      <h1>Default view for contact us is not implemented yet.</h1>
    </>
  );
};

export default ContactUs;
