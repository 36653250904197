import { Dossier } from 'legacy/types/Dossier';
import { PaymentStatus } from 'legacy/types/PaymentStatus';

export interface TransactionDetailsState {
  transactionId: string | null;
  paymentStatus: PaymentStatus | null;
  dossier: Dossier | null;
  confirmationNumber: string | null;
}

export const initialTransactionDetailsState: TransactionDetailsState = {
  transactionId: null,
  paymentStatus: null,
  dossier: null,
  confirmationNumber: null
};
