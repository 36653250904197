import styled from "styled-components";

type TextStyledProps = {
    fontSize: string;
};

export const TextStyled = styled("span")<TextStyledProps>`
    text-align: center;
    display: block;
    font-size: ${props => props.fontSize};

    &.bold {
        font-weight: bold;
    }

    &.inline {
        display: inline;
    }

    &.thin {
        font-weight: 300;
    }

    &.light {
        color: gray;
    }

    &.link {
        cursor: pointer;
        text-decoration: underline;
    }

    &.pointer {
        cursor: pointer;
    }

    &.inputTitle {
        position: absolute;
        font-weight: 300;
        top: 20px;
        left: 15px;
        font-size: 12px;
    }

    &.button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 16px;
        font-weight: 500;
    }

    &.red {
        color: #d45050;
    }
`;
