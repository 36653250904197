import styled from 'styled-components';

const ButtonsGroup = styled('div')`
  display: flex;
  justify-content: center;

  button:first-child {
    margin-right: 25px;
  }

  @media (max-width: 650px) {
    flex-direction: column;

    button:first-child {
      margin: 0;
    }

    button:last-child {
      order: -1;
      margin-bottom: 40px;
    }
  }
`;

export default ButtonsGroup;
