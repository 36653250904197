import React, { useEffect } from 'react';
import styled from 'styled-components';

import Text from 'components/Text';
import { MappedUXFlowErrors } from 'types/data';
import PageContainer from 'apps/UXFlow/components/PageContainer/DE';
import { AppProps } from 'types/apps';
import Input from 'components/InputForm';
import { Analytics } from 'utils/Analytics';
import DangerousHTMLContainer from 'components/DangerousHTMLContainer';

const Instructions = styled.div`
  text-align: center;
  margin: 0 0 20px 0;
  width: 90%;
`;

const ResentOTPText = styled(Text)`
  margin-top: 60px;

  @media (min-width: 550px) {
    margin-top: 30px;
  }
`;

const CheckSPAMText = styled(DangerousHTMLContainer)`
  margin-top: 10px;
  font-size: 14px;
  text-align: center;

  em {
    font-style: normal;
  }
`;

const CheckSPAMResendDisabledText = styled(DangerousHTMLContainer)`
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;

  em {
    font-style: normal;
    font-weight: bold;
    color: #b22335;
  }
`;

interface Props {
  data: any;
  errors: MappedUXFlowErrors;
  loading: boolean;
  handleSubmit: () => void;
  OTP: number | string;
  setOTP: (otp: string | number) => void;
  handleResend: () => void;
  isResendDisabled: boolean;
}

const OTP_LENGTH = 6;

const DEEmailVerification: React.FC<Props & AppProps> = (props: Props & AppProps) => {
  const {
    data,
    errors,
    loading,
    handleSubmit,
    OTP,
    setOTP,
    handleResend,
    isResendDisabled,
  } = props;
  const otpLength = data.settings?.OTPLength || OTP_LENGTH;
  const SPAMTextComponent = isResendDisabled ? CheckSPAMResendDisabledText : CheckSPAMText;

  return (
    <PageContainer
      titleMessageId="DEEmailVerificationTitle"
      buttonMessageId="DEEmailVerificationSubmitBtn"
      buttonOnClick={handleSubmit}
      buttonIsLoading={loading}
      buttonDisabled={OTP.toString().length < otpLength}
      errorContent={errors.otp?.message}
      goBack={props.goBack}
      backBtnEnabled={props.backBtnEnabled}
      onClose={props.onClose}
      buttonStyles={{ bottom: '18.5%' }}
    >
      <Instructions>
        <Text messageId="DEEmailVerificationEmailInfo" type="content" />{' '}
        <Text content={data.email} type="subtitle" />
      </Instructions>

      <Instructions>
        <Text messageId="DEEmailVerificationInstruction" type="content" />
      </Instructions>

      <Input
        id="email_verification_otp"
        autoFocus
        value={OTP}
        changeHandler={setOTP}
        placeholderMessageId="OTPPlaceholder"
        maxLength={data.settings?.OTPLength || OTP_LENGTH}
      />

      <ResentOTPText
        messageId="DEEmailVerificationResendBtn"
        type="content"
        className={isResendDisabled ? 'underline gray' : 'underline pointer'}
        onClick={handleResend}
      />
      <SPAMTextComponent messageId="DEEmailVerificationSpamInfo" />
    </PageContainer>
  );
};

export default DEEmailVerification;
