import React from 'react';
import styled from 'styled-components';
import { redirect } from 'helpers/redirection';
import Text from 'components/Text';
import PageContainer from 'apps/ChangeCard/components/PageContainer/DE';
import flow from '../store/flow';
import sadbag from 'assets/sadbag.png';
import sad from 'assets/sad.png';

// Add icons to use from the BE here
const icons = {
  sadbag,
  sad,
};

const TextContainer = styled.div`
  margin-bottom: 76px;
  text-align: center;
`;

const Img = styled.img`
  margin: 30px 0 30px;
  width: 60px;
`;

const Message = styled(Text)`
  text-align: center;
`;

type ErrorPageType = {
  showIcon?: boolean;
};

const ErrorPage: React.FC<ErrorPageType> = ({ showIcon }) => {
  const error = flow.errors.ErrorPageException;

  return (
    <PageContainer
      titleMessageContent={error.data?.title}
      buttonMessageId="return"
      buttonOnClick={() => redirect(error.data?.fallbackURL)}
      buttonStyles={{ bottom: '18.5%' }}
    >
      {showIcon && <Img src={icons[error.data?.icon]} />}

      <TextContainer>
        {error.data?.messages.map((msg: string) => (
          <Message key={msg} content={`${msg} `} type="content" />
        ))}
      </TextContainer>
    </PageContainer>
  );
};

export default ErrorPage;
