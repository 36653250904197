import React from 'react';
import ui from 'store/ui';
import { DESIGN } from 'types/design';
import { AppComponentProps, AppProps } from 'types/apps';

import DEKYCForm from './DE';
import DefaultKYCForm from './Default';
import { ParentProps } from '../types';

const KYC: React.FC<AppComponentProps & ParentProps & AppProps> = (
  props: AppComponentProps & ParentProps & AppProps
) => {
  const { design } = ui;

  const components = {
    [DESIGN.DE]: DEKYCForm,
    [DESIGN.default]: DefaultKYCForm,
  };

  const KYCForm = components[design];

  return <KYCForm {...props} />;
};

export default KYC;
