import styled from 'styled-components';

export const EnvBannerWrapperStyle = styled('div')`
  width: 85px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: -3px;
  left: -3px;
  z-index: 1;
`;

export const EnvBannerStyle = styled('div')`
  font: bold 15px Sans-Serif;
  color: #fff;
  text-align: center;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  position: relative;
  padding: 7px 0;
  left: -29px;
  top: 15px;
  width: 120px;
  background-color: #FF6633;
  color: #fff;
`;