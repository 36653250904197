import { selectors } from "legacy/store/order";
import { State } from "legacy/store/state";
import { Flow } from "../types";

// Not fully implemented yet. We need to get this information from BE somehow.
const lean: Flow = (state: State) => {
    return (
        // selectors.orderDetailsSelector(state)?.tempForceLeanFlow ||
        // !!process.env.REACT_APP_ENABLE_LEAN_FLOW ||
        // !!(document as any).ENABLE_LEAN_FLOW ||
        // !!localStorage.getItem("ENABLE_LEAN_FLOW") || 
        selectors.orderDetailsSelector(state)?.paymentProgram === "compass_lean_flow"
    );
};

export default lean;