const validCSSProperties = [
  'all',
  'background',
  'background-attachment',
  'background-clip',
  'background-color',
  'background-image',
  'background-origin',
  'background-position',
  'background-repeat',
  'background-size',
  'border',
  'border-bottom',
  'border-bottom-color',
  'border-bottom-left-radius',
  'border-bottom-right-radius',
  'border-bottom-style',
  'border-bottom-width',
  'border-collapse',
  'border-color',
  'border-image',
  'border-image-outset',
  'border-image-repeat',
  'border-image-slice',
  'border-image-source',
  'border-image-width',
  'border-left',
  'border-left-color',
  'border-left-style',
  'border-left-width',
  'border-radius',
  'border-right',
  'border-right-color',
  'border-right-style',
  'border-right-width',
  'border-spacing',
  'border-style',
  'border-top',
  'border-top-color',
  'border-top-left-radius',
  'border-top-right-radius',
  'border-top-style',
  'border-top-width',
  'border-width',
  'bottom',
  'box-shadow',
  'caption-side',
  'clear',
  'clip',
  'color',
  'content',
  'counter-increment',
  'counter-reset',
  'cursor',
  'direction',
  'display',
  'empty-cells',
  'float',
  'font',
  'font-family',
  'font-size',
  'font-size-adjust',
  'font-stretch',
  'font-style',
  'font-synthesis',
  'font-variant',
  'font-weight',
  'height',
  'left',
  'letter-spacing',
  'line-height',
  'list-style',
  'list-style-image',
  'list-style-position',
  'list-style-type',
  'margin',
  'margin-bottom',
  'margin-left',
  'margin-right',
  'margin-top',
  'max-height',
  'max-width',
  'min-height',
  'min-width',
  'opacity',
  'orphans',
  'outline',
  'outline-color',
  'outline-style',
  'outline-width',
  'overflow',
  'padding',
  'padding-bottom',
  'padding-left',
  'padding-right',
  'padding-top',
  'page-break-after',
  'page-break-before',
  'page-break-inside',
  'position',
  'quotes',
  'right',
  'table-layout',
  'text-align',
  'text-decoration',
  'text-decoration-color',
  'text-decoration-line',
  'text-decoration-style',
  'text-indent',
  'text-transform',
  'top',
  'transform',
  'transform-origin',
  'transition',
  'transition-delay',
  'transition-duration',
  'transition-property',
  'transition-timing-function',
  'unicode-bidi',
  'vertical-align',
  'visibility',
  'white-space',
  'widows',
  'width',
  'word-spacing',
  'z-index',
  'font',
  'font-family',
  'font-size',
  'font-weight',
  'font-style',
  'font-variant',
  'font-stretch',
  'font-size-adjust',
  'font-synthesis',
  'line-height',
  'letter-spacing',
  'word-spacing',
  'text-align',
  'text-decoration',
  'text-decoration-line',
  'text-decoration-style',
  'text-decoration-color',
  'text-indent',
  'text-transform',
  'vertical-align',
  'white-space',
  'color',
  'opacity',
  'background-color',
  'background',
  'background-image',
  'background-repeat',
  'background-position',
  'background-attachment',
  'background-clip',
  'background-origin',
  'background-size',
  'border-radius',
  'border-top-left-radius',
  'border-top-right-radius',
  'border-bottom-right-radius',
  'border-bottom-left-radius',
  'border-image',
  'border-image-source',
  'border-image-slice',
  'border-image-width',
  'border-image-outset',
  'border-image-repeat',
  'box-shadow',
  'margin',
  'margin-top',
  'margin-right',
  'margin-bottom',
  'margin-left',
  'padding',
  'padding-top',
  'padding-right',
  'padding-bottom',
  'padding-left',
  'border',
  'border-top',
  'border-right',
  'border-bottom',
  'border-left',
  'border-width',
  'border-top-width',
  'border-right-width',
  'border-bottom-width',
  'border-left-width',
  'border-style',
  'border-top-style',
  'border-right-style',
  'border-bottom-style',
  'border-left-style',
  'border-color',
  'border-top-color',
  'border-right-color',
  'border-bottom-color',
  'border-left-color',
  'width',
  'height',
  'min-width',
  'max-width',
  'min-height',
  'max-height',
  'position',
  'top',
  'right',
  'bottom',
  'left',
  'clip',
  'overflow',
  'z-index',
  'float',
  'clear',
  'display',
  'visibility',
  'content',
  'quotes',
  'counter-increment',
  'counter-reset',
  'list-style',
  'list-style-type',
  'list-style-image',
  'list-style-position',
  'table-layout',
  'border-collapse',
  'border-spacing',
  'empty-cells',
  'caption-side',
  'page-break-before',
  'page-break-after',
  'page-break-inside',
  'orphans',
  'widows',
  'transition',
  'transition-property',
  'transition-duration',
  'transition-timing-function',
  'transition-delay',
  'transform',
  'transform-origin',
  'outline',
  'outline-style',
  'outline-color',
  'outline-width',
  'cursor',
  'direction',
  'unicode-bidi',
  'all',
];

export function filterValidCSSProperties(props: any): any {
  return Object.fromEntries(
    Object.entries(props).filter(([key]) => validCSSProperties.includes(key.toLowerCase()))
  );
}

export function forceMobileFeatures() {
  return (
    (document as any).FORCE_MOBILE_FEATURES === 'true' ||
    process.env.REACT_APP_FORCE_MOBILE_FEATURES === 'true'
  );
}

export function mobileCheck() {
  return forceMobileFeatures() || typeof window.orientation !== 'undefined';
}

function getMobileOperatingSystem(): 'Windows Phone' | 'Android' | 'iOS' | 'Other' {
  const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return 'iOS';
  }

  return 'Other';
}

export function isMobileOperatingSystem(os: string): boolean {
  return (
    forceMobileFeatures() ||
    (mobileCheck() && getMobileOperatingSystem().toLowerCase() === os.toLowerCase())
  );
}

export function isOpenedInIframe(): boolean {
  if (window.location !== window.parent.location) {
    return true;
  }
  return false;
}

export function setWebsiteTab(url: string, title: string): void {
  // Set favicon.
  const existedElement = document.querySelector("link[rel*='icon']");
  existedElement?.remove();

  const element = document.createElement('link') as HTMLLinkElement;

  element.rel = 'icon';
  element.href = `${url}?${Date.now()}`; // date is a cache killer
  document.getElementsByTagName('head')[0].appendChild(element);

  // Set title.
  document.title = title;
}

export const scrollTop = (): void => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
};
