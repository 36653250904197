import { UXFlow, UXFlowErrors } from '../../types/UXFlow';

export interface UXFlowState {
  pending: boolean;
  flow: UXFlow;
  flowErrors: UXFlowErrors;
}

export const initialState: UXFlowState = {
  pending: false,
  flow: { next: '', data: {}, nextStepIndex: 0, totalSteps: 0, offset: 0, refresh: true },
  flowErrors: { invalidFields: {}, flowError: '' },
};
