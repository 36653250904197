import { take, put, call, select } from 'redux-saga/effects';
import { PayloadAction } from 'redux-starter-kit';
import { checkLastDigits } from 'legacy/api';
import { RecoverPinStatus } from 'legacy/types/RecoverPinStatus';
import { actions, selectors } from 'legacy/store/login';
import { notify } from 'legacy/helpers/notify';
import { history } from 'legacy/routing/history';

export function* cardNumberValidationSaga() {
  while (true) {
    const { payload }: PayloadAction<number> = yield take(actions.checkCard);
    const lastDigits = payload.toString();

    try {
      const { mobile } = yield select(selectors.userSelector);
      const { result } = yield call(checkLastDigits, mobile, lastDigits);
      if (result === false) {
        throw new Error('Failed card validation');
      }

      yield put(actions.successWhileRecoverPinCode());
      yield put(actions.setStatus(RecoverPinStatus.setPin));
    } catch (err) {
      yield put(actions.errorResetPinCode());

      if (err.statusCode === 400) {
        notify('error', 'expired-token-for-recover-pin');
        history.goBack();
      }
    }
  }
}
