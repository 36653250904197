const KEY = 'offset';

export const getOffset = (): number => {
  const currentOffset = sessionStorage.getItem(KEY);
  return currentOffset ? parseInt(currentOffset) : 0;
};

export const setOffset = (offset: number): void => {
  sessionStorage.setItem(KEY, offset.toString());
};
