import { take, call, put } from 'redux-saga/effects';
import { TRANSACTION_ID_KEY } from 'legacy/constants/storageKeys';
import { errorsWithToasts } from 'legacy/constants/errorsWithToasts';
import { LocalStorageManager } from 'legacy/utils/LocalStorageManager';
import { getTransactionStatus } from 'legacy/api';
import { actions } from 'legacy/store/transaction';
import { createTransactionSaga } from 'legacy/store/transaction/sagas/createTransactionSaga';
import { notify } from 'legacy/helpers/notify';
import { tryToGetScoringResult } from './createTransactionSaga';
import { ScoringStatus } from 'legacy/types/ScoringStatus';
import { PaymentStatus, PaymentStatusPorting } from 'legacy/types/PaymentStatus';

export function* wizardSaga(): any {
  while (true) {
    yield take(actions.requestTransactionStatus);
    try {
      const transactionId = yield call(LocalStorageManager.getItem, TRANSACTION_ID_KEY);
      yield put(actions.setTransactionId(transactionId));

      if (transactionId) {
        const resp = yield call(getTransactionStatus, transactionId);
        
        const { paymentStatus, confirmationNumber } = resp;
        yield put(actions.setPaymentStatus(paymentStatus));
        yield put(actions.setConfirmationNumber(confirmationNumber));

        if (
          paymentStatus === PaymentStatus.na ||
          paymentStatus === PaymentStatus.pending ||
          paymentStatus === PaymentStatusPorting.new
        ) {
          const scoring: ScoringStatus = yield call(tryToGetScoringResult, transactionId);

          if (scoring !== ScoringStatus.Success) {
            yield put(actions.setPaymentStatus(PaymentStatus.failed));
          }
        }

      } else {
        yield call(createTransactionSaga);
      }
    } catch (err) {
      if (!errorsWithToasts.find(errorItem => err.message === errorItem.message)) {
        notify('error', 'something-went-wrong');
      }
    }
  }
}
