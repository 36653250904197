import { call, put, select, take } from 'redux-saga/effects';
import { acceptDossier, sendPagoOtp } from 'legacy/api';
import { actions } from 'legacy/store/transaction';
import { TRANSACTION_ID_KEY } from 'legacy/constants/storageKeys';
import { errorsWithToasts } from 'legacy/constants/errorsWithToasts'
import { LocalStorageManager } from 'legacy/utils/LocalStorageManager';
import { notify } from 'legacy/helpers/notify';
import { PaymentStatus } from 'legacy/types/PaymentStatus';

export function* acceptDossierSaga(): any {
  while (true) {
    yield take(actions.requestToAcceptDossier);

    try {
      const transactionId = yield call(LocalStorageManager.getItem, TRANSACTION_ID_KEY);
      const state = yield select();

      yield call(acceptDossier, transactionId, state.transaction.dossierApprovalStatus.terms, state.transaction.dossierApprovalStatus.version);
      yield call(sendPagoOtp, transactionId);

      yield put(actions.successAcceptDossier());

      yield put(actions.setPaymentStatus(PaymentStatus.pendingOtpApproval));
    } catch (err) {
      yield put(actions.errorAcceptDossier());

      if (!errorsWithToasts.find(errorItem => err.message === errorItem.message)) {
        notify('error', 'something-went-wrong');
      }
    }
  }
}
