import React from 'react';
import { ParentProps } from 'legacy/SupportPortal/types';

import FormattedMessage from 'legacy/components/FormattedMessage';
import Button from 'legacy/components/Button';

const Success: React.FC<ParentProps> = (props: ParentProps) => {
  const { submitHandler, mobile, loading } = props;

  return (
    <>
      <FormattedMessage
        messageId="change-card:support-portal-success-title"
        className="fs-23 mb-40"
      />
      <FormattedMessage
        messageId="change-card:support-portal-success-info"
        className="fs-16 mb-40"
      />

      <Button
        messageId="change-card:support-portal-success-button"
        clickHandler={() => submitHandler(mobile)}
        isLoading={loading}
      />

    </>
  );
};

export default Success;
