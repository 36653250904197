import Apps from 'apps';
import config from 'config';
import React, { useEffect } from 'react';
import { Amplitude } from 'services/AmplitudeService';
import Auth from 'auth';
import { GlobalStyles, RootContainer } from 'styles';
import { ToastContainer, toast } from 'react-toastify';
import { Analytics } from 'utils/Analytics';

const TOAST_AUTOCLOSE_TIME = 15000;

const Root: React.FC = () => {
  useEffect(() => {
    Amplitude.initialize();
    Auth.initialize();
    Analytics.initialize();
  }, []);

  return (
    <>
      <GlobalStyles />
      <RootContainer>
        <ToastContainer
          position={toast.POSITION.TOP_RIGHT}
          autoClose={TOAST_AUTOCLOSE_TIME}
          className="toaster"
          newestOnTop={false}
          closeOnClick
          hideProgressBar
        />
        <Apps config={config} />
      </RootContainer>
    </>
  );
};

export default Root;
