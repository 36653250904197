import React from 'react';

type MyProps = {};
type MyState = { error: any; errorInfo: any };
class ErrorBoundary extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <h2>I am crashed!</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error}
            <br />
            {this.state.errorInfo}
          </details>
        </div>
      );
    }

    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
