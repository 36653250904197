import React from 'react';
import styled from 'styled-components';
import { FormattedInstallment } from 'legacy/ChangeCardPortal/types';

const TimelineWrapper = styled('section')`
  width: 100%;

  .timeline {
    padding: 0px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    background: -webkit-gradient(
      left top,
      left bottom,
      color-stop(0%, rgba(255, 255, 255, 0)),
      color-stop(45%, rgba(255, 255, 255, 0)),
      color-stop(51%, rgba(191, 128, 11, 1)),
      color-stop(57%, rgba(255, 255, 255, 0)),
      color-stop(100%, rgba(255, 255, 255, 0))
    );
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 25%,
      rgba(175 175 175) 65%,
      rgba(255, 255, 255, 0) 57%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .timeline li {
    display: inline-block;
    width: 15px;
    height: 15px;
    background: #000000;
    text-align: center;
    line-height: 1.2;
    position: relative;
  }

  .timeline li:before {
    content: attr(data-amount);
    position: absolute;
    // font-weight: bold;
    font-size: 20px;
    top: -25px;
    left: 50%;
    transform: translate(-50%, -25px);
  }

  .timeline li:after {
    content: attr(data-date);
    position: absolute;
    color: #8683a4;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -25px);
  }

  .timeline li.red:before,
  .timeline li.red:after {
    color: #d45050;
  }
`;

const ContentWrapper = styled('div')`
  width: 60%;
`;

interface ParentProps {
  installments: FormattedInstallment[];
}

const Timeline: React.FC<ParentProps> = (props: ParentProps) => {
  const { installments } = props;

  return (
    <ContentWrapper>
      <TimelineWrapper>
        <ul className="timeline">
          {installments.map((item, i) => (
            <li
              data-amount={item.amount}
              data-date={item.rata}
              key={i}
              className={item.next ? 'red' : ''}
            ></li>
          ))}
        </ul>
      </TimelineWrapper>
    </ContentWrapper>
  );
};

export default Timeline;
