import React, { useState, useRef, useEffect } from 'react';

import { AppComponentProps, AppProps } from 'types/apps';
import { ParentProps } from '../types';

import useSubmitHandler from 'apps/UXFlow/utils/hooks/useSubmitHandler';
import Text from 'components/Text';
import styled from 'styled-components';
import Selector from './Selector';
import MockSelector from './MockSelector';
import Refresh from 'assets/refresh.svg';
import addressAPI from 'api/address';
import useAsyncEffect from 'utils/hooks/useAsyncEffect';
import flow from 'apps/UXFlow/store/flow';
import ui from 'store/ui';
import PageContainer from 'apps/UXFlow/components/PageContainer/DE';
import { Option } from 'types/address';
import { Analytics } from 'utils/Analytics';

const ResetContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
  max-width: 439px;
  width: 100%;
`;

const ResetText = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  margin-left: 5px;
`;

const ResetIcon = styled('img')`
  height: 13px;
`;

const SubtitleText = styled(Text)`
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 25px;
`;

const Spacer = styled('div')`
  height: 15px;
`;

interface Suggestion {
  context: string;
  label: string;
  description: string;
  value: string;
  preciseEnough: boolean;
}

const MIN_LENGTH_TO_SEARCH = 2;
const COUNTRY = 'germany';

const DEAddress: React.FC<AppComponentProps & ParentProps & AppProps> = (
  props: AppComponentProps & ParentProps & AppProps
) => {
  const { onSubmit, loading } = useSubmitHandler(props);

  const { data } = flow;
  const city = data.city || '';
  const address = data.address || '';
  const zipcode = data.zipcode || '';

  const [text, setText] = useState<string>(``);
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [context, setContext] = useState<string>('');
  const [preciseEnough, setPreciseEnough] = useState<boolean>(false);
  const [lastOptionChosen, setLastOptionChosen] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>();
  const [internalLoading, setLoading] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const options = suggestions.map(suggestion => {
    return {
      ...suggestion,
      text: `${suggestion.label} ${suggestion.description ? `(${suggestion.description})` : ''}`,
    };
  });

  async function getAddressSuggestions(altText?: string) {
    if (data.disableDropdown) {
      return [];
    }

    const response = await addressAPI.getAddress({
      query: altText || text,
      country: COUNTRY,
      context: context || 'deu',
    });

    return response?.data?.suggestions;
  }

  const isAlphanumericString = (str: string): boolean => {
    return !!str.match('^[a-zA-Z\\u00C0-\\u00FF0-9 ]*$');
  }

  const changePhoneNumber = (value: string): void => {
    if (isAlphanumericString(value)) {
      setText(value);
    }
  };

  useEffect(() => {
    setErrorMsg(undefined);
  }, [preciseEnough]);

  useEffect(() => {
    Analytics.sendEvent('Onboarding - Address Page- Visited');
  })

  useAsyncEffect(async () => {
    const addressFromBE = `${city}${address ? ' ' + address : ''}${zipcode ? ' ' + zipcode : ''}`;

    if (addressFromBE) {
      setLoading(true);
      const suggestions: Suggestion[] = await getAddressSuggestions(addressFromBE);
      setLoading(false);

      if (suggestions.length === 1) {
        setText(suggestions[0].value);
        setContext(suggestions[0].context);
      }

      if (suggestions.length > 1) {
        setText(addressFromBE);
        setSuggestions(suggestions);
      }
    }
  }, []);

  function onChooseOption(option: Option) {
    setSuggestions([]);
    setText(option.value);
    setLastOptionChosen(option.value);
    setContext(option.context);
    setPreciseEnough(option.preciseEnough);
    preciseEnough || setLoading(true);
  }

  useAsyncEffect(async (): Promise<void> => {
    if (lastOptionChosen !== text) {
      setPreciseEnough(false);
    }

    if (text.length < MIN_LENGTH_TO_SEARCH) {
      setSuggestions([]);
      return;
    }

    const suggestions: Suggestion[] = await getAddressSuggestions();
    setLoading(false);

    if (suggestions.length === 1 && suggestions[0].value === text) {
      onChooseOption({
        ...suggestions[0],
        text: '',
      });
      return;
    }

    setSuggestions(suggestions);
  }, [text, context]);

  const reset = (): void => setText('');
  const submit = (): void => {
    Analytics.sendEvent('Onboarding - Address Page - Clicked Confirmed');
    if (data.disableAddressValidationAndSplit) {
      onSubmit({ context: context || "context" });
    }
    else {
      onSubmit({ context: context });
    }
  };

  function onClickButtonDisabled() {
    if (data.disableAddressValidationAndSplit) {
      onSubmit({ context: context || "context" });
      return;
    }

    if (!text || lastOptionChosen !== text) {
      setErrorMsg(ui.texts.DEAddressChooseFromDropdownError);
    } else {
      setErrorMsg(ui.texts.DEAddressMissedHouseNumberError);
    }
  }

  return (
    <PageContainer
      titleMessageId="DEAddressTitle"
      buttonMessageId="next"
      buttonOnClick={submit}
      buttonDisabledOnClick={onClickButtonDisabled}
      buttonIsLoading={(loading || internalLoading) && !data.disableAddressValidationAndSplit}
      buttonDisabled={(!context || !preciseEnough) && !data.disableAddressValidationAndSplit}
      goBack={props.goBack}
      backBtnEnabled={props.backBtnEnabled}
      onClose={props.onClose}
      errorContent={errorMsg}
      buttonStyles={{ bottom: '18.5%' }}
    >
      <SubtitleText messageId="DEAddressSubtitle" type="subtitle" />

      <MockSelector placeholderMessageId="DEAddressCountryLabel" />

      <Spacer />

      <Selector
        alwaysShowCheck={!!data.disableAddressValidationAndSplit}
        preciseEnough={preciseEnough}
        value={text}
        onChange={changePhoneNumber}
        onChooseOption={onChooseOption}
        options={options}
        isSelected={!!context}
        placeholderMessageId="DEAddressSelectorPlaceholder"
        ref={inputRef}
      />

      <ResetContainer onClick={reset}>
        <ResetIcon src={Refresh} />
        <ResetText messageId="DEAddressReset" className="underline" />
      </ResetContainer>
    </PageContainer>
  );
};

export default DEAddress;
