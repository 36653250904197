import React, { useState } from 'react';
import Capture from './Capture';
import Confirm from './Confirm';

type CameraType = {
  onUpload: (file: File) => void;
  setIsCameraUsed: (isCameraUsed: boolean) => void;
};

const Camera: React.FC<CameraType> = ({ onUpload, setIsCameraUsed }: CameraType) => {
  const [imageData, setImageData] = useState<string | undefined>(undefined);

  return imageData ? (
    <Confirm imageData={imageData} onUpload={onUpload} retake={() => setImageData(undefined)} />
  ) : (
    <Capture setImageData={setImageData} onUpload={onUpload} setIsCameraUsed={setIsCameraUsed} />
  );
};

export default Camera;
