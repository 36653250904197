import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { State } from 'legacy/store/state';
import { actions as UIActions } from 'legacy/store/UI';
import { Installment, LoanInfo, FormattedInstallment } from 'legacy/ChangeCardPortal/types';
import Loader from 'legacy/components/Loader';

import PaymentPlanReminder from './components/paymentPlanReminder';
import Card from 'legacy/components/Popup/CardDetailsPopup';
import Success from './components/Success';
import Popup from 'legacy/components/Popup';
import { getChannel } from 'legacy/helpers/payment';
import { Currency } from 'legacy/types/Currency';

import { selectors as ConsumerSelectors, actions } from 'legacy/store/consumer';

const mapStateToProps = (state: State) => ({
  onBoardingStatus: ConsumerSelectors.onBoardingStatusSelector(state),
});

const mapDispatchProps = {
  openPopup: UIActions.openPopup,
  requestFlow: actions.requestFlow,
};

const connector = connect(mapStateToProps, mapDispatchProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ChangeCard: React.SFC<PropsFromRedux> = (props: PropsFromRedux) => {
  // const { activePayments } = queryString.parse(window.location.search);
  const { openPopup, requestFlow, onBoardingStatus } = props;

  // some additional options, related to change card portal
  const options = {
    paymentPlan: { compliance: true },
    card: {},
    dossier: {},
    success: {},
    failure: {},
  };

  const { channel } = getChannel();
  const query = {
    type: 'change_card',
    client: 'npg',
    source: 'web',
    channel,
    offset: 0,
  };

  useEffect(() => {
    const data = {
      profile: {},
      query,
    };
    if (onBoardingStatus.flow.refresh) {
      requestFlow(data);
    }
  }, [onBoardingStatus.flow.refresh]);

  const stepId = onBoardingStatus.flow.next;
  const flowErrors = onBoardingStatus.flowErrors;
  const flowData = onBoardingStatus.flow.data;
  // const isCompleted = onBoardingStatus.flow.complete;

  const [isCompleted, setCompleted] = useState(false);

  // const last4Digits = onBoardingStatus.flow.last4Digits;
  const last4Digits = isCompleted ? flowData.last4Digits : undefined;
  let activeLoans = [];
  const activeLoanNextDates: number[] = [];
  let notificationDate = '';

  const amountTemplates = {
    EUR: `${Currency.eur}%amount%`,
    USD: `%amount%${Currency.usd}`,
  };

  if (flowData && flowData.loans && Array.isArray(flowData.loans) && flowData.loans.length) {
    activeLoans = flowData.loans.map((loan: LoanInfo) => {
      const item = {
        merchantLogo: loan.merchant.merchantLogoURL,
        merchantName: loan.merchant.merchantName,
        createdAt: moment(loan.details.createdAt).format('DD/MM'),
        orderId: loan.order.orderId,
        installments: [
          {
            amount: amountTemplates[loan.details.currency].replace(
              '%amount%',
              (loan.details.downPayment.amount / 100).toString()
            ),
            date: loan.details.downPayment.approvedAt,
            rata: `Rata${1}`,
            next: false,
          },
        ],
        nextInstallmentId: '',
      };

      const nextInstallments = loan.details.installments.filter(
        (i: Installment) => new Date(i.dueDate).getTime() >= Date.now()
      );
      if (nextInstallments.length) {
        item.nextInstallmentId = nextInstallments.length ? nextInstallments[0].id : '';
        nextInstallments.map(i => {
          activeLoanNextDates.push(new Date(i.dueDate).getTime());
        });
      }

      (item.installments as FormattedInstallment[]) = (item.installments as FormattedInstallment[]).concat(
        loan.details.installments.map(
          (i: Installment, index: number): FormattedInstallment => {
            return {
              amount: amountTemplates[i.currency].replace('%amount%', (i.amount / 100).toString()),
              date: i.dueDate,
              rata: `Rata${index + 2}`,
              next: i.id === item.nextInstallmentId,
            };
          }
        )
      );

      return item;
    });
  }

  notificationDate = moment(Math.min(...activeLoanNextDates)).format('DD/MM/YYYY');

  const submitHandler = (stepId: string, card?: any): void => {
    // setStepId(stepId);

    // if (card) {
    //   setCard({
    //     last4Digits: card.CARDNUMBER?.slice(-4),
    //     expirationDate: `${card.MESE}/${card.ANNO}`,
    //   });
    // }

    // if (stepId === 'card' || stepId === 'dossier') {
    //   openPopup('CUSTOM_COMPONENT');
    // }
    // openPopup('CUSTOM_COMPONENT');
  };

  const openCardPopup = (): void => {
    openPopup('CUSTOM_COMPONENT');
  };

  return (
    <>
      {onBoardingStatus.pending && !last4Digits ? (
        <>
          <Loader />
        </>
      ) : null}

      {/* Flow, when no active payments */}
      {!onBoardingStatus.pending &&
        stepId === 'ECP_ChangeCard_Reminder' &&
        flowData &&
        (!flowData.loans || !flowData.loans.length) &&
        !last4Digits ? (
        <>
          <Card onSubmit={() => setCompleted(true)} />
        </>
      ) : null}

      {/* Flow, when active payments exists */}
      {!onBoardingStatus.pending &&
        stepId === 'ECP_ChangeCard_Reminder' &&
        flowData &&
        flowData.loans &&
        flowData.loans.length &&
        !last4Digits ? (
        <>
          <PaymentPlanReminder
            activeLoans={activeLoans}
            notificationDate={notificationDate}
            submitHandler={openCardPopup}
          />
          <Popup>
            <Card onSubmit={() => setCompleted(true)} />
          </Popup>
        </>
      ) : null}
      {/* TODO: refactor popup/cardDetails component and reuse it here */}
      {/* {stepId === 'card' && (
        <>
          <PaymentPlanReminder options={options} submitHandler={submitHandler} />
          <Popup>
            <Card options={options} submitHandler={submitHandler} />
          </Popup>
        </>
      )} */}
      {/* {stepId === 'dossier' && (
        <>
          <PaymentPlanReminder options={{ ...options, card }} submitHandler={submitHandler} />
          <Popup maxWidth="800px">
            <Dossier options={{ ...options, card }} submitHandler={submitHandler} />
          </Popup>
        </>
      )} */}
      {last4Digits || isCompleted ? (
        <Success options={{ card: { last4Digits } }} submitHandler={submitHandler} />
      ) : null}
      {/* {stepId === 'failure' && (
        <Failure options={{ ...options, card }} submitHandler={submitHandler} />
      )} */}
    </>
  );
};

export default connector(ChangeCard);
