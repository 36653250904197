import React from 'react';
import styled from 'styled-components';
import Text from 'components/Text';
import Vector from 'assets/vector.svg';

const Wrapper = styled.div`
  width: 100%;
  height: 35px;
  min-height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 20px;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const OptionText = styled(Text)`
  font-weight: 450;
  font-size: 16px;
  color: #8d8d8d;
`;

const VectorIcon = styled('img')`
  height: 14px;
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  text: string;
}

const Option: React.FC<Props> = (props: Props) => {
  const { text, onMouseDown } = props;
  return (
    <Wrapper onMouseDown={onMouseDown}>
      <OptionText content={text} />
      <VectorIcon src={Vector} />
    </Wrapper>
  );
};

export default Option;
