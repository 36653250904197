import { call, put, take, select, delay } from 'redux-saga/effects';
import { PayloadAction } from 'redux-starter-kit';
import { getCards } from 'legacy/api';
import { actions } from 'legacy/store/consumer';
import { CardFlowData } from 'legacy/types/Consumer';
import { selectors as consumerSelectors } from 'legacy/store/consumer';
import { CARD_STATUS_COMPLETED } from 'legacy/constants/index';
import { Analytics } from 'legacy/utils/Analytics';
import { LocalStorageManager } from 'legacy/utils/LocalStorageManager';

export function* commitCardFlowSaga(): any {
  while (true) {
    const { payload: { data, query, onSubmit } }: PayloadAction<CardFlowData> = yield take(
      actions.commitCardFlow
    );

    yield put(actions.setOnboardingPending(true));

    const onBoardingStatus = yield select(consumerSelectors.onBoardingStatusSelector);
    let result;

    // no need to start polling in case ECP redirected us to "failed" or "canceled" callback URLs
    // refresh onboarding component in order to call getFlow API and receive new card URL
    if (data && data.error === 'ecp-error-callback') {
      yield put(actions.setFlow({ ...onBoardingStatus.flow, refresh: true }));
    } else {
      try {
        result = yield runPolling(data.timeout || 60, data.cardsAmount);

        // 60 sec is over, no new cards from polling
        if (!result.done) {
          // We're taking care of it from the component itself now
          // notify('error', `onboarding:card-completion-failed`);
        }
        else {
          onSubmit && onSubmit();
        }
      } catch (err) {
        // ignore error, anyway we will call getFlow API for confirmation of current state
      }

      // Backend doesn't return complete: true for ChangeCard flow once card is added,
      // so this logic on NPG right now.
      // Remove it once backend is ready.
      let lastDigits;

      if (result && result.completedCard && result.completedCard.lastDigits) {
        lastDigits = result.completedCard.lastDigits;
        LocalStorageManager.setItem('lastDigits', lastDigits);
      }

      // refresh: true will make parent onboarding component to call getFlow API after polling is finished
      yield put(
        actions.setFlow({
          ...onBoardingStatus.flow,
          refresh: true,
          last4Digits: lastDigits,
        })
      );
    }
  }
}

const runPolling = function* (timeout: number, cardsAmount?: number): any {
  try {
    for (let i = 0; i < timeout; i++) {
      const data = yield call(getCards);

      // Don't change to !!cardsAmount, it can be 0
      if (cardsAmount === undefined) {
        const completedCard = data.cards.find((card: { status: string; }) =>
          card.status === CARD_STATUS_COMPLETED);
        if (completedCard) {
          Analytics.sendEvent("Onboarding - ECP Card onboarding - Successed", {
            cardType: completedCard.cardDetails?.type,
          });
          return yield { done: true, completedCard }
        }
      }
      else {
        if (data.cards.length > cardsAmount) {
          return yield { done: true, completedCard: data.cards[data.cards.length-1] }
        }

        if (data.cards.length < cardsAmount) {
          return yield { done: false }
        }
      }

      yield delay(1000);
    }
    // for 60 sec no result, assume backend error
    return yield { done: false }
  } catch (err) {
    return yield { done: false }
  }
}
