import { Order } from "legacy/DFE/types/order";

export interface OrderState {
  orderId: string;
  order?: Order;
}

export const initialState: OrderState = {
  orderId: "",
};
