import React, { useState, useEffect } from 'react';
import { uuid } from 'uuidv4';
// import { Analytics } from 'legacy/utils/Analytics';
import useEventListener from 'apps/UXFlow/utils/hooks/useEventListener';
import { AppComponentProps, AppProps } from 'types/apps';
import { ParentProps } from '../types';

import useSubmitHandler from 'apps/UXFlow/utils/hooks/useSubmitHandler';
import flow from 'apps/UXFlow/store/flow';
import ui from 'store/ui';
import DEOTPView from './OTP/DE';
import DefaultOTPView from './OTP/Default';

import { DESIGN } from 'types/design';
import { Amplitude } from 'services/AmplitudeService';
import { Analytics } from 'utils/Analytics';

const DE_OTP_LENGTH = 6;
const DEFAULT_OTP_LENGTH = 5;
const ALL_OTP_LENGTH = {
  [DESIGN.DE]: DE_OTP_LENGTH,
  [DESIGN.default]: DEFAULT_OTP_LENGTH,
};

const AuthOTPOTP: React.FC<AppComponentProps & ParentProps & AppProps> = (
  props: AppComponentProps & ParentProps & AppProps
) => {
  const { onSubmit, loading } = useSubmitHandler(props);

  const { errors, data } = flow;

  const [otp, setOtp] = useState<number | string>('');
  const [shouldAutofocus, setShouldAutofocus] = useState<boolean>(false);
  const [inputId, setInputId] = useState<string>(uuid());
  const [otpResent, setOtpResent] = useState<boolean>(false);
  const [resendDisabled, setResendDisabled] = useState<boolean>(false);

  const OTP_LENGTH = ALL_OTP_LENGTH[ui.design];

  useEffect(() => {
    startTimer();
    if (data.consumerId) {
      Analytics.identifyUser(data.consumerId)
    }
    Analytics.sendEvent('Login OTP page- visited');
  }, []);

  useEffect(() => {
    if (!loading) setOtpResent(false);
  }, [loading]);

  useEffect(() => {
    if (errors['otp']) {
      setShouldAutofocus(true);
      setInputId(uuid());
      changeOtp('');
      Analytics.sendEvent('Login page- phone number validation failed');
    } else if (errors['InvalidOTP']) {
      Analytics.sendEvent('Login OTP Page- OTP Mismatch');
    }
  }, [errors]);

  function startTimer() {
    setResendDisabled(true);
    setTimeout(() => {
      setResendDisabled(false);
    }, (data.secondsToWaitFromLastSMS || 15) * 1000);
  }

  function changeOtp(v: string | number /* For some reason this is the type the children component requires */) {
    if (`${v}`.length > OTP_LENGTH || isNaN(+v)) {
      return;
    }

    setOtp(v);
  }

  const resendOtpHandler = () => {
    Analytics.sendEvent('Onboarding - Login OTP page- Resend SMS Clicked');
    if (resendDisabled) return;
    setResendDisabled(true);
    setOtpResent(true);
    onSubmit({ resendOTP: true, phoneNumber: data?.phoneNumber }).then(() => startTimer());
  };

  const handleSubmit = () => {
    Analytics.sendEvent('Login page- confirmed clicked ');
    onSubmit({ otp });
  };

  const isNextDisabled = !otp || otp.toString().length < OTP_LENGTH;

  useEventListener('keypress', handleSubmit);

  const onChangeOTP = () => {
    if (!otp || otp.toString().length < OTP_LENGTH) {
      return;
    }

    handleSubmit();
  };

  useEffect(onChangeOTP, [otp]);

  const childProps = {
    inputId,
    shouldAutofocus,
    changeOtp,
    otp,
    errors,
    maxLength: OTP_LENGTH,
    loading,
    otpResent,
    resendOtpHandler,
    phoneNumber: data.phoneNumber,
    handleSubmit,

    resendDisabled,
    isNextDisabled,

    goBack: props.goBack,
    backBtnEnabled: props.backBtnEnabled,
    onClose: props.onClose,
  };

  const OTPComponents = {
    [DESIGN.DE]: DEOTPView,
    [DESIGN.default]: DefaultOTPView,
  };

  const OTP = OTPComponents[ui.design];

  return <OTP {...childProps} />;
};

export default AuthOTPOTP;
