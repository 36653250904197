import amplitude from 'amplitude-js';
import { Logger } from 'legacy/utils/Logger';
import { events } from './AnalyticsEvents';
import { SessionStorageManager } from 'legacy/utils/SessionStorageManager';
import { ORDER_AMOUNT } from 'legacy/constants/storageKeys';
import { store } from 'legacy/store/store';
import { selectors } from 'legacy/store/UI';
import { selectors as orderSelectors } from 'legacy/store/order';

export class Analytics {
  static initialize(): void {
    const firebaseConfigByEnv = process.env.REACT_APP_FIREBASE_CONFIG || '';
    const firebaseConfig = JSON.parse(firebaseConfigByEnv);
    // firebase.initializeApp(firebaseConfig);

    const amplitudeApiKey = process.env.REACT_APP_AMPLITUDE_API_KEY || '';
    amplitude.getInstance().init(amplitudeApiKey);
  }

  static identifyUser(userId: string): void {
    if (userId) {
      amplitude.getInstance().identify(new amplitude.Identify().set('ConsumerID', userId));
      amplitude.setUserId(userId)
    }
  }

  static async sendEvent(name: string, params?: any): Promise<void> {
    const eventName = events[name] || name;
    const paymentAmount = SessionStorageManager.getValue(ORDER_AMOUNT);
    const currentPage = selectors.currentPage(store.getState());
    const orderDetails = orderSelectors.orderDetailsSelector(store.getState());
    const merchant = orderDetails?.merchantName;
    const amplitudeInstance  = await amplitude.getInstance();
    const userId = amplitudeInstance.options.userId;
    // await firebase.analytics().logEvent(eventName, params);
    
    amplitudeInstance.logEvent(eventName, {
      paymentProgram: orderDetails?.paymentProgram,
      paymentAmount,
      userId,
      extension:orderDetails?.branch,
      merchantId: orderDetails?.merchantId,
      source: 'NPG',
      page: currentPage,
      merchant,
      ...orderDetails?.campaignData,
      ...params,
    });
    Logger.info(`event '${eventName}' sent`);
  }

  static async sendScreenEvent(screenName: string): Promise<void> {
    await Analytics.sendEvent(`${screenName}_opened`);

    Analytics.sendEvent('page_view', {
      // eslint-disable-next-line @typescript-eslint/camelcase
      screen_name: screenName,
    });
  }
}
