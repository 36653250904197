import React from 'react';
import {
  ButtonContainer,
  ContentWrapper,
  CrossIcon,
  ErrorContainer,
  HeaderWrapper,
  BackIcon,
  TitleText,
  Wrapper,
} from './index.styles';
import Button from 'components/Button';
import Error from '../../Error';
import backArrow from 'assets/marni-back-icon.svg';
import { Analytics } from 'utils/Analytics';

type Props = {
  children: React.ReactNode;
  titleMessageId?: string;
  titleMessageContent?: string;
  buttonMessageId?: string;
  buttonOnClick?: () => void;
  buttonDisabledOnClick?: () => void;
  buttonIsLoading?: boolean;
  buttonDisabled?: boolean;
  errorProperty?: string;
  errorContent?: string;
  backBtnEnabled?: boolean;
  goBack?: () => void;
  onClose?: () => void;
  contentStyles?: React.CSSProperties;
  buttonStyles?: React.CSSProperties;
  closeBtnEnabled?: boolean;
};

const PageContainer: React.FC<Props> = ({ closeBtnEnabled = true, ...props }: Props) => {
  const { children } = props;
  const showError = !!props.errorContent || !!props.errorProperty;

  const onClickBack = (): void => {
    if (!props.buttonIsLoading) {
      props.goBack?.();
    }
  };

  return (
    <>
      {closeBtnEnabled && <CrossIcon onClick={props.onClose}>&#10005;</CrossIcon>}
      {props.backBtnEnabled && <BackIcon src={backArrow} onClick={onClickBack} />}

      <Wrapper>
        <HeaderWrapper>
          <TitleText
            messageId={props.titleMessageId}
            content={props.titleMessageContent}
            type="title"
            className="uppercase"
          />
        </HeaderWrapper>

        <ContentWrapper style={props.contentStyles}>{children}</ContentWrapper>

        <ButtonContainer style={props.buttonStyles}>
          <ErrorContainer>
            {showError && <Error property={props.errorProperty} content={props.errorContent} />}
          </ErrorContainer>

          {!!props.buttonMessageId && (
            <Button
              messageId={props.buttonMessageId}
              onClick={props.buttonOnClick}
              onClickDisabled={props.buttonDisabledOnClick}
              isLoading={props.buttonIsLoading}
              disabled={props.buttonDisabled}
            />
          )}
        </ButtonContainer>
      </Wrapper>
    </>
  );
};

export default PageContainer;
