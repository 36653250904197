import styled from 'styled-components';

export const CheckboxContainer = styled('div')`
  display: inline-block;
  position: relative;

  &.hasError > input + label::before {
    border: 1px solid #ff0000ab !important;
  }

  > input {
    opacity: 0;
    position: absolute;
  }

  > input + label {
    position: relative;
    padding-left: 25px;
    display: flex;

    cursor: pointer;
    &::before {
      content: '';
      position: absolute;
      left: -2.5px;
      width: 12px;
      height: 12px;
      border: 1px solid #a5a5a5ab;
      background: #fff;
      top: 9px;
      transform: translateY(-50%);
      border-radius: 3px;
    }

    &::after {
      content: '✔';
      position: absolute;
      top: 9px;
      left: 0px;
      transform: translateY(-50%);
      font-size: 10px;
      color: #b42d28;
      transition: all 0.2s;
    }
  }

  > input:not(:checked) + label {
    &::after {
      opacity: 0;
    }
  }

  > input:checked + label {
    &::after {
      opacity: 1;
    }
    &::before {
      border: 1px solid #b42d28;
    }
  }

  > input:checked:focus + label,
  input:not(:checked):focus + label {
    &::before {
      border: 1px solid #b42d28;
    }
  }
`;
