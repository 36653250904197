import * as Sentry from "@sentry/react";

export class Logger {
  static log(message: string) {
    if (process.env.NODE_ENV === 'development') {
      console.log(message);
      return;
    }
  }

  static info(message: string) {
    if (process.env.NODE_ENV === 'development') {
      console.info(message);
      return;
    }
  }

  static error(error: string | object, sendToSentryOnlyOnProduction = false) {
    // There are errors, especially for new flows, that we'd like to log only if they happen on production
    // (Because they're expected to happen on Staging for example)
    if (sendToSentryOnlyOnProduction && process.env.NODE_ENV !== "production") {
      return;
    }

    if (typeof error === "object") {
      Sentry.captureException(error);
    }
    else {
      Sentry.captureMessage(error);
    }

    if (process.env.NODE_ENV === 'development') {
      console.error(error);
      return;
    }
  }
}
