import React from 'react';
import Text from 'legacy/DFE/components/Text';
import Button from 'legacy/DFE/components/Button';
import styled from 'styled-components';

const SecondTextContainer = styled('div')`
  display: flex;
  margin-top: 15px;
`;

const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  padding-inline: 20%;
`;

const InsufficientFundsComponent: React.FC<{}> = (props: {}) => {
  const onClick = () => {};
  const onClickHere = () => {};

  return (
    <Container>
      <Text className="bold" fontSize={'24px'}>
        Payment failed
      </Text>
      <TextContainer>
        <Text className="light" fontSize={'16px'}>
          We appreciate the time you have taken to provide us with your feedback. Please fill the
          below and we reply shortly.
        </Text>
        <SecondTextContainer>
          <Text className="light" fontSize={'16px'}>
            If you wish to change your card, click
          </Text>
          <Text className="link light" fontSize={'16px'}>here</Text>
          <Text className="light" fontSize={'16px'}>
            .
          </Text>
        </SecondTextContainer>
      </TextContainer>
      <Button onClick={onClick}>Return to Nike</Button>
    </Container>
  );
};

export default InsufficientFundsComponent;
