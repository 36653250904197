import { AppConfigurationType } from "./types/apps";
import failure from "./apps/Failure";
import uxflow from "apps/UXFlow";
import changeCard from "apps/ChangeCard";

// Maybe do it with reflection?
const config: AppConfigurationType[] = [
    uxflow,
    changeCard,
    failure,
];

export default config;
