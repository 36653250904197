import { AppState } from './AppState';
import { tokenLogin, getSystemStatus } from 'legacy/api';
import { Logger } from 'legacy/utils/Logger';
import { AuthService } from 'legacy/utils/AuthService';
import { Analytics } from 'legacy/utils/Analytics';
import { ChannelManager } from 'legacy/utils/ChannelManager';
import { LocalStorageManager } from 'legacy/utils/LocalStorageManager';

import { Channels } from 'legacy/types/Payment';
import { Mode } from 'legacy/types/System';

export class ChangeCardState extends AppState {
  async handle(): Promise<void> {
    ChannelManager.setChannel(Channels.changeCardPage);
    Analytics.initialize();
    LocalStorageManager.removeItem('lastDigits');

    let authDetails;
    let systemStatus;

    const url = window.location.pathname.split('/');
    const token = url[url.length - 1];

    try {
      systemStatus = await getSystemStatus();
      if (systemStatus?.mode !== Mode.Live) {
        this.renderMaintenanceApp();
        return;
      }
    } catch (err) {
      Logger.error(`Error: ${JSON.stringify(err)}`);
      this.renderMaintenanceApp();
      return;
    }

    try {
      authDetails = await tokenLogin(token);
      if (!authDetails) {
        throw new Error('Empty response');
      }
      AuthService.setToken(authDetails.token);
    } catch (err) {
      Logger.error(`Error: ${JSON.stringify(err)}`);
      this.render({ supportPortal: true });
      return;
    }

    this.render({ changeCardPortal: true });
    return;
  }
}
