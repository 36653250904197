// export default () => {
//   const spath = window.location.pathname.split("/");
//   const path = spath[spath.length - 1].toLowerCase();
//
//   // return false;
//   return process.env.REACT_APP_USE_REFACTOR==="true" || path === "merchants";
// }
import flow from 'apps/UXFlow/store/flow';
import { NPG_APP_VER } from 'constants/index';
import { Analytics } from 'utils/Analytics';

const ORDERS_URL = 'paymentRequest';
const BASE_URL = `${process.env.REACT_APP_API_URL}/api`;
// remove, once all NPG will be migrated to refactor app
const KEY = 'noosa_de';
const programs = ['noosa_de', 'varengold_de'];

enum Bool {
  true = 'true',
  false = 'false',
}

export default class AppManager {
  static isRefactorApp = false;

  public static init = async (): Promise<void> => {
    const spath = window.location.pathname.split('/');
    const path = spath[spath.length - 1].toLowerCase();

    if (path === 'merchants' || window.location.pathname.includes('marni/card/change/t')) {
      AppManager.isRefactorApp = true;
      return;
    }

    if (spath[spath.length - 2] === 'flow' && !!spath[spath.length - 1]) {
      try {
        const result = await fetch(
          `${BASE_URL}/${ORDERS_URL}/getLandingDetails?id=${spath[spath.length - 1]}`,
          {
            headers: [['x-npg-build', NPG_APP_VER || '']],
          }
        );

        const json = await result.json();

        if (json?.order?.campaignData) {
          Analytics.campaignData = json?.order?.campaignData;
        }

        if (json?.order?.language) {
          localStorage.setItem('LANGUAGE', json.order.language);
        }

        if (!result.ok || result.status !== 200) {
          if (programs.includes(json?.error?.data?.paymentProgram)) {
            localStorage.setItem('isRefactorApp', Bool.true);
            AppManager.isRefactorApp = true;
            return;
          }
          localStorage.setItem('isRefactorApp', Bool.false);
          AppManager.isRefactorApp = false;
          return;
        }

        flow.merchantId = json.order.merchantId;
        flow.branch = json.order.branch;
        flow.paymentProgram = json.order.paymentProgram;
        flow.profile = json.order.profile;

        if (programs.includes(json?.order?.paymentProgram)) {
          localStorage.setItem('isRefactorApp', Bool.true);
          AppManager.isRefactorApp = true;
          return;
        }

        localStorage.setItem('isRefactorApp', Bool.false);
        AppManager.isRefactorApp = false;
        return;
      } catch (e) {
        if (programs.includes((e as any)?.data?.paymentProgram)) {
          localStorage.setItem('isRefactorApp', Bool.true);
          AppManager.isRefactorApp = true;
          return;
        }
        localStorage.setItem('isRefactorApp', Bool.false);
        AppManager.isRefactorApp = false;
        return;
      }
    }

    AppManager.isRefactorApp = localStorage.getItem('isRefactorApp') === Bool.true;
  };
}
