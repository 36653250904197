export class NameStateManager {
    private static firstNameState?: (name: string) => void;
    private static lastNameState?: (name: string) => void;

    static setFirstNameState(state: (name: string) => void) {
        NameStateManager.firstNameState = state;
    }
    
    static setLastNameState(state: (name: string) => void) {
        NameStateManager.lastNameState = state;
    }

    static setFirstName(name: string) {
        NameStateManager.firstNameState && NameStateManager.firstNameState(name);
    }

    static setLastName(name: string) {
        NameStateManager.lastNameState && NameStateManager.lastNameState(name);
    }
}