import React from 'react';
import styled from 'styled-components';

import Text from 'components/Text';
import Error from 'apps/UXFlow/components/Error';
import Button from 'components/Button';
import ButtonsGroup from 'components/ButtonsGroup';
import PasswordForm from 'components/PasswordForm';
import DotsFlashing from 'components/DotsFlashing';
import FlexColumn from 'components/FlexColumn';

import PagolightLogo from 'assets/logos/pagolight.png';
import { MappedUXFlowErrors } from 'types/data';

const Img = styled.img`
  display: inline;
  position: relative;
  top: 11px;
  width: 100%;
  max-width: 120px;
`;

const ContentBox = styled.div`
  width: 530px;
`;

interface Props {
  inputId: string;
  shouldAutofocus: boolean;
  changeOtp: (otp: string | number) => void;
  otp: string | number;
  errors: MappedUXFlowErrors;
  maxLength: number;
  loading: boolean;
  otpResent: boolean;
  resendOtpHandler: () => void;
}

const DefaultOTPView: React.FC<Props> = ({
  inputId,
  shouldAutofocus,
  changeOtp,
  otp,
  errors,
  maxLength,
  loading,
  otpResent,
  resendOtpHandler,
}: Props) => (
  <ContentBox>
    <Text messageId="otpInfo1" type="subtitle" />
    <Img src={PagolightLogo} />
    <Text type="subtitle" messageId="otpInfo2" />
    <Error />
    <PasswordForm
      id={inputId}
      autoFocus={shouldAutofocus}
      onChange={changeOtp}
      value={otp}
      error={errors.otp?.message}
      numInputs={maxLength}
      secure={false}
      inputType="otp-input"
    />
    <ButtonsGroup>
      {loading ? (
        <FlexColumn>
          <Text messageId={otpResent ? 'codeResent' : 'codeVerification'} type="subtitle" />
          <DotsFlashing style={{ marginTop: '10px' }} />
        </FlexColumn>
      ) : (
        <Button messageId="resendSms" className="secondary" onClick={resendOtpHandler} />
      )}
    </ButtonsGroup>
  </ContentBox>
);

export default DefaultOTPView;
