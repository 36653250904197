import { Consumer, CardsStatus, MobileNumber } from 'legacy/types/Consumer';
import { ConsumerAddress, initialConsumerAddress } from './address/state';
import { OnBoardingStatus, initialOnBoardingStatus } from './onboarding/state';

export interface ConsumerProfile {
  consumerInfo: Consumer | null;
  cardsStatus: CardsStatus | null;
  mobile: MobileNumber | null;
}

export const initialConsumerProfile: ConsumerProfile = {
  consumerInfo: null,
  cardsStatus: null,
  mobile: null,
};

export interface ConsumerState {
  consumerProfile: ConsumerProfile;
  onBoardingStatus: OnBoardingStatus;
  address: ConsumerAddress;
}

export const initialState: ConsumerState = {
  consumerProfile: initialConsumerProfile,
  onBoardingStatus: initialOnBoardingStatus,
  address: initialConsumerAddress,
};
