import styled from 'styled-components';

export const SideBarStyled = styled('div')`
  flex: 1 0 calc(520px * 0.7);
  width: calc(520px * 0.7);

  @media (max-width: 1280px) {
    flex: 1 0 calc(450px * 0.7);
    width: calc(450px * 0.7);
  }

  @media (max-width: 980px) {
    flex: 1 0 calc(400px * 0.7);
    width: calc(400px * 0.7);
  }

  @media (max-width: 768px) {
    display: none;
  }

  img.hidden {
    display: none;
  }
`;

export const SideBarImage = styled('img')`
  width: inherit;
  height: 100%;
  object-fit: cover;
  object-position: center center;
`;
