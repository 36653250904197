import React from 'react';
import { State } from 'legacy/store/state';
import { selectors as orderSelectors } from 'legacy/store/order';
import styled from 'styled-components';
import { connect, ConnectedProps } from 'react-redux';
import FormattedMessage from 'legacy/components/FormattedMessage';
import { actions as UIActions } from 'legacy/store/UI';
import { Amplitude } from 'services/AmplitudeService';

const TextContainer = styled('div')`
  display: inline-flex;
`;

interface StylesProps {
  isDisabled?: boolean;
}

const Link = styled('span')<StylesProps>`
  pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};
  cursor: ${props => (props.isDisabled ? 'none' : 'pointer')}
  text-decoration: ${props => (props.isDisabled ? 'none' : 'underline')};
`;

const mapStateToProps = (state: State) => ({
  order: orderSelectors.orderDetailsSelector(state),
  isPaymentClosed: orderSelectors.orderIsPaymentClosed(state),
});

const mapDispatchToProps = {
  openPopup: UIActions.openPopup,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const MerchantSiteLink: React.SFC<PropsFromRedux & StylesProps> = ({
  isDisabled,
  isPaymentClosed,
  openPopup,
}: PropsFromRedux & StylesProps) => {
  const cancellRequestClickHandler = () => {
    Amplitude.sendEvent('Merchant privacy clicked');
    openPopup('cancel-confirmation');
  };

  return (
    <TextContainer id="close-and-return">
      {!isPaymentClosed && (
        <>
          <Link onClick={cancellRequestClickHandler} isDisabled={isDisabled}>
            <FormattedMessage messageId="close" className="light" />
          </Link>
          <FormattedMessage messageId="cancel-details-lean" className="light" />
        </>
      )}
    </TextContainer>
  );
};

export default connector(MerchantSiteLink);
