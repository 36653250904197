import Text from 'components/Text';
import React from 'react';
import MerchantItem from './components/MerchantItem';
import { AppComponentProps } from 'types/apps';
import { ParentProps } from '../types';
import flow from 'apps/UXFlow/store/flow';
import Error from 'apps/UXFlow/components/Error';
import { MerchantItemType } from './types';

const MerchantsList: React.FC<AppComponentProps & ParentProps> = (
  props: AppComponentProps & ParentProps
) => {
  const {
    data: { merchants },
  } = flow;

  return (
    <>
      <Error />
      <Text type="subtitle" messageId="title" className="light" />
      {merchants.map((merchant: MerchantItemType) => (
        <>
          <MerchantItem title={merchant.title} description={merchant.description} />
        </>
      ))}
    </>
  );
};

export default MerchantsList;
