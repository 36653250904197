import styled from 'styled-components';

export const SideBarStyled = styled('div')`
  flex: 0 1 520px;

  @media (max-width: 1280px) {
    flex: 0 1 450px;
  }

  @media (max-width: 980px) {
    flex: 0 1 400px;
  }

  @media (max-width: 768px) {
    display: none;
  }

  img.hidden {
    display: none;
  }
`;

export const SideBarImage = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
