import React from 'react';
import styled from 'styled-components';

import Text from 'components/Text';

import questionIcon from 'assets/icon-tooltip.svg';

const TooltipWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  span {
    color: #8683a4;
    padding-left: 5px;
    margin-top: 0;
  }
`;

interface ParentProps {
  toggleInfo: () => void;
}

const InfoTitle: React.FC<ParentProps> = ({ toggleInfo }: ParentProps) => (
  <TooltipWrapper onClick={toggleInfo}>
    <img src={questionIcon} alt="question mark" width="20px" height="20px" />
    <Text className="fs-14" messageId="tooltipTitle" />
  </TooltipWrapper>
);

export default InfoTitle;
