import { makeAutoObservable } from "mobx";
import { DESIGN } from "types/design";
import defaultStyles from "../defaultStyles";

class UI {
    constructor() {
        makeAutoObservable(this);
    }

    public texts: any = {};

    public styles = defaultStyles;

    public design = DESIGN.default;
}

export default new UI();