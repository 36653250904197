import flow from 'apps/UXFlow/store/flow';

export class SeonService {
  static initialize(): void {
      // @ts-ignore:next-line
      seon.config({
      host: "seondf.com",
      session_id: flow.sessionId,
      audio_fingerprint: true,
      canvas_fingerprint: true,
      webgl_fingerprint: true,
      onSuccess: function(message: any) {
        console.log("success", message);
      },
      onError: function(message: any) {
        console.log("error", message);
      }
    });
  }

  static getSessionPayload() {
    // @ts-ignore:next-line
    return seon.getBase64Session();
  }
}
