import { take, call, put } from 'redux-saga/effects';
import { actions } from 'legacy/store/transaction';
import { sendPagoOtp } from 'legacy/api';
import { LocalStorageManager } from 'legacy/utils/LocalStorageManager';
import { TRANSACTION_ID_KEY } from 'legacy/constants/storageKeys';
import { notify } from 'legacy/helpers/notify';

export function* resendOtpSaga(): any {
  while (true) {
    yield take(actions.requestToResendPagoOtp);
    try {
      const transactionId = yield call(LocalStorageManager.getItem, TRANSACTION_ID_KEY);
      yield call(sendPagoOtp, transactionId);
      yield put(actions.successResendPagoOtp());
      notify('success', 'otp-sent');
    } catch (err) {
      yield put(actions.errorResendPagoOtp());
      notify('error', 'error-while-sending-otp');
    }
  }
}
