import React from 'react';
import styled from 'styled-components';

import { AppComponentProps, AppProps } from 'types/apps';
import Text from 'components/Text';
import Input from 'components/InputForm';
import PageContainer from 'apps/UXFlow/components/PageContainer/DE';
import useSubmitHandler from 'apps/UXFlow/utils/hooks/useSubmitHandler';
import flow from 'apps/UXFlow/store/flow';
import ui from 'store/ui';
import { ParentProps } from '../types';

const DEFAULT_MAX_IBAN = 34;

const DESepaDirectDebit: React.FC<AppComponentProps & ParentProps & AppProps> = (
  props: AppComponentProps & ParentProps & AppProps
) => {
  const { data } = flow;

  const [iban, setIban] = React.useState<string>('');
  const [error, setError] = React.useState<string>();

  const maxIbanChars: number = data?.maxIbanChars || DEFAULT_MAX_IBAN;

  const { onSubmit, loading } = useSubmitHandler(props);

  const handleSubmit = async () => {
    await onSubmit({ iban });
  };

  const isValidIban = (iban: string): boolean => {
    const ibanRegex = /^[A-Za-z0-9]*$/i;
    return ibanRegex.test(iban);
  };

  const handleChange = (value: string) => {
    if (!isValidIban(value)) {
      setError(ui.texts.IBANStructureError);
    } else {
      setError(undefined);
    }

    setIban(value);
  };

  return (
    <PageContainer
      titleMessageId="SDDTitle"
      goBack={props.goBack}
      // backBtnEnabled={props.backBtnEnabled}
      backBtnEnabled={false}
      onClose={props.onClose}
      buttonMessageId="next"
      buttonOnClick={handleSubmit}
      buttonIsLoading={loading}
      buttonDisabled={!iban || !!error}
      errorContent={error}
      buttonStyles={{ bottom: '18.5%' }}
    >
      <Text messageId="SDDSubtitle" type="content" />

      <IbanInput
        id="sdd_iban"
        autoFocus
        value={iban}
        changeHandler={handleChange}
        placeholderMessageId="IBANLabel"
        maxLength={maxIbanChars}
      />
    </PageContainer>
  );
};

const IbanInput = styled(Input)`
  margin-top: 30px;
`;

export default DESepaDirectDebit;
