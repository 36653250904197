import axios from 'axios';
import { Translations } from 'legacy/types/Translations';
import { Status } from 'legacy/types/System';
import { getApiUrl } from 'legacy/helpers/getApiUrl';

const SYSTEM_URL = 'systemSettings/';

export const getText = (paymentRequestId: string): Promise<Translations> => {
  return axios.get(getApiUrl(`${SYSTEM_URL}getText`, { paymentRequestId }));
};

export const getSystemStatus = (): Promise<Status> => {
  return axios.get(getApiUrl(`${SYSTEM_URL}status`));
};
