import { CameraUsedForType } from "legacy/containers/Onboarding/components/OCR/types";

export type Theme = {
  logo: string;
  font: string;
  merchantName: string;
  upsaleProductPicture: string;
  loyaltyBackgroundLogo: string;
  upsaleProductName: string;
}

export type OCR = {
  cameraUsedFor: CameraUsedForType;
  tempImagesData: {
    front?: string;
    back?: string;
  };
  cardImageLoading: {
    front: boolean;
    back: boolean;
  };
  errors: {
    alreadyUploaded: {
      front: boolean;
      back: boolean;
    }
  }
}

export interface UIState {
  isLoading: boolean;
  openedPopup: null | string;
  refreshOnClosePopup: boolean;
  sidebarImage: number;
  sidebarImageHistory: number;
  isCookieSupported: boolean;
  confirmedLogout: boolean;
  theme: Theme;
  portingDesign: boolean;
  ocr: OCR;
  currentPage: string;
}

export const initialState: UIState = {
  isLoading: false,
  openedPopup: null,
  refreshOnClosePopup: false,
  sidebarImage: 0,
  sidebarImageHistory: 0,
  isCookieSupported: false,
  confirmedLogout: false,
  portingDesign: process.env.REACT_APP_IS_PORTING_UI === "true",
  theme: {
    logo: "",
    font: "'Ubuntu', sans-serif",
    merchantName: "Noosa",
    upsaleProductPicture: "",
    loyaltyBackgroundLogo: "",
    upsaleProductName: "",
  },
  ocr: {
    cameraUsedFor: "none",
    tempImagesData: {
      front: undefined,
      back: undefined,
    },
    cardImageLoading: {
      front: false,
      back: false,
    },
    errors: {
      alreadyUploaded: {
        front: false,
        back: false,
      }
    }
  },
  currentPage: "/",
};
