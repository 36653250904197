import React from 'react';
import { AppConfigurationType } from 'types/apps';
import flow from '../store/flow';
import Lean_PaymentPlan from './PaymentPlanSelection';
import AuthOTPPhone from './AuthOTPPhone';
import AuthOTPOTP from './AuthOTPOTP';
import LeanEMailAddress from './LeanEMailAddress';
import ECPCard from './ECPCard';
import TermsOfUsePorting from './TermsOfUsePorting';
import MerchantsList from './MerchantsList';
import Lean_NameFiscalCode_OCR_IT from './OCR';
import DEPayment from './DEPayment';
import DEPaymentComplete from './DEPaymentComplete';
import KYC from './KYC';
import DEPaymentSelectMethod from './DEPaymentSelectMethod';
import DEEmailVerification from './DEEmailVerification';
import DEAddress from './DEAddress';
import ContactUs from './ContactUs';
import LegalDocuments from './AuthOTPPhone/LegalDocuments';
import DESepaDirectDebit from './DESepaDirectDebit';
import DEDemoVipClub from './DEDemoVIPClub';

function generateApps(components: { [key: string]: React.FC<any> }): AppConfigurationType[] {
  return Object.entries(components).map(([name, component]) => ({
    Component: component,
    precondition: async () => {
      return flow.step === name;
    },
  }));
}

export default generateApps({
  Lean_PaymentPlan,
  Auth_OTP_Phone: AuthOTPPhone,
  Auth_OTP_OTP: AuthOTPOTP,
  Lean_EMail_Address: LeanEMailAddress,
  Lean_NameFiscalCode_OCR_IT,
  ECPCard: ECPCard,
  TermsOfUse_Porting: TermsOfUsePorting,
  MerchantsList: MerchantsList,

  DE_Auth_OTP_Phone: AuthOTPPhone,
  DE_Auth_OTP_OTP: AuthOTPOTP,
  DE_Address: DEAddress,
  DE_KYC: KYC,
  DE_NameFiscalCode_OCR_IT: Lean_NameFiscalCode_OCR_IT,
  DE_Payment: DEPayment,
  DE_Payment_SelectMethod: DEPaymentSelectMethod,
  Complete: DEPaymentComplete,
  DE_EmailVerification: DEEmailVerification,
  ContactUs: ContactUs,
  LegalDocuments: LegalDocuments,
  DE_SDD_IBAN: DESepaDirectDebit,

  DE_DemoVIPClub: DEDemoVipClub,
});
