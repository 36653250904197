import { put } from 'redux-saga/effects';
import { actions } from 'legacy/store/consumer';
import { notify } from 'legacy/helpers/notify';

interface errorItem {
  property?: string;
  code?: string;
  message?: string;
  stack?: string;
  data?: any;
}

export function* handleFlowErrors(err: any): any {
  let errors = {
    invalidFields: {},
    flowError: '',
  };

  if (err.errors && Array.isArray(err.errors)) {
    err.errors
      .filter((e: errorItem) => e.property || e.data?.property)
      .forEach((e: errorItem) => {
        const property = e.property || e.data?.property;
        if (property) {
          errors.invalidFields[property] = true;
        }
        if (e.message) {
          errors.invalidFields[`${property}_message`] = e.message;
        }
      });

    err.errors.forEach((e: errorItem) => {
      if (e.code && e.code === 'AuthFailure') {
        errors.invalidFields[`auth`] = e.message;
      }
    });
  }

  if (err.errors[0].code == 'InvalidOTP') {
    errors.invalidFields['auth'] = err.errors[0].message;
    errors.flowError = err.errors[0].code;
  }

  if (err.errors[0].code == 'FISCALCODE_FIRSTNAME_MISMATCH') {
    errors.invalidFields['firstName'] = err.errors[0].message;
    errors.flowError = err.errors[0].code;
  }

  if (err.errors[0].code == 'FISCALCODE_LASTNAME_MISMATCH') {
    errors.invalidFields['lastName'] = err.errors[0].message;
    errors.flowError = err.errors[0].code;
  }

  if (err.errors[0].code == 'OCRUnknownDocumentType' || err.errors[0].code == 'OCRNoData') {
    errors.invalidFields['documentType'] = err.errors[0].message;
    errors.flowError = err.errors[0].code;
  }

  if (err.errors[0].code == 'OCR_ERROR_UNDERAGE') {
    errors.invalidFields['birthDate'] = err.errors[0].message;
    errors.flowError = err.errors[0].code;
  }

  if (err.errors[0].code == 'GENERAL') {
    errors.flowError = err.errors[0].message;
  }

  if (err.errors[0].code == 'BACKEND-GENERAL' || err.errors[0].code == 'Invalid Card') {
    errors.flowError = 'BACKEND-GENERAL';
  }

  if (err.errors[0].code == 'Unauthorized') {
    notify('error', 'errors:session-expired-title');
  }

  if (err && !errors.flowError) {
    errors.flowError = 'General error.';
  }

  return yield put(actions.setFlowErrors(errors));
}

export function* resetErrors(): any {
  const errors = {
    invalidFields: {},
    flowError: '',
  };

  return yield put(actions.setFlowErrors(errors));
}
