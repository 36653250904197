import React, { useEffect } from 'react';
import Footer from 'components/Footer';
import Logo from 'components/Logo';
import EnvBanner from 'components/EnvBanner';
import { Background, Container, ContentWrapper, } from './index.styles';
import { disableEnvBanner } from 'utils/env';

type Props = {
  setComponentOnDemand: (component: string, options: any) => void;
};

const Box: React.FC<Props> = ({ children, setComponentOnDemand }) => {
  const openContactUs = () => {
    setComponentOnDemand('ContactUs', { errors: null });
  };

  return (
    <Background>
      {!disableEnvBanner && <EnvBanner />}
      <Container>
        <Logo />

        <ContentWrapper>
          {children}
        </ContentWrapper>

        <Footer
          hideCopyright
          openContactUs={openContactUs}
        />
      </Container>
    </Background>
  );
};

export default Box;
