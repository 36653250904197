import { ParentProps } from 'apps/ChangeCard/apps/types';
import flow from 'apps/ChangeCard/store/flow';
import React, { useCallback, useState } from 'react';
import { AppComponentProps } from 'types/apps';

export default function useSubmitHandler({
  submitFlow,
  reloadApps,
  getFlow
}: ParentProps & AppComponentProps) {
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = useCallback(
    async (data?: any) => {
      if (loading) return;

      setLoading(true);

      await submitFlow(data);

      reloadApps();

      setLoading(false);
    },
    [submitFlow, reloadApps, loading]
  );

  const onGetFlow = useCallback(
    async (params?: any) => {
      await getFlow(params);

      reloadApps();
    },
    [submitFlow, reloadApps, loading]
  );

  return { onSubmit, loading, onGetFlow };
}
