import { RecoverPinStatus } from 'legacy/types/RecoverPinStatus';

export interface ForgotPinCodeStatus {
  pending: boolean;
  error: boolean;
  status: null | RecoverPinStatus;
  resendOtpPending: boolean;
  pinCodeError: boolean;
}

export const initialForgotPinCodeState: ForgotPinCodeStatus = {
  pending: false,
  error: false,
  status: null,
  resendOtpPending: false,
  pinCodeError: false,
};
