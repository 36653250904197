import { ConsumersAPI } from "legacy/DFE/api/handlers";
import { State } from "legacy/DFE/store/state";
import { OnboardingRoute } from "../OnboardingRoute";
import PinCodeComponent from "./index.component";

export default class PinCode extends OnboardingRoute {
    constructor() {
        super("PINCode");
    }

    async preprocess(state: State) {
        const provincesData = (await ConsumersAPI.getProvinces()).provinces;

        return {
            provincesList: provincesData.map((item: { provincia: string }) => item.provincia),
        };
    }

    component = PinCodeComponent;
}