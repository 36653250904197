import { Order as OrderType } from 'legacy/DFE/types/order';
import { createSlice, PayloadAction, createAction } from 'redux-starter-kit';
import { State } from '../state';
import { initialState, OrderState } from './state';

const Order = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrderId: (state, { payload: orderId }: PayloadAction<string>): OrderState => ({
      ...state,
      orderId,
    }),
    setOrder: (state, { payload: order }: PayloadAction<OrderType>): OrderState => ({
      ...state,
      order,
    }),
  },
});

export const { reducer } = Order;

export const actions = {
  ...Order.actions,
};


/** selectors */
const orderState = (state: State): OrderState => state.Order;
const orderId = (state: State): string => orderState(state).orderId;
const order = (state: State): OrderType | undefined => orderState(state).order;

export const selectors = {
  orderState,
  orderId,
  order,
};