import ui from 'store/ui';
import styled, { css } from 'styled-components';
import styledObserver from 'utils/styledObserver';
import { TextType } from './types';

const Title = css`
  font-weight: 600;
  font-size: 14px;
  ${() => css(ui.styles.text.title)};
`;

const Subtitle = css`
  font-weight: 450;
  font-size: 14px;
  line-height: 21px;
  ${() => css(ui.styles.text.subtitle)};
`;

const Content = css`
  font-size: 14px;
  font-weight: 400;
  ${() => css(ui.styles.text.content)};
`;

const Subcontent = css`
  font-size: 12px;
  font-weight: 400;
  ${() => css(ui.styles.text.subcontent)};
`;

const Label = css`
  font-size: 10px;
  font-weight: 400;
  ${() => css(ui.styles.text.label)};
`;

export const StyledSpan = styledObserver(styled.span<{ type?: TextType; weight?: string }>`
  color: #000;

  ${({ type }) => {
    switch (type) {
      case 'title':
        return Title;
      case 'subtitle':
        return Subtitle;
      case 'content':
        return Content;
      case 'subcontent':
        return Subcontent;
      case 'label':
        return Label;
      default:
        return '';
    }
  }}

  &.error, &.red {
    font-size: 14px;
    color: rgb(218, 41, 28);
  }

  &.bold {
    font-weight: bold;
  }

  &.light {
    color: rgb(147, 147, 147);
  }

  &.left-space {
    margin-left: 10px;
  }

  &.link,
  &.pointer {
    cursor: pointer;
  }

  &.link,
  &.underline {
    text-decoration: underline;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.centered {
    text-align: center;
  }

  &.gray {
    color: #c5c5c5;
  }

  ${({ weight }) => weight && `font-weight: ${weight};`}
  letter-spacing: 0.03em;
`);

export const StyledAnchor = styledObserver(styled.a<{ type?: TextType; weight?: string }>`
  color: #000;
  text-decoration: none;

  &.bold {
    font-weight: bold;
  }

  &.light {
    color: rgb(147, 147, 147);
  }

  &.left-space {
    margin-left: 10px;
  }

  &.link,
  &.pointer {
    cursor: pointer;
  }

  &.link,
  &.underline {
    text-decoration: underline;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  ${({ weight }) => weight && `font-weight: ${weight};`}
  letter-spacing: 0.03em;
`);
