import styled from 'styled-components';
import { cardRatioWidthToHeight } from 'utils/OCR';

const height = 60;

export const Image = styled('img')`
  position: absolute;
  width: ${height * cardRatioWidthToHeight}vh;
  height: ${height}vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const buttonsTopSpace = '30%';

export const ApproveButton = styled('div')`
  background-color: #da291c;
  border-radius: 25px;
  min-width: 190px;
  padding: 15px 25px;
  cursor: pointer;
  position: absolute;
  top: ${buttonsTopSpace};
  right: 10px;
`;

export const RetakeButton = styled('div')`
  background-color: red;
  border-radius: 25px;
  min-width: 190px;
  padding: 15px 25px;
  cursor: pointer;
  position: absolute;
  bottom: ${buttonsTopSpace};
  right: 10px;
`;
