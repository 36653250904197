import styled from 'styled-components';

export const CheckboxContainer = styled('div')`
  display: inline-block;

  > input {
    opacity: 0;
  }

  > input + label {
    position: relative;
    padding-left: 45px;
    display: flex;

    cursor: pointer;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      width: 27px;
      height: 27px;
      border: 1px solid #979797;
      background: #fff;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 3px;
    }
    &::after {
      content: '✔';
      position: absolute;
      top: 50%;
      left: 9px;
      transform: translateY(-50%);
      font-size: 16px;
      color: #229fef;
      transition: all 0.2s;
    }
  }

  > input:not(:checked) + label {
    &::after {
      opacity: 0;
    }
  }

  > input:checked + label {
    &::after {
      opacity: 1;
    }
    &::before {
      border: 1px solid #229fef;
    }
  }

  > input:checked:focus + label,
  input:not(:checked):focus + label {
    &::before {
      border: 1px solid #229fef;
    }
  }
`;
