import { useEffect } from 'react';

const useEventListener = (eventType: string, eventHandler: () => void) => {
  const handler = (e: any) => {
    const { key } = e;
    const isEnter = key === 'Enter' || key === 'NumpadEnter';
    if (!isEnter) {
      return;
    }

    eventHandler();
  };

  useEffect(() => {
    window.addEventListener(eventType, handler);
    return () => {
      window.removeEventListener(eventType, handler);
    };
  }, [eventHandler]);
};

export default useEventListener;
