import {
   ORDER_TYPE_PAYNOW,
   ORDER_TYPE_INSTALLMENTS,
   CARD_TYPE_CREDIT,
   CARD_TYPE_DEBIT,
   CARD_STATUS_COMPLETED
} from 'legacy/constants/index';

import { CardsStatus } from 'legacy/types/Consumer';

import { ON_BOARDING } from 'legacy/constants/pathnames';
import { history } from 'legacy/routing/history';
import { forceMobileFeatures, isMobileOperatingSystem } from 'legacy/utils/UI';

export const getCardsStatus = (
  cards: Array<any>
): CardsStatus => {
  const creditCard = cards.find(card => card.cardDetails.type === CARD_TYPE_CREDIT && card.status === CARD_STATUS_COMPLETED);
  const debitCard = cards.find(card => card.cardDetails.type === CARD_TYPE_DEBIT && card.status === CARD_STATUS_COMPLETED);

  return {
    creditCard: creditCard ? true : false,
    debitCard: debitCard ? true : false
  }
}

export const isCardValid = (
  orderType: string,
  cards: CardsStatus
): boolean => {
  if (orderType === ORDER_TYPE_INSTALLMENTS && cards.creditCard) {
    return true;
  }

  if (orderType === ORDER_TYPE_PAYNOW && cards.creditCard) {
    return true;
  }

  if (orderType === ORDER_TYPE_PAYNOW && cards.debitCard) {
    return true;
  }

  return false;
};

export const isBINNumberValid = (cardType: string, cardScheme: string, paymentType: string): boolean => {
  if (!cardType) {
    return false;
  }

  if (cardType === 'debit' && paymentType === ORDER_TYPE_INSTALLMENTS) {
    return false;
  }

  return true;
}

export const getPermission = async (): Promise<{ camera: boolean; microphone: boolean }> => {
  const permission = {
    camera: false,
    microphone: false,
  };

  const devices = await navigator.mediaDevices.enumerateDevices();

  devices.forEach(device => {
    if (device.kind === 'videoinput' && device.label) {
      permission.camera = true;
    }

    if (device.kind === 'audioinput' && device.label) {
      permission.microphone = true;
    }
  });

  return permission;
};
