import { take, put, call } from 'redux-saga/effects';
import { actions as UIActions } from 'legacy/store/UI';
import { AuthService } from 'legacy/utils/AuthService';
import { getConsumerInfo } from 'legacy/api';
import { actions as loginActions } from 'legacy/store/login';
import { actions as consumerActions } from 'legacy/store/consumer';
import { Analytics } from 'legacy/utils/Analytics';

/** handle landing page loaded event */
export function* landingSaga(): any {
  while (true) {
    yield take(loginActions.getLandingPageData);
    try {
      yield put(UIActions.setIsLoading(true));

      const authToken = yield call(AuthService.getToken);
      let consumer;
      if (authToken) {
        consumer = yield call(getConsumerInfo);
      }
      yield put(loginActions.setIsLoggedInUser(Boolean(authToken && consumer)));
      if (consumer) {
        yield put(consumerActions.setConsumerInfo(consumer));
        Analytics.identifyUser(consumer.id);
      }
    } catch (err) {
      yield put(loginActions.setIsLoggedInUser(false));
    } finally {
      yield put(UIActions.setIsLoading(false));
    }
  }
}
