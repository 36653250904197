export const questions = [
  'What is Noosa?',
  'Is Noosa safe?',
  'How do we verify your identity?',
  'I want to shop, how do I sign up?',
  'When will the first payment be made?',
  'How does the installment payment with Noosa work?',
  'Who can request an extension?',
  'Where can I request the payment extension?',
  'Is there a cost for using Noosa?',
  'If my request for payment extension has been refused, what should I do?',
  'How many purchases can I make with Noosa?',
  'I can cancel my Noosa account',
  'Can I use both credit and debit cards?',
  'How can I add or remove a card to my account?',
  'How do I add or remove a card to your account?',
  'How can I change my mobile number?',
  'How can I change my email address?',
  'How can I change my address?',
  'How do I close my account?',
  'Waiting for my order to arrive',
  'I placed an order but the items are no longer available, what do I do?',
  'How much can I spend using the installments?',
  'How to use Noosa in the shop?',
  'In which shops can I use Noosa?',
  'How much can I spend in the shop with Noosa?',
  'Can I request a refund or exchange an item purchased with Noosa?',
  'How much do I have to pay on the day of purchase?',
  'Before making a purchase, do I have to select the store in the list in the app? \n',
  'How many times can I use the barcode?',
  "What happens if I can't pay an installment?",
  'How do I create an account?',
  "Why didn't I receive the SMS with the verification code during registration?",
  'My account has been disabled',
  "Why didn't I receive the password reset email?",
  'How can I contact Noosa?',
  'Making a complaint with Noosa',
  'Do you want to join the network of vendors affiliated with Noosa?',
  'I am a seller, how can I get in touch with you?',
  'When do I get paid with Noosa?',
  'Reset password',
  'Return merchandise management and customer refund processing',
  'Partial return',
  'Total return',
  'Modify an existing order',
  'Cancel an existing order',
  'Which e-commerce platform does Noosa support?',
];

export const answers = [
  "<p>Noosa è il futuro degli acquisti, sempre a portata di mano. La tua nuova alleata nella vita di tutti i giorni, per toglierti tutti quegli sfizi che\n  vuoi quando vuoi. Noosa è semplicissima da usare: ti basta accedere all’app, richiedere di utilizzare uno dei metodi di pagamento offerti tramite\n  Noosa e procedere con il tuo acquisto direttamente in cassa, in tutti i negozi convenzionati con Noosa. Avrai poi a disposizione un periodo di tempo\n  prestabilito per restituire la somma a rate senza interessi. Paghi in totale sicurezza. Noosa è disegnata per essere a portata di mano, su misura\n  per te, e realizzare i tuoi sogni.</p>",
  "<p>Noosa è molto semplice e intuitiva da usare. Offriamo diversi metodi di pagamento: “Soddisfatti o rimborsati”, “paga adesso” (a breve disponibili)\n  e “dilaziona il tuo pagamento”.</p>\n<p>Basta fare acquisti in uno dei tuoi negozi preferiti (li trovi tutti nella Lista dei negozi su www.nooosa.io/stores) e scegliere “Noosa” come\n  metodo di pagamento alla cassa. I clienti che acquistano per la prima volta devono fornire una carta di credito o di debito, mentre dagli acquisti\n  successivi basterà aprire Noosa e procedere direttamente con il pagamento in cassa. Facile e immediato!</p>\n<p>In caso di acquisto online, dopo il check-out la merce vi sarà spedita dal venditore.</p>\n<p>Puoi accedere in qualsiasi momento al tuo account Noosa per verificare il tuo metodo di pagamento: tutti i pagamenti saranno automaticamente\n  addebitati sulla tua carta di debito o di credito a seconda del metodo di pagamento selezionato e, in caso di pagamento dilazionato, in base alla\n  data di scadenza delle rate.</p>\n<p>Noosa è una community: ci impegniamo a garantire a tutti un utilizzo corretto del credito e una gestione responsabile delle proprie spese. </p>\n",
  "<p>Sappiamo quanto è importante la sicurezza e che le tue informazioni personali siano al sicuro. Noosa si impegna a rispettare i migliori protocolli\n  di sicurezza informatica in conformità con la GDPR.\n</p>\n<p>Prendi visione dell’informativa privacy cliccando sul seguente link: www.noosa.io/agreements.</p>",
  "<p>Lavoriamo senza sosta per rendere la nostra Community il più sicura possibile. Ecco perché, prima di fare acquisti con noi, tutti i clienti devono\n  confermare la propria identità. Fa parte del nostro processo di adesione.</p>\n<p>Ti chiediamo di fornirci le seguenti informazioni quando crei il tuo profilo su Noosa, così potremo verificare la tua identità, anche attraverso\n  servizi di verifica forniti da terze parti. </p>\n<ul>\n  <li>Nome e cognome</li>\n  <li>Il tuo indirizzo</li>\n  <li>Email e numero di telefono cellulare</li>\n  <li>Codice Fiscale </li>\n  <li>Informazioni dal documento d'identità che includono: numero, data di emissione, luogo di emissione, data di scadenza.</li>\n</ul>\n<p>Questo processo ci aiuta a prevenire le frodi, oltre a garantire che tutti coloro che utilizzano il servizio abbiano almeno 18 anni.</p>",
  "<p>Noosa è la rivoluzione dello shopping per un motivo: è molto semplice da usare.\n  Quando effettui un acquisto in uno dei nostri negozi convenzionati, ti basterà chiedere di usare Noosa come metodo di pagamento in cassa. </p>\n<p>Prima di effettuare il tuo primo ordine, dovrai creare il tuo account su Noosa. In fase di checkout, per usare Noosa ti basterà semplicemente\n  inserire i tuoi dati di accesso.</p>\n<p>Per richiedere la possibilità di dilazionare il proprio acquisto con Noosa, è necessario:</p>\n<ul>\n  <li>a) avere compiuto 18 anni </li>\n  <li>b) essere residente in Italia </li>\n  <li>c) avere i requisiti per stipulare un contratto giuridicamente vincolante</li>\n  <li>d) disporre di un indirizzo e-mail e di un numero di cellulare, validi e verificabili</li>\n  <li>e) fornire il codice fiscale, carta d’identità ed un indirizzo di residenza valido in Italia</li>\n  <li>f) essere autorizzati ad utilizzare il metodo di pagamento fornito </li>\n</ul>",
  "<p>Al momento dell’acquisto tramite Noosa:</p>\n<ul>\n  <li>nel caso di dilazione di pagamento, pagherai il 25% del valore dell’ordine. Il resto dell’importo verrà dilazionato secondo le seguenti\n    scadenze:\n    <p>A. nel caso di contratto di dilazione concluso tra il giorno 1 e il giorno 10 del mese, il giorno 10 del mese successivo;</p>\n    <p>B. nel caso di contratto di dilazione concluso dopo il giorno 11 del mese, il giorno 25 del mese successivo;</p>\n  </li>\n  <li>nel caso di acquisto tramite “Soddisfatti o rimborsati” pagherai dopo 14 giorni dalla data dell’acquisto; fino a a tale data il relativo importo\n    deve essere disponibile sulla tua carta di credito o sul tuo conto nel caso di carta di debito;</li>\n  <li>nel caso di acquisto tramite “Paga ora” pagherai al momento dell’acquisto tramite la carta di credito o di debito che hai registrato sul tuo\n    account Noosa.</li>\n</ul>",
  "<p>Noosa offre la possibilità di richiedere una dilazione degli acquisti effettuati sia presso i negozi fisici che attraverso il canale online\n  consentendo di pagare l'acquisto in quattro rate uguali, addebitate:</p>\n<ul>\n  <li>nel caso di contratto di dilazione concluso tra il giorno 1 e il giorno 10 del mese, il giorno 10 del mese successivo;</li>\n  <li>nel caso di contratto di dilazione concluso dopo il giorno 11 del mese, il giorno 25 del mese successivo.</li>\n</ul>\n<p>Prima di effettuare il tuo primo ordine, dovrai creare il tuo account su Noosa, assicurandoti di aver fornito i dati della tua carta di credito\n  Visa o MasterCard. In fase di checkout, per usare Noosa ti basterà semplicemente inserire i tuoi dati di accesso.</p>\n<p>Nel caso di acquisti online, dopo il checkout la merce vi sarà spedita dal venditore. In qualsiasi momento potrai accedere al tuo account Noosa per\n  controllare il tuo piano di pagamento e, se lo desideri, richiedere di saldare tutte le rate prima delle relative date di scadenza. Per effettuare\n  la richiesta, invia una mail all’indirizzo <u>support@noosa.io</u> .</p>",
  "<p>La dilazione di pagamento è un metodo di pagamento offerto sia da rivenditori online sia da rivenditori che utilizzano negozi fisici, e può essere\n  richiesto da quegli acquirenti che abbiano:</p>\n<ul>\n  <li>residenza in Italia ed almeno 18 anni</li>\n  <li>i requisiti per stipulare un contratto giuridicamente vincolante</li>\n  <li>un indirizzo e-mail e di un numero di cellulare, validi e verificabili</li>\n  <li>un indirizzo di consegna valido in Italia</li>\n  <li>un codice fiscale e un documento d’identità in corso di validità</li>\n  <li>l’autorizzazione ad utilizzare una carta di credito emessa in Italia per effettuare l'acquisto (di una spesa minima di €80)</li>\n</ul>",
  "<p>La possibilità di richiedere la dilazione dei propri acquisti tramite Noosa sarà presto disponibile presso molti dei principali rivenditori online\n  e negozi fisici in tutta Italia. Per consultare l'elenco completo dei negozi, clicca qui: www.noosa.io/stores. Aggiungiamo costantemente nuovi\n  rivenditori alla lista: resta aggiornato consultando periodicamente il nostro sito web o l’app! Ricorda, la spesa minima per la quale poter\n  richiedere la dilazione è di €80.</p>\n<p>Se vuoi che anche il tuo negozio preferito utilizzi Noosa come metodo di pagamento, mettici in contatto con lui, scrivici una mail ad\n  <u>info@noosa.io</u> , oppure vai su <a href=\"http://www.noosa.io/\">www.noosa.io</a> e clicca su Assistenza.</p>\n",
  "<p>Noosa è un servizio gratuito; per te non ci sono commissioni, interessi o costi nascosti - davvero!</p>\n",
  "<p>Non disperare, abbiamo altre soluzioni per te!</p>\n<p>Per questo motivo abbiamo creato altri servizi che ti permetteranno di utilizzare ancora Noosa, ovvero:</p>\n<ul>\n  <li>“Soddisfatti o Rimborsati”: con questo servizio hai la possibilità di provare i tuoi acquisti per 14 giorni e poi decidere di restituirli; Noosa\n    pre-autorizzerà l’importo del tuo potenziale futuro acquisto sulla carta di credito o debito che hai registrato sul tuo account. Ricorda, tale\n    importo ti verrà restituito nel caso in cui decidessi di restituire la merce.</li>\n  <li>”Paga adesso”: Noosa ti consente anche di pagare immediatamente i tuoi acquisti comodamente tramite App, utilizzando la carta di debito o\n    credito che hai registrato sul tuo account. Grazie a tale pagamento Noosa registrerà un punteggio più alto in associazione al tuo account, il\n    quale potrebbe essere utile per future richieste di pagamento con questo servizio con il precedente.\n    È importante sapere che il servizio “Soddisfatti o Rimborsati” potrebbe non essere disponibile presso tutti i commercianti a causa della politica\n    interna di restituzione di ogni attività commerciale. Tuttavia, il servizio ”Paga adesso” è sempre disponibile presso tali store. </li>\n</ul>",
  "<p>Verifica sempre con attenzione la capienza della tua carta! Prima di effettuare nuovi ordini assicurarti che i pagamenti da te dovuti siano andati\n  a buon fine. </p>\n<p>Il tuo account ti consente di verificare i tuoi acquisti, per supportarti in una spesa responsabile!</p>\n",
  "<p>Se in qualsiasi momento vuoi smettere di usare Noosa, devi solo farcelo sapere e noi chiuderemo il tuo account. </p>\n",
  "<p>Oltre alla dilazione di pagamento per la quale è necessaria una carta di credito, Noosa offre anche i servizi “Soddisfatti o rimborsati” e “Paga\n  adesso”, disponibili sia tramite carta di debito che carta di credito.</p>\n<p>Pertanto, se per qualche motivo la tua richiesta di dilazione di pagamento è stata rifiutata, non scoraggiarti: puoi ancora utilizzare dove\n  disponibile i servizi “Soddisfatti o rimborsati”, che ti concede 14 giorni per poter provare i tuoi acquisti prima di decidere di acquistarli, o\n  ”Paga adesso”, pagamento immediato</p>\n",
  "<h5><strong><u>Scegliere la carta preferita, cambiare o aggiornare una carta per un ordine esistente o per nuovi ordini</u></strong></h5><p>Quando aggiungi una nuova carta al tuo account e la imposti come carta preferita, questa viene utilizzata solo per i nuovi acquisti. Eventuali\n  acquisti esistenti continueranno ad essere rimborsati per il tramite della carta utilizzata al momento dell'acquisto, a meno che non richiedi di\n  modificarlo; per richiedere la modifica invia una mail all’indirizzo <u>support@noosa.io</u> .</p>\n<p>Ti ricordiamo che la dilazione di pagamento supporta esclusivamente le carte di credito, a differenza dei servizi “Soddisfatti o rimborsati” e\n  “Paga ora” che supportano sia le carte di credito che le carte di debito. </p>\n<p>Puoi cambiare la carta che sarà utilizzata per i pagamenti futuri in questo modo:</p>\n<ul>\n  <li>Accedi alla tua area riservata sul sito o nell'applicazione mobile</li>\n  <li>Clicca su “cambia carta di credito”</li>\n  <li>Seleziona una carta esistente dall'elenco o aggiungine una nuova (nota: continueranno ad applicarsi gli stessi Termini e Condizioni approvati\n    per le transazioni precedenti).</li>\n  <li>Questo aggiornerà la carta per i pagamenti dei futuri nuovi ordini. </li>\n</ul>\n<p>Nota: questa opzione non sarà disponibile sul primo ordine che effettui, ma solo su quelli successivi.\n  Se desideri utilizzare una carta per un pagamento in un’unica soluzione, senza cambiare impostazioni sul tuo ordine, puoi farlo utilizzando\n  l'opzione “Paga ora”. Quando scegli l'opzione “Paga ora” dovrai deselezionare la casella “Ricorda la carta per le prossime transazioni”, così non\n  memorizzerai la nuova carta utilizzata sul tuo account.</p>\n",
  "<ul>\n  <li>Effettua il login nella tua area riservata all'indirizzo <a href=\"http://www.noosa.io/\">www.noosa.io</a></li>\n  <li>Seleziona “Il mio account”</li>\n  <li>Clicca su Fatturazione (l'icona è una carta di credito)</li>\n  <li>Clicca su “Aggiungi metodo di pagamento” o “Rimuovi carta”. Non puoi rimuovere la carta preferita sul tuo account o una carta che ha acquisti\n    associati, quindi se stai cercando di rimuovere la tua attuale carta di pagamento preferita dovrai prima modificarla e aggiornare poi i tuoi\n    acquisti effettuati con il servizio “Soddisfatti o rimborsati”, solo allora sarai in grado di rimuovere la carta.</li>\n</ul>\n<p>Tieni a mente: quando aggiungi una nuova carta al tuo account, Noosa la verificherà richiedendo un’autorizzazione temporanea di pochi centesimi: €\n  0,11. Le pre-autorizzazioni sono piccole somme di denaro che non vengono mai effettivamente addebitate sul tuo conto e che dovrebbero essere\n  stornate entro pochi giorni lavorativi, infatti servono solo a confermare la validità della carta. </p>\n",
  "<p>Attualmente gli unici modi per cambiare il numero di telefono sono:</p>\n<ul>\n  <li>inviarci un'e-mail all'indirizzo <u>support@noosa.io</u></li>\n  <li>accedere al nostro sito web www.noosa.io e cliccando poi su Assistenza</li>\n</ul>\n<p>Provvederemo noi a modificare il numero.</p>\n",
  "<p>Accedi al tuo profilo tramite l’app oppure online su <a href=\"http://www.noosa.io/\">www.noosa.io</a>, e vai su \"Il mio profilo”. Da lì, segui le\n  istruzioni e potrai cambiare il tuo indirizzo email in pochi step. Per qualsiasi problema, contattaci all’indirizzo email <u>support@noosa.io</u> ,\n  oppure accedendo al nostro sito web www.noosa.io, cliccando poi su Assistenza: siamo sempre a disposizione. </p>\n",
  "<ul>\n  <li>inviarci un'e-mail all'indirizzo <u>support@noosa.io</u></li>\n  <li>accedere al nostro sito web <a href=\"http://www.noosa.io/\">www.noosa.io</a> e cliccando poi su Assistenza</li>\n</ul>\n<p>Provvederemo noi a modificare il tuo indirizzo.</p>\n",
  "<p>Naturalmente puoi chiudere il tuo account quando vuoi, senza penali o costi. Dobbiamo solo assicurarci che prima della chiusura effettiva, tu non\n  abbia acquisti in corso effettuati tramite il metodo di pagamento “soddisfatti o rimborsati”.</p>\n<p>Per chiudere il tuo account, vai su www.noosa.io o apri l'applicazione mobile Noosa, seleziona \"Il mio profilo\" e poi \"Chiudi il mio account”.</p>\n<p>Rispettiamo pienamente la tua decisione ed è per questo che ci piacerebbe un tuo feedback, in modo da poter continuare a migliorare per la\n  Community di Noosa. Se vuoi condividere con noi la tua esperienza, saremo ben felici di trarne dei suggerimenti per migliorarci.</p>\n\n",
  "<p>Se stai aspettando la consegna del tuo ordine ma questa sta richiedendo più tempo del previsto e vuoi informazioni sulla data di consegna, o se hai\n  ricevuto il tuo ordine con articoli mancanti e/o danneggiati e vuoi inoltrare un reclamo, dovrai metterti in contatto direttamente con il venditore.\n</p>\n<p>In caso di problemi con la consegna, dovresti consultare il tracking della spedizione che ti è stato fornito.\n  Noosa infatti non è in grado di modificare o controllare un ordine una volta che questo è stato approvato: deve essere gestito direttamente con il\n  venditore. Consulta il sito web dei quest’ultimo per contattarlo; troverai i suoi riferimenti anche nel riepilogo dell'ordine su Noosa. </p>\n<p>Ti preghiamo di considerare che il venditore solitamente impiega 48 ore per rispondere alla tua richiesta, quindi non preoccuparti se non ricevi\n  subito una risposta.</p>\n<p>Se non riesci a metterti in contatto con il venditore, comunicacelo e verificheremo come possiamo aiutarti.\n  Avviso: le rate verranno addebitate anche se l'ordine non è stato consegnato, questo perché Noosa non ha alcun controllo sulla consegna della merce.\n  Sarà cura del venditore informare Noosa della mancata consegna dell’ordine in modo da poterti rimborsare. </p>\n<p>Nel caso in cui volessi comunque segnalarci questa casistica, scrivici una mail a <u>support@noosa.io.</u></p>\n",
  "<p>I venditori potrebbero mettersi in contatto con te per informarti che alcuni, o tutti gli articoli che hai ordinato non sono disponibili a causa di\n  un esaurimento delle scorte.</p>\n<p>In questi casi, ti contatteranno direttamente per consigliarti articoli alternativi o offrirti un rimborso.</p>\n<p>Nel caso della cancellazione di un ordine acquistato con PagoLight, fallo sapere a Noosa, in modo che potremo cancellare il tuo ordine e gestire il\n  processo di rimborso sulla tua carta di credito entro 48 ore. Ricorda che i tempi di riaccredito della tua carta dipenderanno dal relativo\n  emittente. </p>\n<p>Nel caso in cui avessi problemi o bisogno di informazioni, contattaci all’indirizzo email <u>support@noosa.io.</u></p>\n",
  "<p>La spesa minima per poter richiedere una dilazione di pagamento con Noosa può cambiare a seconda del venditore (es. 80 € o 100 €).</p>\n",
  "<p>Noosa è disponibile in diversi negozi in tutta Italia. Il processo di Noosa in-store parte dal tuo cellulare.\n  Se sei già utente Noosa basta scaricare l'app ed effettuare l’accesso, in alternativa, registrati come nuovo utente per creare il tuo account.</p>\n",
  "<p>Puoi trovare nell’app la Lista dei Negozi che offrono Noosa come metodo di pagamento. Se selezioni \"in-store\" dal menu profilo in alto a destra\n  della homepage, si aprirà una mappa che ti mostrerà dove puoi acquistare con Noosa!</p>\n",
  "<p>Dopo aver richiesto di utilizzare una dilazione di pagamento, nel caso in cui ti venisse concessa vedrai l'importo disponibile per la spesa\n  direttamente sull’app, in corrispondenza del codice a barre da mostrare in cassa per ottenere la dilazione dal venditore.</p>\n",
  "<p>Il procedimento è lo stesso di qualsiasi altro acquisto: rimborsi e cambi sono soggetti alla politica del rivenditore. </p>\n",
  "<p>Quando acquisti in negozio utilizzando Noosa paghi subito il 25% del totale del tuo acquisto. Questo importo sarà detratto dal metodo di pagamento\n  scelto, preimpostato e collegato al tuo account.\n  Un esempio: stai acquistando un articolo per un valore totale di 100 €? Il giorno dell’acquisto ti verranno detratti 25 €.</p>\n",
  "<p>No, non ce n’è bisogno! Il codice a barre funziona in qualsiasi negozio che accetta Noosa come metodo di pagamento. Indipendentemente dal\n  rivenditore presso il quale sceglierai di fare acquisti, dovrai semplicemente presentare il codice a barre in cassa.</p>\n",
  "<p>Il codice a barre viene aggiornato da Noosa ogni 10 minuti. Scaduto questo periodo di tempo, puoi cliccare su \"Aggiorna codice a barre\" al centro\n  dello schermo per richiederne uno nuovo. </p>\n",
  "<p>Noosa si impegna a farti rispettare il tuo piano di rimborso, assicurandosi che sia sempre sostenibile per la tua situazione economica. Ecco perché\n  viene valutata ogni richiesta di acquisto prima di approvarla, ed è per questo che ti invitiamo a rispettare le date di pagamento previste dal tuo\n  piano di dilazione </p>\n<p>Sei in difficoltà con l’utilizzo di Noosa? Contattaci direttamente accedendo al nostro sito web <a href=\"http://www.noosa.io/\">www.noosa.io</a> e cliccando\n  poi su Assistenza. </p>\n<p>In alternativa puoi scriverci una mail all’indirizzo <u>support@noosa.io.</u></p>\n",
  "<p>Prima di effettuare il tuo primo acquisto con Noosa, dovrai creare un account sul sito o sull'app. Per farlo, segui le istruzioni in app, ci\n  vorranno pochi minuti!</p>\n<p>Se stai usando uno smartphone ed hai problemi di accesso, prova così:</p>\n<ul>\n  <li>assicurati di avere una connessione internet stabile (WiFi o mobile)</li>\n  <li>aggiorna il dispositivo alla versione più recente del sistema operativo e del browser</li>\n</ul>\n<p>Chiudi tutti i browser e le schede aperte e riavvia il telefono.</p>\n<p>Non utilizzare la modalità di Navigazione privata.</p>\n",
  "<p>Se non ricevi l’SMS contenente il codice di verifica, fai così:</p>\n<ul>\n  <li>Togli e rimetti la scheda SIM ed aggiorna la connessione riavviando lo smartphone</li>\n  <li>Contatta il tuo operatore e verifica la presenza di un blocco dei codici di accesso</li>\n</ul>\n<p>Una volta ricevuta conferma della cancellazione, procedi nuovamente con la verifica per completare la configurazione del tuo account.</p>\n",
  "<p>Se il tuo account è stato disattivato per qualche motivo, reimposta la password selezionando “Password dimenticata” nella pagina di accesso. Ti\n  sarà inviata un’e-mail: segui le istruzioni e il tuo account verrà riattivato.</p>\n<p>Non hai ricevuto la mail? Ti chiediamo di attendere qualche minuto prima di richiederne una nuova e di controllare che non sia accidentalmente\n  finita nella cartella Spam. Fai attenzione: se richiedi la riattivazione troppe volte in un breve periodo, il tuo account verrà disattivato in modo\n  permanente.</p>",
  "<p>Ok, può succedere. Fai così: </p>\n<ul>\n  <li>Assicurati di utilizzare la stessa e-mail che ci hai dato con il tuo primo acquisto, se hai dimenticato qual è, controlla tutte le tue e-mail di\n    conferma d'ordine originale che hai ricevuto da noi o dal venditore.</li>\n  <li>Controlla che la mail non sia finita nella cartella Spam</li>\n</ul>\n<p>Ancora nulla? Contattaci, e risolveremo il problema.</p>",
  "<p>Siamo disponibili online 7 giorni su 7, ti risponderemo al più presto e faremo il possibile per risolvere il tuo problema in poco tempo, così\n  intanto ti potrai godere la giornata.</p>\n<p>In app: entra nell’app, clicca sul menu di impostazione del profilo che trovi in alto a destra, seleziona Assistenza. </p>\n<p>Sul web: vai sul nostro sito web all'indirizzo <a href=\"http://www.noosa.io/\">www.noosa.io</a> , poi clicca su Assistenza.</p>\n<p>In alternativa puoi scriverci una mail agli indirizzi:</p>\n<ul>\n  <li><u>info@noosa.io</u> per richiederci informazioni generali riguardanti Noosa</li>\n  <li> <u>support@noosa.io</u> per richiederci supporto per richiederci informazioni su ordini, acquisti, problemi legati all’account, ecc.</li>\n</ul>",
  "<p>Può capitare che si verifichi un problema, e faremo di tutto per risolverlo nel minor tempo possibile per far si che la tua esperienza con Noosa\n  sia sempre semplice e trasparente. </p>\n<p>Per inoltrare un reclamo, contattaci tramite www.noosa.io, poi clicca su Assistenza, oppure invia una mail all’indirizzo <u>support@noosa.io</u> .\n</p>\n<p>Ci impegniamo a rispondere a tutti i reclami entro 5 giorni lavorativi, e a risolverli entro 21 giorni lavorativi.</p>\n",
  "<p>Parlaci del tuo business in Italia!</p>\n<ul>\n  <li>Volume delle vendite del tuo negozio all’anno:</li>\n  <li>Valore medio dell'ordine:</li>\n  <li>URL del negozio:</li>\n</ul>\n<p>Per inserire il tuo negozio tra i nostri partner, invia un'e-mail direttamente al nostro team di marketing ( <u>suppor@noosa.io</u> ) o accedi\n  all’indirizzo <a href=\"http://www.noosa.io/\"> www.noosa.io </a></p>\n",
  "<p>Clicca l’icona qui sotto e scrivici! Siamo disponibili online 7 giorni su 7.</p>\n",
  "<p>I pagamenti vengono attivati al momento dell'approvazione dell'ordine da parte di Noosa, e vengono elaborati come segue:</p>\n<ul>\n  <li>i pagamenti saranno effettuati in base al tempo di elaborazione del regolamento concordato, e variano a seconda dei servizi utilizzati\n    dall’acquirente</li>\n  <li>un’e-mail riassuntiva della transazione viene inviata all'indirizzo e-mail del venditore (se configurato) laddove ci siano importi dovuti al\n    venditore</li>\n  <li>i costi del servizio sono detratti dall’importo dell’acquisto a te dovuto al momento del pagamento e / o potrebbero essere fatturate\n    separatamente a seconda del metodo di regolamento concordato. </li>\n</ul>",
  "<p>Reimpostare la password con Noosa è semplice, fai così: </p>\n<ul>\n  <li>utilizza l'indirizzo e-mail che ci hai fornito per creare il profilo di accesso al portale Noosa e clicca su “password dimenticata”. Se non\n    ricordi quale indirizzo e-mail hai utilizzato, prova a cercare nella tua casella di posta la conferma del login che hai ricevuto da Noosa\n    (controlla che la mail non sia finita nella cartella Spam)</li>\n  <li>nel caso in cui non dovessi trovare o ricordare l’indirizzo email di registrazione, contattaci inviandoci un'e-mail all'indirizzo\n    support@noosa.io, oppure collegandoti a <a href=\"http://www.noosa.io/\">www.noosa.io</a> e cliccando poi su Assistenza.</li>\n</ul>\n<p>Nel caso in cui il tuo profilo non sia stato creato nella sezione dedicata ai venditori, possiamo sostituire noi la collocazione del tuo account:\n  basta compilare subito la richiesta! Contattaci, inviandoci un'e-mail all'indirizzo support@noosa.io, oppure collegandoti a www.noosa.io e cliccando\n  poi su Assistenza.</p>",
  "<p>Per quanto riguarda la restituzione della merce, Noosa indirizzerà tutte le richieste dei clienti al venditore, nel caso in cui queste siano state\n  inserite sull’App o sul portale web di Noosa. </p>\n<p>Nel caso in cui il cliente abbia già effettuato pagamenti verso di te, il rimborso dovrà essere gestito dal venditore stesso.</p>\n<p>Noosa accetterà le richieste di rimborso in conformità alle istruzioni fornite dal venditore, sia che la richiesta di reso sia stata inserita dal\n  cliente tramite il portale dell'esercente, sia che questa sia avvenuta presso il negozio fisico.</p>\n<p>Le comunicazioni circa lo status dell’avvenuto rimborso/del nuovo piano di pagamento verso l’acquirente vengono gestite da Noosa. </p>\n<p>Noosa traccia tutti gli importi dovuti dal cliente al venditore, dal venditore a Noosa e da Noosa al venditore, in modo da gestire al meglio ed in\n  totale trasparenza i flussi di cassa e la fatturazione.</p>\n<p>Le commissioni di Noosa per i commercianti non sono rimborsabili.</p>\n<p>Una volta che il rimborso è stato emesso, Noosa non è in grado di annullare o modificare questa azione.\n  Importante: per assicurare la trasparenza e la registrazione di tutti i flussi di cassa, non consentiamo e non gestiamo rimborsi in contanti.</p>",
  "<p>Qualsiasi richiesta di reso di una merce per un valore inferiore all'intero importo dell’acquisto è da considerarsi un reso parziale.</p>\n<p>Nel caso in cui il cliente abbia acquistato tramite PagoLight, il procedimento è lo stesso di qualsiasi altro acquisto: rimborsi e cambi sono\n  soggetti alla politica del rivenditore. </p>",
  "<p>Qualsiasi richiesta di reso di una merce per un valore pari all'intero importo dell’acquisto è da considerarsi un reso totale, il procedimento è lo\n  stesso di qualsiasi altro acquisto: rimborsi e cambi sono soggetti alla politica del rivenditore.</p>",
  "<p>Una volta che Noosa ha approvato un ordine, i dettagli e il valore dell'ordine non possono essere modificati.</p>\n<p>In quanto venditore, potrai emettere rimborsi parziali o completi se gli articoli non sono disponibili in magazzino o se non possono essere evasi\n  per un qualsiasi motivo. </p>\n<p>Noosa non supporta l'aggiunta di articoli ad un ordine, una volta completato. Pertanto l’acquirente dovrà effettuare un ulteriore ordine per gli\n  articoli aggiuntivi o annullare l'ordine precedente per inserirne uno nuovo.</p>",
  "<p>La cancellazione di un ordine esistente effettuato tramite “Paga ora” o “Soddisfatti o rimborsati” equivale alla richiesta di un reso totale. È\n  facile e richiede solo 2 minuti.</p>\n<h5><strong><u>1. In negozio: scansiona il codice a barre sullo scontrino</u></strong></h5>\n<p>Per emettere un rimborso attraverso la scansione di un codice a barre, chiedi al cliente di mostrare la fattura originale in modo da poter\n  scansionare il codice a barre del pagamento avvenuto con Noosa e segui la procedura standard di rimborso presso la cassa.</p>\n<h5><strong><u>2. Tramite il portale web del venditore: accedi al tuo account sul sito <a href=\"http://www.noosa.io/\">www.noosa.io</a></u></strong></h5>\n<ol>\n  <li>Accedi al tuo account con le tue credenziali</li>\n  <li>Seleziona l'ordine che desideri modificare tramite il numero di riferimento</li>\n  <li>Sono ammessi rimborsi parziali o totali: immetti l'importo del reso</li>\n  <li>Fatto! Ci prendiamo noi cura del resto, il denaro viene riaccreditato immediatamente sulla carta di credito utilizzata per il pagamento,\n    invieremo anche un'email all’acquirente per informarlo dell’avvenuto reso e rimborso.</li>\n</ol>",
  "<p>Noosa supporta diverse piattaforme di e-commerce, per conoscerle contattaci all'indirizzo <a href=\"http://www.noosa.io/\">www.noosa.io</a> e poi clicca su\n  Assistenza.</p>"
];
