import React, { Suspense, useEffect, useState } from 'react';
import { ConnectedProps } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';

import { FLOW, ON_BOARDING } from 'legacy/constants/pathnames';
import {
  PAYMENT_SESSION_ID,
  CHANNEL,
  NOOSA_MOBILE_AS_CAMERA_PHONE,
} from 'legacy/constants/storageKeys';
import { Analytics } from 'legacy/utils/Analytics';
import { Logger } from 'legacy/utils/Logger';
import { history } from 'legacy/routing/history';
import Router from 'legacy/routing/Router';
import SideBar from 'legacy/components/SideBar';
import Popup from 'legacy/components/Popup';
import EnvBanner from 'legacy/components/EnvBanner';
import {
  GlobalStyles,
  PageContainer,
  ContentContainer,
  Container,
  MainContent,
} from './root.styles';

import Logo from 'legacy/components/Logo';
import Footer from 'legacy/components/Footer';
import Header from 'legacy/components/Header';
import Maintenance from 'legacy/containers/Maintenance';
import { ENV_PROD } from 'legacy/constants/index';
import { SessionStorageManager } from 'legacy/utils/SessionStorageManager';
import { Channels } from 'legacy/types/Payment';
import { ChannelManager } from 'legacy/utils/ChannelManager';

import axios from 'axios';
import connector from './connect';
import { getApiUrl } from 'legacy/helpers/getApiUrl';

import ErrorBoundary from './errorBoundary.component';
import PaymentExpired from 'legacy/containers/PaymentExpired';
import { getFlowId, mobileCheck } from 'legacy/utils/UI';

import kenzo from 'legacy/themes/kenzo.json';
import marcjacobs from 'legacy/themes/marcjacobs.json';
import { searchForParent } from 'legacy/utils/dom';

const AUTOCLOSE_TIME = 15000;

interface Location {
  hash: string;
  pathname: string;
  search: string;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

const Root: React.SFC<PropsFromRedux> = (props: PropsFromRedux) => {
  const {
    tryToGetOrderDetails,
    isOrderLoading,
    checkCookie,
    initPaymentSession,
    getText,
    requestConsumerInfo,
    orderExpired,
    setTheme,
    isPortingUI,
    setPortingUI,
    orderDetails,
    theme,
  } = props;

  const [isMaintenanceMode, setMaintenece] = useState<boolean | undefined>(
    process.env.REACT_APP_MAINTENANCE_MODE === 'ON' || undefined
  );
  const [maintenenceModeMessage, setMaintenenceModeMessage] = useState('');

  const sentScreenEvent = ({ pathname }: Location) => {
    if (pathname === ON_BOARDING) {
      return;
    }

    // const isLandingPage = pathname.startsWith(LANDING);
    // const pageName = isLandingPage ? LANDING.slice(1) : pathname.slice(1);
    // Analytics.sendScreenEvent(`screen_view_${pageName}`);
  };

  useEffect(() => {
    document.addEventListener('click', e => {
      const target = e.target as HTMLElement;

      if (searchForParent(target, 'a')) {
        Analytics.sendEvent(`Link ${target.innerHTML} clicked`);
      }
    });
  }, []);

  useEffect(() => {
    setTheme((orderDetails?.failureURL || '').includes('wordpress-dev') ? marcjacobs : kenzo);
  }, [orderDetails?.failureURL]);

  useEffect(() => {
    setPortingUI(orderDetails?.useNewFlow || isPortingUI);
  }, [orderDetails]);

  useEffect(() => {
    checkCookie();
  }, [checkCookie]);

  useEffect(() => {
    Analytics.initialize();
    Logger.info('Analytics initialized');

    sentScreenEvent(history.location);
    history.listen(sentScreenEvent);
    // history.listen(generateSidebarImage);

    // generateSidebarImage();
  }, []);

  useEffect(() => {
    const closeWindowHandler = () => {
      Analytics.sendEvent('WindowClosed');
    };

    window.addEventListener('beforeunload', closeWindowHandler, false);

    return () => {
      window.removeEventListener('beforeunload', closeWindowHandler, false);
    };
  }, []);

  useEffect(() => {
    const { pathname } = history.location;
    // const isTokenPage = pathname.startsWith(TOKEN);
    // if (isTokenPage) {
    //   return;
    // }

    const paymentSessionId = SessionStorageManager.getItem(PAYMENT_SESSION_ID);

    let orderId = null;

    // isLandingPage
    // if (pathname.startsWith(LANDING)) {
    //   orderId = pathname.substring(LANDING.length + 1);
    // }
    // isLeanLanding
    if (pathname.startsWith(FLOW)) {
      orderId = getFlowId() || null;
    }

    if (!paymentSessionId) {
      SessionStorageManager.setItem(PAYMENT_SESSION_ID, Date.now());
      ChannelManager.setChannel(Channels.ecommerce);
      // Moved to tryToGetOrderDetails
      // Analytics.sendEvent('SessionStarted');

      initPaymentSession(orderId);
    }
  }, []);

  useEffect(() => {
    const { pathname } = history.location;
    // const isTokenPage = pathname.startsWith(TOKEN);
    // if (isTokenPage) {
    //   return;
    // }

    let orderId = null;
    let isLandingPage = false;

    // isLandingPage
    // if (pathname.startsWith(LANDING)) {
    //   orderId = pathname.substring(LANDING.length + 1);
    //   isLandingPage = true;
    // }
    // isLeanLanding
    if (pathname.startsWith(FLOW)) {
      orderId = getFlowId() || null;
      isLandingPage = true;
    }

    if (isLandingPage && orderId) {
      tryToGetOrderDetails(orderId);
      return;
    }
    tryToGetOrderDetails();
  }, [tryToGetOrderDetails]);

  useEffect(() => {
    getText();
  }, []);

  // Check for maintenance mode
  useEffect(() => {
    if (isMaintenanceMode !== undefined) return;

    axios.get(getApiUrl('systemSettings/status')).then(res => {
      const data = res as any;
      setMaintenece(data.mode == 'maintenance');
      setMaintenenceModeMessage(data.message);
    });
  });

  useEffect(() => {
    const { pathname } = history.location;
    // const isTokenPage = pathname.startsWith(TOKEN);
    const isLeanLanding = pathname.startsWith(FLOW);

    // Don't ask for consumer info, if it's MAPP /t/XXX page,
    // consumer info will be requested in loginByToken saga.
    // if (isTokenPage) {
    //   return;
    // }

    // Don't ask for consumer info, if it's lean landing page,
    // as it's driven by backend UXFlow and has provided data.
    if (isLeanLanding) {
      return;
    }

    requestConsumerInfo();
  }, []);

  const isProduction = process.env.REACT_APP_BUILD_ENVIRONMENT === ENV_PROD;
  // const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'ON' ? true : false;
  const appIsNotInIframe = window === window.parent;

  const macHideFooter = !!SessionStorageManager.getItem(NOOSA_MOBILE_AS_CAMERA_PHONE);

  return (
    // <Suspense fallback={<Loader />}>
    <>
      <ErrorBoundary>
        {isMaintenanceMode !== undefined && (
          <Suspense fallback={<div></div>}>
            <GlobalStyles font={theme.font} />
            <ToastContainer
              position={toast.POSITION.TOP_RIGHT}
              autoClose={AUTOCLOSE_TIME}
              className="toaster"
              newestOnTop={false}
              closeOnClick
              hideProgressBar
            />
            {!isProduction && <EnvBanner />}

            <Popup />
            <PageContainer>
              {/* {props.isLoading && <Loader />} */}
              {!mobileCheck() && appIsNotInIframe && <SideBar />}
              {isMaintenanceMode && <Maintenance message={maintenenceModeMessage} />}
              {!isMaintenanceMode && !isOrderLoading && (
                <ContentContainer>
                  <Header />
                  <MainContent>
                    <Logo />
                    <Container>{orderExpired ? <PaymentExpired /> : <Router />}</Container>
                  </MainContent>
                  {!macHideFooter && <Footer />}
                </ContentContainer>
              )}
            </PageContainer>
          </Suspense>
        )}
      </ErrorBoundary>
    </>
  );
};

export default connector(Root);
