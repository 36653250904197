import { NOOSA_SESSION_ID } from 'constants/index';
import { makeAutoObservable } from 'mobx';
import { MappedUXFlowErrors } from 'types/data';

class Flow {
  constructor() {
    makeAutoObservable(this);
    this.offset = 0;
  }

  private _sessionId?: string;

  // Data returned from each step
  public data?: any;

  // Errors returned from each step
  public errors: MappedUXFlowErrors = {};

  // Step name
  public step?: string;

  // The session id returned in the headers
  public get sessionId(): string | undefined {
    return this._sessionId;
  }

  public set sessionId(sessionId: string | undefined) {
    this._sessionId = sessionId;
    sessionStorage.setItem(NOOSA_SESSION_ID, JSON.stringify(sessionId ?? null));
  }

  public complete?: boolean;

  public referrer?: string;

  public offset?: number;

  public merchantId?: string;

  public ott?: string;
}

export default new Flow();
