import { AbstractAPIHandler } from './index';
import { languageNames } from 'legacy/DFE/constants/languageNames';
import { version } from '../../../../package.json';

const CHANNEL_NAME = 'Web NPG';
const languages = { ...languageNames } as const;

type Keys = keyof typeof languages;
type LanguageName = typeof languages[Keys];

interface EmailDetails {
  subject: string;
  body: string;
  language: LanguageName;
}

export class EmailAPIHandler extends AbstractAPIHandler {
  constructor() {
    super('');
    this.path = "";
  }

  public sendSupport(emailDetails: EmailDetails): Promise<{ result: boolean }> {
    return this.post('sendEmail', {
      ...emailDetails,
      version,
      channel: CHANNEL_NAME,
    });
  }
}
