import flowAPI from 'api/flowAPI';
import Apps from 'apps';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import ui from 'store/ui';
import useAsyncEffect from 'utils/hooks/useAsyncEffect';
import config from './apps';
import flow from './store/flow';
import { AxiosResponse } from 'axios';
import { ParentProps } from './apps/types';
import Auth from 'auth';
import { DESIGN } from 'types/design';
import DEBox from './components/Boxes/DE';
import DefaultBox from './components/Boxes/Default';
import ErrorPage from './components/ErrorPage';
import { redirect } from 'helpers/redirection';
import { notify } from 'helpers/notify';
import { AppProps } from 'types/apps';
import { setOffset, getOffset } from 'offset';
import { scrollTop } from 'utils/ui';
import Loader from 'components/Loader';

async function handleFlowResp(
  promise: Promise<AxiosResponse | { data: any; headers: any }>
): Promise<boolean> {
  flow.errors = {};

  try {
    const resp = await promise;
    const { data, headers } = resp;

    if (data.accessToken) {
      Auth.set(data.accessToken);
    }

    if (data.previousStepData?.accessToken) {
      Auth.set(data.previousStepData?.accessToken);
    }

    flow.data = {
      ...data.data,
    };

    flow.step = data.next;
    flow.complete = data.complete;

    flow.sessionId = headers['noosa-session-id'] || flow.sessionId;

    ui.texts = {
      ...ui.texts,
      ...data.texts,
    };

    ui.design = DESIGN[data.design] || DESIGN.default;

    flow.referrer = document.referrer;

    return true;
  } catch (err) {
    const errs: any[] = (err as any).response?.data.errors;

    flow.errors = Object.fromEntries(
      errs.map(err => [err.data?.property || err.code || 'ROOT', err])
    );
    return false;
  }
}

const ChangeCard: React.FC<any> = () => {
  const [loading, setLoading] = useState(false);

  useAsyncEffect(async () => {
    await handleFlowResp(flowAPI.getFlow({ offset: getOffset() }));
  }, []);

  const submitFlow = useCallback(async (data: any) => {
    const currentOffset = getOffset();
    const isSuccess = await handleFlowResp(flowAPI.postFlow(data, { offset: currentOffset }));
    if (isSuccess && currentOffset < 0) {
      const nextOffset = currentOffset + 1;
      await handleFlowResp(flowAPI.getFlow({ offset: nextOffset }));
      setOffset(nextOffset);
    }
  }, []);

  const getFlow = useCallback(async (params?: any) => {
    await handleFlowResp(flowAPI.getFlow(params));
  }, []);

  const goBack = useCallback(async () => {
    const currentOffset = getOffset();
    const offset = currentOffset - 1;
    setOffset(offset);

    setLoading(true);

    await getFlow({ offset });

    setLoading(false);
  }, []);

  const setComponentOnDemand = useCallback(async (component: string, options: any = {}) => {
    const data = {
      data: {
        ...flow,
        accessToken: Auth.get(),
        next: component,
        ...options,
      },
      headers: { 'noosa-sesson-id': flow.sessionId },
    };

    await handleFlowResp(Promise.resolve(data));

    ui.design = DESIGN.DE;
  }, []);

  const onClose = async (): Promise<void> => {
    try {
      if (flow.step === 'ContactUs') {
        flow.step = '';
        await getFlow();
      } else if (flow.complete) {
        const redirectURL = flow?.data?.successURL || flow.referrer;
        redirect(redirectURL);
      } else {
        // nothing happens
      }
    } catch (e) {
      if (e instanceof Error) {
        notify('error', e.message || 'Unknown error.');
      } else {
        notify('error', 'Unknown error.');
      }
    }
  };

  useEffect(scrollTop, [flow.step]);

  const BoxComponents = {
    [DESIGN.DE]: DEBox,
    [DESIGN.default]: DefaultBox,
  };

  const Box = BoxComponents[ui.design];

  // ErrorPage component is only for DE Flow
  if (flow.errors.ErrorPageException) {
    ui.design = DESIGN.DE;

    return (
      <DEBox setComponentOnDemand={setComponentOnDemand}>
        <ErrorPage showIcon={false} />
      </DEBox>
    );
  }

  if ((!flow.step && !flow.complete) || loading) {
    return <Loader />;
  }

  if (!flow.step && flow.complete) {
    flow.step = 'Complete';
  }

  const parentProps: ParentProps & AppProps = {
    submitFlow,
    getFlow,
    goBack,
    onClose,
    backBtnEnabled: flow?.data?.backBtnEnabled,
    setComponentOnDemand,
  };

  return (
    <Box setComponentOnDemand={setComponentOnDemand}>
      <Apps config={config} parentProps={parentProps} />
    </Box>
  );
};

const ChangeCardObserver = observer(ChangeCard);
ChangeCardObserver.displayName = 'ChangeCard';

export default ChangeCardObserver;
