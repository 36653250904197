import { fork } from 'redux-saga/effects';
import { getOrderDetailsSaga } from './getOrderDetailsSaga';
import { initPaymentSessionSaga } from './initPaymentSession';
import { setOrderDetailsSaga } from './setOrderDetailsSaga';

export function* orderSagas() {
  yield fork(getOrderDetailsSaga);
  yield fork(initPaymentSessionSaga);
  yield fork(setOrderDetailsSaga);
}
