import { call, put, take, select } from 'redux-saga/effects';
import { PayloadAction } from 'redux-starter-kit';
import { setOnboardingFlow, getOnboardingFlow, setFormDataOnboardingFlow } from 'legacy/api';
import { actions } from 'legacy/store/consumer';
import { actions as OrderActions } from 'legacy/store/order';
import { actions as UIActions } from 'legacy/store/UI';
import { actions as ConsumerActions } from 'legacy/store/consumer';
import { OnboardingFlowAPIData } from 'legacy/types/Consumer';
import { handleFlowErrors, resetErrors } from './errors';
import { State } from 'legacy/store/state';
import { Analytics } from 'legacy/utils/Analytics';
import { getAnalyticsSizeRange } from 'legacy/utils/OCR';
import lean from 'legacy/helpers/switchFlow/flows/lean';
import { store } from 'legacy/store/store';
import { AuthService } from 'legacy/utils/AuthService';

export function* commitFlowSaga(): any {
  while (true) {
    const { payload: data }: PayloadAction<OnboardingFlowAPIData> = yield take(actions.commitFlow);

    const state: State = yield select();

    try {
      // Check if the order is expired, If it is, Show order expired message
      if (
        state.order.orderDetails?.expirationTime &&
        state.order.orderDetails.expirationTime < Date.now()
      ) {
        yield put(
          OrderActions.setOrderDetails({
            ...state.order.orderDetails,
            orderExpired: true,
          })
        );

        return;
      }

      // If we don't have the type of the order, get the order details
      if (!state.order.orderDetails?.type) {
        yield put(OrderActions.tryToGetOrderDetails(state.order.orderDetails?.id));
      }

      yield put(actions.setOnboardingPending(true));

      let flow;

      // It's OCR step. Refactor if it's needed for another steps.
      if (data.profile.image && data.profile.side) {
        try {
          flow = yield call(setFormDataOnboardingFlow, data);
        } catch (error) {
          Analytics.sendEvent(`Onboarding - OCR - ${data.profile.side} side upload failed`, {
            fileType: data.profile.image.name
              .split('.')
              .pop()
              ?.toLowerCase(),
            fileSize: getAnalyticsSizeRange(data.profile.image),
            imageWidth: (data as any).profile.imageDimensions.width,
            imageHeight: (data as any).profile.imageDimensions.height,
            error: (error as any)?.message,
          });

          yield put(UIActions.setOCRCardImageLoading({ side: data.profile.side, loading: false }));

          throw error;
        }

        Analytics.sendEvent(`Onboarding - OCR - ${data.profile.side} side upload succeed`, {
          fileType: data.profile.image.name
            .split('.')
            .pop()
            ?.toLowerCase(),
          fileSize: getAnalyticsSizeRange(data.profile.image),
          imageWidth: (data as any).profile.imageDimensions.width,
          imageHeight: (data as any).profile.imageDimensions.height,
        });

        yield put(UIActions.setOCRCardImageLoading({ side: data.profile.side, loading: false }));
      }
      // It's not the OCR step
      else {
        flow = yield call(setOnboardingFlow, data);
      }

      if (flow.data?.accessToken) {
        AuthService.setToken(flow.data?.accessToken);
        yield put(ConsumerActions.requestConsumerInfo());
      }

      const currentOffset = data.query.offset;
      let nextOffset = currentOffset;

      // we are on step back, ask BE for next step
      if (currentOffset && currentOffset < 0) {
        nextOffset = currentOffset + 1;
        flow = yield call(getOnboardingFlow, {
          profile: data.profile,
          query: { ...data.query, offset: nextOffset },
        });
      }

      // empty "next" means all steps are done
      if (flow && !flow.next) {
        flow.next = lean(store.getState()) ? 'none' : 'Success';
      }

      yield put(actions.setOnboardingPending(false));
      if (data.profile.doNotSetFlow !== true) {
        yield put(actions.setFlow({ ...flow, offset: nextOffset, refresh: false }));
        yield call(resetErrors);
      }

      // Analytics event
    } catch (err) {
      Analytics.sendEvent(`Onboarding - ${data.step} - Invalid`);
      yield call(handleFlowErrors, err);
      yield put(actions.setOnboardingPending(false));
    }
  }
}
