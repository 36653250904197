import React, { useEffect } from 'react';
import styled from 'styled-components';
import PhoneNumberForm from './PhoneNumberForm';
import { ORDER_TYPE_INSTALLMENTS } from 'constants/index';
// import { Analytics } from 'legacy/utils/Analytics';
import Text from 'components/Text';
import Error from 'apps/UXFlow/components/Error';
import { PhoneNumberType } from '../../types';

const PriceContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
`;

const CenterContainer = styled('div')`
  display: flex;
  justify-content: center;
`;

const TitleContainer = styled('div')`
  margin-bottom: 15px;
`;

const SubtitleContainer = styled('div')`
  margin-bottom: 40px;
`;

const PhoneNumberPage: React.FC<PhoneNumberType> = (props: PhoneNumberType) => {
  const { data, submitHandler, isPendingState } = props;

  useEffect(() => {
    // Analytics.sendEvent('LandingPageVisitedNonLoggedIn');
  }, []);

  if (!data) return null;

  const isInstallmentsOrder = data.type === ORDER_TYPE_INSTALLMENTS;

  return (
    <>
      <TitleContainer>
        <CenterContainer>
          <Text messageId="paymentSummary" type="subtitle" />
        </CenterContainer>
      </TitleContainer>
      <Error />
      <SubtitleContainer>
        <PriceContainer>
          <Text messageId="total" type="title" />
          <Text messageId="totalAmount" type="title" className="red" />
        </PriceContainer>
        {isInstallmentsOrder && (
          <PriceContainer>
            <Text messageId="paymentDetails" type="subtitle" />
            <Text messageId="preMonthAmount" type="subtitle" className="bold" />
          </PriceContainer>
        )}
      </SubtitleContainer>
      <PhoneNumberForm submitHandler={submitHandler} isPendingState={isPendingState} data={data} />
    </>
  );
};

export default PhoneNumberPage;
