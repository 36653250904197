import { notify } from 'legacy/helpers/notify';
import { history } from 'legacy/routing/history';
import { AuthService } from 'legacy/utils/AuthService';
import { SessionStorageManager } from 'legacy/utils/SessionStorageManager';
import { FLOW, StaticPages, WELCOME } from 'legacy/constants/pathnames';
import { NOOSA_SESSION_ID } from 'legacy/constants/storageKeys';
import { Logger } from 'legacy/utils/Logger';
import { cleanOrderData } from 'legacy/helpers/cleanOrderData';
import { errorsWithToasts } from 'legacy/constants/errorsWithToasts';
import { getCurrentLanguage } from 'legacy/helpers/language';
import { NPG_APP_VER } from 'constants/index';

import * as Sentry from '@sentry/react';

function parseData(data: any) {
  if (typeof data === 'object') {
    return data;
  }

  return {
    data,
  };
}

const onlyDebugErrorMessages = [
  'Authorization Required',
  'Transaction Expired',
  'Order is expired',
  'Wrong OTP',
];

function addToNetworkHistory(msg: string, data: any) {
  Sentry.addBreadcrumb({
    category: 'network',
    message: msg,
    data: parseData(data),
    level: Sentry.Severity.Debug,
  });
}

const axiosDefaultRequestInterceptor = [
  (config: any) => {
    addToNetworkHistory(`[Request] [${config.method}] ${config.url}`, config.data);

    config.headers.src = 'web';
    config.headers.language = getCurrentLanguage();
    config.headers['noosa-session-id'] = SessionStorageManager.getValue(NOOSA_SESSION_ID);

    // This is used to bypass the maintenance mode on BE.
    config.headers['noosa-backdoor-key'] = localStorage.getItem('mm_backdoor_key');
    const authToken = AuthService.getToken();
    if (authToken) {
      config.headers['Authorization'] = authToken;
    }

    if (NPG_APP_VER) {
      config.headers['x-npg-build'] = NPG_APP_VER;
    }

    return config;
  },
  (error: any) => {
    addToNetworkHistory(`[Error] [Request]`, error);

    return Promise.reject(error);
  },
];

const axiosDefaultResponseInterceptor = [
  (res: any) => {
    addToNetworkHistory(`[Response (${res.status})] ${res.config.url}`, res.data);

    SessionStorageManager.setValue(NOOSA_SESSION_ID, res.headers['noosa-session-id']);

    return res.data;
  },
  (err: any) => {
    addToNetworkHistory(`[Error] [Response]`, err);

    if (err.message === 'Network Error') {
      notify('error', 'network-error');
      return;
    }

    let { error, errors } = err.response.data;
    if (!error) {
      error = {
        statusCode: err?.response?.status || 'unknown',
        message: 'unknown',
        errors,
      };
    }

    if (onlyDebugErrorMessages.includes(error.message)) {
      Logger.log(`[ONLY DEBUG] Error: ${error.statusCode} ${error.message}`);
    } else {
      const fn =
        typeof error.statusCode != 'number' || error.statusCode < 500 ? Logger.info : Logger.error;
      fn(`HTTP Error: ${error.statusCode} ${error.message}`);
    }

    switch (error.message) {
      case 'Transaction Expired':
        cleanOrderData();
        history.push(WELCOME);
        break;
      case 'Authorization Required':
        AuthService.removeToken();
        if (!StaticPages.includes(history.location.pathname)) {
          history.push(FLOW);
        }
        break;
    }

    const errorItem = errorsWithToasts.find(item => item.message === error.message);
    if (errorItem) {
      notify('error', errorItem.toastMsgId);
    }

    return Promise.reject(error);
  },
];

export { axiosDefaultRequestInterceptor, axiosDefaultResponseInterceptor };
