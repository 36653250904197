import { Route } from 'legacy/DFE/routing/RouterTypes';
import AddCardComponent from './index.component';

export default class AddCard extends Route {
  constructor() {
    super('AddCard', '/addCard');
  }

  //TODO Set precondition

  component = AddCardComponent;
}
