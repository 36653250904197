import React from 'react';
import styled from 'styled-components';
import { InputValues } from '../types';
import Text from 'components/Text';

const OptionsWrapper = styled('div')`
  display: flex;
  @media (max-width: 420px) {
    flex-direction: column;
  }
`;

const Option = styled('div')`
  @media (max-width: 420px) {
    margin-top: 4px;
  }
`;

const DisagreeInput = styled('input')`
  @media (min-width: 421px) {
    margin-left: 10px;
  }
`;

const Label = styled('label')`
  margin-left: 2px;
  font-weight: bold;
`;

const LeftSpace = styled('span')`
  margin-left: 5px;
`;

const RightPadding = styled('span')`
  padding-right: 10px;
`;

type Props = {
  agreeText?: string;
  disagreeText?: string;
  disagreeClientText?: string;
  checkboxPrefix?: string;
  name: string;
  error: boolean;
  checkedValue: keyof typeof InputValues | undefined;
  onChange: (name: string, value: keyof typeof InputValues) => void;
};

const SectionInputs: React.FC<Props> = (props: Props) => {
  const {
    agreeText,
    disagreeText,
    disagreeClientText,
    checkboxPrefix,
    name,
    error,
    checkedValue,
    onChange,
  } = props;

  const onClickRadio = (value: keyof typeof InputValues) => {
    return (event: React.ChangeEvent): void => {
      onChange(name, value);
    };
  };

  let disagree = disagreeClientText && (
    <Option>
      <DisagreeInput
        onChange={onClickRadio(InputValues.clientDisagree)}
        className="agree_disagree"
        type="radio"
        checked={checkedValue === InputValues.clientDisagree}
        id={`disagree_${name}`}
        name={name}
        value={InputValues.clientDisagree}
      />
      <Label htmlFor={`disagree_${name}`}> {disagreeClientText} </Label>
    </Option>
  );
  // Create a disagree checkbox if there should be one
  disagree = disagreeText ? (
    <Option>
      <DisagreeInput
        onChange={onClickRadio(InputValues.disagree)}
        checked={checkedValue === InputValues.disagree}
        className="agree_disagree"
        type="radio"
        id={`disagree_${name}`}
        name={name}
        value={InputValues.disagree}
      />
      <Label htmlFor={`disagree_${name}`}> {disagreeText} </Label>
    </Option>
  ) : (
    disagree
  );

  const agree = (
    <Option>
      <input
        onChange={onClickRadio(InputValues.agree)}
        checked={checkedValue === InputValues.agree}
        className="agree_disagree"
        type="radio"
        id={`agree_${name}`}
        name={name}
        value={InputValues.agree}
      />
      <Label htmlFor={`agree_${name}`}> {agreeText} </Label>
    </Option>
  );

  return (
    <OptionsWrapper id={name}>
      {checkboxPrefix && <RightPadding dangerouslySetInnerHTML={{ __html: checkboxPrefix }} />}
      {agree}
      {disagree}
      {error && (
        <LeftSpace>
          <Text messageId="checkAllBoxes" className="red" />
        </LeftSpace>
      )}
    </OptionsWrapper>
  );
};

export default SectionInputs;
