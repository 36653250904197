import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Route, RouteProps, RouteComponentProps } from 'react-router-dom';
import { actions } from 'legacy/store/UI';
import { Analytics } from 'legacy/utils/Analytics';

const mapDispatchToProps = {
  setCurrentPage: actions.setCurrentPage,
};

const connector = connect(undefined, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface ProtectedRouteProps extends RouteProps {
  component: React.ComponentType<Pick<any, string>>;
}

const PublicRoute: React.FC<ProtectedRouteProps & PropsFromRedux> = ({
  component: Component,
  setCurrentPage,
  ...rest
}: (ProtectedRouteProps & PropsFromRedux)) => {

  useEffect(() => {
      setCurrentPage(`${rest.path}`);
      Analytics.sendEvent(`${rest.path} visited`);
  }, [rest.path]);

  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps<any>): React.ReactNode => (
        <Component {...props} />
      )}
    />
  );
};

export default connector(PublicRoute);
