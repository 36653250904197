import React, { useState } from 'react';
import styled from 'styled-components';
import FormattedMessage from 'legacy/components/FormattedMessage';
import icon from 'legacy/assets/warning.png';
import Button from 'legacy/components/Button';
import BoxWithBackground from 'legacy/components/BoxWithBackground';
import { connect } from 'react-redux';
import { State } from 'legacy/store/state';
import { Analytics } from 'legacy/utils/Analytics';
import { AuthService } from 'legacy/utils/AuthService';
import { useEffect } from 'react';

const ImageWrapper = styled('footer')`
  text-align: center;
  margin-bottom: 10px;
`;

const mapStateToProps = (state: State) => ({
  merchantName: state.order.orderDetails?.merchantName,
  failureURL: state.order.orderDetails?.failureURL,
});

const connector = connect(mapStateToProps);
const PaymentExpired = (props: any) => {
  const { merchantName, failureURL } = props;

  const [isRedirectionStarted, setIsRedirectionStarted] = useState<boolean>(false);

  const handleGoToMerchant = (): void => {
    if (isRedirectionStarted) return;

    setIsRedirectionStarted(true);

    Analytics.sendEvent('Payment expired screen - Redirect to merchant site - clicked');

    AuthService.handleRedirectionToMerchant(failureURL);
  };
  useEffect(() => {
    Analytics.sendEvent("SessionExpiredPageVisited")
  },[])

  return (
    <BoxWithBackground>
      <ImageWrapper>
        <img src={icon} alt="error image" width="100" height="100" />
      </ImageWrapper>{' '}
      <br />
      <FormattedMessage
        messageId="errors:session-expired-title"
        className="fs-26 mb-20 bold"
      />{' '}
      <br />
      <FormattedMessage messageId="errors:session-expired-body" className="fs-20" />
      <br />
      <Button
        clickHandler={handleGoToMerchant}
        messageId="common:return-to-merchant"
        params={{ merchantName }}
        isCentered={true}
      />
    </BoxWithBackground>
  );
};

export default connector(PaymentExpired);
