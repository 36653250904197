import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import cancelIconData from 'legacy/DFE/assets/icons/cancel.png';
import styled from 'styled-components';

const CancelIcon = styled('img')`
  cursor: pointer;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 25px;
  right: 25px;
`;

//TODO Really cancel everything on click
const mapDispatchToProps = {};

const connector = connect(undefined, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const CancelButton: React.FC<PropsFromRedux> = (props: PropsFromRedux) => {
  return (
    <>
      <CancelIcon src={cancelIconData} />
    </>
  );
};

export default CancelButton;
