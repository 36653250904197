import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import OptionItem from './Option';
import Vector from 'assets/vector.svg';
import Checked from 'assets/checked.svg';
import { Placeholder, PlaceholderText } from 'components/InputForm/DE/input.styles';
import { Wrapper } from '../index.styles';
import { Option } from 'types/address';

const StyledInput = styled('input')`
  width: 100%;
  height: 50px;
  border: 1px solid #a5a5a5;
  padding: 15px 24px 0px 24px;
  font-weight: 400;
  font-size: 16px;

  :focus {
    border: solid 1px #000000;
    outline: none;
  }
`;

const DropDown = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 215px;
  box-shadow: 0px 10px 30px rgb(0 0 0 / 10%);
  background: #ffffff;
  position: absolute;
  top: 54px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  z-index: 1;
`;

const VectorIcon = styled('img')`
  height: 14px;
  position: absolute;
  right: 22px;
  top: 20px;
  transform: rotate(90deg);
`;

const CheckedIcon = styled('img')`
  height: 25px;
  position: absolute;
  right: 13px;
  top: 13px;
`;

interface Props {
  value: string;
  onChange: (value: string) => void;
  onChooseOption: (option: Option) => void;
  options: Option[];
  preciseEnough: boolean;
  isSelected?: boolean;
  placeholderMessageId?: string;
  alwaysShowCheck?: boolean;
}

const Selector = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { options, isSelected, onChange, value, placeholderMessageId, onChooseOption, preciseEnough, alwaysShowCheck } = props;
  const [isFocused, setIsFocused] = useState<boolean>(true);

  const onFocus = (): void => setIsFocused(true);
  const onBlur = (): void => setIsFocused(false);

  const handelChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(e.target.value);
  };

  useEffect(() => {
    if (!preciseEnough && ref) {
      (ref as any).current.focus();
      setIsFocused(true);
    }
  });

  return (
    <Wrapper>
      {placeholderMessageId && (
        <Placeholder>
          <PlaceholderText messageId={placeholderMessageId} type="label"/>
        </Placeholder>
      )}

      <StyledInput
        ref={ref}
        value={value}
        onChange={handelChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />

      {!isSelected && !isFocused && <VectorIcon src={Vector} />}
      {isSelected && (preciseEnough || alwaysShowCheck) && <CheckedIcon src={Checked} />}

      {isFocused && (
        <DropDown>
          {options.map(option => {
            return (
              <OptionItem text={option.text} key={option.context} onMouseDown={() => onChooseOption(option)} />
            );
          })}
        </DropDown>
      )}
    </Wrapper>
  );
});

Selector.displayName = 'Selector';

export default Selector;
