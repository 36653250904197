import { take, call, select, put } from 'redux-saga/effects';
import { sendRecoverOtp } from 'legacy/api';
import { selectors, actions } from 'legacy/store/login';
import { history } from 'legacy/routing/history';
import { RECOVER_PIN } from 'legacy/constants/pathnames';
import { RecoverPinStatus } from 'legacy/types/RecoverPinStatus';
import { Analytics } from 'legacy/utils/Analytics';

export function* requestRecoverPinSaga() {
  while (true) {
    yield take(actions.recoverPinCode);
    yield call(Analytics.sendEvent, 'PinCodeRecovery');

    try {
      const { mobile } = yield select(selectors.userSelector);
      yield call(sendRecoverOtp, mobile);

      // call success handlers
      yield put(actions.successWhileRecoverPinCode());
      yield put(actions.setStatus(RecoverPinStatus.otp));
      history.push(RECOVER_PIN);
    } catch (err) {
      yield put(actions.errorWhileRecoverPinCode());
    }
  }
}
