import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import { getCurrentLanguage } from 'legacy/helpers/language';

const INITIAL_LANGUAGE = getCurrentLanguage();

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    defaultNS: 'common',
    ns: ['common', 'onboarding', 'payment', 'faq', 'terms-conditions', 'errors', 'change-card'],
    backend: {
      loadPath: 'locales/{{lng}}/{{ns}}.json',
    },
    fallbackLng: INITIAL_LANGUAGE,
    debug: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      wait: true,
      useSuspense: false,
    },
  });

export default i18n;
