import React from 'react';
import Spinner from 'react-loader-spinner';
import styled from 'styled-components';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const LoaderWrapper = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 999;
`;

const Centered = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Loader: React.SFC = () => {
  return (
    <LoaderWrapper>
      <Centered>
        <Spinner type="TailSpin" color="#00BFFF" height={100} width={100} />
      </Centered>
    </LoaderWrapper>
  );
};

export default Loader;
