import React from 'react';
import { EnvBannerWrapperStyle, EnvBannerStyle } from './envBanner.styles';

const EnvBanner: React.SFC = () => (
  <EnvBannerWrapperStyle>
    <EnvBannerStyle>{process.env.REACT_APP_BUILD_ENVIRONMENT}</EnvBannerStyle>
  </EnvBannerWrapperStyle>
);

export default EnvBanner;
