import { OrderAPI } from "legacy/DFE/api/handlers";
import { Route } from "legacy/DFE/routing/RouterTypes";
import { StorageManager } from "legacy/DFE/utils/storage";
import { actions as OrderActions, selectors } from "legacy/DFE/store/order";
import { selectors as RouterSelectors } from "legacy/DFE/store/router";
import { State } from "legacy/DFE/store/state";
import { store } from "legacy/DFE/store/store";
import LandingComponent from "./index.component";
import { actions as AuthActions } from "legacy/DFE/store/auth";

export default class Landing extends Route {
  constructor() {
    super('Landing', '/');
  }

  async precondition(state: State) {
    const storedOrderId = StorageManager.get("orderId");
    const currentPage = RouterSelectors.currentPage(state);

    if (!currentPage.startsWith("/landing") && currentPage !== "/") {
      // We also have to find some data here, in case we're on onboarding or something
      if (!selectors.orderId(state)) {
        store.dispatch(OrderActions.setOrderId(storedOrderId));

        const landingDetails = (await OrderAPI.initLandingDetails(storedOrderId));

        store.dispatch(OrderActions.setOrder(landingDetails));
      }
      return false;
    }

    // TODO: fix the bug, when orderId = route.name
    const orderIdFromURL = currentPage.replace(/(\/landing)?(\/)?/, "");

    if (orderIdFromURL) {
      store.dispatch(AuthActions.setAuthToken(""));
    }

    const orderId = orderIdFromURL || storedOrderId;

    if (!orderId) {
      return false;
    }

    store.dispatch(OrderActions.setOrderId(orderId));
    StorageManager.set("orderId", orderId);

    const paymentValid = (await OrderAPI.initPaymentSession(orderId)).result;

    if (!paymentValid) {
      store.dispatch(OrderActions.setOrderId(""));
      store.dispatch(AuthActions.setAuthToken(""));
      return false;
    }

    const landingDetails = (await OrderAPI.initLandingDetails(orderId));

    store.dispatch(OrderActions.setOrder(landingDetails));

    return paymentValid;
  }

  component = LandingComponent;
}
