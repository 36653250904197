import { createSlice, PayloadAction } from 'redux-starter-kit';
import { Payment } from 'legacy/types/PaymentProcessing';
import { initialState, PaymentFlowState } from './state';

const paymentFlow = createSlice({
  name: 'paymentFlow',
  initialState: initialState,
  reducers: {
    setPayment: (state, { payload: payment }: PayloadAction<Payment | null>): PaymentFlowState => ({
      ...state,
      payment,
    }),
  },
});

export const actions = {
  ...paymentFlow.actions,
};

export const { reducer } = paymentFlow;
