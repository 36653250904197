import styled from 'styled-components';

type AccordionProps = {
  opened: boolean;
};

export const AccordionList = styled('ul')`
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border-radius: 0.4rem;
  padding-right: 20px;
`;

export const AccordionItem = styled('li')<AccordionProps>`
  transition: border 0.5s linear;
  transition-delay: 0.2s;
  border-bottom: 1px solid ${props => (props.opened ? '#e2e2e8' : '#fff')};
`;

export const ItemTitle = styled('span')`
  font-size: 1.14em;
  color: black;
  color: #16142c;
`;

export const AccordionLine = styled('div')`
  padding: 20px 10px;
  padding-right: 40px;
  position: relative;
  border-bottom: 1px solid #e2e2e8;
`;

export const AccordionIcon = styled('span')<AccordionProps>`
  position: absolute;
  right: 20px;
  top: 10px;

  &&:after {  
    content: '${props => (props.opened ? '–' : '+')}';
    font-size: 30px;
    color: #da291c;
    transition: transform .5s linear;
    display: inline-block;
    transform: rotate(${props => (props.opened ? '180deg' : '90deg')});
  }
`;

export const AccordionItemInner = styled('div')<AccordionProps>`
  max-height: ${props => (props.opened ? '100rem' : '0')};
  overflow: hidden;
  transition-timing-function: linear;
  transition-duration: 0.7s;
  transition-property: max-height;
  position: relative;
`;

export const AccordionItemContent = styled('div')<AccordionProps>`
  opacity: ${props => (props.opened ? 1 : 0)};
  padding: 10px;
  padding-bottom: 25px;
  transform: translateY(${props => (props.opened ? '0' : '-1rem')});
  transition-timing-function: ${props => (props.opened ? 'ease-in-out' : 'linear, ease')};
  transition-duration: 0.5s;
  transition-delay: 0.5s;
  transition-property: opacity, transform;
`;

export const AccordionParagraph = styled('p')`
  margin: 0;
  font-size: 1rem;
  color: #8683a4;
  font-weight: 300;
  line-height: 1.3;

  p {
    margin-bottom: 10px;
  }

  a {
    cursor: pointer;
    text-decoration: underline;
    color: #8683a4;
  }
`;
