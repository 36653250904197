import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import useEventListener from 'apps/UXFlow/utils/hooks/useEventListener';
import flow from 'apps/UXFlow/store/flow';
import InputForm from 'components/InputForm';
import Text from 'components/Text';
import PaymentPlan from 'components/PaymentPlan';
import TC from 'components/TermsAndConditions';
import PaymentPriceBox from 'components/PaymentPriceBox';
import { PhoneNumberType } from '../../types';
import PageContainer from 'apps/UXFlow/components/PageContainer/DE';
import { AppProps } from 'types/apps';
import ScrollablePopover from 'components/ScrollablePopover';
import { Analytics } from 'utils/Analytics';
import { InputsDataService } from 'services/InputsDataService';

type Payment = {
  amount: string;
  date: string;
};

type PaymentPlans = {
  installmentsPlan: Array<Payment>;
  id: number;
};

const MobileInputForm = styled(InputForm)`
  margin-top: 10px;

  input {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    padding-left: 0;
    padding-top: 0;
    text-align: center;
    min-height: 50px;
  }
`;

const TCContainer = styled('div')`
  flex-grow: 1;
  --position: absolute;
  bottom: 10px;

  #terms-conditions-link,
  #marni-privacy-policy-link,
  #compass-privacy-policy-link,
  #crif-privacy-policy-link,
  #legal-popup-link,
  #legal-popup-link-alt {
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }
`;

const ContentContainer = styled('div')`
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0px 30px 0 30px;
`;

const FormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 360px;
  height: calc(100vh - 400px);
  position: relative;

  @media (max-height: 700px) {
    height: calc(100vh - 280px);
  }
`;

const GERMAN_PREFIX = '+49';
const DEFAULT_MAX_PHONE_NUMBER = 19;
const DEFAULT_MIN_PHONE_LENGTH = 13;

const prePopulateNumber = (number: string): string => {
  if (number.slice(0, 1) === '+') {
    return `${number.slice(0, 3)} ${number.slice(3, number.length)}`;
  }

  if (number.slice(0, 2) === '49') {
    return `+${number.slice(0, 2)} ${number.slice(2, number.length)}`;
  }

  if (number.slice(0, 2) === '01') {
    return `${GERMAN_PREFIX} ${number.slice(1, number.length)}`;
  }

  // rare, but possible case
  if (number.slice(0, 4) === '0049') {
    return `${GERMAN_PREFIX} ${number.slice(4, number.length)}`;
  }

  return `${GERMAN_PREFIX} ${number}`;
};

const addSpaceToNumber = (number: string): string => {
  if (number.slice(0, 3) === GERMAN_PREFIX && number.length > GERMAN_PREFIX.length) {
    return `${number.slice(0, 3)} ${number.slice(3, number.length)}`;
  }

  return number;
};

const isNumberedString = (str: string): boolean => {
  return !!str.match('^[0-9]+$|^$');
};

const PhoneNumberForm: React.FC<PhoneNumberType & AppProps> = (
  props: PhoneNumberType & AppProps
) => {
  const { data, submitHandler, isPendingState } = props;
  const { errors } = flow;

  const uiPaymentPlans = data?.uiPaymentPlans as Array<PaymentPlans>;
  const maxPhoneNumberChars: number = data?.maxPhoneNumberChars || DEFAULT_MAX_PHONE_NUMBER;

  const [legalPopupOpen, setLegalPopupOpen] = useState<boolean>(false);

  const [phoneNumber, setPhoneNumber] = useState<string>(() => {
    return prePopulateNumber(data?.phoneNumber || '');
  });

  const submitFormHandler = (): void => {
    const phoneNumberSplit = phoneNumber.split(' ');
    if (phoneNumberSplit[1]) {
      phoneNumberSplit[1] = `${+phoneNumberSplit[1]}`
    }
    InputsDataService.save('landing_phone_number', phoneNumberSplit.join(' '));
    const phoneWithOutSpaces = phoneNumberSplit.join('');
    submitHandler && submitHandler(phoneWithOutSpaces);
  };

  useEffect(() => {
    changePhoneNumber(phoneNumber);
  }, []);

  const changePhoneNumber = (value: string): void => {
    const valueWithOutSpaces = value.split(' ').join('');
    const firstSymbolIsPlus = valueWithOutSpaces.slice(0, 1) === '+' || !value.length;

    if (value.length > maxPhoneNumberChars && phoneNumber.length < value.length) {
      return;
    }

    if (!firstSymbolIsPlus && isNumberedString(valueWithOutSpaces)) {
      setPhoneNumber(addSpaceToNumber(`+${valueWithOutSpaces}`));
    }

    if (firstSymbolIsPlus && isNumberedString(valueWithOutSpaces.slice(1))) {
      setPhoneNumber(addSpaceToNumber(valueWithOutSpaces));
    }
  };

  useEventListener('keypress', submitFormHandler);

  const targetToLinkMapping = {
    ['terms-conditions-link']: 'termsOfUse',
    ['marni-privacy-policy-link']: 'privacy',
    ['compass-privacy-policy-link']: 'compassPrivacy',
    ['crif-privacy-policy-link']: 'crifPrivacy',
  };

  const openLegalDocument = (e: any) => {
    if (e?.target?.id) {
      const targetId = e?.target?.id;
      let content;

      if (targetId === 'legal-popup-link') {
        setLegalPopupOpen(true);
        Analytics.sendEvent('Landing page- Privacy statement clicked');
        return;
      }

      if (targetId === 'legal-popup-link-alt') {
        Analytics.sendEvent('Landing page- Legal statement clicked');
        setLegalPopupOpen(true);
        return;
      }

      if (targetId === 'marni-privacy-policy-link') {
        Analytics.sendEvent('Landing page- Marni legal statement clicked');
      }

      if (targetId === 'crif-privacy-policy-link') {
        Analytics.sendEvent('Landing page- CRIF legal statement clicked');
      }

      if (targetId === 'compass-privacy-policy-link') {
        Analytics.sendEvent('Landing page- Compass legal statement clicked');
      }

      if (!!targetToLinkMapping[targetId]) {
        content = flow.data?.termsOfUse.find(
          (term: any): boolean => term.name === targetToLinkMapping[targetId]
        )?.content;
        Analytics.sendEvent(`Landing page- ${targetToLinkMapping[targetId]} legal statement clicked`);
        
      }

      if (content) {
        props.setComponentOnDemand('LegalDocuments', {
          data: { content, messageId: targetToLinkMapping[targetId] },
        });
      }
    }
  };

  return (
    <PageContainer
      titleMessageId="title"
      buttonMessageId="next"
      buttonOnClick={submitFormHandler}
      buttonIsLoading={isPendingState}
      buttonDisabled={phoneNumber.length < DEFAULT_MIN_PHONE_LENGTH}
      errorContent={errors['phoneNumber']?.message}
      goBack={props.goBack}
      backBtnEnabled={props.backBtnEnabled}
      onClose={props.onClose}
    >
      <PaymentPriceBox
        totalAmount="totalAmount"
        paymentDetails="paymentDetails"
        preMonthAmount="preMonthAmount"
      />

      <FormContainer>
        <ContentContainer>
          {uiPaymentPlans?.map(item => {
            return <PaymentPlan payments={[...item.installmentsPlan]} key={item.id} />;
          })}
        </ContentContainer>

        <Text messageId="enterPhoneNumber" type="subtitle" />

        <MobileInputForm
          type="tel"
          id="landing_phone_number"
          placeholderMessageId="numberPlaceholder"
          value={phoneNumber}
          changeHandler={changePhoneNumber}
          textPlaceholder={GERMAN_PREFIX}
          fullWidth
        />

        <TCContainer>
          <TC onClick={openLegalDocument} />
          {legalPopupOpen && (
            <ScrollablePopover
              messageId="DEMobilePagePopupLegalText"
              close={() => setLegalPopupOpen(false)}
              onClick={openLegalDocument}
            />
          )}
        </TCContainer>
      </FormContainer>
    </PageContainer>
  );
};

export default PhoneNumberForm;
