import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';
import queryString from 'query-string';
import { actions } from 'legacy/store/UI';
import { State } from 'legacy/store/state';
import { selectors, actions as consumerActions } from 'legacy/store/consumer';
import { selectors as OrderSelectors } from 'legacy/store/order';
import FlexColumn from 'legacy/components/FlexColumn';
import FormattedMessage from 'legacy/components/FormattedMessage';
import { notify } from 'legacy/helpers/notify';
import { EcpSuccess } from 'legacy/types/CardType';
import { TextStorageManager } from 'legacy/utils/TextStorageManager';
import cardLoader from 'legacy/assets/card-loader.gif';
import { Analytics } from 'legacy/utils/Analytics';

// TODO: check with product the UI issue on mobile (can't reproduce, it's scrollable)
const IFrameWrapper = styled('div')`
  text-align: center;
  height: 470px;
  min-height: 470px;
  min-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;

  iframe {
    height: 470px;
    min-height: 470px;
    min-width: 400px;
  }
`;

// const IFrameWrapper = styled('div')`
//   text-align: center;

//   iframe {
//     height: 100%;
//     min-height: 450px;
//     min-width: 400px;
//   }
// `;

const LoaderWrapper = styled('div')`

`;

type PostedMessage = {
  data: string;
};

const mapStateToProps = (state: State) => ({
  onBoardingStatus: selectors.onBoardingStatusSelector(state),
  order: OrderSelectors.orderDetailsSelector(state)
});

const mapDispatchToProps = {
  closePopup: actions.closePopup,
  setRefreshOnClosePopup: actions.setRefreshOnClosePopup,
  commitCardFlow: consumerActions.commitCardFlow,
  setFlow: consumerActions.setFlow,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type CardDetailsPopupType = {
  onSubmit?: () => void;
} & PropsFromRedux;

const CardDetailsPopup: React.SFC<CardDetailsPopupType> = (props: CardDetailsPopupType) => {
  const {
    onBoardingStatus,
    closePopup,
    commitCardFlow,
    order,
    setFlow,
    onSubmit,
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [, reloadComponent] = useState<any>({});

  let isPollingStarted = false;

  useEffect(() => {
    const handleClosePopup = () => {
      if (!isPollingStarted) {
        setFlow({
          ...onBoardingStatus.flow,
          refresh: true,
        });
      }
    };

    document.addEventListener('closePopup', handleClosePopup, { once: true });
  }, [isPollingStarted]);

  useEffect(() => {
    const receiveMessage = (message: PostedMessage) => {
      const queryParams = queryString.parse(message.data);
      const isECPCardMessage = queryParams.cardType === 'ECP';

      if (isECPCardMessage) {
        const ecpResponse = queryParams.result

        if (!ecpResponse || ecpResponse !== EcpSuccess) {
          isPollingStarted = true;
          closePopup();
          notify('error', `onboarding:adding-card-details-status-01`);
          commitCardFlow({ data: { error: "ecp-error-callback" }, query: { paymentType: order?.type, id: order?.id } });
          Analytics.sendEvent("CardTokenizationFail")
          return;
        }
        isPollingStarted = true;
        closePopup();
        commitCardFlow({ data: { stepId: "postMessageReceived" }, query: { paymentType: order?.type, id: order?.id }, onSubmit });
        Analytics.sendEvent("CardTokenizationSuccseed")
      }
      return;
    };

    window.addEventListener('message', receiveMessage, false);

    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, []);

  const url = onBoardingStatus.flow.data.URL;
  const onLoadHandler = () => {
    setIsLoading(false);
  }

  return (
    <div>
      <FlexColumn>
        <FormattedMessage messageId="onboarding:card-form-title" className="fs-26 mb-10" />
        <FormattedMessage className="fs-14 mb-10 light">{TextStorageManager.get("AddCardScreenVerificationChargeInfo")}</FormattedMessage>
        <FormattedMessage messageId="onboarding:card-form-description-2" className="fs-14 bold" />

      </FlexColumn>
      <IFrameWrapper>
        {isLoading &&
          <LoaderWrapper>
            <img src={cardLoader} width="30px" height="30px"></img>
          </LoaderWrapper>
        }
        <iframe src={url} id="cardDetailsIframe" onLoad={onLoadHandler} title="card-details" style={{ borderStyle: 'none', display: isLoading ? 'none' : 'block' }} />
      </IFrameWrapper>
    </div>
  );
};

export default connector(CardDetailsPopup);
