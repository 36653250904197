import React from 'react';
import Contract from './Contract';
import styled from 'styled-components';
import { AppComponentProps } from 'types/apps';
import { ParentProps } from '../types';
import Error from 'apps/UXFlow/components/Error';
import useSubmitHandler from 'apps/UXFlow/utils/hooks/useSubmitHandler';
import Text from 'components/Text';

const MarginBottom = styled('div')`
  margin-bottom: 10px;
`;

const TermsOfUsePorting: React.FC<AppComponentProps & ParentProps> = (
  props: AppComponentProps & ParentProps
) => {
  const { onSubmit, loading } = useSubmitHandler(props);

  return (
    <>
      <MarginBottom>
        <Text messageId="title" type={'title'} />
      </MarginBottom>
      <Error />
      <Contract submitHandler={onSubmit} isPendingState={loading} />
    </>
  );
};

export default TermsOfUsePorting;
