import { take, call } from 'redux-saga/effects';
import { actions } from 'legacy/store/UI';
import { getText } from 'legacy/api';
import { LocalStorageManager } from 'legacy/utils/LocalStorageManager';
import { ORDER_ID_KEY, TEXT_KEY } from 'legacy/constants/storageKeys';

export function* getTextSaga(): any {
  while (true) {
    yield take(actions.getText);

    const paymentRequestId = LocalStorageManager.getItem(ORDER_ID_KEY);

    try {
        const data = yield call(getText, paymentRequestId);
        LocalStorageManager.setItem(TEXT_KEY, data.text);
    } catch (err) {
        LocalStorageManager.setItem(TEXT_KEY, {});
    }
  }
}
