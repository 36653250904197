import React from 'react';
import FormattedMessage from 'legacy/components/FormattedMessage';
import FlexColumn from 'legacy/components/FlexColumn';

const PleaseWaitPopup: React.SFC = () => {
  return (
    <FlexColumn>
      <FormattedMessage messageId="onboarding:pleasewait" className="fs-26 mb-10" />
    </FlexColumn>
  );
};

export default PleaseWaitPopup;
