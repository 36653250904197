import React, { useState, useEffect } from 'react';
// import { Analytics } from 'legacy/utils/Analytics';
import SelectForm from 'components/SelectForm';
import InputForm from 'components/InputForm';
import Button from 'components/Button';
import italyFlag from 'assets/italy.svg';
import ukraineFlag from 'assets/ukraine.svg';
import israelFlag from 'assets/israel.svg';
import germanFlag from 'assets/german.svg';
import {
  PhoneNumberField,
  CheckboxWrapper,
  SelectOptionWrapper,
  Error,
  CenterContainer,
  TitleContainer,
} from './phoneNumberForm.styles';
import useEventListener from 'apps/UXFlow/utils/hooks/useEventListener';
import CompassText from './CompassText';
import Checkbox from 'components/Checkbox';
import Text from 'components/Text';
import flow from 'apps/UXFlow/store/flow';

const numbersOptions = [
  {
    value: '+380',
    content: (
      <SelectOptionWrapper>
        <img src={ukraineFlag} alt="flag" /> +380
      </SelectOptionWrapper>
    ),
  },
  {
    value: '+39',
    content: (
      <SelectOptionWrapper>
        <img src={italyFlag} alt="flag" /> +39
      </SelectOptionWrapper>
    ),
  },
  {
    value: '+972',
    content: (
      <SelectOptionWrapper>
        <img src={israelFlag} alt="flag" /> +972
      </SelectOptionWrapper>
    ),
  },
  {
    value: '+49',
    content: (
      <SelectOptionWrapper>
        <img src={germanFlag} alt="flag" /> +49
      </SelectOptionWrapper>
    ),
  },
];

const MAX_PHONE_NUMBER_CHARS = 20;

type PhoneNumberFormType = {
  submitHandler?: (phoneNumber: string) => void;
  data?: Record<string, any>;
  isPendingState?: boolean;
};

const PhoneNumberForm: React.FC<PhoneNumberFormType> = (props: PhoneNumberFormType) => {
  const { data, submitHandler, isPendingState } = props;
  const { errors } = flow;

  const mobile = data?.phoneNumber || '';
  const shouldRenderRememberMe = data?.settings?.rememberMe;

  let mobileCode = '+39';
  let mobileNumber = '';

  numbersOptions.forEach(option => {
    const isMatch = mobile.startsWith(option.value, 0);
    if (isMatch) {
      mobileCode = option.value;
    }
    if (isMatch && mobile.length) {
      mobileNumber = mobile.substr(option.value.length, mobile.length - 1);
    }
  });

  const [phoneNumberCode, setPhoneNumberCode] = useState<string>(mobileCode);
  const [phoneNumber, setPhoneNumber] = useState<string>(mobileNumber);
  const [rememberMe, toggleRememberMe] = useState<boolean>(false);

  useEffect(() => {
    // AuthService.setRememberMe(rememberMe);
    // Analytics.sendEvent(`Landing Page - Remember me - ${rememberMe ? 'checked' : 'unchecked'}`);
  }, [rememberMe]);

  const submitFormHandler = (): void => {
    const phone = `${phoneNumberCode}${phoneNumber}`;
    // Analytics.sendEvent('Landing Page - Phone number sent for validation');
    submitHandler && submitHandler(phone);
  };

  const changePhoneNumber = (value: string): void => {
    const maxLength = MAX_PHONE_NUMBER_CHARS - phoneNumberCode.length;
    if (Number.isInteger(+value)) {
      setPhoneNumber(value.slice(0, maxLength));
    }
  };

  useEventListener('keypress', submitFormHandler);

  return (
    <>
      <TitleContainer>
        <CenterContainer>
          <Text messageId="loginTitle" type="subtitle" />
        </CenterContainer>
      </TitleContainer>
      <PhoneNumberField>
        <SelectForm
          isSearchable={false}
          options={numbersOptions}
          onChange={setPhoneNumberCode}
          value={phoneNumberCode}
          maxWidth="115px"
        />
        <InputForm
          type="tel"
          id="landing_phone_number"
          placeholderMessageId="numberPlaceholder"
          value={phoneNumber}
          changeHandler={changePhoneNumber}
          error={errors['phoneNumber']?.message}
        />
      </PhoneNumberField>

      {shouldRenderRememberMe && (
        <CheckboxWrapper>
          <Checkbox
            checked={rememberMe}
            onChange={toggleRememberMe}
            labelMsgId="rememberMe"
            id="rememberMe"
          />
        </CheckboxWrapper>
      )}
      <CompassText />
      <CenterContainer>
        <Button messageId="confirm" onClick={submitFormHandler} isLoading={isPendingState} />
      </CenterContainer>
    </>
  );
};

export default PhoneNumberForm;
