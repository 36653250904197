
import { OnboardingFlow, OnboardingFlowErrors, CardFlow } from 'legacy/types/Consumer';

export interface OnBoardingStatus {
  pending: boolean;
  flow: OnboardingFlow;
  flowErrors: OnboardingFlowErrors;
  cardFlow: CardFlow;
  binStepPresence: boolean;
}

export const initialOnBoardingStatus: OnBoardingStatus = {
  pending: false,
  flow: { next: "", data: {}, nextStepIndex: 0, totalSteps: 0, offset: 0, refresh: true },
  flowErrors: { invalidFields: {}, flowError: "" },
  cardFlow: { next: "", data: {} },
  binStepPresence: false
};
