import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'normalize.css';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { Router as ReactRouter } from 'react-router-dom';

import i18n from '../i18n';
import { store } from 'legacy/store/store';
import { store as DFEStore } from 'legacy/DFE/store/store';
import Root from 'legacy/containers/Root';
import DFERoot from 'legacy/DFE/root';
import ChangeCardRoot from 'legacy/ChangeCardPortal';
import SupportPortalRoot from 'legacy/SupportPortal';
import Welcome from 'legacy/containers/Welcome';
import Maintenance from 'legacy/containers/Maintenance';
import { history } from 'legacy/routing/history';
import { LocalStorageManager } from 'legacy/utils/LocalStorageManager';
import { SessionStorageManager } from 'legacy/utils/SessionStorageManager';

Sentry.init({
  dsn: 'https://6652f13ba3f04f42a38cfb24c2341bb3@o1009322.ingest.sentry.io/5973458',
  tunnel: '/ls/',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  release: process.env.REACT_APP_BUILD_ENVIRONMENT,
  environment: process.env.REACT_APP_BUILD_ENVIRONMENT,
  normalizeDepth: 10,
});

export class AppState {
  constructor() {
    /** */
  }

  public saveOrderId(orderId: string): void {
    LocalStorageManager.setItem('orderId', orderId);
  }

  public saveAuthToken(token: string): void {
    SessionStorageManager.setItem('authToken', token);
  }

  public render(data: any): void {
    if (!data) {
      this.renderWelcomeApp();
      return;
    }

    if (data.changeCardPortal) {
      this.renderChangeCardPortal();
      return;
    }

    if (data.supportPortal) {
      this.renderSupportPortal();
      return;
    }

    if (data.order?.DFEFlowSupport) {
      // Sentry.setContext('State', DFEStore.getState());
      this.renderDFEApp();
    } else {
      // Sentry.setContext('State', store.getState());
      this.renderCompassApp();
    }
    return;
  }

  public renderWelcomeApp(): void {
    ReactDOM.render(<Welcome />, document.getElementById('root'));
  }

  public renderMaintenanceApp(): void {
    ReactDOM.render(<Maintenance />, document.getElementById('root'));
  }

  private renderCompassApp(): void {
    ReactDOM.render(
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <ReactRouter history={history}>
            <Root />
          </ReactRouter>
        </I18nextProvider>
      </Provider>,
      document.getElementById('root')
    );
  }

  private renderDFEApp(): void {
    ReactDOM.render(
      <Provider store={DFEStore}>
        <DFERoot />
      </Provider>,
      document.getElementById('root')
    );
  }

  private renderChangeCardPortal(): void {
    ReactDOM.render(
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <ReactRouter history={history}>
            <ChangeCardRoot />
          </ReactRouter>
        </I18nextProvider>
      </Provider>,
      document.getElementById('root')
    );
  }

  private renderSupportPortal(): void {
    ReactDOM.render(
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <SupportPortalRoot />
        </I18nextProvider>
      </Provider>,
      document.getElementById('root')
    );
  }
}
