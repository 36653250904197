import amplitude from 'amplitude-js';
import { selectors } from 'legacy/DFE/store/router';
import { store } from 'legacy/DFE/store/store';
import Logger from './logger';

export class Analytics {
    static logger: Logger = new Logger('Analytics');

    static initialize(): void {
        const amplitudeApiKey = process.env.REACT_APP_AMPLITUDE_API_KEY || '';
        amplitude.getInstance().init(amplitudeApiKey);
    }

    static identifyUser(userId: string): void {
        if (userId) {
            amplitude.getInstance().identify(new amplitude.Identify().set('ConsumerID', userId))
        }
    }

    static async sendEvent(name: string, params?: any): Promise<void> {
        const state = store.getState();

        const currentPage = selectors.currentPage(state);

        amplitude.getInstance().logEvent(name, { source: "NPG", page: currentPage, ...params });
        this.logger.info(`event '${name}' sent`);
    }
}
