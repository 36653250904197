import { take, put, call } from 'redux-saga/effects';
import { PayloadAction } from 'redux-starter-kit';
import { ORDER_ID_KEY, TRANSACTION_ID_KEY, CANCEL_ID_KEY } from 'legacy/constants/storageKeys';
import { WELCOME, StaticPages } from 'legacy/constants/pathnames';
import { getLandingDetails, getPaymentDetails, getConsumerInfo } from 'legacy/api';
import { LocalStorageManager } from 'legacy/utils/LocalStorageManager';
import { SessionStorageManager } from 'legacy/utils/SessionStorageManager';
import { actions as UIActions } from 'legacy/store/UI';
import { history } from 'legacy/routing/history';
import { notify } from 'legacy/helpers/notify';
import { FAQ } from 'legacy/constants/pathnames';
import { ORDER_AMOUNT } from 'legacy/constants/storageKeys';
import { actions as orderActions } from '../index';
import { AuthService } from 'legacy/utils/AuthService';
import { profileErrors } from 'legacy/utils/ApiErrors';
import { Analytics } from 'legacy/utils/Analytics';

/** handle get order details event */
export function* getOrderDetailsSaga(): any {
  while (true) {
    const { payload: orderIdPathParam }: PayloadAction<string | undefined> = yield take(
      orderActions.tryToGetOrderDetails
    );

    yield put(UIActions.setIsLoading(true));
    yield put(orderActions.setIsOrderLoading(Boolean(true)));

    if (orderIdPathParam) {
      const orderId = orderIdPathParam !== 'undefined' ? orderIdPathParam : null;

      yield call(LocalStorageManager.removeItem, TRANSACTION_ID_KEY);
      yield call(LocalStorageManager.removeItem, CANCEL_ID_KEY);
      yield call(LocalStorageManager.setItem, ORDER_ID_KEY, orderId);
    }

    const isLeanLandingFlow = window.location.pathname.startsWith('/flow');

    if (!isLeanLandingFlow) {
      try {
        yield call(getConsumerInfo);
      } catch (err) {
        if (err && err.statusCode === 401) {
          AuthService.removeToken();
        }
      }
    }

    try {
      const orderId = yield call(LocalStorageManager.getItem, ORDER_ID_KEY);
      if (!orderId) {
        throw new Error('No order');
      }

      const authToken = yield call(AuthService.getToken);

      const { order } = authToken
        ? yield call(getPaymentDetails, orderId)
        : yield call(getLandingDetails, orderId);

      yield put(orderActions.setOrderDetails({ ...order, id: orderId }));

      Analytics.sendEvent('SessionStarted', {
        paymentProgram: order.paymentProgram,
      });

      SessionStorageManager.setValue(ORDER_AMOUNT, order.totalAmount);
    } catch (err) {
      const orderData = err && err.data ? err.data : {};
      yield put(orderActions.setOrderDetails(orderData));

      const { pathname } = history.location;
      if (!StaticPages.includes(pathname)) {
        history.push(WELCOME);
        if (profileErrors[err.message]) {
          notify('error', `errors:${profileErrors[err.message]}`);
        } else {
          notify('error', 'errors:payment-failed');
        }
      }
    } finally {
      yield put(UIActions.setIsLoading(false));
      yield put(orderActions.setIsOrderLoading(Boolean(false)));
    }
  }
}
