import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import cx from 'classnames';
import { ButtonStyled } from './button.styles';
import { Analytics } from 'legacy/utils/Analytics';

export interface ButtonStyledProps {
  secondary?: boolean;
  disableClickHandler?: boolean;
}

interface ButtonProps extends WithTranslation, ButtonStyledProps {
  children?: React.ReactNode | null;
  clickHandler?: (e: React.MouseEvent<HTMLElement>) => void;
  messageId?: string | null;
  isLoading?: boolean;
  isDisabled?: boolean;
  disableClickHandler?: boolean;
  isCentered?: boolean;
  isWordWrap?: boolean;
  className?: string;
  style?: any;
  params?: any;
}

const Button: React.SFC<ButtonProps> = (props: ButtonProps) => {
  const {
    children,
    messageId,
    clickHandler,
    t,
    secondary,
    isLoading,
    isCentered,
    isDisabled,
    isWordWrap,
    className,
    style,
    params,
  } = props;

  const disableClickHandler = props.disableClickHandler === undefined ? props.isDisabled : props.disableClickHandler;

  function innerClickHandler(e: React.MouseEvent<HTMLElement>) {
    Analytics.sendEvent(`Button - ${messageId} - clicked`);
    clickHandler?.(e);
  }

  const name = messageId ? messageId : children;

  return (
    <ButtonStyled
      onClick={innerClickHandler}
      secondary={secondary}
      disableClickHandler={disableClickHandler}
      style={style}
      className={cx(
        {
          'has-loader': isLoading,
          'has-word-wrap': isWordWrap,
          disabled: isDisabled,
          centered: isCentered,
        },
        className
      )}
      id={cx(name)}
    >
      {children}
      {messageId && t(messageId, {...params})}
    </ButtonStyled>
  );
};

Button.defaultProps = {
  children: null,
  clickHandler: () => null,
  secondary: false,
  messageId: null,
  isLoading: false,
  isCentered: false,
  isDisabled: false,
  isWordWrap: false,
  disableClickHandler: undefined,
  style: {}
};

export default withTranslation()(Button);
