import React, { useCallback } from 'react';
import { buttons } from './buttons';
import ui from 'store/ui';
import { debugEnabled } from '../../utils/env';

export type ButtonType = {
  children?: any;
  className?: string;
  onClick?: () => void;
  onClickDisabled?: () => void;
  isLoading?: boolean;
  messageId?: string;
  disabled?: boolean;
};

const Button: React.FC<ButtonType> = (props: ButtonType) => {
  const { children, className, onClick, isLoading, messageId, disabled, onClickDisabled } = props;

  const onClickHandler = useCallback(() => {
    if (isLoading) {
      return;
    }

    if (disabled) {
      onClickDisabled && onClickDisabled();
    }
    else {
      onClick && onClick();
    }
  }, [isLoading, onClick]);

  const classes: string = [className, isLoading && 'loading'].filter(c => !!c).join(' ');

  const text = (messageId ? ui.texts[messageId] : children) || (debugEnabled ? messageId : ''); // Fallback only when we want to debug (dev/test envs)

  const ButtonStyled = buttons[ui.design];

  return (
    <>
      <ButtonStyled className={classes} onClick={onClickHandler} disabledStyled={disabled}>
        {text}
      </ButtonStyled>
    </>
  );
};

export default Button;
