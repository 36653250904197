import { take, call, put } from 'redux-saga/effects';
import { PayloadAction } from 'redux-starter-kit';
import { actions } from 'legacy/store/login';
import { userValidationSaga } from './userValidationSaga';
import { Analytics } from 'legacy/utils/Analytics';

/** handle validate with mobile phone event */
export function* validateByPhoneSaga() {
  while (true) {
    const { payload: phoneNumber }: PayloadAction<string> = yield take(
      actions.requestLoginByPhoneNumber
    );
    try {
      if (phoneNumber.length < 9) {
        throw new Error('wrong phone format');
      }
      yield call(userValidationSaga, phoneNumber);
    } catch (err) {
      Analytics.sendEvent('Landing Page - Phone number validation failed');
      yield put(actions.errorLogin());
    }
  }
}
