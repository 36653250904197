import API from './api';
import { version } from '../../package.json';

enum fields {
  email = 'email',
  name = 'name',
  lastName = 'lastName',
  subject = 'subject',
  body = 'body',
  paymentRequestId = 'paymentRequestId',
}

const CHANNEL_NAME = 'Web NPG';

type Data = Record<fields, string>;

class ContactUsAPI extends API {
  constructor() {
    super('api/sendEmail');
  }

  public async sendEmail(data: Data): Promise<any> {
    return await this.postCurrent({
      version,
      channel: CHANNEL_NAME,
      ...data,
    });
  }
}

export default new ContactUsAPI();
