import { createSlice, PayloadAction } from 'redux-starter-kit';
import {
  initialOtpApprovalStatus,
  initialResendOtpStatus,
  OtpApprovalStatus,
  ResendOtpStatus,
} from './state';

/** otp validation status slice */
const otpValidationStatusSlice = createSlice({
  name: 'otpValidationStatus',
  initialState: initialOtpApprovalStatus,
  reducers: {
    requestPagoOtpValidation: (state, action: PayloadAction<number>): OtpApprovalStatus => ({
      ...state,
      pending: true,
      error: "",
    }),
    successPagoOtpValidation: (state): OtpApprovalStatus => ({
      ...state,
      pending: false,
    }),
    errorPagoOtpValidation: (state, { payload: error }: PayloadAction<string>): OtpApprovalStatus => ({
      ...state,
      pending: false,
      error,
    }),
  },
});

/** resend pago otp status slice */
const resendPagoOtpStatusSlice = createSlice({
  name: 'resendPagoOtpStatusSlice',
  initialState: initialResendOtpStatus,
  reducers: {
    requestToResendPagoOtp: (state): ResendOtpStatus => ({
      ...state,
      pending: true,
      error: false,
    }),
    successResendPagoOtp: (state): ResendOtpStatus => ({
      ...state,
      pending: false,
    }),
    errorResendPagoOtp: (state): ResendOtpStatus => ({
      ...state,
      pending: false,
      error: true,
    }),
  },
});

export const otpValidationStatusReducer = otpValidationStatusSlice.reducer;
export const resendPagoOtpStatusReducer = resendPagoOtpStatusSlice.reducer;

export const actions = {
  ...otpValidationStatusSlice.actions,
  ...resendPagoOtpStatusSlice.actions,
};
