import React, { useState } from 'react';
import { ParentProps } from 'legacy/SupportPortal/types';

import FormattedMessage from 'legacy/components/FormattedMessage';
import Button from 'legacy/components/Button';
import styled from 'styled-components';

const InputWrapper = styled('div')`
  margin-bottom: 30px;

  label {
    position: absolute;
    padding: 8px 0px 0px 10px;
    color: #999999;
  }
  input {
    border: 1px solid #999999;
    border-radius: 8px;
    height: 35px;
    padding-top: 30px;
    padding-left: 20px;
    padding-bottom: 3px;
    padding-right: 15px;
    font-weight: bold;
    font-size: 20px;
  }
`;

const Mobile: React.FC<ParentProps> = (props: ParentProps) => {
  const { submitHandler, error, loading } = props;
  const [mobile, setMobile] = useState<string>('+39');

  const removeSpaces = (str: string) => {
    return str.replace(/\s/g, '');
  };

  const removeLetters = (str: string) => {
    return str.replace(/[^+\d]/g, '');
  };

  return (
    <>
      <FormattedMessage
        messageId="change-card:support-portal-mobile-title"
        className="fs-23 mb-40"
      />
      <FormattedMessage
        messageId="change-card:support-portal-mobile-info"
        className="fs-16 mb-40"
      />

      <InputWrapper>
        <label htmlFor="mobile">Phone number</label>
        <input
          className="mobile"
          type="text"
          id="mobile"
          name="name"
          value={mobile}
          onChange={e => setMobile(removeSpaces(removeLetters(e.target.value)))}
        />
      </InputWrapper>

      {error && <FormattedMessage messageId={error} className="fs-14 mb-10 red" />}

      <Button
        messageId="change-card:support-portal-mobile-button"
        clickHandler={() => submitHandler(mobile)}
        isLoading={loading}
      />
    </>
  );
};

export default Mobile;
