import PaddingContainer from "legacy/DFE/components/PaddingContainer";
import Text from "legacy/DFE/components/Text";
import TextInput from "legacy/DFE/components/TextInput";
import React, { useState } from "react";
import styled from "styled-components";
import { connect, ConnectedProps } from "react-redux";
import { State } from "legacy/DFE/store/state";
import { selectors } from "legacy/DFE/store/order";
import { formatPrice } from "legacy/DFE/utils/ui";
import { RouteComponentType } from "legacy/DFE/routing/RouterTypes";
import { actions } from "legacy/DFE/store/auth";
import MinWidthContainer from "legacy/DFE/components/MinWidthContainer";

const PaymantPlanContainer = styled("div")`
    height: 100px;
    display: flex;
    align-items: center;
`;

const mapStateToProps = (state: State) => ({
    order: selectors.order(state),
});

const mapDispatchProps = {
    setMobile: actions.setMobile,
};

const connector = connect(mapStateToProps, mapDispatchProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type MobileComponentType = PropsFromRedux & RouteComponentType;

const MobileComponent: React.FC<MobileComponentType> = ({ order, setMobile: setMobileAction, done, useSubmit }: MobileComponentType) => {
    const [mobile, setMobileState] = useState("");

    useSubmit(() => {
        setMobileAction(mobile.replace(/ /g, ""));
        done();
    });

    return <>
        <Text fontSize="24px" className="bold"> Summary of your purchase </Text>
        <PaddingContainer padding="15px" display="inline-block">
            <Text fontSize="36px" className="inline thin"> Total: </Text>
            <Text fontSize="36px" className="inline bold"> {formatPrice(order?.totalAmount!)} </Text>
        </PaddingContainer>
        <Text fontSize="18px" className="thin"> In {order?.installmentsNumber} simple installments of {formatPrice(order?.installmentAmount!)} per month </Text>
        <PaymantPlanContainer>
            <Text className="thin"> + View your payment plan </Text>
        </PaymantPlanContainer>
        <MinWidthContainer>
            <Text className="bold"> To proceed with payment enter your phone number </Text>
            <TextInput onChange={val => setMobileState(val)} defaultValue="+39 "> Phone number </TextInput>
            {/* <Button onClick={onSubmit}> Continue </Button> */}
        </MinWidthContainer>
    </>;
}

export default connector(MobileComponent);
