import React, { useEffect } from 'react';
import styled from 'styled-components';

import Text from 'components/Text';
import { redirect } from 'helpers/redirection';
import { MappedUXFlowErrors } from 'types/data';
import paymentSuccessImage from 'assets/marni-payment-success.svg';
import Auth from 'auth';
import PageContainer from 'apps/UXFlow/components/PageContainer/DE';
import { AppProps } from 'types/apps';
import { Analytics } from 'utils/Analytics';

interface Props {
  data: any;
  errors: MappedUXFlowErrors;
  loading: boolean;
  handleSubmit: () => void;
}

const DEPaymentComplete: React.FC<Props & AppProps> = (props: Props & AppProps) => {
  const { data, loading } = props;

  useEffect(() => {
    Analytics.sendEvent('PaymentScoringSuccess');
  }, [])

  useEffect(() => {
    let needToChangeFontSize = true;
    let initFontSize = 24;

    while (needToChangeFontSize) {
      const numberElement = document.getElementsByClassName('order-number-content') as HTMLCollectionOf<HTMLDivElement>;
      const codeElement = document.getElementsByClassName('order-number') as HTMLCollectionOf<HTMLDivElement>;

      needToChangeFontSize = numberElement[0].offsetWidth < codeElement[0].offsetWidth;

      if (needToChangeFontSize) {
        initFontSize--;
        codeElement[0].style.fontSize = `${initFontSize}px`;
      }
    }
  }, []);

  return (
    <PageContainer
      titleMessageId="DEPaymentConfirmationStepTitle"
      buttonMessageId="DEPaymentConfirmationButton"
      buttonOnClick={props.onClose}
      buttonIsLoading={loading}
      goBack={props.goBack}
      backBtnEnabled={props.backBtnEnabled}
      onClose={props.onClose}
      buttonStyles={{ bottom: '18.5%' }}
    >
      <Wrapper>
        <img src={paymentSuccessImage} />

        <Text messageId="DEPaymentConfirmationDescription" type="content" className="order-number-content"/>
        <Text
          messageId="DEPaymentConfirmationNumber"
          type="content"
          className="order-number-title"
        />

        <Text content={data.confirmationNumber} className="order-number centered" />
      </Wrapper>
    </PageContainer>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > img {
    margin-top: 25px;
    margin-bottom: 40px;
  }

  & button {
    margin-top: 241px;
  }

  & .order-number {
    margin-top: 10px;
    font-weight: 600;
    font-size: 24px;
  }

  & .order-number-title {
    margin-top: 15px;
    text-transform: uppercase;
  }
`;

export default DEPaymentComplete;
