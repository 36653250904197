import React from 'react';
import { PopupBackdrop, PopupInner, CrossIcon } from './index.styles';
import { PopupProps } from 'components/Popup/types';

const Popup: React.FC<PopupProps> = (props: PopupProps) => {
  const { isOpen, onClose, children, maxWidth } = props;

  if (!isOpen) return null;

  return (
    <PopupBackdrop>
      <PopupInner maxWidth={maxWidth}>
        {onClose && <CrossIcon onClick={onClose}>&#10005;</CrossIcon>}
        {children}
      </PopupInner>
    </PopupBackdrop>
  );
};

export default Popup;
