import { createSlice, PayloadAction } from 'redux-starter-kit';
import { ValidatedUserAuthData } from 'legacy/types/ValidatedUser';
import { PinLoginPayload } from 'legacy/types/User';
import { initialPinCodeValidationState, PinCodeValidationStatus } from './state';

/** pin code validation slice */
const pinCodeValidationStatus = createSlice({
  name: 'pinCodeValidationStatus',
  initialState: initialPinCodeValidationState,
  reducers: {
    requestPinCodeValidation: (state, action: PayloadAction<PinLoginPayload>): PinCodeValidationStatus => ({
      ...state,
      pending: true,
      error: false,
      validationAuthData: null,
    }),
    errorPinCodeValidation: (state): PinCodeValidationStatus => ({
      ...state,
      pending: false,
      error: true,
    }),
    successPinCodeValidation: (state): PinCodeValidationStatus => ({
      ...state,
      pending: false,
    }),
    setValidationAuthData: (
      state,
      { payload }: PayloadAction<ValidatedUserAuthData>
    ): PinCodeValidationStatus => ({
      ...state,
      pending: false,
      validationAuthData: payload,
    }),
  },
});

export const { reducer, actions } = pinCodeValidationStatus;
