import { createSlice, PayloadAction } from 'redux-starter-kit';
import { State } from '../state';
import { initialState, AuthState } from './state';
import { StorageManager } from "legacy/DFE/utils/storage";
import { store } from '../store';

const Auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setMobile: (state, { payload: mobile }: PayloadAction<string>): AuthState => ({
      ...state,
      mobile,
    }),
    setAuthToken: (state, { payload: authToken }: PayloadAction<string>): AuthState => {
      StorageManager.set("authToken", authToken);

      return {
        ...state,
        authToken,
      }
    },
  },
});

export const { reducer } = Auth;

export const actions = {
  ...Auth.actions,
};


/** selectors */
const authState = (state: State): AuthState => state.Auth;
const mobile = (state: State): string | undefined => authState(state).mobile;
const authToken = (state?: State): string | undefined => {
  // Maybe it's a bit 'bad' code, but I think it's a nice solution and we should have it
  // Because we have a lot of places where we don't have access to the state directly
  state = state || store.getState();
  return authState(state).authToken || StorageManager.get("authToken");
};

export const selectors = {
  authState,
  mobile,
  authToken,
};