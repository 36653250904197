import { TextStyled } from "./styles";
import React from "react";

type TextProps = {
    children: any;
    className?: string;
    fontSize?: string;
    onClick?: () => void;
};

/**
 * This component is here because we want to avoid using anything from legacy NPG, So this is 'FormattedMessage' we have on the legacy code
 * Additionally, We'd like to use the text service in the future. Here we'll be able to implement it without breaking legacy FormattedMessage.
 */
const Text: React.FC<TextProps> = ({ children, className, fontSize, onClick }: TextProps) => {
    return <>
        <TextStyled onClick={onClick} fontSize={fontSize!} className={className}> {children} </TextStyled>
    </>;
}

Text.defaultProps = {
    className: "",
    fontSize: "14px",
}

export default Text