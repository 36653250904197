import styled from 'styled-components';

export const PopupBackdrop = styled('div')`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
`;

export const PopupInner = styled('div')<{maxWidth?: string}>`
  overflow-y: auto;
  position: relative;
  width: 100%;
  max-width: ${props => props.maxWidth || "600px"};
  min-height: 200px;
  max-height: 100%;
  padding: 40px 20px;
  border-radius: 20px;
  background: #fff;
  z-index: 999;
`;

export const CrossIcon = styled('span')`
  position: absolute;
  right: 20px;
  font-size: 20px;
  top: 10px;
  cursor: pointer;
  color: #16142c;
`;
