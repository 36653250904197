import React from 'react';
import { InputFormStyled, InputStyled, Error } from './input.styles';
import 'react-datepicker/dist/react-datepicker.css';
import Text from 'components/Text';
import { InputProps } from '../types';

const Input: React.FC<InputProps> = (props: InputProps) => {
  const {
    placeholder,
    onChange,
    value,
    type,
    error,
    fullWidth,
    textTransform,
    disabled,
    id,
    maxLength,
    style,
    autoFocus,
  } = props;

  return (
    <InputFormStyled fullWidth={fullWidth} disabled={disabled}>
      <InputStyled
        style={style}
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        id={id}
        textTransform={textTransform}
        maxLength={maxLength}
        autoFocus={autoFocus}
      />
      {error && (
        <Error className="form-input-error-box">
          <Text messageId={error} type="content" className="red" />
        </Error>
      )}
    </InputFormStyled>
  );
};

Input.defaultProps = {
  changeHandler: () => null,
  placeholderMessageId: '',
  type: 'text',
  errorMsgId: '',
  fullWidth: false,
  textTransform: '',
  isDisabled: false,
} as Partial<InputProps>;

export default Input;
