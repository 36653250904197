import { combineReducers } from 'redux';
import { reducer as UIReducer } from './UI';
import { reducer as RouterReducer } from './router';
import { reducer as UXFlowReducer } from './UXFlow';
import { reducer as OrderReducer } from './order';
import { reducer as AuthReducer } from './auth';
import { reducer as ConsumerReducer } from './consumer';

export const rootReducer = combineReducers({
  UI: UIReducer,
  Router: RouterReducer,
  UXFlow: UXFlowReducer,
  Order: OrderReducer,
  Auth: AuthReducer,
  Consumer: ConsumerReducer,
});

export type TRootReducer = ReturnType<typeof rootReducer>;
