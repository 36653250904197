import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { TextStyled } from './formattedMessage.styles';

interface Props extends WithTranslation {
  messageId?: string | null;
  content?: string;
  children?: React.ReactNode | null;
  className?: string;
  params?: { [key: string]: any };
  onClick?: () => void;
  id?: string;
}

const FormattedMessage: React.SFC<Props> = (props: Props) => {
  const { t, className, messageId, content, children, onClick, id, params } = props;

  return (
    <TextStyled className={className} onClick={onClick} id={id} > 
      {content ? content : messageId && t(messageId, { ...params })}
      {children && <span>{children}</span>}
    </TextStyled>
  );
};

FormattedMessage.defaultProps = {
  className: '',
  children: null,
  onClick: () => null,
  messageId: '',
  content: '',
  id: ''
};

export default withTranslation()(FormattedMessage);
