import styled from "styled-components";

const RouterContainer = styled("div")`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
`;

export default RouterContainer;