import React from 'react';
import Text from 'components/Text';
import styled from 'styled-components';

const TextContainer = styled('div')`
  position: absolute;
  flex-direction: column;
  align-items: center;
  display: flex;
  top: -35px;
`;

const Square = styled('div')`
  width: 11px;
  height: 11px;
  background-color: #b42d28;
  border-radius: 50%;
  position: relative;
  top: -1px;
  display: flex;
  justify-content: center;
`;

const Line = styled('div')`
  width: 100%;
  height: 0px;

  border-top: 2px dotted #b42d28;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Container = styled('div')`
  width: 100%;
  height: 44px;

  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 4px;

  & .paymentPlanSmallText {
    font-size: 12px;
    font-weight: 400;
  }
`;

type Payment = {
  amount: string;
  date: string;
};

type Props = {
  payments: Array<Payment>;
};

const PaymentPlan: React.FC<Props> = (props: Props) => {
  const { payments } = props;

  return (
    <Container>
      <Line>
        {payments.map(item => {
          return (
            <Square key={item.date}>
              <TextContainer>
                <Text content={item.amount} type="content" weight="450" />
                <Text
                  content={item.date}
                  className="paymentPlanSmallText light"
                  type="subcontent"
                />
              </TextContainer>
            </Square>
          );
        })}
      </Line>
    </Container>
  );
};

export default PaymentPlan;
