import queryString from 'query-string';
import { TOKEN_KEY } from 'legacy/constants/storageKeys';
import { AuthService } from 'legacy/utils/AuthService';
import { SessionStorageManager } from 'legacy/utils/SessionStorageManager';
import { Logger } from 'legacy/utils/Logger';

Logger.log(`Api url: ${process.env.REACT_APP_API_URL}`);

const BASE_URL = `${process.env.REACT_APP_API_URL}/api`;

export const getApiUrl = (url: string, params?: Record<string, any>): string => {
  const authToken = AuthService.getToken();
  const token = SessionStorageManager.getItem(TOKEN_KEY);

  const query = queryString.stringify(
    // eslint-disable-next-line @typescript-eslint/camelcase
    { access_token: authToken, ...params, token },
    { skipNull: true }
  );
  return `${BASE_URL}/${url}?${query}`;
};
