import API from "./api";

class StylesAPI extends API {
    constructor() {
        super("api/npg/v1/styles");
    }

    public async getStyles(requestId?: string, flow?: string, merchantId?: string) {
        return await this.getCurrent(requestId ? { requestId, flow } : { flow, merchantId });
    }
}

export default new StylesAPI();
