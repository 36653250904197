import { OTPAPI, ConsumersAPI } from "legacy/DFE/api/handlers";
import MinWidthContainer from "legacy/DFE/components/MinWidthContainer";
import PaddingContainer from "legacy/DFE/components/PaddingContainer";
import Text from "legacy/DFE/components/Text";
import TextInput from "legacy/DFE/components/TextInput";
import { notify } from "legacy/DFE/helpers/notify";
import { RouteComponentType } from "legacy/DFE/routing/RouterTypes";
import { actions as AuthActions, selectors } from "legacy/DFE/store/auth";
import { actions as ConsumerActions } from "legacy/DFE/store/consumer";
import { State } from "legacy/DFE/store/state";
import { actions as UIActions } from "legacy/DFE/store/UI";
import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";

const mapStateToProps = (state: State) => ({
    mobile: selectors.mobile(state),
});

const mapDispatchProps = {
    setSpinner: UIActions.setSpinner,
    setAuthToken: AuthActions.setAuthToken,
    setConsumer: ConsumerActions.setConsumer,
}

const connector = connect(mapStateToProps, mapDispatchProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type OTPComponentType = PropsFromRedux & RouteComponentType;

const OTPComponent: React.FC<OTPComponentType> = (props: OTPComponentType) => {
    const {
        mobile,
        useSubmit,
        setSpinner,
        setAuthToken,
        setConsumer,
        done,
    } = props;

    const [code, setCode] = useState("");

    useSubmit(async () => {
        setSpinner(true);
        try {
            const { token } = await OTPAPI.loginOTP(mobile!, code);
            setAuthToken(token);

            const consumerInformation = await ConsumersAPI.getInformation();

            setConsumer(consumerInformation);

            done();
        } catch (error) {
            notify("error", "Login failed. Please try again.")    
        }
        setSpinner(false);
    });

    async function resendCode() {
        setSpinner(true);
        await OTPAPI.createOTP(mobile!);
        setSpinner(false);
        notify("success", "The code resent successfully")
    }

    return <>
        <Text fontSize="24px" className="bold"> Verify your number </Text>
        <MinWidthContainer>
            <PaddingContainer padding="30px 0 10px 0">
                <Text fontSize="18px" className="thin"> Please enter verification number sent to </Text>
                <Text fontSize="18px" className="bold"> {mobile} </Text>
            </PaddingContainer>
            <PaddingContainer padding="0 0 0 0">
                <TextInput onChange={(v) => setCode(v)} value={code}> Enter the 5-digit code </TextInput>
                <Text onClick={resendCode} fontSize="18px" className="bold light pointer"> Resend code </Text>
            </PaddingContainer>
        </MinWidthContainer>
    </>;
};

export default connector(OTPComponent);