import { Route } from "legacy/DFE/routing/RouterTypes";
import { selectors as OrderSelectors } from "legacy/DFE/store/order";
import { selectors as RouterSelectors } from "legacy/DFE/store/router";
import { State } from "legacy/DFE/store/state";
import LandingErrorComponent from "./index.component";

export default class LandingError extends Route {
    constructor() {
        super("LandingError", "/");
    }

    async precondition(state: State): Promise<boolean> {
        const currentPage = RouterSelectors.currentPage(state);

        if (!currentPage.startsWith("/landing") && currentPage !== "/") {
            return false;
        }

        return !OrderSelectors.orderId(state);
    }

    component = LandingErrorComponent;
}