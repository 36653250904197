import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import i18n from 'legacy/i18n';
import { selectors } from 'legacy/store/consumer';
import { actions as UIActions, selectors as UISelectors } from 'legacy/store/UI';
import { State } from 'legacy/store/state';
import { LANGUAGE_KEY, NOOSA_MOBILE_AS_CAMERA_PHONE } from 'legacy/constants/storageKeys';
import { LocalStorageManager } from 'legacy/utils/LocalStorageManager';
import { AuthService } from 'legacy/utils/AuthService';
import { Language } from 'legacy/types/Language';
import userIcon from 'legacy/assets/icon-user.svg';
import FormattedMessage from 'legacy/components/FormattedMessage';
import italyFlag from 'legacy/assets/italy.svg';
import ukFlag from 'legacy/assets/uk.svg';
import {
  HeaderContainer,
  UserBlock,
  UserDetails,
  UserName,
  UserAvatar,
  SelectOptionWrapper,
  XButtonContainer,
  XButton,
} from './header.styles';
import { getCurrentLanguage } from 'legacy/helpers/language';
import { NameStateManager } from './name-state-manager';
import cancel from 'legacy/assets/cancel.png';
import { getFlowPath } from 'legacy/utils/UI';
import { selectors as orderSelectors } from 'legacy/store/order';
import { SessionStorageManager } from 'legacy/utils/SessionStorageManager';

const languagesOptions = [
  {
    value: Language.en,
    content: (
      <SelectOptionWrapper>
        <img src={ukFlag} alt="flag" /> EN
      </SelectOptionWrapper>
    ),
  },
  {
    value: Language.it,
    content: (
      <SelectOptionWrapper>
        <img src={italyFlag} alt="flag" /> IT
      </SelectOptionWrapper>
    ),
  },
];

const mapStateToProps = (state: State) => ({
  consumerInfo: selectors.consumerInfoSelector(state),
  confirmedLogout: UISelectors.logoutConfirmationStatus(state),
  isPorting: UISelectors.isPorting(state),
  order: orderSelectors.orderDetailsSelector(state),
});

const mapDispatchToProps = {
  openPopup: UIActions.openPopup,
  setConfirmedLogout: UIActions.setConfirmedLogout,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Header: React.SFC<PropsFromRedux> = (props: PropsFromRedux) => {
  const { consumerInfo, openPopup, confirmedLogout, isPorting, setConfirmedLogout } = props;
  const currentLanguage = getCurrentLanguage();

  const [reloadComponent, setReloadComponent] = useState(false);
  const [language, setLanguage] = useState(currentLanguage);
  const [firstName, setFirstName] = useState(consumerInfo?.firstName);
  const [lastName, setLastName] = useState(consumerInfo?.lastName);

  NameStateManager.setFirstNameState(setFirstName);
  NameStateManager.setLastNameState(setLastName);

  const isAuthorized = AuthService.getToken();

  useEffect(() => {
    LocalStorageManager.setItem(LANGUAGE_KEY, language);
  });

  useEffect(() => {
    setFirstName(consumerInfo?.firstName);
    setLastName(consumerInfo?.lastName);
  }, [consumerInfo?.firstName, consumerInfo?.lastName]);

  // We have an issue with the component not being re-rendered when we got auth token
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (AuthService.getToken() && !reloadComponent) {
        setReloadComponent(true);
        clearInterval(intervalId);
      }
    }, 1000);
  }, []);

  useEffect(() => {}, [confirmedLogout]);

  const changeLanguage = (value: Language): void => {
    i18n.changeLanguage(value);
    setLanguage(value);
    window.location.reload();
  };

  const logoutHandler = () => {
    openPopup('logout-confirmation');
  };

  const cancelHandler = () => {
    AuthService.removeToken();
    setConfirmedLogout(true);
    window.location.href = getFlowPath();
  };

  if (isPorting) {
    return (
      <XButtonContainer>
        <XButton onClick={cancelHandler} src={cancel}></XButton>
      </XButtonContainer>
    );
  }

  const renderFirstName = firstName != null ? firstName : '';
  const renderLastName = lastName != null ? lastName : '';
  const macHideFooter = !!SessionStorageManager.getItem(NOOSA_MOBILE_AS_CAMERA_PHONE);


  return (
    <HeaderContainer>
      {/* <SelectForm options={languagesOptions} changeHandler={changeLanguage} value={language} maxWidth='107px' /> */}
      <div /> {/* I have no idea why, But without this div the user icon goes left */}
      {(isAuthorized && !macHideFooter) ? (
        <UserBlock id="logout">
          <UserDetails>
            <UserName>{`${renderFirstName} ${renderLastName}`}</UserName>
            <FormattedMessage
              messageId="logout"
              className="fs-14 red link"
              onClick={logoutHandler}
              id="logout-text"
            />
          </UserDetails>
          <UserAvatar src={userIcon} id="logout-avatar"/>
        </UserBlock>
      ) : null}
    </HeaderContainer>
  );
};

export default connector(Header);
