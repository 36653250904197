import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 439px;
  width: 100%;
  height: 52px;
  position: relative;
  display: flex;
  align-items: center;
`;

export const MockSelectorWrapper = styled(Wrapper as any)`
  border: 1px solid #a5a5a5;
`;
