import React from "react";
import styled from "styled-components";
import { Analytics } from "legacy/DFE/utils/analytics";
import Text from "./Text";

const ButtonStyled = styled("div")`
    cursor: pointer;
    width: 100%;
    height: 50px;
    background-color: black;
    position: relative;
`;

type ButtonProps = {
    children: any;
    onClick?: () => void;
};

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
    const {
        children,
        onClick,
    } = props;

    function innerOnClick() {
        Analytics.sendEvent(`Button ${children} clicked`);
        onClick?.();
    }

    return <>
        <ButtonStyled onClick={onClick}>
            <Text className="button"> { children } </Text>
        </ButtonStyled>
    </>;
}

export default Button
