import React, { useState, useEffect } from 'react';
import FormattedMessage from 'legacy/components/FormattedMessage';
import { Container, IFrameWrapper } from './styles';
import cardLoader from 'legacy/assets/card-loader.gif';
import queryString from 'query-string';
import { EcpSuccess } from 'legacy/types/CardType';
import { State } from 'legacy/DFE/store/state';
import { connect, ConnectedProps } from 'react-redux';

type PostedMessage = {
  data: string;
};

const mapStateToProps = (state: State) => ({
  // order: selectors.order(state),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const AddCard: React.FC<PropsFromRedux> = ({ }: PropsFromRedux) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // useEffect(() => {
  //   const receiveMessage = (message: PostedMessage) => {
  //     const queryParams = queryString.parse(message.data);
  //     const isECPCardMessage = queryParams.cardType === 'ECP';

  //     if (isECPCardMessage) {
  //       const ecpResponse = queryParams.result;

  //       if (!ecpResponse || ecpResponse !== EcpSuccess) {
  //         setIsLoading(true);

  //         // notify('error', `onboarding:adding-card-details-status-01`);
  //         // commitCardFlow({
  //         //   data: { error: 'ecp-error-callback' },
  //         //   query: { paymentType: order?.type, id: order?.id },
  //         // });
  //         return;
  //       }

  //       setIsLoading(true);
  //       // commitCardFlow({
  //       //   data: { stepId: 'postMessageReceived' },
  //       //   query: { paymentType: order?.type, id: order?.id },
  //       // });
  //     }
  //     return;
  //   };

  //   window.addEventListener('message', receiveMessage, false);

  //   return () => {
  //     window.removeEventListener('message', receiveMessage, false);
  //   };
  // }, []);

  const url = "https://noosa-main-dev.herokuapp.com/noosa-ecp-kenzo"; // onBoardingStatus.flow.data.URL;
  const onLoadHandler = () => {
    setIsLoading(false);
  };

  return (
    <Container>
      <FormattedMessage content="Add your card" className="fs-26 bold mt-30 mb-30" />
      {/* TODO - translations */}
      <FormattedMessage
        content="Enter your card, authorization will not be charged"
        className="fs-18"
      />

      <IFrameWrapper>
        {isLoading && (
          <div>
            <img src={cardLoader} width="30px" height="30px"></img>
          </div>
        )}
        <iframe
          src={url}
          id="cardDetailsIframe"
          onLoad={onLoadHandler}
          title="card-details"
          style={{ display: isLoading ? 'none' : 'block' }}
        />
      </IFrameWrapper>
    </Container>
  );
};

export default connector(AddCard);
