import { combineReducers } from 'redux';
import { State } from 'legacy/store/state';
import { UserValidationData } from './mobilePhoneValidation/state';
import { LoginState } from './state';
import {
  reducer as pinCodeValidationReducer,
  actions as pinCodeActions,
} from './pinCodeValidation';
import {
  reducer as mobilePhoneValidationReducer,
  actions as mobilePhoneValidationActions,
} from './mobilePhoneValidation';
import {
  reducer as recoverPinCodeReducer,
  actions as recoverPinCodeActions,
} from './recoverPinCode';

/** login reducer */
export const reducer = combineReducers({
  pinCodeValidationStatus: pinCodeValidationReducer,
  restorePinCodeStatus: recoverPinCodeReducer,
  mobilePhoneValidation: mobilePhoneValidationReducer,
});

/** actions */
export const actions = {
  ...mobilePhoneValidationActions,
  ...pinCodeActions,
  ...recoverPinCodeActions,
};

/** selectors */
const getLoginState = (state: State): LoginState => state.login;

const pinCodeValidationStatusSelector = (state: State): LoginState['pinCodeValidationStatus'] =>
  getLoginState(state).pinCodeValidationStatus;
const recoverPinCodeStatusSelector = (state: State): LoginState['restorePinCodeStatus'] =>
  getLoginState(state).restorePinCodeStatus;
const loginByMobilePhoneStatusSelector = (state: State): UserValidationData =>
  getLoginState(state).mobilePhoneValidation;
const userSelector = (state: State): UserValidationData['user'] =>
  loginByMobilePhoneStatusSelector(state).user;
const isLoggedInSelector = (state: State): UserValidationData['isLoggedIn'] =>
  loginByMobilePhoneStatusSelector(state).isLoggedIn;

export const selectors = {
  userSelector,
  isLoggedInSelector,
  pinCodeValidationStatusSelector,
  loginByMobilePhoneStatusSelector,
  recoverPinCodeStatusSelector,
};
