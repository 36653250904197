import flow from 'apps/UXFlow/store/flow';
import API from './api';

class AddressAPI extends API {
  constructor() {
    super('api/npg/v1/consumer/addresses');
  }

  protected async getHeaders() {
    return {
      ...await super.getHeaders(),
      'noosa-session-id': flow.sessionId,
    };
  }

  public async getAddress(data: any) {
    return await this.getCurrent(data);
  }
}

export default new AddressAPI();
