import styled from "styled-components";

export const StyledDiv = styled.div`
    &.scroll {
        overflow-y: scroll;
    }

    &.black-links {
        & a {
            color: black;
        }
    }

    &.p-mg-0 {
        & p {
            margin: 0;
        }
    }

    &.bg-gray {
        background-color: rgb(243, 243, 243);
    }

    &.pdl-5 {
        padding-left: 5px;
    }
`;