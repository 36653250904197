import { observer } from "mobx-react-lite";
import React from "react";
import ui from "store/ui";
import { StyledImg } from "./styles";

type LogoType = {
    
}

const Logo: React.FC<LogoType> = (props: LogoType) => {
    const {
        
    } = props;

    return <>
        <StyledImg alt="logo" /* < Do we want to get it from BE? */ src={ui.styles.logo.image} />
    </>;
};

export default observer(Logo);