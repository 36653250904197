export enum PaymentStatus {
  na = 'NA',
  pending = 'pending',
  pendingDossierApproval = 'pending_dossier_approval',
  pendingOtpApproval = 'pending_otp_approval',
  validationOtp = 'validating_otp',
  pendingFirstInstallmentsAuthorization = 'pending_first_installment_authorization',
  customerApproved = 'customer_approved',
  done = 'done',
  failed = 'failed',
  canceled = 'canceled',
  scoringFailed = 'scoring_failed',
}

export enum PaymentStatusPorting {
  new = 'new',
  orderAttached = 'order_attached',
  scoringFailed = 'scoring_failed',
}
