import { AbstractAPIHandler } from ".";

export class CardsAPIHandler extends AbstractAPIHandler {
    constructor() {
        super("cards")
    }

    public getCards() {
        return this.get("getCards");
    }
}