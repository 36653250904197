import { take, put, call, select } from 'redux-saga/effects';
import { validateRecoverOtp } from 'legacy/api';
import { RecoverPinStatus } from 'legacy/types/RecoverPinStatus';
import { actions, selectors } from 'legacy/store/login';
import { SessionStorageManager } from 'legacy/utils/SessionStorageManager';
import { TOKEN_KEY } from 'legacy/constants/storageKeys';

export function* otpValidationSaga(): any {
  while (true) {
    const { payload: otp } = yield take(actions.requestValidateRecoverPinOtp);
    const otpFormatted = otp.toString();

    try {
      const user = yield select(selectors.userSelector);
      const { result, isCardExists, token } = yield call(
        validateRecoverOtp,
        user.mobile,
        otpFormatted
      );
      if (result === false) {
        throw new Error('Otp validation failed');
      }

      yield call(SessionStorageManager.setItem, TOKEN_KEY, token);

      const status = isCardExists ? RecoverPinStatus.checkCard : RecoverPinStatus.setPin;
      yield put(actions.successWhileRecoverPinCode());
      yield put(actions.setStatus(status));
    } catch (err) {
      yield put(actions.errorWhileRecoverPinCode());
    }
  }
}
