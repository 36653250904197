import axios from 'axios';
import { Order } from 'legacy/types/Order';
import { getApiUrl } from 'legacy/helpers/getApiUrl';
const ORDERS_URL = 'paymentRequest/';

/** order api calls */

export const initPaymentSession = (id: string): Promise<{ order: Order }> => {
  return axios.get(getApiUrl(`${ORDERS_URL}initPaymentSession`, { id }));
};

export const getLandingDetails = (id: string): Promise<{ order: Order }> => {
  return axios.get(getApiUrl(`${ORDERS_URL}getLandingDetails`, { id }));
};

export const getPaymentDetails = (id: string): Promise<{ order: Order }> => {
  return axios.get(getApiUrl(`${ORDERS_URL}getPaymentDetails`, { id }));
};
