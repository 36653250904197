import React from 'react';
import Text from 'components/Text';
import styled from 'styled-components';

const PriceText = styled(Text)`
  font-weight: 450;
  font-size: 40px;
`;

const SubtitleContainer = styled('div')`
  margin-bottom: 26px;
`;

type Props = {
  totalAmount: string;
  paymentDetails: string;
  preMonthAmount: string;
};

const PaymentPriceBox: React.FC<Props> = (props: Props) => {
  const { totalAmount, paymentDetails, preMonthAmount } = props;

  return (
    <>
      <PriceText messageId={totalAmount} type="title" />

      <SubtitleContainer>
        <Text messageId={paymentDetails} type="content" weight="450" />
        <> </>
        <Text messageId={preMonthAmount} type="content" weight="450" />
      </SubtitleContainer>
    </>
  );
};

export default PaymentPriceBox;
