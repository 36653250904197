import { fork } from 'redux-saga/effects';
import { otpSagas } from './otp/sagas';
import { loginSagas } from './login/sagas';
import { consumerSagas } from './consumer/sagas';
import { transactionsSagas } from './transaction/sagas';
import { orderSagas } from './order/sagas';
import { contactUsSagas } from './contactUs/sagas';
import { UISagas } from './UI/sagas';

export function* rootSaga() {
  yield fork(otpSagas);
  yield fork(loginSagas);
  yield fork(consumerSagas);
  yield fork(transactionsSagas);
  yield fork(orderSagas);
  yield fork(contactUsSagas);
  yield fork(UISagas);
}
