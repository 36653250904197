import styled from 'styled-components';

export const HeaderContainer = styled('header')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 15px;
  flex: none;

  @media (min-width: 768px) {
    padding: 15px 30px;
  }

  @media (min-width: 980px) {
    padding: 15px 60px;
  }

  @media (min-width: 1280px) {
    padding: 15px 100px;
  }

  & > div {
    margin: 0;
  }
`;

export const SelectOptionWrapper = styled('div')`
  display: flex;
  align-items: center;

  img {
    width: 25px;
    height: auto;
    margin-right: 7px;
  }
`;

export const UserBlock = styled('div')`
  display: flex;
  align-items: center;
`;

export const UserDetails = styled('div')`
  display: flex;
  flex-direction: column;
  margin-right: 13px;
  align-items: flex-end;
`;

export const UserName = styled('span')`
  color: #171627;
`;

export const UserAvatar = styled('img')`
  height: 45px;
  width: 45px;
  border-radius: 50%;
  cursor: pointer;
`;

export const XButton = styled("img")`
  cursor: pointer;
  position: absolute;
  color: black;
  width: 20px;
  height: 20px;
`;

export const XButtonContainer = styled("div")`
  position: relative;
  left: 95%;
  top: 15px;
`