import styled from 'styled-components';
import Text from 'components/Text';

export const Wrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  max-width: 500px;
//   min-height: calc(100vh - 194px);
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  // align-self: start;
  position: relative;

  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: start;
  width: 100%;
  max-height: calc(100% - 100px);

  & > * {
    margin-top: 0;
  }
`;

export const ErrorContainer = styled('div')`
  height: 24px;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 15px;
`;

export const TitleText = styled(Text)`
  margin-bottom: 16px;
  flex-grow: 1;
  text-align: center;
`;

export const CrossIcon = styled('span')`
  position: absolute;
  right: 20px;
  font-size: 25px;
  top: 10px;
  cursor: pointer;
  color: #16142c;
`;

export const BackIcon = styled('img')`
  position: absolute;
  left: 20px;
  top: 14px;
  height: 24px;
  cursor: pointer;
  align-self: start;
`;
