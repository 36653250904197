import defaultLogo from 'legacy/assets/landing-mapp-logo.svg';

export default {
    // Add default styles here
    // We don't have a Style type so add default for every style property we have, even if this is undefined
    text: {
        title: {},
        subtitle: {},
        content: {},
        subcontent: {},
        label: {},
    },
    button: {
        primary: {},
        secondary: {},
    },
    logo: {
        image: defaultLogo,
    },
    sidebarImage: {
        image: "",
        biggest: {},
        big: {},
        medium: {},
        small: {},
    },
    favicon: {
      image: ""
    },
    websiteTitle: "",
    faqLinkUrl: ""
};
