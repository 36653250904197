import axios from 'axios';
import { AppState } from './AppState';
import { Logger } from 'legacy/utils/Logger';
import { getFlowId, mobileCheck } from 'legacy/utils/UI';
import { getLandingDetails, linkLogin } from 'legacy/api';
import { SessionStorageManager } from 'legacy/utils/SessionStorageManager';
import { NOOSA_MOBILE_AS_CAMERA_PHONE, NOOSA_SESSION_ID } from 'legacy/constants/storageKeys';

const mobileAsCameraDisabled = process.env.REACT_APP_FEATURE_DISABLE_MOBILE_AS_CAMERA === 'true';

export class EcommerceLandingState extends AppState {
  resetSessionStorageKeys(): void {
    SessionStorageManager.removeItem('authToken');
    SessionStorageManager.removeItem(NOOSA_SESSION_ID);
    SessionStorageManager.removeItem(NOOSA_MOBILE_AS_CAMERA_PHONE);
  }

  async handle(existedOrderId: string): Promise<void> {
    let order;
    let id;

    if (!mobileAsCameraDisabled && window.location.pathname.startsWith('/ocr/')) {
      this.resetSessionStorageKeys();
      const isMobile = mobileCheck();
      const url = window.location.pathname.split('/');
      if (url.length >= 5) {
        id = url[2];
        const sessionId = url[3];
        if (!isMobile) {
          this.renderWelcomeApp();
          return;
        }
        const accessToken = url[4];
        const oneTimetoken = url[url.length - 1];
        const resp = await linkLogin(oneTimetoken);
        if (!!resp && !!resp.accessToken) {
          this.saveAuthToken(accessToken);
          SessionStorageManager.setValue(NOOSA_SESSION_ID, sessionId);
          SessionStorageManager.setItem(NOOSA_MOBILE_AS_CAMERA_PHONE, true);

          order = await getLandingDetails(id);
        } else {
          this.renderWelcomeApp();
          return;
        }
      }
    }

    if (!id) {
      this.resetSessionStorageKeys();
      // support both NPG-driven landing flow & UXFlow-driven lean landing flow
      if (window.location.pathname.startsWith('/flow')) {
        id = getFlowId();
      } else {
        const url = window.location.pathname.split('/');
        id = url[url.length - 1];
      }
    }

    if (!id && !existedOrderId) {
      Logger.error('missing order id');
      this.renderWelcomeApp();
      return;
    }

    try {
      /**
       * Requesting getLandingDetails without token
       *
       * Loobpack has a weird issue, when API permission is set to $everyone
       * and we still send a token and it's expired, loopback returns 401, even is API is public
       */
      if (!order) {
        order = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/paymentRequest/getLandingDetails?id=${id}`
        );
      }
    } catch (err) {
      Logger.error(err);
      this.renderWelcomeApp();
      return;
    }

    if (!order) {
      Logger.error('no order');
      this.renderWelcomeApp();
      return;
    }

    this.saveOrderId(id);
    this.render(order);
    return;
  }
}
