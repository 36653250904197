import stylesAPI from "api/stylesAPI";
import flow from "apps/UXFlow/store/flow";
import ui from "store/ui";
import { AppConfigurationType } from "types/apps";
import { deepMerge } from "utils/objects";
import UXFlow from "./index.component";
import { setWebsiteTab } from "utils/ui";
import { NOOSA_SESSION_ID } from "../../constants";

const uxflow: AppConfigurationType = {
    Component: UXFlow,
    precondition: async () => {
        if (window.location.pathname.includes('marni/card/change/t')) {
          return false;
        }

        const path = window.location.pathname.split("/");
        const id = path[path.length - 1];

        if (!id) { //TODO Maybe check length and stuff to make sure it's really flow id and not like npg.url/support
            return false;
        }

        if (sessionStorage.getItem("PAYMENT_REQUEST_ID") !== id) {
            sessionStorage.clear();
            sessionStorage.setItem("PAYMENT_REQUEST_ID", id);
        }

        flow.requestId = id;

        const item = sessionStorage.getItem(NOOSA_SESSION_ID);
        try {
            flow.sessionId = item ? JSON.parse(item) : undefined;
        } catch {} // Because we're exporting "new Flow()" as some kind of singleton, we get errors when the item is not JSON in lean flow

        //TODO call the API to check token

        const { data: { styles, texts } } = await stylesAPI.getStyles(id, "order");

        deepMerge(ui.styles, styles);
        setWebsiteTab(ui.styles?.favicon?.image, ui.styles?.websiteTitle);

        ui.texts = {
            ...ui.texts,
            ...texts,
        };

        return true;
    },
}

export default uxflow;
