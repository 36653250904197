import { Route } from 'legacy/DFE/routing/RouterTypes';
import InsufficientFundsComponent from './index.component';

export default class InsufficientFundsError extends Route {
  constructor() {
    super('InsufficientFundsError', '/insufficient-funds-error');
  }

  component = InsufficientFundsComponent;
}
