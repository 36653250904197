import React from 'react';
import DangerousHTMLContainer from 'components/DangerousHTMLContainer';
import styled from 'styled-components';

const DisclaimerContainer = styled('div')`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
`;

type TCProps = {
  onClick: (e: any) => void;
};

const TC: React.FC<TCProps> = (props: TCProps) => {
  const { onClick } = props;
  return (
    <DisclaimerContainer onClick={onClick}>
      <DangerousHTMLContainer messageId="DEMobilePageLegalText" className="black-links p-mg-0" />
    </DisclaimerContainer>
  );
};
export default TC;
