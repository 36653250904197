import React from 'react';
import PaymentTimeline from 'legacy/DFE/components/PaymentPlan';
import Text from 'legacy/DFE/components/Text';
import { ContentWrapper } from 'legacy/DFE/components/ContentWrapper';
import Button from 'legacy/DFE/components/Button';
import TC from './components/TermsConditions';
import styled from 'styled-components';

const CenterContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
`;

const PaymentPlanComponent: React.FC = (props: {}) => {
  return (
    <CenterContainer>
      <div>
        <Text className="bold mb-10" fontSize={'26px'}>Complete your payment</Text>
        <Text className="light mb-20" fontSize={'20px'}>Your payment plan details</Text>
      </div>
      <PaymentTimeline />

      <ContentWrapper>
        <Button>Pay</Button>
        <TC></TC>
      </ContentWrapper>
    </CenterContainer>
  );
};

export default PaymentPlanComponent;
