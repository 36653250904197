import { connect } from 'react-redux';

import { selectors as OrderSelectors } from 'legacy/store/order';
import { selectors as UISelectors } from 'legacy/store/UI';
import { State } from 'legacy/store/state';

const mapStateToProps = (state: State) => ({
  order: OrderSelectors.orderDetailsSelector(state),
  isPortingUI: UISelectors.isPorting(state),
  theme: UISelectors.theme(state),
});

const mapDispatchProps = {
};
  
export default connect(mapStateToProps, mapDispatchProps);