import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import FormattedMessage from 'legacy/components/FormattedMessage';
import Button from 'legacy/components/Button';
import FlexColumn from 'legacy/components/FlexColumn';
import { actions } from 'legacy/store/UI';

const mapDispatchToProps = {
  closePopup: actions.closePopup
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const OnBoardingPopup: React.SFC<PropsFromRedux> = ({ closePopup }: PropsFromRedux) => {

  return (
    <FlexColumn>
      <FormattedMessage messageId="terms-conditions:new-version-title" className="fs-26 mb-10" />
      <FormattedMessage messageId="terms-conditions:new-version-body" className="fs-20 mb-50" />
      <Button
        messageId="payment:agree-to-procced"
        clickHandler={() => closePopup()}
        className="mb-20"
      />
    </FlexColumn>
  );
};

export default connector(OnBoardingPopup);
