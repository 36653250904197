import React from 'react';

import useSubmitHandler from 'apps/UXFlow/utils/hooks/useSubmitHandler';
import PageContainer from 'apps/UXFlow/components/PageContainer/DE';
import { AppComponentProps, AppProps } from 'types/apps';
import flow from 'apps/UXFlow/store/flow';

import { ParentProps } from '../../types';
import { getOffset } from 'offset';
import { createGlobalStyle } from 'styled-components';

const Styles = createGlobalStyle`
  #page-container {
    max-height: calc(100vh - 270px);
  }

  #sidebar {
    display: none;
  }
`;

const LegalDocuments: React.FC<AppComponentProps & ParentProps & AppProps> = (
  props: AppComponentProps & ParentProps & AppProps
) => {
  const { onGetFlow } = useSubmitHandler(props);

  const goBack = async (): Promise<void> => {
    await onGetFlow({ offset: getOffset() });
  };

  return (
    <PageContainer
      titleMessageId={flow.data?.messageId}
      buttonMessageId="return"
      buttonOnClick={goBack}
      onClose={props.onClose}
      contentStyles={{ flex: '1 1 auto', maxHeight: 'calc(100vh - 300px)' }}
      closeBtnEnabled={false}
    >
      <Styles />

      {flow.data?.content ? (
        <div
          style={{ overflowY: 'scroll', padding: '5px' }}
          dangerouslySetInnerHTML={{ __html: flow.data.content }}
        />
      ) : (
        <iframe
          src={flow.data.url}
          id="legalDocument"
          title="card-details"
          style={{ borderStyle: 'none', display: 'block', minWidth: '100%', height: '100%' }}
        />
      )}
    </PageContainer>
  );
};

export default LegalDocuments;
