import React from 'react';
import FormattedMessage from 'legacy/components/FormattedMessage';
import { ContentWrapper } from 'legacy/DFE/components/ContentWrapper';
import Button from 'legacy/DFE/components/Button';

const PaymentSuccessComponent: React.FC = (props: {}) => {
  return (
    <>
      <ContentWrapper>
        <FormattedMessage content="Payment is completed" className="fs-26 bold mb-10" />
        <FormattedMessage content="Your payment plan is sent to your email" className="fs-20 light" />
      </ContentWrapper>

      <ContentWrapper>
        <FormattedMessage content="Order confirmation number" className="fs-18 bold" />
        <FormattedMessage content="XXXXXXX" className="fs-16" />
      </ContentWrapper>

      <ContentWrapper>
        <Button>Return to Nike</Button>
      </ContentWrapper>
    </>
  );
};

export default PaymentSuccessComponent;
