import styled from 'styled-components';

export const TextareaFormStyles = styled('div')`
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const TextareaStyled = styled('textarea')`
  width: 100%;
  border: 1px solid #e1dfe9;
  border-radius: 5px;
  font-size: 0.94em;
  height: 88px;
  padding: 14px 16px;
  color: #000000;

  &:focus {
    outline: none;
  }
`;

export const Error = styled('div')`
  white-space: normal;
  word-break: break-word;
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 20px;
`;
