import React from 'react';
import { State } from '../store/state';
import { connect, ConnectedProps } from 'react-redux';
import { selectors } from 'legacy/DFE/store/UI';
import styled from 'styled-components';

const LogoImage = styled('img')`
  width: 200px;
  height: auto;
`;

const mapStateToProps = (state: State) => ({
  logo: selectors.logo(state),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Logo: React.FC<PropsFromRedux> = ({ logo }: PropsFromRedux) => {
  return (
    <>
      <LogoImage src={logo} />
    </>
  );
};

export default connector(Logo);
