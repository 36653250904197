import { Route } from 'legacy/DFE/routing/RouterTypes';
import FAQComponent from './index.component';

export default class FAQ extends Route {
  constructor() {
    super('FAQComponent', '/FAQ');
  }

  component = FAQComponent;
}
