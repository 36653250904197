import { actions } from "legacy/DFE/store/router";
import { useEventListener } from "legacy/DFE/hooks/useEventListener";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import Button from "./Button";

const mapDispatchProps = {
    setSubmittedRoute: actions.setSubmittedRoute,
};

const connector = connect(undefined, mapDispatchProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ContainerSubmitButtonType = PropsFromRedux & {
    children?: any;
    currentRoute?: string;
};

const ContainerSubmitButton: React.FC<ContainerSubmitButtonType> = (props: ContainerSubmitButtonType) => {
    const {
        setSubmittedRoute,
        currentRoute,
        children,
    } = props;

    useEventListener('keypress', () => setSubmittedRoute(currentRoute || ""));

    return <>
        <div style={{ width: "340px" }}>
            <Button onClick={() => setSubmittedRoute(currentRoute || "")}> Continue </Button>
            {children}
        </div>
    </>;
};

export default connector(ContainerSubmitButton);
