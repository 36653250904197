export type Section = {
  agree_text?: string;
  disagree_text?: string;
  disagree_client_text?: string;
  checkboxPrefix?: string;
  contents: string;
  name: string;
};

export enum InputValues {
  clientDisagree = 'clientDisagree',
  disagree = 'disagree',
  agree = 'agree',
}

export type Consents = {
  [key: string]: boolean;
};

export type SelectedConsent = {
  value: keyof typeof InputValues;
  name: string;
};
