import styled from 'styled-components';
import React from 'react';
import connector from './connect';
import { ConnectedProps } from 'react-redux';
import defaultLogo from 'legacy/assets/landing-mapp-logo.svg';
import { useImgOrientation } from 'legacy/hooks/useImgOrientation';

const LogoImg = styled('img')<{ isPorting: boolean; isHorizontal: boolean }>`
  width: ${props => (props.isHorizontal ? '100%' : 'none')};
  max-width: ${props => (props.isPorting ? '200px' : '165px')};
  max-height: ${props => (props.isPorting ? '200px' : '165px')};
  margin: 25px 0;
  margin-top: ${props => (props.isPorting ? '50px' : '10px')};
  flex: none;

  @media (max-width: 550px) {
    max-width: 140px;
    max-height: 165px;
  }
`;

type PropsFromRedux = ConnectedProps<typeof connector>;

const Logo: React.FC<PropsFromRedux> = (props: PropsFromRedux) => {
  const { order, isPortingUI, theme } = props;
  const [isHorizontal, ref] = useImgOrientation();
  const logo = order ? order.merchantLogo || defaultLogo : '';

  return (
    <LogoImg
      ref={ref}
      isHorizontal={isHorizontal}
      isPorting={isPortingUI}
      src={isPortingUI ? require(`legacy/assets/logos/${theme.logo}`) : logo}
      alt="noosa-logo"
      id="OVS-logo"
    />
  );
};

export default connector(Logo);
