import { LocalStorageManager } from './LocalStorageManager';
import { SessionStorageManager } from './SessionStorageManager';
import { Logger } from './Logger';
import {
  AUTH_TOKEN_KEY,
  REMEMBER_ME_KEY,
  REFERRER_KEY,
  NOOSA_SESSION_ID,
} from 'legacy/constants/storageKeys';
import { isOpenedInIframe } from 'legacy/helpers/payment';
import { logout } from 'legacy/api';

export class AuthService {
  static setRememberMe(value: boolean) {
    LocalStorageManager.setItem(REMEMBER_ME_KEY, value);
  }

  static getRememberMe() {
    return LocalStorageManager.getItem(REMEMBER_ME_KEY) || false;
  }

  static setToken(value: any) {
    SessionStorageManager.setItem(AUTH_TOKEN_KEY, value);
  }

  static getToken() {
    const token = SessionStorageManager.getItem(AUTH_TOKEN_KEY)
    return token;
  }

  static removeToken() {
    const token = SessionStorageManager.getItem(AUTH_TOKEN_KEY);

    // not awaiting it, in order not to block user experience
    if (token) {
      logout(token).catch(err => {
        console.log(err);
      });
    }

    SessionStorageManager.removeItem(AUTH_TOKEN_KEY);
    LocalStorageManager.removeItem(REMEMBER_ME_KEY);
    SessionStorageManager.removeItem(NOOSA_SESSION_ID);
  }

  static handleRedirectionToMerchant(url: string | null) {
    const isRememberMe = LocalStorageManager.getItem(REMEMBER_ME_KEY);
    const isIframe = isOpenedInIframe();
    const redirectUrl = url || LocalStorageManager.getItem(REFERRER_KEY);

    if (!isRememberMe) {
      this.removeToken();
    }

    if (isIframe) {
      window.parent.postMessage({ url: redirectUrl }, '*');
      Logger.info("Post message is sent");
    } else {
      window.location.href = redirectUrl;
    }
  }
}
