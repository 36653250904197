import Auth from 'auth';
import axios from 'axios';
import { SeonService } from 'services/SeonService';
import { urlPathJoin } from 'utils/url';
import { NPG_APP_VER } from 'constants/index';

export default class API {
  protected url: string;

  constructor(path: string) {
    // this.url = urlPathJoin(process.env.REACT_APP_API_URL || '', 'api', 'npg', 'v1', path);
    this.url = urlPathJoin(process.env.REACT_APP_API_URL || '', path);
  }

  private static connectURLWithParams(url: string, path?: string, params?: any) {
    url = path ? urlPathJoin(url, path) : url;

    if (!params) {
      return url;
    }

    const searchParams = new URLSearchParams(params);

    return `${url}?${searchParams.toString()}`;
  }

  protected async getHeaders(isFormData?: boolean) {
    const headers = {
      app: 'npg',
      src: 'web',
    };

    const language = localStorage.getItem('LANGUAGE');

    if (Auth.accessToken) {
      headers['authorization'] = Auth.accessToken;
    }

    if (isFormData) {
      headers['Content-Type'] = 'multipart/form-data';
    }

    if (language) {
      headers['Language'] = language;
    }

    const sessionPayload = await SeonService.getSessionPayload();

    if (sessionPayload) {
      headers['x-session-payload'] = sessionPayload;
    }

    if (NPG_APP_VER) {
      headers['x-npg-build'] = NPG_APP_VER;
    }

    return headers;
  }

  protected async get(path?: string, params?: any) {
    const headers = await this.getHeaders();
    const resp = await axios.get(API.connectURLWithParams(this.url, path, params), {
      headers,
    });

    // We might want to return more data in the future, Like erros from UXFlow. Better do it like this.
    return resp;
  }

  protected async getCurrent(params?: any) {
    return await this.get(undefined, params);
  }

  protected async post(path?: string, body?: any, params?: any) {
    const isFormData = body instanceof FormData;
    const headers = await this.getHeaders(isFormData);
    const resp = await axios.post(API.connectURLWithParams(this.url, path, params), body, {
      headers,
    });

    // We might want to return more data in the future, Like erros from UXFlow. Better do it like this.
    return resp;
  }

  protected async postCurrent(body?: any, params?: any) {
    return this.post(undefined, body, params);
  }
}
