import React from 'react';
import styled from 'styled-components';
import FormattedMessage from 'legacy/components/FormattedMessage';
import logo from 'legacy/assets/noosa_logo.png';

const FooterWrapper = styled('footer')`
  padding: 10px 18px;
  position: fixed;
  bottom: 0px;
  right: 0px;
  }
`;

const CopyrightText = styled('div')`
  span {
    opacity: 90%;
  }
`;

const Footer: React.FC = () => {
  return (
    <FooterWrapper>
      <CopyrightText>
        <FormattedMessage
          messageId="change-card:footer-powered-by"
          className="fs-12 white inline"
        />
        &nbsp;
        <img src={logo} alt={'noosa'} />
      </CopyrightText>
    </FooterWrapper>
  );
};

export default Footer;
