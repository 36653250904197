import CenterContainer from "components/CenterContainer";
import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
    :root {
        font-size: 14px;
    }

    html, body {
        margin: 0;
        overflow-x: hidden;
    }

    body {
        font-family: Ubuntu, sans-serif;
    }

    * {
        box-sizing: border-box;
    }

    .toaster {
      &.Toastify__toast-container {
        width: inherit;
        min-width: 300px;
      }

      .Toastify__toast--error,
      .Toastify__toast--info,
      .Toastify__toast--success {
        min-height: 80px;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
      }

      .Toastify__toast {
        padding: 20px;
        max-width: 388px;
        width: 100%;

        &.Toastify__toast--error {
          border-left: 4px solid #ff5656;
        }

        &.Toastify__toast--success {
          border-left: 4px solid #7f9f40;
        }

        &.Toastify__toast--info {
          border-left: 4px solid #6666ff;
        }
      }

      .toaster-message {
        display: flex;
        align-items: center;
      }

      .Toastify__close-button {
        font-size: 0;
        width: 10px;
        height: 10px;

        &::before {
          content: "";
          display: block;
          width: 10px;
          height: 10px;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDEwIDEwIj4gICAgPHBhdGggZmlsbD0iI0RERDNEMyIgZmlsbC1ydWxlPSJub256ZXJvIiBkPSJNLjI1IDcuMzc1YS44MDguODA4IDAgMCAwLS4yNS41OWMwIC4yMzcuMDgzLjQzOC4yNS42MDRMMS40MyA5Ljc1Yy4xNjcuMTY3LjM2OC4yNS42MDQuMjVhLjgwNy44MDcgMCAwIDAgLjU5LS4yNUw1IDcuMzc1IDcuMzc1IDkuNzVjLjE2Ny4xNjcuMzYzLjI1LjU5LjI1YS44MjMuODIzIDAgMCAwIC42MDUtLjI1bDEuMTgtMS4xOGEuODIyLjgyMiAwIDAgMCAuMjUtLjYwNC44MDguODA4IDAgMCAwLS4yNS0uNTkxTDcuMzc2IDVsMi4zNzYtMi4zNzZhLjgwNy44MDcgMCAwIDAgLjI0OS0uNTkuODIyLjgyMiAwIDAgMC0uMjUtLjYwNEw4LjU3LjI1QS44MjIuODIyIDAgMCAwIDcuOTY2IDBhLjgwNy44MDcgMCAwIDAtLjU5LjI1TDUgMi42MjMgMi42MjUuMjVBLjgwNy44MDcgMCAwIDAgMi4wMzUgMGEuODIyLjgyMiAwIDAgMC0uNjA1LjI1TC4yNSAxLjQzYS44MjIuODIyIDAgMCAwLS4yNS42MDRjMCAuMjI3LjA4My40MjQuMjUuNTlMMi42MjQgNSAuMjQ5IDcuMzc1eiIvPjwvc3ZnPg==);
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }

      .Toastify__toast--error {
        .toaster-icon {
          background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIyIDIwIj4gICAgPHBhdGggZmlsbD0iI0ZGNTY1NiIgZmlsbC1ydWxlPSJub256ZXJvIiBkPSJNMjEuODA2IDE3LjgxNEwxMi4xNTQuNjk3QTEuMzMyIDEuMzMyIDAgMCAwIDExIDBjLS40NzIgMC0uOTEuMjY0LTEuMTU0LjY5N0wuMTk0IDE3LjgxNGMtLjI1MS40NDUtLjI1OSAxLS4wMiAxLjQ1My4yNC40NTMuNjg4LjczMyAxLjE3NC43MzNoMTkuMzA0Yy40ODYgMCAuOTM1LS4yOCAxLjE3NC0uNzMzYTEuNTI4IDEuNTI4IDAgMCAwLS4wMi0xLjQ1M3pNMTEuMDA3IDUuOTIxYy41NTUgMCAxLjAyNC4zMzQgMS4wMjQuOTI3IDAgMS44MDktLjIgNC40MDgtLjIgNi4yMTcgMCAuNDctLjQ4My42NjgtLjgyNC42NjgtLjQ1NSAwLS44MzgtLjE5Ny0uODM4LS42NjggMC0xLjgwOS0uMi00LjQwOC0uMi02LjIxNyAwLS41OTMuNDU1LS45MjcgMS4wMzgtLjkyN3ptLjAxNCAxMS4yMDJjLS42MjUgMC0xLjA5NC0uNTQ3LTEuMDk0LTEuMTcgMC0uNjM5LjQ2OS0xLjE3IDEuMDk0LTEuMTcuNTgzIDAgMS4wOC41MzEgMS4wOCAxLjE3IDAgLjYyMy0uNDk3IDEuMTctMS4wOCAxLjE3eiIvPjwvc3ZnPg==);
        }
      }
    }

    .toaster-icon {
      flex: none;
      margin-right: 20px;
      width: 22px;
      height: 22px;
      display: block;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDIyIDIyIj4gICAgPHBhdGggZmlsbD0iIzdGOUY0MCIgZmlsbC1ydWxlPSJub256ZXJvIiBkPSJNMTEgMEM0LjkgMCAwIDQuOSAwIDExczQuOSAxMSAxMSAxMSAxMS00LjkgMTEtMTFTMTcuMSAwIDExIDB6bTYuMzUgNy41NWwtOCA4LjNjLS4xLjEtLjIuMTUtLjM1LjE1LS4xNSAwLS4zLS4wNS0uMzUtLjE1bC0zLjktNC4yLS4xLS4xYS41NC41NCAwIDAgMS0uMTUtLjM1YzAtLjEuMDUtLjI1LjE1LS4zNWwuNy0uN2MuMi0uMi41LS4yLjcgMGwuMDUuMDUgMi43NSAyLjk1Yy4xLjEuMjUuMS4zNSAwbDYuNy02Ljk1aC4wNWMuMi0uMi41LS4yLjcgMGwuNy43Yy4yLjE1LjIuNDUgMCAuNjV6Ii8+PC9zdmc+);
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;

      .Toastify__toast--error {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIyIDIwIj4gICAgPHBhdGggZmlsbD0iI0ZGNTY1NiIgZmlsbC1ydWxlPSJub256ZXJvIiBkPSJNMjEuODA2IDE3LjgxNEwxMi4xNTQuNjk3QTEuMzMyIDEuMzMyIDAgMCAwIDExIDBjLS40NzIgMC0uOTEuMjY0LTEuMTU0LjY5N0wuMTk0IDE3LjgxNGMtLjI1MS40NDUtLjI1OSAxLS4wMiAxLjQ1My4yNC40NTMuNjg4LjczMyAxLjE3NC43MzNoMTkuMzA0Yy40ODYgMCAuOTM1LS4yOCAxLjE3NC0uNzMzYTEuNTI4IDEuNTI4IDAgMCAwLS4wMi0xLjQ1M3pNMTEuMDA3IDUuOTIxYy41NTUgMCAxLjAyNC4zMzQgMS4wMjQuOTI3IDAgMS44MDktLjIgNC40MDgtLjIgNi4yMTcgMCAuNDctLjQ4My42NjgtLjgyNC42NjgtLjQ1NSAwLS44MzgtLjE5Ny0uODM4LS42NjggMC0xLjgwOS0uMi00LjQwOC0uMi02LjIxNyAwLS41OTMuNDU1LS45MjcgMS4wMzgtLjkyN3ptLjAxNCAxMS4yMDJjLS42MjUgMC0xLjA5NC0uNTQ3LTEuMDk0LTEuMTcgMC0uNjM5LjQ2OS0xLjE3IDEuMDk0LTEuMTcuNTgzIDAgMS4wOC41MzEgMS4wOCAxLjE3IDAgLjYyMy0uNDk3IDEuMTctMS4wOCAxLjE3eiIvPjwvc3ZnPg==);
      }
    }

    /* Scrollbar */
    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #ffffff;
      border-radius: 10px;
      border: 1px solid #000000;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #ffffff;
    }
    /* End Scrollbar */
`;

export const RootContainer = styled(CenterContainer as any)`
    min-height: 100vh;
`;
