import flow from 'apps/UXFlow/store/flow';
import API from './api';

class PaymentAPI extends API {
  constructor() {
    super('api/npg/v1/payments/state');
  }

  private get query() {
    const query = {
      id: flow?.data?.transactionId,
    };

    return query;
  }

  protected async getHeaders() {
    return {
      ... await super.getHeaders(),
      'noosa-session-id': flow.sessionId,
    };
  }

  public async getState() {
    return await this.getCurrent({ ...this.query });
  }
}

export default new PaymentAPI();
