import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastTemplate from 'legacy/components/ToastTemplate';

type ToastStatus = 'success' | 'error' | 'warn' | 'info';

export const notify = (status: ToastStatus, messageId?: string, content?: string) => {
  return toast[status](<ToastTemplate messageId={messageId} content={content} />);
};

export const dismissToast = (toastId: string) => {
  return toast.dismiss(toastId);
};
