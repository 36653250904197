import axios from 'axios';
import moment from 'moment';

import { Transaction } from 'legacy/types/Transaction';
import { Dossier } from 'legacy/types/PaymentProcessing';
import { PaymentStatus } from 'legacy/types/PaymentStatus';
import { getApiUrl } from 'legacy/helpers/getApiUrl';
import { getStringifiedParams } from 'legacy/helpers/getStringifiedParams';
import { LocalStorageManager } from 'legacy/utils/LocalStorageManager';
import { POL_OTP_DELAY, ORDER_ID_KEY } from 'legacy/constants/storageKeys';

const TRANSACTIONS_URL = 'transactions/';

export const createTransaction = (orderId: string, cancelId: string): Promise<Transaction> => {
  return axios.post(
    getApiUrl(`${TRANSACTIONS_URL}createTransactionByOrder`),
    { nOrderId: orderId, cancelId },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
};

export const getDossier = (id: string): Promise<Dossier> => {
  return axios.get(getApiUrl(`${TRANSACTIONS_URL}getDossier`, { id }));
};

export const getTerms = (id: string): Promise<{ legalTerms: any }> => {
  return axios.get(getApiUrl(`${TRANSACTIONS_URL}getTerms`, { id }));
};

export const acceptDossier = (id: string, terms: any[], version: string): Promise<void> => {
  return axios.post(getApiUrl(`${TRANSACTIONS_URL}acceptTerms`, { id }), {
    terms, version
  });
};

export const sendPagoOtp = (id: string): Promise<void> => {
  return axios.post(getApiUrl(`${TRANSACTIONS_URL}sendPagoOTP`, { id }));
};

export const validatePagoOtp = (id: string, otp: string): Promise<void> => {
  const date = moment().format("YYYY-MM-DD");
  const delay = LocalStorageManager.getItem(`${POL_OTP_DELAY}${date}`);

  const data = { id, otp };

  if (delay) {
    data["__simulatedDelayOnValidation"] = delay;
  }

  return axios.post(
    getApiUrl(`${TRANSACTIONS_URL}validatePagoOtp`),
    getStringifiedParams(data)
  );
};

export const getTransactionStatus = (id: string): Promise<PaymentStatus> => {
  return axios.get(getApiUrl(`${TRANSACTIONS_URL}getStatus`, { id }));
};

export const getPaymentStatus = (id: string): Promise<any> => {
  console.log(`getPaymentStatus run ${(new Date()).toISOString()}`);

  return axios.post(getApiUrl(`${TRANSACTIONS_URL}getPaymentStatus`), {
    paymentId: id
  });
};

type TransactionParam = { transactionId: string };
type CancelParam = { cancelId: string };
type PaymentRequestParam = { paymentRequestId: string };

export const cancelCredit = (
  params: CancelParam | TransactionParam | PaymentRequestParam
): Promise<void> => {
  return axios.post(getApiUrl(`npg/v1/payments/cancel`), getStringifiedParams(params));
};

export const scoringCheck = (): Promise<void> => {
  return axios.post(
    getApiUrl(`${TRANSACTIONS_URL}scoringCheck`)
  );
};
