import CenterContainer from 'components/CenterContainer';
import styled from 'styled-components';

const Container = styled(CenterContainer as any)`
  border-radius: 20px;
  padding: 40px 70px;
  padding-bottom: 0;
  margin-bottom: 15px;
  margin-top: 10px;
  width: 750px;

  @media (max-width: 1280px) {
    padding: 20px 30px;
  }

  @media (max-width: 550px) {
    padding: 20px 20px;
  }
`;

export default Container;
