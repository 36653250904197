import React, { lazy } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import * as pathName from 'legacy/constants/pathnames';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';

// const Landing = lazy(() => import('legacy/containers/Landing'));
const OtpValidation = lazy(() => import('legacy/containers/OtpValidation'));
const PinCodeValidation = lazy(() => import('legacy/containers/PinCodeValidation'));
const PaymentRequest = lazy(() => import('legacy/containers/PaymentRequest'));
const Onboarding = lazy(() => import('legacy/containers/Onboarding'));
const Card = lazy(() => import('legacy/containers/Card'));
const Welcome = lazy(() => import('legacy/containers/Welcome'));
const RecoverPinCode = lazy(() => import('legacy/containers/RecoverPinCode'));
const FAQ = lazy(() => import('legacy/containers/FAQ'));
const CancellationSuccess = lazy(() => import('legacy/containers/CancellationSuccess'));
const ListOfMerchants = lazy(() => import('legacy/containers/ListOfMerchants'));
const MacOneTimeLogin = lazy(() =>
  import('legacy/containers/lean/components/MobileCameraOCR/oneTimeLogin.component')
);
// Route to handle landing and auth flows from BE UXFlow. Supposed to used it for future,
// including onboarding and hopefully payment flows.
const Flow = lazy(() => import('legacy/containers/Flow'));

const routes = [
  /** public routes */
  // { path: pathName.LANDING, component: Landing, isProtected: false, exact: true },
  // { path: pathName.LANDING_WITH_ORDER_ID, component: Landing, isProtected: false, exact: false },
  {
    path: pathName.MOBILE_OCR_AUTH,
    component: MacOneTimeLogin,
    isProtected: false,
    exact: false,
  },
  { path: pathName.OTP_VALIDATION, component: OtpValidation, isProtected: false, exact: false },
  {
    path: pathName.PIN_CODE_VALIDATION,
    component: PinCodeValidation,
    isProtected: false,
    exact: false,
  },
  { path: pathName.WELCOME, component: Welcome, isProtected: false, exact: false },
  { path: pathName.RECOVER_PIN, component: RecoverPinCode, isProtected: false, exact: false },
  { path: pathName.FAQ, component: FAQ, isProtected: false, exact: false },
  // { path: pathName.TOKEN, component: TokenPage, isProtected: false, exact: true },
  // { path: pathName.TOKEN_WITH_VALUE, component: TokenPage, isProtected: false, exact: false },
  {
    path: pathName.CANCELLATION_SUCCESS,
    component: CancellationSuccess,
    isProtected: false,
    exact: false,
  },
  {
    path: pathName.LIST_OF_MERCHANTS,
    component: ListOfMerchants,
    isProtected: false,
    exact: false,
  },
  { path: pathName.FLOW, component: Flow, isProtected: false, exact: true },
  { path: pathName.FLOW_WITH_ID, component: Flow, isProtected: false, exact: false },

  /** protected routes */
  { path: pathName.PAYMENT_REQUEST, component: PaymentRequest, isProtected: true, exact: false },
  { path: pathName.ON_BOARDING, component: Onboarding, isProtected: true, exact: true },
  { path: pathName.CARD, component: Card, isProtected: true, exact: true },
  // { path: pathName.LANDING_MAPP, component: LandingMapp, isProtected: true, exact: false },
];

const Router: React.SFC = () => {
  return (
    <Switch>
      {routes.map(({ isProtected, ...routerProps }) => {
        if (isProtected) {
          return <ProtectedRoute {...routerProps} key={routerProps.path} />;
        }
        return <PublicRoute {...routerProps} key={routerProps.path} />;
      })}
      <Redirect from="*" to={pathName.FLOW} />
    </Switch>
  );
};

export default Router;
