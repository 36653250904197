import { createSlice, PayloadAction } from 'redux-starter-kit';
import { RecoverPinStatus } from 'legacy/types/RecoverPinStatus';
import { initialForgotPinCodeState, ForgotPinCodeStatus } from './state';

/** recover pin code slice */
const recoverPinCodeStatus = createSlice({
  name: 'recoverPinCodeStatus',
  initialState: initialForgotPinCodeState,
  reducers: {
    recoverPinCode: (state): ForgotPinCodeStatus => ({
      ...state,
      pending: true,
    }),
    errorWhileRecoverPinCode: (state): ForgotPinCodeStatus => ({
      ...state,
      pending: false,
      error: true,
    }),
    successWhileRecoverPinCode: (state): ForgotPinCodeStatus => ({
      ...state,
      pending: false,
      error: false,
    }),
    setStatus: (state, { payload }: PayloadAction<RecoverPinStatus>): ForgotPinCodeStatus => ({
      ...state,
      status: payload,
    }),
    requestValidateRecoverPinOtp: (state, action: PayloadAction<number>): ForgotPinCodeStatus => ({
      ...state,
      pending: true,
    }),
    checkCard: (state, action: PayloadAction<number>): ForgotPinCodeStatus => ({
      ...state,
      pending: true,
    }),
    requestToResendRecoverPinOtp: (state): ForgotPinCodeStatus => ({
      ...state,
      resendOtpPending: true,
    }),
    finishResendRecoverPinOtp: (state): ForgotPinCodeStatus => ({
      ...state,
      resendOtpPending: false,
    }),
    requestResetPinCode: (state, action: PayloadAction<number>): ForgotPinCodeStatus => ({
      ...state,
      pending: true,
      pinCodeError: false,
    }),
    successResetPinCode: (state): ForgotPinCodeStatus => ({
      ...state,
      pending: false,
    }),
    errorResetPinCode: (state): ForgotPinCodeStatus => ({
      ...state,
      pending: false,
      pinCodeError: true,
    }),
  },
});

export const { reducer, actions } = recoverPinCodeStatus;
