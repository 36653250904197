import PinCode from "./routes/PinCode";
import { ConsumersAPI } from "legacy/DFE/api/handlers";
import ContainerSubmitButton from "legacy/DFE/components/ContainerSubmitButton";
import RouterContainer from "legacy/DFE/components/RouterContainer";
import Router from "legacy/DFE/routing/Router";
import { RouteComponentType } from "legacy/DFE/routing/RouterTypes";
import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import Address from "./routes/Address";
import Profile from "./routes/Profile";
import ECPCard from "./routes/Card";
import { PreprocessType } from "./types";
import { actions, selectors } from "legacy/DFE/store/UXFlow";
import { actions as routActions } from "legacy/DFE/store/router";

const mapStateToProps = (state: any) => ({
    flow: selectors.flow(state),
});

const mapDispatchProps = {
    setFlow: actions.setFlow,
    setPage: routActions.setPage
}

const connector = connect(mapStateToProps, mapDispatchProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type OnboardingComponentType = PreprocessType & PropsFromRedux & RouteComponentType;

const OnboardingComponent: React.FC<OnboardingComponentType> = (props: OnboardingComponentType) => {
    const {
        flow: preprocessFlow,
        setFlow,
        flow,
        done,
    } = props;

    // Router state
    const [currentRoute, setCurrentRoute] = useState<string>("");

    // Onboarding state
    // TODO Implement offset later after checking with product about behavior
    const [offset, _setOffset] = useState(0);

    async function submitFlow(data: any) {
        setFlow(await ConsumersAPI.postFlow(data, offset));
    }

    function onDone() {
        props.setPage('paymentPlan');
        done();
    }

    const step = flow.next;

    useEffect(() => {
        if (!step) {
            onDone();
        }
    }, [ step ]);

    if (!step) {
        return <> </>
    }

    const routes = [
        new Address(),
        new Profile(),
        new PinCode(),
        new ECPCard(),
    ]

    return <>
        <RouterContainer>
            <Router onDone={()=> onDone()} onChangeRoute={(route) => setCurrentRoute(route.name)} parentProps={{ submitFlow, flow }} routes={routes}></Router>
        </RouterContainer>
        {currentRoute !== "ECPCard" && <ContainerSubmitButton currentRoute={currentRoute} />}
    </>

};

export default connector(OnboardingComponent);
