import { Order } from './Order';
import { Transaction } from './Transaction';

export interface ParentProps {
  submitHandler: (data?: any) => void;
  isPendingState: boolean;
  transaction: Payment;
  dossier: Dossier;
  order: Order;
  error: any;
  openedPopup?: string
}

export enum PaymentStatus {
  New = 'new',
  OrderAttached = 'order_attached',
  Signed = 'signed',
  Authorized = 'authorized',
  Captured = 'captured',
  Success = 'success',
  Rejected = 'rejected',
  Error = 'error',
  Cancelled = 'cancelled',
  Ecpired = 'expired',
  ScoringPassed = 'scoring_passed',
  ScoringFailed = 'scoring_failed',
}

export enum ProcessStatus {
  New = 'new',
  OrderAttached = 'order_attached',
  SignatureSent = 'signature_sent',
  SignatureCanceled = 'signature_canceled',
  SignatureValidationStarted = 'signature_validation_started',
  SignatureValidationFailed = 'signature_validation_failed',
  SignatureValidationSuccess = 'signature_validation_success',
  Signed = 'signed',
  ScoringPassed = 'scoring_passed',
  ScoringFailed = 'scoring_failed',
  Authorized = 'authorized',
  Error = 'error',
}

export enum StatusCategory {
  Processing = 'processing',
  Failed = 'failed',
  Success = 'success',
}

export interface Payment {
  paymentId: string;
  compassId: string;
  paymentStatus: PaymentStatus;
  timeBeforeExpiration: string;
  isExpired: boolean;
  isScoringFailed: boolean;
  amount: string;
  processingErrors: any;
  storeName: string;
  merchantDisplayName: string;
  confirmationNumber: string;
  barcodeId: string;
  paymentRequestStatus: string;
  statusCategory: StatusCategory;
  flow: string;
  program: string;
  processingStatus: ProcessStatus;
}

export interface DossierSections {
  agree_text: string;
  checkboxPrefix: string;
  contents: string;
  name: string;
}
export interface Dossier {
  dossier: {
    currency: string;
    installments: { date: string; amount: number }[];
    sections: DossierSections[];
    suffix: string;
    totalAmount: number;
    version: string;
  };
}

export enum Steps {
  Loading = 'loading',
  OTP = 'otp',
  ProgressBar = 'progress_bar',
  Success = 'success',
  Failure = 'failure',
}
