import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';
import { actions } from 'legacy/DFE/store/router';
import Text from 'legacy/DFE/components/Text/';

const FooterWrapper = styled('footer')`
  padding: 10px 18px;
`;

const CopyrightText = styled('div')`
  display: flex;
  justify-content: center;
`;

const SubMenu = styled('div')`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  a {
    display: inline-flex;
  }

  div:not(:last-child)::after {
    content: '|';
    margin: 0 20px;
    color: gray;
  }
`;

const mapDispatchToProps = {
  setPage: actions.setPage,
};

const connector = connect(undefined, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Footer: React.FC<PropsFromRedux> = (props: PropsFromRedux) => {
  const year = new Date().getFullYear();

  return (
    <FooterWrapper>
      <SubMenu>
        <a style={{ color: 'gray', textDecoration: 'none' }} href={'/FAQ'}>
          FAQ
        </a>
        <div />
        <a style={{ color: 'gray', textDecoration: 'none' }} href={'/contact-us'}>
          Contact Us
        </a>
      </SubMenu>
      <CopyrightText>
        <Text className="light" fontSize={'9px'}>
          Copyright ©
        </Text>
        <Text className="light" fontSize={'9px'}>
          {year}
        </Text>
        <Text className="light" fontSize={'9px'}>
          Noosa. All rights reserved
        </Text>
      </CopyrightText>
    </FooterWrapper>
  );
};

export default connector(Footer);
