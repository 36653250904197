import flow from 'apps/UXFlow/store/flow';
import changeCardFlow from 'apps/ChangeCard/store/flow';
import { Channels } from 'types/payments';
import API from './api';
import { getAppType, AppType } from 'helpers/appType';

class FlowAPI extends API {
  constructor() {
    super('api/npg/v1/flow');
  }

  private get query() {
    // A lot hardcoded for now, Needs to be changed to use states wherver needed
    const query = {
      client: 'npg',
      source: 'web',
      channel: Channels.ecommerce,
      paymentType: 'installment',
      type: getAppType(),
    };

    if (getAppType() === AppType.order) {
      query['paymentRequestId'] = flow.requestId;
    }

    return query;
  }

  protected async getHeaders() {
    let noosaSessionId;

    if (getAppType() === AppType.order) {
      noosaSessionId = flow.sessionId;
    }

    if (getAppType() === AppType.de_change_card) {
      noosaSessionId = changeCardFlow.sessionId;
    }

    return {
      ...await super.getHeaders(),
      'noosa-session-id': noosaSessionId,
    };
  }

  public async getFlow(params?: object) {
    return await this.getCurrent({ ...this.query, ...params });
  }

  public async postFlow(data?: any, params?: any) {
    return await this.postCurrent(data, { ...this.query, ...params });
  }
}

export default new FlowAPI();
