import { call, put, take } from 'redux-saga/effects';
import { PayloadAction } from 'redux-starter-kit';
import { getCards } from 'legacy/api';
import { actions } from 'legacy/store/consumer';
import { CardFlowData, CardFlowErrors } from 'legacy/types/Consumer';
import { getCardsStatus, isCardValid } from 'legacy/helpers/onboarding';
import { PAYMENT_REQUEST } from 'legacy/constants/pathnames';
import { history } from 'legacy/routing/history';

export function* requestCardFlowSaga(): any {
  while (true) {
    const { payload: { data, query } }: PayloadAction<CardFlowData> = yield take(
      actions.requestCardFlow
    );

    try {
      yield put(actions.setOnboardingPending(true));

      const cardsData = yield call(getCards);
      const cardsStatus = getCardsStatus(cardsData ? cardsData.cards : []);
      const isValid = isCardValid(query.paymentType || "", cardsStatus);

      if (isValid) {
        yield put(actions.setOnboardingPending(false));
        history.push(PAYMENT_REQUEST);
      } else {
        yield put(actions.setCardFlow({ next: "LetsDoIt", data: {} }));
        yield put(actions.setOnboardingPending(false));
      }
     // Analytics event
    } catch (err) {
      yield put(actions.setOnboardingPending(false));
      yield put(actions.setCardFlow({ next: "LetsDoIt", data: { flowError: CardFlowErrors.UnexpectedError } }));
    }
  }
}