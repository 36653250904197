import { createSlice, createAction, PayloadAction } from 'redux-starter-kit';
import { initialConsumerAddress, ConsumerAddress } from './state';

/** address slice */
const addressSlice = createSlice({
  name: 'consumer address',
  initialState: initialConsumerAddress,
  reducers: {
    setProvinces: (state, { payload: provinces }: PayloadAction<string[]>): ConsumerAddress => ({
      ...state,
      provinces,
    }),
    setCities: (state, { payload: cities }: PayloadAction<string[]>): ConsumerAddress => ({
      ...state,
      cities,
    }),
    setZipCodes: (state, { payload: zipcodes }: PayloadAction<string[]>): ConsumerAddress => ({
      ...state,
      zipcodes,
    }),
  },
});

const requestProvinces = createAction('requestProvinces');
const requestCities = createAction<{ province: string, city?: string }>('requestCities');
const requestZipCodes = createAction<{ provinceCode: string, city: string }>('requestZipCodes');

export const addressActions = {
  ...addressSlice.actions,
  requestProvinces,
  requestCities,
  requestZipCodes,
};

export const { reducer: addressReducer } = addressSlice;
