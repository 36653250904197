import { Route } from "legacy/DFE/routing/RouterTypes";
import { selectors } from "legacy/DFE/store/auth";
import { State } from "legacy/DFE/store/state";
import MobileComponent from "./index.component";

export default class Mobile extends Route {
  constructor() {
    super("Mobile", '/');
  }

  async precondition(state: State) {
    return !state.Auth.mobile && !selectors.authToken(state);
  }

  component = MobileComponent;
}
