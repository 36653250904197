import { AppState } from './AppState';
import { getLandingDetails } from 'legacy/api';
import { Logger } from 'legacy/utils/Logger';

export class ArbitraryState extends AppState {
  async handle(existedOrderId: string, skipOrderValidation?: boolean): Promise<void> {
    // skip order validation for this weird /merchants route, which should be public
    if (skipOrderValidation) {
      this.render({});
      return;
    }

    let order;
    try {
      order = await getLandingDetails(existedOrderId || '');
    } catch (err) {
      Logger.error(err);
      this.renderWelcomeApp();
      return;
    }

    if (!order) {
      Logger.error('no order');
      this.renderWelcomeApp();
      return;
    }

    this.render(order);
    return;
  }
}
