export function validateEmail(email: string): boolean {
    const formattedEmail = email.trim();
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    return regex.test(formattedEmail);
}

export function isJSON(value: string): boolean {
  try {
    JSON.parse(value);
  } catch (error) {
    return false;
  }
  return true;
}
