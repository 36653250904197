import { isJSON } from 'utils/strings';
export default class Auth {
  public static accessToken: string;

  static set(accessToken: string): void {
    Auth.accessToken = accessToken;
    sessionStorage.setItem('access_token', JSON.stringify(accessToken));
  }

  static get(): string {
    const accessToken = sessionStorage.getItem('access_token');
    return accessToken && isJSON(accessToken) ? JSON.parse(accessToken) : '';
  }

  static clear(): void {
    sessionStorage.clear();
  }

  static initialize(): void {
    const accessToken = Auth.get();
    Auth.set(accessToken);
  }
}
