import React, { useState, useEffect } from 'react';

import useEventListener from 'apps/UXFlow/utils/hooks/useEventListener';
import { AppComponentProps, AppProps } from 'types/apps';
import { ParentProps } from '../types';

import useSubmitHandler from 'apps/UXFlow/utils/hooks/useSubmitHandler';
import flow from 'apps/UXFlow/store/flow';
import ui from 'store/ui';
import DEView from './DE';
import DefaultView from './Default';
import { Amplitude } from 'services/AmplitudeService';
import { events } from 'services/events';
import { DESIGN } from 'types/design';
import { Analytics } from 'utils/Analytics';

const OTP_LENGTH = 6;

const DEEmailVerification: React.FC<AppComponentProps & ParentProps & AppProps> = (
  props: AppComponentProps & ParentProps & AppProps
) => {
  useEffect(() => {
    Analytics.sendEvent(events.OnboardingEmailVerificationPageVisited, {});
  }, []);

  const { onSubmit, loading } = useSubmitHandler(props);
  const [OTP, setOTP] = useState<number | string>('');
  const [isResendDisabled, setIsResendDisabled] = useState<boolean>(false);

  const { errors, data } = flow;

  const handleSubmit = async () => {
    Analytics.sendEvent(events.OnboardingEmailVerificationPageSent, {});
    await onSubmit({ otp: OTP });
  };

  const handleResend = async () => {
    if (isResendDisabled) {
      return;
    }
    Analytics.sendEvent(events.OnboardingEmailVerificationPageResend, {});
    setIsResendDisabled(true);
    await onSubmit({ resend: true });
    setTimeout(() => {
      setIsResendDisabled(false);
    }, data.settings.OTPResendDelayMs || 15000);
  };

  useEffect(() => {
    const otpLength = data.settings?.OTPLength || OTP_LENGTH;
    if (OTP.toString().length === otpLength) {
      handleSubmit();
    }
  }, [OTP]);

  useEventListener('keypress', handleSubmit);

  const StepComponents = {
    [DESIGN.DE]: DEView,
    [DESIGN.default]: DefaultView,
  };

  const Step = StepComponents[ui.design];

  return (
    <Step
      data={data}
      errors={errors}
      loading={loading}
      handleSubmit={handleSubmit}
      OTP={OTP}
      setOTP={setOTP}
      handleResend={handleResend}
      isResendDisabled={isResendDisabled}
      goBack={props.goBack}
      backBtnEnabled={props.backBtnEnabled}
      onClose={props.onClose}
    />
  );
};

export default DEEmailVerification;
