import styled from 'styled-components';
import { cardRatioWidthToHeight } from 'utils/OCR';

// Rectangle
const rectangleHeight = 70;

export const WhiteRectangle = styled('div')`
  position: absolute;
  width: ${rectangleHeight * cardRatioWidthToHeight}vh;
  height: ${rectangleHeight}vh;
  border: white 5px solid;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const buttonSize = '10vw';

export const Button = styled('div')`
  width: ${buttonSize};
  height: ${buttonSize};
  position: absolute;
  top: 50%;
  right: ${buttonSize};
  transform: translate(50%, -50%);
  border-radius: 100%;
  background-color: white;
`;

export const GalleryImage = styled('img')`
  filter: invert(1);
  height: ${buttonSize};
`;

export const GalleryBox = styled('label')`
  position: absolute;
  right: ${buttonSize};
  transform: translate(50%, 0);
  bottom: 10%;
`;

export const HiddenInput = styled('input')`
  position: fixed;
  top: -100em;
`;

export const Canvas = styled('canvas')`
  display: 'none';
  width: '100vw';
  height: '100vh';
`;
