import React, { useEffect, useState } from 'react';
import { sha256 } from 'js-sha256';

import Text from 'components/Text';
import Button from 'components/Button';
import InputForm from 'components/InputForm';
import useSubmitHandler from 'apps/UXFlow/utils/hooks/useSubmitHandler';
import flow from 'apps/UXFlow/store/flow';
import { AppComponentProps } from 'types/apps';
import {
  formatDate,
  extractPlaceOfBirth,
  extractDateOfBirth,
  convertBase64ToFile,
  parseFileToImage,
  tryToCompressImage,
  getImageDimensions,
  // getAnalyticsSizeRange,
} from 'utils/OCR';

import Camera from './components/Camera';
import CardImage from './components/CardImage';
import InfoTitle from './components/InfoTooltip/title';
import Info from './components/InfoTooltip/info';
import { ParentProps } from '../types';

import { FullWidthInputContainer, InputContainer, Inputs, Canvas } from './styles';
import { MappedUXFlowErrors } from 'types/data';

const SECONDS_FOR_TAKING_PHOTO = 2;

enum ComeFrom {
  Photo = 'Photo',
  Gallery = 'Gallery',
}

const OCR: React.FC<AppComponentProps & ParentProps> = (props: AppComponentProps & ParentProps) => {
  const [isInfoOpened, setIsInfoOpened] = useState(false);
  const [isOCRCardImageLoading, setIsOCRCardImageLoading] = useState(false);
  const [isCameraUsed, setIsCameraUsed] = useState(false);
  const [OCRTempImage, setOCRTempImage] = useState<string>();
  const [errors, setErrors] = useState<MappedUXFlowErrors>({});

  const [fiscalCode, setFiscalCode] = useState(flow.data.fiscalCode.value || '');
  const [firstName, setFirstName] = useState(flow.data.firstName.value || '');
  const [lastName, setLastName] = useState(flow.data.lastName.value || '');

  const extractedBirthDate = extractDateOfBirth(fiscalCode);
  const birthDate =
    flow.data?.birthDate?.value ||
    (fiscalCode.length === 16 && extractedBirthDate ? formatDate(extractedBirthDate) : '');
  const birthPlace = flow.data?.birthPlace?.value || extractPlaceOfBirth(fiscalCode).name || '';
  const isFullData = firstName && lastName && fiscalCode;

  const { onSubmit } = useSubmitHandler(props);

  useEffect(() => {
    setFirstName(flow.data?.firstName?.value || '');
    setLastName(flow.data?.lastName?.value || '');
    setFiscalCode(flow.data?.fiscalCode?.value || '');
  }, [flow.data]);

  useEffect(() => {
    setErrors(flow.errors);
  }, [flow.errors]);

  const onUpload = async (fileParam: File): Promise<void> => {
    const secondsFromCreationFile = (new Date().getTime() - fileParam.lastModified) / 1000;
    const source =
      secondsFromCreationFile < SECONDS_FOR_TAKING_PHOTO ? ComeFrom.Photo : ComeFrom.Gallery;

    const { file: tmpFile, fileType } = await parseFileToImage(fileParam);

    const allowedTypes = flow.data?.supportedFormats?.replace(/\./g, '').split(',');

    setErrors({});

    if (!allowedTypes.includes(fileType)) {
      setErrors({
        ...errors,
        UnsupportedFormat: flow.data?.texts?.unsupportedFormatMsg,
      });
      return;
    }

    const { file, imageData } = await tryToCompressImage(tmpFile, flow.data);
    const imageDimensions = await getImageDimensions(imageData);

    // The number is 385 in order to not block QA from testing. Our test images are about 390px width~
    const maxLength = 385;
    if (imageDimensions.height < maxLength && imageDimensions.width < maxLength) {
      setErrors({ ...errors, LowQuality: flow.data?.texts?.lowQualityMsg });
      return;
    }

    // Making the card show loader
    setIsOCRCardImageLoading(true);

    // Reset stuff
    setIsCameraUsed(false);

    setOCRTempImage(imageData);

    // Calculating checksum
    const checksum = sha256(imageData);

    // If checksum exists in the array from BE, show error and stop loading
    if (flow.data?.checksums?.image?.includes?.(checksum)) {
      setIsOCRCardImageLoading(false);
      return;
    }

    // For getting format
    const fileNameArr = file.name.split('.');

    // OCRTempImage &&
    //   Analytics.sendEvent(`Onboarding - OCR - image replaced`, {
    //     fileType: fileParam.name
    //       .split('.')
    //       .pop()
    //       ?.toLowerCase(),
    //     fileSize: getAnalyticsSizeRange(file),
    //     imageWidth: imageDimensions.width,
    //     imageHeight: imageDimensions.height,
    //   });

    const body = {
      source,
      side: 'image',
      checksum,
      image: await convertBase64ToFile(imageData),
      fileFormat: fileNameArr[fileNameArr.length - 1],
      imageDimensions,
    };

    const formData = new FormData();

    for (const [key, value] of Object.entries(body)) {
      formData.append(key, value instanceof Blob ? value : `${value}`);
    }

    onSubmit(formData);
  };

  const handleOCRSubmit = (): void => {
    onSubmit({
      firstName,
      lastName,
      birthDate,
      birthPlace,
      fiscalCode,
    });
  };

  const toggleInfo = (): void => setIsInfoOpened(!isInfoOpened);

  const id = 'image';
  const isImageExist: boolean = !!OCRTempImage || !!flow.data?.images[id];
  const isLoading = isOCRCardImageLoading;
  const success: boolean =
    isImageExist &&
    !flow.errors.alreadyUploaded &&
    !isLoading &&
    flow.data?.fiscalCode &&
    !errors.length;

  if (isCameraUsed) {
    return <Camera onUpload={onUpload} setIsCameraUsed={setIsCameraUsed} />;
  }

  return (
    <>
      <Canvas id="worksCanvas" />
      <Text type="title" />
      <CardImage
        data={flow.data}
        onUpload={onUpload}
        OCRTempImage={OCRTempImage}
        cardImageLoading={isOCRCardImageLoading}
        setIsCameraUsed={setIsCameraUsed}
        isCameraUsed={isCameraUsed}
        alreadyUploadedError={flow.errors?.alreadyUploaded?.message}
      />
      <br />

      <InfoTitle toggleInfo={toggleInfo} />

      {Object.keys(errors).map(key => (
        <Text content={errors[key]?.message} key={key} className="red" />
      ))}

      {isInfoOpened && <Info toggleInfo={toggleInfo} />}

      {success && (
        <>
          <FullWidthInputContainer displayElem={!isCameraUsed}>
            <Text messageId="fiscalCodeLabel" className="light left" />
            <InputForm
              fullWidth={true}
              type="text"
              textTransform="uppercase"
              value={fiscalCode}
              changeHandler={setFiscalCode}
              disabled={!flow.data?.fiscalCode?.editable}
            />
          </FullWidthInputContainer>
          <Inputs>
            <InputContainer displayElem={!isCameraUsed}>
              <Text messageId="firstNameLabel" className="light left" />
              <InputForm
                type="text"
                value={firstName}
                changeHandler={setFirstName}
                disabled={!flow.data?.firstName?.editable}
              />
            </InputContainer>
            <InputContainer displayElem={!isCameraUsed}>
              <Text messageId="lastNameLabel" className="light left" />
              <InputForm
                type="text"
                value={lastName}
                changeHandler={setLastName}
                disabled={!flow.data?.lastName?.editable}
              />
            </InputContainer>
          </Inputs>
          <Button
            disabled={isLoading || !isFullData}
            onClick={handleOCRSubmit}
            isLoading={isLoading}
          >
            <Text messageId="confirm" />
          </Button>
        </>
      )}
    </>
  );
};

export default OCR;
