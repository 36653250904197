import React, { useEffect } from "react";
import queryString from 'query-string';
import { CardsAPI } from "legacy/DFE/api/handlers";
import { sleep } from "legacy/DFE/utils/async";
import { notify } from "legacy/DFE/helpers/notify";
import styled from "styled-components";

async function runPolling(): Promise<boolean> {
    for (let i = 0; i < 60; i++) {
        const data = await CardsAPI.getCards();

        const completedCard = data.cards.find((card: { status: string; }) => card.status === "completed");

        if (completedCard) {
            return true;
        }

        await sleep(1000);
    }

    return false;
}

const StyledIframe = styled("iframe")`
    border: none;
    width: 400px;
    height: 500px;
`;

type IFrameType = {
    flow: any,
    done: () => void,
};

const IFrame: React.FC<IFrameType> = (props: IFrameType) => {
    const {
        flow: { data },
        done,
    } = props;

    const url = data.URL;

    useEffect(() => {
        const onMessage = async (message: { data: string }) => {
            const queryParams = queryString.parse(message.data);
            const isECPCardMessage = queryParams.cardType === 'ECP';

            if (isECPCardMessage) {
                const ecpResponse = queryParams.result;

                if (ecpResponse && ecpResponse === "01" && !(await runPolling())) {
                    notify("error", "There was an error while adding the card.")
                }

                // We'd like to refresh the component to let the onboarding fetch everything again. 
                // Not sure it's doing that rn, Need to check.
                done();
            }
        };

        window.addEventListener('message', onMessage, false);

        return () => {
            window.removeEventListener('message', onMessage, false);
        };
    }, []);

    return <>
        <StyledIframe
            src={url}
        />
    </>;
};

export default IFrame;