export function init() {
  //? Maybe think of a better way to handle this event
  window.onpopstate = (event: any) => {
    if (event.state) {
      window.location.reload();
    }
  };
}

export function pushPageToHTML5(page: string) {
  window.history.pushState({}, null as any, page);
}

export function getCurrentPath() {}
