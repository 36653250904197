import { AbstractAPIHandler } from ".";

export class OTPAPIHandler extends AbstractAPIHandler {
    constructor() {
        super("otp")
    }

    public createOTP(mobile: string) {
        return this.post("otpCreate", { mobile });
    }

    public loginOTP(mobile: string, otp: string) {
        // Remember me is hardcoded to one because we don't have a button of remember me
        // TODO Check with product what should be the behavior
        return this.post("otpLogin", { mobile, otp, rememberMe: 1 });
    }
}