import { call, put, take, select } from 'redux-saga/effects';
import { PayloadAction } from 'redux-starter-kit';
import { getOnboardingFlow } from 'legacy/api';
import { actions, selectors } from 'legacy/store/consumer';
import { OnboardingFlowAPIData } from 'legacy/types/Consumer';
import { handleFlowErrors, resetErrors } from './errors';
import lean from 'legacy/helpers/switchFlow/flows/lean';
import { store } from 'legacy/store/store';

export function* requestFlowSaga(): any {
  while (true) {
    const { payload: data }: PayloadAction<OnboardingFlowAPIData> = yield take(
      actions.requestFlow
    );

    try {
      yield put(actions.setOnboardingPending(true));

      const onBoardingStatus = yield select(selectors.onBoardingStatusSelector);
      const isOnboardingInProgress = onBoardingStatus.flow.totalSteps !== 0;
      const finalStep = isOnboardingInProgress ? 'Success' : 'none';

      const flow = yield call(getOnboardingFlow, data);

      // empty "next" means all steps are done
      if (flow && !flow.next) {
        flow.next = lean(store.getState()) ? "none" : finalStep;
      }

      yield put(actions.setFlow({ ...flow, offset: data.query.offset, refresh: false }));
      yield put(actions.setOnboardingPending(false));

      yield call(resetErrors);

      if (flow && flow?.data?.errors) {
        yield call(handleFlowErrors, flow?.data?.errors);
      }

     // Analytics event
    } catch (err) {
      yield call(handleFlowErrors, err);
      yield put(actions.setOnboardingPending(false));
    }
  }
}
