import flow from 'apps/UXFlow/store/flow';
import API from './api';

class CancelAPI extends API {
  constructor() {
    super('api/npg/v1/payments/cancel');
  }

  private get query() {
    const query = {
      id: flow?.data?.transactionId,
    };

    return query;
  }

  protected async getHeaders() {
    return {
      ...await super.getHeaders(),
      'noosa-session-id': flow.sessionId,
    };
  }

  public async cancel(data: any) {
    return await this.postCurrent(data);
  }
}

export default new CancelAPI();
