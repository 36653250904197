import { fork } from 'redux-saga/effects';
import { consumerInfoSaga } from './consumerInfoSaga';
import { onboardingSagas } from '../onboarding/sagas';
import { consumerAddressSaga } from '../address/sagas';

export function* consumerSagas() {
  yield fork(consumerInfoSaga);
  yield fork(onboardingSagas);
  yield fork(consumerAddressSaga);
}
