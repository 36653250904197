import styled from 'styled-components';

export const UploadImage = styled('img')<{ isVertical: boolean; flash: boolean }>`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${props => (props.isVertical ? 'transform: rotate(-90deg);' : '')}

  ${props => (props.flash ? 'animation: glowing 1500ms infinite;' : '')}

  @keyframes glowing {
    0% {
      box-shadow: 0 0 3px #da291c;
    }
    50% {
      box-shadow: 0 0 40px #da291c;
    }
    100% {
      box-shadow: 0 0 3px #da291c;
    }
  }
`;

export const HiddenInput = styled('input')`
  display: none;
`;

export const UploadElement = styled('label')<{ disabled: boolean }>`
  ${props => !props.disabled && 'cursor: pointer;'}
`;
