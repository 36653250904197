import React from 'react';
import FormattedMessage from 'legacy/components/FormattedMessage';
import { ContentWrapper } from 'legacy/DFE/components/ContentWrapper';
import Button from 'legacy/DFE/components/Button';

const PaymentFailureComponent: React.FC = (props: {}) => {
  return (
    <>
      <ContentWrapper>
        <FormattedMessage content="Payment failed" className="fs-26 bold mb-10" />
      </ContentWrapper>

      <ContentWrapper>
        <FormattedMessage content="We are not able to complete your payment due to insufficient funds in the card" className="fs-18 mb-10" />
        <FormattedMessage content="If you wish to change your card, click here" className="fs-16" />
      </ContentWrapper>

      <ContentWrapper>
        <Button>Return to Nike</Button>
      </ContentWrapper>
    </>
  );
};

export default PaymentFailureComponent;
