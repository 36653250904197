import { createSlice, PayloadAction, createAction } from 'redux-starter-kit';
import { State } from '../state';
import { initialState, UIState } from './state';

const UI = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setSpinner: (state, { payload: spinner }: PayloadAction<boolean>): UIState => ({
      ...state,
      spinner,
    }),
    setLogo: (state, { payload: logo }: PayloadAction<string>): UIState => ({
      ...state,
      logo,
    }),
  },
});

export const { reducer } = UI;

export const actions = {
  ...UI.actions,
};

/** selectors */
const getUIState = (state: State): UIState => state.UI;
const showSpinner = (state: State): boolean => getUIState(state).spinner;
const logo = (state: State): string => getUIState(state).logo;

export const selectors = {
  getUIState,
  showSpinner,
  logo,
};
