import { take, put, call } from 'redux-saga/effects';
import { PayloadAction } from 'redux-starter-kit';
import { getProvinces, getCities, getZipCodes } from 'legacy/api';
import { notify } from 'legacy/helpers/notify';
import { addressActions as actions } from '../index';

/** handle request provinces event */
export function* provincesSaga() {
  while (true) {
    yield take(actions.requestProvinces);
    try {
      const { provinces } = yield call(getProvinces);
      const provincessNamesArray = provinces.map((item: { provincia: string }) => item.provincia);
      yield put(actions.setProvinces(provincessNamesArray));
    } catch (err) {
      notify('error', 'err-while-fetching-address-options');
    }
  }
}

/** handle request cities event */
export function* citiesSaga() {
  while (true) {
    const { payload: data }: PayloadAction<string> = yield take(actions.requestCities);
    try {
      const { cities } = yield call(getCities, data);
      const citiesNamesArray = cities.map((item: { comune: string }) => item.comune);
      yield put(actions.setCities(citiesNamesArray));
    } catch (err) {
      notify('error', 'err-while-fetching-address-options');
    }
  }
}

/** handle request zip codes event */
export function* zipcodesSaga() {
  while (true) {
    const { payload: data }: PayloadAction<any> = yield take(actions.requestZipCodes);
    try {
      const { zipCodes } = yield call(getZipCodes, data.provinceCode, data.city);
      const zipCodesNamesArray = zipCodes.map((item: { cap: string }) => item.cap);
      yield put(actions.setZipCodes(zipCodesNamesArray));
    } catch (err) {
      notify('error', 'err-while-fetching-address-options');
    }
  }
}
