import { call, put, take } from 'redux-saga/effects';
import { getDossier } from 'legacy/api';
import { actions } from 'legacy/store/transaction';
import { actions as UIActions } from 'legacy/store/UI';
import { TRANSACTION_ID_KEY } from 'legacy/constants/storageKeys';
import { errorsWithToasts } from 'legacy/constants/errorsWithToasts';
import { LocalStorageManager } from 'legacy/utils/LocalStorageManager';
import { notify } from 'legacy/helpers/notify';

export function* dossierPageLoadedSaga(): any {
  while (true) {
    yield take(actions.getDossierData);
    yield put(UIActions.setIsLoading(true));

    try {
      const transactionId = yield call(LocalStorageManager.getItem, TRANSACTION_ID_KEY);
      const { dossier } = yield call(getDossier, transactionId);

      yield put(actions.setDossier(dossier));
    } catch (err) {
      if (!errorsWithToasts.find(errorItem => err.message === errorItem.message)) {
        notify('error', 'something-went-wrong');
      }
    } finally {
      yield put(UIActions.setIsLoading(false));
    }
  }
}
