import { fork } from 'redux-saga/effects';
import { requestFlowSaga } from './requestFlowSaga';
import { commitFlowSaga } from './commitFlowSaga';
import { requestCardFlowSaga } from './requestCardFlowSaga';
import { commitCardFlowSaga } from './commitCardFlowSaga';

export function* onboardingSagas() {
  yield fork(requestFlowSaga);
  yield fork(commitFlowSaga);
  yield fork(requestCardFlowSaga);
  yield fork(commitCardFlowSaga);
}
