import { Route } from 'legacy/DFE/routing/RouterTypes';
import { selectors } from 'legacy/DFE/store/order';
import { State } from 'legacy/DFE/store/state';
import PaymentSuccessComponent from './index.component';

export default class PaymentSuccess extends Route {
  constructor() {
    super('PaymentSuccess', '/paymentSuccess');
  }

  async precondition(state: State): Promise<boolean> {
    const order = selectors.order(state);
    const paymentFlow = order?.paymentFlow;

    if (paymentFlow?.confirmationScreen.off && order?.successURL) {
      window.location.href = order?.successURL;
    }

    return !paymentFlow?.confirmationScreen?.off && super.precondition(state);
  }

  component = PaymentSuccessComponent;
}
