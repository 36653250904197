import axios from 'axios';

import AppManager from 'isRefactorApp';
import { axiosDefaultRequestInterceptor, axiosDefaultResponseInterceptor } from './interceptors';

!AppManager.isRefactorApp &&
  axios.interceptors.request.use(
    axiosDefaultRequestInterceptor[0],
    axiosDefaultRequestInterceptor[1]
  );

// set a default response handler
!AppManager.isRefactorApp &&
  axios.interceptors.response.use(
    axiosDefaultResponseInterceptor[0],
    axiosDefaultResponseInterceptor[1]
  );

export * from './otpApi';
export * from './orderApi';
export * from './consumerApi';
export * from './cardsApi';
export * from './transactionsApi';
export * from './systemApi';
export * from './paymentApi';
export * from './macApi';
