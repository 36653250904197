import amplitude from 'amplitude-js';
import { events } from './events';

export class Amplitude {
  static initialize(): void {
    const amplitudeApiKey = process.env.REACT_APP_AMPLITUDE_API_KEY || '';
    amplitude.getInstance().init(amplitudeApiKey);
  }

  static identifyUser(userId: string): void {
    if (userId) {
      amplitude.getInstance().identify(new amplitude.Identify().set('ConsumerID', userId));
    }
  }

  static async sendEvent(name: string, params?: any): Promise<void> {
    const eventName = events[name] || name;

    await amplitude.getInstance().logEvent(eventName, {
      source: 'NPG',
      ...params,
    });
  }
}
