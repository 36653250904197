import React, {useRef} from 'react';
import styled from 'styled-components';
import Text from './Text';
import { Error } from './TextareaForm/textareaForm.styles';

const Container = styled('div')<{ width?: string; inline?: boolean }>`
  position: relative;
  height: 60px;
  padding: 10px 0;
  ${props => (props.width ? `width: ${props.width}` : 'width: 100%')}
  ${props => props.inline && 'display: inline-block;'}
`;

const InputStyled = styled('input')`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  border: 1px solid #c4c4c4;
  padding-left: 15px;
  padding-top: 20px;
  font-weight: bold;
  box-sizing: border-box;
`;

type TextInputProps = {
  children: any;
  onChange?: (val: string) => void;
  defaultValue?: string;
  value?: string;
  width?: string;
  inline?: boolean;
  disabled?: boolean;
  list?: string;
  type?: string;
  errorMsg?: string;
};

const TextInput: React.FC<TextInputProps> = (props: TextInputProps) => {
  const ref = useRef<HTMLInputElement>(null);

  const {
    children,
    defaultValue,
    onChange,
    value,
    width,
    inline,
    disabled,
    list,
    type,
    errorMsg,
  } = props;

  const onClick = () => {
    if(ref && ref.current) {
      ref.current.focus();
    }
  };

  return (
    <>
      <Container width={width} inline={inline} onClick={onClick}>
        <Text className="inputTitle"> {children} </Text>
        <InputStyled
          ref={ref}
          type={type}
          autoComplete={list ? 'off' : undefined}
          list={list}
          disabled={disabled}
          onChange={e => onChange?.(e.target.value)}
          defaultValue={defaultValue}
          value={value}
        />
        {errorMsg && (
          <Error>
            <Text fontSize={'14px'} className="red">
              {errorMsg}
            </Text>
          </Error>
        )}
      </Container>
    </>
  );
};

export default TextInput;
