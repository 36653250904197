import React from 'react';
import { CheckboxContainer } from './checkbox.styles';
import Text from 'components/Text';
import { CheckboxProps } from '../types';
import DangerousHTMLContainer from 'components/DangerousHTMLContainer';

const Checkbox: React.FC<CheckboxProps> = (props: CheckboxProps) => {
  const { labelMsgId, checked, onChange, id, content, format = 'text' } = props;

  return (
    <CheckboxContainer className={(props.isError && 'hasError') || undefined}>
      <input type="checkbox" checked={checked} onChange={onChange} id={id} className={labelMsgId} />
      <label htmlFor={id}>
        {format === 'text' && (
          <Text messageId={labelMsgId} type='subcontent' className='light' />
        )}

        {format === 'html' && <DangerousHTMLContainer content={content} className="black-links" />}
      </label>
    </CheckboxContainer>
  );
};

export default Checkbox;
