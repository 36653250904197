import { put, call, select, take } from 'redux-saga/effects';
import { PayloadAction } from 'redux-starter-kit';
import { pinCodeLogin, checkPinCode } from 'legacy/api';
import { history } from 'legacy/routing/history';
import { selectors as loginSelectors } from 'legacy/store/login';
import { actions as orderActions } from 'legacy/store/order'; 
import { AuthService } from 'legacy/utils/AuthService';
import { ON_BOARDING } from 'legacy/constants/pathnames';
import { Analytics } from 'legacy/utils/Analytics';
import { actions } from '../index';
import { actions as consumerActions } from 'legacy/store/consumer';
import { PinLoginPayload } from 'legacy/types/User';

/** handle pin code validation */
export function* pinCodeValidationSaga(): any {
  while (true) {
    const { payload: { pinCode, rememberMe } }: PayloadAction<PinLoginPayload> = yield take(
      actions.requestPinCodeValidation
    );

    const pinCodeString = pinCode.toString();

    try {
      const user = yield select(loginSelectors.userSelector);
      const isLoggedIn = yield select(loginSelectors.isLoggedInSelector);

      if (isLoggedIn) {
        const data = yield call(checkPinCode, pinCodeString);
        if (!data.result) {
          throw new Error();
        }
      } else {
        Analytics.sendEvent('Login PINCode Sent by user');
        const authResponce = yield call(pinCodeLogin, user.mobile, pinCodeString, rememberMe);
        yield call(AuthService.setToken, authResponce.token);
        
        yield put(orderActions.tryToGetOrderDetails());
      }
      
      yield put(actions.successPinCodeValidation());
      yield put(consumerActions.requestConsumerInfo());

      history.push(ON_BOARDING);
    } catch (err) {
      yield call(Analytics.sendEvent, 'Login PINCode Failed');
      yield put(actions.errorPinCodeValidation());

      if (err.statusCode === 403) {
        const { maxAuthAttempts, authAttempts, remainingTime } = err;
        yield put(actions.setValidationAuthData({ maxAuthAttempts, authAttempts, remainingTime }));
        yield call(Analytics.sendEvent, 'User blocked for mutiple Pincode failures');
      }
    }
  }
}
