import React from 'react';

import flow from 'apps/UXFlow/store/flow';
import { forceMobileFeatures, isMobileOperatingSystem } from 'utils/ui';

import { HiddenInput, UploadElement, UploadImage } from './styles';

import uploadIcon from 'assets/ocr/icon-upload.svg';
import { truncateSync } from 'fs';

function useCameraFunc(): boolean {
  const useFor = process.env.REACT_APP_ENABLE_OCR_CAMERA_FOR;

  if (!useFor) {
    return forceMobileFeatures() || false;
  }

  return useFor.split(',').some(os => isMobileOperatingSystem(os));
}

type UploadType = {
  loading: boolean;
  error: boolean;
  onUpload: (file: File) => void;
  isVertical: boolean;
  setIsCameraUsed: (isUsed: boolean) => void;
};

const Upload: React.FC<UploadType> = (props: UploadType) => {
  const { loading, error, setIsCameraUsed, onUpload, isVertical } = props;

  const htmlId = 'upload_ocr';
  const useCamera = useCameraFunc();

  const handleUploadElClick = (): void => {
    if (useCamera) {
      setIsCameraUsed(true);
    }
  };

  const handleInputChange = (event: React.ChangeEvent): void =>
    onUpload((event.target as HTMLInputElement).files![0]);

  return (
    <>
      <UploadElement htmlFor={htmlId} disabled={loading} onClick={handleUploadElClick}>
        <UploadImage src={uploadIcon} isVertical={isVertical} flash={!!error} />
      </UploadElement>
      {!loading && !useCamera && (
        <HiddenInput
          id={htmlId}
          accept={flow.data?.supportedFormats}
          type="file"
          onChange={handleInputChange}
        />
      )}
    </>
  );
};

export default Upload;
