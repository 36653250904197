import { put, takeLatest, select, call } from 'redux-saga/effects';
import { PayloadAction } from 'redux-starter-kit';
import { loginOtp } from 'legacy/api';
import { history } from 'legacy/routing/history';
import { ON_BOARDING } from 'legacy/constants/pathnames';
import { Analytics } from 'legacy/utils/Analytics';
import { AuthService } from 'legacy/utils/AuthService';
import { selectors as loginSelectors } from 'legacy/store/login';
import { actions as orderActions } from 'legacy/store/order';
import { actions } from '../index';
import { OtpLoginPayload } from 'legacy/types/User';

/** handle otp login event */
function* otpLoginSagaHandler(action: PayloadAction<OtpLoginPayload>): any {
  const { payload: { otp, rememberMe } } = action;
  const otpFormatted = otp.toString();

  try {
    const user = yield select(loginSelectors.userSelector);

    const { token } = yield call(loginOtp, user.mobile, otpFormatted, rememberMe);
    yield call(AuthService.setToken, token);
    yield call(Analytics.sendEvent, 'Login OTP Approved');

    yield put(orderActions.tryToGetOrderDetails());

    yield put(actions.successOtpValidation());
    history.push(ON_BOARDING);
  } catch (err) {
    yield put(actions.errorOtpValidation());
    yield call(Analytics.sendEvent, 'Login OTP Failed');
  }
}

export function* otpLoginSaga() {
  yield takeLatest(actions.requestOtpValidation, otpLoginSagaHandler);
}
