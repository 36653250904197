import React from 'react';
import styled from 'styled-components';

import Text from 'components/Text';
import { MappedUXFlowErrors } from 'types/data';
import PageContainer from 'apps/UXFlow/components/PageContainer/DE';
import { AppProps } from 'types/apps';
import Input from 'components/InputForm';

interface Props {
  inputId: string;
  shouldAutofocus: boolean;
  changeOtp: (otp: string | number) => void;
  otp: string | number;
  errors: MappedUXFlowErrors;
  maxLength: number;
  loading: boolean;
  resendOtpHandler: () => void;
  phoneNumber: string;
  handleSubmit: () => void;
  resendDisabled: boolean;
  isNextDisabled: boolean;
}

const DEOTPView: React.FC<Props & AppProps> = (props: Props & AppProps) => {
  const {
    changeOtp,
    otp,
    errors,
    loading,
    resendOtpHandler,
    phoneNumber,
    handleSubmit,
    resendDisabled,
  } = props;

  return (
    <PageContainer
      titleMessageId="title"
      buttonMessageId="next"
      buttonOnClick={handleSubmit}
      buttonIsLoading={loading}
      errorContent={errors['InvalidOTP']?.message}
      goBack={props.goBack}
      backBtnEnabled={props.backBtnEnabled}
      onClose={props.onClose}
      buttonDisabled={props.isNextDisabled}
      buttonStyles={{ bottom: '18.5%' }}
    >
      <PhoneText>
        <Text messageId="subtitle" type="content" />
        <Text content={` ${phoneNumber}`} type="content" weight="600" />
      </PhoneText>

      <Input
        id="login_otp"
        value={otp}
        changeHandler={changeOtp}
        placeholderMessageId="OTPPlaceholder"
      />

      <ResendContainer>
        <ResendOTPLink
          disabled={resendDisabled}
          messageId="resendBtnLabel"
          type="content"
          className={resendDisabled ? '' : 'pointer underline'}
          onClick={resendOtpHandler}
        />
      </ResendContainer>
    </PageContainer>
  );
};

const ResendContainer = styled.div`
  margin-top: 30px;
`;

const ResendOTPLink = styled(Text) <{ disabled?: boolean }>`
  ${props =>
    props.disabled &&
    `
    color: #c5c5c5;
    cursor: default;
  `}
`;

const PhoneText = styled.span`
  text-align: center;
  margin-bottom: 20px;
`;

export default DEOTPView;
