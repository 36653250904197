import { selectors } from "legacy/DFE/store/order";
import { store } from "legacy/DFE/store/store";
import { AbstractAPIHandler } from ".";
import { OnboardingFlowQuery } from "./types/consumer";
import { Analytics } from "../utils/analytics";

function getOnboardingQueryParams(offset: number) {
    const state = store.getState();

    const orderId = selectors.orderId(state);

    const query: OnboardingFlowQuery = {
        type: "onboarding_nsp",
        client: "npg",
        source: "web",
        paymentType: selectors.order(state)?.type as any, // Because of router validations, It must exist
        channel: "ecommerce", // Might to need to get it dynamically somehow, Lets see
        paymentId: orderId,
        paymentRequestId: orderId,
        tmpPaymentRequestId: orderId,
        offset: offset,
    }

    return query;
}

export class ConsumersAPIHandler extends AbstractAPIHandler {
    constructor() {
        super("consumers")
    }

    public getFlow(offset: number = 0) {
        return this.get("flow", getOnboardingQueryParams(offset));
    }

    public postFlow(data: any, offset: number = 0) {
        return this.post("flow", data, getOnboardingQueryParams(offset));
    }

    public getProvinces() {
        return this.get("getProvinces");
    }

    public getCities(province: string, filter: string = "") {
        return this.get("getCities", { province, filter });
    }

    public getZipCodes(provinceCode: string, city: string) {
        return this.get("getZipCodes", { provinceCode, city });
    }

    public async getInformation() {
        const info = await this.get("getInformation");

        Analytics.identifyUser(info.id);

        return info;
    }
}
