import { store } from 'legacy/DFE/store/store';
import { actions as UIActions } from 'legacy/DFE/store/UI';
import React, { useEffect, useState } from 'react';
import { Route } from './RouterTypes';
import { connect, ConnectedProps } from 'react-redux';
import { actions as RouterActions, selectors } from 'legacy/DFE/store/router';
import { State } from 'legacy/DFE/store/state';
import Logger from 'legacy/DFE/utils/logger';
import { Analytics } from 'legacy/DFE/utils/analytics';

const logger = new Logger('Router');

const mapStateToProps = (state: State) => ({
  submittedRoute: selectors.submittedRoute(state),
});

const mapDispatchToProps = {
  setSpinner: UIActions.setSpinner,
  setPage: RouterActions.setPage,
  setSubmittedRoute: RouterActions.setSubmittedRoute,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type RouterProps<ParentPropsType extends object = {}, PreprocessType extends object = {}> = {
  parentProps?: ParentPropsType;
  routes: Route<ParentPropsType, PreprocessType>[];
  onDone?: () => void;
  onChangeRoute?: (route: Route) => void;
} & PropsFromRedux;

/**
 * Checking what the next component is & running its preprocess function
 */
async function chooseComponent<PreprocessType extends object = {}>(routes: Route<any>[]): Promise<{ route: Route | undefined, preprocessData: PreprocessType | void }> {
  for (const route of routes) {
    logger.debug(`Checking ${route.name}`);
    if (await route.precondition(store.getState())) {
      logger.info(`Found next component: ${route.name}. Preprocessing it.`);
      const preprocessData = await route.preprocess(store.getState());
      logger.info("Preprocess done.");

      return {
        route: route as any,
        preprocessData: preprocessData as any,
      };
    }
  }

  return {
    route: undefined,
    preprocessData: undefined,
  };
}

function Router<ParentPropsType extends object = {}, PreprocessType extends object = {}>(props: RouterProps<ParentPropsType, PreprocessType>) {
  const { routes, parentProps, submittedRoute, setSpinner, setPage, onDone, onChangeRoute, setSubmittedRoute, } = props;

  const [currentComponentName, setCurrentComponentName] = useState<string>('');
  const [preprocessData, setPreprocessData] = useState<any>({});

  /**
   * Setting the next component to the Router state
   */
  function nextComponent() {
    setSpinner(true);

    logger.info('Looking for next component');

    chooseComponent<PreprocessType>(routes)
      .then(({ route, preprocessData }) => {
        if (route) {
          // If we found the next route, We're going to set it to the state & change the URL if needed
          setPreprocessData(preprocessData);
          setCurrentComponentName(route.name);
          route.url && setPage(route.url);

          // Calling parent's onChange
          onChangeRoute?.(route);

          // Sending Analytics if everythg is ok
          Analytics.sendEvent(`Page ${route.name} visited`);
        } else {
          logger.info('No more components to load. running onDone.');
          onDone && onDone();
          setCurrentComponentName('');
        }
      })
      .catch(() => {
        //TODO Handle errors
      })
      .finally(() => {
        setSpinner(false);
      });
  }

  useEffect(() => {
    nextComponent();
  }, []);

  function done() {
    nextComponent();
  }

  function useSubmit(callback: () => void) {
    useEffect(() => {
      if (currentComponentName === submittedRoute) {
        logger.info(`Submitting ${currentComponentName}`);
        callback();
        setSubmittedRoute("");
      }
    }, [submittedRoute])
  }

  const route = routes.find(route => route.name === currentComponentName);

  if (route) {
    const Component = route.component as any; // TODO: fix ANY (ConnectedComponent<React.FC<any>, any> | React.FC<any>)

    return <Component useSubmit={useSubmit} done={done} {...parentProps} {...preprocessData} />;
  }

  return <> </>;
}

export default connector(Router);
