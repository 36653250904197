import { Route } from "legacy/DFE/routing/RouterTypes";
import { selectors } from "legacy/DFE/store/auth";
import { State } from "legacy/DFE/store/state";
import LoggedInComponent from "./index.component";

export default class LoggedIn extends Route {
  constructor() {
    super("LoggedIn", '/');
  }

  async precondition(state: State) {
    return !state.Auth.mobile && !!selectors.authToken(state);
  }

  component = LoggedInComponent;
}
