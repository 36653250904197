import React from 'react';
import { AppConfigurationType } from 'types/apps';
import flow from '../store/flow';
import Card from './Card';
import Login from './Login';
import Complete from './Complete';

function generateApps(components: { [key: string]: React.FC<any> }): AppConfigurationType[] {
  return Object.entries(components).map(([name, component]) => ({
    Component: component,
    precondition: async () => {
      return flow.step === name;
    },
  }));
}

export default generateApps({
  DE_ECP_ChangeCard: Card,
  DE_ECP_ChangeCard_Login: Login,
  Complete,
});
