import React, { useEffect } from 'react';
import { AppComponentProps } from 'types/apps';
import { ParentProps } from '../types';
import flow from 'apps/ChangeCard/store/flow';
import PageContainer from 'apps/ChangeCard/components/PageContainer/DE';
import Loader from 'components/Loader';
import useSubmitHandler from 'apps/ChangeCard/utils/hooks/useSubmitHandler';

const Login: React.FC<AppComponentProps & ParentProps> = (
  props: AppComponentProps & ParentProps
) => {
  const { onSubmit } = useSubmitHandler(props);

  useEffect(() => {
    onSubmit({ ott: flow.ott });
  }, []);

  return (
    <PageContainer buttonStyles={{ bottom: '18.5%', display: 'none' }}>
      <Loader />
    </PageContainer>
  );
};

export default Login;
