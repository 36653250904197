import { take, put, call } from 'redux-saga/effects';
import { USER_KEY } from 'legacy/constants/storageKeys';
import { FLOW } from 'legacy/constants/pathnames';
import { actions as UIActions } from 'legacy/store/UI';
import { SessionStorageManager } from 'legacy/utils/SessionStorageManager';
import { actions as loginActions } from 'legacy/store/login';
import { validateConsumer } from 'legacy/api';
import { history } from 'legacy/routing/history';

/** otpValidation/pinCodeValidation page loaded */
export function* loginPageLoadedSaga(): any {
  while (true) {
    yield take(loginActions.getLoginPageData);

    const invalidUserErr = new Error('User is invalid');

    try {
      yield put(UIActions.setIsLoading(true));

      const user = yield call(SessionStorageManager.getItem, USER_KEY);
      if (!user || !user.mobile) {
        throw invalidUserErr;
      }

      const { data: validatedUser } = yield call(validateConsumer, user.mobile);
      if (!validatedUser) {
        throw invalidUserErr;
      }
      const { maxAuthAttempts, authAttempts, remainingTime, mobile, isLoginFlow } = validatedUser;

      yield put(loginActions.setUser({ mobile, isLoginFlow }));

      if (remainingTime && remainingTime >= 0) {
        yield put(loginActions.errorPinCodeValidation());
        yield put(
          loginActions.setValidationAuthData({ maxAuthAttempts, authAttempts, remainingTime })
        );
      }
    } catch (err) {
      yield call(SessionStorageManager.removeItem, USER_KEY);
      history.push(FLOW);
    } finally {
      yield put(UIActions.setIsLoading(false));
    }
  }
}
