import { createSlice, PayloadAction } from 'redux-starter-kit';
import { State } from '../state';
import { initialState, ConsumerState } from './state';

const Consumer = createSlice({
  name: 'Consumer',
  initialState,
  reducers: {
    setConsumer: (state, { payload: consumer }: PayloadAction<ConsumerState>): ConsumerState => ({
      ...state,
      ...consumer,
    }),
  },
});

export const { reducer } = Consumer;

export const actions = {
  ...Consumer.actions,
};

/** selectors */
const getConsumerState = (state: State): ConsumerState => state.Consumer;

export const selectors = {
  getConsumerState,
};
