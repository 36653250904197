import styled from 'styled-components';
import { SelectStyledProps } from './selectForm.component';

export const SelectWrapper = styled('div')<SelectStyledProps>`
  width: 100%;

  .css-yk16xz-control,
  .css-1fhf3k1-control,
  .css-1pahdxg-control {
    border: 1px solid #e1dfe9;
    border-radius: 5px;
    font-size: 0.94;
    height: 45px;
    color: #000000;
    box-shadow: none;

    &:hover {
      border: 1px solid #e1dfe9;
      box-shadow: none;
    }

    .css-1okebmr-indicatorSeparator,
    .css-109onse-indicatorSeparator {
      background-color: transparent;
    }
  }

  .css-1hwfws3 {
    padding-left: 13px;
    padding-right: 0;
  }
`;

export const DropdownImage = styled('img')`
  height: 12px;
`;

export const SelectFormStyled = styled('div')<SelectStyledProps>`
  position: relative;
  max-width: ${props => (props.maxWidth ? props.maxWidth : '340px')};
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Error = styled('div')`
  white-space: normal;
  word-break: break-word;
  position: absolute;
  top: 45px;
  width: 100%;
  text-align: center;
`;
