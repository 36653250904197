import styled from 'styled-components';
import { isIframe } from 'helpers/redirection';

export const Background = styled('div')`
  @media (min-width: 550px) {
    display: flex;
    align-items: center;
    justify-content: center;
    // background: linear-gradient(-45deg, #fafafa, #747475);
  }

  width: 100vw;
  height: ${isIframe() ? '' : '100vh'};
`;

export const Container = styled('div')`
  position: relative;
  width: 100%;
  background: #fff;

  padding: 60px 0 30px 0;

  @media (min-width: 550px) {
    max-width: ${isIframe() ? '' : '580px'};
    padding: ${isIframe() ? '' : '60px 20px 20px 20px'};
    border-radius: ${isIframe() ? '' : '0px'};
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
  }

  @media (max-width: 550px) {
    min-height: 100vh;
  }

  align-items: center;
  display: flex;
  flex-direction: column;

  & * {
    font-family: 'Futura PT', sans-serif !important;
  }
`;

export const ContentWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 10px 0 10px;
`;
