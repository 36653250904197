import { ConsumersAPI } from "legacy/DFE/api/handlers";
import { Route } from "legacy/DFE/routing/RouterTypes";
import { State } from "legacy/DFE/store/state";
import { store } from "legacy/DFE/store/store";
import { actions } from "legacy/DFE/store/UXFlow";
import OnboardingComponent from "./index.component";
import { PreprocessType } from "./types";

export default class Onboarding extends Route<{}, PreprocessType> {
    constructor() {
        super("Onboarding", "/onboarding");
    }

    async preprocess(_state: State) {
        const flow = await ConsumersAPI.getFlow(0);


        store.dispatch(actions.setFlow(flow));
    }

    component = OnboardingComponent;
}