import { store } from 'legacy/store/store';
import { Channels } from 'legacy/types/Payment';
import { ChannelManager } from 'legacy/utils/ChannelManager';

const mapBranchToChannel = (branch?: string): Channels | undefined => {
  if (!branch) return;
  
  branch = branch.toLowerCase(); // Is it always lower cased? Feels safer like it

  return {
    "ecommerce": Channels.ecommerce,
    "merchant_mobile_app": Channels.mapp,
  }[branch];
}

export const getChannel = () => {
  const branch = store.getState().order.orderDetails?.branch;

  const channel = mapBranchToChannel(branch) || ChannelManager.getChannel() || null;

  return {
    isEcommerceChannel: channel === Channels.ecommerce,
    isMerchantAppChannel: channel === Channels.mapp,
    channel
  };
};

export const isOpenedInIframe = () => {
  if (window.location !== window.parent.location) {
    return true;
  }
  return false;
};
