import styled from 'styled-components';

export const PhoneNumberField = styled('div')`
  width: 100%;
  display: flex;
  margin: 0 auto;
  max-width: 340px;
  margin-bottom: 20px;
  position: relative;
  font-size: 18px;

  & > div {
    margin-bottom: 0;
  }

  &::before {
    content: '';
    position: absolute;
    height: 40px;
    width: 1px;
    border-right: solid 1px #dcdceb;
    left: 115px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
  }

  .css-yk16xz-control,
  .css-yk16xz-control:hover,
  .css-1pahdxg-control,
  .css-1pahdxg-control:hover {
    border-right: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  input {
    border-left: 0px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
`;

export const CheckboxWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SelectOptionWrapper = styled('div')`
  display: flex;
  align-items: center;

  img {
    width: 25px;
    height: auto;
    margin-right: 5px;
  }
`;

export const Error = styled('div')`
  position: absolute;
  bottom: -20px;
  white-space: normal;
  word-break: break-word;
  width: 100%;
  text-align: center;
  top: 50px;
  font-size: 15px;
`;

export const CenterContainer = styled('div')`
  display: flex;
  justify-content: center;
`;

export const TitleContainer = styled('div')`
  margin-bottom: 20px;
`;
