import React from 'react';
import { State } from 'legacy/store/state';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FAQ } from 'legacy/constants/pathnames';
import { actions as UIActions, selectors as UISelectors } from 'legacy/store/UI';
import FormattedMessage from 'legacy/components/FormattedMessage';
import MerchantSiteLink from 'legacy/components/MerchantSiteLink';
import { PaymentStatus } from 'legacy/types/PaymentStatus';
import { ProcessStatus } from 'legacy/types/PaymentProcessing';

const FooterWrapper = styled('footer')`
  padding: 10px 18px;
`;

const CancelLink = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

const SubMenu = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  a {
    display: inline-flex;
  }

  a:not(:last-child)::after {
    content: '|';
    margin: 0 20px;
    color: #8683a4;
  }
`;

const mapStateToProps = (state: State) => ({
  isLoading: UISelectors.loadingSelector(state),
  orderExpired: state.order.orderDetails?.orderExpired,
  paymentStatus: state.transaction.transaction.paymentStatus,
  processStatus: state.paymentFlow.payment?.processingStatus,
  isPorting: UISelectors.isPorting(state),
});

const mapDispatchToProps = {
  openPopup: UIActions.openPopup,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Footer: React.SFC<PropsFromRedux> = ({
  openPopup,
  isLoading,
  orderExpired,
  isPorting,
  paymentStatus,
  processStatus,
}: PropsFromRedux) => {
  const openContactUsForm = () => {
    openPopup('contact-us');
  };

  if (isPorting) {
    return <> </>;
  }

  const hideCancelLink =
    (!orderExpired &&
      (paymentStatus === PaymentStatus.customerApproved ||
        paymentStatus === PaymentStatus.done ||
        processStatus === ProcessStatus.Authorized)) ||
    orderExpired;

  return (
    <FooterWrapper>
      {!hideCancelLink && (
        <CancelLink>
          <MerchantSiteLink isDisabled={isLoading} />
        </CancelLink>
      )}
      <SubMenu>
        <Link to={FAQ} target="_blank">
          <FormattedMessage messageId="FAQ" className="light" id="FAQ" />
        </Link>
        <FormattedMessage
          messageId="contact-us"
          className="light link"
          onClick={openContactUsForm}
          id="contact-us"
        />
      </SubMenu>
    </FooterWrapper>
  );
};

export default connector(Footer);
