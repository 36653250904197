import { createSlice, PayloadAction, createAction } from 'redux-starter-kit';
import { Dossier } from 'legacy/types/Dossier';
import { PaymentStatus } from 'legacy/types/PaymentStatus';
import { initialTransactionDetailsState, TransactionDetailsState } from './state';

/** transaction details slice */
const transactionDetailsSlice = createSlice({
  name: 'transactionDetails',
  initialState: initialTransactionDetailsState,
  reducers: {
    setTransactionId: (
      state,
      { payload: transactionId }: PayloadAction<string>
    ): TransactionDetailsState => ({
      ...state,
      transactionId,
    }),
    setPaymentStatus: (
      state,
      { payload: paymentStatus }: PayloadAction<PaymentStatus>
    ): TransactionDetailsState => ({
      ...state,
      paymentStatus,
    }),
    setConfirmationNumber: (
      state,
      { payload: confirmationNumber }: PayloadAction<string>
    ): TransactionDetailsState => ({
      ...state,
      confirmationNumber,
    }),
    setDossier: (state, { payload: dossier }: PayloadAction<Dossier>): TransactionDetailsState => ({
      ...state,
      dossier,
    }),
  },
});

export const { reducer } = transactionDetailsSlice;

const requestTransactionStatus = createAction('requestTransactionStatus');

export const actions = {
  ...transactionDetailsSlice.actions,
  requestTransactionStatus,
};
