import React, { useState, useEffect } from 'react';

// import { Analytics } from 'legacy/utils/Analytics';
import useEventListener from 'apps/UXFlow/utils/hooks/useEventListener';
import { AppComponentProps, AppProps } from 'types/apps';
import { ParentProps } from '../types';

import useSubmitHandler from 'apps/UXFlow/utils/hooks/useSubmitHandler';
import flow from 'apps/UXFlow/store/flow';
import ui from 'store/ui';
import DEView from './DE';
import DefaultView from './Default';

import { DESIGN } from 'types/design';

const DEPaymentComplete: React.FC<AppComponentProps & ParentProps & AppProps> = (
  props: AppComponentProps & ParentProps & AppProps
) => {
  const { onSubmit, loading } = useSubmitHandler(props);

  const { errors, data } = flow;

  // useEffect(() => {
  //   // Analytics.sendEvent('LoginOTPApprovalPageVisited');
  // }, []);

  const handleSubmit = (): void => {
    // Analytics.sendEvent('Login OTP Sent by user');
    onSubmit({});
  };

  useEventListener('keypress', handleSubmit);

  const PaymentComponents = {
    [DESIGN.DE]: DEView,
    [DESIGN.default]: DefaultView,
  };

  const Payment = PaymentComponents[ui.design];

  return (
    <Payment
      data={data}
      errors={errors}
      loading={loading}
      handleSubmit={handleSubmit}
      goBack={props.goBack}
      backBtnEnabled={props.backBtnEnabled}
      onClose={props.onClose}
    />
  );
};

export default DEPaymentComplete;
