import { State } from 'legacy/DFE/store/state';
import { selectors } from 'legacy/DFE/store/router';
import { store } from 'legacy/DFE/store/store';
import Logger from 'legacy/DFE/utils/logger';

//
// Route Types
//

export type RouteComponentType = {
  /**
   * This function pretty much tells the Router to check again what the next component is
   */
  done: () => void;
  /**
   * This function tells the component when the PARENT wants it to submit itself
   */
  useSubmit: (callback: () => void) => void;
};

export abstract class Route<ParentPropsType extends object = {}, PreprocessType extends object = {}> {
  /**
   *
   * @param name For inner use of the routing system. Choose whatever unique name you want.
   * @param url The url path displayed (for example, if it's '/faq', the url will change to 'URL/faq'). Undefined will let the url to stay as it is currently.
   */
  constructor(public name: string, public url?: string) {
    //
  }

  /**
   * Return true in case you want to display the component
   */
  async precondition(state: State): Promise<boolean> {
    return this.checkURL();
  }

  /**
   * Allows you to run some code before displaying the component
   * During this time, The Router going to show a spinner so you don't have to take care of it
   */
  async preprocess(state: State): Promise<PreprocessType | void> {

  }

  //TODO Changed to 'any' because it made some bugs with the ParentProps, I'll take care of it in the future but it's not urgent now
  abstract component: any; // React.FC<RouteComponentType & ParentPropsType & PreprocessType>;

  // Kind of utils

  checkURL(url?: string) {
    url = url || this.url;
    const currentPage = selectors.currentPage(store.getState());

    Logger.debug("RouterType", `Checking URL. currentPage: ${currentPage} | routeURL: ${url}`)

    return currentPage.startsWith(url!);
  }
}