import { AppState } from './AppState';
import { ChannelManager } from 'legacy/utils/ChannelManager';
import { Analytics } from 'legacy/utils/Analytics';
import { Channels } from 'legacy/types/Payment';
import { Mode } from 'legacy/types/System';
import { getSystemStatus } from 'legacy/api';
import { Logger } from 'legacy/utils/Logger';

export class SupportPortalState extends AppState {
  async handle(): Promise<void> {
    ChannelManager.setChannel(Channels.selfLandingPage);
    Analytics.initialize();

    let systemStatus;

    try {
      systemStatus = await getSystemStatus();
      if (systemStatus?.mode !== Mode.Live) {
        this.renderMaintenanceApp();
        return;
      }
    } catch (err) {
      Logger.error(`Error: ${JSON.stringify(err)}`);
      this.renderMaintenanceApp();
      return;
    }

    this.render({ supportPortal: true });
    return;
  }
}
