export interface ConsumerAddress {
  provinces: Array<string>;
  cities: Array<string>;
  zipcodes: Array<string>;
}

export const initialConsumerAddress: ConsumerAddress = {
  provinces: [],
  cities: [],
  zipcodes: [],
};
