import styled from 'styled-components';

type DotsFlashingProps = {
  background?: string;
};

const DotsFlashing = styled('div')<DotsFlashingProps>`
  background: ${props => (props.background ? props.background : '#da291c')};
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${props => (props.background ? props.background : '#da291c')};
  color: ${props => (props.background ? props.background : '#da291c')};
  animation: dotFlashing 0.5s infinite linear alternate;
  animation-delay: 0.2s;

  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${props => (props.background ? props.background : '#da291c')};
    color: ${props => (props.background ? props.background : '#da291c')};
    animation: dotFlashing 0.5s infinite alternate;
    animation-delay: 0s;
  }

  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${props => (props.background ? props.background : '#da291c')};
    color: ${props => (props.background ? props.background : '#da291c')};
    animation: dotFlashing 0.5s infinite alternate;
    animation-delay: 0.4s;
  }

  @keyframes dotFlashing {
    0% {
      background-color: ${props => (props.background ? props.background : '#da291c')};
    }

    50%,
    100% {
      background-color: #ffffff;
    }
  }
`;

export default DotsFlashing;
