/**
 * Managing intermediate inputs data
 */
export class InputsDataService {
  static formatId(id: string): string {
    return `ids_input_${id}`;
  }

  static save(id: string, value: any): void {
    sessionStorage.setItem(this.formatId(id), value);
  }

  static get(id: string): any {
    return sessionStorage.getItem(this.formatId(id));
  }
}
