import CenterContainer from 'components/CenterContainer';
import Line from 'components/Line';
import Text from 'components/Text';
import React, { useState } from 'react';
import { Links, PoweredByContainer, PoweredByLogo } from './styles';
import PagolightLogo from 'legacy/assets/logos/pagolight-2.png';
import flow from 'apps/UXFlow/store/flow';
import ContactUs from 'components/Popup/ContactUs';
import ContactUsSuccess from 'components/Popup/ContactUsSuccess';
import CancelConfirmation from 'components/Popup/CancelConfirmation';
import { DESIGN } from 'types/design';
import ui from 'store/ui';
import styled from 'styled-components';

enum PopupName {
  contactUs = 'contactUs',
  contactUsSuccess = 'contactUsSuccess',
  cancelConfirmation = 'cancelConfirmation',
}

interface Props {
  hideCopyright: boolean;
  openContactUs: () => void;
}

const Footer: React.FC<Props> = (props: Props) => {
  const { data, errors } = flow;
  const hideCopyright = props.hideCopyright || false;
  const [popupName, setPopupName] = useState<PopupName | ''>('');
  const IsDefault = ui.design === DESIGN.default;

  const closePopup = (): void => setPopupName('');
  const openContactUsSuccessPopup = (): void => setPopupName(PopupName.contactUsSuccess);
  const openCancelConfirmationPopup = (): void => setPopupName(PopupName.cancelConfirmation);

  const isPaymentExpiredPage =
    data?.isExpired || errors['ProcessExpiredError'] || errors['PaymentRequestExpired'];

  const showCancelBtn = !data?.hideFooterCancelLink && !isPaymentExpiredPage;

  return (
    <CenterContainer>
      {IsDefault &&
        showCancelBtn && (
          <Line>
            <FooterText
              type="content"
              messageId="cancel"
              className="light link"
              onClick={openCancelConfirmationPopup}
            />
            <FooterText type="content" messageId="cancelDetails" className="light" />
          </Line>
        )}

      <Line>
        <Links>
          {/* Temporary solution for MVP (NOOSA-48689) */}
          {IsDefault && (
            <FooterText messageId="FAQ" url={ui.styles.faqLinkUrl} className="pointer uppercase" />
          )}

          {flow.step !== 'ContactUs' && (
            <FooterText
              messageId="contactUs"
              className="pointer uppercase"
              onClick={props.openContactUs}
            />
          )}
        </Links>
      </Line>

      {/* <ContactUs
        isOpen={popupName === PopupName.contactUs}
        onClose={closePopup}
        onSubmit={openContactUsSuccessPopup}
      /> */}

      <ContactUsSuccess isOpen={popupName === PopupName.contactUsSuccess} onClose={closePopup} />
      <CancelConfirmation
        isOpen={popupName === PopupName.cancelConfirmation}
        onClose={closePopup}
      />

      {!hideCopyright && <FooterText messageId="copyright" className="light" />}

      {IsDefault && (
        <PoweredByContainer>
          <FooterText messageId="poweredBy" className="light" />
          <PoweredByLogo src={PagolightLogo} />
        </PoweredByContainer>
      )}
    </CenterContainer>
  );
};

const FooterText = styled(Text)`
  color: #6d6d6d;
`;

export default Footer;
