import { Order } from 'legacy/types/Order';

export interface OrderState {
  orderDetails: Order | null;
  isOrderLoading: Boolean;
  isPaymentClosed: Boolean;
}

export const initialState: OrderState = {
  orderDetails: null,
  isOrderLoading: false,
  isPaymentClosed: false,
};
