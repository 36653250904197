import React from 'react';
import WithSidebarImages from './WithSidebarImages';
import Container from './Container';
import Footer from 'components/Footer';
import Logo from 'components/Logo';

type Props = {
  children: React.ReactNode;
};

const Box: React.FC<Props> = (props: Props) => {
  const { children } = props;

  return (
    <WithSidebarImages>
      <Logo />
      <Container>{children}</Container>
      <Footer hideCopyright={false} openContactUs={() => {}} />
    </WithSidebarImages>
  );
};

export default Box;
