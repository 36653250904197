export interface ContactUsState {
  pending: boolean;
  error: boolean;
  isAuthorizedForm: boolean | null;
}

export const initialContactUsState: ContactUsState = {
  pending: false,
  error: false,
  isAuthorizedForm: null,
};
