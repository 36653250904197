import { createSlice, PayloadAction } from 'redux-starter-kit';
import { combineReducers } from 'redux';
import { State } from 'legacy/store/state';
import { initialStatusState, StatusState, OtpState } from './state';
import { OtpLoginPayload } from 'legacy/types/User';

/** otp validation slice */
export const otpValidationSlice = createSlice({
  name: 'validateOtp',
  initialState: initialStatusState,
  reducers: {
    requestOtpValidation: (state, action: PayloadAction<OtpLoginPayload>): StatusState => ({
      ...state,
      pending: true,
    }),
    errorOtpValidation: (state): StatusState => ({
      ...state,
      pending: false,
      error: true,
    }),
    successOtpValidation: (state): StatusState => ({
      ...state,
      pending: false,
      error: false,
    }),
    resetOTPState: (state): StatusState => ({
      ...state,
      pending: false,
      error: false,
    }),
  },
});

/** resend otp slice */
export const resendOtpSlice = createSlice({
  name: 'resendOtpSlice',
  initialState: initialStatusState,
  reducers: {
    requestResendOtp: (state): StatusState => ({
      ...state,
      pending: true,
    }),
    errorResendOtp: (state): StatusState => ({
      ...state,
      pending: false,
      error: true,
    }),
    successResendOtp: (state): StatusState => ({
      ...state,
      pending: false,
      error: false,
    }),
  },
});

export const actions = {
  ...otpValidationSlice.actions,
  ...resendOtpSlice.actions,
};

/** selectors */
const getOtpState = (state: State): OtpState => state.otp;
const otpStatusSelector = (state: State): StatusState => getOtpState(state).otpStatus;
const resendOtpStatusSelector = (state: State): StatusState => getOtpState(state).resendOtpStatus;

export const selectors = {
  otpStatusSelector,
  resendOtpStatusSelector,
};

export const reducer = combineReducers({
  otpStatus: otpValidationSlice.reducer,
  resendOtpStatus: resendOtpSlice.reducer,
});
