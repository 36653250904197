import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { State } from 'legacy/store/state';
import { selectors } from 'legacy/store/UI';
import { EnvBannerWrapperStyle, EnvBannerStyle } from './envBanner.styles';

const mapStateToProps = (state: State) => ({
  isPorting: selectors.isPorting(state),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const EnvBanner: React.SFC<PropsFromRedux> = ({ isPorting }: PropsFromRedux) =>
  isPorting ? <> </> :
  <EnvBannerWrapperStyle>
    <EnvBannerStyle>
      {process.env.REACT_APP_BUILD_ENVIRONMENT}
    </EnvBannerStyle>
  </EnvBannerWrapperStyle>;

export default connector(EnvBanner);
