import styled from 'styled-components';

export const InputContainer = styled('div')<{ displayElem: boolean }>`
  width: 50%;
  padding: 5px;
  display: ${props => (props.displayElem ? 'inline-block' : 'none')};
  margin-top: 15px;
`;

export const FullWidthInputContainer = styled('div')<{ displayElem: boolean }>`
  width: 100%;
  padding: 5px;
  display: ${props => (props.displayElem ? 'inline-block' : 'none')};
  margin-top: 15px;
  position: relative;
`;

export const Inputs = styled('div')`
  width: 100%;
  display: flex;
`;

export const Canvas = styled('canvas')`
  display: 'none';
`;
