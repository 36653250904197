import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { State } from 'legacy/store/state';
import { ParentProps } from 'legacy/ChangeCardPortal/types';

import BoxWithBackground from 'legacy/components/BoxWithBackground';
import FlexColumn from 'legacy/components/FlexColumn';
import FormattedMessage from 'legacy/components/FormattedMessage';
import Icon from 'legacy/components/Icon';
import successIcon from 'legacy/assets/checked-black.svg';

const mapStateToProps = (state: State) => ({});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Success: React.FC<PropsFromRedux & ParentProps> = (props: PropsFromRedux & ParentProps) => {
  return (
    <>
      <FormattedMessage messageId="change-card:success-step-title" className="fs-23 mb-10" />
      <BoxWithBackground>
        <FlexColumn>
          <Icon src={successIcon} alt="success-icon" />
          <FormattedMessage
            messageId="change-card:success-step-body-title"
            className="fs-20 pago-red mb-40"
          />
          <FormattedMessage
            messageId="change-card:success-step-body-description"
            className="fs-18"
            params={{ last4digits: props.options?.card?.last4Digits }}
          />
        </FlexColumn>
      </BoxWithBackground>
    </>
  );
};

export default connector(Success);
