import { AppState } from "legacy/init";

export class ForceDFEState extends AppState {
    handle() {
        this.render({
            order: {
                DFEFlowSupport: true
            }
        });
    }
}