import AppManager from 'isRefactorApp';
import React from 'react';
import ReactDOM from 'react-dom';
import Root from 'root';

const main = async (): Promise<void> => {
  await AppManager.init();
  if (AppManager.isRefactorApp) {
    ReactDOM.render(<Root />, document.getElementById('root'));
  } else {
    require('./legacy');
  }
};

main();
