import {
  InvalidState,
  ArbitraryState,
  EcommerceLandingState,
  ChangeCardState,
  SupportPortalState,
  ForceDFEState,
} from './init';
import { LocalStorageManager } from 'legacy/utils/LocalStorageManager';

const init = async (): Promise<void> => {
  const path = window.location.pathname.split('/');
  const id = path[path.length - 1];
  if (
    window.location.pathname.startsWith('/flow') &&
    sessionStorage.getItem('PAYMENT_REQUEST_ID') !== id
  ) {
    sessionStorage.clear();
    sessionStorage.setItem('PAYMENT_REQUEST_ID', id);
  }

  const isEcommerceChannel = window.location.pathname.startsWith('/flow') || window.location.pathname.startsWith('/ocr/');
  const isMerchantsRoute = window.location.pathname.startsWith('/merchants');
  const isChangeCardChannel = window.location.pathname.startsWith('/card/change/t/');
  const isSupportPortal = window.location.pathname.startsWith('/support-portal');

  const existedOrderId = LocalStorageManager.getItem('orderId') || '';

  if (process.env.REACT_APP_FORCE_DFE_FLOW) {
    const state = new ForceDFEState();
    state.handle();
    return;
  }

  if (isMerchantsRoute) {
    const state = new ArbitraryState();
    await state.handle('', true);
    return;
  }

  if (isChangeCardChannel) {
    const state = new ChangeCardState();
    await state.handle();
    return;
  }

  if (isSupportPortal) {
    const state = new SupportPortalState();
    await state.handle();
    return;
  }

  // if it's not a landing pages and orderId doesn't exist
  // -> go to welcome page, as NPG lives only in conjunction with an order
  if (!isEcommerceChannel && !existedOrderId) {
    const state = new InvalidState();
    state.handle();
    return;
  }

  if (existedOrderId && !isEcommerceChannel) {
    const state = new ArbitraryState();
    await state.handle(existedOrderId);
    return;
  }

  if (isEcommerceChannel) {
    const state = new EcommerceLandingState();
    await state.handle(existedOrderId);
    return;
  }
};

const setWebsiteTab = async (): Promise<void> => {
  // Set favicon.
  const existedElement = document.querySelector("link[rel*='icon']");
  existedElement?.remove();

  const element = document.createElement('link') as HTMLLinkElement;

  element.rel = 'icon';
  element.href = 'pagolight-favicon.ico';
  document.getElementsByTagName('head')[0].appendChild(element);

  // Set tab title.
  document.title = 'Pagolight';
};

setWebsiteTab();
init();
