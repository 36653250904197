import cookieCheck from 'third-party-cookie-check';
import { take, put, call } from 'redux-saga/effects';
import { actions } from 'legacy/store/UI';
import { Analytics } from 'legacy/utils/Analytics';

/**
 * This check was done in order to define what flow to use for adding SIA card (iframe or redirection)
 * SIA is removed, leaved this check as only cookie check for now
 */
export function* cookieCheckSaga(): any {
  while (true) {
    yield take(actions.checkCookie);

    if (process.env.REACT_APP_IS_ENABLED_THIRD_PARTY_COOKIE_BLOCK_DETECTION === 'true') {
      try {
        const { supported, timedOut } = yield cookieCheck();
      
        supported
          ? yield call(Analytics.sendEvent, '3rdPartyCookiesBlockUndetected')
          : yield call(Analytics.sendEvent, '3rdPartyCookiesBlockDetected');
      
        yield put(actions.setCookieStatus(Boolean(supported)));
      } catch (err) {
        yield put(actions.setCookieStatus(false));
      }
    } else {
      // if feature flag is OFF, assume that cookie is enabled
      yield put(actions.setCookieStatus(true));
    }
  }
}
