import { fork } from 'redux-saga/effects';
import { mobilePhoneValidationSagas } from './mobilePhoneValidation/sagas';
import { pinCodeValidationSaga } from './pinCodeValidation/sagas';
import { recoverPinSagas } from './recoverPinCode/sagas';

export function* loginSagas() {
  yield fork(mobilePhoneValidationSagas);
  yield fork(pinCodeValidationSaga);
  yield fork(recoverPinSagas);
}
