import React from 'react';
import styled from 'styled-components';

const ScrollableDiv = styled.div`
  overflow-y: scroll;
  height: 100px;
  background: #f3f3f3;
  font-size: 12px;
  color: black;
  padding: 10px;
  text-align: justify;
`;

const NestedList = styled.ul`
  list-style: inside;
  list-style-type: 'ii) ';
  margin: 10px 0;
`;

const StyledP = styled.p`
  padding: 0px 10px;
  font-size: 12px;
  color: black;
`;

type CompassTextType = {};

const CompassText: React.FC<CompassTextType> = (props: CompassTextType) => {
  const {} = props;

  return (
    <>
      <br />
      {/* It's two hard to put those texts with the right design on the json files and not worth the effort probably */}
      <StyledP> Cliccando il tasto CONFERMA, </StyledP>
      <StyledP>
        {' '}
        accetti la cessione del credito dall’esercente a Compass Banca S.p.A. e prendi atto delle
        condizioni sotto riportate{' '}
      </StyledP>
      <ScrollableDiv>
        {/* This part is literally copy pasted from the wiki (with inspect element) */}
        {/* TODO: We probably want to integrate it somehow with BE */}
        <p data-renderer-start-pos="5553">IL CLIENTE PRENDE ATTO ED ACCETTA</p>
        <ul className="ak-ul" data-indent-level="1">
          <li>
            <p data-renderer-start-pos="5590">
              Che l’Esercente cede a Compass Banca S.p.A. (il “Cessionario”), con sede legale in Via
              Caldera 21/D - 20153 Milano - Italia, a titolo oneroso, il credito - derivante dal
              contratto di acquisto del bene/servizio di cui l’accordo di dilazione costituisce
              parte integrale e sostanziale - pari all’importo risultante dalla somma delle rate
              indicate nell’accordo di dilazione e degli accessori, riconoscendo espressamente tale
              debito verso Compass Banca S.p.A..&nbsp;
            </p>
          </li>
          <li>
            <p data-renderer-start-pos="6050">
              Che la cessione del credito è risolutivamente condizionata all’esito negativo:
            </p>
          </li>
          <NestedList>
            <li data-renderer-start-pos="6132" data-indent-level="2">
              delle verifiche di capienza, stato attivo dello strumento di pagamento e di
              abilitazione al servizio TTL (Terminale Telematico) utilizzato o all’esito negativo
              della verifica dell’importo minimo cedibile e dell’addebito della prima rata
              eventualmente richiesta all’atto dell’acquisto;
            </li>
            <li data-renderer-start-pos="6422">
              delle verifiche di regolarità della documentazione acquisita da Compass Banca S.p.A. o
              delle verifiche effettuate sul Cliente (debitore ceduto) anche mediante interrogazione
              delle banche dati gestite da soggetti privati (SIC), in conformità all’informativa
              resa ai sensi del relativo codice di condotta.
            </li>
          </NestedList>
          <li>
            <p data-renderer-start-pos="6734">
              Che nei casi predetti, seguirà la cessazione degli effetti della cessione del credito
              e, per l’effetto, della dilazione di pagamento collegata, con obbligo di
              corrispondere, interamente o parzialmente ove dovuto, il prezzo del bene/servizio
              direttamente all’Esercente.
            </p>
          </li>
          <li>
            <p data-renderer-start-pos="7006">
              Che le comunicazioni del Cessionario possono essere effettuate mediante informativa
              pubblicata sul Sito Internet{' '}
              <a
                className="sc-gGBfsJ ezSIMS"
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.compass.it"
                title="http://www.compass.it"
                data-renderer-mark="true"
              >
                http://www.compass.it
              </a>{' '}
              o trasmesse tramite tecniche di comunicazione a distanza, anche ai fini del preavviso
              di imminente segnalazione, nelle banche dati, delle informazioni relative al ritardo
              nei pagamenti (ivi compresi, e-mail, SMS, contatto telefonico registrato o altri
              servizi di messaggistica), ovvero rese disponibili all’interno dell’area riservata del
              Sito Internet{' '}
              <a
                className="sc-gGBfsJ ezSIMS"
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.compass.it"
                title="http://www.compass.it"
                data-renderer-mark="true"
              >
                http://www.compass.it
              </a>{' '}
              ovvero mediante deposito nell’app dedicata, se previste.
            </p>
          </li>
        </ul>
      </ScrollableDiv>
    </>
  );
};

export default CompassText;
