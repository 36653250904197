import React, { memo, useCallback, useState } from 'react';
import { AppComponentProps, AppConfigurationType } from 'types/apps';
import useAsyncEffect from 'utils/hooks/useAsyncEffect';

type AppsPropsType = {
  config: AppConfigurationType[];
  parentProps?: any;
};

const Apps: React.FC<AppsPropsType> = ({ config, parentProps }: AppsPropsType) => {
  const [AppComponent, setAppComponent] = useState<React.ComponentType<AppComponentProps> | null>(
    null
  );
  const [currentComponentName, setCurrentComponentName] = useState<string>();

  const reloadApps = useCallback(async () => {
    for (const appConfig of config) {
      // Checking precondition of every app and stopping when finding the first valid
      if (await appConfig.precondition()) {
        const upcomingComponentName = appConfig.Component.name || appConfig.Component.displayName;
        if (!currentComponentName || currentComponentName !== upcomingComponentName) {
          setCurrentComponentName(upcomingComponentName);
          setAppComponent(memo(appConfig.Component)); // It creates a lot of unexpected behavior without react memo
          return;
        }

        if (currentComponentName === upcomingComponentName) {
          return;
        }
      }
    }

    setAppComponent(null);
  }, [config, currentComponentName]);

  useAsyncEffect(reloadApps, []);

  // We don't need to worry probably about not having a component, Maybe just put a loader or something
  // Always the failure app is the last one and the precondition is set to true
  if (!AppComponent) {
    return <> </>;
  }

  return <AppComponent reloadApps={reloadApps} {...parentProps} />;
};

export default Apps;
