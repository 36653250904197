import styled from 'styled-components';
import { mobileCheck } from 'utils/ui';

export const CardImageBox = styled('div')<{
  isVertical: boolean;
  hasError: boolean;
}>`
  ${props =>
    props.isVertical
      ? `
    width: 210px;
    height: 35%;
    margin: auto;
    transform: rotate(90deg);
    flex-direction: column;
    margin-top: 20px;
  `
      : ''}
  ${props => (props.isVertical && props.hasError ? `height: 50%;` : '')}
  ${props => (props.isVertical && props.hasError ? `margin: 0px auto 15px;` : '')}
  justify-content: center;
  display: flex;

  @media (max-width: 450px) {
    max-width: 170px;
  }
`;

export const ImageBox = styled('div')<{
  visible: boolean;
  isVertical: boolean;
}>`
  @media (max-width: 450px) {
    width: auto;
    max-height: 320px;
  }
  ${props => (props.isVertical ? `width: 210px;` : 'width: 100%;')}
  ${props => (props.isVertical ? `height: 320px;` : 'height: auto;')}
  ${!mobileCheck() && 'padding: 10px;'};
  display: ${props => (props.visible ? 'inline-block' : 'none')};
  position: relative;
`;

export const StatusImg = styled('img')<{ isVertical: boolean }>`
  height: 40px;
  position: absolute;
  right: -15px;
  bottom: -15px;
  ${props => (props.isVertical ? 'top: -15px;' : 'bottom: -15px;')}
  ${props => (props.isVertical ? 'transform: rotate(-90deg);' : '')}
`;

export const FullSizeDiv = styled('div')`
  border: 2px dashed;
  padding: 5px;
  border-radius: 15px;
  position: relative;

  align-items: center;
  display: flex;
  position: relative;

  min-height: 180px;

  @media (max-width: 850px) {
    min-height: 155px;
  }

  @media (max-width: 700px) {
    min-height: 150px;
  }

  @media (max-width: 600px) {
    min-height: 120px;
  }

  @media (max-width: 450px) {
    min-height: 170px;
  }

  &.error {
    border: 2px red dashed;
  }

  &.success {
    border: 2px black dashed;
  }
`;

export const Image = styled('img')<{ cover: boolean }>`
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: ${props => (props.cover ? 'cover' : 'unset')};
  margin-left: 50%;
  transform: translate(-50%, 0);
  max-height: 300px;

  @media (max-width: 450px) {
    max-height: 260px;
  }
`;

export const SpinnerBox = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ErrorTextBox = styled('div')<{ isVertical: boolean }>`
  ${props =>
    props.isVertical
      ? `
    position: relative;
    top: -50%
    left: -20%;
    width: 500px;
    transform: rotate(-90deg);
  `
      : ''}
  padding: 0px 10px;
`;
