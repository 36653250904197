import { take, call, put, delay } from 'redux-saga/effects';
import { PayloadAction } from 'redux-starter-kit';
import { LocalStorageManager } from 'legacy/utils/LocalStorageManager';
import { Analytics } from 'legacy/utils/Analytics';
import { TRANSACTION_ID_KEY } from 'legacy/constants/storageKeys';
import { validatePagoOtp, getTransactionStatus } from 'legacy/api';
import { actions } from 'legacy/store/transaction';
import { PaymentStatus } from 'legacy/types/PaymentStatus';
import { notify } from 'legacy/helpers/notify';
import { otpErrors, parseErrorPagoOTP } from 'legacy/utils/ApiErrors';

function* validatePagoOTPApi(transactionId: string, otp: string): any {
  const retryMessages = {
    0: 'payment:err-validating-pago-otp-retry-1',
    1: 'payment:err-validating-pago-otp-retry-2',
    2: 'payment:err-validating-pago-otp-retry-3'
  }

  for(let i = 0; i < 4; i++) {
    try {
      return yield call(validatePagoOtp, transactionId, otp);
    } catch(err) {
      if (err.statusCode !== 503) {
        throw new Error('Validation failed');
      }

      if (i < 3) {
        notify('info', retryMessages[i]);
      }
    }
  }
  // request failed after 3 attempts
  throw new Error('Validation failed');
}

export function* validateOtpSaga(): any {
  while (true) {
    const { payload: otp }: PayloadAction<number> = yield take(actions.requestPagoOtpValidation);

    const transactionId = yield call(LocalStorageManager.getItem, TRANSACTION_ID_KEY);

    try {
      let data;

      data = yield call(validatePagoOtp, transactionId, otp.toString());

      if (!data) {
        throw new Error("InternalError");
      }
      yield call(Analytics.sendEvent, 'PaymentPOLOTPApproved');

      yield put(actions.successPagoOtpValidation());
      yield put(actions.setPaymentStatus(PaymentStatus.customerApproved));
      yield call(Analytics.sendEvent, 'PaymentCompleted');
    } catch (err) {
      const errorMsg = parseErrorPagoOTP(err);

      yield put(actions.errorPagoOtpValidation(errorMsg));
      if (errorMsg) {
        if (errorMsg !== 'errors:api-invalid-otp-error') {
          // if it's invalid OTP, the user has a chance to try again

          // if it's acquirer authorization error, request the status of payment,
          // failed status will switch the flow to a failure screen
          const { paymentStatus } = yield call(getTransactionStatus, transactionId);
          yield put(actions.setPaymentStatus(paymentStatus));
        }
        yield call(Analytics.sendEvent, 'PaymentPOLOTPFailed');
      } else {
        yield call(Analytics.sendEvent, 'PaymentFailed');
        yield put(actions.setPaymentStatus(PaymentStatus.failed));
      }
    }
  }
}
