import React from 'react';
import FormattedMessage from 'legacy/components/FormattedMessage';
import FlexColumn from 'legacy/components/FlexColumn';

const RetryFailed: React.SFC = () => {
  return (
    <FlexColumn>
      <FormattedMessage messageId="onboarding:retryfailed" className="fs-26 mb-10" />
    </FlexColumn>
  );
};

export default RetryFailed;
