import React, { useEffect } from 'react';
import { AppComponentProps, AppProps } from 'types/apps';
import { ParentProps } from '../types';
import DefaultPhoneNumber from './PhoneNumber/Default';
import DEPhoneNumber from './PhoneNumber/DE';
import useSubmitHandler from 'apps/UXFlow/utils/hooks/useSubmitHandler';
import { DESIGN } from 'types/design';
import ui from 'store/ui';
import flow from 'apps/UXFlow/store/flow';
import { Analytics } from 'utils/Analytics';

const AuthOTPPhone: React.FC<AppComponentProps & ParentProps & AppProps> = (
  props: AppComponentProps & ParentProps & AppProps
) => {
  const { onSubmit, loading } = useSubmitHandler(props);

  const sumbitFormHandler = (number: string): void => {
    onSubmit({ phoneNumber: number });
    Analytics.sendEvent('Landing Page- confirmed clicked ');
  };

  useEffect(() => {
    Analytics.sendEvent('Landing Page- visited');
  }, []);

  const PhoneNumberComponents = {
    [DESIGN.DE]: DEPhoneNumber,
    [DESIGN.default]: DefaultPhoneNumber,
  };

  const PhoneNumber = PhoneNumberComponents[ui.design];

  return (
    <PhoneNumber
      submitHandler={sumbitFormHandler}
      isPendingState={loading}
      data={flow.data}
      goBack={props.goBack}
      backBtnEnabled={props.backBtnEnabled}
      onClose={props.onClose}
      setComponentOnDemand={props.setComponentOnDemand}
    />
  );
};

export default AuthOTPPhone;
