export const ORDER_TYPE_PAYNOW = 'paynow';
export const ORDER_TYPE_INSTALLMENTS = 'installments';

export const CARD_TYPE_CREDIT = 'credit';
export const CARD_TYPE_DEBIT = 'debit';

export const CARD_STATUS_COMPLETED = 'completed';

export const TERMS_CONDITIONS_LINK_IT = 'https://noosa-static-files.s3.eu-central-1.amazonaws.com/web/web_noosa_terms_privacy_04092020_3.html';
export const TERMS_CONDITIONS_LINK_EN = 'https://noosa-static-files.s3.eu-central-1.amazonaws.com/web/web_noosa_terms_privacy_04092020_3.html';

export const ENV_DEV = 'DEV';
export const ENV_DEMO = 'DEMO';
export const ENV_SANDBOX = 'SANDBOX';
export const ENV_STAGING = 'STAGING';
export const ENV_PRE_PROD = 'PRE_PROD';
export const ENV_PROD = 'PRODUCTION';
