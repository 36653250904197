import React, { useState } from 'react';
import {
  AccordionIcon,
  AccordionList,
  ItemTitle,
  AccordionLine,
  AccordionParagraph,
  AccordionItemContent,
  AccordionItemInner,
  AccordionItem as AccordionItemStyled,
} from './accordion.styles';

interface AcordionItem {
  title: string;
  paragraph: string;
}

const AccordionItem = (props: AcordionItem) => {
  const [opened, changeOpened] = useState<boolean>(false);
  const { paragraph, title } = props;

  return (
    <AccordionItemStyled onClick={() => changeOpened(!opened)} opened={opened}>
      <AccordionLine>
        <ItemTitle>{title}</ItemTitle>
        <AccordionIcon opened={opened} />
      </AccordionLine>
      <AccordionItemInner opened={opened}>
        <AccordionItemContent opened={opened}>
          <AccordionParagraph dangerouslySetInnerHTML={{ __html: paragraph }} />
        </AccordionItemContent>
      </AccordionItemInner>
    </AccordionItemStyled>
  );
};

interface Props {
  items: AcordionItem[];
}

const Accordion: React.SFC<Props> = ({ items }: Props) => (
  <AccordionList>
    {items.map((item, key) => (
      <AccordionItem {...item} key={key} />
    ))}
  </AccordionList>
);

export default Accordion;
