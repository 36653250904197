import { put, call } from 'redux-saga/effects';
import { createOtp, validateConsumer } from 'legacy/api';
import { history } from 'legacy/routing/history';
import { OTP_VALIDATION, PIN_CODE_VALIDATION } from 'legacy/constants/pathnames';
import { SessionStorageManager } from 'legacy/utils/SessionStorageManager';
import { USER_KEY } from 'legacy/constants/storageKeys';
import { actions as OTPActions } from 'legacy/store/otp';
import { actions } from 'legacy/store/login';
import { Analytics } from 'legacy/utils/Analytics';
import lean from 'legacy/helpers/switchFlow/flows/lean';
import { store } from 'legacy/store/store';

/** handle user validation */
export function* userValidationSaga(phoneNumber: string) {
  try {
    const {
      data: { mobile, isLoginFlow },
    } = yield call(validateConsumer, phoneNumber);
    const user = { mobile, isLoginFlow };

    yield put(actions.setUser(user));
    yield call(SessionStorageManager.setItem, USER_KEY, user);

    if (isLoginFlow === false) {
      Analytics.sendEvent('New user detected');
      yield call(createOtp, phoneNumber);
      yield put(OTPActions.resetOTPState());
    } else {
      Analytics.sendEvent('Registered user detected');
    }

    yield put(actions.successLogin());

    let pathToRedirect = isLoginFlow ? PIN_CODE_VALIDATION : OTP_VALIDATION;

    const isLeanFlow = lean(store.getState());

    if (isLeanFlow) {
      pathToRedirect = OTP_VALIDATION;
    }

    history.push(pathToRedirect);
  } catch (err) {
    yield put(actions.errorLogin());
    Analytics.sendEvent('PhoneNumberValidationFailed');
  }
}
