import React, { useState } from 'react';
import { GlobalStyles, Background, Frame, Container } from './root.styles';
import { ToastContainer, toast } from 'react-toastify';
import Mobile from './components/Mobile';
import Success from './components/Success';
import Footer from './components/Footer';
import { Logger } from 'legacy/utils/Logger';
import { changeCardRequest } from 'legacy/api';
import { notify } from 'legacy/helpers/notify';

const TOAST_AUTOCLOSE_TIME = 15000;

enum Steps {
  mobile = 'mobile',
  success = 'success',
}

const Root: React.FC = () => {
  const [stepId, setStepId] = useState<string>(Steps.mobile);
  const [mobile, setMobile] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const validateMobile = (mobile: string): boolean => {
    const minLength = 13;
    const maxLength = 14;
    return /\+[0-9]+$/.test(mobile) && mobile.length >= minLength && mobile.length <= maxLength;
  };

  const submitHandler = async (mobile: string): Promise<void> => {
    setMobile(mobile);

    const isValid = validateMobile(mobile);
    if (!isValid) {
      setStepId(Steps.mobile);
      setError('change-card:support-portal-invalid-mobile');
      return;
    }

    setLoading(true);
    try {
      const result = await changeCardRequest(mobile);
      if (!result.success) {
        throw new Error(`API returns mailformed response: ${result}`);
      }
      setStepId(Steps.success);
      notify('success', 'change-card:support-portal-resend-toast');
    } catch (err) {
      Logger.error(`Error: ${JSON.stringify(err)}`);
      setStepId(Steps.mobile);
      setError('something-went-wrong');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <GlobalStyles />
      <ToastContainer
        position={toast.POSITION.TOP_RIGHT}
        autoClose={TOAST_AUTOCLOSE_TIME}
        className="toaster"
        newestOnTop={false}
        closeOnClick
        hideProgressBar
      />
      <Background>
        <Frame>
          <Container>
            {stepId === 'mobile' && (
              <Mobile submitHandler={submitHandler} error={error} loading={loading} />
            )}
            {stepId === 'success' && (
              <Success
                submitHandler={submitHandler}
                mobile={mobile}
                error={error}
                loading={loading}
              />
            )}
          </Container>
        </Frame>
        <Footer></Footer>
      </Background>
    </>
  );
};

export default Root;
