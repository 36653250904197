import React from 'react';
import Text from 'legacy/DFE/components/Text';
import Button from 'legacy/DFE/components/Button';
import styled from 'styled-components';

const OrderConfirmationContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

const Container = styled('div')`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 95px;
`;

const PaymentConfirmationComponent: React.FC<{}> = (props: {}) => {
  const onClick = () => {
  };

  return (
    <>
      <Text className="bold" fontSize={'24px'}>
        Payment is completed
      </Text>
      <Container>
        <Text className="light" fontSize={'18px'}>
          Your payment plan is sent to your email
        </Text>
        <OrderConfirmationContainer>
          <Text className="light" fontSize={'18px'}>
            Order confirmation number
          </Text>
          <Text className="bold" fontSize={'18px'}>
            DX23SQ
          </Text>
        </OrderConfirmationContainer>
        <Button onClick={onClick}>Return to Nike</Button>
      </Container>
    </>
  );
};

export default PaymentConfirmationComponent;
