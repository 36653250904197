export const errorsWithToasts = [
  { message: 'Amount is below minimum', toastMsgId: 'amount-below-minimum' },
  {
    message: 'The required amount to process is more than the available balance',
    toastMsgId: 'unavailable-amount',
  },
  { message: 'Service Unavailable', toastMsgId: 'service-unavailable' },
  { message: 'Transaction Expired', toastMsgId: 'transaction-expired' },
  // { message: 'Authorization Required', toastMsgId: 'authorization-required' },
  { message: 'SIA card not found', toastMsgId: 'card-not-found' },
  { message: 'Transaction is already passed', toastMsgId: 'transaction-passed' },
];
