export enum AppType {
  'de_change_card' = 'de_change_card',
  merchantsList = 'merchantsList',
  order = 'order',
}

export const getAppType = (): keyof typeof AppType => {
  const spath = window.location.pathname.split('/');
  const path = spath[spath.length - 1].toLowerCase();

  switch (true) {
    case window.location.pathname.includes('marni/card/change/t'):
      return 'de_change_card';
    case path === 'merchants':
      return 'merchantsList';
    default:
      return 'order';
  }
};
