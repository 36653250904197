import lean from 'legacy/helpers/switchFlow/flows/lean';
import { store } from 'legacy/store/store';
import styled from 'styled-components';

interface Props {
  smallPadding?: boolean;
  heightMax?: boolean;
}
const BoxWithBackground = styled('div')<Props>`
  border-radius: 20px;
  ${_ =>
    lean(store.getState())
      ? `
  `
      : `
    border: solid 1px #ebe8ee;
    background-color: #f6f5f7;
  `}
  padding: ${props => (props.smallPadding ? '20px 30px' : '40px 70px')};
  margin-bottom: 5px;
  margin-top: 10px;
  width: 100%;
  ${props => (props.heightMax ? 'height: 100%;' : '')}

  @media (max-width: 1280px) {
    padding: 20px 30px;
  }

  @media (max-width: 550px) {
    padding: 20px 20px;
  }
`;

export default BoxWithBackground;
