export class SessionStorageManager {
  static getItem(key: string) {
    const item = sessionStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  }

  static getValue(key: string) {
    const item = sessionStorage.getItem(key);
    return item || null;
  }

  static setValue(key: string, value: any) {
    sessionStorage.setItem(key, value);
  }

  static setItem(key: string, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  static removeItem(key: string) {
    sessionStorage.removeItem(key);
  }
}
