import React from 'react';
import styled from 'styled-components';
import Text from 'components/Text';
import PageContainer from 'apps/UXFlow/components/PageContainer/DE';
import { AppProps } from 'types/apps';

const DescriptionBox = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  margin: 23px 40px 40px 40px;
  text-align: center;
`;

const Spacer = styled.div`
  height: 1em;
`;

const ECPCardPage: React.FC<any> = props => {
  return (
    <PageContainer titleMessageId="title" onClose={props.onClose} goBack={() => { }} backBtnEnabled={false}>
      <DescriptionBox>
        <Text messageId="subtitle" />
        <Spacer />
        <Text messageId="ECPCardProviderText" />
      </DescriptionBox>
      {props.children}
    </PageContainer>
  );
};

export default ECPCardPage;
