import { connect } from 'react-redux';

import { selectors as OrderSelectors } from 'legacy/store/order';
import { State } from 'legacy/store/state';

const mapStateToProps = (state: State) => ({
  order: OrderSelectors.orderDetailsSelector(state),
});

const mapDispatchProps = {
};
  
export default connect(mapStateToProps, mapDispatchProps);