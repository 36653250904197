import styled from 'styled-components';

interface FormProps {
  fullWidth?: boolean;
  disabled?: boolean;
  textTransform?: string;
  style?: any;
}

export const InputFormStyled = styled('div')<FormProps>`
  position: relative;
  max-width: ${props => (props.fullWidth ? '100%' : '340px')}
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const InputStyled = styled('input')<FormProps>`
  width: 100%;
  border: 1px solid #e1dfe9;
  background-color: ${props => (props.disabled ? '#e6e5e5' : '#fff')}
  border-radius: 5px;
  font-size: 0.94em;
  height: 45px;
  padding: 0px 16px;
  color: #000000;
  text-transform: ${props => (props.textTransform ? props.textTransform : 'none')};

  &:focus {
    outline: none;
  }

  // remove X frome end of input
  &&[type='search']::-webkit-search-decoration,
  &&[type='search']::-webkit-search-cancel-button,
  &&[type='search']::-webkit-search-results-button,
  &&[type='search']::-webkit-search-results-decoration {
    display: none;
  }
`;

export const Error = styled('div')`
  white-space: normal;
  word-break: break-word;
  position: absolute;
  top: 45px;
  width: 100%;
  text-align: center;
`;
