import { NOOSA_SESSION_ID } from "constants/index";
import { makeAutoObservable } from "mobx";
import { Profile } from "types/profile";
import { MappedUXFlowErrors } from "types/data";

class Flow {
    constructor() {
        makeAutoObservable(this);
        this.offset = 0;
    }

    private _sessionId?: string;

    // The id which we get in the url (npg.noosa.io/flow/ID)
    public requestId?: string;

    // Data returned from each step
    public data?: any;

    //Profile data of a user
    public profile?: Profile;

    // Errors returned from each step
    public errors: MappedUXFlowErrors = {};

    // Step name
    public step?: string;

    // The session id returned in the headers
    public get sessionId(): string | undefined {
        return this._sessionId;
    }

    public set sessionId(sessionId: string | undefined) {
        this._sessionId = sessionId;
        sessionStorage.setItem(NOOSA_SESSION_ID, JSON.stringify(sessionId ?? null));
    }

    public complete?: boolean;

    public referrer?: string;

    public offset?: number;

    public merchantId?: string;

    public branch?: string;

    public paymentProgram?: string;
}

export default new Flow();
