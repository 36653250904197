import { take, put, call } from 'redux-saga/effects';
import { actions as UIActions } from 'legacy/store/UI';
import { showAuthorizedForm } from 'legacy/helpers/showAuthorizedForm';
import { history } from 'legacy/routing/history';
import { actions } from '../index';

export function* pageLoadedSaga(): any {
  while (true) {
    yield take(actions.loadContactUsPage);

    // yield put(UIActions.setIsLoading(true));
    const { pathname } = history.location;
    const isAuthorizedForm = yield call(showAuthorizedForm, pathname);
    yield put(actions.changeFormType(isAuthorizedForm));
    // yield put(UIActions.setIsLoading(false));
  }
}
