import { take, put, call, select } from 'redux-saga/effects';
import { createOtp } from 'legacy/api';
import { notify } from 'legacy/helpers/notify';
import { selectors as loginSelectors } from 'legacy/store/login';
import { actions } from '../index';

/** handle reset otp event */
export function* resendOtpSaga() {
  while (true) {
    yield take(actions.requestResendOtp);

    try {
      const { mobile } = yield select(loginSelectors.userSelector);
      yield call(createOtp, mobile);

      yield put(actions.successResendOtp());
      notify('success', 'resent-sms-success');
    } catch (err) {
      notify('error', 'error-while-sending-otp');
      yield put(actions.errorResendOtp());
    }
  }
}
