import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import FlexColumn from 'legacy/DFE/components/FlexColumn';
import Text from 'legacy/DFE/components/Text';
import Button from 'legacy/DFE/components/Button';
import TextInput from 'legacy/DFE/components/TextInput';
import TextareaForm from 'legacy/DFE/components/TextareaForm';
import { selectors as consumerSelectors } from 'legacy/DFE/store/consumer';
import { State } from 'legacy/DFE/store/state';
import { ON_BOARDING } from 'legacy/DFE/constants/pathnames';
import { useEventListener } from 'legacy/DFE/hooks/useEventListener';
import { Analytics } from 'legacy/DFE/utils/analytics';
import { getCurrentLanguage } from 'legacy/DFE/helpers/language';
import { languageNames } from 'legacy/DFE/constants/languageNames';
import { EmailAPI } from 'legacy/DFE/api/handlers';
import { notify } from 'legacy/DFE/helpers/notify';
import { actions } from 'legacy/DFE/store/router';
import styled from 'styled-components';
import { RouteComponentType } from 'legacy/DFE/routing/RouterTypes';

const MarginContainer = styled('div')`
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 10px;
`;

const FormContainer = styled('div')`
  margin: auto;
  width: 70%;
`;

interface Form {
  email: string;
  name: string;
  subject: string;
  body: string;
}

interface Errors {
  email: boolean;
  name: boolean;
  subject: boolean;
  body: boolean;
}

const initialForm: Form = {
  email: '',
  name: '',
  subject: '',
  body: '',
};

const initialErrors: Errors = {
  email: false,
  name: false,
  subject: false,
  body: false,
};

const mapStateToProps = (state: State) => ({
  consumerInfo: consumerSelectors.getConsumerState(state),
});

const mapDispatchToProps = {
  setPage: actions.setPage,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ContactUsComponent: React.FC<PropsFromRedux & RouteComponentType> = (
  props: PropsFromRedux & RouteComponentType
) => {
  const { consumerInfo, setPage, done } = props;
  const [form, changeForm] = useState<Form>(initialForm);
  const [errors, changeErrors] = useState<Errors>(initialErrors);
  const [pending, setPending] = useState<boolean>(false);
  const isAuthorizedForm = !!consumerInfo.email && !!consumerInfo.firstName;

  useEffect(() => {
    Analytics.sendEvent('SupportFormPageVisited');
  }, []);

  useEffect(() => {
    if (!isAuthorizedForm) return;

    const { pathname } = window.location;
    if (pathname.startsWith(ON_BOARDING)) {
      changeForm({
        ...form,
        email: consumerInfo.email || '',
        name: consumerInfo.firstName || '',
      });
    }
  }, [consumerInfo]);

  const updateField = (field: string, value: string) => {
    changeForm({
      ...form,
      [field]: value,
    });
  };

  const validateForm = (formFields: Partial<Form>): boolean => {
    const errors = { ...initialErrors };
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    for (const key in formFields) {
      const value = formFields[key].trim();
      const isValid = key === 'email' ? regex.test(value) : Boolean(value);
      errors[key] = !isValid;
    }

    changeErrors(errors);
    return Object.values(errors).every(err => err === false);
  };

  const redirectBack = () => {
    setPage('/');
    done();
  };

  const submitForm = async () => {
    Analytics.sendEvent('SupportFormPageSubmited');

    const formFields = isAuthorizedForm ? { subject: form.subject, body: form.body } : { ...form };
    const isValid = validateForm(formFields);

    if (!isValid) return;

    const currentLanguage = getCurrentLanguage();
    const emailData = {
      ...formFields,
      language: languageNames[currentLanguage],
    };

    setPending(true);

    const { result } = await EmailAPI.sendSupport(emailData);

    if (!result) {
      throw new Error('Sending failed');
    }

    setPending(false);
    changeForm(initialForm);
    notify('success', 'Success');
    redirectBack();
  };

  useEventListener('keypress', submitForm);

  return (
    <FlexColumn>
      <MarginContainer>
        <Text className="mb-10" fontSize={'23px'}>
          Contact us
        </Text>
        <Text className="light mb-40" fontSize={'16px'}>
          We appreciate the time you have taken to provide us with your feedback. Please fill the
          form below and we reply shortly.
        </Text>
      </MarginContainer>
      <FormContainer>
        {!isAuthorizedForm && (
          <>
            <TextInput
              value={form.email}
              onChange={value => updateField('email', value)}
              errorMsg={errors.email ? 'email err' : ''}
            >
              your email
            </TextInput>
            <TextInput
              value={form.name}
              onChange={value => updateField('name', value)}
              errorMsg={errors.name ? 'name mandatory err' : ''}
            >
              your name
            </TextInput>
          </>
        )}
        <TextInput
          value={form.subject}
          onChange={value => updateField('subject', value)}
          errorMsg={errors.subject ? 'subject mandatory err' : ''}
        >
          subject
        </TextInput>
        <TextareaForm
          placeholder={'request suggestion'}
          changeHandler={value => updateField('body', value)}
          errorStatus={errors.body}
          errorMsgId={'request mandatory err'}
        >
          {form.body}
        </TextareaForm>
        <Button onClick={submitForm}>{pending ? 'pending' : 'submit'}</Button>
      </FormContainer>
      <a href={'/'} style={{ color: 'gray', textDecoration: 'none' }}>Go back</a>
    </FlexColumn>
  );
};

export default connector(ContactUsComponent);
