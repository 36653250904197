import { OTPAPI } from "legacy/DFE/api/handlers";
import { Route } from "legacy/DFE/routing/RouterTypes";
import { selectors } from "legacy/DFE/store/auth";
import { State } from "legacy/DFE/store/state";
import OTPComponent from "./index.component";

export default class OTP extends Route {
  constructor() {
    super('OTP', '/');
  }

  async precondition(state: State) {
    return !selectors.authToken(state);
  }

  async preprocess(state: State) {
    await OTPAPI.createOTP(selectors.mobile(state)!);
  }

  component = OTPComponent;
}
