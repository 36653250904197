import React, { useEffect, useState } from 'react';
import Button from 'components/Button';
import styled from 'styled-components';
import './contract.css';
import { Section, InputValues, Consents, SelectedConsent } from './types';
import SectionInputs from './SectionInputs';
import flow from 'apps/UXFlow/store/flow';

const ContractBox = styled('div')<{ height?: string; allowScroll: boolean }>`
  border-radius: 20px;
  border: solid 1px #ebe8ee;
  background-color: #f0f0f4;
  margin: 30px 0;
  padding: 40px;
  width: 100%;
  height: ${props => props.height || '70vh'};
  overflow-y: ${props => (props.allowScroll ? 'scroll' : 'hidden')};
  scroll-behavior: smooth;
`;

function scrollToNextButton(id: string) {
  const element = document.getElementById(id);

  if (element) {
    element.scrollIntoView({ block: 'center' });
  } else {
    const container = document.getElementById('tsbox');

    if (!container) {
      return;
    }

    container.scrollTop = container.scrollHeight || 0;
  }
}

type propsTypes = {
  height?: string;
  isPendingState?: boolean;
  submitHandler?: (data: any) => void;
};

const Contract: React.FC<propsTypes> = (props: propsTypes) => {
  const { isPendingState, submitHandler, height } = props;

  const [errors, setErrors] = useState<string[]>([]);
  const [consents, setConsents] = useState<Array<SelectedConsent>>([]);
  const [enableButton, setEnableButton] = useState<boolean>(false);
  const { data } = flow;

  const sections: Section[] = data.termsOfUse.sections || [];
  const suffix: string = data.termsOfUse.suffix;

  const getNextButtonName = (): string | undefined => {
    return sections.find(section => !consents.find(consent => consent.name === section.name))?.name;
  };

  const validateCheckbox = (): Array<string> => {
    return sections.reduce((previousValue, currentValue) => {
      const consent = consents.find(consent => consent.name === currentValue.name);
      if (!consent || consent.value === InputValues.clientDisagree) {
        return [...previousValue, currentValue.name];
      }

      return previousValue;
    }, [] as Array<string>);
  };

  function getAllConsents(): Consents {
    return consents.reduce((previousValue, currentValue) => {
      return {
        ...previousValue,
        [currentValue.name]: currentValue.value === InputValues.agree,
      };
    }, {} as Consents);
  }

  const onSubmit = (): void => {
    const consents = getAllConsents();
    const errors = validateCheckbox();

    setErrors(errors);

    if (errors.length > 0) {
      scrollToNextButton(errors[0]);
      return;
    }

    submitHandler &&
      submitHandler({
        consents,
        version: data.termsOfUse.version,
      });
  };

  useEffect(() => {
    scrollToNextButton(getNextButtonName() || '');
  }, [consents]);

  useEffect(() => {
    const errors = validateCheckbox();
    setEnableButton(errors.length === 0);
  }, [consents]);

  const onClick = (name: string, value: keyof typeof InputValues): void => {
    setConsents([
      ...consents.filter(consent => consent.name !== name),
      {
        value: value,
        name: name,
      },
    ]);
  };

  const contents = sections.map(section => {
    return (
      <div key={section.name} style={section.contents ? {} : { display: 'none' }}>
        <div className="content" dangerouslySetInnerHTML={{ __html: section.contents }} />
        <br />
        <SectionInputs
          onChange={onClick}
          checkedValue={consents.find(consent => consent.name === section.name)?.value}
          disagreeClientText={section.disagree_client_text}
          disagreeText={section.disagree_text}
          agreeText={section.agree_text}
          name={section.name}
          checkboxPrefix={section.checkboxPrefix}
          error={errors.includes(section.name)}
        />
        <br />
      </div>
    );
  });

  return (
    <>
      <ContractBox allowScroll={true} height={height} id="tsbox">
        {contents}
        {suffix && <div dangerouslySetInnerHTML={{ __html: suffix }} />}
      </ContractBox>
      <div>
        {submitHandler && (
          <Button
            disabled={!enableButton}
            messageId={'confirm'}
            onClick={onSubmit}
            isLoading={isPendingState}
          />
        )}
      </div>
    </>
  );
};

export default Contract;
