import axios from 'axios';
import { Consumer, OnboardingFlowAPIData, OnboardingFlow } from 'legacy/types/Consumer';
import { ValidatedUser } from 'legacy/types/ValidatedUser';
import { getApiUrl } from 'legacy/helpers/getApiUrl';
import { getStringifiedParams } from 'legacy/helpers/getStringifiedParams';
import { languageNames } from 'legacy/constants/languageNames';
import { version } from '../../../package.json';

const CHANNEL_NAME = 'Web NPG';
const languages = { ...languageNames } as const;

type Keys = keyof typeof languages;
type LanguageName = typeof languages[Keys];

interface EmailDetails {
  subject: string;
  body: string;
  language: LanguageName;
}

export const sendSupport = (emailDetails: EmailDetails): Promise<{ result: boolean }> => {
  return axios.post(
    getApiUrl('sendEmail'),
    getStringifiedParams({
      ...emailDetails,
      version,
      channel: CHANNEL_NAME,
    })
  );
};

/** consumers api calls */

const CONSUMERS_URL = 'consumers/';
const API_PREFIX = 'npg/v1';

interface UserToken {
  token: string;
}

interface TokenLoginData {
  token: string;
  orderId: string;
}

interface LinkLoginData {
  accessToken: string;
}

export const validateConsumer = (mobile: string): Promise<ValidatedUser> => {
  return axios.post(getApiUrl(`${CONSUMERS_URL}validate`), getStringifiedParams({ mobile }));
};

export const pinCodeLogin = (
  mobile: string,
  pin: string,
  rememberMe: boolean
): Promise<UserToken> => {
  const rememberMeValue = rememberMe ? 1 : 0;
  return axios.post(
    getApiUrl(`${CONSUMERS_URL}pinLogin`),
    getStringifiedParams({ mobile, pin, rememberMe: rememberMeValue })
  );
};

export const tokenLogin = (token: string): Promise<TokenLoginData> => {
  return axios.post(getApiUrl(`${CONSUMERS_URL}tokenLogin`), getStringifiedParams({ token }));
};

export const linkLogin = (token: string): Promise<LinkLoginData> => {
  return axios.post(getApiUrl(`${API_PREFIX}/consumer/linkLogin`), getStringifiedParams({ token }));
};

export const getConsumerInfo = (): Promise<Consumer> => {
  return axios.get(getApiUrl(`${CONSUMERS_URL}getInformation`));
};

export const saveConsumerInfo = (data: Partial<Consumer>): Promise<void> => {
  return axios.patch(getApiUrl(`${CONSUMERS_URL}saveInformation`), getStringifiedParams(data));
};

export const getProvinces = (): Promise<string[]> => {
  return axios.get(getApiUrl(`${CONSUMERS_URL}getProvinces`));
};

export const getCities = (data: any): Promise<string[]> => {
  return axios.get(
    getApiUrl(`${CONSUMERS_URL}getCities`, { province: data.province, filter: data.city })
  );
};

export const getZipCodes = (provinceCode: string, city: string): Promise<string[]> => {
  return axios.get(getApiUrl(`${CONSUMERS_URL}getZipCodes`, { provinceCode, city }));
};

export const checkPinCode = (pin: string): Promise<void> => {
  return axios.post(getApiUrl(`${CONSUMERS_URL}checkPin`), getStringifiedParams({ pin }));
};

export const setPinCode = (pin: number): Promise<void> => {
  return axios.patch(getApiUrl(`${CONSUMERS_URL}savePin`), getStringifiedParams({ pin }));
};

export const acceptTerms = (): Promise<void> => {
  return axios.post(getApiUrl(`${CONSUMERS_URL}acceptTerms`));
};

export const acceptPromotionEmails = (flag: boolean): Promise<void> => {
  return axios.post(
    getApiUrl(`${CONSUMERS_URL}acceptPromotionEmails`),
    getStringifiedParams({ flag })
  );
};

/** recover pin */
export const sendRecoverOtp = (mobile: string): Promise<void> => {
  return axios.post(getApiUrl(`${CONSUMERS_URL}sendRecoverOtp`), getStringifiedParams({ mobile }));
};

type validateRecoverOtpResponce = {
  isCardExists: boolean;
  resul: boolean;
  token: string;
};

export const validateRecoverOtp = (
  mobile: string,
  otp: string
): Promise<validateRecoverOtpResponce> => {
  return axios.post(
    getApiUrl(`${CONSUMERS_URL}validateRecoverOtp`),
    getStringifiedParams({ mobile, otp })
  );
};

export const saveRecoverPin = (mobile: string, pin: string): Promise<void> => {
  return axios.patch(
    getApiUrl(`${CONSUMERS_URL}saveRecoverPin`),
    getStringifiedParams({ pin, mobile })
  );
};

export const checkLastDigits = (mobile: string, digits: string): Promise<void> => {
  return axios.post(
    getApiUrl(`${CONSUMERS_URL}checkLastDigits`),
    getStringifiedParams({ digits, mobile })
  );
};

export const getOnboardingFlow = (data: OnboardingFlowAPIData): Promise<OnboardingFlow> => {
  return axios.get(getApiUrl(`${API_PREFIX}/flow`, data.query));
};

export const setOnboardingFlow = (data: OnboardingFlowAPIData): Promise<OnboardingFlow> => {
  return axios.post(getApiUrl(`${API_PREFIX}/flow`, data.query), data.profile || {});
};

// Right now used only for OCR, in case of using it for other flows we'll need to refactor it
export async function setFormDataOnboardingFlow(
  data: OnboardingFlowAPIData
): Promise<OnboardingFlow> {
  const formData = new FormData();

  for (const [key, value] of Object.entries(data.profile)) {
    formData.append(
      key,
      value instanceof Blob ? value : typeof value === 'object' ? JSON.stringify(value) : `${value}`
    );
  }

  return await axios.post(getApiUrl(`${API_PREFIX}/flow`, data.query), formData || {}, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export const changeCardRequest = (mobile: string): Promise<{ success: boolean }> => {
  return axios.post(getApiUrl(`npg/v1/card/change`), getStringifiedParams({ mobile }));
};

export const logout = (authToken: string): Promise<{}> => {
  return axios.post(getApiUrl(`${CONSUMERS_URL}consumerLogout`, { access_token: authToken }));
};
