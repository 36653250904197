import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Text from 'legacy/DFE/components/Text';

const mapDispatchToProps = {};

const connector = connect(undefined, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const TC: React.FC<PropsFromRedux> = (props: PropsFromRedux) => {
  return (
    <>
      <Text className="fs-14 light">
        By clicking the button, you agreed with Terms and Conditions of the deferred payment plan
      </Text>
    </>
  );
};

export default TC;
