import React from 'react';
import Scrollbar from 'simplebar-react';
import Accordion from 'legacy/DFE/components/Accordion';
import Text from 'legacy/DFE/components/Text';
import 'simplebar/dist/simplebar.min.css';
import { questions, answers } from './questions';

const FAQComponent: React.FC<{}> = () => {
  const items = questions.map((question, index) => ({
    title: question,
    paragraph: answers[index],
  }));

  return (
    <>
      <Text className="mb-40" fontSize={'26px'}>Frequently asked questions</Text>
      <Scrollbar
        style={{ maxHeight: '500px', height: '1vh', width: '100%', flex: '1 1 auto' }}
        autoHide={false}
      >
        <Accordion items={items} />
      </Scrollbar>
    </>
  );
};

export default FAQComponent;
