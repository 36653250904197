import React, { useEffect, useState } from 'react';
import Spinner from 'react-loader-spinner';

import flow from 'apps/UXFlow/store/flow';
import Text from 'components/Text';
import { Consumer } from 'types/consumer';
import { AdditionalDataFields } from 'types/data';
import { mobileCheck } from 'utils/ui';
import { getImageDimensions } from 'utils/OCR';

import Upload from './Upload';
import {
  CardImageBox,
  ErrorTextBox,
  FullSizeDiv,
  Image,
  ImageBox,
  SpinnerBox,
  StatusImg,
} from './styles';

import cardImage from 'assets/ocr/back_hi_card.jpg';
import successIcon from 'assets/ocr/icon-success.svg';
import failIcon from 'assets/ocr/icon-fail.svg';

enum UploadStatuses {
  SUCCESS = 'success',
  ERROR = 'error',
  NONE = '',
}

type CardImageType = {
  data: (Partial<Consumer> & Partial<AdditionalDataFields>) | undefined;
  onUpload: (file: File) => void;
  isCameraUsed: boolean;
  OCRTempImage?: string;
  cardImageLoading: boolean;
  alreadyUploadedError: string;
  setIsCameraUsed: (isUsed: boolean) => void;
};

const CardImage: React.FC<CardImageType> = (props: CardImageType) => {
  const {
    data,
    isCameraUsed,
    setIsCameraUsed,
    onUpload,
    OCRTempImage,
    cardImageLoading,
    alreadyUploadedError,
  } = props;
  const [imgDimensions, setImgDimensions] = useState<{ width: number; height: number }>();

  // If it's image we got from BE, we have to add the information for the browser that it's a base64 image
  const image: string =
    OCRTempImage || (data?.images.image && `data:image/jpeg;base64,${data?.images.image}`);

  useEffect(() => {
    const asyncSetImgDimensions = async (): Promise<void> => {
      const imageDimensions = await getImageDimensions(image);
      setImgDimensions(imageDimensions);
    };
    asyncSetImgDimensions();
  }, [image]);

  const isMobile: boolean = mobileCheck();

  const success = !alreadyUploadedError && (data as any)?.fiscalCode?.value;
  const error: boolean =
    !!image && !success && !cardImageLoading && !(data as any)?.fiscalCode?.value;
  const imageLoadedSuccessfully: boolean = !!image && success && !cardImageLoading;

  const hasError = error || alreadyUploadedError;
  const uploadStatus: UploadStatuses = imageLoadedSuccessfully
    ? UploadStatuses.SUCCESS
    : hasError
    ? UploadStatuses.ERROR
    : UploadStatuses.NONE;

  const isVertical = Boolean(imgDimensions && imgDimensions?.width < imgDimensions?.height);

  return (
    <>
      <Text messageId="back" className="light" />
      <CardImageBox isVertical={isVertical} hasError={!!hasError}>
        <ImageBox visible={!isCameraUsed} isVertical={isVertical}>
          <FullSizeDiv className={uploadStatus}>
            <Image
              cover={!!image}
              src={image || cardImage}
              alt="Picture of health insurance card"
            />
            {uploadStatus !== UploadStatuses.NONE && (
              <StatusImg
                src={uploadStatus === UploadStatuses.SUCCESS ? successIcon : failIcon}
                isVertical={isVertical}
              />
            )}
            <Upload
              setIsCameraUsed={setIsCameraUsed}
              loading={cardImageLoading}
              error={error}
              onUpload={onUpload}
              isVertical={isVertical}
            />
          </FullSizeDiv>

          <ErrorTextBox isVertical={isVertical}>
            {hasError && <Text content={flow.errors[0].message} className="red" />}
          </ErrorTextBox>
          {cardImageLoading && (
            <SpinnerBox>
              <Spinner
                type="TailSpin"
                color="#00BFFF"
                height={isMobile ? 50 : 100}
                width={isMobile ? 50 : 100}
              />
            </SpinnerBox>
          )}
        </ImageBox>
      </CardImageBox>
    </>
  );
};

export default CardImage;
