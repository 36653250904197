import { fork } from 'redux-saga/effects';
import { landingSaga } from './landingSaga';
import { validateByPhoneSaga } from './validateByPhoneSaga';
import { loginPageLoadedSaga } from './loginPageLoadedSaga';
import { validateUserWithTokenSaga } from './validateUserWithTokenSaga';

export function* mobilePhoneValidationSagas() {
  yield fork(landingSaga);
  yield fork(validateByPhoneSaga);
  yield fork(loginPageLoadedSaga);
  yield fork(validateUserWithTokenSaga);
}
