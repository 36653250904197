import React from 'react';
import { DESIGN } from 'types/design';
import DECheckbox from './DE';
import DefaultCheckbox from './Default';
import ui from 'store/ui';

interface Props {
  checked: boolean;
  labelMsgId?: string;
  onChange: (value: boolean) => void;
  id?: string;
  format?: 'text' | 'html';
  content?: string;
  isError?: boolean;
}

const Checkbox: React.FC<Props> = (props: Props) => {
  const { labelMsgId, checked, onChange, id, format, content, isError } = props;
  const changeHandler = (): void => {
    onChange(!checked);
  };

  const CheckboxComponents = {
    [DESIGN.DE]: DECheckbox,
    [DESIGN.default]: DefaultCheckbox,
  };

  const Checkbox = CheckboxComponents[ui.design];

  return (
    <Checkbox
      checked={checked}
      onChange={changeHandler}
      id={id}
      labelMsgId={labelMsgId}
      format={format}
      content={content}
      isError={isError}
    />
  );
};

export default Checkbox;
