import { SessionStorageManager } from 'legacy/utils/SessionStorageManager';
import { CHANNEL } from 'legacy/constants/storageKeys';
import { Channels } from 'legacy/types/Payment';

export class ChannelManager {
  static setChannel(value: Channels): void {
    SessionStorageManager.setItem(CHANNEL, value);
  }

  static getChannel() {
    const channel = SessionStorageManager.getItem(CHANNEL) || null;
    return channel;
  }
}
