import { createSlice, PayloadAction, createAction } from 'redux-starter-kit';
import { initialState, UXFlowState } from './state';
import { State } from '../state';
import {
  UXFlow as UXFlowType,
  UXFlowErrors,
} from '../../types/UXFlow';

const UXFlow = createSlice({
  name: 'uxflow',
  initialState,
  reducers: {
    setFlow: (state, { payload: flow }: PayloadAction<UXFlowType>): UXFlowState => ({
      ...state,
      flow,
    }),
    setFlowErrors: (state, { payload: flowErrors }: PayloadAction<UXFlowErrors>): UXFlowState => ({
      ...state,
      flowErrors,
    }),
  },
});

/** reducer */
export const { reducer } = UXFlow;

/** actions */
export const actions = {
  ...UXFlow.actions,
};

/** selectors */
export const selectors = {
  getUXFlowState: (state: State): UXFlowState => state.UXFlow,
  flow: (state: State): UXFlowType => selectors.getUXFlowState(state).flow,
};
