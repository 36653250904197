import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Text from 'components/Text';
import { MappedUXFlowErrors } from 'types/data';
import vipClubImage from 'assets/marni-vipclub-heart.svg';
import vipClubImageRed from 'assets/marni-vipclub-heart-red.png';
import PageContainer from 'apps/UXFlow/components/PageContainer/DE';
import { AppProps } from 'types/apps';
import Button from 'components/Button';
import DangerousHTMLContainer from 'components/DangerousHTMLContainer';


interface Props {
  data: any;
  errors: MappedUXFlowErrors;
  loading: boolean;
  handleSubmit: () => void;
}

const DEDemoVipClub: React.FC<Props & AppProps> = (props: Props & AppProps) => {
  const { data, loading, handleSubmit } = props;

  const [buttonClicked, setButtonClicked] = useState<number | null>(null);

  const [fakeLoading, setFakeLoading] = useState<boolean>(false);

  const onButtonClicked = (buttonId: number) => {
    setFakeLoading(true);
    setButtonClicked(buttonId);
    setTimeout(() => {
      handleSubmit()
      setFakeLoading(false);
    }, buttonId == 1 ? 3500 : 0);
    // handleSubmit();
  };

  const isLoading = loading || fakeLoading;

  return (
    <PageContainer
      titleMessageId="DEDemoVipClubStepTitle"
      goBack={props.goBack}
      backBtnEnabled={props.backBtnEnabled}
      onClose={props.onClose}
    >
      <Wrapper>
        <img src={buttonClicked == 1 ? vipClubImageRed : vipClubImage} width={77} height={60} />

        {buttonClicked == 1 ? (
          <>
            <Text content="Thank you for joining!" className="marni-linehight" type="content" />
            <Text
              content="congrats & WELCOME TO Our club!"
              className="marni-linehight uppercase joined-text"
              type="content"
            />
            <Row>
              <img src={vipClubImage} className="miniheart" />
              <img src={vipClubImage} className="miniheart" />
              <img src={vipClubImage} className="miniheart" />
            </Row>
          </>
        ) : (
          <>
            <Text
              messageId="DEDemoVipClubStepTextUpper"
              className="marni-linehight"
              type="content"
            />
            <Text
              messageId="DEDemoVipClubStepTextLower"
              className="marni-linehight uppercase"
              type="content"
            />
          </>
        )}

        <ButtonContainer>
          <ButtonsWrapper>
            <Button
              messageId="DEDemoVipClubStepButtonJoin"
              onClick={() => onButtonClicked(1)}
              isLoading={isLoading && buttonClicked == 1}
              disabled={isLoading && buttonClicked !== 1}
            />

            <Button
              messageId="DEDemoVipClubStepButtonSkip"
              onClick={() => onButtonClicked(2)}
              isLoading={isLoading && buttonClicked == 2}
              disabled={isLoading && buttonClicked !== 2}
            />
          </ButtonsWrapper>
          <HTMLWrapper messageId="DEDemoVipClubStepTextDetails" className="details" />
        </ButtonContainer>
      </Wrapper>
    </PageContainer>
  );
};

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
`;

const ButtonsWrapper = styled.div`
  margin-top: 101px;
  display: flex;
  flex-direction: row;
  gap: 21px;
  margin-bottom: 15px;
`;

const HTMLWrapper = styled(DangerousHTMLContainer)`
  #details-link {
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > img {
    margin-top: 26px;
    margin-bottom: 36px;
  }

  & .details {
    font-size: 10px;
    color: #cacaca;
    line-height: 13px;
  }

  & .marni-linehight {
    line-height: 141.2%;
    margin-bottom: 6px;
  }

  & .joined-text {
    color: #b22335;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.03em;
  }

  & .uppercase {
    text-transform: uppercase;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3;

  & .miniheart {
    width: 14.67px;
    height: 11px;
  }
`;

export default DEDemoVipClub;
