import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Text from 'components/Text';

const ToastTemplate = ({ messageId, content }: { messageId?: string; content?: string }) => {
  return (
    <div className="toaster-message">
      <i className="toaster-icon flex-none" />
      <Text messageId={messageId} content={content} type="subtitle" />
    </div>
  );
};

export default ToastTemplate;
