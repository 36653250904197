import { FLOW } from 'legacy/constants/pathnames';
import lean from 'legacy/helpers/switchFlow/flows/lean';
import { selectors } from 'legacy/store/order';
import { store } from 'legacy/store/store';
import UAParser from 'ua-parser-js';
import { LocalStorageManager } from './LocalStorageManager';

export function mobileCheck() {
  return forceMobileFeatures() || isMobileByUserAgent();
}

function isMobileByUserAgent() {
  const key = '__NPG__MOBILE';
  if ((document as any)[key] != undefined) {
    return (document as any)[key];
  }
  const uaParser = new UAParser();
  const { type } = uaParser.getDevice();
  const isMobile = type == 'mobile' || type == 'tablet';
  (document as any)[key] = isMobile;
  return isMobile;
}

/**
 * Returns the user os for Windows Phone, Android or IOS
 */
function getMobileOperatingSystem(): 'Windows Phone' | 'Android' | 'iOS' | 'Other' {
  var userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return 'iOS';
  }

  return 'Other';
}

export function isMobileOperatingSystem(os: string): boolean {
  return (
    forceMobileFeatures() ||
    (mobileCheck() && getMobileOperatingSystem().toLowerCase() === os.toLowerCase())
  );
}

export function forceMobileFeatures() {
  return (
    (document as any).FORCE_MOBILE_FEATURES === 'true' ||
    process.env.REACT_APP_FORCE_MOBILE_FEATURES === 'true'
  );
}

const phoneNumberPrefixes = ['972', '39', '380', '49'];

export function formatPhoneNumber(phoneNumber: string): string {
  phoneNumber = phoneNumber
    .replace('+', '')
    .replace(' ', '')
    .replace(/\D/g, '');

  for (const prefix of phoneNumberPrefixes) {
    if (phoneNumber.startsWith(prefix)) {
      phoneNumber = phoneNumber.replace(prefix, `${prefix} `);
    }
  }

  return `+${phoneNumber}`;
}

export function formatPrice(price: number): string {
  return (price / 100).toFixed(2).replace(/\./g, ',');
}

export function getNumberOfInstallments(): number {
  const state = store.getState();
  const order = selectors.orderDetailsSelector(state);

  const isLean = lean(state);

  const storagedInstallments = LocalStorageManager.getItem('selectedNumberOfInstallments');
  const stateInstallments = order?.installmentsNumber || 1;
  const leanInstallments = storagedInstallments ? +storagedInstallments + 1 : stateInstallments;

  return isLean ? leanInstallments : stateInstallments;
}

export function getFlowId(): string {
  const urlParams = new URLSearchParams(window.location.search);

  let id = urlParams.get('prId') || '';

  if (!id) {
    const url = window.location.pathname.split('/');
    id = url[url.length - 1];
  }

  return id;
}

export function getFlowPath(): string {
  const orderId = selectors.orderDetailsSelector(store.getState())?.id || '';

  return `${FLOW}/${orderId}`;
}
