import { actions } from 'legacy/store/order';
import { State } from 'legacy/store/state';
import { selectors, actions as UIActions } from 'legacy/store/UI';
import { connect } from 'react-redux';
import { selectors as OrderSelectors } from 'legacy/store/order';
import { actions as ConsumerActions } from 'legacy/store/consumer';

const mapDispatchToProps = {
  tryToGetOrderDetails: actions.tryToGetOrderDetails,
  generateSidebarImage: UIActions.generateSidebarImage,
  initPaymentSession: actions.initPaymentSession,
  checkCookie: UIActions.checkCookie,
  getText: UIActions.getText,
  requestConsumerInfo: ConsumerActions.requestConsumerInfo,
  setTheme: UIActions.setTheme,
  setPortingUI: UIActions.setPortingUI,
};

const mapStateToProps = (state: State) => ({
  isLoading: selectors.loadingSelector(state),
  isOrderLoading: OrderSelectors.orderLoadingSelector(state),
  orderExpired: state.order.orderDetails?.orderExpired,
  isPortingUI: selectors.isPorting(state),
  orderDetails: OrderSelectors.orderDetailsSelector(state),
  theme: selectors.theme(state),
});

export default connect(mapStateToProps, mapDispatchToProps);
