import React from 'react';
import styled from 'styled-components';
import DangerousHTMLContainer from 'components/DangerousHTMLContainer';

const InfoBox = styled.div`
  position: absolute;
  z-index: 1;
  width: 421px;
  min-height: 312px;
  top: 30px;
  left: -30px;

  @media (max-width: 550px) {
    width: 95%;
    left: 0;
  }
`;

const ContentBox = styled.div`
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  margin: auto;
  border-radius: 5px;
  padding: 5px;
  min-height: 312px;
`;

const Content = styled.div`
  padding: 27px 20px 23px 20px;
  margin-top: 12.75px;
  height: 312px;
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: #dedede #efeef;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #efeeef;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #dedede;
    border-radius: 5px;
  }
`;

const CloseBtn = styled.div`
  color: #bababa;
  float: right;
  font-size: 25px;
  padding: 5px;
  line-height: 10px;

  :hover,
  :focus {
    color: #666666;
    text-decoration: none;
    cursor: pointer;
  }
`;

interface Props {
  content?: string;
  messageId?: string;
  close: (e: any) => void;
  onClick?: (e: any) => void;
}

const ScrollablePopover: React.FC<Props> = ({ messageId, content, close, onClick }: Props) => {
  return (
    <InfoBox id="agreement-infobox" onClick={close}>
      <ContentBox
        onClick={(e: any) => {
          e.stopPropagation();

          if (onClick) {
            return onClick(e);
          }
        }}
      >
        <CloseBtn onClick={close}>&times;</CloseBtn>
        <Content>
          <DangerousHTMLContainer content={content} messageId={messageId} />
        </Content>
      </ContentBox>
    </InfoBox>
  );
};

export default ScrollablePopover;
