import styled from 'styled-components';

export const Container = styled('div')`
  width: 70%;
  height: 70%;
`;

export const IFrameWrapper = styled('div')`
  text-align: center;
  height: 70%;
  min-height: 400px;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  iframe {
    height: 70%;
    min-height: 400px;
    min-width: 100%;
    border: none;
  }
`;
