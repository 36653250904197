import { createSlice, createAction, createSelector, PayloadAction } from 'redux-starter-kit';
import { State } from 'legacy/store/state';
import { Order } from 'legacy/types/Order';
import { initialState, OrderState } from './state';

/** order slice */
const orderSlice = createSlice({
  name: 'order',
  initialState: initialState,
  reducers: {
    setIsOrderLoading: (state, { payload: isOrderLoading }: PayloadAction<boolean>): OrderState => ({
      ...state,
      isOrderLoading,
    }),
    setOrderDetails: (state, { payload: orderDetails }: PayloadAction<Order>): OrderState => ({
      ...state,
      orderDetails: {
        ...state.orderDetails,
        ...orderDetails,
        expirationTime: orderDetails.expirationTime ? (new Date(orderDetails.expirationTime)).getTime() : undefined,
      },
    }),
    setIsPaymentClosed: (
      state,
      { payload: isPaymentClosed }: PayloadAction<boolean>
    ): OrderState => ({
      ...state,
      isPaymentClosed,
    }),
  },
});

export const { reducer } = orderSlice;

/** sagas actions */
const tryToGetOrderDetails = createAction<string | undefined>('tryToGetOrderDetails');
const initPaymentSession = createAction<string | null>('initPaymentSession');

export const actions = {
  ...orderSlice.actions,
  tryToGetOrderDetails,
  initPaymentSession
};

/** selectors */
const orderSelector = (state: State): OrderState => state.order;
const orderDetailsSelector = createSelector(orderSelector, data => data.orderDetails);
const orderLoadingSelector = createSelector(orderSelector, data => data.isOrderLoading);
const orderIsPaymentClosed = createSelector(orderSelector, data => data.isPaymentClosed);
const isPorting = createSelector(orderSelector, data => data.orderDetails?.paymentProgram === "installments_compass");

export const selectors = {
  orderDetailsSelector,
  orderLoadingSelector,
  orderIsPaymentClosed,
  isPorting,
};
