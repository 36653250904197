import { put, take } from "@redux-saga/core/effects";
import { call } from "redux-saga-test-plan/matchers";
import { PayloadAction } from "redux-starter-kit";
import { Order } from "legacy/types/Order";
import { actions } from '../index';
import { delay } from "redux-saga/effects";

function sleep(ms: number) {
    return new Promise<void>(resolve => {
        setTimeout(() => {
            resolve();
        }, ms);
    });
}

export function* setOrderDetailsSaga() {
    const { payload: orderDetails }: PayloadAction<Order> = yield take(actions.setOrderDetails)

    if (!orderDetails.expirationTime) { 
        return;
    }

    const expirationTime = (new Date(orderDetails.expirationTime)).getTime();
    const nowTime = Date.now();

    if (expirationTime > nowTime) {
        yield delay(expirationTime - nowTime);

        yield put(actions.setOrderDetails(({
            orderExpired: true
        } as any)))
    }
}