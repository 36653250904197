import React from 'react';
import ui from 'store/ui';
import { DESIGN } from 'types/design';
import { AppComponentProps } from 'types/apps';

import DEEMailAddressForm from './EMailAddressForm/DE';
import DefaultEMailAddressForm from './EMailAddressForm/Default';
import { ParentProps } from '../types';

const LeanEMailAddress: React.FC<AppComponentProps & ParentProps> = (
  props: AppComponentProps & ParentProps
) => {
  const { design } = ui;

  const components = {
    [DESIGN.DE]: DEEMailAddressForm,
    [DESIGN.default]: DefaultEMailAddressForm,
  };

  const EmailAddressForm = components[design];

  return <EmailAddressForm {...props} />;
};

export default LeanEMailAddress;
