import Text from 'legacy/DFE/components/Text';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { selectors as OrderSelectors } from 'legacy/DFE/store/order';
import { State } from 'legacy/DFE/store/state';
import styled from 'styled-components';
import { formatPrice } from 'legacy/DFE/utils/ui';

const Container = styled('div')<{ width?: string }>`
  height: 5px;
  background-color: #d8d6d4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  bottom: 7px;
  z-index: -1;
  width: ${props => props.width || '485px'};
  margin-right: 1px;
`;

const InvisibleContainer = styled('div')<{ width?: string }>`
  width: ${props => props.width || '550px'};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CenterContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const squareSize = '10px';

const Square = styled('div')`
  width: ${squareSize};
  height: ${squareSize};
  background-color: #232223;
`;

const mapStateToProps = (state: State) => ({
  orderDetails: OrderSelectors.order(state),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector> & {
  spaceHeight?: string;
  textWidth?: string;
  lineWidth?: string;
};

function toTwoDigits(num: number): string {
  return num > 9 ? `${num}` : `0${num}`;
}

function formatDate(installmentNumber: number): string {
  const date = new Date();

  return installmentNumber === 0
    ? 'Today'
    : `${toTwoDigits(date.getDate())}/${toTwoDigits(
        date.getMonth() + 1 + installmentNumber
      )}/${date.getFullYear()}`;
}

const PaymentPlan: React.FC<PropsFromRedux> = (props: PropsFromRedux) => {
  const { orderDetails, spaceHeight, lineWidth, textWidth } = props;

  const texts = [];
  const squres = [];

  for (
    let installmentNumber = 0;
    installmentNumber < (orderDetails?.installmentsNumber || 0);
    installmentNumber++
  ) {
    texts.push(<></>);
    squres.push(
      <>
        <CenterContainer>
          <Text className="fs-20">{formatPrice(orderDetails?.installmentAmount || NaN)}</Text>
          <Text className="light fs-16">{formatDate(installmentNumber)}</Text>
          <Square />
        </CenterContainer>
      </>
    );
  }

  return (
    <>
      <CenterContainer>
        <InvisibleContainer width={textWidth}>{squres}</InvisibleContainer>
        <Container width={lineWidth} />
      </CenterContainer>
    </>
  );
};

export default connector(PaymentPlan);
