import React from 'react';

import Text from 'components/Text';
import { convertBase64ToFile } from 'utils/OCR';
import { BottomTextBox, Cover, TopTextBox } from '../styles';
import { ApproveButton, Image, RetakeButton } from './styles';

type ConfirmType = {
  imageData: string;
  onUpload: (file: File) => void;
  retake: () => void;
};

const Confirm: React.FC<ConfirmType> = (props: ConfirmType) => {
  const { imageData, onUpload, retake } = props;

  const handleUpload = async (): Promise<void> => onUpload(await convertBase64ToFile(imageData));

  return (
    <Cover backgroundColor="rgb(133,156,131)">
      <Image src={imageData} />
      <TopTextBox>
        <Text messageId="topOCRApproveText" className="light" />
      </TopTextBox>
      <BottomTextBox>
        <Text messageId="topOCRApproveText" className="light" />
      </BottomTextBox>
      <ApproveButton onClick={handleUpload}>
        <Text messageId="OCRLooksGood" className="light" />
      </ApproveButton>
      <RetakeButton onClick={retake}>
        <Text messageId="OCRRetake" className="light" />
      </RetakeButton>
    </Cover>
  );
};

export default Confirm;
