import React from 'react';
import { AppProps } from 'types/apps';
import { MappedUXFlowErrors } from 'types/data';

interface Props {
  data: any;
  errors: MappedUXFlowErrors;
  loading: boolean;
  handleSubmit: () => void;
}

const DEPaymentComplete: React.FC<Props & AppProps> = () => {
  return (
    <>
      <h1>Default view is not implemented yet.</h1>
    </>
  );
};

export default DEPaymentComplete;
