import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import FormattedMessage from 'legacy/components/FormattedMessage';

const ToastTemplate = ({ messageId, content }: { messageId?: string; content?: string }) => {
  return (
    <div className="toaster-message">
      <i className="toaster-icon flex-none" />
      <FormattedMessage messageId={messageId} content={content} className="fs-16" />
    </div>
  );
};

export default ToastTemplate;
