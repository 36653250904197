import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastTemplate from 'legacy/DFE/components/ToastTemplate';

type ToastStatus = 'success' | 'error' | 'warn' | 'info';

export const notify = (status: ToastStatus, messageId: string) => {
  return toast[status](<ToastTemplate messageId={messageId} />);
};
