import React, { useEffect } from 'react';

import flow from 'apps/UXFlow/store/flow';
import Text from 'components/Text';
import { CameraStream, drawCanvas } from 'utils/OCR';
import { Button, GalleryBox, GalleryImage, HiddenInput, WhiteRectangle, Canvas } from './styles';
import { BottomTextBox, Cover, TopTextBox, VideoStream } from '../styles';

import gallery from 'assets/ocr/gallery.svg';

type CaptureType = {
  setImageData: (data: string) => void;
  onUpload: (file: File) => void;
  setIsCameraUsed: (isCameraUsed: boolean) => void;
};

const Capture: React.FC<CaptureType> = (props: CaptureType) => {
  const { setIsCameraUsed, setImageData, onUpload } = props;

  useEffect(() => {
    async function loadCameraStream(): Promise<void> {
      const cameraView = document.getElementById('camera-view') as HTMLVideoElement;

      // Trying to get a CameraStream from the camera of the user
      try {
        CameraStream.stream =
          CameraStream.stream ||
          (await navigator.mediaDevices.getUserMedia({
            video: {
              facingMode: 'environment',
              width: { ideal: window.innerWidth },
              height: { ideal: window.innerHeight },
            },
            audio: false,
          }));
        // If there's an error and we know how to handle it, it's handled. If not let's throw it and take care of it from Sentry.
      } catch (err) {
        const error = err as any;

        if (error.name === 'NotReadableError' || error.name === 'TrackStartError') {
          setIsCameraUsed(false);
          return;
        } else {
          throw error;
        }
      }

      cameraView!.srcObject = CameraStream.stream;

      // FullScreen has better support & UX for streams
      try {
        await document.getElementById('fullscreenid')?.requestFullscreen();
      } catch (error) {
        // From Sentry logs we know it's mainly unsupported browsers, Not relevant to log it or take care of it
      }
    }

    loadCameraStream();
  }, []);

  function onClick(): void {
    const cameraView = document.getElementById('camera-view') as HTMLVideoElement;
    const cameraSensor = document.getElementById('camera-sensor') as HTMLCanvasElement;
    const rectanglePos = document.getElementById('rectangle')?.getBoundingClientRect();

    // We're setting the cameraSensor widht and height to keep the proportion of the image according to the rectangle
    cameraSensor.width = rectanglePos?.width as number;
    cameraSensor.height = Math.round((rectanglePos?.height as number) * 0.9);

    drawCanvas(cameraSensor, cameraView, document.getElementById('rectangle'), 20);

    setImageData(cameraSensor.toDataURL('image/jpeg'));
  }

  return (
    <div id="fullscreenid">
      {/* This is the 'Camera Sensor' and it's used to draw the exact frame from the video to convert it later to JPG */}
      <Canvas id="camera-sensor" />
      {/* This is for showing the user the stream from the camera */}
      <VideoStream id="camera-view" autoPlay playsInline />
      {/* This is where we put stuff on the stream, Like rectangle and texts */}
      <Cover>
        <TopTextBox>
          <Text messageId="topOCRText" className="light" />
        </TopTextBox>
        <BottomTextBox>
          <Text messageId="bottomOCRText" className="light" />
        </BottomTextBox>
        <WhiteRectangle id="rectangle" />

        <Button onClick={onClick} />
        <GalleryBox htmlFor="gallery">
          <GalleryImage src={gallery} alt="Gallery" />
        </GalleryBox>

        <HiddenInput
          id="gallery"
          type="file"
          accept={flow.data?.supportedFormats}
          onChange={event => onUpload(event.target.files?.[0]!)}
        />
      </Cover>
    </div>
  );
};

export default Capture;
