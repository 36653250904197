import axios from 'axios';
import { User } from 'legacy/types/User';
import { getApiUrl } from 'legacy/helpers/getApiUrl';
import { getStringifiedParams } from 'legacy/helpers/getStringifiedParams';

/** otp api calls */
interface UserToken {
  token: string;
}

const OTP_URL = 'otp/';

export const createOtp = (mobile: string): Promise<User> => {
  return axios.post(getApiUrl(`${OTP_URL}otpCreate`), getStringifiedParams({ mobile }));
};

export const loginOtp = (mobile: string, otp: string, rememberMe: boolean): Promise<UserToken> => {
  const rememberMeValue = rememberMe ? 1 : 0;
  return axios.post(getApiUrl(`${OTP_URL}otpLogin`), getStringifiedParams({ mobile, otp, rememberMe: rememberMeValue }));
};
