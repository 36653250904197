export type IdType = 'ID_TYPE_ELECTRONIC_CURRENT' | 'ID_TYPE_ELECTRONIC' | 'ID_TYPE_PAPER';

export interface ConsumerDocuments {
  idType: null | IdType;
  idNumber: string;
  issuingDate: string;
  expiryDate: string;
  issuingPlace: string;
}

export interface Consumer extends ConsumerDocuments {
  firstName: null | string;
  lastName: null | string;
  email: null | string;
  mobile: string;
  address: null | string;
  fiscalCode: null | string;
  city: null | string;
  province: null | string;
  country: null | string;
  zipcode: null | string;
  privacyFlag: boolean;
  hasPin: boolean;
  promotionEmailsFlag: boolean;
  selectedLanguage: string;
  marketingMaterialsFlag: boolean;
  termsOfUse: any;
  birthDate: string;
  consents: any;
  version: string;
  first: boolean;
  // OCR
  image: any;
  side: string;
  fileFormat: string;
  checksum: string;
  birthPlace: string;
}

export interface CardsStatus {
  creditCard: boolean,
  debitCard: boolean
}

export interface MobileNumber {
  prefix: string,
  number: string,
}

export interface OnboardingFlowQueryParams {
  type: string,
  client: string,
  paymentType: string,
  source: string,
  channel: string,
  paymentRequestId: string,
  id: string,
  offset: number,
}

export interface OnboardingFlow {
  next: string;
  data: any;
  nextStepIndex: number;
  totalSteps: number;
  offset: number;
  refresh: boolean;
  last4Digits?: string;
  complete?: boolean;
}

export interface OnboardingFlowErrors {
  invalidFields: Record<string, boolean>,
  flowError: string
}

export interface CardFlow {
  next: string,
  data: any
}

export interface OnboardingFlowAPIData {
  profile: Partial<Consumer> & { resend?: boolean, doNotSetFlow?: boolean };
  query: Partial<OnboardingFlowQueryParams>;
  step?: string;
}

export interface CardFlowData {
  data: {
    stepId?: string,
    bin?: string,
    binType?: string,
    binScheme?: string,
    isBinValid?: boolean,
    cardURL?: string,
    cardId?: string,
    isCardValid?: boolean,
    error?: string,
    cardsAmount?: number,
    timeout?: number,
  },
  query: Partial<OnboardingFlowQueryParams>,
  onSubmit?: () => void;
}

export enum CardFlowErrors {
  UnexpectedError = 'UnexpectedError',
  InitCardFailed = 'InitCardFailed',
  BinValidationError = 'BinValidationError',
  AddingCardFailed = 'AddingCardFailed'
}
