import React from 'react';
import styled from 'styled-components';
import errorImg from 'legacy/assets/error-icon.png';
import FormattedMessage from 'legacy/components/FormattedMessage';

const ImageWrapper = styled('footer')`
  text-align: center;
  margin-bottom: 10px;
`;

const Welcome: React.FC = () => {
  return (
    <div>
      <ImageWrapper>
        <img src={errorImg} alt="error image" width="100" height="100" />
      </ImageWrapper>
      <FormattedMessage messageId="errors:something-is-wrong-title" className="fs-23 mb-20" />
      <FormattedMessage messageId="errors:something-is-wrong-description" className="fs-23" />
    </div>
  );
};

export default Welcome;
