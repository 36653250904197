import { ValidatedUserAuthData } from 'legacy/types/ValidatedUser';

export interface PinCodeValidationStatus {
  pending: boolean;
  error: boolean;
  validationAuthData: ValidatedUserAuthData | null;
}

export const initialPinCodeValidationState: PinCodeValidationStatus = {
  pending: false,
  error: false,
  validationAuthData: null,
};
