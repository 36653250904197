import React, { useState } from 'react';
import FlexColumn from 'components/FlexColumn';
import Button from 'components/Button';
import Popup from 'components/Popup/Popup';
import { PopupProps } from 'components/Popup/types';
import styled from 'styled-components';
import Text from 'components/Text';
import cancelAPI from 'api/cancelAPI';
import flow from 'apps/UXFlow/store/flow';
import { notify } from 'helpers/notify';
import { isOpenedInIframe } from 'utils/ui';

const ButtonsGroup = styled('div')`
  display: flex;
  justify-content: center;

  button:first-child {
    margin-right: 25px;
  }

  @media (max-width: 650px) {
    flex-direction: column;

    button:first-child {
      margin: 0;
    }

    button:last-child {
      order: -1;
      margin-bottom: 40px;
    }
  }
`;

const CancelConfirmation: React.FC<PopupProps> = (props: PopupProps) => {
  const { isOpen, onClose } = props;
  const [loading, setLoading] = useState<boolean>(false);

  const submit = async (): Promise<void> => {
    setLoading(true);

    try {
      const result = await cancelAPI.cancel({
        paymentRequestId: flow.requestId,
        transactionId: flow?.data?.transactionId
      });

      const redirectURL = result?.data?.redirectURL || flow.referrer;
      const isIframe = isOpenedInIframe();

      if (isIframe) {
        window.parent.postMessage({ url: redirectURL }, '*');
        console.log('Post message is sent.');
      } else {
        window.location.href = redirectURL;
      }
    } catch (e) {
      notify('error', 'something-went-wrong');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Popup isOpen={isOpen} onClose={onClose}>
      <FlexColumn>
        <Text messageId="cancelConfirmation" className="fs-23" type="subtitle" />

        <ButtonsGroup>
          <Button messageId="cancelYes" onClick={submit} isLoading={loading} className="mb-20" />
          <Button messageId="cancelNo" onClick={onClose} disabled={loading} className="mb-20" />
        </ButtonsGroup>
       </FlexColumn>
    </Popup>
  );
};

export default CancelConfirmation;
