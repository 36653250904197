import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';

type BackgroundProps = {
  backgroundImage: string;
  backgroundSize?: string;
  backgroundPosition?: string;
};

export const Background = styled('div')<BackgroundProps>`
    width: 100vw;
    height: 100vh;
    background-image: url("${props => props.backgroundImage}");
    background-size: ${props => props.backgroundSize || 'cover'};
    background-position: ${props => props.backgroundPosition || 'center'};

    @media (max-width: 700px) {
        background-image: unset;
    }
`;

export const Frame = styled('div')`
  background-color: white;
  width: 580px;
  height: 700px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding-top: 40px;

  @media (max-width: 700px) {
    box-shadow: unset;
    width: 100vw;
    height: 100vh;
  }

  @media (max-height: 700px) {
    height: 100vh;
  }
`;

export const GlobalStyles = createGlobalStyle<{}>`
  /* spacing */
  .mb-50 {
    margin-bottom: 50px;
  }

  .mb-40 {
    margin-bottom: 40px;
  }

  .mb-30 {
    margin-bottom: 20px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mt-30 {
    margin-top: 10px;
  }

  .mt-15 {
    margin-top: 15px;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .w-10p {
    width: 10%;
  }

  /* customize scroll */
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #8683a4;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 20px;
  }

  @media (max-height: 700px) {
      height: 100vh;
  }
`;