import React from 'react';
import styled from 'styled-components';
import Text from 'components/Text';
import DarkGermanFlag from 'assets/german-dark.png';
import Vector from 'assets/vector.svg';
import { Placeholder, PlaceholderText } from 'components/InputForm/DE/input.styles';
import { MockSelectorWrapper } from '../index.styles';

interface Props {
  placeholderMessageId?: string;
}

const MockSelector: React.FC<Props> = ({ placeholderMessageId }) => {
  return (
    <MockSelectorWrapper>
      {placeholderMessageId && (
        <Placeholder>
          <PlaceholderText messageId={placeholderMessageId} type="label"/>
        </Placeholder>
      )}

      <StyledInput>
        <GermanIcon src={DarkGermanFlag} />
        <StyledText messageId="DEAddressGermany" />
      </StyledInput>

      <VectorIcon src={Vector} />
    </MockSelectorWrapper>
  );
};

const StyledInput = styled('div')`
  display: flex;
  align-items: center;
  padding-top: 15px;
`;

const StyledText = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  color: #a5a5a5;
  margin-left: 15px;
`;

const GermanIcon = styled('img')`
  height: 18px;
  margin-left: 24px;
`;

const VectorIcon = styled('img')`
  height: 14px;
  position: absolute;
  right: 20px;
  transform: rotate(90deg);
`;

export default MockSelector;
