import styled from 'styled-components';

export const Form = styled('div')`
  position: relative;
`;

export const ErrorWrapper = styled('div')`
  margin-top: 30px;
`;

export const InputWrapper = styled('div')<{ error?: boolean }>`
  display: flex;
  justify-content: center;

  .otp-input.secure {
    -webkit-text-security: disc;
    -moz-webkit-text-security: disc;
    -moz-text-security: disc;
  }

  .otp-input {
    min-width: 0%;
    padding: 20px;
    border: none;
    border-bottom: 1px solid ${props => (props.error ? '#FF3030' : '#c4c4c4')};
    margin: 0 12px !important;
    background: transparent;
    color: #16142c;
    font-size: 30px;

    @media (max-width: 550px) {
      padding: 10px;
      font-size: 20px;
      margin: 0 4px !important;
    }

    &:focus {
      outline: none;
      border-bottom: 1px solid #000000;
    }
  }

  .square-input {
    min-width: 0%;
    padding: 10px;
    font-size: 30px;

    @media (max-width: 550px) {
      padding: 10px;
      font-size: 20px;
      margin: 0 4px !important;
    }

    &:focus {
      outline: none;
    }
  }
`;
