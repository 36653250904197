import React from 'react';
import styled from 'styled-components';
import DangerousHTMLContainer from 'components/DangerousHTMLContainer';
import error from 'assets/error.svg';

const InfoBox = styled.div`
  position: absolute;
  z-index: 1;
  width: 340px;
  min-height: 312px;
  bottom: 70px;

  @media (max-width: 550px) {
    width: 95%;
  }
`;

const ContentBox = styled.div`
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  margin: auto;
  border-radius: 5px;
  padding: 5px;
  min-height: 240px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: red;
  padding: 27px 20px 23px 20px;
  margin-top: 12.75px;
  height: 200px;
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: #dedede #efeef;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #efeeef;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #dedede;
    border-radius: 5px;
  }
`;

const CloseBtn = styled.div`
  color: #bababa;
  float: right;
  font-size: 25px;
  padding: 5px;
  line-height: 10px;

  :hover,
  :focus {
    color: #666666;
    text-decoration: none;
    cursor: pointer;
  }
`;

export const Icon = styled('img')`
  height: 23px;
  margin-bottom: 14px;
`;

interface Props {
    content?: string;
    messageId?: string;
    close: (e: any) => void;
    onClick?: (e: any) => void;
}

const ErrorPopup: React.FC<Props> = ({ messageId, content, close, onClick }: Props) => {
    return (
        <InfoBox id="error-box" onClick={close}>

            <ContentBox
                onClick={(e: any) => {
                    e.stopPropagation();

                    if (onClick) {
                        return onClick(e);
                    }
                }}
            >
                <CloseBtn onClick={close}>&times;</CloseBtn>
                <Content>
                    <Icon src={error} />
                    <DangerousHTMLContainer content={content} messageId={messageId} />
                </Content>
            </ContentBox>
        </InfoBox>
    );
};

export default ErrorPopup;
