import { createSlice, PayloadAction, createAction } from 'redux-starter-kit';
import { State } from 'legacy/store/state';
import { initialState, OCR, Theme, UIState } from './state';
import { generateImageNumber } from 'legacy/helpers/ui';
import { ScrollManager } from 'legacy/components/Contract/scroll_manager';
import { CameraUsedForType } from 'legacy/containers/Onboarding/components/OCR/types';

/** UI slice*/
const UI = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setIsLoading: (state, { payload: isLoading }: PayloadAction<boolean>): UIState => ({
      ...state,
      isLoading,
    }),
    openPopup: (state, { payload: popupName }: PayloadAction<string>): UIState => ({
      ...state,
      openedPopup: popupName,
    }),
    closePopup: (state): UIState => {
      ScrollManager.setAllowAutoScroll(true);
      return {
        ...state,
        openedPopup: null,
      }
    },
    setRefreshOnClosePopup: (state, { payload: refreshOnClosePopup }): UIState => {
      return {
        ...state,
        refreshOnClosePopup,
      }
    },
    generateSidebarImage: (state): UIState => ({
      ...state,
      sidebarImageHistory: state.sidebarImage,
      sidebarImage: generateImageNumber(state.sidebarImage)
    }),
    setCookieStatus: (state, { payload: isCookieSupported }: PayloadAction<boolean>): UIState => ({
      ...state,
      isCookieSupported,
    }),
    setConfirmedLogout: (state, { payload: confirmedLogout }: PayloadAction<boolean>): UIState => ({
      ...state,
      confirmedLogout,
    }),
    setTheme: (state, { payload: theme }: PayloadAction<any>): UIState => ({
      ...state,
      theme: {
        ...state.theme,
        ...theme,
      },
    }),
    setPortingUI: (state, { payload: portingDesign }: PayloadAction<boolean>): UIState => ({
      ...state,
      portingDesign,
    }),
    setCameraUsedFor: (state, { payload: cameraUsedFor }: PayloadAction<CameraUsedForType>): UIState => ({
      ...state,
      ocr: {
        ...state.ocr,
        cameraUsedFor,
      }
    }),
    setOCRTempImage: (state, { payload }: PayloadAction<{ side: string, data: string }>): void => {
      state.ocr.tempImagesData[payload.side] = payload.data;
    },
    setOCRCardImageLoading: (state, { payload }: PayloadAction<{ side: string, loading: boolean }>): void => {
      state.ocr.cardImageLoading[payload.side] = payload.loading;
    },
    setOCRCardAlreadyUploaded: (state, { payload }: PayloadAction<{ side: string, uploaded: boolean }>): void => {
      state.ocr.errors.alreadyUploaded[payload.side] = payload.uploaded;
    },
    setCurrentPage: (state, { payload }: PayloadAction<string>): UIState => ({
      ...state,
      currentPage: payload,
    }),
  },
});

export const { reducer } = UI;

const checkCookie = createAction('checkCookie');
const getText = createAction('getText');

export const actions = {
  ...UI.actions,
  checkCookie,
  getText
};


/** selectors */
const getUIState = (state: State): UIState => state.UI;
const loadingSelector = (state: State) => getUIState(state).isLoading;
const openedPopupSelector = (state: State) => getUIState(state).openedPopup;
const sidebarImageSelector = (state: State) => getUIState(state).sidebarImage;
const sidebarImageHistorySelector = (state: State) => getUIState(state).sidebarImageHistory;
const logoutConfirmationStatus = (state: State) => getUIState(state).confirmedLogout;
const theme = (state: State): Theme => getUIState(state).theme;
const isPorting = (state: State): boolean => getUIState(state).portingDesign;
const ocr = (state: State): OCR => getUIState(state).ocr;
const currentPage = (state: State): string => getUIState(state).currentPage;

export const selectors = {
  loadingSelector,
  openedPopupSelector,
  sidebarImageSelector,
  sidebarImageHistorySelector,
  logoutConfirmationStatus,
  theme,
  isPorting,
  ocr,
  currentPage,
};