import Text from 'components/Text';
import { observer } from 'mobx-react-lite';
import React from 'react';
import flow from '../store/flow';
import ErrorDotPng from 'assets/error.svg';
import styled from 'styled-components';

const ErrorImage = styled('img')`
  margin-right: 5.79px;
`;
const ErrorContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

type ErrorType = {
  property?: string;
  content?: string;
};

const Error: React.FC<ErrorType> = (props: ErrorType) => {
  const { property, content } = props;

  const message = content
    ? content
    : property
    ? flow?.errors[property]?.message
    : flow?.errors['ROOT']?.message;

  if (!message) {
    return <> </>;
  }

  return (
    <ErrorContainer>
      <ErrorImage src={ErrorDotPng} />

      <Text content={message} type="content" className="error" />
    </ErrorContainer>
  );
};

export default observer(Error);
