import Error from "apps/UXFlow/components/Error";
import flow from "apps/UXFlow/store/flow";
import useSubmitHandler from "apps/UXFlow/utils/hooks/useSubmitHandler";
import Button from "components/Button";
import DangerousHTMLContainer from "components/DangerousHTMLContainer";
import Text from "components/Text";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { AppComponentProps } from "types/apps";
import { ParentProps } from "../types";
import Plan from "./components/Plan";
import { InstallmentPlan } from "./types";

const PriceContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
`;

const PlansContainer = styled('div')`
  margin: 20px 0;
  width: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PaymentPlanSelection: React.FC<AppComponentProps & ParentProps> = (props) => {
  const [expandedPlan, setExpandedPlan] = useState<InstallmentPlan>();
  const [chosen, setChosen] = useState<InstallmentPlan>();

  const { onSubmit, loading } = useSubmitHandler(props);

  const onExpandPlan = (plan: InstallmentPlan) => (): void => {
    plan.id === expandedPlan?.id ? setExpandedPlan(undefined) : setExpandedPlan(plan);
  };

  const onChoosePlan = (plan: InstallmentPlan) => (e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation();
    // LocalStorageManager.setItem('selectedNumberOfInstallments', plan.installments.length);
    setChosen(plan);
    setExpandedPlan(plan);
  };

  const plans = useMemo(() => flow.data.uiPaymentPlans.map((plan: InstallmentPlan) => (
    <Plan
      key={plan.id}
      installments={plan.installmentsPlan}
      onChoosingPlan={onChoosePlan(plan)}
      onExpandPlan={onExpandPlan(plan)}
      isExpanded={plan.id === expandedPlan?.id}
      chosen={plan.id === chosen?.id}
      downPaymentAmount={plan.downPaymentAmount}
    />
  )), [chosen, expandedPlan]);

  return (
    <>
      <PriceContainer>
        <Text type="title" />
        <Text type="title" messageId="totalPrice" className="left-space red" />
      </PriceContainer>
      <Text type="subtitle" />
      <Error />
      <PlansContainer>{plans}</PlansContainer>
      <DangerousHTMLContainer messageId="static" className="black-links p-mg-0" />
      <DangerousHTMLContainer messageId="scroll" height="100px" className="pdl-5 bg-gray scroll" />
      <Button isLoading={loading} onClick={() => onSubmit({ paymentPlanId: chosen?.id })}>
        <Text messageId="confirm" />
      </Button>
      {/* <Button
        isLoading={isPendingState}
        messageId="Conferma"
        clickHandler={(): void => onSubmit && onSubmit(chosen)}
        isCentered
        isDisabled={chosen == null}
      /> */}
    </>
  );
};

export default PaymentPlanSelection;
