import { Route } from 'legacy/DFE/routing/RouterTypes';
import PaymentConfirmationComponent from './index.component';

export default class PaymentConfirmation extends Route {
  constructor() {
    super('PaymentConfirmationComponent', '/payment-confirmation');
  }

  component = PaymentConfirmationComponent;
}
