import styled from 'styled-components';

export const TextStyled = styled('span')`
  text-align: center;
  display: block;
  color: #16142c;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  white-space: pre-line;
  margin-right: 4px;

  /* title extra big */
  &.fs-38 {
    font-size: 2.7em;
  }

  /* title big */
  &.fs-26 {
    font-size: 1.85em;
  }

  /* title small */
  &.fs-23 {
    font-size: 1.64em;
  }

  &.fs-20 {
    font-size: 1.40em;
  }

  &.fs-18 {
    font-size: 1.25em;
  }

  /* paragraph */
  &.fs-16 {
    font-size: 1.14em;
  }

  /* error */
  &.fs-14 {
    font-size: 1em;
  }

  &.fs-12 {
    font-size: 0.8em;
  }

  &.fs-8 {
    font-size: 8px;
  }

  &.red {
    color: #da291c;
  }

  &.light {
    color: #8683a4;
  }

  &.blue {
    color: #229fef;
  }

  &.green {
    color: #009900;
  }

  &.white {
    color: white;
  }

  &.primary {
    color: #fff;
  }

  &.gray {
    color: #939393;
  }

  &.pago-red {
    color: #da291c;
  }

  &.text-underline {
    text-decoration: underline;
  }

  &.bold {
    font-weight: 700;
  }

  &.text-uncentered {
    text-align: unset;
  }

  &.link {
    cursor: pointer;
  }

  &.left {
    text-align: left;
  }

  &.unset-display {
    display: unset;
  }

  &.inline {
    display: inline;
  }

  &.porting10px {
    font-size: 10px;
  }

  &.porting12px {
    font-size: 12px;
  }

  &.porting13px {
    font-size: 13px;
  }

  &.porting14px5 {
    font-size: 14.5px;
  }

  &.porting15px5 {
    font-size: 15.5px;
  }

  &.porting16px5 {
    font-size: 16.5px;
  }

  &.full-width {
    width: 100%;
  }
`;
