import stylesAPI from 'api/stylesAPI';
import flow from 'apps/ChangeCard/store/flow';
import ui from 'store/ui';
import { AppConfigurationType } from 'types/apps';
import { deepMerge } from 'utils/objects';
import ChangeCard from './index.component';
import { setWebsiteTab } from 'utils/ui';
import { NOOSA_SESSION_ID } from '../../constants';
import queryString from 'query-string';

const changeCard: AppConfigurationType = {
  Component: ChangeCard,
  precondition: async () => {
    if (!window.location.pathname.includes('marni/card/change/t')) {
      return false;
    }

    const path = window.location.pathname.split('/');
    const oneTimeToken = path[path.length - 1];

    if (!oneTimeToken) {
      return false;
    }

    flow.ott = oneTimeToken;

    const query = queryString.parse(window.location.search);

    if (query?.language) {
      localStorage.setItem('LANGUAGE', query.language.toString());
    }

    const {
      data: { styles, texts },
      headers,
    } = await stylesAPI.getStyles('', '', query?.mId?.toString());

    const item = sessionStorage.getItem(NOOSA_SESSION_ID);

    try {
      flow.sessionId = item ? JSON.parse(item) : headers['noosa-session-id'];
    } catch {}

    deepMerge(ui.styles, styles);
    setWebsiteTab(ui.styles?.favicon?.image, ui.styles?.websiteTitle);

    ui.texts = {
      ...ui.texts,
      ...texts,
    };

    return true;
  },
};

export default changeCard;
