import React, { useEffect, useState } from 'react';
import OTPInput from 'otp-input-react';
import cx from 'classnames';
import { Form, ErrorWrapper, InputWrapper } from './passwordForm.styles';
import Error from 'components/Error';

interface Props {
  error?: string;
  numInputs: number;
  onChange: (enteredValue: number) => void;
  value: string | number;
  secure?: boolean;
  inputType: 'otp-input' | 'square-input';
  autoFocus?: boolean;
  id?: string;
}

const PasswordForm: React.FC<Props> = (props: Props) => {
  const {
    error,
    numInputs,
    secure,
    inputType,
    autoFocus,
    id = '',
    value = '',
    onChange,
    ...otpInputParams
  } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [result, setResult] = useState<number | null>(null);

  const onResize = (): void => setWindowWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return (): void => window.removeEventListener('resize', onResize);
  }, []);

  const handle = (otp: number): void => {
    setResult(otp);
    onChange(otp);
  };

  let formattedValue = '';
  if (result && result.toString().length) {
    formattedValue = value || value == 0 ? value.toString() : '';
  }

  return (
    <Form>
      <InputWrapper error={!!error}>
        <OTPInput
          key={id}
          inputClassName={cx(inputType, { secure: secure })}
          inputStyles={{
            width: windowWidth > 550 ? '67px' : '43px',
            maxWidth: windowWidth > 550 ? '67px' : '43px',
            height: 'auto',
          }}
          otpType="number"
          autoFocus={autoFocus}
          OTPLength={numInputs}
          value={formattedValue}
          onChange={handle}
          {...otpInputParams}
        />
      </InputWrapper>
      {error && (
        <ErrorWrapper>
          <Error content={error} />
        </ErrorWrapper>
      )}
    </Form>
  );
};

export default PasswordForm;
