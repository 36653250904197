import { combineReducers } from 'redux';
import { reducer as UIReducer } from './UI';
import { reducer as otpReducer } from './otp';
import { reducer as loginReducer } from './login';
import { reducer as consumerReducer } from './consumer';
import { reducer as orderReducer } from './order';
import { reducer as transactionReducer } from './transaction';
import { reducer as contactUsReducer } from './contactUs';
import { reducer as paymentFlowReducer } from './paymentFlow';

export const rootReducer = combineReducers({
  UI: UIReducer,
  otp: otpReducer,
  login: loginReducer,
  consumer: consumerReducer,
  order: orderReducer,
  transaction: transactionReducer,
  contactUs: contactUsReducer,
  paymentFlow: paymentFlowReducer,
});

export type TRootReducer = ReturnType<typeof rootReducer>;
