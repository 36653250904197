import { take, call, select } from 'redux-saga/effects';
import { actions } from 'legacy/store/transaction';
import { cancelCredit } from 'legacy/api';
import { LocalStorageManager } from 'legacy/utils/LocalStorageManager';
import { AuthService } from 'legacy/utils/AuthService';
import {
  TRANSACTION_ID_KEY,
  FAILED_TRANSACTION_ID_KEY,
  ORDER_ID_KEY,
} from 'legacy/constants/storageKeys';
import { errorsWithToasts } from 'legacy/constants/errorsWithToasts';
import { selectors } from 'legacy/store/order';
import { notify } from 'legacy/helpers/notify';
import { cleanOrderData } from 'legacy/helpers/cleanOrderData';
import { getChannel } from 'legacy/helpers/payment';
import { history } from 'legacy/routing/history';
import { CANCELLATION_SUCCESS } from 'legacy/constants/pathnames';

export function* cancelCreditSaga(): any {
  while (true) {
    yield take(actions.requestCancelCredit);

    const transactionId = yield call(LocalStorageManager.getItem, TRANSACTION_ID_KEY);
    const failedTransactionId = yield call(LocalStorageManager.getItem, FAILED_TRANSACTION_ID_KEY);
    const paymentRequestId = yield call(LocalStorageManager.getItem, ORDER_ID_KEY);

    const { isEcommerceChannel, isMerchantAppChannel } = getChannel();

    const order = yield select(selectors.orderDetailsSelector);
    const redirectionURL = order && order.failureURL ? order.failureURL : null;

    const transactionIdToCancel = transactionId || failedTransactionId;

    try {
      if (transactionIdToCancel || paymentRequestId) {
        yield call(cancelCredit, { transactionId: transactionIdToCancel, paymentRequestId });
      }

      // remove all data bound to the order
      yield call(cleanOrderData);

      if (isEcommerceChannel) {
        AuthService.handleRedirectionToMerchant(redirectionURL);
      }
      if (isMerchantAppChannel) {
        history.push(CANCELLATION_SUCCESS);
      }
    } catch (err) {
      if (isEcommerceChannel) {
        AuthService.handleRedirectionToMerchant(redirectionURL);
      } else {
        if (!errorsWithToasts.find(errorItem => err.message === errorItem.message)) {
          notify('error', 'something-went-wrong');
        }
      }
    }
  }
}
