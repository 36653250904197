// export const LANDING = '/landing';
// export const LANDING_WITH_ORDER_ID = `${LANDING}/:orderId`;
// export const LANDING_MAPP = '/mapp_landing';
export const OTP_VALIDATION = '/otp_validation';
export const PIN_CODE_VALIDATION = '/pin_code_validation';
export const PAYMENT_REQUEST = '/payment_request';
export const ON_BOARDING = '/on_boarding';
export const CARD = '/card';
export const WELCOME = '/welcome';
export const RECOVER_PIN = '/recover_pin';
export const FAQ = '/FAQ';
// export const TOKEN = '/t';
// export const TOKEN_WITH_VALUE = `${TOKEN}/:token`;
export const CANCELLATION_SUCCESS = '/cancellation_success';
export const LIST_OF_MERCHANTS = '/merchants';
export const FLOW = '/flow';
export const FLOW_WITH_ID = `${FLOW}/:flowId`;

export const MOBILE_OCR_AUTH = '/ocr/:flowId/auth/t/:token';

export const StaticPages = [
    FAQ,
    LIST_OF_MERCHANTS,
    CANCELLATION_SUCCESS,
];
