import Text from 'components/Text';
import React from 'react';
import MerchantItemContainer from './MerchantItemContainer';
import MerchantTextContainer from './MerchantTextContainer';
import { MerchantItemType } from '../types';

const MerchantItem: React.FC<MerchantItemType> = (props: MerchantItemType) => {
  const { title, description } = props;

  return (
    <>
      <MerchantItemContainer>
        <MerchantTextContainer>
          <Text type="content" content={title} className="light bold" />
        </MerchantTextContainer>
        <MerchantTextContainer>
          <Text type="content" content={description} className="light" />
        </MerchantTextContainer>
      </MerchantItemContainer>
    </>
  );
};

export default MerchantItem;
