import React from 'react';
import styled from 'styled-components';

import Text from 'components/Text';
import { ButtonType } from 'components/Button';

const PaymentMethodButton: React.FC<ButtonType> = (props: ButtonType) => {
  const notAvailable = props.disabled || props.isLoading;

  return (
    <Wrapper onClick={props.onClick} notAvailable={notAvailable}>
      <Row>
        <Column>
          <Label messageId={props.messageId} type="subtitle" />
        </Column>

        <ChildrenWrapper>{props.children}</ChildrenWrapper>
      </Row>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ notAvailable?: boolean }>`
  background: #f8f8f8;
  border: solid 1px #8c8c8c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: ${props => (props.notAvailable ? 'auto' : 'pointer')};
  padding: 20px 16px 20px 24px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  column-gap: 48px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 4px;
`;

const ChildrenWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Label = styled(Text)`
  margin: 0;
`;

export default PaymentMethodButton;
