import { ConsumersAPI } from "legacy/DFE/api/handlers";
import PaddingContainer from "legacy/DFE/components/PaddingContainer";
import Text from "legacy/DFE/components/Text";
import TextInput from "legacy/DFE/components/TextInput";
import { notify } from "legacy/DFE/helpers/notify";
import { RouteComponentType } from "legacy/DFE/routing/RouterTypes";
import { useAsyncEffect } from "legacy/DFE/utils/react";
import React, { useState } from "react";
import styled from "styled-components";
import InlineInputContainer from "../../components/InlineInputContainer";
import InputsContainer from "../../components/InputsContainer";
import { ParentProps } from "../../types";

type AddressComponentType = ParentProps & RouteComponentType & { provincesList: string[] };

const AddressComponent: React.FC<AddressComponentType> = (props: AddressComponentType) => {
    const {
        flow,
        submitFlow,
        useSubmit,
        provincesList,
        done,
    } = props;

    // Inputs
    const [province, setProvince] = useState("");
    const [city, setCity] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [address, setAddress] = useState("");

    // Options
    const [citiesList, setCitiesList] = useState<string[]>([]);
    const [zipCodesList, setZipCodesList] = useState<string[]>([]);

    // On choosing province
    useAsyncEffect(async () => {
        // Deleting any old data
        setCity("");
        setZipCode("");
        setZipCodesList([]);

        // Fetching cities data
        if (provincesList.includes(province?.toUpperCase())) {
            const citiesData = (await ConsumersAPI.getCities(province)).cities;

            setCitiesList(citiesData.map((item: { comune: string }) => item.comune));
        }
        else {
            setCitiesList([]);
        }
    }, [province]);

    // On choosing city
    useAsyncEffect(async () => {
        // Deleting any old data
        setZipCode("");
        setZipCodesList([]);

        // Fetching zipCode data
        if (citiesList.includes(city.toUpperCase())) {
            const zipCodesData = (await ConsumersAPI.getZipCodes(province, city)).zipCodes;

            setZipCodesList(zipCodesData.map((item: { cap: string }) => item.cap));
        }
    }, [city]);

    useSubmit(() => {
        if (!(province && address && city && zipCode)) {
            notify("error", "You have to fill all of the fields in order to continue");
            return;
        }

        // No need to check other fields because if the zipCode has something in it then all of the other fields are already checked in the effects above
        if (!zipCodesList.includes(zipCode)) {
            notify("error", "The zip code is not found. Please choose a zip code from the list.");
            return;
        }

        submitFlow({
            province,
            city,
            zipcode: zipCode,
            address,
        }).then(done);
    });  

    return <>
        <Text fontSize="24px" className="bold"> Complete your profile </Text>
        <Text fontSize="18px" className="thin"> Please provide your billing address </Text>
        <InputsContainer>
            <InlineInputContainer>
                <PaddingContainer padding="0 5px 0 0">
                    <TextInput list="provinces" disabled={provincesList.length === 0} onChange={(v) => setProvince(v)} value={province} inline={true}> Province </TextInput>
                    <datalist id="provinces">
                        {provincesList.map(name => <option key={name}> {name} </option>)}
                    </datalist>
                </PaddingContainer>
                <PaddingContainer padding="0 0 0 5px">
                    <TextInput list="cities" disabled={citiesList.length === 0} onChange={(v) => setCity(v)} value={city} inline={true}> City </TextInput>
                    <datalist id="cities">
                        {citiesList.map(name => <option key={name}> {name} </option>)}
                    </datalist>
                </PaddingContainer>
            </InlineInputContainer>
            <TextInput onChange={(v) => setAddress(v)} value={address}> Address </TextInput>
            <TextInput list="zipCodes" disabled={zipCodesList.length === 0} onChange={(v) => setZipCode(v)} value={zipCode}> Zip Code </TextInput>
            <datalist id="zipCodes">
                {zipCodesList.map(code => <option key={code}> {code} </option>)}
            </datalist>
        </InputsContainer>
    </>;
};

export default AddressComponent;