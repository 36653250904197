import { useEffect, useState } from "react";

export default function useAsyncEffect(effect: () => Promise<void>, dependencies?: any[]) {
    const [pending, setPending] = useState(false);

    useEffect(() => {
        setPending(true);
        effect().finally(() => setPending(false));
    }, dependencies);

    return pending;
}