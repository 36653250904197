import StateManager from 'react-select';
import { createSlice, createAction, PayloadAction } from 'redux-starter-kit';
import { OnboardingFlow, OnboardingFlowErrors, CardFlow, OnboardingFlowAPIData, CardFlowData } from 'legacy/types/Consumer';
import { OnBoardingStatus, initialOnBoardingStatus } from './state';

/** onboarding slice */
const onBoardingSlice = createSlice({
  name: 'onboarding',
  initialState: initialOnBoardingStatus,
  reducers: {
    setFlow: (
      state,
      { payload: flow }: PayloadAction<OnboardingFlow>
    ): OnBoardingStatus => ({
      ...state,
      flow,
    }),
    setFlowErrors: (
      state,
      { payload: flowErrors }: PayloadAction<OnboardingFlowErrors>
    ): OnBoardingStatus => ({
      ...state,
      flowErrors,
    }),
    setCardFlow: (
      state,
      { payload: cardFlow }: PayloadAction<CardFlow>
    ): OnBoardingStatus => ({
      ...state,
      cardFlow,
    }),
    setOnboardingPending: (
      state,
      { payload: pending }: PayloadAction<boolean>
    ): OnBoardingStatus => ({
      ...state,
      pending,
    }),
    setBINStepPresence: (
      state,
      { payload: binStepPresence }: PayloadAction<boolean>
    ): OnBoardingStatus => ({
      ...state,
      binStepPresence,
    }),
  },
});

const requestFlow = createAction<OnboardingFlowAPIData>('requestFlow');
const commitFlow = createAction<OnboardingFlowAPIData>('commitFlow');
const requestCardFlow = createAction<CardFlowData>('requestCardFlow');
const commitCardFlow = createAction<CardFlowData>('commitCardFlow');

export const { reducer: onBoardingStatusReducer } = onBoardingSlice;

export const onBoardingStatusActions = {
  ...onBoardingSlice.actions,
  requestCardFlow,
  commitCardFlow,
  requestFlow,
  commitFlow
};
