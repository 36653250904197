export const profileErrors = {
  "Incorrect fiscal code": "incorrect-fiscal-code",
  "The fiscal code entered is not consistent with the data you entered, make sure it is correct and verify your name spelling": "fiscal-code-error-02",
  "Payment is canceled": "payment-expired",
  "Payment is failed": "payment-failed",
  "Order is expired": "payment-expired"
}

export const otpErrors = {
  "InvalidOtpError": "api-invalid-otp-error",
  "InvalidCardError": "api-acquiring-failure-card-issue",
  "ExpiredCardError": "api-acquiring-failure-card-issue",
  "CreditExceededError": "api-acquiring-failure-insufficient-funds",
  "AcquiringTechnicalFailure": "api-acquiring-technical-failure"
}

export const parseErrorPagoOTP = (err: any) => {
  let error = "";

  if (err.errorCode === "AcquirerProviderUserFailure" && err.data.errorCode === "InvalidCard") {
    error = "InvalidCardError";
  }

  if (err.errorCode === "AcquirerProviderUserFailure" && err.data.errorType === "ExpiredCard") {
    error = "ExpiredCardError";
  }

  if (err.errorCode === "AcquirerProviderUserFailure" && err.data.errorCode === "CreditExceeded") {
    error = "CreditExceededError";
  }

  if (err.errorCode === "AcquirerProviderTechnicalFailure") {
    error = "AcquiringTechnicalFailure";
  }

  if (err.message === "InvalidOtpError" || err.errorCode === "InvalidOtpError") {
    error = "InvalidOtpError";
  }

  if (err.message === "InternalError" || err.errorCode === "InternalServerError") {
    error = "AcquiringTechnicalFailure";
  }

  return otpErrors[error] ? `errors:${otpErrors[error]}` : `errors:${otpErrors["AcquiringTechnicalFailure"]}`;
}