import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { State } from 'legacy/store/state';
import { ParentProps, FormattedLoan } from 'legacy/ChangeCardPortal/types';
import Button from 'legacy/components/Button';
import BoxWithBackground from 'legacy/components/BoxWithBackground';
import FlexColumn from 'legacy/components/FlexColumn';
import FormattedMessage from 'legacy/components/FormattedMessage';
import Timeline from './components/Timeline';
import { BsCreditCard } from 'react-icons/bs';
import styled from 'styled-components';

const EnvelopIcon = styled('div')`
  display: inline;
  vertical-align: middle;
  font-size: 25px;
`;

const BrandWrapper = styled('div')`
  margin-right: auto;
`;

const NotificationWrapper = styled('div')`
  display: flex;
  background-color: #ffff99;
  padding-botton: 2px;
  margin-botton: 3px;
  border: 1px solid #ffff99;
  border-radius: 20px;
  width: 80%;
  margin: 10px;
  padding: 10px;
`;

const mapStateToProps = (state: State) => ({});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const PaymentPlanReminder: React.FC<PropsFromRedux & ParentProps> = (
  props: PropsFromRedux & ParentProps
) => {
  const { submitHandler, activeLoans, notificationDate } = props;

  return (
    <>
      <br />
      <FormattedMessage messageId="change-card:payment-plan-title" className="fs-18 bold mb-20" />
      <FormattedMessage messageId="change-card:payment-plan-description" className="fs-14 mb-20" />

      {activeLoans &&
        activeLoans.map((loan: FormattedLoan) => {
          return (
            <>
              <BrandWrapper>
                <img src={loan.merchantLogo} alt="logo" height="50px" width="90px" />
              </BrandWrapper>

              <BoxWithBackground>
                <FlexColumn>
                  <FormattedMessage
                    messageId="change-card:payment-plan-timeline-title"
                    params={{ date: loan.createdAt }}
                    className="fs-16"
                  >
                    <b>{loan.merchantName}</b>
                  </FormattedMessage>
                  <FormattedMessage
                    messageId="change-card:payment-plan-order-id"
                    params={{ orderId: loan.orderId }}
                    className="fs-16 mb-70 light"
                  />
                  <Timeline installments={loan.installments} />
                </FlexColumn>
              </BoxWithBackground>
            </>
          );
        })}

      <NotificationWrapper>
        <FormattedMessage className="fs-14 inline">
          <EnvelopIcon>
            <BsCreditCard />
          </EnvelopIcon>
        </FormattedMessage>
        <FormattedMessage
          className="fs-14 inline"
          messageId="change-card:payment-plan-notification"
        >
          <b>{notificationDate}</b>
        </FormattedMessage>
      </NotificationWrapper>

      <Button
        messageId="change-card:payment-plan-button"
        clickHandler={() => submitHandler()}
        isLoading={false}
      />

      <br />
    </>
  );
};

export default connector(PaymentPlanReminder);
