import CenterContainer from "components/CenterContainer";
import { observer } from "mobx-react-lite";
import React from "react";
import ui from "store/ui";
import { Container, Sidebar, StyledImg } from "./styles";

type SidebarImageType = {
    children: any[];
}

const SidebarImage: React.FC<SidebarImageType> = (props: SidebarImageType) => {
    const {
        children,
    } = props;

    if (!ui.styles.sidebarImage.image) {
        return <> {children} </>
    }

    return <>
        <Container>
            <Sidebar>
                <StyledImg src={ui.styles.sidebarImage.image} />
            </Sidebar>
            <CenterContainer>
                {children}
            </CenterContainer>
            <div /> {/* This div is needed to make the container fill the entire width of the page. */}
        </Container>
    </>;
};

export default observer(SidebarImage);