import styled from 'styled-components';

const FullScreenStyle = `
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
`;

export const VideoStream = styled('video')`
  ${FullScreenStyle}
`;

export const Cover = styled('div')<{ backgroundColor?: string }>`
  ${FullScreenStyle}
  ${props => props.backgroundColor && `background-color: ${props.backgroundColor};`}
`;

export const TopTextBox = styled('div')`
  top: 2vh;
  position: absolute;
  width: 100%;
`;

export const BottomTextBox = styled('div')`
  bottom: 2vh;
  position: absolute;
  width: 100%;
`;
