import React from 'react';
import Spinner from 'react-loader-spinner';
import styled from 'styled-components';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const LoaderWrapper = styled('div')`
  height: calc(100vh - 194px);
  background-color: #fff;
  z-index: 999;
`;

const Centered = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Loader = ({ centered = true }: { centered?: boolean }) => {
  if (centered) {
    return (
      <LoaderWrapper>
        <Centered>
          <TailSpinner />
        </Centered>
      </LoaderWrapper>
    );
  }

  return <TailSpinner />;
};

const TailSpinner = () => <Spinner type="TailSpin" color="#000" height={100} width={100} />;

export default Loader;
