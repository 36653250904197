import React from 'react';
import { AppComponentProps } from 'types/apps';
import { ParentProps } from '../types';

import PageContainer from 'apps/ChangeCard/components/PageContainer/DE';
import styled from 'styled-components';
import Text from 'components/Text';

const ConfirmationBox = styled('div')`
  margin-top: 80px;
  padding: 30px;
  width: 100%;
  max-width: 500px;
  min-height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  align-self: start;
  position: relative;
  background: #ffffff;
  border: 1px solid #ffffff;
  // box-shadow: 0px 0px 30px rgb(0 0 0 / 25%);
  border-radius: 5px;
`;

const TextBox = styled('div')`
  margin-bottom: 16px;
  flex-grow: 1;
  text-align: center;
  display: flex;
  align-items: center;
`;

const TextItem = styled(Text)`
  display: block;
  margin: 16px;
`;

const Complete: React.FC<AppComponentProps & ParentProps> = () => {
  return (
    <PageContainer buttonStyles={{ bottom: '18.5%', display: 'none' }}>
      <TextItem messageId="ChangeCardCompletionTitle" type="title" className="uppercase" />
      <ConfirmationBox>
        <TextBox>
          <TextItem messageId="ChangeCardCompletionText" type="subtitle" />
        </TextBox>
      </ConfirmationBox>
    </PageContainer>
  );
};

export default Complete;
