import React from 'react';
import styled from 'styled-components';
import { BsArrowRightShort as ArrowRightIcon } from 'react-icons/bs';
import Text from 'components/Text';

const CenteredFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: -5px 0;
  padding: 0 5px;
  align-items: center;

  & > * {
    color: #2a2a2a;
  }

  @media (max-width: 480px) {
    justify-content: center;
  }
`;

type InstallmentLineType = {
  date: string;
  amount: string;
};

const InstallmentLine: React.FC<InstallmentLineType> = (props: InstallmentLineType) => {
  const { date, amount } = props;

  return (
    <CenteredFlexRow>
      <Text type="content" content={date} /*className="fs-14"*/ />
      <ArrowRightIcon color="#da291c" size={24} />
      <Text type="content" content={amount} /*className="fs-14"*/ />
    </CenteredFlexRow>
  );
};

export default InstallmentLine;
