import { State } from 'legacy/store/state';
import { selectors, actions as UIActions } from 'legacy/store/UI';
import { connect } from 'react-redux';

const mapDispatchToProps = {
  getText: UIActions.getText,
  setTheme: UIActions.setTheme,
};

const mapStateToProps = (state: State) => ({
  theme: selectors.theme(state),
});

export default connect(mapStateToProps, mapDispatchToProps);
