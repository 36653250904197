import { CardsAPIHandler } from "./cards";
import { ConsumersAPIHandler } from "./consumers";
import { OrderAPIHandler } from "./order";
import { OTPAPIHandler } from "./otp";
import { EmailAPIHandler } from "./email";

export const OrderAPI = new OrderAPIHandler();
export const OTPAPI = new OTPAPIHandler();
export const ConsumersAPI = new ConsumersAPIHandler();
export const CardsAPI = new CardsAPIHandler();
export const EmailAPI = new EmailAPIHandler();
