export const cities = {
    "A001": "ABANO TERME",
    "A004": "ABBADIA CERRETO",
    "A005": "ABBADIA LARIANA",
    "A006": "ABBADIA SAN SALVATORE",
    "A007": "ABBASANTA",
    "A008": "ABBATEGGIO",
    "A010": "ABBIATEGRASSO",
    "A012": "ABETONE",
    "A013": "ABRIOLA",
    "A014": "ACATE",
    "A015": "ACCADIA",
    "A016": "ACCEGLIO",
    "A017": "ACCETTURA",
    "A018": "ACCIANO",
    "A019": "ACCUMOLI",
    "A020": "ACERENZA",
    "A023": "ACERNO",
    "A024": "ACERRA",
    "A025": "ACI BONACCORSI",
    "A026": "ACI CASTELLO",
    "A027": "ACI CATENA",
    "A029": "ACI SANT'ANTONIO",
    "A028": "ACIREALE",
    "A031": "ACQUACANINA",
    "A032": "ACQUAFONDATA",
    "A033": "ACQUAFORMOSA",
    "A034": "ACQUAFREDDA",
    "A035": "ACQUALAGNA",
    "A039": "ACQUANEGRA CREMONESE",
    "A038": "ACQUANEGRA SUL CHIESE",
    "A040": "ACQUAPENDENTE",
    "A041": "ACQUAPPESA",
    "A042": "ACQUARICA DEL CAPO",
    "A043": "ACQUARO",
    "A044": "ACQUASANTA TERME",
    "A045": "ACQUASPARTA",
    "A050": "ACQUAVIVA COLLECROCE",
    "A048": "ACQUAVIVA DELLE FONTI",
    "A051": "ACQUAVIVA D'ISERNIA",
    "A047": "ACQUAVIVA PICENA",
    "A049": "ACQUAVIVA PLATANI",
    "M211": "ACQUEDOLCI",
    "A052": "ACQUI TERME",
    "A053": "ACRI",
    "A054": "ACUTO",
    "A055": "ADELFIA",
    "A056": "ADRANO",
    "A057": "ADRARA SAN MARTINO",
    "A058": "ADRARA SAN ROCCO",
    "A059": "ADRIA",
    "A060": "ADRO",
    "A061": "AFFI",
    "A062": "AFFILE",
    "A064": "AFRAGOLA",
    "A065": "AFRICO",
    "A067": "AGAZZANO",
    "A068": "AGEROLA",
    "A069": "AGGIUS",
    "A070": "AGIRA",
    "A071": "AGLIANA",
    "A072": "AGLIANO TERME",
    "A074": "AGLIE'",
    "H848": "AGLIENTU",
    "A075": "AGNA",
    "A076": "AGNADELLO",
    "A077": "AGNANA CALABRA",
    "A080": "AGNONE",
    "A082": "AGNOSINE",
    "A083": "AGORDO",
    "A084": "AGOSTA",
    "A085": "AGRA",
    "A087": "AGRATE BRIANZA",
    "A088": "AGRATE CONTURBIA",
    "A089": "AGRIGENTO",
    "A091": "AGROPOLI",
    "A092": "AGUGLIANO",
    "A093": "AGUGLIARO",
    "A096": "AICURZIO",
    "A097": "AIDOMAGGIORE",
    "A098": "AIDONE",
    "A100": "AIELLI",
    "A102": "AIELLO CALABRO",
    "A103": "AIELLO DEL FRIULI",
    "A101": "AIELLO DEL SABATO",
    "A105": "AIETA",
    "A106": "AILANO",
    "A107": "AILOCHE",
    "A109": "AIRASCA",
    "A110": "AIROLA",
    "A111": "AIROLE",
    "A112": "AIRUNO",
    "A113": "AISONE",
    "A116": "ALA",
    "A115": "ALA' DEI SARDI",
    "A117": "ALA DI STURA",
    "A118": "ALAGNA",
    "A119": "ALAGNA VALSESIA",
    "A120": "ALANNO",
    "A121": "ALANO DI PIAVE",
    "A122": "ALASSIO",
    "A123": "ALATRI",
    "A124": "ALBA",
    "A125": "ALBA ADRIATICA",
    "A126": "ALBAGIARA",
    "A127": "ALBAIRATE",
    "A128": "ALBANELLA",
    "A131": "ALBANO DI LUCANIA",
    "A132": "ALBANO LAZIALE",
    "A129": "ALBANO SANT'ALESSANDRO",
    "A130": "ALBANO VERCELLESE",
    "A134": "ALBAREDO ARNABOLDI",
    "A137": "ALBAREDO D'ADIGE",
    "A135": "ALBAREDO PER SAN MARCO",
    "A138": "ALBARETO",
    "A139": "ALBARETTO DELLA TORRE",
    "A143": "ALBAVILLA",
    "A145": "ALBENGA",
    "A146": "ALBERA LIGURE",
    "A149": "ALBEROBELLO",
    "A150": "ALBERONA",
    "A153": "ALBESE CON CASSANO",
    "A154": "ALBETTONE",
    "A155": "ALBI",
    "A158": "ALBIANO",
    "A157": "ALBIANO D'IVREA",
    "A159": "ALBIATE",
    "A160": "ALBIDONA",
    "A161": "ALBIGNASEGO",
    "A162": "ALBINEA",
    "A163": "ALBINO",
    "A164": "ALBIOLO",
    "A166": "ALBISOLA SUPERIORE",
    "A165": "ALBISSOLA MARINA",
    "A167": "ALBIZZATE",
    "A171": "ALBONESE",
    "A172": "ALBOSAGGIA",
    "A173": "ALBUGNANO",
    "A175": "ALBUZZANO",
    "A176": "ALCAMO",
    "A177": "ALCARA LI FUSI",
    "A178": "ALDENO",
    "A179": "ALDINO .ALDEIN.",
    "A180": "ALES",
    "A182": "ALESSANDRIA",
    "A183": "ALESSANDRIA DEL CARRETTO",
    "A181": "ALESSANDRIA DELLA ROCCA",
    "A184": "ALESSANO",
    "A185": "ALEZIO",
    "A186": "ALFANO",
    "A187": "ALFEDENA",
    "A188": "ALFIANELLO",
    "A189": "ALFIANO NATTA",
    "A191": "ALFONSINE",
    "A192": "ALGHERO",
    "A193": "ALGUA",
    "A194": "ALI'",
    "A201": "ALI' TERME",
    "A195": "ALIA",
    "A196": "ALIANO",
    "A197": "ALICE BEL COLLE",
    "A198": "ALICE CASTELLO",
    "A199": "ALICE SUPERIORE",
    "A200": "ALIFE",
    "A202": "ALIMENA",
    "A203": "ALIMINUSA",
    "A204": "ALLAI",
    "A206": "ALLEGHE",
    "A205": "ALLEIN",
    "A207": "ALLERONA",
    "A208": "ALLISTE",
    "A210": "ALLUMIERE",
    "A211": "ALLUVIONI CAMBIO'",
    "A214": "ALME'",
    "A216": "ALMENNO SAN BARTOLOMEO",
    "A217": "ALMENNO SAN SALVATORE",
    "A218": "ALMESE",
    "A220": "ALONTE",
    "M375": "ALPAGO",
    "A221": "ALPETTE",
    "A222": "ALPIGNANO",
    "A223": "ALSENO",
    "A224": "ALSERIO",
    "A225": "ALTAMURA",
    "A226": "ALTARE",
    "M349": "ALTAVALLE",
    "A228": "ALTAVILLA IRPINA",
    "A229": "ALTAVILLA MILICIA",
    "A227": "ALTAVILLA MONFERRATO",
    "A230": "ALTAVILLA SILENTINA",
    "A231": "ALTAVILLA VICENTINA",
    "A233": "ALTIDONA",
    "A234": "ALTILIA",
    "A235": "ALTINO",
    "A236": "ALTISSIMO",
    "A237": "ALTIVOLE",
    "A238": "ALTO",
    "M369": "ALTO RENO TERME",
    "A239": "ALTOFONTE",
    "A240": "ALTOMONTE",
    "A241": "ALTOPASCIO",
    "M350": "ALTOPIANO DELLA VIGOLANA",
    "A242": "ALVIANO",
    "A243": "ALVIGNANO",
    "A244": "ALVITO",
    "A246": "ALZANO LOMBARDO",
    "A245": "ALZANO SCRIVIA",
    "A249": "ALZATE BRIANZA",
    "A251": "AMALFI",
    "A252": "AMANDOLA",
    "A253": "AMANTEA",
    "A254": "AMARO",
    "A255": "AMARONI",
    "A256": "AMASENO",
    "A257": "AMATO",
    "A258": "AMATRICE",
    "A259": "AMBIVERE",
    "A260": "AMBLAR",
    "M351": "AMBLAR-DON",
    "A261": "AMEGLIA",
    "A262": "AMELIA",
    "A263": "AMENDOLARA",
    "A264": "AMENO",
    "A265": "AMOROSI",
    "A267": "AMPEZZO",
    "A268": "ANACAPRI",
    "A269": "ANAGNI",
    "A270": "ANCARANO",
    "A271": "ANCONA",
    "A272": "ANDALI",
    "A274": "ANDALO",
    "A273": "ANDALO VALTELLINO",
    "A275": "ANDEZENO",
    "A278": "ANDORA",
    "A280": "ANDORNO MICCA",
    "A281": "ANDRANO",
    "A282": "ANDRATE",
    "A283": "ANDREIS",
    "A284": "ANDRETTA",
    "A285": "ANDRIA",
    "A286": "ANDRIANO .ANDRIAN.",
    "A287": "ANELA",
    "A288": "ANFO",
    "A290": "ANGERA",
    "A291": "ANGHIARI",
    "A292": "ANGIARI",
    "A293": "ANGOLO TERME",
    "A294": "ANGRI",
    "A295": "ANGROGNA",
    "A297": "ANGUILLARA SABAZIA",
    "A296": "ANGUILLARA VENETA",
    "A299": "ANNICCO",
    "A301": "ANNONE DI BRIANZA",
    "A302": "ANNONE VENETO",
    "A303": "ANOIA",
    "A304": "ANTEGNATE",
    "A306": "ANTERIVO .ALTREI.",
    "A305": "ANTEY-SAINT-ANDRE'",
    "A309": "ANTICOLI CORRADO",
    "A312": "ANTIGNANO",
    "A313": "ANTILLO",
    "A314": "ANTONIMINA",
    "A315": "ANTRODOCO",
    "A317": "ANTRONA SCHIERANCO",
    "A318": "ANVERSA DEGLI ABRUZZI",
    "A319": "ANZANO DEL PARCO",
    "A320": "ANZANO DI PUGLIA",
    "A321": "ANZI",
    "A323": "ANZIO",
    "A324": "ANZOLA DELL'EMILIA",
    "A325": "ANZOLA D'OSSOLA",
    "A326": "AOSTA",
    "A327": "APECCHIO",
    "A328": "APICE",
    "A329": "APIRO",
    "A330": "APOLLOSA",
    "A333": "APPIANO GENTILE",
    "A332": "APPIANO SULLA STRADA DEL VINO .EPPAN AN DER.",
    "A334": "APPIGNANO",
    "A335": "APPIGNANO DEL TRONTO",
    "A337": "APRICA",
    "A338": "APRICALE",
    "A339": "APRICENA",
    "A340": "APRIGLIANO",
    "A341": "APRILIA",
    "A343": "AQUARA",
    "A344": "AQUILA D'ARROSCIA",
    "A346": "AQUILEIA",
    "A347": "AQUILONIA",
    "A348": "AQUINO",
    "A350": "ARADEO",
    "A351": "ARAGONA",
    "A352": "ARAMENGO",
    "A354": "ARBA",
    "A357": "ARBOREA",
    "A358": "ARBORIO",
    "A359": "ARBUS",
    "A360": "ARCADE",
    "A363": "ARCE",
    "A365": "ARCENE",
    "A366": "ARCEVIA",
    "A367": "ARCHI",
    "A369": "ARCIDOSSO",
    "A370": "ARCINAZZO ROMANO",
    "A371": "ARCISATE",
    "A372": "ARCO",
    "A373": "ARCOLA",
    "A374": "ARCOLE",
    "A375": "ARCONATE",
    "A376": "ARCORE",
    "A377": "ARCUGNANO",
    "A379": "ARDARA",
    "A380": "ARDAULI",
    "M213": "ARDEA",
    "A382": "ARDENNO",
    "A383": "ARDESIO",
    "A385": "ARDORE",
    "A386": "ARENA",
    "A387": "ARENA PO",
    "A388": "ARENZANO",
    "A389": "ARESE",
    "A390": "AREZZO",
    "A391": "ARGEGNO",
    "A392": "ARGELATO",
    "A393": "ARGENTA",
    "A394": "ARGENTERA",
    "A396": "ARGUELLO",
    "A397": "ARGUSTO",
    "A398": "ARI",
    "A399": "ARIANO IRPINO",
    "A400": "ARIANO NEL POLESINE",
    "A401": "ARICCIA",
    "A402": "ARIELLI",
    "A403": "ARIENZO",
    "A405": "ARIGNANO",
    "A407": "ARITZO",
    "A409": "ARIZZANO",
    "A412": "ARLENA DI CASTRO",
    "A413": "ARLUNO",
    "A414": "ARMENO",
    "A415": "ARMENTO",
    "A418": "ARMO",
    "A419": "ARMUNGIA",
    "A424": "ARNAD",
    "A421": "ARNARA",
    "A422": "ARNASCO",
    "A425": "ARNESANO",
    "A427": "AROLA",
    "A429": "ARONA",
    "A430": "AROSIO",
    "A431": "ARPAIA",
    "A432": "ARPAISE",
    "A433": "ARPINO",
    "A434": "ARQUA' PETRARCA",
    "A435": "ARQUA' POLESINE",
    "A437": "ARQUATA DEL TRONTO",
    "A436": "ARQUATA SCRIVIA",
    "A438": "ARRE",
    "A439": "ARRONE",
    "A441": "ARSAGO SEPRIO",
    "A443": "ARSIE'",
    "A444": "ARSIERO",
    "A445": "ARSITA",
    "A446": "ARSOLI",
    "A447": "ARTA TERME",
    "A448": "ARTEGNA",
    "A449": "ARTENA",
    "A451": "ARTOGNE",
    "A452": "ARVIER",
    "A453": "ARZACHENA",
    "A440": "ARZAGO D'ADDA",
    "A454": "ARZANA",
    "A455": "ARZANO",
    "A456": "ARZENE",
    "A458": "ARZERGRANDE",
    "A459": "ARZIGNANO",
    "A460": "ASCEA",
    "A461": "ASCIANO",
    "A462": "ASCOLI PICENO",
    "A463": "ASCOLI SATRIANO",
    "A464": "ASCREA",
    "A465": "ASIAGO",
    "A467": "ASIGLIANO VENETO",
    "A466": "ASIGLIANO VERCELLESE",
    "A470": "ASOLA",
    "A471": "ASOLO",
    "A473": "ASSAGO",
    "A474": "ASSEMINI",
    "A475": "ASSISI",
    "A476": "ASSO",
    "A477": "ASSOLO",
    "A478": "ASSORO",
    "A479": "ASTI",
    "A480": "ASUNI",
    "A481": "ATELETA",
    "A482": "ATELLA",
    "A484": "ATENA LUCANA",
    "A485": "ATESSA",
    "A486": "ATINA",
    "A487": "ATRANI",
    "A488": "ATRI",
    "A489": "ATRIPALDA",
    "A490": "ATTIGLIANO",
    "A491": "ATTIMIS",
    "A492": "ATZARA",
    "A493": "AUDITORE",
    "A494": "AUGUSTA",
    "A495": "AULETTA",
    "A496": "AULLA",
    "A497": "AURANO",
    "A499": "AURIGO",
    "A501": "AURONZO DI CADORE",
    "A502": "AUSONIA",
    "A503": "AUSTIS",
    "A506": "AVEGNO",
    "A507": "AVELENGO .HAFLING.",
    "A508": "AVELLA",
    "A509": "AVELLINO",
    "A511": "AVERARA",
    "A512": "AVERSA",
    "A514": "AVETRANA",
    "A515": "AVEZZANO",
    "A516": "AVIANO",
    "A517": "AVIATICO",
    "A518": "AVIGLIANA",
    "A519": "AVIGLIANO",
    "M258": "AVIGLIANO UMBRO",
    "A520": "AVIO",
    "A521": "AVISE",
    "A522": "AVOLA",
    "A523": "AVOLASCA",
    "A094": "AYAS",
    "A108": "AYMAVILLES",
    "A525": "AZEGLIO",
    "A526": "AZZANELLO",
    "A527": "AZZANO D'ASTI",
    "A530": "AZZANO DECIMO",
    "A529": "AZZANO MELLA",
    "A528": "AZZANO SAN PAOLO",
    "A531": "AZZATE",
    "A532": "AZZIO",
    "A533": "AZZONE",
    "A534": "BACENO",
    "A535": "BACOLI",
    "A536": "BADALUCCO",
    "M214": "BADESI",
    "A537": "BADIA .ABTEI.",
    "A540": "BADIA CALAVENA",
    "A538": "BADIA PAVESE",
    "A539": "BADIA POLESINE",
    "A541": "BADIA TEDALDA",
    "A542": "BADOLATO",
    "A544": "BAGALADI",
    "A546": "BAGHERIA",
    "A547": "BAGNACAVALLO",
    "A552": "BAGNARA CALABRA",
    "A551": "BAGNARA DI ROMAGNA",
    "A550": "BAGNARIA",
    "A553": "BAGNARIA ARSA",
    "A555": "BAGNASCO",
    "A557": "BAGNATICA",
    "A560": "BAGNI DI LUCCA",
    "A564": "BAGNO A RIPOLI",
    "A565": "BAGNO DI ROMAGNA",
    "A567": "BAGNOLI DEL TRIGNO",
    "A568": "BAGNOLI DI SOPRA",
    "A566": "BAGNOLI IRPINO",
    "A570": "BAGNOLO CREMASCO",
    "A572": "BAGNOLO DEL SALENTO",
    "A574": "BAGNOLO DI PO",
    "A573": "BAGNOLO IN PIANO",
    "A569": "BAGNOLO MELLA",
    "A571": "BAGNOLO PIEMONTE",
    "A575": "BAGNOLO SAN VITO",
    "A576": "BAGNONE",
    "A577": "BAGNOREGIO",
    "A578": "BAGOLINO",
    "A579": "BAIA E LATINA",
    "A580": "BAIANO",
    "A581": "BAIARDO",
    "A584": "BAIRO",
    "A586": "BAISO",
    "A587": "BALANGERO",
    "A588": "BALDICHIERI D'ASTI",
    "A590": "BALDISSERO CANAVESE",
    "A589": "BALDISSERO D'ALBA",
    "A591": "BALDISSERO TORINESE",
    "A592": "BALESTRATE",
    "A593": "BALESTRINO",
    "A594": "BALLABIO",
    "A597": "BALLAO",
    "A599": "BALME",
    "A600": "BALMUCCIA",
    "A601": "BALOCCO",
    "A603": "BALSORANO",
    "A604": "BALVANO",
    "A605": "BALZOLA",
    "A606": "BANARI",
    "A607": "BANCHETTE",
    "A610": "BANNIO ANZINO",
    "A612": "BANZI",
    "A613": "BAONE",
    "A614": "BARADILI",
    "A615": "BARAGIANO",
    "A616": "BARANELLO",
    "A617": "BARANO D'ISCHIA",
    "A618": "BARANZATE",
    "A619": "BARASSO",
    "A621": "BARATILI SAN PIETRO",
    "A625": "BARBANIA",
    "A626": "BARBARA",
    "A628": "BARBARANO ROMANO",
    "A627": "BARBARANO VICENTINO",
    "A629": "BARBARESCO",
    "A630": "BARBARIGA",
    "A631": "BARBATA",
    "A632": "BARBERINO DI MUGELLO",
    "A633": "BARBERINO VAL D'ELSA",
    "A634": "BARBIANELLO",
    "A635": "BARBIANO .BARBIAN.",
    "A637": "BARBONA",
    "A638": "BARCELLONA POZZO DI GOTTO",
    "A639": "BARCHI",
    "A640": "BARCIS",
    "A643": "BARD",
    "A645": "BARDELLO",
    "A646": "BARDI",
    "A647": "BARDINETO",
    "A650": "BARDOLINO",
    "A651": "BARDONECCHIA",
    "A652": "BAREGGIO",
    "A653": "BARENGO",
    "A655": "BARESSA",
    "A656": "BARETE",
    "A657": "BARGA",
    "A658": "BARGAGLI",
    "A660": "BARGE",
    "A661": "BARGHE",
    "A662": "BARI",
    "A663": "BARI SARDO",
    "A664": "BARIANO",
    "A665": "BARICELLA",
    "A666": "BARILE",
    "A667": "BARISCIANO",
    "A668": "BARLASSINA",
    "A669": "BARLETTA",
    "A670": "BARNI",
    "A671": "BAROLO",
    "A673": "BARONE CANAVESE",
    "A674": "BARONISSI",
    "A676": "BARRAFRANCA",
    "A677": "BARRALI",
    "A678": "BARREA",
    "A681": "BARUMINI",
    "A683": "BARZAGO",
    "A684": "BARZANA",
    "A686": "BARZANO'",
    "A687": "BARZIO",
    "A689": "BASALUZZO",
    "A690": "BASCAPE'",
    "A691": "BASCHI",
    "A692": "BASCIANO",
    "A694": "BASELGA DI PINE'",
    "A696": "BASELICE",
    "A697": "BASIANO",
    "A698": "BASICO'",
    "A699": "BASIGLIO",
    "A700": "BASILIANO",
    "A702": "BASSANO BRESCIANO",
    "A703": "BASSANO DEL GRAPPA",
    "A706": "BASSANO IN TEVERINA",
    "A704": "BASSANO ROMANO",
    "A707": "BASSIANO",
    "A708": "BASSIGNANA",
    "A709": "BASTIA MONDOVI'",
    "A710": "BASTIA UMBRA",
    "A711": "BASTIDA DE' DOSSI",
    "A712": "BASTIDA PANCARANA",
    "A713": "BASTIGLIA",
    "A714": "BATTAGLIA TERME",
    "A716": "BATTIFOLLO",
    "A717": "BATTIPAGLIA",
    "A718": "BATTUDA",
    "A719": "BAUCINA",
    "A721": "BAULADU",
    "A722": "BAUNEI",
    "A725": "BAVENO",
    "A726": "BAZZANO",
    "A728": "BEDERO VALCUVIA",
    "A729": "BEDIZZOLE",
    "A730": "BEDOLLO",
    "A731": "BEDONIA",
    "A732": "BEDULITA",
    "A733": "BEE",
    "A734": "BEINASCO",
    "A735": "BEINETTE",
    "A736": "BELCASTRO",
    "A737": "BELFIORE",
    "A740": "BELFORTE ALL'ISAURO",
    "A739": "BELFORTE DEL CHIENTI",
    "A738": "BELFORTE MONFERRATO",
    "A741": "BELGIOIOSO",
    "A742": "BELGIRATE",
    "A743": "BELLA",
    "M335": "BELLAGIO",
    "A744": "BELLAGIO",
    "A745": "BELLANO",
    "A746": "BELLANTE",
    "A747": "BELLARIA-IGEA MARINA",
    "A749": "BELLEGRA",
    "A750": "BELLINO",
    "A751": "BELLINZAGO LOMBARDO",
    "A752": "BELLINZAGO NOVARESE",
    "M294": "BELLIZZI",
    "A755": "BELLONA",
    "A756": "BELLOSGUARDO",
    "A757": "BELLUNO",
    "A759": "BELLUSCO",
    "A762": "BELMONTE CALABRO",
    "A763": "BELMONTE CASTELLO",
    "A761": "BELMONTE DEL SANNIO",
    "A765": "BELMONTE IN SABINA",
    "A764": "BELMONTE MEZZAGNO",
    "A760": "BELMONTE PICENO",
    "A766": "BELPASSO",
    "A768": "BELSITO",
    "A772": "BELVEDERE DI SPINELLO",
    "A774": "BELVEDERE LANGHE",
    "A773": "BELVEDERE MARITTIMO",
    "A769": "BELVEDERE OSTRENSE",
    "A770": "BELVEGLIO",
    "A776": "BELVI'",
    "A777": "BEMA",
    "A778": "BENE LARIO",
    "A779": "BENE VAGIENNA",
    "A780": "BENESTARE",
    "A781": "BENETUTTI",
    "A782": "BENEVELLO",
    "A783": "BENEVENTO",
    "A784": "BENNA",
    "A785": "BENTIVOGLIO",
    "A786": "BERBENNO",
    "A787": "BERBENNO DI VALTELLINA",
    "A788": "BERCETO",
    "A789": "BERCHIDDA",
    "A791": "BEREGAZZO CON FIGLIARO",
    "A792": "BEREGUARDO",
    "A793": "BERGAMASCO",
    "A794": "BERGAMO",
    "A795": "BERGANTINO",
    "A796": "BERGEGGI",
    "A798": "BERGOLO",
    "A799": "BERLINGO",
    "A801": "BERNALDA",
    "A802": "BERNAREGGIO",
    "A804": "BERNATE TICINO",
    "A805": "BERNEZZO",
    "A806": "BERRA",
    "A808": "BERSONE",
    "A809": "BERTINORO",
    "A810": "BERTIOLO",
    "A811": "BERTONICO",
    "A812": "BERZANO DI SAN PIETRO",
    "A813": "BERZANO DI TORTONA",
    "A816": "BERZO DEMO",
    "A817": "BERZO INFERIORE",
    "A815": "BERZO SAN FERMO",
    "A818": "BESANA IN BRIANZA",
    "A819": "BESANO",
    "A820": "BESATE",
    "A821": "BESENELLO",
    "A823": "BESENZONE",
    "A825": "BESNATE",
    "A826": "BESOZZO",
    "A827": "BESSUDE",
    "A831": "BETTOLA",
    "A832": "BETTONA",
    "A834": "BEURA-CARDEZZA",
    "A835": "BEVAGNA",
    "A836": "BEVERINO",
    "A837": "BEVILACQUA",
    "A839": "BEZZECCA",
    "A841": "BIANCAVILLA",
    "A842": "BIANCHI",
    "A843": "BIANCO",
    "A844": "BIANDRATE",
    "A845": "BIANDRONNO",
    "A846": "BIANZANO",
    "A847": "BIANZE'",
    "A848": "BIANZONE",
    "A849": "BIASSONO",
    "A850": "BIBBIANO",
    "A851": "BIBBIENA",
    "A852": "BIBBONA",
    "A853": "BIBIANA",
    "A854": "BICCARI",
    "A855": "BICINICCO",
    "A856": "BIDONI'",
    "A859": "BIELLA",
    "A861": "BIENNO",
    "A863": "BIENO",
    "A864": "BIENTINA",
    "A866": "BIGARELLO",
    "A870": "BINAGO",
    "A872": "BINASCO",
    "A874": "BINETTO",
    "A876": "BIOGLIO",
    "A877": "BIONAZ",
    "A878": "BIONE",
    "A880": "BIRORI",
    "A881": "BISACCIA",
    "A882": "BISACQUINO",
    "A883": "BISCEGLIE",
    "A884": "BISEGNA",
    "A885": "BISENTI",
    "A887": "BISIGNANO",
    "A889": "BISTAGNO",
    "A891": "BISUSCHIO",
    "A892": "BITETTO",
    "A893": "BITONTO",
    "A894": "BITRITTO",
    "A895": "BITTI",
    "A896": "BIVONA",
    "A897": "BIVONGI",
    "A898": "BIZZARONE",
    "A901": "BLEGGIO INFERIORE",
    "A902": "BLEGGIO SUPERIORE",
    "A903": "BLELLO",
    "A857": "BLERA",
    "A904": "BLESSAGNO",
    "A905": "BLEVIO",
    "M268": "BLUFI",
    "A906": "BOARA PISANI",
    "A909": "BOBBIO",
    "A910": "BOBBIO PELLICE",
    "A911": "BOCA",
    "A912": "BOCCHIGLIERO",
    "A914": "BOCCIOLETO",
    "A916": "BOCENAGO",
    "A918": "BODIO LOMNAGO",
    "A919": "BOFFALORA D'ADDA",
    "A920": "BOFFALORA SOPRA TICINO",
    "A922": "BOGLIASCO",
    "A925": "BOGNANCO",
    "A929": "BOGOGNO",
    "A931": "BOISSANO",
    "A930": "BOJANO",
    "A932": "BOLANO",
    "A933": "BOLBENO",
    "A937": "BOLGARE",
    "A940": "BOLLATE",
    "A941": "BOLLENGO",
    "A944": "BOLOGNA",
    "A945": "BOLOGNANO",
    "A946": "BOLOGNETTA",
    "A947": "BOLOGNOLA",
    "A948": "BOLOTANA",
    "A949": "BOLSENA",
    "A950": "BOLTIERE",
    "A952": "BOLZANO .BOZEN.",
    "A953": "BOLZANO NOVARESE",
    "A954": "BOLZANO VICENTINO",
    "A955": "BOMARZO",
    "A956": "BOMBA",
    "A957": "BOMPENSIERE",
    "A958": "BOMPIETRO",
    "A959": "BOMPORTO",
    "A960": "BONARCADO",
    "A961": "BONASSOLA",
    "A963": "BONATE SOPRA",
    "A962": "BONATE SOTTO",
    "A964": "BONAVIGO",
    "A965": "BONDENO",
    "A967": "BONDO",
    "A968": "BONDONE",
    "A970": "BONEA",
    "A971": "BONEFRO",
    "A972": "BONEMERSE",
    "A973": "BONIFATI",
    "A975": "BONITO",
    "A976": "BONNANARO",
    "A977": "BONO",
    "A978": "BONORVA",
    "A979": "BONVICINO",
    "A981": "BORBONA",
    "A982": "BORCA DI CADORE",
    "A983": "BORDANO",
    "A984": "BORDIGHERA",
    "A986": "BORDOLANO",
    "A987": "BORE",
    "A988": "BORETTO",
    "A989": "BORGARELLO",
    "A990": "BORGARO TORINESE",
    "A991": "BORGETTO",
    "A993": "BORGHETTO D'ARROSCIA",
    "A998": "BORGHETTO DI BORBERA",
    "A992": "BORGHETTO DI VARA",
    "A995": "BORGHETTO LODIGIANO",
    "A999": "BORGHETTO SANTO SPIRITO",
    "B001": "BORGHI",
    "B002": "BORGIA",
    "B003": "BORGIALLO",
    "B005": "BORGIO VEREZZI",
    "B007": "BORGO A MOZZANO",
    "M352": "BORGO CHIESE",
    "B009": "BORGO D'ALE",
    "B010": "BORGO DI TERZO",
    "M353": "BORGO LARES",
    "B026": "BORGO PACE",
    "B028": "BORGO PRIOLO",
    "B033": "BORGO SAN DALMAZZO",
    "B035": "BORGO SAN GIACOMO",
    "B017": "BORGO SAN GIOVANNI",
    "B036": "BORGO SAN LORENZO",
    "B037": "BORGO SAN MARTINO",
    "B038": "BORGO SAN SIRO",
    "B043": "BORGO TICINO",
    "B044": "BORGO TOSSIGNANO",
    "B042": "BORGO VAL DI TARO",
    "B006": "BORGO VALSUGANA",
    "A996": "BORGO VELINO",
    "B046": "BORGO VERCELLI",
    "M340": "BORGO VIRGILIO",
    "B011": "BORGOFORTE",
    "B015": "BORGOFRANCO D'IVREA",
    "B013": "BORGOFRANCO SUL PO",
    "B016": "BORGOLAVEZZARO",
    "B018": "BORGOMALE",
    "B019": "BORGOMANERO",
    "B020": "BORGOMARO",
    "B021": "BORGOMASINO",
    "M370": "BORGOMEZZAVALLE",
    "B024": "BORGONE SUSA",
    "B025": "BORGONOVO VAL TIDONE",
    "B029": "BORGORATTO ALESSANDRINO",
    "B030": "BORGORATTO MORMOROLO",
    "B031": "BORGORICCO",
    "B008": "BORGOROSE",
    "B040": "BORGOSATOLLO",
    "B041": "BORGOSESIA",
    "B048": "BORMIDA",
    "B049": "BORMIO",
    "B051": "BORNASCO",
    "B054": "BORNO",
    "B055": "BORONEDDU",
    "B056": "BORORE",
    "B057": "BORRELLO",
    "B058": "BORRIANA",
    "B061": "BORSO DEL GRAPPA",
    "B062": "BORTIGALI",
    "B063": "BORTIGIADAS",
    "B064": "BORUTTA",
    "B067": "BORZONASCA",
    "B068": "BOSA",
    "B069": "BOSARO",
    "B070": "BOSCHI SANT'ANNA",
    "B073": "BOSCO CHIESANUOVA",
    "B071": "BOSCO MARENGO",
    "B075": "BOSCONERO",
    "B076": "BOSCOREALE",
    "B077": "BOSCOTRECASE",
    "B078": "BOSENTINO",
    "B079": "BOSIA",
    "B080": "BOSIO",
    "B081": "BOSISIO PARINI",
    "B082": "BOSNASCO",
    "B083": "BOSSICO",
    "B084": "BOSSOLASCO",
    "B085": "BOTRICELLO",
    "B086": "BOTRUGNO",
    "B088": "BOTTANUCO",
    "B091": "BOTTICINO",
    "B094": "BOTTIDDA",
    "B097": "BOVA",
    "B099": "BOVA MARINA",
    "B098": "BOVALINO",
    "B100": "BOVEGNO",
    "B101": "BOVES",
    "B102": "BOVEZZO",
    "A720": "BOVILLE ERNICA",
    "B104": "BOVINO",
    "B105": "BOVISIO-MASCIAGO",
    "B106": "BOVOLENTA",
    "B107": "BOVOLONE",
    "B109": "BOZZOLE",
    "B110": "BOZZOLO",
    "B111": "BRA",
    "B112": "BRACCA",
    "B114": "BRACCIANO",
    "B115": "BRACIGLIANO",
    "B116": "BRAIES .PRAGS.",
    "B117": "BRALLO DI PREGOLA",
    "B118": "BRANCALEONE",
    "B120": "BRANDICO",
    "B121": "BRANDIZZO",
    "B123": "BRANZI",
    "B124": "BRAONE",
    "B126": "BREBBIA",
    "B128": "BREDA DI PIAVE",
    "B131": "BREGANO",
    "B132": "BREGANZE",
    "B134": "BREGNANO",
    "B135": "BREGUZZO",
    "B136": "BREIA",
    "B137": "BREMBATE",
    "B138": "BREMBATE DI SOPRA",
    "B140": "BREMBILLA",
    "B141": "BREMBIO",
    "B142": "BREME",
    "B143": "BRENDOLA",
    "B144": "BRENNA",
    "B145": "BRENNERO .BRENNER.",
    "B149": "BRENO",
    "B150": "BRENTA",
    "B152": "BRENTINO BELLUNO",
    "B153": "BRENTONICO",
    "B154": "BRENZONE",
    "B156": "BRESCELLO",
    "B157": "BRESCIA",
    "B158": "BRESIMO",
    "B159": "BRESSANA BOTTARONE",
    "B160": "BRESSANONE .BRIXEN.",
    "B161": "BRESSANVIDO",
    "B162": "BRESSO",
    "B165": "BREZ",
    "B166": "BREZZO DI BEDERO",
    "B167": "BRIAGLIA",
    "B169": "BRIATICO",
    "B171": "BRICHERASIO",
    "B172": "BRIENNO",
    "B173": "BRIENZA",
    "B175": "BRIGA ALTA",
    "B176": "BRIGA NOVARESE",
    "B178": "BRIGNANO GERA D'ADDA",
    "B179": "BRIGNANO-FRASCATA",
    "B180": "BRINDISI",
    "B181": "BRINDISI MONTAGNA",
    "B182": "BRINZIO",
    "B183": "BRIONA",
    "B184": "BRIONE",
    "B185": "BRIONE",
    "B187": "BRIOSCO",
    "B188": "BRISIGHELLA",
    "B191": "BRISSAGO-VALTRAVAGLIA",
    "B192": "BRISSOGNE",
    "B193": "BRITTOLI",
    "B194": "BRIVIO",
    "B195": "BROCCOSTELLA",
    "B196": "BROGLIANO",
    "B197": "BROGNATURO",
    "B198": "BROLO",
    "B200": "BRONDELLO",
    "B201": "BRONI",
    "B202": "BRONTE",
    "B203": "BRONZOLO .BRANZOLL.",
    "B204": "BROSSASCO",
    "B205": "BROSSO",
    "B207": "BROVELLO-CARPUGNINO",
    "B209": "BROZOLO",
    "B212": "BRUGHERIO",
    "B213": "BRUGINE",
    "B214": "BRUGNATO",
    "B215": "BRUGNERA",
    "B216": "BRUINO",
    "B217": "BRUMANO",
    "B218": "BRUNATE",
    "B219": "BRUNELLO",
    "B220": "BRUNICO .BRUNECK.",
    "B221": "BRUNO",
    "B223": "BRUSAPORTO",
    "B225": "BRUSASCO",
    "B227": "BRUSCIANO",
    "B228": "BRUSIMPIANO",
    "B229": "BRUSNENGO",
    "B230": "BRUSSON",
    "B232": "BRUZOLO",
    "B234": "BRUZZANO ZEFFIRIO",
    "B235": "BUBBIANO",
    "B236": "BUBBIO",
    "B237": "BUCCHERI",
    "B238": "BUCCHIANICO",
    "B239": "BUCCIANO",
    "B240": "BUCCINASCO",
    "B242": "BUCCINO",
    "B243": "BUCINE",
    "B246": "BUDDUSO'",
    "B247": "BUDOIA",
    "B248": "BUDONI",
    "B249": "BUDRIO",
    "B250": "BUGGERRU",
    "B251": "BUGGIANO",
    "B255": "BUGLIO IN MONTE",
    "B256": "BUGNARA",
    "B258": "BUGUGGIATE",
    "B259": "BUJA",
    "B261": "BULCIAGO",
    "B262": "BULGAROGRASSO",
    "B264": "BULTEI",
    "B265": "BULZI",
    "B266": "BUONABITACOLO",
    "B267": "BUONALBERGO",
    "B269": "BUONCONVENTO",
    "B270": "BUONVICINO",
    "B272": "BURAGO DI MOLGORA",
    "B274": "BURCEI",
    "B275": "BURGIO",
    "B276": "BURGOS",
    "B278": "BURIASCO",
    "B279": "BUROLO",
    "B280": "BURONZO",
    "B281": "BUSACHI",
    "B282": "BUSALLA",
    "B283": "BUSANA",
    "B284": "BUSANO",
    "B285": "BUSCA",
    "B286": "BUSCATE",
    "B287": "BUSCEMI",
    "B288": "BUSETO PALIZZOLO",
    "B289": "BUSNAGO",
    "B292": "BUSSERO",
    "B293": "BUSSETO",
    "B294": "BUSSI SUL TIRINO",
    "B295": "BUSSO",
    "B296": "BUSSOLENGO",
    "B297": "BUSSOLENO",
    "B300": "BUSTO ARSIZIO",
    "B301": "BUSTO GAROLFO",
    "B302": "BUTERA",
    "B303": "BUTI",
    "B304": "BUTTAPIETRA",
    "B305": "BUTTIGLIERA ALTA",
    "B306": "BUTTIGLIERA D'ASTI",
    "B309": "BUTTRIO",
    "B320": "CA' D'ANDREA",
    "B311": "CABELLA LIGURE",
    "B313": "CABIATE",
    "B314": "CABRAS",
    "B315": "CACCAMO",
    "B319": "CACCURI",
    "B326": "CADEGLIANO-VICONAGO",
    "B328": "CADELBOSCO DI SOPRA",
    "B332": "CADEO",
    "B335": "CADERZONE TERME",
    "B345": "CADONEGHE",
    "B346": "CADORAGO",
    "B347": "CADREZZATE",
    "B349": "CAERANO DI SAN MARCO",
    "B350": "CAFASSE",
    "B351": "CAGGIANO",
    "B352": "CAGLI",
    "B354": "CAGLIARI",
    "B355": "CAGLIO",
    "B358": "CAGNANO AMITERNO",
    "B357": "CAGNANO VARANO",
    "B359": "CAGNO",
    "B360": "CAGNO'",
    "B361": "CAIANELLO",
    "B362": "CAIAZZO",
    "B364": "CAINES .KUENS.",
    "B365": "CAINO",
    "B366": "CAIOLO",
    "B367": "CAIRANO",
    "B368": "CAIRATE",
    "B369": "CAIRO MONTENOTTE",
    "B371": "CAIVANO",
    "B374": "CALABRITTO",
    "B375": "CALALZO DI CADORE",
    "B376": "CALAMANDRANA",
    "B377": "CALAMONACI",
    "B378": "CALANGIANUS",
    "B379": "CALANNA",
    "B380": "CALASCA-CASTIGLIONE",
    "B381": "CALASCIBETTA",
    "B382": "CALASCIO",
    "B383": "CALASETTA",
    "B384": "CALATABIANO",
    "B385": "CALATAFIMI SEGESTA",
    "B386": "CALAVINO",
    "B388": "CALCATA",
    "B389": "CALCERANICA AL LAGO",
    "B390": "CALCI",
    "B391": "CALCIANO",
    "B392": "CALCINAIA",
    "B393": "CALCINATE",
    "B394": "CALCINATO",
    "B395": "CALCIO",
    "B396": "CALCO",
    "B397": "CALDARO SULLA STRADA DEL VINO .KALTERN AN DE.",
    "B398": "CALDAROLA",
    "B399": "CALDERARA DI RENO",
    "B400": "CALDES",
    "B402": "CALDIERO",
    "B403": "CALDOGNO",
    "B404": "CALDONAZZO",
    "B405": "CALENDASCO",
    "B406": "CALENZANO",
    "B408": "CALESTANO",
    "B410": "CALICE AL CORNOVIGLIO",
    "B409": "CALICE LIGURE",
    "B413": "CALIMERA",
    "B415": "CALITRI",
    "B416": "CALIZZANO",
    "B417": "CALLABIANA",
    "B418": "CALLIANO",
    "B419": "CALLIANO",
    "B423": "CALOLZIOCORTE",
    "B424": "CALOPEZZATI",
    "B425": "CALOSSO",
    "B426": "CALOVETO",
    "B427": "CALTABELLOTTA",
    "B428": "CALTAGIRONE",
    "B429": "CALTANISSETTA",
    "B430": "CALTAVUTURO",
    "B431": "CALTIGNAGA",
    "B432": "CALTO",
    "B433": "CALTRANO",
    "B434": "CALUSCO D'ADDA",
    "B435": "CALUSO",
    "B436": "CALVAGESE DELLA RIVIERA",
    "B437": "CALVANICO",
    "B439": "CALVATONE",
    "B440": "CALVELLO",
    "B441": "CALVENE",
    "B442": "CALVENZANO",
    "B443": "CALVERA",
    "B444": "CALVI",
    "B446": "CALVI DELL'UMBRIA",
    "B445": "CALVI RISORTA",
    "B447": "CALVIGNANO",
    "B448": "CALVIGNASCO",
    "B450": "CALVISANO",
    "B452": "CALVIZZANO",
    "B453": "CAMAGNA MONFERRATO",
    "B455": "CAMAIORE",
    "B456": "CAMAIRAGO",
    "B457": "CAMANDONA",
    "B460": "CAMASTRA",
    "B461": "CAMBIAGO",
    "B462": "CAMBIANO",
    "B463": "CAMBIASCA",
    "B465": "CAMBURZANO",
    "B467": "CAMERANA",
    "B468": "CAMERANO",
    "B469": "CAMERANO CASASCO",
    "B471": "CAMERATA CORNELLO",
    "B472": "CAMERATA NUOVA",
    "B470": "CAMERATA PICENA",
    "B473": "CAMERI",
    "B474": "CAMERINO",
    "B476": "CAMEROTA",
    "B477": "CAMIGLIANO",
    "B479": "CAMINATA",
    "B481": "CAMINI",
    "B482": "CAMINO",
    "B483": "CAMINO AL TAGLIAMENTO",
    "B484": "CAMISANO",
    "B485": "CAMISANO VICENTINO",
    "B486": "CAMMARATA",
    "B489": "CAMO",
    "B490": "CAMOGLI",
    "B492": "CAMPAGNA",
    "B493": "CAMPAGNA LUPIA",
    "B496": "CAMPAGNANO DI ROMA",
    "B497": "CAMPAGNATICO",
    "B498": "CAMPAGNOLA CREMASCA",
    "B499": "CAMPAGNOLA EMILIA",
    "B500": "CAMPANA",
    "B501": "CAMPARADA",
    "B502": "CAMPEGINE",
    "B504": "CAMPELLO SUL CLITUNNO",
    "B505": "CAMPERTOGNO",
    "B507": "CAMPI BISENZIO",
    "B506": "CAMPI SALENTINA",
    "M373": "CAMPIGLIA CERVO",
    "B508": "CAMPIGLIA CERVO",
    "B511": "CAMPIGLIA DEI BERICI",
    "B509": "CAMPIGLIA MARITTIMA",
    "B512": "CAMPIGLIONE FENILE",
    "B513": "CAMPIONE D'ITALIA",
    "B514": "CAMPITELLO DI FASSA",
    "B515": "CAMPLI",
    "B516": "CAMPO CALABRO",
    "B526": "CAMPO DI GIOVE",
    "B529": "CAMPO DI TRENS .FREIENFELD.",
    "B538": "CAMPO LIGURE",
    "B553": "CAMPO NELL'ELBA",
    "B564": "CAMPO SAN MARTINO",
    "B570": "CAMPO TURES .SAND IN TAUFERS.",
    "B519": "CAMPOBASSO",
    "B520": "CAMPOBELLO DI LICATA",
    "B521": "CAMPOBELLO DI MAZARA",
    "B522": "CAMPOCHIARO",
    "B524": "CAMPODARSEGO",
    "B525": "CAMPODENNO",
    "B527": "CAMPODIMELE",
    "B528": "CAMPODIPIETRA",
    "B530": "CAMPODOLCINO",
    "B531": "CAMPODORO",
    "B533": "CAMPOFELICE DI FITALIA",
    "B532": "CAMPOFELICE DI ROCCELLA",
    "B534": "CAMPOFILONE",
    "B535": "CAMPOFIORITO",
    "B536": "CAMPOFORMIDO",
    "B537": "CAMPOFRANCO",
    "B539": "CAMPOGALLIANO",
    "B541": "CAMPOLATTARO",
    "B543": "CAMPOLI APPENNINO",
    "B542": "CAMPOLI DEL MONTE TABURNO",
    "B544": "CAMPOLIETO",
    "B545": "CAMPOLONGO AL TORRE",
    "B546": "CAMPOLONGO MAGGIORE",
    "B547": "CAMPOLONGO SUL BRENTA",
    "M311": "CAMPOLONGO TAPOGLIANO",
    "B549": "CAMPOMAGGIORE",
    "B550": "CAMPOMARINO",
    "B551": "CAMPOMORONE",
    "B554": "CAMPONOGARA",
    "B555": "CAMPORA",
    "B556": "CAMPOREALE",
    "B557": "CAMPORGIANO",
    "B559": "CAMPOROSSO",
    "B562": "CAMPOROTONDO DI FIASTRONE",
    "B561": "CAMPOROTONDO ETNEO",
    "B563": "CAMPOSAMPIERO",
    "B565": "CAMPOSANO",
    "B566": "CAMPOSANTO",
    "B567": "CAMPOSPINOSO",
    "B569": "CAMPOTOSTO",
    "B572": "CAMUGNANO",
    "B577": "CANAL SAN BOVO",
    "B573": "CANALE",
    "B574": "CANALE D'AGORDO",
    "B576": "CANALE MONTERANO",
    "B578": "CANARO",
    "B579": "CANAZEI",
    "B580": "CANCELLARA",
    "B581": "CANCELLO ED ARNONE",
    "B582": "CANDA",
    "B584": "CANDELA",
    "B586": "CANDELO",
    "B588": "CANDIA CANAVESE",
    "B587": "CANDIA LOMELLINA",
    "B589": "CANDIANA",
    "B590": "CANDIDA",
    "B591": "CANDIDONI",
    "B592": "CANDIOLO",
    "B593": "CANEGRATE",
    "B594": "CANELLI",
    "B597": "CANEPINA",
    "B598": "CANEVA",
    "B599": "CANEVINO",
    "B602": "CANICATTI'",
    "B603": "CANICATTINI BAGNI",
    "B604": "CANINO",
    "B605": "CANISCHIO",
    "B606": "CANISTRO",
    "B607": "CANNA",
    "B608": "CANNALONGA",
    "B609": "CANNARA",
    "B610": "CANNERO RIVIERA",
    "B613": "CANNETO PAVESE",
    "B612": "CANNETO SULL'OGLIO",
    "B615": "CANNOBIO",
    "B616": "CANNOLE",
    "B617": "CANOLO",
    "B618": "CANONICA D'ADDA",
    "B619": "CANOSA DI PUGLIA",
    "B620": "CANOSA SANNITA",
    "B621": "CANOSIO",
    "C669": "CANOSSA",
    "B624": "CANSANO",
    "B626": "CANTAGALLO",
    "B627": "CANTALICE",
    "B628": "CANTALUPA",
    "B631": "CANTALUPO IN SABINA",
    "B629": "CANTALUPO LIGURE",
    "B630": "CANTALUPO NEL SANNIO",
    "B633": "CANTARANA",
    "B634": "CANTELLO",
    "B635": "CANTERANO",
    "B636": "CANTIANO",
    "B637": "CANTOIRA",
    "B639": "CANTU'",
    "B640": "CANZANO",
    "B641": "CANZO",
    "B642": "CAORLE",
    "B643": "CAORSO",
    "B644": "CAPACCIO",
    "B645": "CAPACI",
    "B646": "CAPALBIO",
    "B647": "CAPANNOLI",
    "B648": "CAPANNORI",
    "B649": "CAPENA",
    "B650": "CAPERGNANICA",
    "B651": "CAPESTRANO",
    "B653": "CAPIAGO INTIMIANO",
    "B655": "CAPISTRANO",
    "B656": "CAPISTRELLO",
    "B658": "CAPITIGNANO",
    "B660": "CAPIZZI",
    "B661": "CAPIZZONE",
    "B664": "CAPO DI PONTE",
    "B666": "CAPO D'ORLANDO",
    "B663": "CAPODIMONTE",
    "B667": "CAPODRISE",
    "B669": "CAPOLIVERI",
    "B670": "CAPOLONA",
    "B671": "CAPONAGO",
    "B672": "CAPORCIANO",
    "B674": "CAPOSELE",
    "B675": "CAPOTERRA",
    "B676": "CAPOVALLE",
    "B677": "CAPPADOCIA",
    "B679": "CAPPELLA CANTONE",
    "B680": "CAPPELLA DE' PICENARDI",
    "B678": "CAPPELLA MAGGIORE",
    "B681": "CAPPELLE SUL TAVO",
    "B682": "CAPRACOTTA",
    "B684": "CAPRAIA E LIMITE",
    "B685": "CAPRAIA ISOLA",
    "B686": "CAPRALBA",
    "B688": "CAPRANICA",
    "B687": "CAPRANICA PRENESTINA",
    "B690": "CAPRARICA DI LECCE",
    "B691": "CAPRAROLA",
    "B692": "CAPRAUNA",
    "B693": "CAPRESE MICHELANGELO",
    "B694": "CAPREZZO",
    "B696": "CAPRI",
    "B695": "CAPRI LEONE",
    "B697": "CAPRIANA",
    "B698": "CAPRIANO DEL COLLE",
    "B701": "CAPRIATA D'ORBA",
    "B703": "CAPRIATE SAN GERVASIO",
    "B704": "CAPRIATI A VOLTURNO",
    "B705": "CAPRIE",
    "B706": "CAPRIGLIA IRPINA",
    "B707": "CAPRIGLIO",
    "B708": "CAPRILE",
    "B710": "CAPRINO BERGAMASCO",
    "B709": "CAPRINO VERONESE",
    "B711": "CAPRIOLO",
    "B712": "CAPRIVA DEL FRIULI",
    "B715": "CAPUA",
    "B716": "CAPURSO",
    "B718": "CARAFFA DEL BIANCO",
    "B717": "CARAFFA DI CATANZARO",
    "B719": "CARAGLIO",
    "B720": "CARAMAGNA PIEMONTE",
    "B722": "CARAMANICO TERME",
    "B723": "CARANO",
    "B724": "CARAPELLE",
    "B725": "CARAPELLE CALVISIO",
    "B726": "CARASCO",
    "B727": "CARASSAI",
    "B729": "CARATE BRIANZA",
    "B730": "CARATE URIO",
    "B731": "CARAVAGGIO",
    "B732": "CARAVATE",
    "B733": "CARAVINO",
    "B734": "CARAVONICA",
    "B735": "CARBOGNANO",
    "B741": "CARBONARA AL TICINO",
    "B740": "CARBONARA DI NOLA",
    "B739": "CARBONARA DI PO",
    "B736": "CARBONARA SCRIVIA",
    "B742": "CARBONATE",
    "B743": "CARBONE",
    "B744": "CARBONERA",
    "B745": "CARBONIA",
    "B748": "CARCARE",
    "B749": "CARCERI",
    "B752": "CARCOFORO",
    "B754": "CARDANO AL CAMPO",
    "B755": "CARDE'",
    "M285": "CARDEDU",
    "B756": "CARDETO",
    "B758": "CARDINALE",
    "B759": "CARDITO",
    "B760": "CAREGGINE",
    "B762": "CAREMA",
    "B763": "CARENNO",
    "B765": "CARENTINO",
    "B766": "CARERI",
    "B767": "CARESANA",
    "B768": "CARESANABLOT",
    "B769": "CAREZZANO",
    "B771": "CARFIZZI",
    "B772": "CARGEGHE",
    "B774": "CARIATI",
    "B776": "CARIFE",
    "B777": "CARIGNANO",
    "B778": "CARIMATE",
    "B779": "CARINARO",
    "B780": "CARINI",
    "B781": "CARINOLA",
    "B782": "CARISIO",
    "B783": "CARISOLO",
    "B784": "CARLANTINO",
    "B785": "CARLAZZO",
    "B787": "CARLENTINI",
    "B788": "CARLINO",
    "B789": "CARLOFORTE",
    "B790": "CARLOPOLI",
    "B791": "CARMAGNOLA",
    "B792": "CARMIANO",
    "B794": "CARMIGNANO",
    "B795": "CARMIGNANO DI BRENTA",
    "B796": "CARNAGO",
    "B798": "CARNATE",
    "B801": "CAROBBIO DEGLI ANGELI",
    "B802": "CAROLEI",
    "B803": "CARONA",
    "B804": "CARONIA",
    "B805": "CARONNO PERTUSELLA",
    "B807": "CARONNO VARESINO",
    "B808": "CAROSINO",
    "B809": "CAROVIGNO",
    "B810": "CAROVILLI",
    "B812": "CARPANETO PIACENTINO",
    "B813": "CARPANZANO",
    "B814": "CARPASIO",
    "B816": "CARPEGNA",
    "B817": "CARPENEDOLO",
    "B818": "CARPENETO",
    "B819": "CARPI",
    "B820": "CARPIANO",
    "B822": "CARPIGNANO SALENTINO",
    "B823": "CARPIGNANO SESIA",
    "B825": "CARPINETI",
    "B827": "CARPINETO DELLA NORA",
    "B828": "CARPINETO ROMANO",
    "B826": "CARPINETO SINELLO",
    "B829": "CARPINO",
    "B830": "CARPINONE",
    "B832": "CARRARA",
    "B835": "CARRE'",
    "B836": "CARREGA LIGURE",
    "B838": "CARRO",
    "B839": "CARRODANO",
    "B840": "CARROSIO",
    "B841": "CARRU'",
    "B842": "CARSOLI",
    "B844": "CARTIGLIANO",
    "B845": "CARTIGNANO",
    "B846": "CARTOCETO",
    "B847": "CARTOSIO",
    "B848": "CARTURA",
    "B850": "CARUGATE",
    "B851": "CARUGO",
    "B853": "CARUNCHIO",
    "B854": "CARVICO",
    "B856": "CARZANO",
    "B857": "CASABONA",
    "B858": "CASACALENDA",
    "B859": "CASACANDITELLA",
    "B860": "CASAGIOVE",
    "B870": "CASAL CERMELLI",
    "B872": "CASAL DI PRINCIPE",
    "B895": "CASAL VELINO",
    "B861": "CASALANGUIDA",
    "B862": "CASALATTICO",
    "B864": "CASALBELTRAME",
    "B865": "CASALBORDINO",
    "B866": "CASALBORE",
    "B867": "CASALBORGONE",
    "B868": "CASALBUONO",
    "B869": "CASALBUTTANO ED UNITI",
    "B871": "CASALCIPRANO",
    "B873": "CASALDUNI",
    "B876": "CASALE CORTE CERRO",
    "B881": "CASALE CREMASCO-VIDOLASCO",
    "B877": "CASALE DI SCODOSIA",
    "B875": "CASALE LITTA",
    "B878": "CASALE MARITTIMO",
    "B885": "CASALE MONFERRATO",
    "B879": "CASALE SUL SILE",
    "B880": "CASALECCHIO DI RENO",
    "B882": "CASALEGGIO BOIRO",
    "B883": "CASALEGGIO NOVARA",
    "B886": "CASALEONE",
    "B889": "CASALETTO CEREDANO",
    "B890": "CASALETTO DI SOPRA",
    "B887": "CASALETTO LODIGIANO",
    "B888": "CASALETTO SPARTANO",
    "B891": "CASALETTO VAPRIO",
    "B892": "CASALFIUMANESE",
    "B893": "CASALGRANDE",
    "B894": "CASALGRASSO",
    "B896": "CASALINCONTRADA",
    "B897": "CASALINO",
    "B898": "CASALMAGGIORE",
    "B899": "CASALMAIOCCO",
    "B900": "CASALMORANO",
    "B901": "CASALMORO",
    "B902": "CASALNOCETO",
    "B905": "CASALNUOVO DI NAPOLI",
    "B904": "CASALNUOVO MONTEROTARO",
    "B907": "CASALOLDO",
    "B910": "CASALPUSTERLENGO",
    "B911": "CASALROMANO",
    "B912": "CASALSERUGO",
    "B916": "CASALUCE",
    "B917": "CASALVECCHIO DI PUGLIA",
    "B918": "CASALVECCHIO SICULO",
    "B919": "CASALVIERI",
    "B920": "CASALVOLONE",
    "B921": "CASALZUIGNO",
    "B922": "CASAMARCIANO",
    "B923": "CASAMASSIMA",
    "B924": "CASAMICCIOLA TERME",
    "B925": "CASANDRINO",
    "B928": "CASANOVA ELVO",
    "B927": "CASANOVA LERRONE",
    "B929": "CASANOVA LONATI",
    "B932": "CASAPE",
    "M260": "CASAPESENNA",
    "B933": "CASAPINTA",
    "B934": "CASAPROTA",
    "B935": "CASAPULLA",
    "B936": "CASARANO",
    "B937": "CASARGO",
    "B938": "CASARILE",
    "B940": "CASARSA DELLA DELIZIA",
    "B939": "CASARZA LIGURE",
    "B941": "CASASCO",
    "B942": "CASASCO D'INTELVI",
    "B943": "CASATENOVO",
    "B945": "CASATISMA",
    "B946": "CASAVATORE",
    "B947": "CASAZZA",
    "B948": "CASCIA",
    "B949": "CASCIAGO",
    "A559": "CASCIANA TERME",
    "M327": "CASCIANA TERME LARI",
    "B950": "CASCINA",
    "B953": "CASCINETTE D'IVREA",
    "B954": "CASEI GEROLA",
    "B955": "CASELETTE",
    "B956": "CASELLA",
    "B959": "CASELLE IN PITTARI",
    "B961": "CASELLE LANDI",
    "B958": "CASELLE LURANI",
    "B960": "CASELLE TORINESE",
    "B963": "CASERTA",
    "B965": "CASIER",
    "B966": "CASIGNANA",
    "B967": "CASINA",
    "B971": "CASIRATE D'ADDA",
    "B974": "CASLINO D'ERBA",
    "B977": "CASNATE CON BERNATE",
    "B978": "CASNIGO",
    "B980": "CASOLA DI NAPOLI",
    "B979": "CASOLA IN LUNIGIANA",
    "B982": "CASOLA VALSENIO",
    "B983": "CASOLE BRUZIO",
    "B984": "CASOLE D'ELSA",
    "B985": "CASOLI",
    "B988": "CASORATE PRIMO",
    "B987": "CASORATE SEMPIONE",
    "B989": "CASOREZZO",
    "B990": "CASORIA",
    "B991": "CASORZO",
    "A472": "CASPERIA",
    "B993": "CASPOGGIO",
    "B994": "CASSACCO",
    "B996": "CASSAGO BRIANZA",
    "C002": "CASSANO ALL'IONIO",
    "C003": "CASSANO D'ADDA",
    "B998": "CASSANO DELLE MURGE",
    "B997": "CASSANO IRPINO",
    "C004": "CASSANO MAGNAGO",
    "C005": "CASSANO SPINOLA",
    "B999": "CASSANO VALCUVIA",
    "C006": "CASSARO",
    "C007": "CASSIGLIO",
    "C014": "CASSINA DE' PECCHI",
    "C020": "CASSINA RIZZARDI",
    "C024": "CASSINA VALSASSINA",
    "C022": "CASSINASCO",
    "C027": "CASSINE",
    "C030": "CASSINELLE",
    "C033": "CASSINETTA DI LUGAGNANO",
    "C034": "CASSINO",
    "C037": "CASSOLA",
    "C038": "CASSOLNOVO",
    "C041": "CASTAGNARO",
    "C044": "CASTAGNETO CARDUCCI",
    "C045": "CASTAGNETO PO",
    "C046": "CASTAGNITO",
    "C049": "CASTAGNOLE DELLE LANZE",
    "C047": "CASTAGNOLE MONFERRATO",
    "C048": "CASTAGNOLE PIEMONTE",
    "C050": "CASTANA",
    "C052": "CASTANO PRIMO",
    "C053": "CASTEGGIO",
    "C055": "CASTEGNATO",
    "C056": "CASTEGNERO",
    "C058": "CASTEL BARONIA",
    "C064": "CASTEL BOGLIONE",
    "C065": "CASTEL BOLOGNESE",
    "B494": "CASTEL CAMPAGNANO",
    "C040": "CASTEL CASTAGNA",
    "C071": "CASTEL COLONNA",
    "C183": "CASTEL CONDINO",
    "C075": "CASTEL D'AIANO",
    "C076": "CASTEL D'ARIO",
    "C078": "CASTEL D'AZZANO",
    "C082": "CASTEL DEL GIUDICE",
    "C083": "CASTEL DEL MONTE",
    "C085": "CASTEL DEL PIANO",
    "C086": "CASTEL DEL RIO",
    "B969": "CASTEL DI CASIO",
    "C090": "CASTEL DI IERI",
    "C091": "CASTEL DI IUDICA",
    "C093": "CASTEL DI LAMA",
    "C094": "CASTEL DI LUCIO",
    "C096": "CASTEL DI SANGRO",
    "C097": "CASTEL DI SASSO",
    "C098": "CASTEL DI TORA",
    "C102": "CASTEL FOCOGNANO",
    "C114": "CASTEL FRENTANO",
    "C115": "CASTEL GABBIANO",
    "C116": "CASTEL GANDOLFO",
    "C117": "CASTEL GIORGIO",
    "C118": "CASTEL GOFFREDO",
    "C121": "CASTEL GUELFO DI BOLOGNA",
    "M354": "CASTEL IVANO",
    "C203": "CASTEL MADAMA",
    "C204": "CASTEL MAGGIORE",
    "C208": "CASTEL MELLA",
    "C211": "CASTEL MORRONE",
    "C252": "CASTEL RITALDI",
    "C253": "CASTEL ROCCHERO",
    "C255": "CASTEL ROZZONE",
    "C259": "CASTEL SAN GIORGIO",
    "C261": "CASTEL SAN GIOVANNI",
    "C262": "CASTEL SAN LORENZO",
    "C263": "CASTEL SAN NICCOLO'",
    "C266": "CASTEL SAN PIETRO ROMANO",
    "C265": "CASTEL SAN PIETRO TERME",
    "C270": "CASTEL SAN VINCENZO",
    "C268": "CASTEL SANT'ANGELO",
    "C269": "CASTEL SANT'ELIA",
    "C289": "CASTEL VISCARDO",
    "C110": "CASTEL VITTORIO",
    "C291": "CASTEL VOLTURNO",
    "C057": "CASTELBALDO",
    "C059": "CASTELBELFORTE",
    "C060": "CASTELBELLINO",
    "C062": "CASTELBELLO CIARDES .KASTELBELL TSCHARS.",
    "C063": "CASTELBIANCO",
    "C066": "CASTELBOTTACCIO",
    "C067": "CASTELBUONO",
    "C069": "CASTELCIVITA",
    "C072": "CASTELCOVATI",
    "C073": "CASTELCUCCO",
    "C074": "CASTELDACCIA",
    "C080": "CASTELDELCI",
    "C081": "CASTELDELFINO",
    "C089": "CASTELDIDONE",
    "C100": "CASTELFIDARDO",
    "C101": "CASTELFIORENTINO",
    "C103": "CASTELFONDO",
    "C104": "CASTELFORTE",
    "C105": "CASTELFRANCI",
    "C112": "CASTELFRANCO DI SOPRA",
    "C113": "CASTELFRANCO DI SOTTO",
    "C107": "CASTELFRANCO EMILIA",
    "C106": "CASTELFRANCO IN MISCANO",
    "M322": "CASTELFRANCO PIANDISCO'",
    "C111": "CASTELFRANCO VENETO",
    "C119": "CASTELGOMBERTO",
    "C120": "CASTELGRANDE",
    "C122": "CASTELGUGLIELMO",
    "C123": "CASTELGUIDONE",
    "C125": "CASTELLABATE",
    "C126": "CASTELLAFIUME",
    "C127": "CASTELL'ALFERO",
    "C128": "CASTELLALTO",
    "C130": "CASTELLAMMARE DEL GOLFO",
    "C129": "CASTELLAMMARE DI STABIA",
    "C133": "CASTELLAMONTE",
    "C134": "CASTELLANA GROTTE",
    "C135": "CASTELLANA SICULA",
    "C136": "CASTELLANETA",
    "C137": "CASTELLANIA",
    "C139": "CASTELLANZA",
    "C140": "CASTELLAR",
    "C142": "CASTELLAR GUIDOBONO",
    "C141": "CASTELLARANO",
    "C143": "CASTELLARO",
    "C145": "CASTELL'ARQUATO",
    "C146": "CASTELLAVAZZO",
    "C147": "CASTELL'AZZARA",
    "C148": "CASTELLAZZO BORMIDA",
    "C149": "CASTELLAZZO NOVARESE",
    "C153": "CASTELLEONE",
    "C152": "CASTELLEONE DI SUASA",
    "C154": "CASTELLERO",
    "C155": "CASTELLETTO CERVO",
    "C156": "CASTELLETTO D'ERRO",
    "C157": "CASTELLETTO DI BRANDUZZO",
    "C158": "CASTELLETTO D'ORBA",
    "C160": "CASTELLETTO MERLI",
    "C161": "CASTELLETTO MOLINA",
    "C162": "CASTELLETTO MONFERRATO",
    "C166": "CASTELLETTO SOPRA TICINO",
    "C165": "CASTELLETTO STURA",
    "C167": "CASTELLETTO UZZONE",
    "C169": "CASTELLI",
    "C079": "CASTELLI CALEPIO",
    "C172": "CASTELLINA IN CHIANTI",
    "C174": "CASTELLINA MARITTIMA",
    "C173": "CASTELLINALDO",
    "C175": "CASTELLINO DEL BIFERNO",
    "C176": "CASTELLINO TANARO",
    "C177": "CASTELLIRI",
    "B312": "CASTELLO CABIAGLIO",
    "C184": "CASTELLO D'AGOGNA",
    "C185": "CASTELLO D'ARGILE",
    "C178": "CASTELLO DEL MATESE",
    "C186": "CASTELLO DELL'ACQUA",
    "A300": "CASTELLO DI ANNONE",
    "C187": "CASTELLO DI BRIANZA",
    "C188": "CASTELLO DI CISTERNA",
    "C190": "CASTELLO DI GODEGO",
    "C191": "CASTELLO DI SERRAVALLE",
    "C194": "CASTELLO TESINO",
    "C189": "CASTELLO-MOLINA DI FIEMME",
    "C195": "CASTELLUCCHIO",
    "C198": "CASTELLUCCIO DEI SAURI",
    "C199": "CASTELLUCCIO INFERIORE",
    "C201": "CASTELLUCCIO SUPERIORE",
    "C202": "CASTELLUCCIO VALMAGGIORE",
    "C051": "CASTELL'UMBERTO",
    "C205": "CASTELMAGNO",
    "C206": "CASTELMARTE",
    "C207": "CASTELMASSA",
    "C197": "CASTELMAURO",
    "C209": "CASTELMEZZANO",
    "C210": "CASTELMOLA",
    "C213": "CASTELNOVETTO",
    "C215": "CASTELNOVO BARIANO",
    "C217": "CASTELNOVO DEL FRIULI",
    "C218": "CASTELNOVO DI SOTTO",
    "C219": "CASTELNOVO NE' MONTI",
    "C216": "CASTELNUOVO",
    "C226": "CASTELNUOVO BELBO",
    "C227": "CASTELNUOVO BERARDENGA",
    "C228": "CASTELNUOVO BOCCA D'ADDA",
    "C229": "CASTELNUOVO BORMIDA",
    "C220": "CASTELNUOVO BOZZENTE",
    "C230": "CASTELNUOVO CALCEA",
    "C231": "CASTELNUOVO CILENTO",
    "C225": "CASTELNUOVO DEL GARDA",
    "C222": "CASTELNUOVO DELLA DAUNIA",
    "C214": "CASTELNUOVO DI CEVA",
    "C235": "CASTELNUOVO DI CONZA",
    "C224": "CASTELNUOVO DI FARFA",
    "C236": "CASTELNUOVO DI GARFAGNANA",
    "C237": "CASTELNUOVO DI PORTO",
    "C232": "CASTELNUOVO DON BOSCO",
    "C240": "CASTELNUOVO MAGRA",
    "C241": "CASTELNUOVO NIGRA",
    "C223": "CASTELNUOVO PARANO",
    "C242": "CASTELNUOVO RANGONE",
    "C243": "CASTELNUOVO SCRIVIA",
    "C244": "CASTELNUOVO VAL DI CECINA",
    "C245": "CASTELPAGANO",
    "C246": "CASTELPETROSO",
    "C247": "CASTELPIZZUTO",
    "C248": "CASTELPLANIO",
    "C250": "CASTELPOTO",
    "C251": "CASTELRAIMONDO",
    "C254": "CASTELROTTO .KASTELRUTH.",
    "C267": "CASTELSANTANGELO SUL NERA",
    "C271": "CASTELSARACENO",
    "C272": "CASTELSARDO",
    "C273": "CASTELSEPRIO",
    "B968": "CASTELSILANO",
    "C274": "CASTELSPINA",
    "C275": "CASTELTERMINI",
    "C181": "CASTELVECCANA",
    "C278": "CASTELVECCHIO CALVISIO",
    "C276": "CASTELVECCHIO DI ROCCA BARBENA",
    "C279": "CASTELVECCHIO SUBEQUO",
    "C280": "CASTELVENERE",
    "B129": "CASTELVERDE",
    "C200": "CASTELVERRINO",
    "C284": "CASTELVETERE IN VAL FORTORE",
    "C283": "CASTELVETERE SUL CALORE",
    "C286": "CASTELVETRANO",
    "C287": "CASTELVETRO DI MODENA",
    "C288": "CASTELVETRO PIACENTINO",
    "C290": "CASTELVISCONTI",
    "C292": "CASTENASO",
    "C293": "CASTENEDOLO",
    "M288": "CASTIADAS",
    "C318": "CASTIGLION FIBOCCHI",
    "C319": "CASTIGLION FIORENTINO",
    "C308": "CASTIGLIONE A CASAURIA",
    "C302": "CASTIGLIONE CHIAVARESE",
    "C301": "CASTIGLIONE COSENTINO",
    "C304": "CASTIGLIONE D'ADDA",
    "C296": "CASTIGLIONE DEI PEPOLI",
    "C306": "CASTIGLIONE DEL GENOVESI",
    "C309": "CASTIGLIONE DEL LAGO",
    "C310": "CASTIGLIONE DELLA PESCAIA",
    "C312": "CASTIGLIONE DELLE STIVIERE",
    "C303": "CASTIGLIONE DI GARFAGNANA",
    "C297": "CASTIGLIONE DI SICILIA",
    "C299": "CASTIGLIONE D'INTELVI",
    "C313": "CASTIGLIONE D'ORCIA",
    "C314": "CASTIGLIONE FALLETTO",
    "C315": "CASTIGLIONE IN TEVERINA",
    "C298": "CASTIGLIONE MESSER MARINO",
    "C316": "CASTIGLIONE MESSER RAIMONDO",
    "C300": "CASTIGLIONE OLONA",
    "C317": "CASTIGLIONE TINELLA",
    "C307": "CASTIGLIONE TORINESE",
    "C321": "CASTIGNANO",
    "C322": "CASTILENTI",
    "C323": "CASTINO",
    "C325": "CASTIONE ANDEVENNO",
    "C324": "CASTIONE DELLA PRESOLANA",
    "C327": "CASTIONS DI STRADA",
    "C329": "CASTIRAGA VIDARDO",
    "C330": "CASTO",
    "C331": "CASTORANO",
    "C332": "CASTREZZATO",
    "C334": "CASTRI DI LECCE",
    "C335": "CASTRIGNANO DE' GRECI",
    "C336": "CASTRIGNANO DEL CAPO",
    "C337": "CASTRO",
    "M261": "CASTRO",
    "C338": "CASTRO DEI VOLSCI",
    "C339": "CASTROCARO TERME E TERRA DEL SOLE",
    "C340": "CASTROCIELO",
    "C341": "CASTROFILIPPO",
    "C108": "CASTROLIBERO",
    "C343": "CASTRONNO",
    "C344": "CASTRONOVO DI SICILIA",
    "C345": "CASTRONUOVO DI SANT'ANDREA",
    "C346": "CASTROPIGNANO",
    "C347": "CASTROREALE",
    "C348": "CASTROREGIO",
    "C349": "CASTROVILLARI",
    "C351": "CATANIA",
    "C352": "CATANZARO",
    "C353": "CATENANUOVA",
    "C354": "CATIGNANO",
    "C357": "CATTOLICA",
    "C356": "CATTOLICA ERACLEA",
    "C285": "CAULONIA",
    "C359": "CAUTANO",
    "C361": "CAVA DE' TIRRENI",
    "C360": "CAVA MANARA",
    "C362": "CAVACURTA",
    "C363": "CAVAGLIA'",
    "C364": "CAVAGLIETTO",
    "C365": "CAVAGLIO D'AGOGNA",
    "C367": "CAVAGLIO-SPOCCIA",
    "C369": "CAVAGNOLO",
    "C370": "CAVAION VERONESE",
    "C372": "CAVALESE",
    "C374": "CAVALLASCA",
    "C375": "CAVALLERLEONE",
    "C376": "CAVALLERMAGGIORE",
    "C377": "CAVALLINO",
    "M308": "CAVALLINO-TREPORTI",
    "C378": "CAVALLIRIO",
    "C380": "CAVARENO",
    "C381": "CAVARGNA",
    "C382": "CAVARIA CON PREMEZZO",
    "C383": "CAVARZERE",
    "C384": "CAVASO DEL TOMBA",
    "C385": "CAVASSO NUOVO",
    "C387": "CAVATORE",
    "C389": "CAVAZZO CARNICO",
    "C390": "CAVE",
    "C392": "CAVEDAGO",
    "C393": "CAVEDINE",
    "C394": "CAVENAGO D'ADDA",
    "C395": "CAVENAGO DI BRIANZA",
    "C396": "CAVERNAGO",
    "C398": "CAVEZZO",
    "C400": "CAVIZZANA",
    "C404": "CAVOUR",
    "C405": "CAVRIAGO",
    "C406": "CAVRIANA",
    "C407": "CAVRIGLIA",
    "C409": "CAZZAGO BRABBIA",
    "C408": "CAZZAGO SAN MARTINO",
    "C412": "CAZZANO DI TRAMIGNA",
    "C410": "CAZZANO SANT'ANDREA",
    "C413": "CECCANO",
    "C414": "CECIMA",
    "C415": "CECINA",
    "C417": "CEDEGOLO",
    "C418": "CEDRASCO",
    "C420": "CEFALA' DIANA",
    "C421": "CEFALU'",
    "C422": "CEGGIA",
    "C424": "CEGLIE MESSAPICA",
    "C426": "CELANO",
    "C428": "CELENZA SUL TRIGNO",
    "C429": "CELENZA VALFORTORE",
    "C430": "CELICO",
    "C435": "CELLA DATI",
    "C432": "CELLA MONTE",
    "C436": "CELLAMARE",
    "C437": "CELLARA",
    "C438": "CELLARENGO",
    "C439": "CELLATICA",
    "C444": "CELLE DI BULGHERIA",
    "C441": "CELLE DI MACRA",
    "C442": "CELLE DI SAN VITO",
    "C440": "CELLE ENOMONDO",
    "C443": "CELLE LIGURE",
    "C446": "CELLENO",
    "C447": "CELLERE",
    "C449": "CELLINO ATTANASIO",
    "C448": "CELLINO SAN MARCO",
    "C450": "CELLIO",
    "M262": "CELLOLE",
    "C452": "CEMBRA",
    "M355": "CEMBRA LISIGNAGO",
    "C453": "CENADI",
    "C456": "CENATE SOPRA",
    "C457": "CENATE SOTTO",
    "C458": "CENCENIGHE AGORDINO",
    "C459": "CENE",
    "C461": "CENESELLI",
    "C463": "CENGIO",
    "C467": "CENTA SAN NICOLO'",
    "C466": "CENTALLO",
    "C469": "CENTO",
    "C470": "CENTOLA",
    "C472": "CENTRACHE",
    "C471": "CENTURIPE",
    "C474": "CEPAGATTI",
    "C476": "CEPPALONI",
    "C478": "CEPPO MORELLI",
    "C479": "CEPRANO",
    "C480": "CERAMI",
    "C481": "CERANESI",
    "C483": "CERANO",
    "C482": "CERANO D'INTELVI",
    "C484": "CERANOVA",
    "C485": "CERASO",
    "C486": "CERCEMAGGIORE",
    "C487": "CERCENASCO",
    "C488": "CERCEPICCOLA",
    "C489": "CERCHIARA DI CALABRIA",
    "C492": "CERCHIO",
    "C493": "CERCINO",
    "C494": "CERCIVENTO",
    "C495": "CERCOLA",
    "C496": "CERDA",
    "C498": "CEREA",
    "C500": "CEREGNANO",
    "C501": "CERENZIA",
    "C497": "CERES",
    "C502": "CERESARA",
    "C503": "CERESETO",
    "C504": "CERESOLE ALBA",
    "C505": "CERESOLE REALE",
    "C506": "CERETE",
    "C508": "CERETTO LOMELLINA",
    "C509": "CERGNAGO",
    "C510": "CERIALE",
    "C511": "CERIANA",
    "C512": "CERIANO LAGHETTO",
    "C513": "CERIGNALE",
    "C514": "CERIGNOLA",
    "C515": "CERISANO",
    "C516": "CERMENATE",
    "A022": "CERMES .TSCHERMS.",
    "C517": "CERMIGNANO",
    "C520": "CERNOBBIO",
    "C521": "CERNUSCO LOMBARDONE",
    "C523": "CERNUSCO SUL NAVIGLIO",
    "C526": "CERRETO CASTELLO",
    "C528": "CERRETO D'ASTI",
    "C524": "CERRETO D'ESI",
    "C527": "CERRETO DI SPOLETO",
    "C507": "CERRETO GRUE",
    "C529": "CERRETO GUIDI",
    "C518": "CERRETO LAZIALE",
    "C525": "CERRETO SANNITA",
    "C530": "CERRETTO LANGHE",
    "C531": "CERRINA MONFERRATO",
    "C532": "CERRIONE",
    "C536": "CERRO AL LAMBRO",
    "C534": "CERRO AL VOLTURNO",
    "C537": "CERRO MAGGIORE",
    "C533": "CERRO TANARO",
    "C538": "CERRO VERONESE",
    "C539": "CERSOSIMO",
    "C540": "CERTALDO",
    "C541": "CERTOSA DI PAVIA",
    "C542": "CERVA",
    "C543": "CERVARA DI ROMA",
    "C544": "CERVARESE SANTA CROCE",
    "C545": "CERVARO",
    "C547": "CERVASCA",
    "C548": "CERVATTO",
    "C549": "CERVENO",
    "C550": "CERVERE",
    "C551": "CERVESINA",
    "C552": "CERVETERI",
    "C553": "CERVIA",
    "C554": "CERVICATI",
    "C555": "CERVIGNANO D'ADDA",
    "C556": "CERVIGNANO DEL FRIULI",
    "C557": "CERVINARA",
    "C558": "CERVINO",
    "C559": "CERVO",
    "C560": "CERZETO",
    "C561": "CESA",
    "C563": "CESANA BRIANZA",
    "C564": "CESANA TORINESE",
    "C565": "CESANO BOSCONE",
    "C566": "CESANO MADERNO",
    "C567": "CESARA",
    "C568": "CESARO'",
    "C569": "CESATE",
    "C573": "CESENA",
    "C574": "CESENATICO",
    "C576": "CESINALI",
    "C578": "CESIO",
    "C577": "CESIOMAGGIORE",
    "C580": "CESSALTO",
    "C581": "CESSANITI",
    "C582": "CESSAPALOMBO",
    "C583": "CESSOLE",
    "C584": "CETARA",
    "C585": "CETO",
    "C587": "CETONA",
    "C588": "CETRARO",
    "C589": "CEVA",
    "C591": "CEVO",
    "C593": "CHALLAND-SAINT-ANSELME",
    "C594": "CHALLAND-SAINT-VICTOR",
    "C595": "CHAMBAVE",
    "B491": "CHAMOIS",
    "C596": "CHAMPDEPRAZ",
    "B540": "CHAMPORCHER",
    "C598": "CHARVENSOD",
    "C294": "CHATILLON",
    "C599": "CHERASCO",
    "C600": "CHEREMULE",
    "C604": "CHIALAMBERTO",
    "C605": "CHIAMPO",
    "C606": "CHIANCHE",
    "C608": "CHIANCIANO TERME",
    "C609": "CHIANNI",
    "C610": "CHIANOCCO",
    "C612": "CHIARAMONTE GULFI",
    "C613": "CHIARAMONTI",
    "C614": "CHIARANO",
    "C615": "CHIARAVALLE",
    "C616": "CHIARAVALLE CENTRALE",
    "C618": "CHIARI",
    "C619": "CHIAROMONTE",
    "C620": "CHIAUCI",
    "C621": "CHIAVARI",
    "C623": "CHIAVENNA",
    "C624": "CHIAVERANO",
    "C625": "CHIENES .KIENS.",
    "C627": "CHIERI",
    "C630": "CHIES D'ALPAGO",
    "C628": "CHIESA IN VALMALENCO",
    "C629": "CHIESANUOVA",
    "C631": "CHIESINA UZZANESE",
    "C632": "CHIETI",
    "C633": "CHIEUTI",
    "C634": "CHIEVE",
    "C635": "CHIGNOLO D'ISOLA",
    "C637": "CHIGNOLO PO",
    "C638": "CHIOGGIA",
    "C639": "CHIOMONTE",
    "C640": "CHIONS",
    "C641": "CHIOPRIS VISCONE",
    "C648": "CHITIGNANO",
    "C649": "CHIUDUNO",
    "C650": "CHIUPPANO",
    "C651": "CHIURO",
    "C652": "CHIUSA .KLAUSEN.",
    "C653": "CHIUSA DI PESIO",
    "C655": "CHIUSA DI SAN MICHELE",
    "C654": "CHIUSA SCLAFANI",
    "C656": "CHIUSAFORTE",
    "C657": "CHIUSANICO",
    "C658": "CHIUSANO D'ASTI",
    "C659": "CHIUSANO DI SAN DOMENICO",
    "C660": "CHIUSAVECCHIA",
    "C661": "CHIUSDINO",
    "C662": "CHIUSI",
    "C663": "CHIUSI DELLA VERNA",
    "C665": "CHIVASSO",
    "M272": "CIAMPINO",
    "C668": "CIANCIANA",
    "C672": "CIBIANA DI CADORE",
    "C673": "CICAGNA",
    "C674": "CICALA",
    "C675": "CICCIANO",
    "C676": "CICERALE",
    "C677": "CICILIANO",
    "C678": "CICOGNOLO",
    "C679": "CICONIO",
    "C680": "CIGLIANO",
    "C681": "CIGLIE'",
    "C684": "CIGOGNOLA",
    "C685": "CIGOLE",
    "C686": "CILAVEGNA",
    "C689": "CIMADOLMO",
    "C691": "CIMBERGO",
    "C694": "CIMEGO",
    "C695": "CIMINA'",
    "C696": "CIMINNA",
    "C697": "CIMITILE",
    "C699": "CIMOLAIS",
    "C700": "CIMONE",
    "C701": "CINAGLIO",
    "C702": "CINETO ROMANO",
    "C703": "CINGIA DE' BOTTI",
    "C704": "CINGOLI",
    "C705": "CINIGIANO",
    "C707": "CINISELLO BALSAMO",
    "C708": "CINISI",
    "C709": "CINO",
    "C710": "CINQUEFRONDI",
    "C711": "CINTANO",
    "C712": "CINTE TESINO",
    "C714": "CINTO CAOMAGGIORE",
    "C713": "CINTO EUGANEO",
    "C715": "CINZANO",
    "C716": "CIORLANO",
    "C718": "CIPRESSA",
    "C719": "CIRCELLO",
    "C722": "CIRIE'",
    "C723": "CIRIGLIANO",
    "C724": "CIRIMIDO",
    "C725": "CIRO'",
    "C726": "CIRO' MARINA",
    "C727": "CIS",
    "C728": "CISANO BERGAMASCO",
    "C729": "CISANO SUL NEVA",
    "C730": "CISERANO",
    "C732": "CISLAGO",
    "C733": "CISLIANO",
    "C734": "CISMON DEL GRAPPA",
    "C735": "CISON DI VALMARINO",
    "C738": "CISSONE",
    "C739": "CISTERNA D'ASTI",
    "C740": "CISTERNA DI LATINA",
    "C741": "CISTERNINO",
    "C742": "CITERNA",
    "C744": "CITTA' DELLA PIEVE",
    "C745": "CITTA' DI CASTELLO",
    "C750": "CITTA' SANT'ANGELO",
    "C743": "CITTADELLA",
    "C746": "CITTADUCALE",
    "C747": "CITTANOVA",
    "C749": "CITTAREALE",
    "C751": "CITTIGLIO",
    "C752": "CIVATE",
    "C754": "CIVENNA",
    "C755": "CIVEZZA",
    "C756": "CIVEZZANO",
    "C757": "CIVIASCO",
    "C758": "CIVIDALE DEL FRIULI",
    "C759": "CIVIDATE AL PIANO",
    "C760": "CIVIDATE CAMUNO",
    "C763": "CIVITA",
    "C765": "CIVITA CASTELLANA",
    "C766": "CIVITA D'ANTINO",
    "C764": "CIVITACAMPOMARANO",
    "C768": "CIVITALUPARELLA",
    "C769": "CIVITANOVA DEL SANNIO",
    "C770": "CIVITANOVA MARCHE",
    "C771": "CIVITAQUANA",
    "C773": "CIVITAVECCHIA",
    "C778": "CIVITELLA ALFEDENA",
    "C779": "CIVITELLA CASANOVA",
    "C780": "CIVITELLA D'AGLIANO",
    "C781": "CIVITELLA DEL TRONTO",
    "C777": "CIVITELLA DI ROMAGNA",
    "C774": "CIVITELLA IN VAL DI CHIANA",
    "C776": "CIVITELLA MESSER RAIMONDO",
    "C782": "CIVITELLA PAGANICO",
    "C783": "CIVITELLA ROVETO",
    "C784": "CIVITELLA SAN PAOLO",
    "C785": "CIVO",
    "C787": "CLAINO CON OSTENO",
    "C790": "CLAUT",
    "C791": "CLAUZETTO",
    "C792": "CLAVESANA",
    "C793": "CLAVIERE",
    "C794": "CLES",
    "C795": "CLETO",
    "C796": "CLIVIO",
    "C797": "CLOZ",
    "C800": "CLUSONE",
    "C801": "COASSOLO TORINESE",
    "C803": "COAZZE",
    "C804": "COAZZOLO",
    "C806": "COCCAGLIO",
    "C807": "COCCONATO",
    "C810": "COCQUIO-TREVISAGO",
    "C811": "COCULLO",
    "C812": "CODEVIGO",
    "C813": "CODEVILLA",
    "C814": "CODIGORO",
    "C815": "CODOGNE'",
    "C816": "CODOGNO",
    "C817": "CODROIPO",
    "C818": "CODRONGIANOS",
    "C819": "COGGIOLA",
    "C820": "COGLIATE",
    "C821": "COGNE",
    "C823": "COGOLETO",
    "C824": "COGOLLO DEL CENGIO",
    "C826": "COGORNO",
    "C829": "COLAZZA",
    "C830": "COLBORDOLO",
    "C835": "COLERE",
    "C836": "COLFELICE",
    "C838": "COLI",
    "C839": "COLICO",
    "C840": "COLLAGNA",
    "C841": "COLLALTO SABINO",
    "C844": "COLLARMELE",
    "C845": "COLLAZZONE",
    "C851": "COLLE BRIANZA",
    "C854": "COLLE D'ANCHISE",
    "C857": "COLLE DI TORA",
    "C847": "COLLE DI VAL D'ELSA",
    "C870": "COLLE SAN MAGNO",
    "C846": "COLLE SANNITA",
    "C872": "COLLE SANTA LUCIA",
    "C848": "COLLE UMBERTO",
    "C850": "COLLEBEATO",
    "C852": "COLLECCHIO",
    "C853": "COLLECORVINO",
    "C311": "COLLEDARA",
    "C855": "COLLEDIMACINE",
    "C856": "COLLEDIMEZZO",
    "C858": "COLLEFERRO",
    "C859": "COLLEGIOVE",
    "C860": "COLLEGNO",
    "C862": "COLLELONGO",
    "C864": "COLLEPARDO",
    "C865": "COLLEPASSO",
    "C866": "COLLEPIETRO",
    "C867": "COLLERETTO CASTELNUOVO",
    "C868": "COLLERETTO GIACOSA",
    "C869": "COLLESALVETTI",
    "C871": "COLLESANO",
    "C875": "COLLETORTO",
    "C876": "COLLEVECCHIO",
    "C878": "COLLI A VOLTURNO",
    "C877": "COLLI DEL TRONTO",
    "C880": "COLLI SUL VELINO",
    "C879": "COLLIANO",
    "C882": "COLLINAS",
    "C883": "COLLIO",
    "C884": "COLLOBIANO",
    "C885": "COLLOREDO DI MONTE ALBANO",
    "C886": "COLMURANO",
    "C888": "COLOBRARO",
    "C890": "COLOGNA VENETA",
    "C893": "COLOGNE",
    "C894": "COLOGNO AL SERIO",
    "C895": "COLOGNO MONZESE",
    "C897": "COLOGNOLA AI COLLI",
    "C900": "COLONNA",
    "C901": "COLONNELLA",
    "C902": "COLONNO",
    "C903": "COLORINA",
    "C904": "COLORNO",
    "C905": "COLOSIMI",
    "C908": "COLTURANO",
    "M336": "COLVERDE",
    "C910": "COLZATE",
    "C911": "COMABBIO",
    "C912": "COMACCHIO",
    "C914": "COMANO",
    "M314": "COMANO TERME",
    "C917": "COMAZZO",
    "C918": "COMEGLIANS",
    "C920": "COMELICO SUPERIORE",
    "C922": "COMERIO",
    "C925": "COMEZZANO-CIZZAGO",
    "C926": "COMIGNAGO",
    "C927": "COMISO",
    "C928": "COMITINI",
    "C929": "COMIZIANO",
    "C930": "COMMESSAGGIO",
    "C931": "COMMEZZADURA",
    "C933": "COMO",
    "C934": "COMPIANO",
    "C937": "COMUN NUOVO",
    "C935": "COMUNANZA",
    "C938": "CONA",
    "C941": "CONCA CASALE",
    "C940": "CONCA DEI MARINI",
    "C939": "CONCA DELLA CAMPANIA",
    "C943": "CONCAMARISE",
    "C944": "CONCEI",
    "C946": "CONCERVIANO",
    "C948": "CONCESIO",
    "C949": "CONCO",
    "C950": "CONCORDIA SAGITTARIA",
    "C951": "CONCORDIA SULLA SECCHIA",
    "C952": "CONCOREZZO",
    "C953": "CONDINO",
    "C954": "CONDOFURI",
    "C955": "CONDOVE",
    "C956": "CONDRO'",
    "C957": "CONEGLIANO",
    "C958": "CONFIENZA",
    "C959": "CONFIGNI",
    "C960": "CONFLENTI",
    "C962": "CONIOLO",
    "C963": "CONSELICE",
    "C964": "CONSELVE",
    "C965": "CONSIGLIO DI RUMO",
    "M356": "CONTA'",
    "C968": "CONTESSA ENTELLINA",
    "C969": "CONTIGLIANO",
    "C971": "CONTRADA",
    "C972": "CONTROGUERRA",
    "C973": "CONTRONE",
    "C974": "CONTURSI TERME",
    "C975": "CONVERSANO",
    "C976": "CONZA DELLA CAMPANIA",
    "C977": "CONZANO",
    "C978": "COPERTINO",
    "C979": "COPIANO",
    "C980": "COPPARO",
    "C982": "CORANA",
    "C983": "CORATO",
    "C984": "CORBARA",
    "C986": "CORBETTA",
    "C987": "CORBOLA",
    "C988": "CORCHIANO",
    "C990": "CORCIANO",
    "C991": "CORDENONS",
    "C992": "CORDIGNANO",
    "C993": "CORDOVADO",
    "C994": "COREDO",
    "C996": "COREGLIA ANTELMINELLI",
    "C995": "COREGLIA LIGURE",
    "C998": "CORENO AUSONIO",
    "C999": "CORFINIO",
    "D003": "CORI",
    "D004": "CORIANO",
    "D005": "CORIGLIANO CALABRO",
    "D006": "CORIGLIANO D'OTRANTO",
    "D007": "CORINALDO",
    "D008": "CORIO",
    "D009": "CORLEONE",
    "D011": "CORLETO MONFORTE",
    "D010": "CORLETO PERTICARA",
    "D013": "CORMANO",
    "D014": "CORMONS",
    "D015": "CORNA IMAGNA",
    "D016": "CORNALBA",
    "D017": "CORNALE",
    "M338": "CORNALE E BASTIDA",
    "D018": "CORNAREDO",
    "D019": "CORNATE D'ADDA",
    "B799": "CORNEDO ALL'ISARCO .KARNEID.",
    "D020": "CORNEDO VICENTINO",
    "D021": "CORNEGLIANO LAUDENSE",
    "D022": "CORNELIANO D'ALBA",
    "D026": "CORNIGLIO",
    "D027": "CORNO DI ROSAZZO",
    "D028": "CORNO GIOVINE",
    "D029": "CORNOVECCHIO",
    "D030": "CORNUDA",
    "D037": "CORREGGIO",
    "D038": "CORREZZANA",
    "D040": "CORREZZOLA",
    "D041": "CORRIDO",
    "D042": "CORRIDONIA",
    "D043": "CORROPOLI",
    "D044": "CORSANO",
    "D045": "CORSICO",
    "D046": "CORSIONE",
    "D048": "CORTACCIA SULLA STRADA DEL VINO .KURTATSCH A.",
    "D049": "CORTALE",
    "D050": "CORTANDONE",
    "D051": "CORTANZE",
    "D052": "CORTAZZONE",
    "D054": "CORTE BRUGNATELLA",
    "D056": "CORTE DE' CORTESI CON CIGNONE",
    "D057": "CORTE DE' FRATI",
    "D058": "CORTE FRANCA",
    "D068": "CORTE PALASIO",
    "D061": "CORTEMAGGIORE",
    "D062": "CORTEMILIA",
    "D064": "CORTENO GOLGI",
    "D065": "CORTENOVA",
    "D066": "CORTENUOVA",
    "D067": "CORTEOLONA",
    "M372": "CORTEOLONA E GENZONE",
    "D072": "CORTIGLIONE",
    "A266": "CORTINA D'AMPEZZO",
    "D075": "CORTINA SULLA STRADA DEL VINO .KURTINIG AN D.",
    "D076": "CORTINO",
    "D077": "CORTONA",
    "D078": "CORVARA",
    "D079": "CORVARA IN BADIA .CORVARA.",
    "D081": "CORVINO SAN QUIRICO",
    "D082": "CORZANO",
    "D085": "COSEANO",
    "D086": "COSENZA",
    "D087": "COSIO D'ARROSCIA",
    "D088": "COSIO VALTELLINO",
    "D089": "COSOLETO",
    "D093": "COSSANO BELBO",
    "D092": "COSSANO CANAVESE",
    "D094": "COSSATO",
    "D095": "COSSERIA",
    "D096": "COSSIGNANO",
    "D099": "COSSOGNO",
    "D100": "COSSOINE",
    "D101": "COSSOMBRATO",
    "D109": "COSTA DE' NOBILI",
    "D110": "COSTA DI MEZZATE",
    "D105": "COSTA DI ROVIGO",
    "D112": "COSTA MASNAGA",
    "D111": "COSTA SERINA",
    "D103": "COSTA VALLE IMAGNA",
    "D102": "COSTA VESCOVATO",
    "D117": "COSTA VOLPINO",
    "D107": "COSTABISSARA",
    "D108": "COSTACCIARO",
    "D113": "COSTANZANA",
    "D114": "COSTARAINERA",
    "D118": "COSTERMANO",
    "D119": "COSTIGLIOLE D'ASTI",
    "D120": "COSTIGLIOLE SALUZZO",
    "D121": "COTIGNOLA",
    "D123": "COTRONEI",
    "D124": "COTTANELLO",
    "D012": "COURMAYEUR",
    "D126": "COVO",
    "D127": "COZZO",
    "D128": "CRACO",
    "D131": "CRANDOLA VALSASSINA",
    "D132": "CRAVAGLIANA",
    "D133": "CRAVANZANA",
    "D134": "CRAVEGGIA",
    "D136": "CREAZZO",
    "D137": "CRECCHIO",
    "D139": "CREDARO",
    "D141": "CREDERA RUBBIANO",
    "D142": "CREMA",
    "D143": "CREMELLA",
    "D144": "CREMENAGA",
    "D145": "CREMENO",
    "D147": "CREMIA",
    "D149": "CREMOLINO",
    "D150": "CREMONA",
    "D151": "CREMOSANO",
    "D154": "CRESCENTINO",
    "D156": "CRESPADORO",
    "D157": "CRESPANO DEL GRAPPA",
    "D158": "CRESPELLANO",
    "D159": "CRESPIATICA",
    "D160": "CRESPINA",
    "M328": "CRESPINA LORENZANA",
    "D161": "CRESPINO",
    "D162": "CRESSA",
    "D165": "CREVACUORE",
    "D166": "CREVALCORE",
    "D168": "CREVOLADOSSOLA",
    "D170": "CRISPANO",
    "D171": "CRISPIANO",
    "D172": "CRISSOLO",
    "D175": "CROCEFIESCHI",
    "C670": "CROCETTA DEL MONTELLO",
    "D177": "CRODO",
    "D179": "CROGNALETO",
    "D180": "CROPALATI",
    "D181": "CROPANI",
    "D182": "CROSA",
    "D184": "CROSIA",
    "D185": "CROSIO DELLA VALLE",
    "D122": "CROTONE",
    "D186": "CROTTA D'ADDA",
    "D187": "CROVA",
    "D188": "CROVIANA",
    "D189": "CRUCOLI",
    "D192": "CUASSO AL MONTE",
    "D194": "CUCCARO MONFERRATO",
    "D195": "CUCCARO VETERE",
    "D196": "CUCCIAGO",
    "D197": "CUCEGLIO",
    "D198": "CUGGIONO",
    "D199": "CUGLIATE-FABIASCO",
    "D200": "CUGLIERI",
    "D201": "CUGNOLI",
    "D202": "CUMIANA",
    "D203": "CUMIGNANO SUL NAVIGLIO",
    "D204": "CUNARDO",
    "D205": "CUNEO",
    "D206": "CUNEVO",
    "D207": "CUNICO",
    "D208": "CUORGNE'",
    "D209": "CUPELLO",
    "D210": "CUPRA MARITTIMA",
    "D211": "CUPRAMONTANA",
    "B824": "CURA CARPIGNANO",
    "D214": "CURCURIS",
    "D216": "CUREGGIO",
    "D217": "CURIGLIA CON MONTEVIASCO",
    "D218": "CURINGA",
    "D219": "CURINO",
    "D221": "CURNO",
    "D222": "CURON VENOSTA .GRAUN IM VINSCHGAU.",
    "D223": "CURSI",
    "D225": "CURSOLO-ORASSO",
    "D226": "CURTAROLO",
    "D227": "CURTATONE",
    "D228": "CURTI",
    "D229": "CUSAGO",
    "D231": "CUSANO MILANINO",
    "D230": "CUSANO MUTRI",
    "D232": "CUSINO",
    "D233": "CUSIO",
    "D234": "CUSTONACI",
    "D235": "CUTIGLIANO",
    "D236": "CUTRO",
    "D237": "CUTROFIANO",
    "D238": "CUVEGLIO",
    "D239": "CUVIO",
    "D243": "DAIANO",
    "D244": "DAIRAGO",
    "D245": "DALMINE",
    "D246": "DAMBEL",
    "D247": "DANTA DI CADORE",
    "D248": "DAONE",
    "D250": "DARE'",
    "D251": "DARFO BOARIO TERME",
    "D253": "DASA'",
    "D255": "DAVAGNA",
    "D256": "DAVERIO",
    "D257": "DAVOLI",
    "D258": "DAZIO",
    "D259": "DECIMOMANNU",
    "D260": "DECIMOPUTZU",
    "D261": "DECOLLATURA",
    "D264": "DEGO",
    "D265": "DEIVA MARINA",
    "D266": "DELEBIO",
    "D267": "DELIA",
    "D268": "DELIANUOVA",
    "D269": "DELICETO",
    "D270": "DELLO",
    "D271": "DEMONTE",
    "D272": "DENICE",
    "D273": "DENNO",
    "D277": "DERNICE",
    "D278": "DEROVERE",
    "D279": "DERUTA",
    "D280": "DERVIO",
    "D281": "DESANA",
    "D284": "DESENZANO DEL GARDA",
    "D286": "DESIO",
    "D287": "DESULO",
    "D289": "DIAMANTE",
    "D293": "DIANO ARENTINO",
    "D296": "DIANO CASTELLO",
    "D291": "DIANO D'ALBA",
    "D297": "DIANO MARINA",
    "D298": "DIANO SAN PIETRO",
    "D299": "DICOMANO",
    "D300": "DIGNANO",
    "D302": "DIMARO",
    "M366": "DIMARO FOLGARIDA",
    "D303": "DINAMI",
    "D304": "DIPIGNANO",
    "D305": "DISO",
    "D309": "DIVIGNANO",
    "D310": "DIZZASCO",
    "D311": "DOBBIACO .TOBLACH.",
    "D312": "DOBERDO' DEL LAGO",
    "D314": "DOGLIANI",
    "D315": "DOGLIOLA",
    "D316": "DOGNA",
    "D317": "DOLCE'",
    "D318": "DOLCEACQUA",
    "D319": "DOLCEDO",
    "D321": "DOLEGNA DEL COLLIO",
    "D323": "DOLIANOVA",
    "D325": "DOLO",
    "D327": "DOLZAGO",
    "D328": "DOMANICO",
    "D329": "DOMASO",
    "D330": "DOMEGGE DI CADORE",
    "D331": "DOMICELLA",
    "D332": "DOMODOSSOLA",
    "D333": "DOMUS DE MARIA",
    "D334": "DOMUSNOVAS",
    "D336": "DON",
    "D339": "DONATO",
    "D341": "DONGO",
    "D338": "DONNAS",
    "D344": "DONORI",
    "D345": "DORGALI",
    "D346": "DORIO",
    "D347": "DORMELLETTO",
    "D348": "DORNO",
    "D349": "DORSINO",
    "D350": "DORZANO",
    "D351": "DOSOLO",
    "D352": "DOSSENA",
    "D355": "DOSSO DEL LIRO",
    "D356": "DOUES",
    "D357": "DOVADOLA",
    "D358": "DOVERA",
    "D360": "DOZZA",
    "D361": "DRAGONI",
    "D364": "DRAPIA",
    "D365": "DRENA",
    "D366": "DRENCHIA",
    "D367": "DRESANO",
    "D369": "DREZZO",
    "D370": "DRIZZONA",
    "D371": "DRO",
    "D372": "DRONERO",
    "D373": "DRUENTO",
    "D374": "DRUOGNO",
    "D376": "DUALCHI",
    "D377": "DUBINO",
    "M300": "DUE CARRARE",
    "D379": "DUEVILLE",
    "D380": "DUGENTA",
    "D383": "DUINO-AURISINA",
    "D384": "DUMENZA",
    "D385": "DUNO",
    "D386": "DURAZZANO",
    "C772": "DURONIA",
    "D388": "DUSINO SAN MICHELE",
    "D390": "EBOLI",
    "D391": "EDOLO",
    "D392": "EGNA .NEUMARKT.",
    "D394": "ELICE",
    "D395": "ELINI",
    "D398": "ELLO",
    "D399": "ELMAS",
    "D401": "ELVA",
    "D402": "EMARESE",
    "D403": "EMPOLI",
    "D406": "ENDINE GAIANO",
    "D407": "ENEGO",
    "D408": "ENEMONZO",
    "C342": "ENNA",
    "D410": "ENTRACQUE",
    "D411": "ENTRATICO",
    "D412": "ENVIE",
    "D414": "EPISCOPIA",
    "D415": "ERACLEA",
    "D416": "ERBA",
    "D419": "ERBE'",
    "D420": "ERBEZZO",
    "D421": "ERBUSCO",
    "D422": "ERCHIE",
    "H243": "ERCOLANO",
    "D423": "ERICE",
    "D424": "ERLI",
    "D426": "ERTO E CASSO",
    "M292": "ERULA",
    "D428": "ERVE",
    "D429": "ESANATOGLIA",
    "D430": "ESCALAPLANO",
    "D431": "ESCOLCA",
    "D434": "ESINE",
    "D436": "ESINO LARIO",
    "D440": "ESPERIA",
    "D441": "ESPORLATU",
    "D442": "ESTE",
    "D443": "ESTERZILI",
    "D444": "ETROUBLES",
    "D445": "EUPILIO",
    "D433": "EXILLES",
    "D447": "FABBRICA CURONE",
    "D449": "FABBRICHE DI VALLICO",
    "M319": "FABBRICHE DI VERGEMOLI",
    "D450": "FABBRICO",
    "D451": "FABRIANO",
    "D452": "FABRICA DI ROMA",
    "D453": "FABRIZIA",
    "D454": "FABRO",
    "D455": "FAEDIS",
    "D457": "FAEDO",
    "D456": "FAEDO VALTELLINO",
    "D458": "FAENZA",
    "D459": "FAETO",
    "D461": "FAGAGNA",
    "D462": "FAGGETO LARIO",
    "D463": "FAGGIANO",
    "D465": "FAGNANO ALTO",
    "D464": "FAGNANO CASTELLO",
    "D467": "FAGNANO OLONA",
    "D468": "FAI DELLA PAGANELLA",
    "D469": "FAICCHIO",
    "D470": "FALCADE",
    "D471": "FALCIANO DEL MASSICO",
    "D473": "FALCONARA ALBANESE",
    "D472": "FALCONARA MARITTIMA",
    "D474": "FALCONE",
    "D475": "FALERIA",
    "D476": "FALERNA",
    "D477": "FALERONE",
    "D480": "FALLO",
    "D481": "FALMENTA",
    "D482": "FALOPPIO",
    "D483": "FALVATERRA",
    "D484": "FALZES .PFALZEN.",
    "D486": "FANANO",
    "D487": "FANNA",
    "D488": "FANO",
    "D489": "FANO ADRIANO",
    "D494": "FARA FILIORUM PETRI",
    "D490": "FARA GERA D'ADDA",
    "D493": "FARA IN SABINA",
    "D492": "FARA NOVARESE",
    "D491": "FARA OLIVANA CON SOLA",
    "D495": "FARA SAN MARTINO",
    "D496": "FARA VICENTINO",
    "D497": "FARDELLA",
    "D499": "FARIGLIANO",
    "D501": "FARINDOLA",
    "D502": "FARINI",
    "D503": "FARNESE",
    "D506": "FARRA D'ALPAGO",
    "D505": "FARRA DI SOLIGO",
    "D504": "FARRA D'ISONZO",
    "D508": "FASANO",
    "D509": "FASCIA",
    "D510": "FAUGLIA",
    "D511": "FAULE",
    "D512": "FAVALE DI MALVARO",
    "D514": "FAVARA",
    "D516": "FAVER",
    "D518": "FAVIGNANA",
    "D520": "FAVRIA",
    "D523": "FEISOGLIO",
    "D524": "FELETTO",
    "D526": "FELINO",
    "D527": "FELITTO",
    "D528": "FELIZZANO",
    "D529": "FELONICA",
    "D530": "FELTRE",
    "D531": "FENEGRO'",
    "D532": "FENESTRELLE",
    "D537": "FENIS",
    "D538": "FERENTILLO",
    "D539": "FERENTINO",
    "D540": "FERLA",
    "D541": "FERMIGNANO",
    "D542": "FERMO",
    "D543": "FERNO",
    "D544": "FEROLETO ANTICO",
    "D545": "FEROLETO DELLA CHIESA",
    "D547": "FERRANDINA",
    "D548": "FERRARA",
    "D549": "FERRARA DI MONTE BALDO",
    "D550": "FERRAZZANO",
    "D551": "FERRERA DI VARESE",
    "D552": "FERRERA ERBOGNONE",
    "D554": "FERRERE",
    "D555": "FERRIERE",
    "D557": "FERRUZZANO",
    "D560": "FIAMIGNANO",
    "D562": "FIANO",
    "D561": "FIANO ROMANO",
    "D564": "FIASTRA",
    "D565": "FIAVE'",
    "D567": "FICARAZZI",
    "D568": "FICAROLO",
    "D569": "FICARRA",
    "D570": "FICULLE",
    "B034": "FIDENZA",
    "D571": "FIE' ALLO SCILIAR .VOELS AM SCHLERN.",
    "D572": "FIERA DI PRIMIERO",
    "D573": "FIEROZZO",
    "D574": "FIESCO",
    "D575": "FIESOLE",
    "D576": "FIESSE",
    "D578": "FIESSO D'ARTICO",
    "D577": "FIESSO UMBERTIANO",
    "D579": "FIGINO SERENZA",
    "M321": "FIGLINE E INCISA VALDARNO",
    "D583": "FIGLINE VALDARNO",
    "D582": "FIGLINE VEGLIATURO",
    "D586": "FILACCIANO",
    "D587": "FILADELFIA",
    "D588": "FILAGO",
    "D589": "FILANDARI",
    "D590": "FILATTIERA",
    "D591": "FILETTINO",
    "D592": "FILETTO",
    "D593": "FILIANO",
    "D594": "FILIGHERA",
    "D595": "FILIGNANO",
    "D596": "FILOGASO",
    "D597": "FILOTTRANO",
    "D599": "FINALE EMILIA",
    "D600": "FINALE LIGURE",
    "D604": "FINO DEL MONTE",
    "D605": "FINO MORNASCO",
    "D606": "FIORANO AL SERIO",
    "D608": "FIORANO CANAVESE",
    "D607": "FIORANO MODENESE",
    "D609": "FIORDIMONTE",
    "D611": "FIORENZUOLA D'ARDA",
    "D612": "FIRENZE",
    "D613": "FIRENZUOLA",
    "D614": "FIRMO",
    "M323": "FISCAGLIA",
    "D615": "FISCIANO",
    "A310": "FIUGGI",
    "D617": "FIUMALBO",
    "D619": "FIUMARA",
    "D621": "FIUME VENETO",
    "D622": "FIUMEDINISI",
    "D624": "FIUMEFREDDO BRUZIO",
    "D623": "FIUMEFREDDO DI SICILIA",
    "D627": "FIUMICELLO",
    "M297": "FIUMICINO",
    "D628": "FIUMINATA",
    "D629": "FIVIZZANO",
    "D630": "FLAIBANO",
    "D631": "FLAVON",
    "D634": "FLERO",
    "D635": "FLORESTA",
    "D636": "FLORIDIA",
    "D637": "FLORINAS",
    "D638": "FLUMERI",
    "D639": "FLUMINIMAGGIORE",
    "D640": "FLUSSIO",
    "D641": "FOBELLO",
    "D643": "FOGGIA",
    "D644": "FOGLIANISE",
    "D645": "FOGLIANO REDIPUGLIA",
    "D646": "FOGLIZZO",
    "D649": "FOIANO DELLA CHIANA",
    "D650": "FOIANO DI VAL FORTORE",
    "D651": "FOLGARIA",
    "D652": "FOLIGNANO",
    "D653": "FOLIGNO",
    "D654": "FOLLINA",
    "D655": "FOLLO",
    "D656": "FOLLONICA",
    "D660": "FOMBIO",
    "D661": "FONDACHELLI-FANTINA",
    "D662": "FONDI",
    "D663": "FONDO",
    "D665": "FONNI",
    "D666": "FONTAINEMORE",
    "D667": "FONTANA LIRI",
    "D670": "FONTANAFREDDA",
    "D671": "FONTANAROSA",
    "D668": "FONTANELICE",
    "D672": "FONTANELLA",
    "D673": "FONTANELLATO",
    "D674": "FONTANELLE",
    "D675": "FONTANETO D'AGOGNA",
    "D676": "FONTANETTO PO",
    "D677": "FONTANIGORDA",
    "D678": "FONTANILE",
    "D679": "FONTANIVA",
    "D680": "FONTE",
    "M309": "FONTE NUOVA",
    "D681": "FONTECCHIO",
    "D682": "FONTECHIARI",
    "D683": "FONTEGRECA",
    "D684": "FONTENO",
    "D685": "FONTEVIVO",
    "D686": "FONZASO",
    "D688": "FOPPOLO",
    "D689": "FORANO",
    "D691": "FORCE",
    "D693": "FORCHIA",
    "D694": "FORCOLA",
    "D695": "FORDONGIANUS",
    "D696": "FORENZA",
    "D697": "FORESTO SPARSO",
    "D700": "FORGARIA NEL FRIULI",
    "D701": "FORINO",
    "D702": "FORIO",
    "D704": "FORLI'",
    "D703": "FORLI' DEL SANNIO",
    "D705": "FORLIMPOPOLI",
    "D706": "FORMAZZA",
    "D707": "FORMELLO",
    "D708": "FORMIA",
    "D709": "FORMICOLA",
    "D710": "FORMIGARA",
    "D711": "FORMIGINE",
    "D712": "FORMIGLIANA",
    "D713": "FORMIGNANA",
    "D714": "FORNACE",
    "D715": "FORNELLI",
    "D718": "FORNI AVOLTRI",
    "D719": "FORNI DI SOPRA",
    "D720": "FORNI DI SOTTO",
    "D725": "FORNO CANAVESE",
    "D726": "FORNO DI ZOLDO",
    "D728": "FORNOVO DI TARO",
    "D727": "FORNOVO SAN GIOVANNI",
    "D730": "FORTE DEI MARMI",
    "D731": "FORTEZZA .FRANZENSFESTE.",
    "D732": "FORTUNAGO",
    "D733": "FORZA D'AGRO'",
    "D734": "FOSCIANDORA",
    "D735": "FOSDINOVO",
    "D736": "FOSSA",
    "D738": "FOSSACESIA",
    "D740": "FOSSALTA DI PIAVE",
    "D741": "FOSSALTA DI PORTOGRUARO",
    "D737": "FOSSALTO",
    "D742": "FOSSANO",
    "D745": "FOSSATO DI VICO",
    "D744": "FOSSATO SERRALTA",
    "D748": "FOSSO'",
    "D749": "FOSSOMBRONE",
    "D750": "FOZA",
    "D751": "FRABOSA SOPRANA",
    "D752": "FRABOSA SOTTANA",
    "D559": "FRACONALTO",
    "D754": "FRAGAGNANO",
    "D755": "FRAGNETO L'ABATE",
    "D756": "FRAGNETO MONFORTE",
    "D757": "FRAINE",
    "D758": "FRAMURA",
    "D763": "FRANCAVILLA AL MARE",
    "D762": "FRANCAVILLA ANGITOLA",
    "D759": "FRANCAVILLA BISIO",
    "D760": "FRANCAVILLA D'ETE",
    "D765": "FRANCAVILLA DI SICILIA",
    "D761": "FRANCAVILLA FONTANA",
    "D766": "FRANCAVILLA IN SINNI",
    "D764": "FRANCAVILLA MARITTIMA",
    "D767": "FRANCICA",
    "D768": "FRANCOFONTE",
    "D769": "FRANCOLISE",
    "D770": "FRASCARO",
    "D771": "FRASCAROLO",
    "D773": "FRASCATI",
    "D774": "FRASCINETO",
    "D775": "FRASSILONGO",
    "D776": "FRASSINELLE POLESINE",
    "D777": "FRASSINELLO MONFERRATO",
    "D780": "FRASSINETO PO",
    "D781": "FRASSINETTO",
    "D782": "FRASSINO",
    "D783": "FRASSINORO",
    "D785": "FRASSO SABINO",
    "D784": "FRASSO TELESINO",
    "D788": "FRATTA POLESINE",
    "D787": "FRATTA TODINA",
    "D789": "FRATTAMAGGIORE",
    "D790": "FRATTAMINORE",
    "D791": "FRATTE ROSA",
    "D793": "FRAZZANO'",
    "D794": "FREGONA",
    "D796": "FRESAGRANDINARIA",
    "D797": "FRESONARA",
    "D798": "FRIGENTO",
    "D799": "FRIGNANO",
    "D802": "FRINCO",
    "D803": "FRISA",
    "D804": "FRISANCO",
    "D805": "FRONT",
    "D807": "FRONTINO",
    "D808": "FRONTONE",
    "D810": "FROSINONE",
    "D811": "FROSOLONE",
    "D812": "FROSSASCO",
    "D813": "FRUGAROLO",
    "D814": "FUBINE",
    "D815": "FUCECCHIO",
    "D817": "FUIPIANO VALLE IMAGNA",
    "D818": "FUMANE",
    "D819": "FUMONE",
    "D821": "FUNES .VILLNOESS.",
    "D823": "FURCI",
    "D824": "FURCI SICULO",
    "D825": "FURNARI",
    "D826": "FURORE",
    "D827": "FURTEI",
    "D828": "FUSCALDO",
    "D829": "FUSIGNANO",
    "D830": "FUSINE",
    "D832": "FUTANI",
    "D834": "GABBIONETA BINANUOVA",
    "D835": "GABIANO",
    "D836": "GABICCE MARE",
    "D839": "GABY",
    "D841": "GADESCO PIEVE DELMONA",
    "D842": "GADONI",
    "D843": "GAETA",
    "D844": "GAGGI",
    "D845": "GAGGIANO",
    "D847": "GAGGIO MONTANO",
    "D848": "GAGLIANICO",
    "D850": "GAGLIANO ATERNO",
    "D849": "GAGLIANO CASTELFERRATO",
    "D851": "GAGLIANO DEL CAPO",
    "D852": "GAGLIATO",
    "D853": "GAGLIOLE",
    "D854": "GAIARINE",
    "D855": "GAIBA",
    "D856": "GAIOLA",
    "D858": "GAIOLE IN CHIANTI",
    "D859": "GAIRO",
    "D860": "GAIS .GAIS.",
    "D861": "GALATI MAMERTINO",
    "D862": "GALATINA",
    "D863": "GALATONE",
    "D864": "GALATRO",
    "D865": "GALBIATE",
    "D867": "GALEATA",
    "D868": "GALGAGNANO",
    "D869": "GALLARATE",
    "D870": "GALLESE",
    "D872": "GALLIATE",
    "D871": "GALLIATE LOMBARDO",
    "D873": "GALLIAVOLA",
    "D874": "GALLICANO",
    "D875": "GALLICANO NEL LAZIO",
    "D876": "GALLICCHIO",
    "D878": "GALLIERA",
    "D879": "GALLIERA VENETA",
    "D881": "GALLINARO",
    "D882": "GALLIO",
    "D883": "GALLIPOLI",
    "D884": "GALLO MATESE",
    "D885": "GALLODORO",
    "D886": "GALLUCCIO",
    "D888": "GALTELLI'",
    "D889": "GALZIGNANO TERME",
    "D890": "GAMALERO",
    "D891": "GAMBARA",
    "D892": "GAMBARANA",
    "D894": "GAMBASCA",
    "D895": "GAMBASSI TERME",
    "D896": "GAMBATESA",
    "D897": "GAMBELLARA",
    "D898": "GAMBERALE",
    "D899": "GAMBETTOLA",
    "D901": "GAMBOLO'",
    "D902": "GAMBUGLIANO",
    "D903": "GANDELLINO",
    "D905": "GANDINO",
    "D906": "GANDOSSO",
    "D907": "GANGI",
    "D909": "GARAGUSO",
    "D910": "GARBAGNA",
    "D911": "GARBAGNA NOVARESE",
    "D912": "GARBAGNATE MILANESE",
    "D913": "GARBAGNATE MONASTERO",
    "D915": "GARDA",
    "D917": "GARDONE RIVIERA",
    "D918": "GARDONE VAL TROMPIA",
    "D920": "GARESSIO",
    "D921": "GARGALLO",
    "D923": "GARGAZZONE .GARGAZON.",
    "D924": "GARGNANO",
    "D925": "GARLASCO",
    "D926": "GARLATE",
    "D927": "GARLENDA",
    "D928": "GARNIGA TERME",
    "D930": "GARZENO",
    "D931": "GARZIGLIANA",
    "D932": "GASPERINA",
    "D933": "GASSINO TORINESE",
    "D934": "GATTATICO",
    "D935": "GATTEO",
    "D937": "GATTICO",
    "D938": "GATTINARA",
    "D940": "GAVARDO",
    "D941": "GAVAZZANA",
    "D942": "GAVELLO",
    "D943": "GAVERINA TERME",
    "D944": "GAVI",
    "D945": "GAVIGNANO",
    "D946": "GAVIRATE",
    "D947": "GAVOI",
    "D948": "GAVORRANO",
    "D949": "GAZOLDO DEGLI IPPOLITI",
    "D951": "GAZZADA SCHIANNO",
    "D952": "GAZZANIGA",
    "D956": "GAZZO",
    "D957": "GAZZO VERONESE",
    "D958": "GAZZOLA",
    "D959": "GAZZUOLO",
    "D960": "GELA",
    "D961": "GEMMANO",
    "D962": "GEMONA DEL FRIULI",
    "D963": "GEMONIO",
    "D964": "GENAZZANO",
    "D965": "GENGA",
    "D966": "GENIVOLTA",
    "D967": "GENOLA",
    "D968": "GENONI",
    "D969": "GENOVA",
    "D970": "GENURI",
    "D971": "GENZANO DI LUCANIA",
    "D972": "GENZANO DI ROMA",
    "D973": "GENZONE",
    "D974": "GERA LARIO",
    "D975": "GERACE",
    "D977": "GERACI SICULO",
    "D978": "GERANO",
    "D980": "GERENZAGO",
    "D981": "GERENZANO",
    "D982": "GERGEI",
    "D983": "GERMAGNANO",
    "D984": "GERMAGNO",
    "D986": "GERMASINO",
    "D987": "GERMIGNAGA",
    "D988": "GEROCARNE",
    "D990": "GEROLA ALTA",
    "D991": "GEROSA",
    "D993": "GERRE DE' CAPRIOLI",
    "D994": "GESICO",
    "D995": "GESSATE",
    "D996": "GESSOPALENA",
    "D997": "GESTURI",
    "D998": "GESUALDO",
    "D999": "GHEDI",
    "E001": "GHEMME",
    "E003": "GHIFFA",
    "E004": "GHILARZA",
    "E006": "GHISALBA",
    "E007": "GHISLARENGO",
    "E008": "GIACCIANO CON BARUCHELLA",
    "E009": "GIAGLIONE",
    "E010": "GIANICO",
    "E012": "GIANO DELL'UMBRIA",
    "E011": "GIANO VETUSTO",
    "E013": "GIARDINELLO",
    "E014": "GIARDINI-NAXOS",
    "E015": "GIAROLE",
    "E016": "GIARRATANA",
    "E017": "GIARRE",
    "E019": "GIAVE",
    "E020": "GIAVENO",
    "E021": "GIAVERA DEL MONTELLO",
    "E022": "GIBA",
    "E023": "GIBELLINA",
    "E024": "GIFFLENGA",
    "E025": "GIFFONE",
    "E026": "GIFFONI SEI CASALI",
    "E027": "GIFFONI VALLE PIANA",
    "E028": "GIGNESE",
    "E029": "GIGNOD",
    "E030": "GILDONE",
    "E031": "GIMIGLIANO",
    "E033": "GINESTRA",
    "E034": "GINESTRA DEGLI SCHIAVONI",
    "E036": "GINOSA",
    "E037": "GIOI",
    "E040": "GIOIA DEI MARSI",
    "E038": "GIOIA DEL COLLE",
    "E039": "GIOIA SANNITICA",
    "E041": "GIOIA TAURO",
    "E044": "GIOIOSA IONICA",
    "E043": "GIOIOSA MAREA",
    "E045": "GIOVE",
    "E047": "GIOVINAZZO",
    "E048": "GIOVO",
    "E049": "GIRASOLE",
    "E050": "GIRIFALCO",
    "E051": "GIRONICO",
    "E052": "GISSI",
    "E053": "GIUGGIANELLO",
    "E054": "GIUGLIANO IN CAMPANIA",
    "E055": "GIULIANA",
    "E057": "GIULIANO DI ROMA",
    "E056": "GIULIANO TEATINO",
    "E058": "GIULIANOVA",
    "E059": "GIUNCUGNANO",
    "E060": "GIUNGANO",
    "E061": "GIURDIGNANO",
    "E062": "GIUSSAGO",
    "E063": "GIUSSANO",
    "E064": "GIUSTENICE",
    "E065": "GIUSTINO",
    "E066": "GIUSVALLA",
    "E067": "GIVOLETTO",
    "E068": "GIZZERIA",
    "E069": "GLORENZA .GLURNS.",
    "E071": "GODEGA DI SANT'URBANO",
    "E072": "GODIASCO",
    "E074": "GODRANO",
    "E078": "GOITO",
    "E079": "GOLASECCA",
    "E081": "GOLFERENZO",
    "M274": "GOLFO ARANCI",
    "E082": "GOMBITO",
    "E083": "GONARS",
    "E084": "GONI",
    "E086": "GONNESA",
    "E087": "GONNOSCODINA",
    "E085": "GONNOSFANADIGA",
    "D585": "GONNOSNO'",
    "E088": "GONNOSTRAMATZA",
    "E089": "GONZAGA",
    "E090": "GORDONA",
    "E091": "GORGA",
    "E092": "GORGO AL MONTICANO",
    "E093": "GORGOGLIONE",
    "E094": "GORGONZOLA",
    "E096": "GORIANO SICOLI",
    "E098": "GORIZIA",
    "E101": "GORLA MAGGIORE",
    "E102": "GORLA MINORE",
    "E100": "GORLAGO",
    "E103": "GORLE",
    "E104": "GORNATE-OLONA",
    "E106": "GORNO",
    "E107": "GORO",
    "E109": "GORRETO",
    "E111": "GORZEGNO",
    "E113": "GOSALDO",
    "E114": "GOSSOLENGO",
    "E115": "GOTTASECCA",
    "E116": "GOTTOLENGO",
    "E118": "GOVONE",
    "E120": "GOZZANO",
    "E122": "GRADARA",
    "E124": "GRADISCA D'ISONZO",
    "E125": "GRADO",
    "E126": "GRADOLI",
    "E127": "GRAFFIGNANA",
    "E128": "GRAFFIGNANO",
    "E130": "GRAGLIA",
    "E131": "GRAGNANO",
    "E132": "GRAGNANO TREBBIENSE",
    "E133": "GRAMMICHELE",
    "E134": "GRANA",
    "E135": "GRANAGLIONE",
    "E136": "GRANAROLO DELL'EMILIA",
    "E138": "GRANCONA",
    "E139": "GRANDATE",
    "E141": "GRANDOLA ED UNITI",
    "E142": "GRANITI",
    "E143": "GRANOZZO CON MONTICELLO",
    "E144": "GRANTOLA",
    "E145": "GRANTORTO",
    "E146": "GRANZE",
    "E147": "GRASSANO",
    "E148": "GRASSOBBIO",
    "E149": "GRATTERI",
    "E150": "GRAUNO",
    "E151": "GRAVEDONA",
    "M315": "GRAVEDONA ED UNITI",
    "E152": "GRAVELLONA LOMELLINA",
    "E153": "GRAVELLONA TOCE",
    "E154": "GRAVERE",
    "E156": "GRAVINA DI CATANIA",
    "E155": "GRAVINA IN PUGLIA",
    "E158": "GRAZZANISE",
    "E159": "GRAZZANO BADOGLIO",
    "E160": "GRECCIO",
    "E161": "GRECI",
    "E163": "GREGGIO",
    "E164": "GREMIASCO",
    "E165": "GRESSAN",
    "E167": "GRESSONEY-LA-TRINITE'",
    "E168": "GRESSONEY-SAINT-JEAN",
    "E169": "GREVE IN CHIANTI",
    "E170": "GREZZAGO",
    "E171": "GREZZANA",
    "E172": "GRIANTE",
    "E173": "GRICIGNANO DI AVERSA",
    "E177": "GRIGNASCO",
    "E178": "GRIGNO",
    "E179": "GRIMACCO",
    "E180": "GRIMALDI",
    "E182": "GRINZANE CAVOUR",
    "E184": "GRISIGNANO DI ZOCCO",
    "E185": "GRISOLIA",
    "E187": "GRIZZANA MORANDI",
    "E188": "GROGNARDO",
    "E189": "GROMO",
    "E191": "GRONDONA",
    "E192": "GRONE",
    "E193": "GRONTARDO",
    "E195": "GROPELLO CAIROLI",
    "E196": "GROPPARELLO",
    "E199": "GROSCAVALLO",
    "E200": "GROSIO",
    "E201": "GROSOTTO",
    "E202": "GROSSETO",
    "E203": "GROSSO",
    "E204": "GROTTAFERRATA",
    "E205": "GROTTAGLIE",
    "E206": "GROTTAMINARDA",
    "E207": "GROTTAMMARE",
    "E208": "GROTTAZZOLINA",
    "E209": "GROTTE",
    "E210": "GROTTE DI CASTRO",
    "E212": "GROTTERIA",
    "E213": "GROTTOLE",
    "E214": "GROTTOLELLA",
    "E215": "GRUARO",
    "E216": "GRUGLIASCO",
    "E217": "GRUMELLO CREMONESE ED UNITI",
    "E219": "GRUMELLO DEL MONTE",
    "E221": "GRUMENTO NOVA",
    "E222": "GRUMES",
    "E223": "GRUMO APPULA",
    "E224": "GRUMO NEVANO",
    "E226": "GRUMOLO DELLE ABBADESSE",
    "E227": "GUAGNANO",
    "E228": "GUALDO",
    "E229": "GUALDO CATTANEO",
    "E230": "GUALDO TADINO",
    "E232": "GUALTIERI",
    "E233": "GUALTIERI SICAMINO'",
    "E234": "GUAMAGGIORE",
    "E235": "GUANZATE",
    "E236": "GUARCINO",
    "E240": "GUARDA VENETA",
    "E237": "GUARDABOSONE",
    "E238": "GUARDAMIGLIO",
    "E239": "GUARDAVALLE",
    "E241": "GUARDEA",
    "E245": "GUARDIA LOMBARDI",
    "E246": "GUARDIA PERTICARA",
    "E242": "GUARDIA PIEMONTESE",
    "E249": "GUARDIA SANFRAMONDI",
    "E243": "GUARDIAGRELE",
    "E244": "GUARDIALFIERA",
    "E248": "GUARDIAREGIA",
    "E250": "GUARDISTALLO",
    "E251": "GUARENE",
    "E252": "GUASILA",
    "E253": "GUASTALLA",
    "E255": "GUAZZORA",
    "E256": "GUBBIO",
    "E258": "GUDO VISCONTI",
    "E259": "GUGLIONESI",
    "E261": "GUIDIZZOLO",
    "E263": "GUIDONIA MONTECELIO",
    "E264": "GUIGLIA",
    "E266": "GUILMI",
    "E269": "GURRO",
    "E270": "GUSPINI",
    "E271": "GUSSAGO",
    "E272": "GUSSOLA",
    "E273": "HONE",
    "E280": "IDRO",
    "E281": "IGLESIAS",
    "E282": "IGLIANO",
    "E283": "ILBONO",
    "E284": "ILLASI",
    "E285": "ILLORAI",
    "E287": "IMBERSAGO",
    "E288": "IMER",
    "E289": "IMOLA",
    "E290": "IMPERIA",
    "E291": "IMPRUNETA",
    "E292": "INARZO",
    "E296": "INCISA IN VAL D'ARNO",
    "E295": "INCISA SCAPACCINO",
    "E297": "INCUDINE",
    "E299": "INDUNO OLONA",
    "E301": "INGRIA",
    "E304": "INTRAGNA",
    "E305": "INTROBIO",
    "E306": "INTROD",
    "E307": "INTRODACQUA",
    "E308": "INTROZZO",
    "E309": "INVERIGO",
    "E310": "INVERNO E MONTELEONE",
    "E311": "INVERSO PINASCA",
    "E313": "INVERUNO",
    "E314": "INVORIO",
    "E317": "INZAGO",
    "E321": "IONADI",
    "E323": "IRGOLI",
    "E325": "IRMA",
    "E326": "IRSINA",
    "E327": "ISASCA",
    "E328": "ISCA SULLO IONIO",
    "E329": "ISCHIA",
    "E330": "ISCHIA DI CASTRO",
    "E332": "ISCHITELLA",
    "E333": "ISEO",
    "E334": "ISERA",
    "E335": "ISERNIA",
    "E336": "ISILI",
    "E337": "ISNELLO",
    "E338": "ISOLA D'ASTI",
    "E341": "ISOLA DEL CANTONE",
    "E348": "ISOLA DEL GIGLIO",
    "E343": "ISOLA DEL GRAN SASSO D'ITALIA",
    "E340": "ISOLA DEL LIRI",
    "E351": "ISOLA DEL PIANO",
    "E349": "ISOLA DELLA SCALA",
    "E350": "ISOLA DELLE FEMMINE",
    "E339": "ISOLA DI CAPO RIZZUTO",
    "E353": "ISOLA DI FONDRA",
    "E356": "ISOLA DOVARESE",
    "E358": "ISOLA RIZZA",
    "E360": "ISOLA SANT'ANTONIO",
    "E354": "ISOLA VICENTINA",
    "E345": "ISOLABELLA",
    "E346": "ISOLABONA",
    "E363": "ISOLE TREMITI",
    "E364": "ISORELLA",
    "E365": "ISPANI",
    "E366": "ISPICA",
    "E367": "ISPRA",
    "E368": "ISSIGLIO",
    "E369": "ISSIME",
    "E370": "ISSO",
    "E371": "ISSOGNE",
    "E373": "ISTRANA",
    "E374": "ITALA",
    "E375": "ITRI",
    "E376": "ITTIREDDU",
    "E377": "ITTIRI",
    "E378": "IVANO FRACENA",
    "E379": "IVREA",
    "E380": "IZANO",
    "E274": "JACURSO",
    "E381": "JELSI",
    "E382": "JENNE",
    "E386": "JERAGO CON ORAGO",
    "E387": "JERZU",
    "E388": "JESI",
    "C388": "JESOLO",
    "E320": "JOLANDA DI SAVOIA",
    "E389": "JOPPOLO",
    "E390": "JOPPOLO GIANCAXIO",
    "E391": "JOVENCAN",
    "E394": "LA CASSA",
    "E423": "LA LOGGIA",
    "E425": "LA MADDALENA",
    "A308": "LA MAGDELEINE",
    "E430": "LA MORRA",
    "E458": "LA SALLE",
    "E463": "LA SPEZIA",
    "E470": "LA THUILE",
    "E491": "LA VALLE .WENGEN.",
    "E490": "LA VALLE AGORDINA",
    "M348": "LA VALLETTA BRIANZA",
    "E392": "LABICO",
    "E393": "LABRO",
    "E395": "LACCHIARELLA",
    "E396": "LACCO AMENO",
    "E397": "LACEDONIA",
    "E398": "LACES .LATSCH.",
    "E400": "LACONI",
    "M212": "LADISPOLI",
    "E401": "LAERRU",
    "E402": "LAGANADI",
    "E403": "LAGHI",
    "E405": "LAGLIO",
    "E406": "LAGNASCO",
    "E407": "LAGO",
    "E409": "LAGONEGRO",
    "E410": "LAGOSANTO",
    "E412": "LAGUNDO .ALGUND.",
    "E414": "LAIGUEGLIA",
    "E415": "LAINATE",
    "E416": "LAINO",
    "E417": "LAINO BORGO",
    "E419": "LAINO CASTELLO",
    "E420": "LAION .LAJEN.",
    "E421": "LAIVES .LEIFERS.",
    "E413": "LAJATICO",
    "E422": "LALLIO",
    "E424": "LAMA DEI PELIGNI",
    "E426": "LAMA MOCOGNO",
    "E428": "LAMBRUGO",
    "M208": "LAMEZIA TERME",
    "E429": "LAMON",
    "E431": "LAMPEDUSA E LINOSA",
    "E432": "LAMPORECCHIO",
    "E433": "LAMPORO",
    "E434": "LANA .LANA.",
    "E435": "LANCIANO",
    "E436": "LANDIONA",
    "E437": "LANDRIANO",
    "E438": "LANGHIRANO",
    "E439": "LANGOSCO",
    "E441": "LANUSEI",
    "C767": "LANUVIO",
    "E443": "LANZADA",
    "E444": "LANZO D'INTELVI",
    "E445": "LANZO TORINESE",
    "E447": "LAPEDONA",
    "E448": "LAPIO",
    "E450": "LAPPANO",
    "A345": "L'AQUILA",
    "E451": "LARCIANO",
    "E452": "LARDARO",
    "E454": "LARDIRAGO",
    "E455": "LARI",
    "M207": "LARIANO",
    "E456": "LARINO",
    "E464": "LAS PLASSAS",
    "E457": "LASA .LAAS.",
    "E459": "LASCARI",
    "E461": "LASINO",
    "E462": "LASNIGO",
    "E465": "LASTEBASSE",
    "E466": "LASTRA A SIGNA",
    "E467": "LATERA",
    "E468": "LATERINA",
    "E469": "LATERZA",
    "E471": "LATIANO",
    "E472": "LATINA",
    "E473": "LATISANA",
    "E474": "LATRONICO",
    "E475": "LATTARICO",
    "E476": "LAUCO",
    "E480": "LAUREANA CILENTO",
    "E479": "LAUREANA DI BORRELLO",
    "E481": "LAUREGNO .LAUREIN.",
    "E482": "LAURENZANA",
    "E483": "LAURIA",
    "E484": "LAURIANO",
    "E485": "LAURINO",
    "E486": "LAURITO",
    "E487": "LAURO",
    "E488": "LAVAGNA",
    "E489": "LAVAGNO",
    "E492": "LAVARONE",
    "E493": "LAVELLO",
    "E494": "LAVENA PONTE TRESA",
    "E496": "LAVENO MOMBELLO",
    "E497": "LAVENONE",
    "E498": "LAVIANO",
    "E500": "LAVIS",
    "E502": "LAZISE",
    "E504": "LAZZATE",
    "E506": "LECCE",
    "E505": "LECCE NEI MARSI",
    "E507": "LECCO",
    "M313": "LEDRO",
    "E509": "LEFFE",
    "E510": "LEGGIUNO",
    "E512": "LEGNAGO",
    "E514": "LEGNANO",
    "E515": "LEGNARO",
    "E517": "LEI",
    "E518": "LEINI",
    "E519": "LEIVI",
    "E520": "LEMIE",
    "E522": "LENDINARA",
    "E523": "LENI",
    "E524": "LENNA",
    "E525": "LENNO",
    "E526": "LENO",
    "E527": "LENOLA",
    "E528": "LENTA",
    "E530": "LENTATE SUL SEVESO",
    "E531": "LENTELLA",
    "C562": "LENTIAI",
    "E532": "LENTINI",
    "E535": "LEONESSA",
    "E536": "LEONFORTE",
    "E537": "LEPORANO",
    "E538": "LEQUILE",
    "E540": "LEQUIO BERRIA",
    "E539": "LEQUIO TANARO",
    "E541": "LERCARA FRIDDI",
    "E542": "LERICI",
    "E543": "LERMA",
    "E544": "LESA",
    "E546": "LESEGNO",
    "E547": "LESIGNANO DE' BAGNI",
    "E549": "LESINA",
    "E550": "LESMO",
    "E551": "LESSOLO",
    "M371": "LESSONA",
    "E552": "LESSONA",
    "E553": "LESTIZZA",
    "E554": "LETINO",
    "E555": "LETOJANNI",
    "E557": "LETTERE",
    "E558": "LETTOMANOPPELLO",
    "E559": "LETTOPALENA",
    "E560": "LEVANTO",
    "E562": "LEVATE",
    "E563": "LEVERANO",
    "E564": "LEVICE",
    "E565": "LEVICO TERME",
    "E566": "LEVONE",
    "E569": "LEZZENO",
    "E570": "LIBERI",
    "E571": "LIBRIZZI",
    "E573": "LICATA",
    "E574": "LICCIANA NARDI",
    "E576": "LICENZA",
    "E578": "LICODIA EUBEA",
    "E581": "LIERNA",
    "E583": "LIGNANA",
    "E584": "LIGNANO SABBIADORO",
    "E585": "LIGONCHIO",
    "E586": "LIGOSULLO",
    "E587": "LILLIANES",
    "E588": "LIMANA",
    "E589": "LIMATOLA",
    "E590": "LIMBADI",
    "E591": "LIMBIATE",
    "E592": "LIMENA",
    "E593": "LIMIDO COMASCO",
    "E594": "LIMINA",
    "E597": "LIMONE PIEMONTE",
    "E596": "LIMONE SUL GARDA",
    "E599": "LIMOSANO",
    "E600": "LINAROLO",
    "E602": "LINGUAGLOSSA",
    "E605": "LIONI",
    "E606": "LIPARI",
    "E607": "LIPOMO",
    "E608": "LIRIO",
    "E610": "LISCATE",
    "E611": "LISCIA",
    "E613": "LISCIANO NICCONE",
    "E614": "LISIGNAGO",
    "E615": "LISIO",
    "E617": "LISSONE",
    "E620": "LIVERI",
    "E621": "LIVIGNO",
    "E622": "LIVINALLONGO DEL COL DI LANA",
    "E623": "LIVO",
    "E624": "LIVO",
    "E625": "LIVORNO",
    "E626": "LIVORNO FERRARIS",
    "E627": "LIVRAGA",
    "E629": "LIZZANELLO",
    "E630": "LIZZANO",
    "A771": "LIZZANO IN BELVEDERE",
    "E632": "LOANO",
    "E633": "LOAZZOLO",
    "E635": "LOCANA",
    "E639": "LOCATE DI TRIULZI",
    "E638": "LOCATE VARESINO",
    "E640": "LOCATELLO",
    "E644": "LOCERI",
    "E645": "LOCOROTONDO",
    "D976": "LOCRI",
    "E646": "LOCULI",
    "E647": "LODE'",
    "E648": "LODI",
    "E651": "LODI VECCHIO",
    "E649": "LODINE",
    "E652": "LODRINO",
    "E654": "LOGRATO",
    "E655": "LOIANO",
    "M275": "LOIRI PORTO SAN PAOLO",
    "E656": "LOMAGNA",
    "E658": "LOMASO",
    "E659": "LOMAZZO",
    "E660": "LOMBARDORE",
    "E661": "LOMBRIASCO",
    "E662": "LOMELLO",
    "E664": "LONA LASES",
    "E665": "LONATE CEPPINO",
    "E666": "LONATE POZZOLO",
    "E667": "LONATO",
    "M312": "LONATO DEL GARDA",
    "E668": "LONDA",
    "E669": "LONGANO",
    "E671": "LONGARE",
    "M342": "LONGARONE",
    "E672": "LONGARONE",
    "E673": "LONGHENA",
    "E674": "LONGI",
    "E675": "LONGIANO",
    "E677": "LONGOBARDI",
    "E678": "LONGOBUCCO",
    "E679": "LONGONE AL SEGRINO",
    "E681": "LONGONE SABINO",
    "E682": "LONIGO",
    "E683": "LORANZE'",
    "E684": "LOREGGIA",
    "E685": "LOREGLIA",
    "E687": "LORENZAGO DI CADORE",
    "E688": "LORENZANA",
    "E689": "LOREO",
    "E690": "LORETO",
    "E691": "LORETO APRUTINO",
    "E692": "LORIA",
    "E693": "LORO CIUFFENNA",
    "E694": "LORO PICENO",
    "E695": "LORSICA",
    "E698": "LOSINE",
    "E700": "LOTZORAI",
    "E704": "LOVERE",
    "E705": "LOVERO",
    "E706": "LOZIO",
    "E707": "LOZZA",
    "E709": "LOZZO ATESTINO",
    "E708": "LOZZO DI CADORE",
    "E711": "LOZZOLO",
    "E712": "LU",
    "E713": "LUBRIANO",
    "E715": "LUCCA",
    "E714": "LUCCA SICULA",
    "E716": "LUCERA",
    "E718": "LUCIGNANO",
    "E719": "LUCINASCO",
    "E722": "LUCITO",
    "E723": "LUCO DEI MARSI",
    "E724": "LUCOLI",
    "E726": "LUGAGNANO VAL D'ARDA",
    "E727": "LUGNACCO",
    "E729": "LUGNANO IN TEVERINA",
    "E730": "LUGO",
    "E731": "LUGO DI VICENZA",
    "E734": "LUINO",
    "E735": "LUISAGO",
    "E736": "LULA",
    "E737": "LUMARZO",
    "E738": "LUMEZZANE",
    "E742": "LUNAMATRONA",
    "E743": "LUNANO",
    "B387": "LUNGAVILLA",
    "E745": "LUNGRO",
    "E746": "LUOGOSANO",
    "E747": "LUOGOSANTO",
    "E748": "LUPARA",
    "E749": "LURAGO D'ERBA",
    "E750": "LURAGO MARINONE",
    "E751": "LURANO",
    "E752": "LURAS",
    "E753": "LURATE CACCIVIO",
    "E754": "LUSCIANO",
    "E757": "LUSERNA",
    "E758": "LUSERNA SAN GIOVANNI",
    "E759": "LUSERNETTA",
    "E760": "LUSEVERA",
    "E761": "LUSIA",
    "E762": "LUSIANA",
    "E763": "LUSIGLIE'",
    "E764": "LUSON .LUESEN.",
    "E767": "LUSTRA",
    "E769": "LUVINATE",
    "E770": "LUZZANA",
    "E772": "LUZZARA",
    "E773": "LUZZI",
    "E775": "MACCAGNO",
    "M339": "MACCAGNO CON PINO E VEDDASCA",
    "E777": "MACCASTORNA",
    "E778": "MACCHIA D'ISERNIA",
    "E780": "MACCHIA VALFORTORE",
    "E779": "MACCHIAGODENA",
    "E782": "MACELLO",
    "E783": "MACERATA",
    "E784": "MACERATA CAMPANIA",
    "E785": "MACERATA FELTRIA",
    "E786": "MACHERIO",
    "E787": "MACLODIO",
    "E788": "MACOMER",
    "E789": "MACRA",
    "E790": "MACUGNAGA",
    "E791": "MADDALONI",
    "E342": "MADESIMO",
    "E793": "MADIGNANO",
    "E794": "MADONE",
    "E795": "MADONNA DEL SASSO",
    "M357": "MADRUZZO",
    "E798": "MAENZA",
    "E799": "MAFALDA",
    "E800": "MAGASA",
    "E801": "MAGENTA",
    "E803": "MAGGIORA",
    "E804": "MAGHERNO",
    "E805": "MAGIONE",
    "E806": "MAGISANO",
    "E809": "MAGLIANO ALFIERI",
    "E808": "MAGLIANO ALPI",
    "E811": "MAGLIANO DE' MARSI",
    "E807": "MAGLIANO DI TENNA",
    "E810": "MAGLIANO IN TOSCANA",
    "E813": "MAGLIANO ROMANO",
    "E812": "MAGLIANO SABINA",
    "E814": "MAGLIANO VETERE",
    "E815": "MAGLIE",
    "E816": "MAGLIOLO",
    "E817": "MAGLIONE",
    "E818": "MAGNACAVALLO",
    "E819": "MAGNAGO",
    "E821": "MAGNANO",
    "E820": "MAGNANO IN RIVIERA",
    "E825": "MAGOMADAS",
    "E829": "MAGRE' SULLA STRADA DEL VINO .MARGREID AN DE.",
    "E830": "MAGREGLIO",
    "E834": "MAIDA",
    "E835": "MAIERA'",
    "E836": "MAIERATO",
    "E837": "MAIOLATI SPONTINI",
    "E838": "MAIOLO",
    "E839": "MAIORI",
    "E840": "MAIRAGO",
    "E841": "MAIRANO",
    "E842": "MAISSANA",
    "E833": "MAJANO",
    "E843": "MALAGNINO",
    "E844": "MALALBERGO",
    "E847": "MALBORGHETTO-VALBRUNA",
    "E848": "MALCESINE",
    "E850": "MALE'",
    "E851": "MALEGNO",
    "E852": "MALEO",
    "E853": "MALESCO",
    "E854": "MALETTO",
    "E855": "MALFA",
    "E856": "MALGESSO",
    "E858": "MALGRATE",
    "E859": "MALITO",
    "E860": "MALLARE",
    "E862": "MALLES VENOSTA .MALS.",
    "E863": "MALNATE",
    "E864": "MALO",
    "E865": "MALONNO",
    "E866": "MALOSCO",
    "E868": "MALTIGNANO",
    "E869": "MALVAGNA",
    "E870": "MALVICINO",
    "E872": "MALVITO",
    "E873": "MAMMOLA",
    "E874": "MAMOIADA",
    "E875": "MANCIANO",
    "E876": "MANDANICI",
    "E877": "MANDAS",
    "E878": "MANDATORICCIO",
    "B632": "MANDELA",
    "E879": "MANDELLO DEL LARIO",
    "E880": "MANDELLO VITTA",
    "E882": "MANDURIA",
    "E883": "MANERBA DEL GARDA",
    "E884": "MANERBIO",
    "E885": "MANFREDONIA",
    "E887": "MANGO",
    "E888": "MANGONE",
    "M283": "MANIACE",
    "E889": "MANIAGO",
    "E891": "MANOCALZATI",
    "E892": "MANOPPELLO",
    "E893": "MANSUE'",
    "E894": "MANTA",
    "E896": "MANTELLO",
    "E897": "MANTOVA",
    "E899": "MANZANO",
    "E900": "MANZIANA",
    "E901": "MAPELLO",
    "E902": "MARA",
    "E903": "MARACALAGONIS",
    "E904": "MARANELLO",
    "E906": "MARANO DI NAPOLI",
    "E911": "MARANO DI VALPOLICELLA",
    "E908": "MARANO EQUO",
    "E910": "MARANO LAGUNARE",
    "E914": "MARANO MARCHESATO",
    "E915": "MARANO PRINCIPATO",
    "E905": "MARANO SUL PANARO",
    "E907": "MARANO TICINO",
    "E912": "MARANO VICENTINO",
    "E917": "MARANZANA",
    "E919": "MARATEA",
    "E921": "MARCALLO CON CASONE",
    "E922": "MARCARIA",
    "E923": "MARCEDUSA",
    "E924": "MARCELLINA",
    "E925": "MARCELLINARA",
    "E927": "MARCETELLI",
    "E928": "MARCHENO",
    "E929": "MARCHIROLO",
    "E930": "MARCIANA",
    "E931": "MARCIANA MARINA",
    "E932": "MARCIANISE",
    "E933": "MARCIANO DELLA CHIANA",
    "E934": "MARCIGNAGO",
    "E936": "MARCON",
    "E938": "MAREBBE .ENNEBERG.",
    "E939": "MARENE",
    "E940": "MARENO DI PIAVE",
    "E941": "MARENTINO",
    "E944": "MARETTO",
    "E945": "MARGARITA",
    "E946": "MARGHERITA DI SAVOIA",
    "E947": "MARGNO",
    "E949": "MARIANA MANTOVANA",
    "E951": "MARIANO COMENSE",
    "E952": "MARIANO DEL FRIULI",
    "E953": "MARIANOPOLI",
    "E954": "MARIGLIANELLA",
    "E955": "MARIGLIANO",
    "E956": "MARINA DI GIOIOSA IONICA",
    "E957": "MARINEO",
    "E958": "MARINO",
    "E959": "MARLENGO .MARLING.",
    "E960": "MARLIANA",
    "E961": "MARMENTINO",
    "E962": "MARMIROLO",
    "E963": "MARMORA",
    "E965": "MARNATE",
    "E967": "MARONE",
    "E968": "MAROPATI",
    "E970": "MAROSTICA",
    "E971": "MARRADI",
    "E972": "MARRUBIU",
    "E973": "MARSAGLIA",
    "E974": "MARSALA",
    "E975": "MARSCIANO",
    "E976": "MARSICO NUOVO",
    "E977": "MARSICOVETERE",
    "E978": "MARTA",
    "E979": "MARTANO",
    "E980": "MARTELLAGO",
    "E981": "MARTELLO .MARTELL.",
    "E982": "MARTIGNACCO",
    "E983": "MARTIGNANA DI PO",
    "E984": "MARTIGNANO",
    "E986": "MARTINA FRANCA",
    "E987": "MARTINENGO",
    "E988": "MARTINIANA PO",
    "E989": "MARTINSICURO",
    "E990": "MARTIRANO",
    "E991": "MARTIRANO LOMBARDO",
    "E992": "MARTIS",
    "E993": "MARTONE",
    "E994": "MARUDO",
    "E995": "MARUGGIO",
    "B689": "MARZABOTTO",
    "E999": "MARZANO",
    "E998": "MARZANO APPIO",
    "E997": "MARZANO DI NOLA",
    "F001": "MARZI",
    "F002": "MARZIO",
    "M270": "MASAINAS",
    "F003": "MASATE",
    "F004": "MASCALI",
    "F005": "MASCALUCIA",
    "F006": "MASCHITO",
    "F007": "MASCIAGO PRIMO",
    "F009": "MASER",
    "F010": "MASERA",
    "F011": "MASERA' DI PADOVA",
    "F012": "MASERADA SUL PIAVE",
    "F013": "MASI",
    "F016": "MASI TORELLO",
    "F015": "MASIO",
    "F017": "MASLIANICO",
    "F019": "MASON VICENTINO",
    "F020": "MASONE",
    "F023": "MASSA",
    "F022": "MASSA D'ALBE",
    "M289": "MASSA DI SOMMA",
    "F025": "MASSA E COZZILE",
    "F021": "MASSA FERMANA",
    "F026": "MASSA FISCAGLIA",
    "F029": "MASSA LOMBARDA",
    "F030": "MASSA LUBRENSE",
    "F032": "MASSA MARITTIMA",
    "F024": "MASSA MARTANA",
    "F027": "MASSAFRA",
    "F028": "MASSALENGO",
    "F033": "MASSANZAGO",
    "F035": "MASSAROSA",
    "F037": "MASSAZZA",
    "F041": "MASSELLO",
    "F042": "MASSERANO",
    "F044": "MASSIGNANO",
    "F045": "MASSIMENO",
    "F046": "MASSIMINO",
    "F047": "MASSINO VISCONTI",
    "F048": "MASSIOLA",
    "F050": "MASULLAS",
    "F051": "MATELICA",
    "F052": "MATERA",
    "F053": "MATHI",
    "F054": "MATINO",
    "F055": "MATRICE",
    "F058": "MATTIE",
    "F059": "MATTINATA",
    "F061": "MAZARA DEL VALLO",
    "F063": "MAZZANO",
    "F064": "MAZZANO ROMANO",
    "F065": "MAZZARINO",
    "F066": "MAZZARRA' SANT'ANDREA",
    "M271": "MAZZARRONE",
    "F067": "MAZZE'",
    "F068": "MAZZIN",
    "F070": "MAZZO DI VALTELLINA",
    "F074": "MEANA DI SUSA",
    "F073": "MEANA SARDO",
    "F078": "MEDA",
    "F080": "MEDE",
    "F081": "MEDEA",
    "F082": "MEDESANO",
    "F083": "MEDICINA",
    "F084": "MEDIGLIA",
    "F085": "MEDOLAGO",
    "F086": "MEDOLE",
    "F087": "MEDOLLA",
    "F088": "MEDUNA DI LIVENZA",
    "F089": "MEDUNO",
    "F091": "MEGLIADINO SAN FIDENZIO",
    "F092": "MEGLIADINO SAN VITALE",
    "F093": "MEINA",
    "F094": "MEL",
    "F095": "MELARA",
    "F096": "MELAZZO",
    "F097": "MELDOLA",
    "F098": "MELE",
    "F100": "MELEGNANO",
    "F101": "MELENDUGNO",
    "F102": "MELETI",
    "F104": "MELFI",
    "F105": "MELICUCCA'",
    "F106": "MELICUCCO",
    "F107": "MELILLI",
    "F108": "MELISSA",
    "F109": "MELISSANO",
    "F111": "MELITO DI NAPOLI",
    "F112": "MELITO DI PORTO SALVO",
    "F110": "MELITO IRPINO",
    "F113": "MELIZZANO",
    "F114": "MELLE",
    "F115": "MELLO",
    "F117": "MELPIGNANO",
    "F118": "MELTINA .MOELTEN.",
    "F119": "MELZO",
    "F120": "MENAGGIO",
    "F121": "MENAROLA",
    "F122": "MENCONICO",
    "F123": "MENDATICA",
    "F125": "MENDICINO",
    "F126": "MENFI",
    "F127": "MENTANA",
    "F130": "MEOLO",
    "F131": "MERANA",
    "F132": "MERANO .MERAN.",
    "F133": "MERATE",
    "F134": "MERCALLO",
    "F135": "MERCATELLO SUL METAURO",
    "F136": "MERCATINO CONCA",
    "F138": "MERCATO SAN SEVERINO",
    "F139": "MERCATO SARACENO",
    "F140": "MERCENASCO",
    "F141": "MERCOGLIANO",
    "F144": "MERETO DI TOMBA",
    "F145": "MERGO",
    "F146": "MERGOZZO",
    "F147": "MERI'",
    "F148": "MERLARA",
    "F149": "MERLINO",
    "F151": "MERONE",
    "F152": "MESAGNE",
    "F153": "MESE",
    "F154": "MESENZANA",
    "F155": "MESERO",
    "F156": "MESOLA",
    "F157": "MESORACA",
    "F158": "MESSINA",
    "F161": "MESTRINO",
    "F162": "META",
    "F164": "MEUGLIANO",
    "F165": "MEZZAGO",
    "F168": "MEZZANA",
    "F170": "MEZZANA BIGLI",
    "F167": "MEZZANA MORTIGLIENGO",
    "F171": "MEZZANA RABATTONE",
    "F172": "MEZZANE DI SOTTO",
    "F173": "MEZZANEGO",
    "F174": "MEZZANI",
    "F175": "MEZZANINO",
    "F176": "MEZZANO",
    "F181": "MEZZEGRA",
    "F182": "MEZZENILE",
    "F183": "MEZZOCORONA",
    "F184": "MEZZOJUSO",
    "F186": "MEZZOLDO",
    "F187": "MEZZOLOMBARDO",
    "F188": "MEZZOMERICO",
    "F189": "MIAGLIANO",
    "F190": "MIANE",
    "F191": "MIASINO",
    "F192": "MIAZZINA",
    "F193": "MICIGLIANO",
    "F194": "MIGGIANO",
    "F196": "MIGLIANICO",
    "F198": "MIGLIARINO",
    "F199": "MIGLIARO",
    "F200": "MIGLIERINA",
    "F201": "MIGLIONICO",
    "F202": "MIGNANEGO",
    "F203": "MIGNANO MONTE LUNGO",
    "F205": "MILANO",
    "F206": "MILAZZO",
    "E618": "MILENA",
    "F207": "MILETO",
    "F208": "MILIS",
    "F209": "MILITELLO IN VAL DI CATANIA",
    "F210": "MILITELLO ROSMARINO",
    "F213": "MILLESIMO",
    "F214": "MILO",
    "F216": "MILZANO",
    "F217": "MINEO",
    "F218": "MINERBE",
    "F219": "MINERBIO",
    "F221": "MINERVINO DI LECCE",
    "F220": "MINERVINO MURGE",
    "F223": "MINORI",
    "F224": "MINTURNO",
    "F225": "MINUCCIANO",
    "F226": "MIOGLIA",
    "F229": "MIRA",
    "F230": "MIRABELLA ECLANO",
    "F231": "MIRABELLA IMBACCARI",
    "F235": "MIRABELLO",
    "F232": "MIRABELLO MONFERRATO",
    "F233": "MIRABELLO SANNITICO",
    "F238": "MIRADOLO TERME",
    "F239": "MIRANDA",
    "F240": "MIRANDOLA",
    "F241": "MIRANO",
    "F242": "MIRTO",
    "F244": "MISANO ADRIATICO",
    "F243": "MISANO DI GERA D'ADDA",
    "F246": "MISILMERI",
    "F247": "MISINTO",
    "F248": "MISSAGLIA",
    "F249": "MISSANELLO",
    "F250": "MISTERBIANCO",
    "F251": "MISTRETTA",
    "F254": "MOASCA",
    "F256": "MOCONESI",
    "F257": "MODENA",
    "F258": "MODICA",
    "F259": "MODIGLIANA",
    "F261": "MODOLO",
    "F262": "MODUGNO",
    "F263": "MOENA",
    "F265": "MOGGIO",
    "F266": "MOGGIO UDINESE",
    "F267": "MOGLIA",
    "F268": "MOGLIANO",
    "F269": "MOGLIANO VENETO",
    "F270": "MOGORELLA",
    "F272": "MOGORO",
    "F274": "MOIANO",
    "F275": "MOIMACCO",
    "F277": "MOIO ALCANTARA",
    "F276": "MOIO DE' CALVI",
    "F278": "MOIO DELLA CIVITELLA",
    "F279": "MOIOLA",
    "F280": "MOLA DI BARI",
    "F281": "MOLARE",
    "F283": "MOLAZZANA",
    "F284": "MOLFETTA",
    "M255": "MOLINA ATERNO",
    "F286": "MOLINA DI LEDRO",
    "F287": "MOLINARA",
    "F288": "MOLINELLA",
    "F290": "MOLINI DI TRIORA",
    "F293": "MOLINO DEI TORTI",
    "F294": "MOLISE",
    "F295": "MOLITERNO",
    "F297": "MOLLIA",
    "F301": "MOLOCHIO",
    "F304": "MOLTENO",
    "F305": "MOLTRASIO",
    "F306": "MOLVENA",
    "F307": "MOLVENO",
    "F308": "MOMBALDONE",
    "F309": "MOMBARCARO",
    "F310": "MOMBAROCCIO",
    "F311": "MOMBARUZZO",
    "F312": "MOMBASIGLIO",
    "F315": "MOMBELLO DI TORINO",
    "F313": "MOMBELLO MONFERRATO",
    "F316": "MOMBERCELLI",
    "F317": "MOMO",
    "F318": "MOMPANTERO",
    "F319": "MOMPEO",
    "F320": "MOMPERONE",
    "F322": "MONACILIONI",
    "F323": "MONALE",
    "F324": "MONASTERACE",
    "F325": "MONASTERO BORMIDA",
    "F327": "MONASTERO DI LANZO",
    "F326": "MONASTERO DI VASCO",
    "F329": "MONASTEROLO CASOTTO",
    "F328": "MONASTEROLO DEL CASTELLO",
    "F330": "MONASTEROLO DI SAVIGLIANO",
    "F332": "MONASTIER DI TREVISO",
    "F333": "MONASTIR",
    "F335": "MONCALIERI",
    "F336": "MONCALVO",
    "D553": "MONCENISIO",
    "F337": "MONCESTINO",
    "F338": "MONCHIERO",
    "F340": "MONCHIO DELLE CORTI",
    "F341": "MONCLASSICO",
    "F342": "MONCRIVELLO",
    "F343": "MONCUCCO TORINESE",
    "F346": "MONDAINO",
    "F347": "MONDAVIO",
    "F348": "MONDOLFO",
    "F351": "MONDOVI'",
    "F352": "MONDRAGONE",
    "F354": "MONEGLIA",
    "F355": "MONESIGLIO",
    "F356": "MONFALCONE",
    "F358": "MONFORTE D'ALBA",
    "F359": "MONFORTE SAN GIORGIO",
    "F360": "MONFUMO",
    "F361": "MONGARDINO",
    "F363": "MONGHIDORO",
    "F364": "MONGIANA",
    "F365": "MONGIARDINO LIGURE",
    "F368": "MONGIUFFI MELIA",
    "F369": "MONGRANDO",
    "F370": "MONGRASSANO",
    "F371": "MONGUELFO-TESIDO .WELSBERG-TAISTEN.",
    "F372": "MONGUZZO",
    "F373": "MONIGA DEL GARDA",
    "F374": "MONLEALE",
    "F375": "MONNO",
    "F376": "MONOPOLI",
    "F377": "MONREALE",
    "F378": "MONRUPINO",
    "F379": "MONSAMPIETRO MORICO",
    "F380": "MONSAMPOLO DEL TRONTO",
    "F381": "MONSANO",
    "F382": "MONSELICE",
    "F383": "MONSERRATO",
    "F384": "MONSUMMANO TERME",
    "F385": "MONTA'",
    "F386": "MONTABONE",
    "F387": "MONTACUTO",
    "F390": "MONTAFIA",
    "F391": "MONTAGANO",
    "F392": "MONTAGNA .MONTAN.",
    "F393": "MONTAGNA IN VALTELLINA",
    "F394": "MONTAGNANA",
    "F395": "MONTAGNAREALE",
    "F396": "MONTAGNE",
    "F397": "MONTAGUTO",
    "F398": "MONTAIONE",
    "F400": "MONTALBANO ELICONA",
    "F399": "MONTALBANO JONICO",
    "F402": "MONTALCINO",
    "F403": "MONTALDEO",
    "F404": "MONTALDO BORMIDA",
    "F405": "MONTALDO DI MONDOVI'",
    "F408": "MONTALDO ROERO",
    "F409": "MONTALDO SCARAMPI",
    "F407": "MONTALDO TORINESE",
    "F410": "MONTALE",
    "F411": "MONTALENGHE",
    "F414": "MONTALLEGRO",
    "F415": "MONTALTO DELLE MARCHE",
    "F419": "MONTALTO DI CASTRO",
    "F420": "MONTALTO DORA",
    "F406": "MONTALTO LIGURE",
    "F417": "MONTALTO PAVESE",
    "F416": "MONTALTO UFFUGO",
    "F422": "MONTANARO",
    "F423": "MONTANASO LOMBARDO",
    "F424": "MONTANERA",
    "F426": "MONTANO ANTILIA",
    "F427": "MONTANO LUCINO",
    "F428": "MONTAPPONE",
    "F429": "MONTAQUILA",
    "F430": "MONTASOLA",
    "F432": "MONTAURO",
    "F433": "MONTAZZOLI",
    "F437": "MONTE ARGENTARIO",
    "F456": "MONTE CASTELLO DI VIBIO",
    "F460": "MONTE CAVALLO",
    "F467": "MONTE CERIGNONE",
    "F476": "MONTE COLOMBO",
    "F477": "MONTE COMPATRI",
    "F434": "MONTE CREMASCO",
    "F486": "MONTE DI MALO",
    "F488": "MONTE DI PROCIDA",
    "F517": "MONTE GIBERTO",
    "F524": "MONTE GRIMANO TERME",
    "F532": "MONTE ISOLA",
    "F561": "MONTE MARENZO",
    "F589": "MONTE PORZIO",
    "F590": "MONTE PORZIO CATONE",
    "F599": "MONTE RINALDO",
    "F600": "MONTE ROBERTO",
    "F603": "MONTE ROMANO",
    "F616": "MONTE SAN BIAGIO",
    "F618": "MONTE SAN GIACOMO",
    "F620": "MONTE SAN GIOVANNI CAMPANO",
    "F619": "MONTE SAN GIOVANNI IN SABINA",
    "F621": "MONTE SAN GIUSTO",
    "F622": "MONTE SAN MARTINO",
    "F626": "MONTE SAN PIETRANGELI",
    "F627": "MONTE SAN PIETRO",
    "F628": "MONTE SAN SAVINO",
    "F634": "MONTE SAN VITO",
    "F629": "MONTE SANTA MARIA TIBERINA",
    "F631": "MONTE SANT'ANGELO",
    "F653": "MONTE URANO",
    "F664": "MONTE VIDON COMBATTE",
    "F665": "MONTE VIDON CORRADO",
    "F440": "MONTEBELLO DELLA BATTAGLIA",
    "F441": "MONTEBELLO DI BERTONA",
    "D746": "MONTEBELLO IONICO",
    "B268": "MONTEBELLO SUL SANGRO",
    "F442": "MONTEBELLO VICENTINO",
    "F443": "MONTEBELLUNA",
    "F445": "MONTEBRUNO",
    "F446": "MONTEBUONO",
    "F450": "MONTECALVO IN FOGLIA",
    "F448": "MONTECALVO IRPINO",
    "F449": "MONTECALVO VERSIGGIA",
    "F452": "MONTECARLO",
    "F453": "MONTECAROTTO",
    "F454": "MONTECASSIANO",
    "F455": "MONTECASTELLO",
    "F457": "MONTECASTRILLI",
    "A561": "MONTECATINI TERME",
    "F458": "MONTECATINI VAL DI CECINA",
    "F461": "MONTECCHIA DI CROSARA",
    "F462": "MONTECCHIO",
    "F463": "MONTECCHIO EMILIA",
    "F464": "MONTECCHIO MAGGIORE",
    "F465": "MONTECCHIO PRECALCINO",
    "F469": "MONTECHIARO D'ACQUI",
    "F468": "MONTECHIARO D'ASTI",
    "F473": "MONTECHIARUGOLO",
    "F474": "MONTECICCARDO",
    "F475": "MONTECILFONE",
    "F478": "MONTECOPIOLO",
    "F479": "MONTECORICE",
    "F480": "MONTECORVINO PUGLIANO",
    "F481": "MONTECORVINO ROVELLA",
    "F482": "MONTECOSARO",
    "F483": "MONTECRESTESE",
    "F484": "MONTECRETO",
    "F487": "MONTEDINOVE",
    "F489": "MONTEDORO",
    "F491": "MONTEFALCIONE",
    "F492": "MONTEFALCO",
    "F493": "MONTEFALCONE APPENNINO",
    "F494": "MONTEFALCONE DI VAL FORTORE",
    "F495": "MONTEFALCONE NEL SANNIO",
    "F496": "MONTEFANO",
    "F497": "MONTEFELCINO",
    "F498": "MONTEFERRANTE",
    "F499": "MONTEFIASCONE",
    "F500": "MONTEFINO",
    "F502": "MONTEFIORE CONCA",
    "F501": "MONTEFIORE DELL'ASO",
    "F503": "MONTEFIORINO",
    "F504": "MONTEFLAVIO",
    "F507": "MONTEFORTE CILENTO",
    "F508": "MONTEFORTE D'ALPONE",
    "F506": "MONTEFORTE IRPINO",
    "F509": "MONTEFORTINO",
    "F510": "MONTEFRANCO",
    "F511": "MONTEFREDANE",
    "F512": "MONTEFUSCO",
    "F513": "MONTEGABBIONE",
    "F514": "MONTEGALDA",
    "F515": "MONTEGALDELLA",
    "F516": "MONTEGALLO",
    "F518": "MONTEGIOCO",
    "F519": "MONTEGIORDANO",
    "F520": "MONTEGIORGIO",
    "F522": "MONTEGRANARO",
    "F523": "MONTEGRIDOLFO",
    "F526": "MONTEGRINO VALTRAVAGLIA",
    "F527": "MONTEGROSSO D'ASTI",
    "F528": "MONTEGROSSO PIAN LATTE",
    "F529": "MONTEGROTTO TERME",
    "F531": "MONTEIASI",
    "F533": "MONTELABBATE",
    "F534": "MONTELANICO",
    "F535": "MONTELAPIANO",
    "F536": "MONTELEONE DI FERMO",
    "F538": "MONTELEONE DI PUGLIA",
    "F540": "MONTELEONE DI SPOLETO",
    "F543": "MONTELEONE D'ORVIETO",
    "F542": "MONTELEONE ROCCA DORIA",
    "F541": "MONTELEONE SABINO",
    "F544": "MONTELEPRE",
    "F545": "MONTELIBRETTI",
    "F546": "MONTELLA",
    "F547": "MONTELLO",
    "F548": "MONTELONGO",
    "F549": "MONTELPARO",
    "F550": "MONTELUPO ALBESE",
    "F551": "MONTELUPO FIORENTINO",
    "F552": "MONTELUPONE",
    "F555": "MONTEMAGGIORE AL METAURO",
    "F553": "MONTEMAGGIORE BELSITO",
    "F556": "MONTEMAGNO",
    "F558": "MONTEMALE DI CUNEO",
    "F559": "MONTEMARANO",
    "F560": "MONTEMARCIANO",
    "F562": "MONTEMARZINO",
    "F563": "MONTEMESOLA",
    "F564": "MONTEMEZZO",
    "F565": "MONTEMIGNAIO",
    "F566": "MONTEMILETTO",
    "F568": "MONTEMILONE",
    "F569": "MONTEMITRO",
    "F570": "MONTEMONACO",
    "F572": "MONTEMURLO",
    "F573": "MONTEMURRO",
    "F574": "MONTENARS",
    "F576": "MONTENERO DI BISACCIA",
    "F579": "MONTENERO SABINO",
    "F580": "MONTENERO VAL COCCHIARA",
    "F578": "MONTENERODOMO",
    "F582": "MONTEODORISIO",
    "F586": "MONTEPAONE",
    "F587": "MONTEPARANO",
    "F591": "MONTEPRANDONE",
    "F592": "MONTEPULCIANO",
    "F593": "MONTERADO",
    "F594": "MONTERCHI",
    "F595": "MONTEREALE",
    "F596": "MONTEREALE VALCELLINA",
    "F597": "MONTERENZIO",
    "F598": "MONTERIGGIONI",
    "F601": "MONTERODUNI",
    "F605": "MONTERONI D'ARBIA",
    "F604": "MONTERONI DI LECCE",
    "F606": "MONTEROSI",
    "F609": "MONTEROSSO AL MARE",
    "F610": "MONTEROSSO ALMO",
    "F607": "MONTEROSSO CALABRO",
    "F608": "MONTEROSSO GRANA",
    "F611": "MONTEROTONDO",
    "F612": "MONTEROTONDO MARITTIMO",
    "F614": "MONTERUBBIANO",
    "F623": "MONTESANO SALENTINO",
    "F625": "MONTESANO SULLA MARCELLANA",
    "F636": "MONTESARCHIO",
    "F637": "MONTESCAGLIOSO",
    "F638": "MONTESCANO",
    "F639": "MONTESCHENO",
    "F640": "MONTESCUDAIO",
    "F641": "MONTESCUDO",
    "M368": "MONTESCUDO-MONTE COLOMBO",
    "F642": "MONTESE",
    "F644": "MONTESEGALE",
    "F646": "MONTESILVANO",
    "F648": "MONTESPERTOLI",
    "F651": "MONTEU DA PO",
    "F654": "MONTEU ROERO",
    "F655": "MONTEVAGO",
    "F656": "MONTEVARCHI",
    "F657": "MONTEVECCHIA",
    "F659": "MONTEVEGLIO",
    "F660": "MONTEVERDE",
    "F661": "MONTEVERDI MARITTIMO",
    "F662": "MONTEVIALE",
    "F666": "MONTEZEMOLO",
    "F667": "MONTI",
    "F668": "MONTIANO",
    "F672": "MONTICELLI BRUSATI",
    "F671": "MONTICELLI D'ONGINA",
    "F670": "MONTICELLI PAVESE",
    "F674": "MONTICELLO BRIANZA",
    "F675": "MONTICELLO CONTE OTTO",
    "F669": "MONTICELLO D'ALBA",
    "F471": "MONTICHIARI",
    "F676": "MONTICIANO",
    "F677": "MONTIERI",
    "M302": "MONTIGLIO MONFERRATO",
    "F679": "MONTIGNOSO",
    "F680": "MONTIRONE",
    "F367": "MONTJOVET",
    "F681": "MONTODINE",
    "F682": "MONTOGGIO",
    "F685": "MONTONE",
    "F687": "MONTOPOLI DI SABINA",
    "F686": "MONTOPOLI IN VAL D'ARNO",
    "F688": "MONTORFANO",
    "F690": "MONTORIO AL VOMANO",
    "F689": "MONTORIO NEI FRENTANI",
    "F692": "MONTORIO ROMANO",
    "M330": "MONTORO",
    "F693": "MONTORO INFERIORE",
    "F694": "MONTORO SUPERIORE",
    "F696": "MONTORSO VICENTINO",
    "F697": "MONTOTTONE",
    "F698": "MONTRESTA",
    "F701": "MONTU' BECCARIA",
    "F703": "MONVALLE",
    "F704": "MONZA",
    "F705": "MONZAMBANO",
    "F706": "MONZUNO",
    "F708": "MORANO CALABRO",
    "F707": "MORANO SUL PO",
    "F709": "MORANSENGO",
    "F710": "MORARO",
    "F711": "MORAZZONE",
    "F712": "MORBEGNO",
    "F713": "MORBELLO",
    "F716": "MORCIANO DI LEUCA",
    "F715": "MORCIANO DI ROMAGNA",
    "F717": "MORCONE",
    "F718": "MORDANO",
    "F720": "MORENGO",
    "F721": "MORES",
    "F722": "MORESCO",
    "F723": "MORETTA",
    "F724": "MORFASSO",
    "F725": "MORGANO",
    "F726": "MORGEX",
    "F727": "MORGONGIORI",
    "F728": "MORI",
    "F729": "MORIAGO DELLA BATTAGLIA",
    "F730": "MORICONE",
    "F731": "MORIGERATI",
    "D033": "MORIMONDO",
    "F732": "MORINO",
    "F733": "MORIONDO TORINESE",
    "F734": "MORLUPO",
    "F735": "MORMANNO",
    "F736": "MORNAGO",
    "F737": "MORNESE",
    "F738": "MORNICO AL SERIO",
    "F739": "MORNICO LOSANA",
    "F740": "MOROLO",
    "F743": "MOROZZO",
    "F744": "MORRA DE SANCTIS",
    "F745": "MORRO D'ALBA",
    "F747": "MORRO D'ORO",
    "F746": "MORRO REATINO",
    "F748": "MORRONE DEL SANNIO",
    "F749": "MORROVALLE",
    "F750": "MORSANO AL TAGLIAMENTO",
    "F751": "MORSASCO",
    "F754": "MORTARA",
    "F756": "MORTEGLIANO",
    "F758": "MORTERONE",
    "F760": "MORUZZO",
    "F761": "MOSCAZZANO",
    "F762": "MOSCHIANO",
    "F764": "MOSCIANO SANT'ANGELO",
    "F765": "MOSCUFO",
    "F766": "MOSO IN PASSIRIA .MOOS IN PASSEIER.",
    "F767": "MOSSA",
    "F768": "MOSSANO",
    "M304": "MOSSO",
    "F771": "MOTTA BALUFFI",
    "F772": "MOTTA CAMASTRA",
    "F773": "MOTTA D'AFFERMO",
    "F774": "MOTTA DE' CONTI",
    "F770": "MOTTA DI LIVENZA",
    "F777": "MOTTA MONTECORVINO",
    "F779": "MOTTA SAN GIOVANNI",
    "F780": "MOTTA SANTA LUCIA",
    "F781": "MOTTA SANT'ANASTASIA",
    "F783": "MOTTA VISCONTI",
    "F775": "MOTTAFOLLONE",
    "F776": "MOTTALCIATA",
    "B012": "MOTTEGGIANA",
    "F784": "MOTTOLA",
    "F785": "MOZZAGROGNA",
    "F786": "MOZZANICA",
    "F788": "MOZZATE",
    "F789": "MOZZECANE",
    "F791": "MOZZO",
    "F793": "MUCCIA",
    "F795": "MUGGIA",
    "F797": "MUGGIO'",
    "F798": "MUGNANO DEL CARDINALE",
    "F799": "MUGNANO DI NAPOLI",
    "F801": "MULAZZANO",
    "F802": "MULAZZO",
    "F806": "MURA",
    "F808": "MURAVERA",
    "F809": "MURAZZANO",
    "F811": "MURELLO",
    "F813": "MURIALDO",
    "F814": "MURISENGO",
    "F815": "MURLO",
    "F816": "MURO LECCESE",
    "F817": "MURO LUCANO",
    "F818": "MUROS",
    "F820": "MUSCOLINE",
    "F822": "MUSEI",
    "F826": "MUSILE DI PIAVE",
    "F828": "MUSSO",
    "F829": "MUSSOLENTE",
    "F830": "MUSSOMELI",
    "F832": "MUZZANA DEL TURGNANO",
    "F833": "MUZZANO",
    "F835": "NAGO-TORBOLE",
    "F836": "NALLES .NALS.",
    "F837": "NANNO",
    "F838": "NANTO",
    "F839": "NAPOLI",
    "F840": "NARBOLIA",
    "F841": "NARCAO",
    "F842": "NARDO'",
    "F843": "NARDODIPACE",
    "F844": "NARNI",
    "F845": "NARO",
    "F846": "NARZOLE",
    "F847": "NASINO",
    "F848": "NASO",
    "F849": "NATURNO .NATURNS.",
    "F851": "NAVE",
    "F853": "NAVE SAN ROCCO",
    "F852": "NAVELLI",
    "F856": "NAZ SCIAVES .NATZ SCHABS.",
    "F857": "NAZZANO",
    "F858": "NE",
    "F859": "NEBBIUNO",
    "F861": "NEGRAR",
    "F862": "NEIRONE",
    "F863": "NEIVE",
    "F864": "NEMBRO",
    "F865": "NEMI",
    "F866": "NEMOLI",
    "F867": "NEONELI",
    "F868": "NEPI",
    "F870": "NERETO",
    "F871": "NEROLA",
    "F872": "NERVESA DELLA BATTAGLIA",
    "F874": "NERVIANO",
    "F876": "NESPOLO",
    "F877": "NESSO",
    "F878": "NETRO",
    "F880": "NETTUNO",
    "F881": "NEVIANO",
    "F882": "NEVIANO DEGLI ARDUINI",
    "F883": "NEVIGLIE",
    "F884": "NIARDO",
    "F885": "NIBBIANO",
    "F886": "NIBBIOLA",
    "F887": "NIBIONNO",
    "F889": "NICHELINO",
    "F890": "NICOLOSI",
    "F891": "NICORVO",
    "F892": "NICOSIA",
    "F893": "NICOTERA",
    "F894": "NIELLA BELBO",
    "F895": "NIELLA TANARO",
    "F898": "NIMIS",
    "F899": "NISCEMI",
    "F900": "NISSORIA",
    "F901": "NIZZA DI SICILIA",
    "F902": "NIZZA MONFERRATO",
    "F904": "NOALE",
    "F906": "NOASCA",
    "F907": "NOCARA",
    "F908": "NOCCIANO",
    "F912": "NOCERA INFERIORE",
    "F913": "NOCERA SUPERIORE",
    "F910": "NOCERA TERINESE",
    "F911": "NOCERA UMBRA",
    "F914": "NOCETO",
    "F915": "NOCI",
    "F916": "NOCIGLIA",
    "F917": "NOEPOLI",
    "F918": "NOGARA",
    "F920": "NOGAREDO",
    "F921": "NOGAROLE ROCCA",
    "F922": "NOGAROLE VICENTINO",
    "F923": "NOICATTARO",
    "F924": "NOLA",
    "F925": "NOLE",
    "F926": "NOLI",
    "F927": "NOMAGLIO",
    "F929": "NOMI",
    "F930": "NONANTOLA",
    "F931": "NONE",
    "F932": "NONIO",
    "F933": "NORAGUGUME",
    "F934": "NORBELLO",
    "F935": "NORCIA",
    "F937": "NORMA",
    "F939": "NOSATE",
    "F942": "NOTARESCO",
    "F943": "NOTO",
    "F949": "NOVA LEVANTE .WELSCHNOFEN.",
    "F944": "NOVA MILANESE",
    "F950": "NOVA PONENTE .DEUTSCHNOFEN.",
    "A942": "NOVA SIRI",
    "F137": "NOVAFELTRIA",
    "F947": "NOVALEDO",
    "F948": "NOVALESA",
    "F952": "NOVARA",
    "F951": "NOVARA DI SICILIA",
    "F956": "NOVATE MEZZOLA",
    "F955": "NOVATE MILANESE",
    "F957": "NOVE",
    "F958": "NOVEDRATE",
    "F960": "NOVELLARA",
    "F961": "NOVELLO",
    "F963": "NOVENTA DI PIAVE",
    "F962": "NOVENTA PADOVANA",
    "F964": "NOVENTA VICENTINA",
    "F966": "NOVI DI MODENA",
    "F965": "NOVI LIGURE",
    "F967": "NOVI VELIA",
    "F968": "NOVIGLIO",
    "F970": "NOVOLI",
    "F972": "NUCETTO",
    "F975": "NUGHEDU SAN NICOLO'",
    "F974": "NUGHEDU SANTA VITTORIA",
    "F976": "NULE",
    "F977": "NULVI",
    "F978": "NUMANA",
    "F979": "NUORO",
    "F980": "NURACHI",
    "F981": "NURAGUS",
    "F982": "NURALLAO",
    "F983": "NURAMINIS",
    "F985": "NURECI",
    "F986": "NURRI",
    "F987": "NUS",
    "F988": "NUSCO",
    "F989": "NUVOLENTO",
    "F990": "NUVOLERA",
    "F991": "NUXIS",
    "F992": "OCCHIEPPO INFERIORE",
    "F993": "OCCHIEPPO SUPERIORE",
    "F994": "OCCHIOBELLO",
    "F995": "OCCIMIANO",
    "F996": "OCRE",
    "F997": "ODALENGO GRANDE",
    "F998": "ODALENGO PICCOLO",
    "F999": "ODERZO",
    "G001": "ODOLO",
    "G002": "OFENA",
    "G003": "OFFAGNA",
    "G004": "OFFANENGO",
    "G005": "OFFIDA",
    "G006": "OFFLAGA",
    "G007": "OGGEBBIO",
    "G008": "OGGIONA CON SANTO STEFANO",
    "G009": "OGGIONO",
    "G010": "OGLIANICO",
    "G011": "OGLIASTRO CILENTO",
    "G015": "OLBIA",
    "G016": "OLCENENGO",
    "G018": "OLDENICO",
    "G019": "OLEGGIO",
    "G020": "OLEGGIO CASTELLO",
    "G021": "OLEVANO DI LOMELLINA",
    "G022": "OLEVANO ROMANO",
    "G023": "OLEVANO SUL TUSCIANO",
    "G025": "OLGIATE COMASCO",
    "G026": "OLGIATE MOLGORA",
    "G028": "OLGIATE OLONA",
    "G030": "OLGINATE",
    "G031": "OLIENA",
    "G032": "OLIVA GESSI",
    "G034": "OLIVADI",
    "G036": "OLIVERI",
    "G039": "OLIVETO CITRA",
    "G040": "OLIVETO LARIO",
    "G037": "OLIVETO LUCANO",
    "G041": "OLIVETTA SAN MICHELE",
    "G042": "OLIVOLA",
    "G043": "OLLASTRA",
    "G044": "OLLOLAI",
    "G045": "OLLOMONT",
    "G046": "OLMEDO",
    "G047": "OLMENETA",
    "G049": "OLMO AL BREMBO",
    "G048": "OLMO GENTILE",
    "G050": "OLTRE IL COLLE",
    "G054": "OLTRESSENDA ALTA",
    "G056": "OLTRONA DI SAN MAMETTE",
    "G058": "OLZAI",
    "G061": "OME",
    "G062": "OMEGNA",
    "G063": "OMIGNANO",
    "G064": "ONANI'",
    "G065": "ONANO",
    "G066": "ONCINO",
    "G068": "ONETA",
    "G070": "ONIFAI",
    "G071": "ONIFERI",
    "G074": "ONO SAN PIETRO",
    "G075": "ONORE",
    "G076": "ONZO",
    "G078": "OPERA",
    "G079": "OPI",
    "G080": "OPPEANO",
    "G081": "OPPIDO LUCANO",
    "G082": "OPPIDO MAMERTINA",
    "G083": "ORA .AUER.",
    "G084": "ORANI",
    "G086": "ORATINO",
    "G087": "ORBASSANO",
    "G088": "ORBETELLO",
    "G089": "ORCIANO DI PESARO",
    "G090": "ORCIANO PISANO",
    "D522": "ORCO FEGLINO",
    "M266": "ORDONA",
    "G093": "ORERO",
    "G095": "ORGIANO",
    "G097": "ORGOSOLO",
    "G098": "ORIA",
    "G102": "ORICOLA",
    "G103": "ORIGGIO",
    "G105": "ORINO",
    "G108": "ORIO AL SERIO",
    "G109": "ORIO CANAVESE",
    "G107": "ORIO LITTA",
    "G110": "ORIOLO",
    "G111": "ORIOLO ROMANO",
    "G113": "ORISTANO",
    "G114": "ORMEA",
    "G115": "ORMELLE",
    "G116": "ORNAGO",
    "G117": "ORNAVASSO",
    "G118": "ORNICA",
    "G119": "OROSEI",
    "G120": "OROTELLI",
    "G121": "ORRIA",
    "G122": "ORROLI",
    "G123": "ORSAGO",
    "G124": "ORSARA BORMIDA",
    "G125": "ORSARA DI PUGLIA",
    "G126": "ORSENIGO",
    "G128": "ORSOGNA",
    "G129": "ORSOMARSO",
    "G130": "ORTA DI ATELLA",
    "G131": "ORTA NOVA",
    "G134": "ORTA SAN GIULIO",
    "G133": "ORTACESUS",
    "G135": "ORTE",
    "G136": "ORTELLE",
    "G137": "ORTEZZANO",
    "G139": "ORTIGNANO RAGGIOLO",
    "G140": "ORTISEI .ST ULRICH.",
    "G141": "ORTONA",
    "G142": "ORTONA DEI MARSI",
    "G143": "ORTONOVO",
    "G144": "ORTOVERO",
    "G145": "ORTUCCHIO",
    "G146": "ORTUERI",
    "G147": "ORUNE",
    "G148": "ORVIETO",
    "B595": "ORVINIO",
    "G149": "ORZINUOVI",
    "G150": "ORZIVECCHI",
    "G151": "OSASCO",
    "G152": "OSASIO",
    "G153": "OSCHIRI",
    "G154": "OSIDDA",
    "G155": "OSIGLIA",
    "G156": "OSILO",
    "G157": "OSIMO",
    "G158": "OSINI",
    "G159": "OSIO SOPRA",
    "G160": "OSIO SOTTO",
    "E529": "OSMATE",
    "G161": "OSNAGO",
    "G163": "OSOPPO",
    "G164": "OSPEDALETTI",
    "G168": "OSPEDALETTO",
    "G165": "OSPEDALETTO D'ALPINOLO",
    "G167": "OSPEDALETTO EUGANEO",
    "G166": "OSPEDALETTO LODIGIANO",
    "G169": "OSPITALE DI CADORE",
    "G170": "OSPITALETTO",
    "G171": "OSSAGO LODIGIANO",
    "G173": "OSSANA",
    "G178": "OSSI",
    "G179": "OSSIMO",
    "G181": "OSSONA",
    "G182": "OSSUCCIO",
    "G183": "OSTANA",
    "G184": "OSTELLATO",
    "G185": "OSTIANO",
    "G186": "OSTIGLIA",
    "F401": "OSTRA",
    "F581": "OSTRA VETERE",
    "G187": "OSTUNI",
    "G188": "OTRANTO",
    "G189": "OTRICOLI",
    "G191": "OTTANA",
    "G192": "OTTATI",
    "G190": "OTTAVIANO",
    "G193": "OTTIGLIO",
    "G194": "OTTOBIANO",
    "G195": "OTTONE",
    "G196": "OULX",
    "G197": "OVADA",
    "G198": "OVARO",
    "G199": "OVIGLIO",
    "G200": "OVINDOLI",
    "G201": "OVODDA",
    "G012": "OYACE",
    "G202": "OZEGNA",
    "G203": "OZIERI",
    "G205": "OZZANO DELL'EMILIA",
    "G204": "OZZANO MONFERRATO",
    "G206": "OZZERO",
    "G207": "PABILLONIS",
    "G209": "PACE DEL MELA",
    "G208": "PACECO",
    "G210": "PACENTRO",
    "G211": "PACHINO",
    "G212": "PACIANO",
    "G213": "PADENGHE SUL GARDA",
    "G214": "PADERGNONE",
    "G215": "PADERNA",
    "G218": "PADERNO D'ADDA",
    "G221": "PADERNO DEL GRAPPA",
    "G220": "PADERNO DUGNANO",
    "G217": "PADERNO FRANCIACORTA",
    "G222": "PADERNO PONCHIELLI",
    "G224": "PADOVA",
    "G225": "PADRIA",
    "M301": "PADRU",
    "G226": "PADULA",
    "G227": "PADULI",
    "G228": "PAESANA",
    "G229": "PAESE",
    "G230": "PAGANI",
    "G232": "PAGANICO SABINO",
    "G233": "PAGAZZANO",
    "G234": "PAGLIARA",
    "G237": "PAGLIETA",
    "G238": "PAGNACCO",
    "G240": "PAGNO",
    "G241": "PAGNONA",
    "G242": "PAGO DEL VALLO DI LAURO",
    "G243": "PAGO VEIANO",
    "G247": "PAISCO LOVENO",
    "G248": "PAITONE",
    "G249": "PALADINA",
    "G250": "PALAGANO",
    "G251": "PALAGIANELLO",
    "G252": "PALAGIANO",
    "G253": "PALAGONIA",
    "G254": "PALAIA",
    "G255": "PALANZANO",
    "G257": "PALATA",
    "G258": "PALAU",
    "G259": "PALAZZAGO",
    "G263": "PALAZZO ADRIANO",
    "G262": "PALAZZO CANAVESE",
    "G260": "PALAZZO PIGNANO",
    "G261": "PALAZZO SAN GERVASIO",
    "G267": "PALAZZOLO ACREIDE",
    "G268": "PALAZZOLO DELLO STELLA",
    "G264": "PALAZZOLO SULL'OGLIO",
    "G266": "PALAZZOLO VERCELLESE",
    "G270": "PALAZZUOLO SUL SENIO",
    "G271": "PALENA",
    "G272": "PALERMITI",
    "G273": "PALERMO",
    "G274": "PALESTRINA",
    "G275": "PALESTRO",
    "G276": "PALIANO",
    "G277": "PALIZZI",
    "G278": "PALLAGORIO",
    "G280": "PALLANZENO",
    "G281": "PALLARE",
    "G283": "PALMA CAMPANIA",
    "G282": "PALMA DI MONTECHIARO",
    "G284": "PALMANOVA",
    "G285": "PALMARIGGI",
    "G286": "PALMAS ARBOREA",
    "G288": "PALMI",
    "G289": "PALMIANO",
    "G290": "PALMOLI",
    "G291": "PALO DEL COLLE",
    "G293": "PALOMBARA SABINA",
    "G294": "PALOMBARO",
    "G292": "PALOMONTE",
    "G295": "PALOSCO",
    "G297": "PALU'",
    "G296": "PALU' DEL FERSINA",
    "G298": "PALUDI",
    "G300": "PALUZZA",
    "G302": "PAMPARATO",
    "G303": "PANCALIERI",
    "G304": "PANCARANA",
    "G305": "PANCHIA'",
    "G306": "PANDINO",
    "G307": "PANETTIERI",
    "G308": "PANICALE",
    "G311": "PANNARANO",
    "G312": "PANNI",
    "G315": "PANTELLERIA",
    "G316": "PANTIGLIATE",
    "G317": "PAOLA",
    "G318": "PAOLISI",
    "G320": "PAPASIDERO",
    "G323": "PAPOZZE",
    "G324": "PARABIAGO",
    "G325": "PARABITA",
    "G327": "PARATICO",
    "G328": "PARCINES .PARTSCHINS.",
    "G329": "PARE'",
    "G330": "PARELLA",
    "G331": "PARENTI",
    "G333": "PARETE",
    "G334": "PARETO",
    "G335": "PARGHELIA",
    "G336": "PARLASCO",
    "G337": "PARMA",
    "G338": "PARODI LIGURE",
    "G339": "PAROLDO",
    "G340": "PAROLISE",
    "G342": "PARONA",
    "G344": "PARRANO",
    "G346": "PARRE",
    "G347": "PARTANNA",
    "G348": "PARTINICO",
    "G349": "PARUZZARO",
    "G350": "PARZANICA",
    "G352": "PASIAN DI PRATO",
    "G353": "PASIANO DI PORDENONE",
    "G354": "PASPARDO",
    "G358": "PASSERANO MARMORITO",
    "G359": "PASSIGNANO SUL TRASIMENO",
    "G361": "PASSIRANO",
    "G362": "PASTENA",
    "G364": "PASTORANO",
    "G365": "PASTRENGO",
    "G367": "PASTURANA",
    "G368": "PASTURO",
    "G371": "PATERNO'",
    "M269": "PATERNO",
    "G372": "PATERNO CALABRO",
    "G370": "PATERNOPOLI",
    "G374": "PATRICA",
    "G376": "PATTADA",
    "G377": "PATTI",
    "G378": "PATU'",
    "G379": "PAU",
    "G381": "PAULARO",
    "G382": "PAULI ARBAREI",
    "G384": "PAULILATINO",
    "G385": "PAULLO",
    "G386": "PAUPISI",
    "G387": "PAVAROLO",
    "G388": "PAVIA",
    "G389": "PAVIA DI UDINE",
    "G392": "PAVONE CANAVESE",
    "G391": "PAVONE DEL MELLA",
    "G393": "PAVULLO NEL FRIGNANO",
    "G394": "PAZZANO",
    "G395": "PECCIOLI",
    "G396": "PECCO",
    "G397": "PECETTO DI VALENZA",
    "G398": "PECETTO TORINESE",
    "G399": "PECORARA",
    "G400": "PEDACE",
    "G402": "PEDARA",
    "G403": "PEDASO",
    "G404": "PEDAVENA",
    "G406": "PEDEMONTE",
    "G408": "PEDEROBBA",
    "G410": "PEDESINA",
    "G411": "PEDIVIGLIANO",
    "G412": "PEDRENGO",
    "G415": "PEGLIO",
    "G416": "PEGLIO",
    "G417": "PEGOGNAGA",
    "G418": "PEIA",
    "G419": "PEIO",
    "G420": "PELAGO",
    "G421": "PELLA",
    "G424": "PELLEGRINO PARMENSE",
    "G426": "PELLEZZANO",
    "G427": "PELLIO INTELVI",
    "G428": "PELLIZZANO",
    "G429": "PELUGO",
    "G430": "PENANGO",
    "G432": "PENNA IN TEVERINA",
    "G436": "PENNA SAN GIOVANNI",
    "G437": "PENNA SANT'ANDREA",
    "G433": "PENNABILLI",
    "G434": "PENNADOMO",
    "G435": "PENNAPIEDIMONTE",
    "G438": "PENNE",
    "G439": "PENTONE",
    "G441": "PERANO",
    "G442": "PERAROLO DI CADORE",
    "G443": "PERCA .PERCHA.",
    "G444": "PERCILE",
    "G445": "PERDASDEFOGU",
    "G446": "PERDAXIUS",
    "G447": "PERDIFUMO",
    "G448": "PEREGO",
    "G449": "PERETO",
    "G450": "PERFUGAS",
    "G451": "PERGINE VALDARNO",
    "G452": "PERGINE VALSUGANA",
    "G453": "PERGOLA",
    "G454": "PERINALDO",
    "G455": "PERITO",
    "G456": "PERLEDO",
    "G457": "PERLETTO",
    "G458": "PERLO",
    "G459": "PERLOZ",
    "G461": "PERNUMIA",
    "C013": "PERO",
    "G463": "PEROSA ARGENTINA",
    "G462": "PEROSA CANAVESE",
    "G465": "PERRERO",
    "G469": "PERSICO DOSIMO",
    "G471": "PERTENGO",
    "G474": "PERTICA ALTA",
    "G475": "PERTICA BASSA",
    "G476": "PERTOSA",
    "G477": "PERTUSIO",
    "G478": "PERUGIA",
    "G479": "PESARO",
    "G480": "PESCAGLIA",
    "G481": "PESCANTINA",
    "G482": "PESCARA",
    "G483": "PESCAROLO ED UNITI",
    "G484": "PESCASSEROLI",
    "G485": "PESCATE",
    "G486": "PESCHE",
    "G487": "PESCHICI",
    "G488": "PESCHIERA BORROMEO",
    "G489": "PESCHIERA DEL GARDA",
    "G491": "PESCIA",
    "G492": "PESCINA",
    "G494": "PESCO SANNITA",
    "G493": "PESCOCOSTANZO",
    "G495": "PESCOLANCIANO",
    "G496": "PESCOPAGANO",
    "G497": "PESCOPENNATARO",
    "G498": "PESCOROCCHIANO",
    "G499": "PESCOSANSONESCO",
    "G500": "PESCOSOLIDO",
    "G502": "PESSANO CON BORNAGO",
    "G504": "PESSINA CREMONESE",
    "G505": "PESSINETTO",
    "G506": "PETACCIATO",
    "G508": "PETILIA POLICASTRO",
    "G509": "PETINA",
    "G510": "PETRALIA SOPRANA",
    "G511": "PETRALIA SOTTANA",
    "G513": "PETRELLA SALTO",
    "G512": "PETRELLA TIFERNINA",
    "G514": "PETRIANO",
    "G515": "PETRIOLO",
    "G516": "PETRITOLI",
    "G517": "PETRIZZI",
    "G518": "PETRONA'",
    "M281": "PETROSINO",
    "G519": "PETRURO IRPINO",
    "G520": "PETTENASCO",
    "G521": "PETTINENGO",
    "G522": "PETTINEO",
    "G523": "PETTORANELLO DEL MOLISE",
    "G524": "PETTORANO SUL GIZIO",
    "G525": "PETTORAZZA GRIMANI",
    "G526": "PEVERAGNO",
    "G528": "PEZZANA",
    "G529": "PEZZAZE",
    "G532": "PEZZOLO VALLE UZZONE",
    "G535": "PIACENZA",
    "G534": "PIACENZA D'ADIGE",
    "G536": "PIADENA",
    "G537": "PIAGGE",
    "G538": "PIAGGINE",
    "G546": "PIAN CAMUNO",
    "G552": "PIAN DI SCO'",
    "G542": "PIANA CRIXIA",
    "G543": "PIANA DEGLI ALBANESI",
    "G541": "PIANA DI MONTE VERNA",
    "G547": "PIANCASTAGNAIO",
    "G549": "PIANCOGNO",
    "G551": "PIANDIMELETO",
    "G553": "PIANE CRATI",
    "G555": "PIANELLA",
    "G556": "PIANELLO DEL LARIO",
    "G557": "PIANELLO VAL TIDONE",
    "G558": "PIANENGO",
    "G559": "PIANEZZA",
    "G560": "PIANEZZE",
    "G561": "PIANFEI",
    "G564": "PIANICO",
    "G565": "PIANIGA",
    "G568": "PIANO DI SORRENTO",
    "D546": "PIANOPOLI",
    "G570": "PIANORO",
    "G571": "PIANSANO",
    "G572": "PIANTEDO",
    "G574": "PIARIO",
    "G575": "PIASCO",
    "G576": "PIATEDA",
    "G577": "PIATTO",
    "G582": "PIAZZA AL SERCHIO",
    "G580": "PIAZZA ARMERINA",
    "G579": "PIAZZA BREMBANA",
    "G583": "PIAZZATORRE",
    "G587": "PIAZZOLA SUL BRENTA",
    "G588": "PIAZZOLO",
    "G589": "PICCIANO",
    "G590": "PICERNO",
    "G591": "PICINISCO",
    "G592": "PICO",
    "G593": "PIEA",
    "G594": "PIEDICAVALLO",
    "G597": "PIEDIMONTE ETNEO",
    "G596": "PIEDIMONTE MATESE",
    "G598": "PIEDIMONTE SAN GERMANO",
    "G600": "PIEDIMULERA",
    "G601": "PIEGARO",
    "G602": "PIENZA",
    "G603": "PIERANICA",
    "G612": "PIETRA DE' GIORGI",
    "G605": "PIETRA LIGURE",
    "G619": "PIETRA MARAZZI",
    "G606": "PIETRABBONDANTE",
    "G607": "PIETRABRUNA",
    "G608": "PIETRACAMELA",
    "G609": "PIETRACATELLA",
    "G610": "PIETRACUPA",
    "G611": "PIETRADEFUSI",
    "G613": "PIETRAFERRAZZANA",
    "G615": "PIETRAFITTA",
    "G616": "PIETRAGALLA",
    "G618": "PIETRALUNGA",
    "G620": "PIETRAMELARA",
    "G604": "PIETRAMONTECORVINO",
    "G621": "PIETRANICO",
    "G622": "PIETRAPAOLA",
    "G623": "PIETRAPERTOSA",
    "G624": "PIETRAPERZIA",
    "G625": "PIETRAPORZIO",
    "G626": "PIETRAROJA",
    "G627": "PIETRARUBBIA",
    "G628": "PIETRASANTA",
    "G629": "PIETRASTORNINA",
    "G630": "PIETRAVAIRANO",
    "G631": "PIETRELCINA",
    "G636": "PIEVE A NIEVOLE",
    "G635": "PIEVE ALBIGNOLA",
    "G638": "PIEVE D'ALPAGO",
    "G639": "PIEVE DEL CAIRO",
    "G641": "PIEVE DI BONO",
    "M365": "PIEVE DI BONO-PREZZO",
    "G642": "PIEVE DI CADORE",
    "G643": "PIEVE DI CENTO",
    "G633": "PIEVE DI CORIANO",
    "G644": "PIEVE DI LEDRO",
    "G645": "PIEVE DI SOLIGO",
    "G632": "PIEVE DI TECO",
    "G647": "PIEVE D'OLMI",
    "G634": "PIEVE EMANUELE",
    "G096": "PIEVE FISSIRAGA",
    "G648": "PIEVE FOSCIANA",
    "G646": "PIEVE LIGURE",
    "G650": "PIEVE PORTO MORONE",
    "G651": "PIEVE SAN GIACOMO",
    "G653": "PIEVE SANTO STEFANO",
    "G656": "PIEVE TESINO",
    "G657": "PIEVE TORINA",
    "G658": "PIEVE VERGONTE",
    "G637": "PIEVEBOVIGLIANA",
    "G649": "PIEVEPELAGO",
    "G659": "PIGLIO",
    "G660": "PIGNA",
    "G662": "PIGNATARO INTERAMNA",
    "G661": "PIGNATARO MAGGIORE",
    "G663": "PIGNOLA",
    "G664": "PIGNONE",
    "G665": "PIGRA",
    "G666": "PILA",
    "G669": "PIMENTEL",
    "G670": "PIMONTE",
    "G671": "PINAROLO PO",
    "G672": "PINASCA",
    "G673": "PINCARA",
    "G674": "PINEROLO",
    "F831": "PINETO",
    "G676": "PINO D'ASTI",
    "G677": "PINO SULLA SPONDA DEL LAGO MAGGIORE",
    "G678": "PINO TORINESE",
    "G680": "PINZANO AL TAGLIAMENTO",
    "G681": "PINZOLO",
    "G682": "PIOBBICO",
    "G683": "PIOBESI D'ALBA",
    "G684": "PIOBESI TORINESE",
    "G685": "PIODE",
    "G686": "PIOLTELLO",
    "G687": "PIOMBINO",
    "G688": "PIOMBINO DESE",
    "G690": "PIORACO",
    "G691": "PIOSSASCO",
    "G692": "PIOVA' MASSAIA",
    "G693": "PIOVE DI SACCO",
    "G694": "PIOVENE ROCCHETTE",
    "G695": "PIOVERA",
    "G696": "PIOZZANO",
    "G697": "PIOZZO",
    "G699": "PIRAINO",
    "G702": "PISA",
    "G703": "PISANO",
    "G705": "PISCINA",
    "M291": "PISCINAS",
    "G707": "PISCIOTTA",
    "G710": "PISOGNE",
    "G704": "PISONIANO",
    "G712": "PISTICCI",
    "G713": "PISTOIA",
    "G715": "PITEGLIO",
    "G716": "PITIGLIANO",
    "G717": "PIUBEGA",
    "G718": "PIURO",
    "G719": "PIVERONE",
    "G720": "PIZZALE",
    "G721": "PIZZIGHETTONE",
    "G722": "PIZZO",
    "G724": "PIZZOFERRATO",
    "G726": "PIZZOLI",
    "G727": "PIZZONE",
    "G728": "PIZZONI",
    "G729": "PLACANICA",
    "G733": "PLATACI",
    "G734": "PLATANIA",
    "G735": "PLATI'",
    "G299": "PLAUS .PLAUS.",
    "G737": "PLESIO",
    "G740": "PLOAGHE",
    "G741": "PLODIO",
    "G742": "POCAPAGLIA",
    "G743": "POCENIA",
    "G746": "PODENZANA",
    "G747": "PODENZANO",
    "G749": "POFI",
    "G751": "POGGIARDO",
    "G752": "POGGIBONSI",
    "G754": "POGGIO A CAIANO",
    "G755": "POGGIO BERNI",
    "G756": "POGGIO BUSTONE",
    "G757": "POGGIO CATINO",
    "G761": "POGGIO IMPERIALE",
    "G763": "POGGIO MIRTETO",
    "G764": "POGGIO MOIANO",
    "G765": "POGGIO NATIVO",
    "G766": "POGGIO PICENZE",
    "G768": "POGGIO RENATICO",
    "G753": "POGGIO RUSCO",
    "G770": "POGGIO SAN LORENZO",
    "G771": "POGGIO SAN MARCELLO",
    "D566": "POGGIO SAN VICINO",
    "B317": "POGGIO SANNITA",
    "M324": "POGGIO TORRIANA",
    "G758": "POGGIODOMO",
    "G760": "POGGIOFIORITO",
    "G762": "POGGIOMARINO",
    "G767": "POGGIOREALE",
    "G769": "POGGIORSINI",
    "G431": "POGGIRIDENTI",
    "G772": "POGLIANO MILANESE",
    "G773": "POGNANA LARIO",
    "G774": "POGNANO",
    "G775": "POGNO",
    "G777": "POIRINO",
    "G776": "POJANA MAGGIORE",
    "G779": "POLAVENO",
    "G780": "POLCENIGO",
    "G782": "POLESELLA",
    "G783": "POLESINE PARMENSE",
    "M367": "POLESINE ZIBELLO",
    "G784": "POLI",
    "G785": "POLIA",
    "G786": "POLICORO",
    "G787": "POLIGNANO A MARE",
    "G789": "POLINAGO",
    "G790": "POLINO",
    "G791": "POLISTENA",
    "G792": "POLIZZI GENEROSA",
    "G793": "POLLA",
    "G794": "POLLEIN",
    "G795": "POLLENA TROCCHIA",
    "F567": "POLLENZA",
    "G796": "POLLICA",
    "G797": "POLLINA",
    "G798": "POLLONE",
    "G799": "POLLUTRI",
    "G800": "POLONGHERA",
    "G801": "POLPENAZZE DEL GARDA",
    "G802": "POLVERARA",
    "G803": "POLVERIGI",
    "G804": "POMARANCE",
    "G805": "POMARETTO",
    "G806": "POMARICO",
    "G807": "POMARO MONFERRATO",
    "G808": "POMAROLO",
    "G809": "POMBIA",
    "G811": "POMEZIA",
    "G812": "POMIGLIANO D'ARCO",
    "G813": "POMPEI",
    "G814": "POMPEIANA",
    "G815": "POMPIANO",
    "G816": "POMPONESCO",
    "G817": "POMPU",
    "G818": "PONCARALE",
    "G820": "PONDERANO",
    "G821": "PONNA",
    "G822": "PONSACCO",
    "G823": "PONSO",
    "G826": "PONT CANAVESE",
    "G825": "PONTASSIEVE",
    "G545": "PONTBOSET",
    "G827": "PONTE",
    "G833": "PONTE BUGGIANESE",
    "G842": "PONTE DELL'OLIO",
    "G844": "PONTE DI LEGNO",
    "G846": "PONTE DI PIAVE",
    "G830": "PONTE GARDENA .WAIDBRUCK.",
    "G829": "PONTE IN VALTELLINA",
    "G847": "PONTE LAMBRO",
    "B662": "PONTE NELLE ALPI",
    "G851": "PONTE NIZZA",
    "F941": "PONTE NOSSA",
    "G855": "PONTE SAN NICOLO'",
    "G856": "PONTE SAN PIETRO",
    "G831": "PONTEBBA",
    "G834": "PONTECAGNANO FAIANO",
    "G836": "PONTECCHIO POLESINE",
    "G837": "PONTECHIANALE",
    "G838": "PONTECORVO",
    "G839": "PONTECURONE",
    "G840": "PONTEDASSIO",
    "G843": "PONTEDERA",
    "G848": "PONTELANDOLFO",
    "G849": "PONTELATONE",
    "G850": "PONTELONGO",
    "G852": "PONTENURE",
    "G853": "PONTERANICA",
    "G858": "PONTESTURA",
    "G859": "PONTEVICO",
    "G860": "PONTEY",
    "G861": "PONTI",
    "G862": "PONTI SUL MINCIO",
    "G864": "PONTIDA",
    "G865": "PONTINIA",
    "G866": "PONTINVREA",
    "G867": "PONTIROLO NUOVO",
    "G869": "PONTOGLIO",
    "G870": "PONTREMOLI",
    "G854": "PONT-SAINT-MARTIN",
    "G871": "PONZA",
    "G873": "PONZANO DI FERMO",
    "G872": "PONZANO MONFERRATO",
    "G874": "PONZANO ROMANO",
    "G875": "PONZANO VENETO",
    "G877": "PONZONE",
    "G878": "POPOLI",
    "G879": "POPPI",
    "G881": "PORANO",
    "G882": "PORCARI",
    "G886": "PORCIA",
    "G888": "PORDENONE",
    "G889": "PORLEZZA",
    "G890": "PORNASSIO",
    "G891": "PORPETTO",
    "A558": "PORRETTA TERME",
    "G894": "PORTACOMARO",
    "G895": "PORTALBERA",
    "G900": "PORTE",
    "M358": "PORTE DI RENDENA",
    "G902": "PORTICI",
    "G903": "PORTICO DI CASERTA",
    "G904": "PORTICO E SAN BENEDETTO",
    "G905": "PORTIGLIOLA",
    "E680": "PORTO AZZURRO",
    "G906": "PORTO CERESIO",
    "M263": "PORTO CESAREO",
    "F299": "PORTO EMPEDOCLE",
    "G917": "PORTO MANTOVANO",
    "G919": "PORTO RECANATI",
    "G920": "PORTO SAN GIORGIO",
    "G921": "PORTO SANT'ELPIDIO",
    "G923": "PORTO TOLLE",
    "G924": "PORTO TORRES",
    "G907": "PORTO VALTRAVAGLIA",
    "G926": "PORTO VIRO",
    "G909": "PORTOBUFFOLE'",
    "G910": "PORTOCANNONE",
    "G912": "PORTOFERRAIO",
    "G913": "PORTOFINO",
    "G914": "PORTOGRUARO",
    "G916": "PORTOMAGGIORE",
    "M257": "PORTOPALO DI CAPO PASSERO",
    "G922": "PORTOSCUSO",
    "G925": "PORTOVENERE",
    "G927": "PORTULA",
    "G929": "POSADA",
    "G931": "POSINA",
    "G932": "POSITANO",
    "G933": "POSSAGNO",
    "G934": "POSTA",
    "G935": "POSTA FIBRENO",
    "G936": "POSTAL .BURGSTALL.",
    "G937": "POSTALESIO",
    "G939": "POSTIGLIONE",
    "G940": "POSTUA",
    "G942": "POTENZA",
    "F632": "POTENZA PICENA",
    "G943": "POVE DEL GRAPPA",
    "G944": "POVEGLIANO",
    "G945": "POVEGLIANO VERONESE",
    "G947": "POVIGLIO",
    "G949": "POVOLETTO",
    "G950": "POZZA DI FASSA",
    "G951": "POZZAGLIA SABINA",
    "B914": "POZZAGLIO ED UNITI",
    "G953": "POZZALLO",
    "G954": "POZZILLI",
    "G955": "POZZO D'ADDA",
    "G960": "POZZOL GROPPO",
    "G959": "POZZOLENGO",
    "G957": "POZZOLEONE",
    "G961": "POZZOLO FORMIGARO",
    "G962": "POZZOMAGGIORE",
    "G963": "POZZONOVO",
    "G964": "POZZUOLI",
    "G966": "POZZUOLO DEL FRIULI",
    "G965": "POZZUOLO MARTESANA",
    "G968": "PRADALUNGA",
    "G969": "PRADAMANO",
    "G970": "PRADLEVES",
    "G973": "PRAGELATO",
    "G975": "PRAIA A MARE",
    "G976": "PRAIANO",
    "G977": "PRALBOINO",
    "G978": "PRALI",
    "G979": "PRALORMO",
    "G980": "PRALUNGO",
    "G981": "PRAMAGGIORE",
    "G982": "PRAMOLLO",
    "G985": "PRAROLO",
    "G986": "PRAROSTINO",
    "G987": "PRASCO",
    "G988": "PRASCORSANO",
    "G989": "PRASO",
    "G993": "PRATA CAMPORTACCIO",
    "G992": "PRATA D'ANSIDONIA",
    "G994": "PRATA DI PORDENONE",
    "G990": "PRATA DI PRINCIPATO ULTRA",
    "G991": "PRATA SANNITA",
    "G995": "PRATELLA",
    "G997": "PRATIGLIONE",
    "G999": "PRATO",
    "H004": "PRATO ALLO STELVIO .PRAD AM STILFSERJOCH.",
    "H002": "PRATO CARNICO",
    "H001": "PRATO SESIA",
    "H007": "PRATOLA PELIGNA",
    "H006": "PRATOLA SERRA",
    "H008": "PRATOVECCHIO",
    "M329": "PRATOVECCHIO STIA",
    "H010": "PRAVISDOMINI",
    "G974": "PRAY",
    "H011": "PRAZZO",
    "H014": "PRECENICCO",
    "H015": "PRECI",
    "M344": "PREDAIA",
    "H017": "PREDAPPIO",
    "H018": "PREDAZZO",
    "H019": "PREDOI .PRETTAU.",
    "H020": "PREDORE",
    "H021": "PREDOSA",
    "H022": "PREGANZIOL",
    "H026": "PREGNANA MILANESE",
    "H027": "PRELA'",
    "H028": "PREMANA",
    "H029": "PREMARIACCO",
    "H030": "PREMENO",
    "H033": "PREMIA",
    "H034": "PREMILCUORE",
    "H036": "PREMOLO",
    "H037": "PREMOSELLO-CHIOVENDA",
    "H038": "PREONE",
    "H039": "PREORE",
    "H040": "PREPOTTO",
    "H042": "PRE'-SAINT-DIDIER",
    "H043": "PRESEGLIE",
    "H045": "PRESENZANO",
    "H046": "PRESEZZO",
    "H047": "PRESICCE",
    "H048": "PRESSANA",
    "H050": "PRESTINE",
    "H052": "PRETORO",
    "H055": "PREVALLE",
    "H056": "PREZZA",
    "H057": "PREZZO",
    "H059": "PRIERO",
    "H062": "PRIGNANO CILENTO",
    "H061": "PRIGNANO SULLA SECCHIA",
    "H063": "PRIMALUNA",
    "M359": "PRIMIERO SAN MARTINO DI CASTROZZA",
    "H068": "PRIOCCA",
    "H069": "PRIOLA",
    "M279": "PRIOLO GARGALLO",
    "G698": "PRIVERNO",
    "H070": "PRIZZI",
    "H071": "PROCENO",
    "H072": "PROCIDA",
    "H073": "PROPATA",
    "H074": "PROSERPIO",
    "H076": "PROSSEDI",
    "H078": "PROVAGLIO D'ISEO",
    "H077": "PROVAGLIO VAL SABBIA",
    "H081": "PROVES .PROVEIS.",
    "H083": "PROVVIDENTI",
    "H085": "PRUNETTO",
    "H086": "PUEGNAGO DEL GARDA",
    "H087": "PUGLIANELLO",
    "H088": "PULA",
    "H089": "PULFERO",
    "H090": "PULSANO",
    "H091": "PUMENENGO",
    "H092": "PUOS D'ALPAGO",
    "H094": "PUSIANO",
    "H095": "PUTIFIGARI",
    "H096": "PUTIGNANO",
    "H097": "QUADRELLE",
    "H098": "QUADRI",
    "H100": "QUAGLIUZZO",
    "H101": "QUALIANO",
    "H102": "QUARANTI",
    "H103": "QUAREGNA",
    "H104": "QUARGNENTO",
    "H106": "QUARNA SOPRA",
    "H107": "QUARNA SOTTO",
    "H108": "QUARONA",
    "H109": "QUARRATA",
    "H110": "QUART",
    "H114": "QUARTO",
    "H117": "QUARTO D'ALTINO",
    "H118": "QUARTU SANT'ELENA",
    "H119": "QUARTUCCIU",
    "H120": "QUASSOLO",
    "H121": "QUATTORDIO",
    "H122": "QUATTRO CASTELLA",
    "H124": "QUERO",
    "M332": "QUERO VAS",
    "H126": "QUILIANO",
    "H127": "QUINCINETTO",
    "H128": "QUINDICI",
    "H129": "QUINGENTOLE",
    "H130": "QUINTANO",
    "H131": "QUINTO DI TREVISO",
    "H132": "QUINTO VERCELLESE",
    "H134": "QUINTO VICENTINO",
    "H140": "QUINZANO D'OGLIO",
    "H143": "QUISTELLO",
    "H145": "QUITTENGO",
    "H146": "RABBI",
    "H147": "RACALE",
    "H148": "RACALMUTO",
    "H150": "RACCONIGI",
    "H151": "RACCUJA",
    "H152": "RACINES .RATSCHINGS.",
    "H153": "RADDA IN CHIANTI",
    "H154": "RADDUSA",
    "H156": "RADICOFANI",
    "H157": "RADICONDOLI",
    "H159": "RAFFADALI",
    "M287": "RAGALNA",
    "H161": "RAGOGNA",
    "H162": "RAGOLI",
    "H163": "RAGUSA",
    "H166": "RAIANO",
    "H168": "RAMACCA",
    "G654": "RAMISETO",
    "H171": "RAMPONIO VERNA",
    "H173": "RANCIO VALCUVIA",
    "H174": "RANCO",
    "H175": "RANDAZZO",
    "H176": "RANICA",
    "H177": "RANZANICO",
    "H180": "RANZO",
    "H182": "RAPAGNANO",
    "H183": "RAPALLO",
    "H184": "RAPINO",
    "H185": "RAPOLANO TERME",
    "H186": "RAPOLLA",
    "H187": "RAPONE",
    "H188": "RASSA",
    "H189": "RASUN ANTERSELVA .RASEN ANTHOLZ.",
    "H192": "RASURA",
    "H194": "RAVANUSA",
    "H195": "RAVARINO",
    "H196": "RAVASCLETTO",
    "H198": "RAVELLO",
    "H199": "RAVENNA",
    "H200": "RAVEO",
    "H202": "RAVISCANINA",
    "H203": "RE",
    "H204": "REA",
    "H205": "REALMONTE",
    "H206": "REANA DEL ROJALE",
    "H207": "REANO",
    "H210": "RECALE",
    "H211": "RECANATI",
    "H212": "RECCO",
    "H213": "RECETTO",
    "H214": "RECOARO TERME",
    "H216": "REDAVALLE",
    "H218": "REDONDESCO",
    "H219": "REFRANCORE",
    "H220": "REFRONTOLO",
    "H221": "REGALBUTO",
    "H222": "REGGELLO",
    "H224": "REGGIO DI CALABRIA",
    "H223": "REGGIO NELL'EMILIA",
    "H225": "REGGIOLO",
    "H227": "REINO",
    "H228": "REITANO",
    "H229": "REMANZACCO",
    "H230": "REMEDELLO",
    "H233": "RENATE",
    "H235": "RENDE",
    "H236": "RENON .RITTEN.",
    "H238": "RESANA",
    "H240": "RESCALDINA",
    "H242": "RESIA",
    "H244": "RESIUTTA",
    "H245": "RESUTTANO",
    "H246": "RETORBIDO",
    "H247": "REVELLO",
    "H248": "REVERE",
    "H250": "REVIGLIASCO D'ASTI",
    "H253": "REVINE LAGO",
    "H254": "REVO'",
    "H255": "REZZAGO",
    "H256": "REZZATO",
    "H257": "REZZO",
    "H258": "REZZOAGLIO",
    "H262": "RHEMES-NOTRE-DAME",
    "H263": "RHEMES-SAINT-GEORGES",
    "H264": "RHO",
    "H265": "RIACE",
    "H266": "RIALTO",
    "H267": "RIANO",
    "H268": "RIARDO",
    "H269": "RIBERA",
    "H270": "RIBORDONE",
    "H271": "RICADI",
    "H272": "RICALDONE",
    "H273": "RICCIA",
    "H274": "RICCIONE",
    "H275": "RICCO' DEL GOLFO DI SPEZIA",
    "H276": "RICENGO",
    "H277": "RICIGLIANO",
    "H280": "RIESE PIO X",
    "H281": "RIESI",
    "H282": "RIETI",
    "H284": "RIFIANO .RIFFIAN.",
    "H285": "RIFREDDO",
    "H288": "RIGNANO FLAMINIO",
    "H287": "RIGNANO GARGANICO",
    "H286": "RIGNANO SULL'ARNO",
    "H289": "RIGOLATO",
    "H291": "RIMA SAN GIUSEPPE",
    "H292": "RIMASCO",
    "H293": "RIMELLA",
    "H294": "RIMINI",
    "H299": "RIO DI PUSTERIA .MUEHLBACH.",
    "H305": "RIO MARINA",
    "H297": "RIO NELL'ELBA",
    "H298": "RIO SALICETO",
    "H300": "RIOFREDDO",
    "H301": "RIOLA SARDO",
    "H302": "RIOLO TERME",
    "H303": "RIOLUNATO",
    "H304": "RIOMAGGIORE",
    "H307": "RIONERO IN VULTURE",
    "H308": "RIONERO SANNITICO",
    "H320": "RIPA TEATINA",
    "H311": "RIPABOTTONI",
    "H312": "RIPACANDIDA",
    "H313": "RIPALIMOSANI",
    "H314": "RIPALTA ARPINA",
    "H315": "RIPALTA CREMASCA",
    "H316": "RIPALTA GUERINA",
    "H319": "RIPARBELLA",
    "H321": "RIPATRANSONE",
    "H322": "RIPE",
    "H323": "RIPE SAN GINESIO",
    "H324": "RIPI",
    "H325": "RIPOSTO",
    "H326": "RITTANA",
    "H330": "RIVA DEL GARDA",
    "H331": "RIVA DI SOLTO",
    "H328": "RIVA LIGURE",
    "H337": "RIVA PRESSO CHIERI",
    "H329": "RIVA VALDOBBIA",
    "H333": "RIVALBA",
    "H334": "RIVALTA BORMIDA",
    "H335": "RIVALTA DI TORINO",
    "H327": "RIVAMONTE AGORDINO",
    "H336": "RIVANAZZANO TERME",
    "H338": "RIVARA",
    "H340": "RIVAROLO CANAVESE",
    "H341": "RIVAROLO DEL RE ED UNITI",
    "H342": "RIVAROLO MANTOVANO",
    "H343": "RIVARONE",
    "H344": "RIVAROSSA",
    "H346": "RIVE",
    "H347": "RIVE D'ARCANO",
    "H348": "RIVELLO",
    "H350": "RIVERGARO",
    "H352": "RIVIGNANO",
    "M317": "RIVIGNANO TEOR",
    "H353": "RIVISONDOLI",
    "H354": "RIVODUTRI",
    "H355": "RIVOLI",
    "H356": "RIVOLI VERONESE",
    "H357": "RIVOLTA D'ADDA",
    "H359": "RIZZICONI",
    "H360": "RO",
    "H361": "ROANA",
    "H362": "ROASCHIA",
    "H363": "ROASCIO",
    "H365": "ROASIO",
    "H366": "ROATTO",
    "H367": "ROBASSOMERO",
    "G223": "ROBBIATE",
    "H369": "ROBBIO",
    "H371": "ROBECCHETTO CON INDUNO",
    "H372": "ROBECCO D'OGLIO",
    "H375": "ROBECCO PAVESE",
    "H373": "ROBECCO SUL NAVIGLIO",
    "H376": "ROBELLA",
    "H377": "ROBILANTE",
    "H378": "ROBURENT",
    "H386": "ROCCA CANAVESE",
    "H387": "ROCCA CANTERANO",
    "H391": "ROCCA CIGLIE'",
    "H392": "ROCCA D'ARAZZO",
    "H395": "ROCCA DE' BALDI",
    "H396": "ROCCA DE' GIORGI",
    "H398": "ROCCA D'EVANDRO",
    "H399": "ROCCA DI BOTTE",
    "H400": "ROCCA DI CAMBIO",
    "H401": "ROCCA DI CAVE",
    "H402": "ROCCA DI MEZZO",
    "H403": "ROCCA DI NETO",
    "H404": "ROCCA DI PAPA",
    "H414": "ROCCA GRIMALDA",
    "H416": "ROCCA IMPERIALE",
    "H421": "ROCCA MASSIMA",
    "H429": "ROCCA PIA",
    "H379": "ROCCA PIETORE",
    "H432": "ROCCA PRIORA",
    "H437": "ROCCA SAN CASCIANO",
    "H438": "ROCCA SAN FELICE",
    "H439": "ROCCA SAN GIOVANNI",
    "H440": "ROCCA SANTA MARIA",
    "H441": "ROCCA SANTO STEFANO",
    "H446": "ROCCA SINIBALDA",
    "H450": "ROCCA SUSELLA",
    "H382": "ROCCABASCERANA",
    "H383": "ROCCABERNARDA",
    "H384": "ROCCABIANCA",
    "H385": "ROCCABRUNA",
    "H389": "ROCCACASALE",
    "H393": "ROCCADARCE",
    "H394": "ROCCADASPIDE",
    "H405": "ROCCAFIORITA",
    "H390": "ROCCAFLUVIONE",
    "H408": "ROCCAFORTE DEL GRECO",
    "H406": "ROCCAFORTE LIGURE",
    "H407": "ROCCAFORTE MONDOVI'",
    "H409": "ROCCAFORZATA",
    "H410": "ROCCAFRANCA",
    "H411": "ROCCAGIOVINE",
    "H412": "ROCCAGLORIOSA",
    "H413": "ROCCAGORGA",
    "H417": "ROCCALBEGNA",
    "H418": "ROCCALUMERA",
    "H420": "ROCCAMANDOLFI",
    "H422": "ROCCAMENA",
    "H423": "ROCCAMONFINA",
    "H424": "ROCCAMONTEPIANO",
    "H425": "ROCCAMORICE",
    "H426": "ROCCANOVA",
    "H427": "ROCCANTICA",
    "H428": "ROCCAPALUMBA",
    "H431": "ROCCAPIEMONTE",
    "H433": "ROCCARAINOLA",
    "H434": "ROCCARASO",
    "H436": "ROCCAROMANA",
    "H442": "ROCCASCALEGNA",
    "H443": "ROCCASECCA",
    "H444": "ROCCASECCA DEI VOLSCI",
    "H445": "ROCCASICURA",
    "H447": "ROCCASPARVERA",
    "H448": "ROCCASPINALVETI",
    "H449": "ROCCASTRADA",
    "H380": "ROCCAVALDINA",
    "H451": "ROCCAVERANO",
    "H452": "ROCCAVIGNALE",
    "H453": "ROCCAVIONE",
    "H454": "ROCCAVIVARA",
    "H456": "ROCCELLA IONICA",
    "H455": "ROCCELLA VALDEMONE",
    "H458": "ROCCHETTA A VOLTURNO",
    "H462": "ROCCHETTA BELBO",
    "H461": "ROCCHETTA DI VARA",
    "H459": "ROCCHETTA E CROCE",
    "H465": "ROCCHETTA LIGURE",
    "H460": "ROCCHETTA NERVINA",
    "H466": "ROCCHETTA PALAFEA",
    "H467": "ROCCHETTA SANT'ANTONIO",
    "H468": "ROCCHETTA TANARO",
    "H470": "RODANO",
    "H472": "RODDI",
    "H473": "RODDINO",
    "H474": "RODELLO",
    "H475": "RODENGO .RODENECK.",
    "H477": "RODENGO SAIANO",
    "H478": "RODERO",
    "H480": "RODI GARGANICO",
    "H479": "RODI' MILICI",
    "H481": "RODIGO",
    "H484": "ROE' VOLCIANO",
    "H485": "ROFRANO",
    "H486": "ROGENO",
    "H488": "ROGGIANO GRAVINA",
    "H489": "ROGHUDI",
    "H490": "ROGLIANO",
    "H491": "ROGNANO",
    "H492": "ROGNO",
    "H493": "ROGOLO",
    "H494": "ROIATE",
    "H495": "ROIO DEL SANGRO",
    "H497": "ROISAN",
    "H498": "ROLETTO",
    "H500": "ROLO",
    "H501": "ROMA",
    "H503": "ROMAGNANO AL MONTE",
    "H502": "ROMAGNANO SESIA",
    "H505": "ROMAGNESE",
    "H506": "ROMALLO",
    "H507": "ROMANA",
    "H508": "ROMANENGO",
    "H511": "ROMANO CANAVESE",
    "H512": "ROMANO D'EZZELINO",
    "H509": "ROMANO DI LOMBARDIA",
    "H514": "ROMANS D'ISONZO",
    "H516": "ROMBIOLO",
    "H517": "ROMENO",
    "H518": "ROMENTINO",
    "H519": "ROMETTA",
    "H521": "RONAGO",
    "H522": "RONCA'",
    "H523": "RONCADE",
    "H525": "RONCADELLE",
    "H527": "RONCARO",
    "H528": "RONCEGNO TERME",
    "H529": "RONCELLO",
    "H531": "RONCHI DEI LEGIONARI",
    "H532": "RONCHI VALSUGANA",
    "H533": "RONCHIS",
    "H534": "RONCIGLIONE",
    "H540": "RONCO ALL'ADIGE",
    "H538": "RONCO BIELLESE",
    "H537": "RONCO BRIANTINO",
    "H539": "RONCO CANAVESE",
    "H536": "RONCO SCRIVIA",
    "H535": "RONCOBELLO",
    "H541": "RONCOFERRARO",
    "H542": "RONCOFREDDO",
    "H544": "RONCOLA",
    "H545": "RONCONE",
    "H546": "RONDANINA",
    "H547": "RONDISSONE",
    "H549": "RONSECCO",
    "M303": "RONZO-CHIENIS",
    "H552": "RONZONE",
    "H553": "ROPPOLO",
    "H554": "RORA'",
    "H556": "ROSA'",
    "H558": "ROSARNO",
    "H559": "ROSASCO",
    "H560": "ROSATE",
    "H561": "ROSAZZA",
    "H562": "ROSCIANO",
    "H564": "ROSCIGNO",
    "H565": "ROSE",
    "H566": "ROSELLO",
    "H572": "ROSETO CAPO SPULICO",
    "F585": "ROSETO DEGLI ABRUZZI",
    "H568": "ROSETO VALFORTORE",
    "H570": "ROSIGNANO MARITTIMO",
    "H569": "ROSIGNANO MONFERRATO",
    "H573": "ROSOLINA",
    "H574": "ROSOLINI",
    "H575": "ROSORA",
    "H577": "ROSSA",
    "H578": "ROSSANA",
    "H579": "ROSSANO",
    "H580": "ROSSANO VENETO",
    "H581": "ROSSIGLIONE",
    "H583": "ROSTA",
    "H584": "ROTA D'IMAGNA",
    "H585": "ROTA GRECA",
    "H588": "ROTELLA",
    "H589": "ROTELLO",
    "H590": "ROTONDA",
    "H591": "ROTONDELLA",
    "H592": "ROTONDI",
    "H593": "ROTTOFRENO",
    "H594": "ROTZO",
    "H555": "ROURE",
    "H596": "ROVAGNATE",
    "H364": "ROVASENDA",
    "H598": "ROVATO",
    "H599": "ROVEGNO",
    "H601": "ROVELLASCA",
    "H602": "ROVELLO PORRO",
    "H604": "ROVERBELLA",
    "H606": "ROVERCHIARA",
    "H607": "ROVERE' DELLA LUNA",
    "H608": "ROVERE' VERONESE",
    "H610": "ROVEREDO DI GUA'",
    "H609": "ROVEREDO IN PIANO",
    "H612": "ROVERETO",
    "H614": "ROVESCALA",
    "H615": "ROVETTA",
    "H618": "ROVIANO",
    "H620": "ROVIGO",
    "H621": "ROVITO",
    "H622": "ROVOLON",
    "H623": "ROZZANO",
    "H625": "RUBANO",
    "H627": "RUBIANA",
    "H628": "RUBIERA",
    "H629": "RUDA",
    "H630": "RUDIANO",
    "H631": "RUEGLIO",
    "H632": "RUFFANO",
    "H633": "RUFFIA",
    "H634": "RUFFRE'-MENDOLA",
    "H635": "RUFINA",
    "F271": "RUINAS",
    "H637": "RUINO",
    "H639": "RUMO",
    "H641": "RUOTI",
    "H642": "RUSSI",
    "H643": "RUTIGLIANO",
    "H644": "RUTINO",
    "H165": "RUVIANO",
    "H646": "RUVO DEL MONTE",
    "H645": "RUVO DI PUGLIA",
    "H647": "SABAUDIA",
    "H648": "SABBIA",
    "H650": "SABBIO CHIESE",
    "H652": "SABBIONETA",
    "H654": "SACCO",
    "H655": "SACCOLONGO",
    "H657": "SACILE",
    "H658": "SACROFANO",
    "H659": "SADALI",
    "H661": "SAGAMA",
    "H662": "SAGLIANO MICCA",
    "H665": "SAGRADO",
    "H666": "SAGRON MIS",
    "H669": "SAINT-CHRISTOPHE",
    "H670": "SAINT-DENIS",
    "H671": "SAINT-MARCEL",
    "H672": "SAINT-NICOLAS",
    "H673": "SAINT-OYEN",
    "H674": "SAINT-PIERRE",
    "H675": "SAINT-RHEMY-EN-BOSSES",
    "H676": "SAINT-VINCENT",
    "H682": "SALA BAGANZA",
    "H681": "SALA BIELLESE",
    "H678": "SALA BOLOGNESE",
    "H679": "SALA COMACINA",
    "H683": "SALA CONSILINA",
    "H677": "SALA MONFERRATO",
    "H687": "SALANDRA",
    "H688": "SALAPARUTA",
    "H689": "SALARA",
    "H690": "SALASCO",
    "H691": "SALASSA",
    "H684": "SALBERTRAND",
    "F810": "SALCEDO",
    "H693": "SALCITO",
    "H694": "SALE",
    "H695": "SALE DELLE LANGHE",
    "H699": "SALE MARASINO",
    "H704": "SALE SAN GIOVANNI",
    "H700": "SALEMI",
    "H686": "SALENTO",
    "H702": "SALERANO CANAVESE",
    "H701": "SALERANO SUL LAMBRO",
    "H703": "SALERNO",
    "H705": "SALETTO",
    "H706": "SALGAREDA",
    "H707": "SALI VERCELLESE",
    "H708": "SALICE SALENTINO",
    "H710": "SALICETO",
    "H713": "SALISANO",
    "H714": "SALIZZOLE",
    "H715": "SALLE",
    "H716": "SALMOUR",
    "H717": "SALO'",
    "H719": "SALORNO .SALURN.",
    "H720": "SALSOMAGGIORE TERME",
    "H721": "SALTARA",
    "H723": "SALTRIO",
    "H724": "SALUDECIO",
    "H725": "SALUGGIA",
    "H726": "SALUSSOLA",
    "H727": "SALUZZO",
    "H729": "SALVE",
    "H731": "SALVIROLA",
    "H732": "SALVITELLE",
    "H734": "SALZA DI PINEROLO",
    "H733": "SALZA IRPINA",
    "H735": "SALZANO",
    "H736": "SAMARATE",
    "H738": "SAMASSI",
    "H739": "SAMATZAI",
    "H743": "SAMBUCA DI SICILIA",
    "H744": "SAMBUCA PISTOIESE",
    "H745": "SAMBUCI",
    "H746": "SAMBUCO",
    "H749": "SAMMICHELE DI BARI",
    "H013": "SAMO",
    "H752": "SAMOLACO",
    "H754": "SAMONE",
    "H753": "SAMONE",
    "H755": "SAMPEYRE",
    "H756": "SAMUGHEO",
    "H763": "SAN BARTOLOMEO AL MARE",
    "H764": "SAN BARTOLOMEO IN GALDO",
    "H760": "SAN BARTOLOMEO VAL CAVARGNA",
    "H765": "SAN BASILE",
    "H766": "SAN BASILIO",
    "H767": "SAN BASSANO",
    "H768": "SAN BELLINO",
    "H770": "SAN BENEDETTO BELBO",
    "H772": "SAN BENEDETTO DEI MARSI",
    "H769": "SAN BENEDETTO DEL TRONTO",
    "H773": "SAN BENEDETTO IN PERILLIS",
    "H771": "SAN BENEDETTO PO",
    "H774": "SAN BENEDETTO ULLANO",
    "G566": "SAN BENEDETTO VAL DI SAMBRO",
    "H775": "SAN BENIGNO CANAVESE",
    "H777": "SAN BERNARDINO VERBANO",
    "H780": "SAN BIAGIO DELLA CIMA",
    "H781": "SAN BIAGIO DI CALLALTA",
    "H778": "SAN BIAGIO PLATANI",
    "H779": "SAN BIAGIO SARACINISCO",
    "H782": "SAN BIASE",
    "H783": "SAN BONIFACIO",
    "H784": "SAN BUONO",
    "H785": "SAN CALOGERO",
    "H786": "SAN CANDIDO .INNICHEN.",
    "H787": "SAN CANZIAN D'ISONZO",
    "H789": "SAN CARLO CANAVESE",
    "H790": "SAN CASCIANO DEI BAGNI",
    "H791": "SAN CASCIANO IN VAL DI PESA",
    "M264": "SAN CASSIANO",
    "H792": "SAN CATALDO",
    "M295": "SAN CESAREO",
    "H793": "SAN CESARIO DI LECCE",
    "H794": "SAN CESARIO SUL PANARO",
    "H795": "SAN CHIRICO NUOVO",
    "H796": "SAN CHIRICO RAPARO",
    "H797": "SAN CIPIRELLO",
    "H798": "SAN CIPRIANO D'AVERSA",
    "H800": "SAN CIPRIANO PICENTINO",
    "H799": "SAN CIPRIANO PO",
    "H801": "SAN CLEMENTE",
    "H803": "SAN COLOMBANO AL LAMBRO",
    "H804": "SAN COLOMBANO BELMONTE",
    "H802": "SAN COLOMBANO CERTENOLI",
    "H805": "SAN CONO",
    "H806": "SAN COSMO ALBANESE",
    "H808": "SAN COSTANTINO ALBANESE",
    "H807": "SAN COSTANTINO CALABRO",
    "H809": "SAN COSTANZO",
    "H810": "SAN CRISTOFORO",
    "H814": "SAN DAMIANO AL COLLE",
    "H811": "SAN DAMIANO D'ASTI",
    "H812": "SAN DAMIANO MACRA",
    "H816": "SAN DANIELE DEL FRIULI",
    "H815": "SAN DANIELE PO",
    "H818": "SAN DEMETRIO CORONE",
    "H819": "SAN DEMETRIO NE' VESTINI",
    "H820": "SAN DIDERO",
    "H823": "SAN DONA' DI PIAVE",
    "H822": "SAN DONACI",
    "H826": "SAN DONATO DI LECCE",
    "H825": "SAN DONATO DI NINEA",
    "H827": "SAN DONATO MILANESE",
    "H824": "SAN DONATO VAL DI COMINO",
    "D324": "SAN DORLIGO DELLA VALLE",
    "H830": "SAN FEDELE INTELVI",
    "H831": "SAN FELE",
    "H834": "SAN FELICE A CANCELLO",
    "H836": "SAN FELICE CIRCEO",
    "H838": "SAN FELICE DEL BENACO",
    "H833": "SAN FELICE DEL MOLISE",
    "H835": "SAN FELICE SUL PANARO",
    "M277": "SAN FERDINANDO",
    "H839": "SAN FERDINANDO DI PUGLIA",
    "H840": "SAN FERMO DELLA BATTAGLIA",
    "H841": "SAN FILI",
    "H842": "SAN FILIPPO DEL MELA",
    "H843": "SAN FIOR",
    "H844": "SAN FIORANO",
    "H845": "SAN FLORIANO DEL COLLIO",
    "H846": "SAN FLORO",
    "H847": "SAN FRANCESCO AL CAMPO",
    "H850": "SAN FRATELLO",
    "H856": "SAN GAVINO MONREALE",
    "H857": "SAN GEMINI",
    "H858": "SAN GENESIO ATESINO .JENESIEN.",
    "H859": "SAN GENESIO ED UNITI",
    "H860": "SAN GENNARO VESUVIANO",
    "H862": "SAN GERMANO CHISONE",
    "H863": "SAN GERMANO DEI BERICI",
    "H861": "SAN GERMANO VERCELLESE",
    "H865": "SAN GERVASIO BRESCIANO",
    "H867": "SAN GIACOMO DEGLI SCHIAVONI",
    "H870": "SAN GIACOMO DELLE SEGNATE",
    "H868": "SAN GIACOMO FILIPPO",
    "B952": "SAN GIACOMO VERCELLESE",
    "H873": "SAN GILLIO",
    "H875": "SAN GIMIGNANO",
    "H876": "SAN GINESIO",
    "H892": "SAN GIORGIO A CREMANO",
    "H880": "SAN GIORGIO A LIRI",
    "H881": "SAN GIORGIO ALBANESE",
    "H890": "SAN GIORGIO CANAVESE",
    "H894": "SAN GIORGIO DEL SANNIO",
    "H891": "SAN GIORGIO DELLA RICHINVELDA",
    "H893": "SAN GIORGIO DELLE PERTICHE",
    "H885": "SAN GIORGIO DI LOMELLINA",
    "H883": "SAN GIORGIO DI MANTOVA",
    "H895": "SAN GIORGIO DI NOGARO",
    "H886": "SAN GIORGIO DI PESARO",
    "H896": "SAN GIORGIO DI PIANO",
    "H897": "SAN GIORGIO IN BOSCO",
    "H882": "SAN GIORGIO IONICO",
    "H898": "SAN GIORGIO LA MOLARA",
    "H888": "SAN GIORGIO LUCANO",
    "H878": "SAN GIORGIO MONFERRATO",
    "H889": "SAN GIORGIO MORGETO",
    "H887": "SAN GIORGIO PIACENTINO",
    "H899": "SAN GIORGIO SCARAMPI",
    "H884": "SAN GIORGIO SU LEGNANO",
    "H900": "SAN GIORIO DI SUSA",
    "H907": "SAN GIOVANNI A PIRO",
    "H906": "SAN GIOVANNI AL NATISONE",
    "H910": "SAN GIOVANNI BIANCO",
    "H911": "SAN GIOVANNI D'ASSO",
    "H912": "SAN GIOVANNI DEL DOSSO",
    "H903": "SAN GIOVANNI DI GERACE",
    "H914": "SAN GIOVANNI GEMINI",
    "H916": "SAN GIOVANNI ILARIONE",
    "H918": "SAN GIOVANNI IN CROCE",
    "H919": "SAN GIOVANNI IN FIORE",
    "H920": "SAN GIOVANNI IN GALDO",
    "H921": "SAN GIOVANNI IN MARIGNANO",
    "G467": "SAN GIOVANNI IN PERSICETO",
    "H917": "SAN GIOVANNI INCARICO",
    "H922": "SAN GIOVANNI LA PUNTA",
    "H923": "SAN GIOVANNI LIPIONI",
    "H924": "SAN GIOVANNI LUPATOTO",
    "H926": "SAN GIOVANNI ROTONDO",
    "G287": "SAN GIOVANNI SUERGIU",
    "D690": "SAN GIOVANNI TEATINO",
    "H901": "SAN GIOVANNI VALDARNO",
    "H928": "SAN GIULIANO DEL SANNIO",
    "H929": "SAN GIULIANO DI PUGLIA",
    "H930": "SAN GIULIANO MILANESE",
    "A562": "SAN GIULIANO TERME",
    "H933": "SAN GIUSEPPE JATO",
    "H931": "SAN GIUSEPPE VESUVIANO",
    "H935": "SAN GIUSTINO",
    "H936": "SAN GIUSTO CANAVESE",
    "H937": "SAN GODENZO",
    "H942": "SAN GREGORIO DA SASSOLA",
    "H940": "SAN GREGORIO DI CATANIA",
    "H941": "SAN GREGORIO D'IPPONA",
    "H943": "SAN GREGORIO MAGNO",
    "H939": "SAN GREGORIO MATESE",
    "H938": "SAN GREGORIO NELLE ALPI",
    "H945": "SAN LAZZARO DI SAVENA",
    "H949": "SAN LEO",
    "H951": "SAN LEONARDO",
    "H952": "SAN LEONARDO IN PASSIRIA .ST LEONHARD IN PAS.",
    "H953": "SAN LEUCIO DEL SANNIO",
    "H955": "SAN LORENZELLO",
    "H959": "SAN LORENZO",
    "H957": "SAN LORENZO AL MARE",
    "H961": "SAN LORENZO BELLIZZI",
    "H962": "SAN LORENZO DEL VALLO",
    "H956": "SAN LORENZO DI SEBATO .ST LORENZEN.",
    "M345": "SAN LORENZO DORSINO",
    "H966": "SAN LORENZO IN BANALE",
    "H958": "SAN LORENZO IN CAMPO",
    "H964": "SAN LORENZO ISONTINO",
    "H967": "SAN LORENZO MAGGIORE",
    "H969": "SAN LORENZO NUOVO",
    "H970": "SAN LUCA",
    "H971": "SAN LUCIDO",
    "H973": "SAN LUPO",
    "H976": "SAN MANGO D'AQUINO",
    "H977": "SAN MANGO PIEMONTE",
    "H975": "SAN MANGO SUL CALORE",
    "H978": "SAN MARCELLINO",
    "H979": "SAN MARCELLO",
    "H980": "SAN MARCELLO PISTOIESE",
    "H981": "SAN MARCO ARGENTANO",
    "H982": "SAN MARCO D'ALUNZIO",
    "H984": "SAN MARCO DEI CAVOTI",
    "F043": "SAN MARCO EVANGELISTA",
    "H985": "SAN MARCO IN LAMIS",
    "H986": "SAN MARCO LA CATOLA",
    "H999": "SAN MARTINO AL TAGLIAMENTO",
    "H987": "SAN MARTINO ALFIERI",
    "I003": "SAN MARTINO BUON ALBERGO",
    "H997": "SAN MARTINO CANAVESE",
    "H994": "SAN MARTINO D'AGRI",
    "I005": "SAN MARTINO DALL'ARGINE",
    "I007": "SAN MARTINO DEL LAGO",
    "H992": "SAN MARTINO DI FINITA",
    "I008": "SAN MARTINO DI LUPARI",
    "H996": "SAN MARTINO DI VENEZZE",
    "H988": "SAN MARTINO IN BADIA .ST MARTIN IN THURN.",
    "H989": "SAN MARTINO IN PASSIRIA .ST MARTIN IN PASSEI.",
    "H990": "SAN MARTINO IN PENSILIS",
    "I011": "SAN MARTINO IN RIO",
    "I012": "SAN MARTINO IN STRADA",
    "I002": "SAN MARTINO SANNITA",
    "I014": "SAN MARTINO SICCOMARIO",
    "H991": "SAN MARTINO SULLA MARRUCINA",
    "I016": "SAN MARTINO VALLE CAUDINA",
    "I018": "SAN MARZANO DI SAN GIUSEPPE",
    "I017": "SAN MARZANO OLIVETO",
    "I019": "SAN MARZANO SUL SARNO",
    "I023": "SAN MASSIMO",
    "I024": "SAN MAURIZIO CANAVESE",
    "I025": "SAN MAURIZIO D'OPAGLIO",
    "I028": "SAN MAURO CASTELVERDE",
    "I031": "SAN MAURO CILENTO",
    "H712": "SAN MAURO DI SALINE",
    "I029": "SAN MAURO FORTE",
    "I032": "SAN MAURO LA BRUCA",
    "I026": "SAN MAURO MARCHESATO",
    "I027": "SAN MAURO PASCOLI",
    "I030": "SAN MAURO TORINESE",
    "I040": "SAN MICHELE AL TAGLIAMENTO",
    "I042": "SAN MICHELE ALL'ADIGE",
    "I035": "SAN MICHELE DI GANZARIA",
    "I034": "SAN MICHELE DI SERINO",
    "I037": "SAN MICHELE MONDOVI'",
    "I045": "SAN MICHELE SALENTINO",
    "I046": "SAN MINIATO",
    "I047": "SAN NAZARIO",
    "I049": "SAN NAZZARO",
    "I052": "SAN NAZZARO SESIA",
    "I051": "SAN NAZZARO VAL CAVARGNA",
    "I054": "SAN NICANDRO GARGANICO",
    "I060": "SAN NICOLA ARCELLA",
    "I061": "SAN NICOLA BARONIA",
    "I058": "SAN NICOLA DA CRISSA",
    "I057": "SAN NICOLA DELL'ALTO",
    "I056": "SAN NICOLA LA STRADA",
    "I062": "SAN NICOLA MANFREDI",
    "A368": "SAN NICOLO' D'ARCIDANO",
    "I063": "SAN NICOLO' DI COMELICO",
    "G383": "SAN NICOLO' GERREI",
    "I065": "SAN PANCRAZIO .ST PANKRAZ.",
    "I066": "SAN PANCRAZIO SALENTINO",
    "G407": "SAN PAOLO",
    "B906": "SAN PAOLO ALBANESE",
    "I073": "SAN PAOLO BEL SITO",
    "I074": "SAN PAOLO CERVO",
    "B310": "SAN PAOLO D'ARGON",
    "I072": "SAN PAOLO DI CIVITATE",
    "I071": "SAN PAOLO DI JESI",
    "I076": "SAN PAOLO SOLBRITO",
    "I079": "SAN PELLEGRINO TERME",
    "I082": "SAN PIER D'ISONZO",
    "I084": "SAN PIER NICETO",
    "I085": "SAN PIERO A SIEVE",
    "I086": "SAN PIERO PATTI",
    "I093": "SAN PIETRO A MAIDA",
    "I092": "SAN PIETRO AL NATISONE",
    "I089": "SAN PIETRO AL TANAGRO",
    "I095": "SAN PIETRO APOSTOLO",
    "I096": "SAN PIETRO AVELLANA",
    "I098": "SAN PIETRO CLARENZA",
    "I088": "SAN PIETRO DI CADORE",
    "I102": "SAN PIETRO DI CARIDA'",
    "I103": "SAN PIETRO DI FELETTO",
    "I105": "SAN PIETRO DI MORUBIO",
    "I108": "SAN PIETRO IN AMANTEA",
    "I109": "SAN PIETRO IN CARIANO",
    "I110": "SAN PIETRO IN CASALE",
    "G788": "SAN PIETRO IN CERRO",
    "I107": "SAN PIETRO IN GU",
    "I114": "SAN PIETRO IN GUARANO",
    "I115": "SAN PIETRO IN LAMA",
    "I113": "SAN PIETRO INFINE",
    "I116": "SAN PIETRO MOSEZZO",
    "I117": "SAN PIETRO MUSSOLINO",
    "I090": "SAN PIETRO VAL LEMINA",
    "I119": "SAN PIETRO VERNOTICO",
    "I120": "SAN PIETRO VIMINARIO",
    "I121": "SAN PIO DELLE CAMERE",
    "I125": "SAN POLO DEI CAVALIERI",
    "I123": "SAN POLO D'ENZA",
    "I124": "SAN POLO DI PIAVE",
    "I122": "SAN POLO MATESE",
    "I126": "SAN PONSO",
    "I128": "SAN POSSIDONIO",
    "I130": "SAN POTITO SANNITICO",
    "I129": "SAN POTITO ULTRA",
    "I131": "SAN PRISCO",
    "I132": "SAN PROCOPIO",
    "I133": "SAN PROSPERO",
    "I135": "SAN QUIRICO D'ORCIA",
    "I136": "SAN QUIRINO",
    "I137": "SAN RAFFAELE CIMENA",
    "I139": "SAN ROBERTO",
    "I140": "SAN ROCCO AL PORTO",
    "I142": "SAN ROMANO IN GARFAGNANA",
    "I143": "SAN RUFO",
    "I147": "SAN SALVATORE DI FITALIA",
    "I144": "SAN SALVATORE MONFERRATO",
    "I145": "SAN SALVATORE TELESINO",
    "I148": "SAN SALVO",
    "I151": "SAN SEBASTIANO AL VESUVIO",
    "I150": "SAN SEBASTIANO CURONE",
    "I152": "SAN SEBASTIANO DA PO",
    "I154": "SAN SECONDO DI PINEROLO",
    "I153": "SAN SECONDO PARMENSE",
    "I157": "SAN SEVERINO LUCANO",
    "I156": "SAN SEVERINO MARCHE",
    "I158": "SAN SEVERO",
    "I162": "SAN SIRO",
    "I163": "SAN SOSSIO BARONIA",
    "I164": "SAN SOSTENE",
    "I165": "SAN SOSTI",
    "I166": "SAN SPERATE",
    "I373": "SAN STINO DI LIVENZA",
    "I261": "SAN TAMMARO",
    "I328": "SAN TEODORO",
    "I329": "SAN TEODORO",
    "I347": "SAN TOMASO AGORDINO",
    "I376": "SAN VALENTINO IN ABRUZZO CITERIORE",
    "I377": "SAN VALENTINO TORIO",
    "I381": "SAN VENANZO",
    "I382": "SAN VENDEMIANO",
    "I384": "SAN VERO MILIS",
    "I390": "SAN VINCENZO",
    "I388": "SAN VINCENZO LA COSTA",
    "I389": "SAN VINCENZO VALLE ROVETO",
    "I391": "SAN VITALIANO",
    "I402": "SAN VITO",
    "I403": "SAN VITO AL TAGLIAMENTO",
    "I404": "SAN VITO AL TORRE",
    "I394": "SAN VITO CHIETINO",
    "I396": "SAN VITO DEI NORMANNI",
    "I392": "SAN VITO DI CADORE",
    "I405": "SAN VITO DI FAGAGNA",
    "I401": "SAN VITO DI LEGUZZANO",
    "I407": "SAN VITO LO CAPO",
    "I400": "SAN VITO ROMANO",
    "I393": "SAN VITO SULLO IONIO",
    "I408": "SAN VITTORE DEL LAZIO",
    "I409": "SAN VITTORE OLONA",
    "I414": "SAN ZENO DI MONTAGNA",
    "I412": "SAN ZENO NAVIGLIO",
    "I415": "SAN ZENONE AL LAMBRO",
    "I416": "SAN ZENONE AL PO",
    "I417": "SAN ZENONE DEGLI EZZELINI",
    "H757": "SANARICA",
    "H821": "SANDIGLIANO",
    "H829": "SANDRIGO",
    "H851": "SANFRE'",
    "H852": "SANFRONT",
    "H855": "SANGANO",
    "H872": "SANGIANO",
    "H877": "SANGINETO",
    "H944": "SANGUINETTO",
    "H974": "SANLURI",
    "I048": "SANNAZZARO DE' BURGONDI",
    "I053": "SANNICANDRO DI BARI",
    "I059": "SANNICOLA",
    "I138": "SANREMO",
    "I155": "SANSEPOLCRO",
    "I168": "SANTA BRIGIDA",
    "I171": "SANTA CATERINA ALBANESE",
    "I170": "SANTA CATERINA DELLO IONIO",
    "I169": "SANTA CATERINA VILLARMOSA",
    "I172": "SANTA CESAREA TERME",
    "I176": "SANTA CRISTINA D'ASPROMONTE",
    "I175": "SANTA CRISTINA E BISSONE",
    "I174": "SANTA CRISTINA GELA",
    "I173": "SANTA CRISTINA VALGARDENA .ST CHRISTINA IN",
    "I178": "SANTA CROCE CAMERINA",
    "I179": "SANTA CROCE DEL SANNIO",
    "I181": "SANTA CROCE DI MAGLIANO",
    "I177": "SANTA CROCE SULL'ARNO",
    "I183": "SANTA DOMENICA TALAO",
    "I184": "SANTA DOMENICA VITTORIA",
    "I185": "SANTA ELISABETTA",
    "I187": "SANTA FIORA",
    "I188": "SANTA FLAVIA",
    "I203": "SANTA GIULETTA",
    "I205": "SANTA GIUSTA",
    "I206": "SANTA GIUSTINA",
    "I207": "SANTA GIUSTINA IN COLLE",
    "I217": "SANTA LUCE",
    "I220": "SANTA LUCIA DEL MELA",
    "I221": "SANTA LUCIA DI PIAVE",
    "I219": "SANTA LUCIA DI SERINO",
    "I226": "SANTA MARGHERITA D'ADIGE",
    "I224": "SANTA MARGHERITA DI BELICE",
    "I230": "SANTA MARGHERITA DI STAFFORA",
    "I225": "SANTA MARGHERITA LIGURE",
    "I232": "SANTA MARIA A MONTE",
    "I233": "SANTA MARIA A VICO",
    "I234": "SANTA MARIA CAPUA VETERE",
    "M284": "SANTA MARIA COGHINAS",
    "C717": "SANTA MARIA DEL CEDRO",
    "I238": "SANTA MARIA DEL MOLISE",
    "I237": "SANTA MARIA DELLA VERSA",
    "I240": "SANTA MARIA DI LICODIA",
    "I242": "SANTA MARIA DI SALA",
    "I243": "SANTA MARIA HOE'",
    "I244": "SANTA MARIA IMBARO",
    "M273": "SANTA MARIA LA CARITA'",
    "I247": "SANTA MARIA LA FOSSA",
    "I248": "SANTA MARIA LA LONGA",
    "I249": "SANTA MARIA MAGGIORE",
    "I251": "SANTA MARIA NUOVA",
    "I253": "SANTA MARINA",
    "I254": "SANTA MARINA SALINA",
    "I255": "SANTA MARINELLA",
    "I291": "SANTA NINFA",
    "I301": "SANTA PAOLINA",
    "I308": "SANTA SEVERINA",
    "I310": "SANTA SOFIA",
    "I309": "SANTA SOFIA D'EPIRO",
    "I311": "SANTA TERESA DI RIVA",
    "I312": "SANTA TERESA GALLURA",
    "I314": "SANTA VENERINA",
    "I316": "SANTA VITTORIA D'ALBA",
    "I315": "SANTA VITTORIA IN MATENANO",
    "I182": "SANTADI",
    "I189": "SANT'AGAPITO",
    "I191": "SANT'AGATA BOLOGNESE",
    "I197": "SANT'AGATA DE' GOTI",
    "I198": "SANT'AGATA DEL BIANCO",
    "I192": "SANT'AGATA DI ESARO",
    "I199": "SANT'AGATA DI MILITELLO",
    "I193": "SANT'AGATA DI PUGLIA",
    "I201": "SANT'AGATA FELTRIA",
    "I190": "SANT'AGATA FOSSILI",
    "I202": "SANT'AGATA LI BATTIATI",
    "I196": "SANT'AGATA SUL SANTERNO",
    "I208": "SANT'AGNELLO",
    "I209": "SANT'AGOSTINO",
    "I210": "SANT'ALBANO STURA",
    "I213": "SANT'ALESSIO CON VIALONE",
    "I214": "SANT'ALESSIO IN ASPROMONTE",
    "I215": "SANT'ALESSIO SICULO",
    "I216": "SANT'ALFIO",
    "I258": "SANT'AMBROGIO DI TORINO",
    "I259": "SANT'AMBROGIO DI VALPOLICELLA",
    "I256": "SANT'AMBROGIO SUL GARIGLIANO",
    "I262": "SANT'ANASTASIA",
    "I263": "SANT'ANATOLIA DI NARCO",
    "I266": "SANT'ANDREA APOSTOLO DELLO IONIO",
    "I265": "SANT'ANDREA DEL GARIGLIANO",
    "I264": "SANT'ANDREA DI CONZA",
    "I271": "SANT'ANDREA FRIUS",
    "I277": "SANT'ANGELO A CUPOLO",
    "I278": "SANT'ANGELO A FASANELLA",
    "I280": "SANT'ANGELO A SCALA",
    "I279": "SANT'ANGELO ALL'ESCA",
    "I273": "SANT'ANGELO D'ALIFE",
    "I281": "SANT'ANGELO DEI LOMBARDI",
    "I282": "SANT'ANGELO DEL PESCO",
    "I283": "SANT'ANGELO DI BROLO",
    "I275": "SANT'ANGELO DI PIOVE DI SACCO",
    "I285": "SANT'ANGELO IN LIZZOLA",
    "I286": "SANT'ANGELO IN PONTANO",
    "I287": "SANT'ANGELO IN VADO",
    "I288": "SANT'ANGELO LE FRATTE",
    "I289": "SANT'ANGELO LIMOSANO",
    "I274": "SANT'ANGELO LODIGIANO",
    "I276": "SANT'ANGELO LOMELLINA",
    "I290": "SANT'ANGELO MUXARO",
    "I284": "SANT'ANGELO ROMANO",
    "M209": "SANT'ANNA ARRESI",
    "I292": "SANT'ANNA D'ALFAEDO",
    "I293": "SANT'ANTIMO",
    "I294": "SANT'ANTIOCO",
    "I296": "SANT'ANTONINO DI SUSA",
    "I300": "SANT'ANTONIO ABATE",
    "M276": "SANT'ANTONIO DI GALLURA",
    "I302": "SANT'APOLLINARE",
    "I305": "SANT'ARCANGELO",
    "I304": "SANTARCANGELO DI ROMAGNA",
    "F557": "SANT'ARCANGELO TRIMONTE",
    "I306": "SANT'ARPINO",
    "I307": "SANT'ARSENIO",
    "I326": "SANTE MARIE",
    "I318": "SANT'EGIDIO ALLA VIBRATA",
    "I317": "SANT'EGIDIO DEL MONTE ALBINO",
    "I319": "SANT'ELENA",
    "B466": "SANT'ELENA SANNITA",
    "I320": "SANT'ELIA A PIANISI",
    "I321": "SANT'ELIA FIUMERAPIDO",
    "I324": "SANT'ELPIDIO A MARE",
    "I327": "SANTENA",
    "I330": "SANTERAMO IN COLLE",
    "I332": "SANT'EUFEMIA A MAIELLA",
    "I333": "SANT'EUFEMIA D'ASPROMONTE",
    "I335": "SANT'EUSANIO DEL SANGRO",
    "I336": "SANT'EUSANIO FORCONESE",
    "I337": "SANTHIA'",
    "I339": "SANTI COSMA E DAMIANO",
    "I341": "SANT'ILARIO DELLO IONIO",
    "I342": "SANT'ILARIO D'ENZA",
    "I344": "SANT'IPPOLITO",
    "I365": "SANTO STEFANO AL MARE",
    "I367": "SANTO STEFANO BELBO",
    "I368": "SANTO STEFANO D'AVETO",
    "I357": "SANTO STEFANO DEL SOLE",
    "C919": "SANTO STEFANO DI CADORE",
    "I370": "SANTO STEFANO DI CAMASTRA",
    "I363": "SANTO STEFANO DI MAGRA",
    "I359": "SANTO STEFANO DI ROGLIANO",
    "I360": "SANTO STEFANO DI SESSANIO",
    "I371": "SANTO STEFANO IN ASPROMONTE",
    "I362": "SANTO STEFANO LODIGIANO",
    "I356": "SANTO STEFANO QUISQUINA",
    "I372": "SANTO STEFANO ROERO",
    "I361": "SANTO STEFANO TICINO",
    "I346": "SANT'OLCESE",
    "I260": "SANTOMENNA",
    "I348": "SANT'OMERO",
    "M333": "SANT'OMOBONO TERME",
    "I349": "SANT'OMOBONO TERME",
    "I350": "SANT'ONOFRIO",
    "I351": "SANTOPADRE",
    "I352": "SANT'ORESTE",
    "I353": "SANTORSO",
    "I354": "SANT'ORSOLA TERME",
    "I374": "SANTU LUSSURGIU",
    "I375": "SANT'URBANO",
    "I410": "SANZA",
    "I411": "SANZENO",
    "I418": "SAONARA",
    "I420": "SAPONARA",
    "I421": "SAPPADA",
    "I422": "SAPRI",
    "I423": "SARACENA",
    "I424": "SARACINESCO",
    "I425": "SARCEDO",
    "I426": "SARCONI",
    "I428": "SARDARA",
    "I429": "SARDIGLIANO",
    "I430": "SAREGO",
    "I431": "SARENTINO .SARNTAL.",
    "I432": "SAREZZANO",
    "I433": "SAREZZO",
    "I434": "SARMATO",
    "I435": "SARMEDE",
    "I436": "SARNANO",
    "I437": "SARNICO",
    "I438": "SARNO",
    "I439": "SARNONICO",
    "I441": "SARONNO",
    "I442": "SARRE",
    "I443": "SARROCH",
    "I444": "SARSINA",
    "I445": "SARTEANO",
    "I447": "SARTIRANA LOMELLINA",
    "I448": "SARULE",
    "I449": "SARZANA",
    "I451": "SASSANO",
    "I452": "SASSARI",
    "I453": "SASSELLO",
    "I454": "SASSETTA",
    "I455": "SASSINORO",
    "I457": "SASSO DI CASTALDA",
    "G972": "SASSO MARCONI",
    "I459": "SASSOCORVARO",
    "I460": "SASSOFELTRIO",
    "I461": "SASSOFERRATO",
    "I462": "SASSUOLO",
    "I463": "SATRIANO",
    "G614": "SATRIANO DI LUCANIA",
    "I464": "SAURIS",
    "I465": "SAUZE DI CESANA",
    "I466": "SAUZE D'OULX",
    "I467": "SAVA",
    "I468": "SAVELLI",
    "I469": "SAVIANO",
    "I470": "SAVIGLIANO",
    "I471": "SAVIGNANO IRPINO",
    "I473": "SAVIGNANO SUL PANARO",
    "I472": "SAVIGNANO SUL RUBICONE",
    "I474": "SAVIGNO",
    "I475": "SAVIGNONE",
    "I476": "SAVIORE DELL'ADAMELLO",
    "I477": "SAVOCA",
    "I478": "SAVOGNA",
    "I479": "SAVOGNA D'ISONZO",
    "H730": "SAVOIA DI LUCANIA",
    "I480": "SAVONA",
    "I482": "SCAFA",
    "I483": "SCAFATI",
    "I484": "SCAGNELLO",
    "I486": "SCALA",
    "I485": "SCALA COELI",
    "I487": "SCALDASOLE",
    "I489": "SCALEA",
    "I490": "SCALENGHE",
    "I492": "SCALETTA ZANCLEA",
    "I493": "SCAMPITELLA",
    "I494": "SCANDALE",
    "I496": "SCANDIANO",
    "B962": "SCANDICCI",
    "I497": "SCANDOLARA RAVARA",
    "I498": "SCANDOLARA RIPA D'OGLIO",
    "I499": "SCANDRIGLIA",
    "I501": "SCANNO",
    "I503": "SCANO DI MONTIFERRO",
    "I504": "SCANSANO",
    "M256": "SCANZANO JONICO",
    "I506": "SCANZOROSCIATE",
    "I507": "SCAPOLI",
    "I510": "SCARLINO",
    "I511": "SCARMAGNO",
    "I512": "SCARNAFIGI",
    "I514": "SCARPERIA",
    "M326": "SCARPERIA E SAN PIERO",
    "I519": "SCENA .SCHENNA.",
    "I520": "SCERNI",
    "I522": "SCHEGGIA E PASCELUPO",
    "I523": "SCHEGGINO",
    "I526": "SCHIAVI DI ABRUZZO",
    "I527": "SCHIAVON",
    "I529": "SCHIGNANO",
    "I530": "SCHILPARIO",
    "I531": "SCHIO",
    "I532": "SCHIVENOGLIA",
    "I533": "SCIACCA",
    "I534": "SCIARA",
    "I535": "SCICLI",
    "I536": "SCIDO",
    "D290": "SCIGLIANO",
    "I537": "SCILLA",
    "I538": "SCILLATO",
    "I539": "SCIOLZE",
    "I540": "SCISCIANO",
    "I541": "SCLAFANI BAGNI",
    "I543": "SCONTRONE",
    "I544": "SCOPA",
    "I545": "SCOPELLO",
    "I546": "SCOPPITO",
    "I548": "SCORDIA",
    "I549": "SCORRANO",
    "I551": "SCORZE'",
    "I553": "SCURCOLA MARSICANA",
    "I554": "SCURELLE",
    "I555": "SCURZOLENGO",
    "I556": "SEBORGA",
    "I558": "SECINARO",
    "I559": "SECLI'",
    "I561": "SECUGNAGO",
    "I562": "SEDEGLIANO",
    "I563": "SEDICO",
    "I564": "SEDILO",
    "I565": "SEDINI",
    "I566": "SEDRIANO",
    "I567": "SEDRINA",
    "I569": "SEFRO",
    "I570": "SEGARIU",
    "I571": "SEGGIANO",
    "I573": "SEGNI",
    "I576": "SEGONZANO",
    "I577": "SEGRATE",
    "I578": "SEGUSINO",
    "I580": "SELARGIUS",
    "I581": "SELCI",
    "I582": "SELEGAS",
    "M360": "SELLA GIUDICARIE",
    "I585": "SELLANO",
    "I588": "SELLERO",
    "I589": "SELLIA",
    "I590": "SELLIA MARINA",
    "I593": "SELVA DEI MOLINI .MUEHLWALD.",
    "I592": "SELVA DI CADORE",
    "I594": "SELVA DI PROGNO",
    "I591": "SELVA DI VAL GARDENA .WOLKENSTEIN IN GROEDEN.",
    "I595": "SELVAZZANO DENTRO",
    "I596": "SELVE MARCONE",
    "I597": "SELVINO",
    "I598": "SEMESTENE",
    "I599": "SEMIANA",
    "I600": "SEMINARA",
    "I601": "SEMPRONIANO",
    "I602": "SENAGO",
    "I604": "SENALES .SCHNALS.",
    "I603": "SENALE-SAN FELICE .UNSERE LIEBE FRAU IM WALD.",
    "I605": "SENEGHE",
    "I606": "SENERCHIA",
    "I607": "SENIGA",
    "I608": "SENIGALLIA",
    "I609": "SENIS",
    "I610": "SENISE",
    "I611": "SENNA COMASCO",
    "I612": "SENNA LODIGIANA",
    "I613": "SENNARIOLO",
    "I614": "SENNORI",
    "I615": "SENORBI'",
    "I618": "SEPINO",
    "I619": "SEPPIANA",
    "I621": "SEQUALS",
    "I622": "SERAVEZZA",
    "I624": "SERDIANA",
    "I625": "SEREGNO",
    "I626": "SEREN DEL GRAPPA",
    "I627": "SERGNANO",
    "I628": "SERIATE",
    "I629": "SERINA",
    "I630": "SERINO",
    "I631": "SERLE",
    "I632": "SERMIDE",
    "I634": "SERMONETA",
    "I635": "SERNAGLIA DELLA BATTAGLIA",
    "I636": "SERNIO",
    "I637": "SEROLE",
    "I642": "SERRA D'AIELLO",
    "I643": "SERRA DE' CONTI",
    "I650": "SERRA PEDACE",
    "I640": "SERRA RICCO'",
    "I639": "SERRA SAN BRUNO",
    "I653": "SERRA SAN QUIRICO",
    "I654": "SERRA SANT'ABBONDIO",
    "I641": "SERRACAPRIOLA",
    "I644": "SERRADIFALCO",
    "I646": "SERRALUNGA D'ALBA",
    "I645": "SERRALUNGA DI CREA",
    "I647": "SERRAMANNA",
    "F357": "SERRAMAZZONI",
    "I648": "SERRAMEZZANA",
    "I649": "SERRAMONACESCA",
    "I651": "SERRAPETRONA",
    "I652": "SERRARA FONTANA",
    "I655": "SERRASTRETTA",
    "I656": "SERRATA",
    "I662": "SERRAVALLE A PO",
    "I661": "SERRAVALLE DI CHIENTI",
    "I659": "SERRAVALLE LANGHE",
    "I660": "SERRAVALLE PISTOIESE",
    "I657": "SERRAVALLE SCRIVIA",
    "I663": "SERRAVALLE SESIA",
    "I666": "SERRE",
    "I667": "SERRENTI",
    "I668": "SERRI",
    "I669": "SERRONE",
    "I670": "SERRUNGARINA",
    "I671": "SERSALE",
    "C070": "SERVIGLIANO",
    "I676": "SESSA AURUNCA",
    "I677": "SESSA CILENTO",
    "I678": "SESSAME",
    "I679": "SESSANO DEL MOLISE",
    "E070": "SESTA GODANO",
    "I681": "SESTINO",
    "I687": "SESTO .SEXTEN.",
    "I686": "SESTO AL REGHENA",
    "I688": "SESTO CALENDE",
    "I682": "SESTO CAMPANO",
    "I683": "SESTO ED UNITI",
    "I684": "SESTO FIORENTINO",
    "I690": "SESTO SAN GIOVANNI",
    "I689": "SESTOLA",
    "I693": "SESTRI LEVANTE",
    "I692": "SESTRIERE",
    "I695": "SESTU",
    "I696": "SETTALA",
    "I697": "SETTEFRATI",
    "I698": "SETTIME",
    "I700": "SETTIMO MILANESE",
    "I701": "SETTIMO ROTTARO",
    "I699": "SETTIMO SAN PIETRO",
    "I703": "SETTIMO TORINESE",
    "I702": "SETTIMO VITTONE",
    "I704": "SETTINGIANO",
    "I705": "SETZU",
    "I706": "SEUI",
    "I707": "SEULO",
    "I709": "SEVESO",
    "I711": "SEZZADIO",
    "I712": "SEZZE",
    "I714": "SFRUZ",
    "I715": "SGONICO",
    "I716": "SGURGOLA",
    "I717": "SIAMAGGIORE",
    "I718": "SIAMANNA",
    "I720": "SIANO",
    "I721": "SIAPICCIA",
    "M253": "SICIGNANO DEGLI ALBURNI",
    "I723": "SICULIANA",
    "I724": "SIDDI",
    "I725": "SIDERNO",
    "I726": "SIENA",
    "I727": "SIGILLO",
    "I728": "SIGNA",
    "I729": "SILANDRO .SCHLANDERS.",
    "I730": "SILANUS",
    "F116": "SILEA",
    "I732": "SILIGO",
    "I734": "SILIQUA",
    "I735": "SILIUS",
    "I737": "SILLANO",
    "M347": "SILLANO GIUNCUGNANO",
    "I736": "SILLAVENGO",
    "I738": "SILVANO D'ORBA",
    "I739": "SILVANO PIETRA",
    "I741": "SILVI",
    "I742": "SIMALA",
    "I743": "SIMAXIS",
    "I744": "SIMBARIO",
    "I745": "SIMERI CRICHI",
    "I747": "SINAGRA",
    "A468": "SINALUNGA",
    "I748": "SINDIA",
    "I749": "SINI",
    "I750": "SINIO",
    "I751": "SINISCOLA",
    "I752": "SINNAI",
    "I753": "SINOPOLI",
    "I754": "SIRACUSA",
    "I756": "SIRIGNANO",
    "I757": "SIRIS",
    "I633": "SIRMIONE",
    "I758": "SIROLO",
    "I759": "SIRONE",
    "I760": "SIROR",
    "I761": "SIRTORI",
    "I763": "SISSA",
    "M325": "SISSA TRECASALI",
    "I765": "SIURGUS DONIGALA",
    "E265": "SIZIANO",
    "I767": "SIZZANO",
    "I771": "SLUDERNO .SCHLUDERNS.",
    "I772": "SMARANO",
    "I774": "SMERILLO",
    "I775": "SOAVE",
    "I777": "SOCCHIEVE",
    "I778": "SODDI'",
    "I779": "SOGLIANO AL RUBICONE",
    "I780": "SOGLIANO CAVOUR",
    "I781": "SOGLIO",
    "I782": "SOIANO DEL LAGO",
    "I783": "SOLAGNA",
    "I785": "SOLARINO",
    "I786": "SOLARO",
    "I787": "SOLAROLO",
    "I790": "SOLAROLO RAINERIO",
    "I791": "SOLARUSSA",
    "I792": "SOLBIATE",
    "I793": "SOLBIATE ARNO",
    "I794": "SOLBIATE OLONA",
    "I796": "SOLDANO",
    "I797": "SOLEMINIS",
    "I798": "SOLERO",
    "I799": "SOLESINO",
    "I800": "SOLETO",
    "I801": "SOLFERINO",
    "I802": "SOLIERA",
    "I803": "SOLIGNANO",
    "I805": "SOLOFRA",
    "I808": "SOLONGHELLO",
    "I809": "SOLOPACA",
    "I812": "SOLTO COLLINA",
    "I813": "SOLZA",
    "I815": "SOMAGLIA",
    "I817": "SOMANO",
    "I819": "SOMMA LOMBARDO",
    "I820": "SOMMA VESUVIANA",
    "I821": "SOMMACAMPAGNA",
    "I822": "SOMMARIVA DEL BOSCO",
    "I823": "SOMMARIVA PERNO",
    "I824": "SOMMATINO",
    "I825": "SOMMO",
    "I826": "SONA",
    "I827": "SONCINO",
    "I828": "SONDALO",
    "I829": "SONDRIO",
    "I830": "SONGAVAZZO",
    "I831": "SONICO",
    "I832": "SONNINO",
    "I835": "SOPRANA",
    "I838": "SORA",
    "I839": "SORAGA",
    "I840": "SORAGNA",
    "I841": "SORANO",
    "I844": "SORBO SAN BASILE",
    "I843": "SORBO SERPICO",
    "I845": "SORBOLO",
    "I847": "SORDEVOLO",
    "I848": "SORDIO",
    "I849": "SORESINA",
    "I850": "SORGA'",
    "I851": "SORGONO",
    "I852": "SORI",
    "I853": "SORIANELLO",
    "I854": "SORIANO CALABRO",
    "I855": "SORIANO NEL CIMINO",
    "I856": "SORICO",
    "I857": "SORISO",
    "I858": "SORISOLE",
    "I860": "SORMANO",
    "I861": "SORRADILE",
    "I862": "SORRENTO",
    "I863": "SORSO",
    "I864": "SORTINO",
    "I865": "SOSPIRO",
    "I866": "SOSPIROLO",
    "I867": "SOSSANO",
    "I868": "SOSTEGNO",
    "I869": "SOTTO IL MONTE GIOVANNI XXIII",
    "I871": "SOVER",
    "I872": "SOVERATO",
    "I873": "SOVERE",
    "I874": "SOVERIA MANNELLI",
    "I875": "SOVERIA SIMERI",
    "I876": "SOVERZENE",
    "I877": "SOVICILLE",
    "I878": "SOVICO",
    "I879": "SOVIZZO",
    "I673": "SOVRAMONTE",
    "I880": "SOZZAGO",
    "I881": "SPADAFORA",
    "I884": "SPADOLA",
    "I885": "SPARANISE",
    "I886": "SPARONE",
    "I887": "SPECCHIA",
    "I888": "SPELLO",
    "I889": "SPERA",
    "I891": "SPERLINGA",
    "I892": "SPERLONGA",
    "I893": "SPERONE",
    "I894": "SPESSA",
    "I895": "SPEZZANO ALBANESE",
    "I896": "SPEZZANO DELLA SILA",
    "I898": "SPEZZANO PICCOLO",
    "I899": "SPIAZZO",
    "I901": "SPIGNO MONFERRATO",
    "I902": "SPIGNO SATURNIA",
    "I903": "SPILAMBERTO",
    "I904": "SPILIMBERGO",
    "I905": "SPILINGA",
    "I906": "SPINADESCO",
    "I907": "SPINAZZOLA",
    "I908": "SPINEA",
    "I909": "SPINEDA",
    "I910": "SPINETE",
    "I911": "SPINETO SCRIVIA",
    "I912": "SPINETOLI",
    "I914": "SPINO D'ADDA",
    "I916": "SPINONE AL LAGO",
    "I917": "SPINOSO",
    "I919": "SPIRANO",
    "I921": "SPOLETO",
    "I922": "SPOLTORE",
    "I923": "SPONGANO",
    "I924": "SPORMAGGIORE",
    "I925": "SPORMINORE",
    "I926": "SPOTORNO",
    "I927": "SPRESIANO",
    "I928": "SPRIANA",
    "I929": "SQUILLACE",
    "I930": "SQUINZANO",
    "I932": "STAFFOLO",
    "I935": "STAGNO LOMBARDO",
    "I936": "STAITI",
    "I937": "STALETTI'",
    "I938": "STANGHELLA",
    "I939": "STARANZANO",
    "M298": "STATTE",
    "I941": "STAZZANO",
    "I942": "STAZZEMA",
    "I943": "STAZZONA",
    "I945": "STEFANACONI",
    "I946": "STELLA",
    "G887": "STELLA CILENTO",
    "I947": "STELLANELLO",
    "I948": "STELVIO .STILFS.",
    "I949": "STENICO",
    "I950": "STERNATIA",
    "I951": "STEZZANO",
    "I952": "STIA",
    "I953": "STIENTA",
    "I954": "STIGLIANO",
    "I955": "STIGNANO",
    "I956": "STILO",
    "I959": "STIMIGLIANO",
    "M290": "STINTINO",
    "I960": "STIO",
    "I962": "STORNARA",
    "I963": "STORNARELLA",
    "I964": "STORO",
    "I965": "STRA",
    "I968": "STRADELLA",
    "I969": "STRAMBINELLO",
    "I970": "STRAMBINO",
    "I973": "STRANGOLAGALLI",
    "I974": "STREGNA",
    "I975": "STREMBO",
    "I976": "STRESA",
    "I977": "STREVI",
    "I978": "STRIANO",
    "I979": "STRIGNO",
    "I980": "STRONA",
    "I981": "STRONCONE",
    "I982": "STRONGOLI",
    "I984": "STROPPIANA",
    "I985": "STROPPO",
    "I986": "STROZZA",
    "I990": "STURNO",
    "B014": "SUARDI",
    "I991": "SUBBIANO",
    "I992": "SUBIACO",
    "I993": "SUCCIVO",
    "I994": "SUEGLIO",
    "I995": "SUELLI",
    "I996": "SUELLO",
    "I997": "SUISIO",
    "I998": "SULBIATE",
    "I804": "SULMONA",
    "L002": "SULZANO",
    "L003": "SUMIRAGO",
    "L004": "SUMMONTE",
    "L006": "SUNI",
    "L007": "SUNO",
    "L008": "SUPERSANO",
    "L009": "SUPINO",
    "L010": "SURANO",
    "L011": "SURBO",
    "L013": "SUSA",
    "L014": "SUSEGANA",
    "L015": "SUSTINENTE",
    "L016": "SUTERA",
    "L017": "SUTRI",
    "L018": "SUTRIO",
    "L019": "SUVERETO",
    "L020": "SUZZARA",
    "L022": "TACENO",
    "L023": "TADASUNI",
    "L024": "TAGGIA",
    "L025": "TAGLIACOZZO",
    "L026": "TAGLIO DI PO",
    "L027": "TAGLIOLO MONFERRATO",
    "L030": "TAIBON AGORDINO",
    "L032": "TAINO",
    "L033": "TAIO",
    "G736": "TAIPANA",
    "L034": "TALAMELLO",
    "L035": "TALAMONA",
    "L036": "TALANA",
    "L037": "TALEGGIO",
    "L038": "TALLA",
    "L039": "TALMASSONS",
    "L040": "TAMBRE",
    "L042": "TAORMINA",
    "L044": "TAPOGLIANO",
    "L046": "TARANO",
    "L047": "TARANTA PELIGNA",
    "L048": "TARANTASCA",
    "L049": "TARANTO",
    "L050": "TARCENTO",
    "D024": "TARQUINIA",
    "L055": "TARSIA",
    "L056": "TARTANO",
    "L057": "TARVISIO",
    "L058": "TARZO",
    "L059": "TASSAROLO",
    "L060": "TASSULLO",
    "L061": "TAURANO",
    "L062": "TAURASI",
    "L063": "TAURIANOVA",
    "L064": "TAURISANO",
    "L065": "TAVAGNACCO",
    "L066": "TAVAGNASCO",
    "L067": "TAVARNELLE VAL DI PESA",
    "F260": "TAVAZZANO CON VILLAVESCO",
    "L069": "TAVENNA",
    "L070": "TAVERNA",
    "L071": "TAVERNERIO",
    "L073": "TAVERNOLA BERGAMASCA",
    "C698": "TAVERNOLE SUL MELLA",
    "L074": "TAVIANO",
    "L075": "TAVIGLIANO",
    "L078": "TAVOLETO",
    "L081": "TAVULLIA",
    "L082": "TEANA",
    "L083": "TEANO",
    "D292": "TEGGIANO",
    "L084": "TEGLIO",
    "L085": "TEGLIO VENETO",
    "L086": "TELESE TERME",
    "L087": "TELGATE",
    "L088": "TELTI",
    "L089": "TELVE",
    "L090": "TELVE DI SOPRA",
    "L093": "TEMPIO PAUSANIA",
    "L094": "TEMU'",
    "L096": "TENNA",
    "L097": "TENNO",
    "L100": "TEOLO",
    "L101": "TEOR",
    "L102": "TEORA",
    "L103": "TERAMO",
    "L104": "TERDOBBIATE",
    "L105": "TERELLE",
    "L106": "TERENTO .TERENTEN.",
    "E548": "TERENZO",
    "M282": "TERGU",
    "L107": "TERLAGO",
    "L108": "TERLANO .TERLAN.",
    "L109": "TERLIZZI",
    "M210": "TERME VIGLIATORE",
    "L111": "TERMENO SULLA STRADA DEL VINO .TRAMIN AN DER.",
    "L112": "TERMINI IMERESE",
    "L113": "TERMOLI",
    "L115": "TERNATE",
    "L116": "TERNENGO",
    "L117": "TERNI",
    "L118": "TERNO D'ISOLA",
    "L120": "TERRACINA",
    "L121": "TERRAGNOLO",
    "L122": "TERRALBA",
    "L124": "TERRANOVA DA SIBARI",
    "L125": "TERRANOVA DEI PASSERINI",
    "L126": "TERRANOVA DI POLLINO",
    "L127": "TERRANOVA SAPPO MINULIO",
    "L123": "TERRANUOVA BRACCIOLINI",
    "L131": "TERRASINI",
    "L132": "TERRASSA PADOVANA",
    "L134": "TERRAVECCHIA",
    "L136": "TERRAZZO",
    "L137": "TERRES",
    "L138": "TERRICCIOLA",
    "L139": "TERRUGGIA",
    "L140": "TERTENIA",
    "L142": "TERZIGNO",
    "L143": "TERZO",
    "L144": "TERZO DI AQUILEIA",
    "L145": "TERZOLAS",
    "L146": "TERZORIO",
    "L147": "TESERO",
    "L149": "TESIMO .TISENS.",
    "L150": "TESSENNANO",
    "L152": "TESTICO",
    "L153": "TETI",
    "L154": "TEULADA",
    "L155": "TEVEROLA",
    "L156": "TEZZE SUL BRENTA",
    "L157": "THIENE",
    "L158": "THIESI",
    "L160": "TIANA",
    "L162": "TIARNO DI SOPRA",
    "L163": "TIARNO DI SOTTO",
    "L164": "TICENGO",
    "L165": "TICINETO",
    "L166": "TIGGIANO",
    "L167": "TIGLIETO",
    "L168": "TIGLIOLE",
    "L169": "TIGNALE",
    "L172": "TINNURA",
    "L173": "TIONE DEGLI ABRUZZI",
    "L174": "TIONE DI TRENTO",
    "L175": "TIRANO",
    "L176": "TIRES .TIERS.",
    "L177": "TIRIOLO",
    "L178": "TIROLO .TIROL.",
    "L180": "TISSI",
    "L181": "TITO",
    "L182": "TIVOLI",
    "L183": "TIZZANO VAL PARMA",
    "L184": "TOANO",
    "L185": "TOCCO CAUDIO",
    "L186": "TOCCO DA CASAURIA",
    "L187": "TOCENO",
    "L188": "TODI",
    "L189": "TOFFIA",
    "L190": "TOIRANO",
    "L191": "TOLENTINO",
    "L192": "TOLFA",
    "L193": "TOLLEGNO",
    "L194": "TOLLO",
    "L195": "TOLMEZZO",
    "L197": "TOLVE",
    "L199": "TOMBOLO",
    "L200": "TON",
    "L201": "TONADICO",
    "L202": "TONARA",
    "L203": "TONCO",
    "L204": "TONENGO",
    "D717": "TONEZZA DEL CIMONE",
    "L205": "TORA E PICCILLI",
    "L206": "TORANO CASTELLO",
    "L207": "TORANO NUOVO",
    "L210": "TORBOLE CASAGLIA",
    "L211": "TORCEGNO",
    "L212": "TORCHIARA",
    "L213": "TORCHIAROLO",
    "L214": "TORELLA DEI LOMBARDI",
    "L215": "TORELLA DEL SANNIO",
    "L216": "TORGIANO",
    "L217": "TORGNON",
    "L219": "TORINO",
    "L218": "TORINO DI SANGRO",
    "L220": "TORITTO",
    "L221": "TORLINO VIMERCATI",
    "L223": "TORNACO",
    "L224": "TORNARECCIO",
    "L225": "TORNATA",
    "L227": "TORNIMPARTE",
    "L228": "TORNO",
    "L229": "TORNOLO",
    "L230": "TORO",
    "L231": "TORPE'",
    "L233": "TORRACA",
    "L235": "TORRALBA",
    "L237": "TORRAZZA COSTE",
    "L238": "TORRAZZA PIEMONTE",
    "L239": "TORRAZZO",
    "L245": "TORRE ANNUNZIATA",
    "L250": "TORRE BERETTI E CASTELLARO",
    "L251": "TORRE BOLDONE",
    "L252": "TORRE BORMIDA",
    "L243": "TORRE CAJETANI",
    "L247": "TORRE CANAVESE",
    "L256": "TORRE D'ARESE",
    "L257": "TORRE DE' BUSI",
    "L262": "TORRE DE' NEGRI",
    "L263": "TORRE DE' PASSERI",
    "L258": "TORRE DE' PICENARDI",
    "L265": "TORRE DE' ROVERI",
    "L259": "TORRE DEL GRECO",
    "L267": "TORRE DI MOSTO",
    "L240": "TORRE DI RUGGIERO",
    "L244": "TORRE DI SANTA MARIA",
    "L269": "TORRE D'ISOLA",
    "L272": "TORRE LE NOCELLE",
    "L241": "TORRE MONDOVI'",
    "L274": "TORRE ORSAIA",
    "L276": "TORRE PALLAVICINA",
    "L277": "TORRE PELLICE",
    "L278": "TORRE SAN GIORGIO",
    "L279": "TORRE SAN PATRIZIO",
    "L280": "TORRE SANTA SUSANNA",
    "L246": "TORREANO",
    "L248": "TORREBELVICINO",
    "L253": "TORREBRUNA",
    "L254": "TORRECUSO",
    "L270": "TORREGLIA",
    "L271": "TORREGROTTA",
    "L273": "TORREMAGGIORE",
    "M286": "TORRENOVA",
    "L281": "TORRESINA",
    "L282": "TORRETTA",
    "L285": "TORREVECCHIA PIA",
    "L284": "TORREVECCHIA TEATINA",
    "L287": "TORRI DEL BENACO",
    "L297": "TORRI DI QUARTESOLO",
    "L286": "TORRI IN SABINA",
    "I550": "TORRIANA",
    "L290": "TORRICE",
    "L294": "TORRICELLA",
    "L296": "TORRICELLA DEL PIZZO",
    "L293": "TORRICELLA IN SABINA",
    "L291": "TORRICELLA PELIGNA",
    "L295": "TORRICELLA SICURA",
    "L292": "TORRICELLA VERZATE",
    "L298": "TORRIGLIA",
    "L299": "TORRILE",
    "L301": "TORRIONI",
    "L303": "TORRITA DI SIENA",
    "L302": "TORRITA TIBERINA",
    "A355": "TORTOLI'",
    "L304": "TORTONA",
    "L305": "TORTORA",
    "L306": "TORTORELLA",
    "L307": "TORTORETO",
    "L308": "TORTORICI",
    "L309": "TORVISCOSA",
    "L312": "TOSCOLANO MADERNO",
    "L314": "TOSSICIA",
    "L316": "TOVO DI SANT'AGATA",
    "L315": "TOVO SAN GIACOMO",
    "L317": "TRABIA",
    "L319": "TRADATE",
    "L321": "TRAMATZA",
    "L322": "TRAMBILENO",
    "L323": "TRAMONTI",
    "L324": "TRAMONTI DI SOPRA",
    "L325": "TRAMONTI DI SOTTO",
    "L326": "TRAMUTOLA",
    "L327": "TRANA",
    "L328": "TRANI",
    "L329": "TRANSACQUA",
    "L330": "TRAONA",
    "L331": "TRAPANI",
    "L332": "TRAPPETO",
    "L333": "TRAREGO VIGGIONA",
    "L334": "TRASACCO",
    "L335": "TRASAGHIS",
    "L336": "TRASQUERA",
    "L337": "TRATALIAS",
    "L338": "TRAUSELLA",
    "I236": "TRAVACO' SICCOMARIO",
    "L339": "TRAVAGLIATO",
    "L342": "TRAVEDONA-MONATE",
    "L345": "TRAVERSELLA",
    "L346": "TRAVERSETOLO",
    "L340": "TRAVES",
    "L347": "TRAVESIO",
    "L348": "TRAVO",
    "M361": "TRE VILLE",
    "L349": "TREBASELEGHE",
    "L353": "TREBISACCE",
    "L354": "TRECASALI",
    "M280": "TRECASE",
    "L355": "TRECASTAGNI",
    "M318": "TRECASTELLI",
    "L356": "TRECATE",
    "L357": "TRECCHINA",
    "L359": "TRECENTA",
    "L361": "TREDOZIO",
    "L363": "TREGLIO",
    "L364": "TREGNAGO",
    "L366": "TREIA",
    "L367": "TREISO",
    "L368": "TREMENICO",
    "L369": "TREMESTIERI ETNEO",
    "M341": "TREMEZZINA",
    "L371": "TREMEZZO",
    "L372": "TREMOSINE",
    "L375": "TRENTA",
    "L377": "TRENTINARA",
    "L378": "TRENTO",
    "L379": "TRENTOLA DUCENTA",
    "L380": "TRENZANO",
    "L381": "TREPPO CARNICO",
    "L382": "TREPPO GRANDE",
    "L383": "TREPUZZI",
    "L384": "TREQUANDA",
    "L385": "TRES",
    "L386": "TRESANA",
    "L388": "TRESCORE BALNEARIO",
    "L389": "TRESCORE CREMASCO",
    "L390": "TRESIGALLO",
    "L392": "TRESIVIO",
    "L393": "TRESNURAGHES",
    "L396": "TREVENZUOLO",
    "L397": "TREVI",
    "L398": "TREVI NEL LAZIO",
    "L399": "TREVICO",
    "L400": "TREVIGLIO",
    "L402": "TREVIGNANO",
    "L401": "TREVIGNANO ROMANO",
    "L403": "TREVILLE",
    "L404": "TREVIOLO",
    "L407": "TREVISO",
    "L406": "TREVISO BRESCIANO",
    "L408": "TREZZANO ROSA",
    "L409": "TREZZANO SUL NAVIGLIO",
    "L411": "TREZZO SULL'ADDA",
    "L410": "TREZZO TINELLA",
    "L413": "TREZZONE",
    "L414": "TRIBANO",
    "L415": "TRIBIANO",
    "L416": "TRIBOGNA",
    "L418": "TRICARICO",
    "L419": "TRICASE",
    "L420": "TRICERRO",
    "L421": "TRICESIMO",
    "L422": "TRICHIANA",
    "L423": "TRIEI",
    "L424": "TRIESTE",
    "L425": "TRIGGIANO",
    "L426": "TRIGOLO",
    "L427": "TRINITA'",
    "L428": "TRINITA' D'AGULTU E VIGNOLA",
    "B915": "TRINITAPOLI",
    "L429": "TRINO",
    "L430": "TRIORA",
    "L431": "TRIPI",
    "L432": "TRISOBBIO",
    "L433": "TRISSINO",
    "L434": "TRIUGGIO",
    "L435": "TRIVENTO",
    "L436": "TRIVERO",
    "L437": "TRIVIGLIANO",
    "L438": "TRIVIGNANO UDINESE",
    "L439": "TRIVIGNO",
    "L440": "TRIVOLZIO",
    "L444": "TRODENA NEL PARCO NATURALE .TRUDEN IM NATURPA",
    "L445": "TROFARELLO",
    "L447": "TROIA",
    "L448": "TROINA",
    "L449": "TROMELLO",
    "L450": "TRONTANO",
    "A705": "TRONZANO LAGO MAGGIORE",
    "L451": "TRONZANO VERCELLESE",
    "L452": "TROPEA",
    "L453": "TROVO",
    "L454": "TRUCCAZZANO",
    "L455": "TUBRE .TAUFERS IM MUENSTERTAL.",
    "L457": "TUENNO",
    "L458": "TUFARA",
    "L459": "TUFILLO",
    "L460": "TUFINO",
    "L461": "TUFO",
    "L462": "TUGLIE",
    "L463": "TUILI",
    "L464": "TULA",
    "L466": "TUORO SUL TRASIMENO",
    "G507": "TURANIA",
    "L469": "TURANO LODIGIANO",
    "L470": "TURATE",
    "L471": "TURBIGO",
    "L472": "TURI",
    "L473": "TURRI",
    "L474": "TURRIACO",
    "L475": "TURRIVALIGNANI",
    "L477": "TURSI",
    "L478": "TUSA",
    "L310": "TUSCANIA",
    "C789": "UBIALE CLANEZZO",
    "L480": "UBOLDO",
    "L482": "UCRIA",
    "L483": "UDINE",
    "L484": "UGENTO",
    "L485": "UGGIANO LA CHIESA",
    "L487": "UGGIATE-TREVANO",
    "L488": "ULA' TIRSO",
    "L489": "ULASSAI",
    "L490": "ULTIMO .ULTEN.",
    "D786": "UMBERTIDE",
    "L492": "UMBRIATICO",
    "L494": "URAGO D'OGLIO",
    "L496": "URAS",
    "L497": "URBANA",
    "L498": "URBANIA",
    "L499": "URBE",
    "L500": "URBINO",
    "L501": "URBISAGLIA",
    "L502": "URGNANO",
    "L503": "URI",
    "L505": "URURI",
    "L506": "URZULEI",
    "L507": "USCIO",
    "L508": "USELLUS",
    "L509": "USINI",
    "L511": "USMATE VELATE",
    "L512": "USSANA",
    "L513": "USSARAMANNA",
    "L514": "USSASSAI",
    "L515": "USSEAUX",
    "L516": "USSEGLIO",
    "L517": "USSITA",
    "L519": "USTICA",
    "L521": "UTA",
    "L522": "UZZANO",
    "L524": "VACCARIZZO ALBANESE",
    "L525": "VACONE",
    "L526": "VACRI",
    "L527": "VADENA .PFATTEN.",
    "L528": "VADO LIGURE",
    "L533": "VAGLI SOTTO",
    "L529": "VAGLIA",
    "L532": "VAGLIO BASILICATA",
    "L531": "VAGLIO SERRA",
    "L537": "VAIANO",
    "L535": "VAIANO CREMASCO",
    "L538": "VAIE",
    "L539": "VAILATE",
    "L540": "VAIRANO PATENORA",
    "M265": "VAJONT",
    "M334": "VAL BREMBILLA",
    "L555": "VAL DELLA TORRE",
    "L562": "VAL DI NIZZA",
    "L564": "VAL DI VIZZE .PFITSCH.",
    "M374": "VAL DI ZOLDO",
    "L638": "VAL MASINO",
    "H259": "VAL REZZO",
    "L544": "VALBONDIONE",
    "L545": "VALBREMBO",
    "L546": "VALBREVENNA",
    "L547": "VALBRONA",
    "L550": "VALDA",
    "L551": "VALDAGNO",
    "M343": "VALDAONE",
    "L552": "VALDAORA .OLANG.",
    "L554": "VALDASTICO",
    "L556": "VALDENGO",
    "G319": "VALDERICE",
    "L557": "VALDIDENTRO",
    "L558": "VALDIERI",
    "L561": "VALDINA",
    "L563": "VALDISOTTO",
    "L565": "VALDOBBIADENE",
    "L566": "VALDUGGIA",
    "L568": "VALEGGIO",
    "L567": "VALEGGIO SUL MINCIO",
    "L569": "VALENTANO",
    "L570": "VALENZA",
    "L571": "VALENZANO",
    "L572": "VALERA FRATTA",
    "L573": "VALFABBRICA",
    "L574": "VALFENERA",
    "L575": "VALFLORIANA",
    "L576": "VALFURVA",
    "L577": "VALGANNA",
    "L578": "VALGIOIE",
    "L579": "VALGOGLIO",
    "L580": "VALGRANA",
    "L581": "VALGREGHENTINO",
    "L582": "VALGRISENCHE",
    "L583": "VALGUARNERA CAROPEPE",
    "L584": "VALLADA AGORDINA",
    "L586": "VALLANZENGO",
    "L588": "VALLARSA",
    "L589": "VALLATA",
    "L594": "VALLE AGRICOLA",
    "L595": "VALLE AURINA .AHRNTAL.",
    "L597": "VALLE CASTELLANA",
    "G540": "VALLE DELL'ANGELO",
    "L590": "VALLE DI CADORE",
    "L601": "VALLE DI CASIES .GSIES.",
    "L591": "VALLE DI MADDALONI",
    "M362": "VALLE LAGHI",
    "L593": "VALLE LOMELLINA",
    "L606": "VALLE MOSSO",
    "L617": "VALLE SALIMBENE",
    "L620": "VALLE SAN NICOLAO",
    "L596": "VALLEBONA",
    "L598": "VALLECORSA",
    "L599": "VALLECROSIA",
    "L603": "VALLEDOLMO",
    "L604": "VALLEDORIA",
    "I322": "VALLEFIORITA",
    "M331": "VALLEFOGLIA",
    "L607": "VALLELONGA",
    "L609": "VALLELUNGA PRATAMENO",
    "L605": "VALLEMAIO",
    "L611": "VALLEPIETRA",
    "L612": "VALLERANO",
    "L613": "VALLERMOSA",
    "L614": "VALLEROTONDA",
    "L616": "VALLESACCARDA",
    "L623": "VALLEVE",
    "L624": "VALLI DEL PASUBIO",
    "L625": "VALLINFREDA",
    "L626": "VALLIO TERME",
    "L628": "VALLO DELLA LUCANIA",
    "L627": "VALLO DI NERA",
    "L629": "VALLO TORINESE",
    "L631": "VALLORIATE",
    "L633": "VALMACCA",
    "L634": "VALMADRERA",
    "L636": "VALMALA",
    "L639": "VALMONTONE",
    "L640": "VALMOREA",
    "L641": "VALMOZZOLA",
    "L642": "VALNEGRA",
    "L643": "VALPELLINE",
    "L644": "VALPERGA",
    "B510": "VALPRATO SOANA",
    "M320": "VALSAMOGGIA",
    "L647": "VALSAVARENCHE",
    "L649": "VALSECCA",
    "D513": "VALSINNI",
    "C936": "VALSOLDA",
    "L650": "VALSTAGNA",
    "L651": "VALSTRONA",
    "L653": "VALTOPINA",
    "L655": "VALTORTA",
    "L654": "VALTOURNENCHE",
    "L656": "VALVA",
    "L657": "VALVASONE",
    "M346": "VALVASONE ÀRZENE",
    "L658": "VALVERDE",
    "L659": "VALVERDE",
    "L468": "VALVESTINO",
    "L660": "VANDOIES .VINTL.",
    "L664": "VANZAGHELLO",
    "L665": "VANZAGO",
    "L666": "VANZONE CON SAN CARLO",
    "L667": "VAPRIO D'ADDA",
    "L668": "VAPRIO D'AGOGNA",
    "L669": "VARALLO",
    "L670": "VARALLO POMBIA",
    "L671": "VARANO BORGHI",
    "L672": "VARANO DE' MELEGARI",
    "L673": "VARAPODIO",
    "L675": "VARAZZE",
    "L676": "VARCO SABINO",
    "L677": "VAREDO",
    "L678": "VARENA",
    "L680": "VARENNA",
    "L682": "VARESE",
    "L681": "VARESE LIGURE",
    "L685": "VARISELLA",
    "L686": "VARMO",
    "L687": "VARNA .VAHRN.",
    "L689": "VARSI",
    "L690": "VARZI",
    "L691": "VARZO",
    "L692": "VAS",
    "A701": "VASANELLO",
    "L693": "VASIA",
    "E372": "VASTO",
    "L696": "VASTOGIRARDI",
    "L697": "VATTARO",
    "L698": "VAUDA CANAVESE",
    "L699": "VAZZANO",
    "L700": "VAZZOLA",
    "L702": "VECCHIANO",
    "L704": "VEDANO AL LAMBRO",
    "L703": "VEDANO OLONA",
    "L705": "VEDDASCA",
    "L706": "VEDELAGO",
    "L707": "VEDESETA",
    "L709": "VEDUGGIO CON COLZANO",
    "L710": "VEGGIANO",
    "L711": "VEGLIE",
    "L712": "VEGLIO",
    "L713": "VEJANO",
    "L715": "VELESO",
    "L716": "VELEZZO LOMELLINA",
    "L719": "VELLETRI",
    "L720": "VELLEZZO BELLINI",
    "L723": "VELO D'ASTICO",
    "L722": "VELO VERONESE",
    "L724": "VELTURNO .FELDTHURNS.",
    "L725": "VENAFRO",
    "L727": "VENARIA REALE",
    "L728": "VENAROTTA",
    "L729": "VENASCA",
    "L726": "VENAUS",
    "L730": "VENDONE",
    "L731": "VENDROGNO",
    "L733": "VENEGONO INFERIORE",
    "L734": "VENEGONO SUPERIORE",
    "L735": "VENETICO",
    "L736": "VENEZIA",
    "L737": "VENIANO",
    "L738": "VENOSA",
    "M364": "VENTASSO",
    "L739": "VENTICANO",
    "L741": "VENTIMIGLIA",
    "L740": "VENTIMIGLIA DI SICILIA",
    "L742": "VENTOTENE",
    "L743": "VENZONE",
    "L745": "VERANO .VOERAN.",
    "L744": "VERANO BRIANZA",
    "L746": "VERBANIA",
    "L747": "VERBICARO",
    "L748": "VERCANA",
    "L749": "VERCEIA",
    "L750": "VERCELLI",
    "L751": "VERCURAGO",
    "L752": "VERDELLINO",
    "L753": "VERDELLO",
    "M337": "VERDERIO",
    "L755": "VERDERIO INFERIORE",
    "L756": "VERDERIO SUPERIORE",
    "L758": "VERDUNO",
    "L762": "VERGATO",
    "L763": "VERGEMOLI",
    "L764": "VERGHERETO",
    "L765": "VERGIATE",
    "L768": "VERMEZZO",
    "L769": "VERMIGLIO",
    "L771": "VERNANTE",
    "L772": "VERNASCA",
    "L773": "VERNATE",
    "L774": "VERNAZZA",
    "L775": "VERNIO",
    "L776": "VERNOLE",
    "L777": "VEROLANUOVA",
    "L778": "VEROLAVECCHIA",
    "L779": "VEROLENGO",
    "L780": "VEROLI",
    "L781": "VERONA",
    "D193": "VERONELLA",
    "L783": "VERRAYES",
    "C282": "VERRES",
    "L784": "VERRETTO",
    "L785": "VERRONE",
    "L788": "VERRUA PO",
    "L787": "VERRUA SAVOIA",
    "L792": "VERTEMATE CON MINOPRIO",
    "L795": "VERTOVA",
    "L797": "VERUCCHIO",
    "L798": "VERUNO",
    "L799": "VERVIO",
    "L800": "VERVO'",
    "L801": "VERZEGNIS",
    "L802": "VERZINO",
    "L804": "VERZUOLO",
    "L805": "VESCOVANA",
    "L806": "VESCOVATO",
    "L807": "VESIME",
    "L808": "VESPOLATE",
    "L809": "VESSALICO",
    "L810": "VESTENANOVA",
    "L811": "VESTIGNE'",
    "L812": "VESTONE",
    "L813": "VESTRENO",
    "L814": "VETRALLA",
    "L815": "VETTO",
    "L817": "VEZZA D'ALBA",
    "L816": "VEZZA D'OGLIO",
    "L821": "VEZZANO",
    "L819": "VEZZANO LIGURE",
    "L820": "VEZZANO SUL CROSTOLO",
    "L823": "VEZZI PORTIO",
    "L826": "VIADANA",
    "L827": "VIADANICA",
    "L828": "VIAGRANDE",
    "L829": "VIALE",
    "L830": "VIALFRE'",
    "L831": "VIANO",
    "L833": "VIAREGGIO",
    "L834": "VIARIGI",
    "F537": "VIBO VALENTIA",
    "L835": "VIBONATI",
    "L836": "VICALVI",
    "L837": "VICARI",
    "L838": "VICCHIO",
    "L840": "VICENZA",
    "L548": "VICO CANAVESE",
    "L842": "VICO DEL GARGANO",
    "L845": "VICO EQUENSE",
    "L843": "VICO NEL LAZIO",
    "L841": "VICOFORTE",
    "L846": "VICOLI",
    "L847": "VICOLUNGO",
    "L850": "VICOPISANO",
    "L851": "VICOVARO",
    "M259": "VIDDALBA",
    "L854": "VIDIGULFO",
    "L856": "VIDOR",
    "L857": "VIDRACCO",
    "L858": "VIESTE",
    "L859": "VIETRI DI POTENZA",
    "L860": "VIETRI SUL MARE",
    "L864": "VIGANELLA",
    "L866": "VIGANO'",
    "L865": "VIGANO SAN MARTINO",
    "L868": "VIGARANO MAINARDA",
    "L869": "VIGASIO",
    "L872": "VIGEVANO",
    "L873": "VIGGIANELLO",
    "L874": "VIGGIANO",
    "L876": "VIGGIU'",
    "L878": "VIGHIZZOLO D'ESTE",
    "L880": "VIGLIANO BIELLESE",
    "L879": "VIGLIANO D'ASTI",
    "L881": "VIGNALE MONFERRATO",
    "L882": "VIGNANELLO",
    "L883": "VIGNATE",
    "L885": "VIGNOLA",
    "L886": "VIGNOLA FALESINA",
    "L887": "VIGNOLE BORBERA",
    "L888": "VIGNOLO",
    "L889": "VIGNONE",
    "L890": "VIGO DI CADORE",
    "L893": "VIGO DI FASSA",
    "L903": "VIGO RENDENA",
    "L892": "VIGODARZERE",
    "L894": "VIGOLO",
    "L896": "VIGOLO VATTARO",
    "L897": "VIGOLZONE",
    "L898": "VIGONE",
    "L899": "VIGONOVO",
    "L900": "VIGONZA",
    "L904": "VIGUZZOLO",
    "L910": "VILLA AGNEDO",
    "L912": "VILLA BARTOLOMEA",
    "L913": "VILLA BASILICA",
    "L917": "VILLA BISCOSSI",
    "L919": "VILLA CARCINA",
    "L920": "VILLA CASTELLI",
    "L922": "VILLA CELIERA",
    "L926": "VILLA COLLEMANDINA",
    "L928": "VILLA CORTESE",
    "L929": "VILLA D'ADDA",
    "A215": "VILLA D'ALME'",
    "L933": "VILLA DEL BOSCO",
    "L934": "VILLA DEL CONTE",
    "D801": "VILLA DI BRIANO",
    "L907": "VILLA DI CHIAVENNA",
    "L936": "VILLA DI SERIO",
    "L908": "VILLA DI TIRANO",
    "L938": "VILLA D'OGNA",
    "L937": "VILLA ESTENSE",
    "L943": "VILLA FARALDI",
    "L956": "VILLA GUARDIA",
    "L957": "VILLA LAGARINA",
    "A081": "VILLA LATINA",
    "L844": "VILLA LITERNO",
    "L969": "VILLA MINOZZO",
    "F804": "VILLA POMA",
    "M006": "VILLA RENDENA",
    "M018": "VILLA SAN GIOVANNI",
    "H913": "VILLA SAN GIOVANNI IN TUSCIA",
    "I118": "VILLA SAN PIETRO",
    "M019": "VILLA SAN SECONDO",
    "L905": "VILLA SANTA LUCIA",
    "M021": "VILLA SANTA LUCIA DEGLI ABRUZZI",
    "M022": "VILLA SANTA MARIA",
    "M023": "VILLA SANT'ANGELO",
    "I298": "VILLA SANT'ANTONIO",
    "L909": "VILLA SANTINA",
    "I364": "VILLA SANTO STEFANO",
    "A609": "VILLA VERDE",
    "M034": "VILLA VICENTINA",
    "L915": "VILLABASSA .NIEDERDORF.",
    "L916": "VILLABATE",
    "L923": "VILLACHIARA",
    "L924": "VILLACIDRO",
    "L931": "VILLADEATI",
    "L939": "VILLADOSE",
    "L906": "VILLADOSSOLA",
    "L942": "VILLAFALLETTO",
    "L945": "VILLAFRANCA D'ASTI",
    "L949": "VILLAFRANCA DI VERONA",
    "L946": "VILLAFRANCA IN LUNIGIANA",
    "L947": "VILLAFRANCA PADOVANA",
    "L948": "VILLAFRANCA PIEMONTE",
    "L944": "VILLAFRANCA SICULA",
    "L950": "VILLAFRANCA TIRRENA",
    "L951": "VILLAFRATI",
    "L952": "VILLAGA",
    "L953": "VILLAGRANDE STRISAILI",
    "L958": "VILLALAGO",
    "L959": "VILLALBA",
    "L961": "VILLALFONSINA",
    "L963": "VILLALVERNIA",
    "L964": "VILLAMAGNA",
    "L965": "VILLAMAINA",
    "L966": "VILLAMAR",
    "L967": "VILLAMARZANA",
    "L968": "VILLAMASSARGIA",
    "L970": "VILLAMIROGLIO",
    "L971": "VILLANDRO .VILLANDERS.",
    "L978": "VILLANOVA BIELLESE",
    "L982": "VILLANOVA CANAVESE",
    "L975": "VILLANOVA D'ALBENGA",
    "L983": "VILLANOVA D'ARDENGHI",
    "L984": "VILLANOVA D'ASTI",
    "L973": "VILLANOVA DEL BATTISTA",
    "L985": "VILLANOVA DEL GHEBBO",
    "L977": "VILLANOVA DEL SILLARO",
    "L979": "VILLANOVA DI CAMPOSAMPIERO",
    "L988": "VILLANOVA MARCHESANA",
    "L974": "VILLANOVA MONDOVI'",
    "L972": "VILLANOVA MONFERRATO",
    "L989": "VILLANOVA MONTELEONE",
    "L990": "VILLANOVA SOLARO",
    "L980": "VILLANOVA SULL'ARDA",
    "L991": "VILLANOVA TRUSCHEDU",
    "L992": "VILLANOVA TULO",
    "L986": "VILLANOVAFORRU",
    "L987": "VILLANOVAFRANCA",
    "L994": "VILLANTERIO",
    "L995": "VILLANUOVA SUL CLISI",
    "M278": "VILLAPERUCCIO",
    "B903": "VILLAPIANA",
    "L998": "VILLAPUTZU",
    "L999": "VILLAR DORA",
    "M007": "VILLAR FOCCHIARDO",
    "M013": "VILLAR PELLICE",
    "M014": "VILLAR PEROSA",
    "M015": "VILLAR SAN COSTANZO",
    "M002": "VILLARBASSE",
    "M003": "VILLARBOIT",
    "M004": "VILLAREGGIA",
    "G309": "VILLARICCA",
    "M009": "VILLAROMAGNANO",
    "M011": "VILLAROSA",
    "M016": "VILLASALTO",
    "M017": "VILLASANTA",
    "B738": "VILLASIMIUS",
    "M025": "VILLASOR",
    "M026": "VILLASPECIOSA",
    "M027": "VILLASTELLONE",
    "M028": "VILLATA",
    "M030": "VILLAURBANA",
    "M031": "VILLAVALLELONGA",
    "M032": "VILLAVERLA",
    "M363": "VILLE D'ANAUNIA",
    "L981": "VILLENEUVE",
    "M043": "VILLESSE",
    "M041": "VILLETTA BARREA",
    "M042": "VILLETTE",
    "M044": "VILLIMPENTA",
    "M045": "VILLONGO",
    "M048": "VILLORBA",
    "M050": "VILMINORE DI SCALVE",
    "M052": "VIMERCATE",
    "M053": "VIMODRONE",
    "M055": "VINADIO",
    "M057": "VINCHIATURO",
    "M058": "VINCHIO",
    "M059": "VINCI",
    "M060": "VINOVO",
    "M062": "VINZAGLIO",
    "M063": "VIOLA",
    "M065": "VIONE",
    "M067": "VIPITENO .STERZING.",
    "H123": "VIRGILIO",
    "M069": "VIRLE PIEMONTE",
    "M070": "VISANO",
    "M071": "VISCHE",
    "M072": "VISCIANO",
    "M073": "VISCO",
    "M077": "VISONE",
    "M078": "VISSO",
    "M079": "VISTARINO",
    "M080": "VISTRORIO",
    "M081": "VITA",
    "M082": "VITERBO",
    "M083": "VITICUSO",
    "M085": "VITO D'ASIO",
    "M086": "VITORCHIANO",
    "M088": "VITTORIA",
    "M089": "VITTORIO VENETO",
    "M090": "VITTORITO",
    "M091": "VITTUONE",
    "M093": "VITULANO",
    "M092": "VITULAZIO",
    "M094": "VIU'",
    "M096": "VIVARO",
    "M095": "VIVARO ROMANO",
    "M098": "VIVERONE",
    "M100": "VIZZINI",
    "M101": "VIZZOLA TICINO",
    "M102": "VIZZOLO PREDABISSI",
    "M103": "VO'",
    "M104": "VOBARNO",
    "M105": "VOBBIA",
    "M106": "VOCCA",
    "M108": "VODO CADORE",
    "M109": "VOGHERA",
    "M110": "VOGHIERA",
    "M111": "VOGOGNA",
    "M113": "VOLANO",
    "M115": "VOLLA",
    "M116": "VOLONGO",
    "M118": "VOLPAGO DEL MONTELLO",
    "M119": "VOLPARA",
    "M120": "VOLPEDO",
    "M121": "VOLPEGLINO",
    "M122": "VOLPIANO",
    "M125": "VOLTA MANTOVANA",
    "M123": "VOLTAGGIO",
    "M124": "VOLTAGO AGORDINO",
    "M126": "VOLTERRA",
    "M127": "VOLTIDO",
    "M131": "VOLTURARA APPULA",
    "M130": "VOLTURARA IRPINA",
    "M132": "VOLTURINO",
    "M133": "VOLVERA",
    "M136": "VOTTIGNASCO",
    "M138": "ZACCANOPOLI",
    "M139": "ZAFFERANA ETNEA",
    "M140": "ZAGARISE",
    "M141": "ZAGAROLO",
    "M142": "ZAMBANA",
    "M143": "ZAMBRONE",
    "M144": "ZANDOBBIO",
    "M145": "ZANE'",
    "M147": "ZANICA",
    "M267": "ZAPPONETA",
    "M150": "ZAVATTARELLO",
    "M152": "ZECCONE",
    "M153": "ZEDDIANI",
    "M156": "ZELBIO",
    "M158": "ZELO BUON PERSICO",
    "M160": "ZELO SURRIGONE",
    "M161": "ZEME",
    "M162": "ZENEVREDO",
    "M163": "ZENSON DI PIAVE",
    "M165": "ZERBA",
    "M166": "ZERBO",
    "M167": "ZERBOLO'",
    "M168": "ZERFALIU",
    "M169": "ZERI",
    "M170": "ZERMEGHEDO",
    "M171": "ZERO BRANCO",
    "M172": "ZEVIO",
    "M173": "ZIANO DI FIEMME",
    "L848": "ZIANO PIACENTINO",
    "M174": "ZIBELLO",
    "M176": "ZIBIDO SAN GIACOMO",
    "M177": "ZIGNAGO",
    "M178": "ZIMELLA",
    "M179": "ZIMONE",
    "M180": "ZINASCO",
    "M182": "ZOAGLI",
    "M183": "ZOCCA",
    "M184": "ZOGNO",
    "M185": "ZOLA PREDOSA",
    "I345": "ZOLDO ALTO",
    "M187": "ZOLLINO",
    "M188": "ZONE",
    "M189": "ZOPPE' DI CADORE",
    "M190": "ZOPPOLA",
    "M194": "ZOVENCEDO",
    "M196": "ZUBIENA",
    "M197": "ZUCCARELLO",
    "M198": "ZUCLO",
    "M199": "ZUGLIANO",
    "M200": "ZUGLIO",
    "M201": "ZUMAGLIA",
    "M202": "ZUMPANO",
    "M203": "ZUNGOLI",
    "M204": "ZUNGRI"
}