
export const events = {
  "SessionStarted": "Session Started",
  "3rdPartyCookiesBlockDetected": "3rd party cookies block detected",
  "3rdPartyCookiesBlockUndetected": "3rd party cookies block undetected",
  "LandingPageVisitedNonLoggedIn": "Landing Page - Visited - Non logged-in",
  "PhoneNumberSentForValidation": "Phone number sent for validation",
  "PhoneNumberValidationFailed": "Phone number validation failed",
  "NewUserDetected": "New user detected",
  "RegisteredUserDetected": "Registered user detected",
  "LoginOTPApprovalPageVisited": "Login OTP Aprooval Page - Visited",
  "LogiPINCodeApprovalPageVisited": "Login PINcode Aprooval Page - Visited",
  "LoginOTPSentByUser": "Login OTP Sent by user",
  "LoginPINCodeSentByUser": "Login PINCode Sent by user",
  "LoginOTPFailed": "Login OTP Failed",
  "LoginPINCodeFailed": "Login PINCode Failed",
  "UserBlockedForMultiplePincodeFailures": "User blocked for mutiple Pincode failures",
  "LoginOTPApproved": "Login OTP Approved",
  "OnboardingTCPageVisited": "Onboarding - T&C Page - Visited",
  "OnboardingTCPageFlag1Checked": "Onboarding - T&C Page - Flag 1 Chacked",
  "OnboardingTCPageFlag1Unchecked": "Onboarding - T&C Page - Flag 1 Un-Chacked",
  "OnboardingTCPageFlag2Checked": "Onboarding - T&C Page - Flag 2 Chacked",
  "OnboardingTCPageFlag2Unchecked": "Onboarding - T&C Page - Flag 2 Un-Chacked",
  "OnboardingTCPageConfirmed": "Onboarding - T&C Page - Confirmed",
  "OnboardingEmailPageVisited": "Onboarding - Email Page - Visited",
  "OnboardingEmailPageSent": "Onboarding - Email Page - Sent",
  "OnboardingNameFCVisited": "Onboarding - Name and FC - Visited",
  "OnboardingNameFCSent": "Onboarding - Name and FC - Sent",
  "OnboardingAddressVisited": "Onboarding - Address - Visited",
  "OnboardingAddressSent": "Onboarding - Address - Sent",
  "OnboardingDocumentTypeVisited": "Onboarding - Document Type - Visited",
  "OnboardingDocumentTypeSent": "Onboarding - Document Type - Sent",
  "OnboardingDocumentDetailsVisited": "Onboarding - Document Details - Visited",
  "OnboardingDocumentDetailsSent": "Onboarding - Document Details - Sent",
  "OnboardingPinCodeSelectionVisited": "Onboarding - PinCode Selection - Visited",
  "OnboardingPinCodeSelectionSent": "Onboarding - PinCode Selection - Sent",
  "OnboardingCardAddPageVisited": "Onboarding - Card Add Page - Visted",
  "OnboardingCardAddPageLetsDoItClicked": "Onboarding - Card Add Page - Lets Do It Btn - Clicked",
  "OnboardingCardAddPageInfoClicked": "Onboarding - Card Add Page - Why Do We Ask Btn - Clicked",
  "OnboardingCardAddPageInfoVisited": "Onboarding - Card Why Do We Ask Page - Visited",
  "OnboardingSIAInitFailed": "Onboarding - SIA init failed",
  "OnboardingSIACardOnboardingSucceded": "Onboarding - SIA Card onboarding - Succeded",
  "OnboardingSIACardOnboardingFailed": "Onboarding - SIA Card onboarding - Failed",
  "PaymentPinCodeVisited": "Payment - PinCode Page - Visited",
  "PaymentTransactionCreated": "Payment - Transaction Created",
  "PaymentScoringFailed": "Payment - Scoring - Failed",
  "PaymentScoringSuccess": "Payment - Scoring - Success",
  "PaymentPlanPageVisited": "Payment - Payment Plan Page - Visited",
  "PaymentDossierPopupVisited": "Payment - Dossier Popup - Visited",
  "PaymentDossierPopupConfirmed": "Payment - Dossier Popup - Confirmed",
  "PaymentPOLOTPVisited": "Payment - POL OTP Page - Visited",
  "PaymentPOLOTPSentByUser": "Payment - POL OTP Sent by user",
  "PaymentPOLOTPFailed": "Payment - POL OTP Failed",
  "PaymentPOLOTPApproved": "Payment - POL OTP Approved",
  "PaymentPOLOTPUserAskToResend": "Payment - POL OTP User Aask to Resend",
  "PaymentCompleted": "Payment - Completed",
  "PaymentFailed": "Payment - Failed",
  // "0056": "Payment - Failed - User Asked to Redirect", /** disabled for now */
  "PinCodeRecovery": "Pin Code Recovery",
  "Cancel": "Cancel",
  "SupportFormPageVisited": "Support Form Page - Visited",
  "SupportFormPageSubmitted": "Support Form Page - Submitted",
  "WindowClosed": "Window Closed",
  "OnboardingCardIdentifyVisited": "Onboarding - Card Identify - Visited",
  "OnboardingCardIdentifySent": "Onboarding - Card Identify - Sent",
  "OnboardingCardIdentifyDebitDetected": "Onboarding - Card Identify - Debit Detected",
  "OnboardingCardIdentifyCreditDetected": "Onboarding - Card Identify - Credit Detected",
  "OnboardingDebitDetectedVisited": "Onboarding - Debit Detected - Visited",
  "OnboardingDebitDetectedTryAgainClicked": "Onboarding - Debit Detected - Try Again - Clicked",
  "OnboardingScoringFailed": "Onboarding - Scoring - Failed",
  "OnboardingScoringSuccess": "Onboarding - Scoring - Success",
  "ContractOTPPageVisited": "Contract OTP page- visited",
  "ContractOTPMatch": "Contract OTP page- OTP match",
  "ContractOTPMissmatched": "Contract OTP page- OTP mismatched",
  "CardTokenizationSuccseed":"Add card page- card tokenization successful",
  "CardTokenizationFail":"Add card page- card tokenization fail",
  "LandingPagePaymentPlanSelected": "Landing Page- payment plan selected",
  "NoosaPrivacyClicked": "Noosa Privacy clicked",
  "NoosaT&CClicked": "Noosa T&C clicked",
  "MerchantPrivacyClicked": "Merchant privacy clicked",
  "PagolightPrivacyClicked": "Pagolight Privacy clicked",
  "PhoneNumberAutoPopulated": "Login page- phone number auto populated",
  "BackButtonClicked": "Back button clicked",
  "PrivacyPageCheckedConsente": "Privacy Page flag checked consente",
  "PrivacyPageCheckedNonConsente": "Privacy Page flag checked non consente",
  "Address&EmailPageClickedConfirma": "Address and email page- clicked Confirma",
  "Address&EmailPageInformationPopulated": "Address and email page- information populated",
  "OCRPageClickedOnUpload": "OCR page- clicked on upload an image",
  "OCRPageHowToUploadMyDocumentClicked": "OCR page- how to upload my document clicked",
  "ContractOTPclickedResendSms": "Contract OTP page- clicked resend sms",
  "ContractOTPclickedConferma": "Onboarding - Contract OTP Page- Clicked Confirm",
  "ScoringFailedPageVisited": "Payment Scoring Failed",
  "SessionExpiredPageVisited": "Session expired page - visited"
}