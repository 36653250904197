import axios from 'axios';
import { selectors } from 'legacy/DFE/store/auth';
import queryString from 'query-string';

const BASE_URL = `${process.env.REACT_APP_API_URL}/api`;

export abstract class AbstractAPIHandler {
    constructor(protected path: string) {
        this.path = path.endsWith("/") ? path : `${path}/`;
    }

    protected get(url: string, params?: Record<string, any>): Promise<any> {
        return axios.get(getApiUrl(`${this.path}${url}`, params));
    }

    protected post(url: string, body: any, params?: Record<string, any>): Promise<any> {
        return axios.post(getApiUrl(`${this.path}${url}`, params), body);
    }
}

export const getApiUrl = (path: string, params?: Record<string, any>): string => {
  const authToken = selectors.authToken() || "";

  const query = queryString.stringify(
        // eslint-disable-next-line @typescript-eslint/camelcase
        {  access_token: authToken, ...params },
        { skipNull: true }
    );
    return `${BASE_URL}/${path}?${query}`;
};
