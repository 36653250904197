import { ConsumersAPI } from "legacy/DFE/api/handlers";
import { State } from "legacy/DFE/store/state";
import { OnboardingRoute } from "../OnboardingRoute";
import AddressComponent from "./index.component";

export default class Address extends OnboardingRoute {
    constructor() {
        super("Address_IT");
    }

    async preprocess(state: State) {
        const provincesData = (await ConsumersAPI.getProvinces()).provinces;

        return {
            provincesList: provincesData.map((item: { provincia: string }) => item.provincia),
        };
    }

    component = AddressComponent;
}