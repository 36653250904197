import React from 'react';
import ui from 'store/ui';
import { DESIGN } from 'types/design';
import { AppComponentProps, AppProps } from 'types/apps';

import DEKYCForm from './DE';
import DefaultKYCForm from './Default';
import { ParentProps } from '../types';

const ContactUs: React.FC<AppComponentProps & ParentProps & AppProps> = (
  props: AppComponentProps & ParentProps & AppProps
) => {
  const components = {
    [DESIGN.DE]: DEKYCForm,
    [DESIGN.default]: DefaultKYCForm,
  };

  const ContactUsComponent = components[ui.design];

  return <ContactUsComponent {...props} />;
};

export default ContactUs;
