import { LocalStorageManager } from './LocalStorageManager';
import { TEXT_KEY } from 'legacy/constants/storageKeys';
import { store } from 'legacy/store/store';
import { selectors as OrderSelectors } from 'legacy/store/order';

export class TextStorageManager {
  static get(key: string) {
    const state = store.getState();
    const porting = OrderSelectors.isPorting(state);

    if (!porting) return "";

    const text = LocalStorageManager.getItem(TEXT_KEY);
    return text[key] || "";
  }
}
  