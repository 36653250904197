import { createSlice, createAction, PayloadAction } from 'redux-starter-kit';
import { combineReducers } from 'redux';
import { State } from 'legacy/store/state';
import { Consumer, CardsStatus, MobileNumber } from 'legacy/types/Consumer';
import { onBoardingStatusReducer, onBoardingStatusActions } from './onboarding';
import { addressReducer, addressActions } from './address';
import { initialConsumerProfile, ConsumerProfile, ConsumerState } from './state';

/** consumer slice */
export const consumerProfileSlice = createSlice({
  name: 'consumer profile',
  initialState: initialConsumerProfile,
  reducers: {
    setConsumerInfo: (
      state,
      { payload: consumerInfo }: PayloadAction<Consumer>
    ): ConsumerProfile => ({
      ...state,
      consumerInfo,
    }),
    setCardsStatus: (
      state,
      { payload: cardsStatus }: PayloadAction<CardsStatus>
    ): ConsumerProfile => ({
      ...state,
      cardsStatus,
    }),
    setMobileNumber: (
      state,
      { payload: mobile }: PayloadAction<MobileNumber>
    ): ConsumerProfile => ({
      ...state,
      mobile,
    }),
  },
});

export const reducer = combineReducers({
  consumerProfile: consumerProfileSlice.reducer,
  onBoardingStatus: onBoardingStatusReducer,
  address: addressReducer,
});

/** sagas actions */
const requestConsumerInfo = createAction('requestConsumerInfo');
const loginByToken = createAction<string>('loginByToken');

export const actions = {
  ...consumerProfileSlice.actions,
  ...onBoardingStatusActions,
  ...addressActions,
  requestConsumerInfo,
  loginByToken,
};

/** selectors */
const consumerSelector = (state: State) => state.consumer;

const consumerInfoSelector = (state: State): ConsumerProfile['consumerInfo'] =>
  consumerSelector(state).consumerProfile.consumerInfo;
const consumerCardsStatusSelector = (state: State): ConsumerProfile['cardsStatus'] =>
  consumerSelector(state).consumerProfile.cardsStatus;
const onBoardingStatusSelector = (state: State): ConsumerState['onBoardingStatus'] =>
  consumerSelector(state).onBoardingStatus;
const consumerAddressSelector = (state: State): ConsumerState['address'] =>
  consumerSelector(state).address;
const consumerMobileSelector = (state: State): ConsumerProfile['mobile'] =>
  consumerSelector(state).consumerProfile.mobile;

export const selectors = {
  consumerSelector,
  consumerInfoSelector,
  consumerCardsStatusSelector,
  onBoardingStatusSelector,
  consumerAddressSelector,
  consumerMobileSelector,
};
