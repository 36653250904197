import axios, { AxiosInstance } from 'axios';
import { getApiUrl } from 'legacy/helpers/getApiUrl';
import { getStringifiedParams } from 'legacy/helpers/getStringifiedParams';
import { axiosDefaultRequestInterceptor, axiosDefaultResponseInterceptor } from './interceptors';

interface CheckOTTData {
  valid: boolean;
}

interface PassedOCRData {
  result: boolean;
}

const API_PREFIX = 'npg/v1';

class MacApi {
  private axios: AxiosInstance;

  constructor() {
    this.axios = axios.create();
    this.axios.interceptors.request.use(
      axiosDefaultRequestInterceptor[0],
      axiosDefaultRequestInterceptor[1]
    );
    this.axios.interceptors.response.use(axiosDefaultResponseInterceptor[0]);
  }

  checkOTT = (token: string): Promise<CheckOTTData> => {
    return this.axios.post(
      getApiUrl(`${API_PREFIX}/consumer/checkOTT`),
      getStringifiedParams({ ott: token })
    );
  };

  getPassedOCR = (): Promise<PassedOCRData> => {
    return this.axios.get(getApiUrl(`${API_PREFIX}/consumer/passedOCR`));
  };

  checkPaymentRequest = (id: string): Promise<{ canceled: boolean }> => {
    return this.axios.get(getApiUrl(`${API_PREFIX}/payments/checkPaymentRequest`, { id }));
  };
}

const macApi = new MacApi();

export { macApi };
