import PaddingContainer from "legacy/DFE/components/PaddingContainer";
import Text from "legacy/DFE/components/Text";
import TextInput from "legacy/DFE/components/TextInput";
import { notify } from "legacy/DFE/helpers/notify";
import { RouteComponentType } from "legacy/DFE/routing/RouterTypes";
import React, { useState } from "react";
import InlineInputContainer from "../../components/InlineInputContainer";
import InputsContainer from "../../components/InputsContainer";
import { ParentProps } from "../../types";

type ProfileComponentType = ParentProps & RouteComponentType;

function validateEmail(email: string) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const ProfileComponent: React.FC<ProfileComponentType> = (props: ProfileComponentType) => {
    const {
        useSubmit,
        submitFlow,
        done,
    } = props;

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [birthDate, setBirthDate] = useState("");
    const [documentNumber, setDocumentNumber] = useState("");

    useSubmit(() => {
        if (!firstName || !lastName || !email || !birthDate || !documentNumber) {
            notify("error", "Please fill in all fields");
            return;
        }

        if (!validateEmail(email)) {
            notify("error", "Please enter a valid email address");
            return;
        }

        submitFlow({
            firstName,
            lastName,
            email,
            birthDate,
            documentNumber,
        }).then(done);
    });

    return <>
        <Text fontSize="24px" className="bold"> User details </Text>
        <Text fontSize="18px" className="thin"> Let’s set up your account </Text>
        <InputsContainer>
            <InlineInputContainer>
                <PaddingContainer padding="0 5px 0 0">
                    <TextInput value={firstName} onChange={val => setFirstName(val)} inline={true}> First Name </TextInput>
                </PaddingContainer>
                <PaddingContainer padding="0 0 0 5px">
                    <TextInput value={lastName} onChange={val => setLastName(val)} inline={true}> Last Name </TextInput>
                </PaddingContainer>
            </InlineInputContainer>
            <TextInput value={birthDate} onChange={val => setBirthDate(val)} type="date"> Date Of Birth </TextInput>
            <TextInput value={documentNumber} onChange={val => setDocumentNumber(val)}> Document Number </TextInput>
            <TextInput value={email} onChange={val => setEmail(val)}> Email </TextInput>
        </InputsContainer>
    </>;
};

export default ProfileComponent;