export interface StatusState {
  pending: boolean;
  error: boolean;
}

export const initialStatusState: StatusState = {
  pending: false,
  error: false,
};

export interface OtpState {
  otpStatus: StatusState;
  resendOtpStatus: StatusState;
}

export const initialState: OtpState = {
  otpStatus: initialStatusState,
  resendOtpStatus: initialStatusState,
};
