import Router from "legacy/DFE/routing/Router";
import { RouteComponentType } from "legacy/DFE/routing/RouterTypes";
import React, { useState } from "react";
import Mobile from "./Mobile";
import OTP from "./OTP";
import RouterContainer from "legacy/DFE/components/RouterContainer";
import Text from "legacy/DFE/components/Text";
import styled from "styled-components";
import { actions } from "legacy/DFE/store/router";
import { connect, ConnectedProps } from "react-redux";
import ContainerSubmitButton from "legacy/DFE/components/ContainerSubmitButton";
import LoggedIn from "./LoggedIn";

const DisclaimerContainer = styled("div")`
    width: 100%;
    box-sizing: border-box;
    position: relative;
`;

const mapDispatchProps = {
    setPage: actions.setPage,
}

const connector = connect(undefined, mapDispatchProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type LandingType = RouteComponentType & PropsFromRedux;

const Landing: React.FC<LandingType> = (props: LandingType) => {
    const {
        done,
        setPage,
    } = props;

    const [currentRoute, setCurrentRoute] = useState("");

    const routes = [
        new Mobile(),
        new OTP(),
        new LoggedIn(),
    ];

    function onDone() {
        setPage("/onboarding");
        done();
    }

    return <>
        <RouterContainer>
            <Router onChangeRoute={(route) => { setCurrentRoute(route?.name); }} onDone={onDone} routes={routes} />
        </RouterContainer>
        <ContainerSubmitButton currentRoute={currentRoute} >
            {["Mobile", "LoggedIn"].includes(currentRoute) && <DisclaimerContainer>
                <Text className="inline thin" fontSize="12px"> By clicking continue, you will agree with the </Text>
                <Text className="inline thin link" fontSize="12px"> Terms and Condition, and Privacy Policy </Text>
            </DisclaimerContainer>}
        </ContainerSubmitButton>
    </>;
};

export default connector(Landing);