export interface ConsumerState {
  id?: string,
  mobile?: string,
  firstName?: string,
  lastName?: string,
  email?: string,
  address?: string,
  city?: string,
  province?: string,
  country?: string,
  zipcode?: string,
  idNumber?: string,
  selectedLanguage?: string,
}

export const initialState: ConsumerState = {
  
};
