import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import queryString from 'query-string';
import { AxiosError } from 'axios';

import cardLoader from 'assets/card-loader.gif';
import { AppComponentProps } from 'types/apps';
import useSubmitHandler from 'apps/UXFlow/utils/hooks/useSubmitHandler';
import flowAPI from 'api/flowAPI';
import flow from 'apps/UXFlow/store/flow';
import { DESIGN } from 'types/design';
import ErrorComponent from 'components/Error';
import ui from 'store/ui';
import { ParentProps } from '../types';
import DefaultECPCardTitle from './Default';
import DEECPCardTitle from './DE';
import { Analytics } from 'utils/Analytics';
import Popup from 'components/Popup/Popup';
import ScrollablePopover from 'components/ScrollablePopover';
import ErrorPopup from 'components/ErrorPopup';

// TODO: check with product the UI issue on mobile (can't reproduce, it's scrollable)
const IFrameWrapper = styled('div')`
  text-align: center;
  height: 370px;
  min-height: 370px;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  flex-direction: column;

  iframe {
    height: 370px;
    min-height: 370px;
    min-width: 400px;
    width: 400px;
    padding: 0px 25px;
  }
`;

const ErrorPopupContainer = styled('div')`
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0px;
`;

/*
flex-grow: 1;
  --position: absolute;
  bottom: 10px;
*/

const LoaderWrapper = styled('div')``;

type PostedMessage = {
  data: string;
};

let cardsChecked = 0;
const EcpSuccess = '01';

const delay = (ms: number): Promise<void> =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, ms);
  });

const runPolling = async (timout: number): Promise<boolean> => {
  try {
    for (let i = 0; i < timout; i++) {
      const result = await flowAPI.getFlow({
        isPolling: true,
      });

      if (result.data.next !== 'ECPCard') {
        return true;
      }

      const flowData = result.data.data;

      if (
        flowData.cards.length > cardsChecked &&
        flowData.cards[flowData.cards.length - 1].invalidation
      ) {
        cardsChecked = flowData.cards.length;
        return false;
      }

      await delay(1000);
    }

    return false;
  } catch (err) {
    if (err instanceof Error) {
      throw err;
    }

    return false;
  }
};

const isECPCardMessage = (cardType?: string | string[] | null): boolean => {
  if (Array.isArray(cardType)) {
    return cardType.includes('ECP');
  }

  return cardType === 'ECP';
};

const isECPSuccess = (ecpResponse?: string | string[] | null): boolean => {
  if (Array.isArray(ecpResponse)) {
    return ecpResponse.includes(EcpSuccess);
  }

  return ecpResponse === EcpSuccess;
};

const ECPCard: React.FC<AppComponentProps & ParentProps> = (
  props: AppComponentProps & ParentProps
) => {
  const { onGetFlow } = useSubmitHandler(props);
  const { data } = flow;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [URL, setURL] = useState<string>(data.URL);

  const [errorMessage, setErrorMessage] = useState<string>();
  const [showError, setShowError] = useState<boolean>(false);

  const [legalPopupOpen, setLegalPopupOpen] = useState<boolean>(false);

  useEffect(() => {
    cardsChecked = data.cards.length;
    Analytics.sendEvent('Onboarding - Add card Page - Visited');
  }, []);

  useEffect(() => {
    showError && setLegalPopupOpen(true);
  }, [showError])

  const receiveMessage = async (message: PostedMessage): Promise<void> => {
    const queryParams = queryString.parse(message.data);

    if (isECPCardMessage(queryParams.cardType)) {
      setShowError(false);
      setErrorMessage(undefined);

      const ecpResponse = queryParams.result;

      if (!ecpResponse || !isECPSuccess(ecpResponse)) {
        setShowError(true);
        setErrorMessage(ui.texts.CardCompletionFailed);
        await Analytics.sendEvent("Add card page- card tokenization fail");

        await onGetFlow();

        setURL(flow.data.URL);
        setIsLoading(true);

        return;
      } else {
        await Analytics.sendEvent("Add card page- card tokenization successful");
      }

      try {
        const result = await runPolling(data.timeout ? +data.timeout : 15);

        if (!result) {
          await onGetFlow();
          setShowError(true);
        }
      } catch (err) {
        if (err instanceof Error) {
          const pageExceptionError = ((err as AxiosError).response?.data?.errors || []).filter(
            (e: { code: string }) => e.code === 'ErrorPageException'
          );

          // don't show error on error page
          if (!pageExceptionError.length) {
            setShowError(true);
            setErrorMessage(ui.texts.CardCompletionFailed);
          }
        }
      }

      await onGetFlow();

      setURL(flow.data.URL);
      setIsLoading(true);

    }

    return;
  };

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);

    return (): void => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, []);

  const onLoadHandler = (): void => {
    setIsLoading(false);
    setShowError(false);
  };

  const PageComponents = {
    [DESIGN.DE]: DEECPCardTitle,
    [DESIGN.default]: DefaultECPCardTitle,
  };

  const Page = PageComponents[ui.design];

  const error = errorMessage || data.message || ui.texts.CardCompletionFailed;

  return (
    <Page {...props}>
      <IFrameWrapper>
        <ErrorPopupContainer>
          {legalPopupOpen && (
            <ErrorPopup
              content={error}
              close={() => setLegalPopupOpen(false)}
            />
          )}
        </ErrorPopupContainer>

        {isLoading && (
          <LoaderWrapper>
            <img src={cardLoader} alt="loader" width="30px" height="30px" />
          </LoaderWrapper>
        )}
        <iframe
          src={URL}
          id="cardDetailsIframe"
          onLoad={onLoadHandler}
          title="card-details"
          style={{ borderStyle: 'none', display: isLoading ? 'none' : 'block', minWidth: '100%' }}
        />
      </IFrameWrapper>

    </Page>
  );
};

export default ECPCard;
