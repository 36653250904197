import { observer } from 'mobx-react-lite';
import React from 'react';
import ui from 'store/ui';
import { debugEnabled } from 'utils/env';
import { StyledAnchor, StyledSpan } from './style';
import { TextType } from './types';

type TextProps = {
  type?: TextType;
  className?: string;
  messageId?: string;
  content?: string;
  weight?: string;
  onClick?: () => void;
  url?: string;
};

const Text: React.FC<TextProps> = (props: TextProps) => {
  const { messageId: propMessageId, content, type, className, weight, onClick } = props;

  const messageId = propMessageId || (content ? undefined : type); // Use the type as the default message id, Because they're usually the same

  const text = (messageId ? ui.texts[messageId] : content) || (debugEnabled ? messageId : ''); // Fallback only when we want to debug (dev/test envs)

  return (
    <>
      {props.url ? (
        <StyledAnchor
          weight={weight}
          className={className}
          type={type}
          onClick={onClick}
          href={props.url}
          target="_blank"
        >
          {text}
        </StyledAnchor>
      ) : (
        <StyledSpan weight={weight} className={className} type={type} onClick={onClick}>
          {text}
          {localStorage.getItem('QA_SEE_TEXT_KEYS') === 'true'
            ? `[${messageId || 'HARDCODED'}]`
            : ''}
        </StyledSpan>
      )}
    </>
  );
};

export default observer(Text);
