import React, { useEffect } from 'react';
import ReactSelect, { components, IndicatorProps, ValueType } from 'react-select';
import arrow from 'legacy/assets/arrow.svg';
import { SelectWrapper, DropdownImage, SelectFormStyled, Error } from './selectForm.styles';
import Text from 'components/Text';
import ui from 'store/ui';

interface Option {
  value: string | number;
  messageId?: string;
  content?: React.ReactNode;
}

export interface SelectStyledProps {
  maxWidth?: string;
}

interface SelectProps extends SelectStyledProps {
  options: Option[];
  onChange: (optionValue: any) => void;
  value?: string | number | null;
  errorStatus?: boolean;
  errorMsgId?: string;
  placeholderMsgId?: string;
  isDisabled?: boolean;
  isSearchable?: boolean;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  maxLength?: number;
  type?: 'number' | 'text' | 'date' | 'tel' | 'file' | 'autocomplete' | 'email' | 'search';
}

const DropdownIndicator = (props: IndicatorProps<any>) => (
  <components.DropdownIndicator {...props}>
    <DropdownImage src={arrow} alt="arrow" />
  </components.DropdownIndicator>
);

const SelectForm: React.SFC<SelectProps> = (props: SelectProps) => {
  const {
    options,
    onChange,
    value,
    maxWidth,
    errorMsgId,
    errorStatus,
    placeholderMsgId,
    isDisabled,
    isSearchable,
    onMenuOpen,
    onMenuClose,
    maxLength,
    type,
  } = props;

  const onChangeSelect = ({ value }: ValueType<any>) => {
    onChange(value);
  };

  const optionsTranslated = options.map(({ value, messageId = '', content }) => {
    const labelContent = content || ui.texts[messageId];
    return { value, label: labelContent };
  });
  const currentValue = optionsTranslated.find(option => option.value === value);
  const placeholder = placeholderMsgId && ui.texts[placeholderMsgId];

  // useEffect(() => {
  //   // eslint-disable-next-line
  //   if (options.length === 1 && !currentValue) {
  //     // eslint-disable-next-line
  //     onChangeSelect(options[0]);
  //   }
  // }, [options]);

  return (
    <SelectFormStyled maxWidth={maxWidth}>
      <SelectWrapper>
        <ReactSelect
          type={type}
          value={currentValue || ''}
          components={{ DropdownIndicator }}
          options={optionsTranslated}
          placeholder={placeholder}
          onChange={onChangeSelect}
          isDisabled={isDisabled}
          isSearchable={isSearchable}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          onInputChange={(inputValue): string =>
            maxLength == null || inputValue.length <= maxLength
              ? inputValue
              : inputValue.substr(0, maxLength)
          }
        />
      </SelectWrapper>
      {errorStatus && (
        <Error>
          <Text messageId={errorMsgId} className="red" /* className="fs-14 red" */ />
        </Error>
      )}
    </SelectFormStyled>
  );
};

SelectForm.defaultProps = {
  value: null,
  errorStatus: false,
  errorMsgId: '',
  maxWidth: '',
  placeholderMsgId: '',
  isDisabled: false,
  isSearchable: true,
  onMenuOpen: () => {},
  onMenuClose: () => {},
};

export default SelectForm;
