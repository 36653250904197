import { take, call, put } from 'redux-saga/effects';
import { PayloadAction } from 'redux-starter-kit';
import { sendSupport } from 'legacy/api';
import { notify } from 'legacy/helpers/notify';
import { actions as UIActions } from 'legacy/store/UI';
import { languageNames } from 'legacy/constants/languageNames';
import { errorsWithToasts } from 'legacy/constants/errorsWithToasts';
import { actions } from '../index';
import { getCurrentLanguage } from 'legacy/helpers/language';

export function* contactUsSaga() {
  while (true) {
    const { payload }: PayloadAction<any> = yield take(actions.tryToSendEmail);
    try {
      const currentLanguage = getCurrentLanguage();
      const emailData = {
        ...payload,
        language: languageNames[currentLanguage],
      };

      const { result } = yield call(sendSupport, emailData);

      if (!result) {
        throw new Error('Sending failed');
      }
      yield put(actions.sendEmailSucceed());
      yield put(UIActions.openPopup('contact-us-success'));
    } catch (err) {
      yield put(actions.sendEmailFailed());

      if (!errorsWithToasts.find(errorItem => err.message === errorItem.message)) {
        notify('error', 'something-went-wrong');
      }
    }
  }
}
