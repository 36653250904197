export class ScrollManager {
    static stateUpdate?: (allow: boolean) => void = undefined;
    static intervalId?: number;

    static setStateFunction(func?: (allow: boolean) => void) {
        ScrollManager.stateUpdate = func;
    }

    static setAllowAutoScroll(allow: boolean) {
        ScrollManager.clearInterval();
        ScrollManager.intervalId = setInterval(() => {
            if (ScrollManager.stateUpdate) {
                ScrollManager.stateUpdate(allow);
                ScrollManager.clearInterval();
            }
        }, 500);
    }

    static clearInterval() {
        clearInterval(ScrollManager.intervalId);
    }
}