import { Route } from 'legacy/DFE/routing/RouterTypes';
import { selectors } from 'legacy/DFE/store/order';
import { State } from 'legacy/DFE/store/state';
import PaymentFailureComponent from './index.component';

export default class PaymentFailure extends Route {
  constructor() {
    super('PaymentFailure', '/paymentFailure');
  }

  async precondition(state: State): Promise<boolean> {
    const order = selectors.order(state);
    const paymentFlow = order?.paymentFlow;

    if (paymentFlow?.failureScreen.off && order?.failureURL) {
      window.location.href = order?.failureURL;
    }

    return !paymentFlow?.failureScreen?.off && super.precondition(state);
  }

  component = PaymentFailureComponent;
}
