import React from 'react';
import FlexColumn from 'components/FlexColumn';
import Button from 'components/Button';
import tickIcon from 'assets/tick.svg';
import Popup from 'components/Popup/Popup';
import { PopupProps } from 'components/Popup/types';
import styled from 'styled-components';
import Text from 'components/Text';

const Icon = styled('img')`
  height: 70px;
  width: auto;
  margin-bottom: 30px;
`;

const ContactUsSuccess: React.FC<PopupProps> = (props: PopupProps) => {
  const { isOpen, onClose } = props;

  return (
    <Popup isOpen={isOpen} onClose={onClose}>
      <FlexColumn>
        <Icon src={tickIcon} alt="tick-icon" />
        <Text messageId="thankYou" type="subtitle" />
        <Text messageId="contactUsSuccess" type="content" className="light" />
        <Button onClick={onClose} messageId="continue" />
      </FlexColumn>
    </Popup>
  );
};

export default ContactUsSuccess;
