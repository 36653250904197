import React from 'react';
import ui from 'store/ui';
import { AppComponentProps, AppProps } from 'types/apps';
import { DESIGN } from 'types/design';

import PageContainer from 'apps/UXFlow/components/PageContainer/DE';
import DEPaymentMethodSelectionForm from './DE';
import DefaultPaymentMethodSelectionForm from './Default';
import { ParentProps } from '../types';

const DEPaymentMethodSelection: React.FC<AppComponentProps & ParentProps & AppProps> = (
  props: AppComponentProps & ParentProps & AppProps
) => {
  const PaymentMethodSelectionComponents = {
    [DESIGN.DE]: DEPaymentMethodSelectionForm,
    [DESIGN.default]: DefaultPaymentMethodSelectionForm,
  };

  const PaymentSelectMethodForm = PaymentMethodSelectionComponents[ui.design];

  return (
    <PageContainer
      titleMessageId="title"
      goBack={props.goBack}
      backBtnEnabled={props.backBtnEnabled}
      onClose={props.onClose}
    >
      <PaymentSelectMethodForm {...props} />
    </PageContainer>
  );
};

export default DEPaymentMethodSelection;
