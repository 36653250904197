import Text from "legacy/DFE/components/Text";
import { RouteComponentType } from "legacy/DFE/routing/RouterTypes";
import React from "react";
import { ParentProps } from "../../types";
import IFrame from "./iframe";

type CardComponentType = ParentProps & RouteComponentType;

const CardComponent: React.FC<CardComponentType> = (props: CardComponentType) => {
    const {
        done,
        submitFlow,
        flow,
    } = props;

    return <>
        <Text fontSize="24px" className="bold"> Add your card </Text>
        <Text fontSize="18px" className="thin"> Enter your card, authorization will not be charged </Text>
        <IFrame done={() => submitFlow({}).then(done)} flow={flow} />
    </>;
};

export default CardComponent;