import { take, put, call, select } from 'redux-saga/effects';
import { PayloadAction } from 'redux-starter-kit';
import { saveRecoverPin } from 'legacy/api';
import { RecoverPinStatus } from 'legacy/types/RecoverPinStatus';
import { actions, selectors } from 'legacy/store/login';
import { AuthService } from 'legacy/utils/AuthService';
import { errorsWithToasts } from 'legacy/constants/errorsWithToasts';
import { notify } from 'legacy/helpers/notify';
import { history } from 'legacy/routing/history';

export function* setPinCodeSaga() {
  while (true) {
    const { payload: pin }: PayloadAction<number> = yield take(actions.requestResetPinCode);

    try {
      const { mobile } = yield select(selectors.userSelector);
      const { token } = yield call(saveRecoverPin, mobile, pin.toString());

      // call success handlers
      yield call(AuthService.setToken, token);
      yield put(actions.successResetPinCode());
      yield put(actions.setStatus(RecoverPinStatus.done));
    } catch (err) {
      yield put(actions.errorResetPinCode());
      if (err.statusCode === 400) {
        notify('error', 'expired-token-for-recover-pin');
        history.goBack();
      } else if (!errorsWithToasts.find(errorItem => err.message === errorItem.message)) {
        notify('error', 'something-went-wrong');
      }
    }
  }
}
