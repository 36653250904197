import React from 'react';
import styled from 'styled-components';
import Text from 'components/Text';
import { redirect } from 'helpers/redirection';
import PageContainer from 'apps/UXFlow/components/PageContainer/DE';
import { AppProps } from 'types/apps';

const Message = styled(Text)`
  text-align: center;
`;

interface Props {
  url: string;
  loading: boolean;
}

const DEExpiredPayment: React.FC<Props & AppProps> = (props: Props & AppProps) => {
  const { url, loading } = props;
  const handleReturn = (): void => {
    redirect(url);
  };

  return (
    <PageContainer
      titleMessageId="DEPaymentExpiredTitle"
      buttonMessageId="return"
      buttonOnClick={handleReturn}
      buttonIsLoading={loading}
      goBack={props.goBack}
      backBtnEnabled={props.backBtnEnabled}
      onClose={props.onClose}
      buttonStyles={{ bottom: '18.5%' }}
    >
      <Message messageId="DEPaymentExpiredInfo" type="content" />
    </PageContainer>
  );
};

export default DEExpiredPayment;
