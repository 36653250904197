import React, { useEffect, useState } from 'react';
import Input from 'components/InputForm';
import styled from 'styled-components';

import flow from 'apps/UXFlow/store/flow';
import useSubmitHandler from 'apps/UXFlow/utils/hooks/useSubmitHandler';
import useEventListener from 'apps/UXFlow/utils/hooks/useEventListener';
import Text from 'components/Text';
import { AppComponentProps, AppProps } from 'types/apps';

import { ParentProps } from '../../types';
import moment from 'moment';
import ui from 'store/ui';
import PageContainer from 'apps/UXFlow/components/PageContainer/DE';
import { Analytics } from 'utils/Analytics';

const InputSection = styled('div')`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 91px;

  & > * {
    color: #a5a5a5 !important;
  }

  width: 100%;
`;

const Inputs = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

type CLIENT_SIDE_ERRORS = {
  email?: string;
  firstName?: string;
  lastName?: string;
  birthDate?: string;
};

const isDateInRange = (minAge: number, maxAge: number, birthDate: string): boolean => {
  const since = new Date();
  const till = new Date();
  since.setFullYear(since.getFullYear() - maxAge);
  till.setFullYear(till.getFullYear() - minAge);
  const md = moment(new Date(birthDate));

  return md.isBefore(till) && md.isAfter(since);
};

const isOlder = (age: number, birthDate: string): boolean => {
  const till = new Date();
  till.setFullYear(till.getFullYear() - age);
  const md = moment(new Date(birthDate));
  return md.isBefore(till);
};

const isNameValid = (name: string): boolean => {
  // latin alphabet + special german letters
  return !!name.trim().length && /^[A-Za-zßäöüẞÄÖÜëéï\s]+$/.test(name);
};

const isEmailValid = (email: string): boolean => {
  const emailRegex = /^(\w+([\.\-]\w+)*)@(((?:\w\-\w+|\w{2,})(\-\w+)*\.){1,3}[a-zA-Z]{2,})$/;
  return !!email.trim() && emailRegex.test(email);
};

const isRequired = (name: string): boolean => {
  return !!name.trim();
}

const KYCForm: React.FC<AppComponentProps & ParentProps & AppProps> = (
  props: AppComponentProps & ParentProps & AppProps
) => {
  const { data, errors } = flow;
  const { onSubmit, loading } = useSubmitHandler(props);

  const [clientSideErrors, setClientSideErrors] = useState<CLIENT_SIDE_ERRORS>({});

  const [email, setEmail] = useState<string>(data?.email || '');
  const [firstName, setFirstName] = useState<string>(data?.firstName || '');
  const [lastName, setLastName] = useState<string>(data?.lastName || '');
  const [birthDate, setBirthDate] = useState<string>(data?.birthDate || '');

  useEffect(() => {
    console.log("id: ", flow.profile?.originalMobile);
    Analytics.identifyUser(data.consumerId);
    Analytics.sendEvent("KYCPageVisited");
    if(firstName.length || lastName.length || email.length || birthDate.length) {
      Analytics.sendEvent("KYCPageInfoAutoPopulated");
    }
  }, [])

  const emailChangeHandler = (email: string) => {
    setEmail(email.replace(/ /g, ""));
  }

  const onChangeFirstName = (firstName: string): void => {
    if (firstName.length <= 100) {
      setFirstName(firstName);
    }
  };

  const onChangeLastName = (lastName: string): void => {
    if (lastName.length <= 100) {
      setLastName(lastName);
    }
  };

  const validate = (): boolean => {
    let isValid = true;
    const errors = {} as CLIENT_SIDE_ERRORS;

    if (!isNameValid(firstName)) {
      errors.firstName = ui.texts.DEMustContainOnlyLettersFirst;
      isValid = false;
    }
    if (!isRequired(firstName)) {
      errors.firstName = ui.texts.DEFirstNameCanNotBeEmpty;
      isValid = false;
    }
    if (!isNameValid(lastName)) {
      errors.lastName = ui.texts.DEMustContainOnlyLettersLast;
      isValid = false;
    }
    if (!isRequired(lastName)) {
      errors.lastName = ui.texts.DELastNameCanNotBeEmpty;
      isValid = false;
    }
    if (!isEmailValid(email)) {
      errors.email = ui.texts.DEEmailInvalid;
      isValid = false;
    }
    if (!isRequired(email)) {
      errors.email = ui.texts.DEEmailCanNotBeEmpty;
      isValid = false;
    }
    if (!isOlder(18, birthDate)) {
      errors.birthDate = ui.texts.DEAgeShouldBeInRange;
      isValid = false;
    }
    if (!isRequired(birthDate)) {
      errors.birthDate = ui.texts.DEDateBirthCanNotBeEmpty;
      isValid = false;
    }
    if (isOlder(101, birthDate)) {
      errors.birthDate = ui.texts.DEInvalidDate;
      isValid = false;
    }

    setClientSideErrors(errors);

    return isValid;
  };

  const handleSubmit = (): void => {
    Analytics.sendEvent("KYCPageClickedConfirm")
    if (!validate()) {
      return;
    }

    onSubmit({
      email,
      firstName,
      lastName,
      birthDate,
    });
  };

  useEventListener('keypress', handleSubmit);

  const hasError = Object.keys(clientSideErrors).length || Object.keys(errors).length;
  const errorContent =
  clientSideErrors[Object.keys(clientSideErrors)[0]] || errors[Object.keys(errors)[0]]?.message;

  return (
    <PageContainer
      titleMessageId="title"
      buttonMessageId="next"
      buttonOnClick={handleSubmit}
      buttonIsLoading={loading}
      errorContent={hasError ? errorContent : undefined}
      goBack={props.goBack}
      backBtnEnabled={props.backBtnEnabled}
      onClose={props.onClose}
      buttonStyles={{ bottom: '18.5%' }}
    >
      <Text messageId="subtitle" type="content" />

      <InputSection>
        <Inputs>
          <Input
            id="first_name"
            value={firstName}
            changeHandler={onChangeFirstName}
            placeholderMessageId="firstNameLabel"
            errorBorder={!!(errors.firstName?.message || clientSideErrors.firstName)}
          />
          <Input
            id="last_name"
            value={lastName}
            changeHandler={onChangeLastName}
            placeholderMessageId="lastNameLabel"
            errorBorder={!!(errors.lastName?.message || clientSideErrors.lastName)}
          />
          <Input
            id="email"
            value={email}
            changeHandler={emailChangeHandler}
            placeholderMessageId="emailLabel"
            errorBorder={!!(errors.email?.message || clientSideErrors.email)}
          />
          <Input
            id="birth_date"
            value={birthDate}
            type="date"
            changeHandler={value => {
              if (value.length <= 10) {
                setBirthDate(value);
              }
            }}
            placeholderMessageId="birthdateLabel"
            errorBorder={!!(errors.birthDate?.message || clientSideErrors.birthDate)}
          />
        </Inputs>
      </InputSection>
    </PageContainer>
  );
};

export default KYCForm;
