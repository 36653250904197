import React, { useEffect, useState } from 'react';
import Input from 'components/InputForm';
import Text from 'components/Text';
import Button from 'components/Button';
import styled from 'styled-components';

import flow from 'apps/UXFlow/store/flow';
import useEventListener from 'apps/UXFlow/utils/hooks/useEventListener';
import { AppComponentProps, AppProps } from 'types/apps';

import { ParentProps } from '../../types';
import contactUsAPI from 'api/contactUsAPI';
import PageContainer from 'apps/UXFlow/components/PageContainer/DE';
import ui from 'store/ui';

const InputSection = styled('div')`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 91px;

  & > * {
    color: #a5a5a5 !important;
  }

  width: 100%;
`;

const Inputs = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const ConfirmationBox = styled('div')`
  margin-top: 40px;
  padding: 30px;
  width: 100%;
  max-width: 500px;
  min-height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  align-self: start;
  position: relative;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 0px 30px rgb(0 0 0 / 25%);
  border-radius: 5px;
`;

const TextBox = styled('div')`
  margin-bottom: 16px;
  flex-grow: 1;
  text-align: center;
`;

const TextItem = styled(Text)`
  display: block;
  margin: 16px;
`;

enum screens {
  form = 'form',
  confirmation = 'confirmation',
}
type CLIENT_SIDE_ERRORS = {
  email?: string;
  firstName?: string;
  lastName?: string;
  subject?: string;
  body?: string;
};

function findEmptyValues(obj: Object) {
  const emptyValues = [];
  for (let prop in obj) {
    if (obj[prop] === '') {
      emptyValues.push(prop);
    }
  }
  return emptyValues;
}

const isEmailValid = (email: string): boolean => {
  const emailRegex = /^(\w+([\.\-]\w+)*)@(((?:\w\-\w+|\w{2,})(\-\w+)*\.){1,3}[a-zA-Z]{2,})$/;
  return emailRegex.test(email);
};

const ContactUs: React.FC<AppComponentProps & ParentProps & AppProps> = (
  props: AppComponentProps & ParentProps & AppProps
) => {
  const { errors } = flow;

  const [firstName, setFirstName] = useState<string>(flow.profile?.firstName || '');
  const [lastName, setLastName] = useState<string>(flow.profile?.lastName || '');
  const [email, setEmail] = useState<string>(flow.profile?.useremail || '');
  const [subject, setSubject] = useState<string>('');
  const [body, setBody] = useState<string>('');

  const [screen, setScreen] = useState<screens>(screens.form);
  const [loading, setLoading] = useState<boolean>(false);
  const [clientSideErrors, setClientSideErrors] = useState<CLIENT_SIDE_ERRORS>({});

  const validate = (): boolean => {
    let isValid = true;
    const errors = {} as CLIENT_SIDE_ERRORS;
    const fieldsToValidate = { firstName, lastName, email, subject, body }
    const emptyFields = findEmptyValues(fieldsToValidate);

    if (emptyFields.length) {
      emptyFields.forEach(field => {
        errors[field] = ui.texts.DEContactUsFieldCannotBeEmpty;
        isValid = false;
      })
    }

    if (!isEmailValid(email)) {
      errors.email = ui.texts.DEContactUsEmailInvalid;
      isValid = false;
    }

    setClientSideErrors(errors);
    return isValid;
  };

  const handleSubmit = async (): Promise<void> => {
    if (!validate()) {
      return;
    }
    setLoading(true);

    await contactUsAPI.sendEmail({
      name: firstName,
      lastName,
      email,
      subject: subject,
      body: body,
      paymentRequestId: flow.requestId || '',
    });
    setLoading(false);

    setScreen(screens.confirmation);
  };

  useEventListener('keypress', handleSubmit);

  const isError = Object.keys(errors).length || Object.keys(clientSideErrors).length;
  const errorContent =
    errors[Object.keys(errors)[0]]?.message || clientSideErrors[Object.keys(clientSideErrors)[0]];

  const formScreen = (
    <PageContainer
      titleMessageId="contactUs"
      buttonMessageId="DEContactUsButtonName"
      buttonOnClick={handleSubmit}
      buttonIsLoading={loading}
      errorContent={isError ? errorContent : undefined}
      goBack={props.goBack}
      backBtnEnabled={true}
      onClose={props.onClose}
    >
      <InputSection>
        <Inputs>
          <Input
            id="contact_us_first_name"
            value={firstName}
            changeHandler={setFirstName}
            placeholderMessageId="DEContactUsFirstNameLabel"
            errorBorder={!!(errors.firstName?.message || clientSideErrors.firstName)}
          />
          <Input
            id="contact_us_last_name"
            value={lastName}
            changeHandler={setLastName}
            placeholderMessageId="DEContactUsLastNameLabel"
            errorBorder={!!(errors.lastName?.message || clientSideErrors.lastName)}
          />
          <Input
            id="contact_us_email"
            value={email}
            changeHandler={setEmail}
            placeholderMessageId="DEContactUsEmailLabel"
            errorBorder={!!(errors.email?.message || clientSideErrors.email)}
          />
          <Input
            value={subject}
            changeHandler={setSubject}
            placeholderMessageId="DEContactUsSubjectLabel"
            errorBorder={!!(errors.subject?.message || clientSideErrors.subject)}
          />
          <Input
            type="textarea"
            value={body}
            changeHandler={setBody}
            placeholderMessageId="DEContactUsBodyLabel"
            errorBorder={!!(errors.body?.message || clientSideErrors.body)}
            style={{ height: '130px' }}
          />
        </Inputs>
      </InputSection>
    </PageContainer>
  );

  const confirmationScreen = (
    <PageContainer
      titleMessageId="contactUs"
      buttonMessageId=""
      buttonOnClick={handleSubmit}
      buttonIsLoading={loading}
      errorContent={
        !!Object.keys(errors)?.length ? errors[Object.keys(errors)[0]]?.message : undefined
      }
      goBack={props.goBack}
      backBtnEnabled={true}
      onClose={props.onClose}
    >
      <ConfirmationBox>
        <TextBox>
          <TextItem messageId="DEContactUsConfirmationTitle" type="subtitle" />
          <TextItem messageId="DEContactUsConfirmationInfo" type="subtitle" />
        </TextBox>

        <Button messageId="continue" onClick={props.goBack} />
      </ConfirmationBox>
    </PageContainer>
  );

  return screen === screens.form ? formScreen : confirmationScreen;
};

export default ContactUs;
