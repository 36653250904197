import Text from "legacy/DFE/components/Text";
import TextInput from "legacy/DFE/components/TextInput";
import { notify } from "legacy/DFE/helpers/notify";
import { RouteComponentType } from "legacy/DFE/routing/RouterTypes";
import React, { useState } from "react";
import InputsContainer from "../../components/InputsContainer";
import { ParentProps } from "../../types";

type PinCodeComponentType = RouteComponentType & ParentProps;

const PinCodeComponent: React.FC<PinCodeComponentType> = (props: PinCodeComponentType) => {
    const {
        useSubmit,
        done,
        submitFlow,
    } = props;

    const [firstCode, setFirstCode] = useState("");
    const [secondCode, setSecondCode] = useState("");

    // Just to make sure it's not longer than 4 chars & not contains any non-numeric chars
    const handleCodeChange = (value: string, setFunc: (v: string) => void) => {
        if (value.length > 4 && value.match(/[^0-9]/g)) {
            return;
        }

        setFunc(value);
    };

    useSubmit(() => {
        if (firstCode !== secondCode) {
            notify("error", "The codes don't match")
            return;
        }

        submitFlow({
            pin: firstCode,
        }).then(done);
    });

    return <>
        <Text fontSize="24px" className="bold"> Set up PIN code </Text>
        <Text fontSize="18px" className="thin"> The PIN code will be used to protect your account </Text>
        <InputsContainer>
            <TextInput onChange={(v) => handleCodeChange(v, setFirstCode)} value={firstCode}> Enter your 4 digits PIN Code </TextInput>
            <TextInput disabled={firstCode.length != 4} onChange={(v) => handleCodeChange(v, setSecondCode)} value={secondCode}> Confirm your 4 digits PIN Code </TextInput>
        </InputsContainer>
    </>;
};

export default PinCodeComponent;