import { useCallback, useState } from 'react';

type Response = [boolean, (ref: HTMLImageElement | null) => void];

export const useImgOrientation = (): Response => {
  const [isHorizontal, setIsHorizontal] = useState(false);

  const ref = useCallback((node) => {
    if (node) {
      if (node.naturalHeight > node.naturalWidth) {
        setIsHorizontal(false);
      } else {
        setIsHorizontal(true);
      }
    }
  }, []);

  return [isHorizontal, ref];
};
