import { take, call, select } from 'redux-saga/effects';
import { actions } from 'legacy/store/UI';

const closePopupEvent = new Event("closePopup");

export function* closePopupSaga(): any {
  while (true) {
    yield take(actions.closePopup);
    // const state = yield select();
    // if (state.UI.refreshOnClosePopup) {
    //   window.location.reload();
    // }
    document.dispatchEvent(closePopupEvent);
  }
}
