import * as Sentry from "@sentry/react";

function convertToString(data: any): string {
    if (typeof data === "string") {
        return data;
    }

    if (typeof data === "object") {
        return JSON.stringify(data);
    }

    return data.toString();
}

export default class Logger {
    constructor(private context: string) {
        //
    }

    private static byLevel(level: Sentry.Severity, items: any[], context = "Logger") {
        items = [
            `[${context}]`,
            ...items,
        ]

        // Logging only on dev & When enabled in the console to debug on other environments
        if (process.env.NODE_ENV === 'development' || (document as any).ENABLE_LOGGER || process.env.REACT_APP_ENABLE_LOGGER) {
            switch (level) {
                case Sentry.Severity.Log:
                    console.log(...items);
                    break;
                case Sentry.Severity.Debug:
                    console.debug(...items);
                    break;
                case Sentry.Severity.Info:
                    console.info(...items);
                    break;
                case Sentry.Severity.Warning:
                    console.warn(...items);
                    break;
                case Sentry.Severity.Error:
                    console.error(...items);
                    // Putting the error on Sentry's board
                    items.forEach((item: any) => {
                        if (typeof item === "object") {
                            Sentry.captureException(item);
                        }
                        else {
                            Sentry.captureMessage(item);
                        }
                    });
                    break;
            }
        }
        // Adding the logs into Sentry even if we're not logging them into console
        else if (level !== Sentry.Severity.Error) {
            items.forEach(item => {
                Sentry.addBreadcrumb({
                    category: context,
                    message: convertToString(item),
                    level,
                });
            });
        }
    }

    // Static methods
    static log(context: string, ...items: any) {
        Logger.byLevel(Sentry.Severity.Log, items, context);
    }

    static debug(context: string, ...items: any) {
        Logger.byLevel(Sentry.Severity.Debug, items, context);
    }

    static info(context: string, ...items: any) {
        Logger.byLevel(Sentry.Severity.Info, items, context);
    }

    static warn(context: string, ...items: any) {
        Logger.byLevel(Sentry.Severity.Warning, items, context);
    }

    static error(context: string, ...items: any) {
        Logger.byLevel(Sentry.Severity.Error, items, context);
    }

    // Instance methods
    public log(...items: any) {
        Logger.log(this.context, ...items);
    }

    public debug(...items: any) {
        Logger.debug(this.context, ...items);
    }

    public info(...items: any) {
        Logger.info(this.context, ...items);
    }

    public warn(...items: any) {
        Logger.warn(this.context, ...items);
    }

    public error(...items: any) {
        Logger.error(this.context, ...items);
    }
}