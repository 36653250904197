import * as React from 'react';
import Text from 'legacy/DFE/components/Text';
import { TextareaFormStyles, TextareaStyled, Error } from './textareaForm.styles';

let focusedTime: number;

interface InputProps {
  changeHandler?: (value: string) => void;
  children: string;
  placeholder?: string;
  errorStatus?: boolean;
  errorMsgId?: string;
  name?: string;
}

const TextareaForm: React.FC<InputProps & Partial<InputProps>> = (
  props: InputProps & Partial<InputProps>
) => {
  const { placeholder, changeHandler, children, errorMsgId, errorStatus } = props;
  const onChange = ({ target }: React.ChangeEvent<HTMLTextAreaElement>) => {
    changeHandler && changeHandler(target.value);
  };

  const onFocus = () => {
    focusedTime = new Date().getTime();
  };

  return (
    <TextareaFormStyles>
      <TextareaStyled
        placeholder={placeholder}
        value={children}
        onChange={onChange}
        onFocus={onFocus}
      />
      {errorStatus && (
        <Error>
          <Text fontSize={'14px'} className="red">{errorMsgId}</Text>
        </Error>
      )}
    </TextareaFormStyles>
  );
};

TextareaForm.defaultProps = {
  changeHandler: () => null,
  placeholder: '',
  errorStatus: false,
  errorMsgId: '',
} as Partial<InputProps>;

export default TextareaForm;
