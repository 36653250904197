import { createSlice, createAction, PayloadAction } from 'redux-starter-kit';
import { initialDossierApprovalStatus, DossierApprovalStatus } from './state';

/** accept dossier status slice */
const dossierApprovalStatusSlice = createSlice({
  name: 'dossierApprovalStatus',
  initialState: initialDossierApprovalStatus,
  reducers: {
    requestToAcceptDossier: (state, action: PayloadAction<{terms: any[], version: string}>): DossierApprovalStatus => ({
      ...state,
      pending: true,
      error: false,
      terms: action.payload.terms,
      version: action.payload.version,
    }),
    successAcceptDossier: (state): DossierApprovalStatus => ({
      ...state,
      pending: false,
      success: true,
    }),
    errorAcceptDossier: (state): DossierApprovalStatus => ({
      ...state,
      pending: false,
      error: true,
    }),
  },
});

export const { reducer } = dossierApprovalStatusSlice;

const getDossierData = createAction('getDossierData');

export const actions = {
  ...dossierApprovalStatusSlice.actions,
  getDossierData,
};
