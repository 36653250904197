import axios from 'axios';
import { InitialedCardData } from 'legacy/types/InitialedCardData';
import { getApiUrl } from 'legacy/helpers/getApiUrl';
import { getStringifiedParams } from 'legacy/helpers/getStringifiedParams';

/** cards api calls */
const CARDS_URL = 'cards/';

export const getHasCardStatus = (): Promise<boolean> => {
  return axios.get(getApiUrl(`${CARDS_URL}hasCard`));
};

export const initEcpCard = (): Promise<InitialedCardData> => {
  return axios.post(
    getApiUrl(`${CARDS_URL}initEcpCard`),
    getStringifiedParams({ isCustomCallback: 1 })
  );
};

export const getCards = (): Promise<void> => {
  return axios.get(getApiUrl(`${CARDS_URL}getCards`));
};

export const validateBIN = (bin: string): Promise<void> => {
  return axios.post(
    getApiUrl(`${CARDS_URL}validateBIN`),
    getStringifiedParams({ bin })
  );
};