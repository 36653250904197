import { combineReducers } from 'redux';
import { createAction } from 'redux-starter-kit';
import { State } from 'legacy/store/state';
import {
  reducer as transactionDetailsReducer,
  actions as transactionDetailsActions,
} from './transactionDetails';
import { TransactionState } from './state';
import { TransactionDetailsState } from './transactionDetails/state';
import {
  reducer as dossierApprovalStatusReducer,
  actions as dossierApprovalStatusActions,
} from './dossier';
import { DossierApprovalStatus } from './dossier/state';
import {
  otpValidationStatusReducer,
  resendPagoOtpStatusReducer,
  actions as otpValidationStatusActions,
} from './otp';
import { OtpApprovalStatus, ResendOtpStatus } from './otp/state';

export const reducer = combineReducers({
  transaction: transactionDetailsReducer,
  dossierApprovalStatus: dossierApprovalStatusReducer,
  otpValidationStatus: otpValidationStatusReducer,
  resendOtpStatus: resendPagoOtpStatusReducer,
});

const requestCancelCredit = createAction('requestCancelCredit');

export const actions = {
  ...transactionDetailsActions,
  ...dossierApprovalStatusActions,
  ...otpValidationStatusActions,
  requestCancelCredit,
};

/** selectors */
const getTransactionState = (state: State): TransactionState => state.transaction;
const transactionDetailsSelector = (state: State): TransactionDetailsState =>
  getTransactionState(state).transaction;
const getDossierApprovalStatus = (state: State): DossierApprovalStatus =>
  getTransactionState(state).dossierApprovalStatus;
const getOtpValidationStatus = (state: State): OtpApprovalStatus =>
  getTransactionState(state).otpValidationStatus;
const getResendPagoOtpStatus = (state: State): ResendOtpStatus =>
  getTransactionState(state).resendOtpStatus;

export const selectors = {
  transactionDetailsSelector,
  getDossierApprovalStatus,
  getOtpValidationStatus,
  getResendPagoOtpStatus,
};
