import { fork } from 'redux-saga/effects';
import { dossierSagas } from '../dossier/sagas';
import { otpValidationSagas } from '../otp/sagas';
import { cancelCreditSaga } from './cancelCreditSaga';
import { wizardSaga } from './wizardSaga';

export function* transactionsSagas() {
  yield fork(wizardSaga);
  yield fork(dossierSagas);
  yield fork(otpValidationSagas);
  yield fork(cancelCreditSaga);
}
