import { call, put } from 'redux-saga/effects';
import { uuid } from 'uuidv4';
import { TRANSACTION_ID_KEY, ORDER_ID_KEY, CANCEL_ID_KEY, FAILED_TRANSACTION_ID_KEY } from 'legacy/constants/storageKeys';
import { LocalStorageManager } from 'legacy/utils/LocalStorageManager';
import { Analytics } from 'legacy/utils/Analytics';
import { createTransaction, getPaymentStatus, getTransactionStatus } from 'legacy/api';
import { actions } from 'legacy/store/transaction';
import { PaymentStatus, PaymentStatusPorting } from 'legacy/types/PaymentStatus';
import { notify } from 'legacy/helpers/notify';
import { cleanOrderData } from 'legacy/helpers/cleanOrderData';
import { history } from 'legacy/routing/history';
import { WELCOME } from 'legacy/constants/pathnames';
import { ScoringStatus } from 'legacy/types/ScoringStatus';
import { store } from 'legacy/store/store';
import { selectors } from 'legacy/store/consumer';

/**
 * Conditions of exiting from polling:
 *
 * 1) timeout: no status change in 60 sec - failure
 * 2) status changed to 'scoring_failed' - failure
 * 3) status changed to 'order_attached' - success
 */
export function tryToGetScoringResult(id: string): Promise<ScoringStatus> {
  return new Promise(resolve => {
    // Asking BE what's the status until we get an answer
    const intervalId = setInterval(async () => {
      let result;
      try {
        result = await getPaymentStatus(id);
      } catch (err) {
        store.dispatch(actions.setPaymentStatus(PaymentStatus.failed));
        clearInterval(intervalId);
        resolve(ScoringStatus.Failure);
        return;
      }

      if (result?.paymentStatus === PaymentStatusPorting.orderAttached) {
        clearInterval(intervalId);

        const success = result.statusCategory === 'processing';

        Analytics.sendEvent(`Payment - Scoring - ${success ? "Successed" : "Failed"}`);

        resolve(
          success ? ScoringStatus.Success : ScoringStatus.Failure
        );
      }

      if (result?.paymentStatus === PaymentStatusPorting.scoringFailed) {
        clearInterval(intervalId);
        Analytics.sendEvent("Payment - Scoring - Failed");
        resolve(ScoringStatus.Failure);
      }
    }, 1000);

    // Timeouting after X seconds if there's no answer yet (Default 30 seconds)
    setTimeout(() => {
      clearInterval(intervalId);
      resolve(ScoringStatus.Timeout);
    }, +(process.env.REACT_APP_SCORING_TIMEOUT || 60) * 1000);
  });
}

export function* createTransactionSaga(): any {
  try {
    const orderId = yield call(LocalStorageManager.getItem, ORDER_ID_KEY);

    // generate cancelId
    const cancelId = yield call(uuid);
    const transaction = yield call(createTransaction, orderId, cancelId);

    yield call(LocalStorageManager.setItem, TRANSACTION_ID_KEY, transaction.transactionId);
    yield put(actions.setTransactionId(transaction.transactionId))

    Analytics.sendEvent('PaymentTransactionCreated');

    const scoring: ScoringStatus = yield call(tryToGetScoringResult, transaction.transactionId);

    if (scoring !== ScoringStatus.Success) {
      throw {
        message: "Scoring Failed"
      };
    }

    Analytics.sendEvent('PaymentScoringSuccess');

    if (transaction) {
      const { paymentStatus, confirmationNumber } = yield call(getTransactionStatus, transaction.transactionId);
      yield put(actions.setPaymentStatus(paymentStatus));
      yield put(actions.setConfirmationNumber(confirmationNumber));
    }

    yield call(LocalStorageManager.setItem, CANCEL_ID_KEY, cancelId);
    yield call(LocalStorageManager.setItem, TRANSACTION_ID_KEY, transaction.transactionId);

    yield put(actions.setPaymentStatus(transaction.paymentStatus));
  } catch (err) {
    if (err.message === 'Order is expired') {
      yield call(notify, 'error', 'order-expired');
      yield call(cleanOrderData);
      history.push(WELCOME);
    } else if (err.message === 'Invalid order') {
      yield call(notify, 'error', 'order-error');
      yield call(cleanOrderData);
      history.push(WELCOME);
    } else {
      Analytics.sendEvent('PaymentScoringFailed');
      yield put(actions.setPaymentStatus(PaymentStatus.failed));
      if (err.data && err.data.id) {
        yield call(LocalStorageManager.setItem, FAILED_TRANSACTION_ID_KEY, err.data.id);
      }
    }
  }
}
