import React, { useEffect } from 'react';
import Router from './routing/Router';
import { init } from './routing/utils';
import { connect, ConnectedProps } from 'react-redux';
import { actions, selectors } from './store/UI';
import { State } from './store/state';
import Spinner from './components/Spinner';
import { Route } from './routing/RouterTypes';
import { Background, Frame } from './root.styles';
import { GlobalStyles } from './global.styles';
import nikeBackground from './assets/tempAndTesting/nikeBackground.png';
import nikeLogo from './assets/tempAndTesting/nikeLogo.png';
import Logo from './components/Logo';
import CancelButton from './components/CancelButton';
import Footer from './components/Footer';

/** Routes */
import Landing from './containers/Landing';
import AddCard from './containers/AddCard';
import PaymentPlan from './containers/PaymentPlan';
import ContactUs from './containers/ContactUs';
import PaymentSuccess from './containers/PaymentSuccess';
import PaymentFailure from './containers/PaymentFailure';
import LandingError from './containers/Errors/LandingError';
import InsufficientFundsError from './containers/Errors/InsufficientFundsError';
import PaymentConfirmation from './containers/PaymentConfirmation';
import Onboarding from './containers/Onboarding';
import FAQ from "./containers/FAQ";
import RouterContainer from './components/RouterContainer';
import { ToastContainer } from 'react-toastify';
import Logger from './utils/logger';
import { Analytics } from './utils/analytics';
import { searchForParent } from './utils/dom';

const logger = new Logger('Root');

const mapStateToProps = (state: State) => ({
  spinner: selectors.showSpinner(state),
});

const mapDispatchToProps = {
  setLogo: actions.setLogo,
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

/**
 * This is going to be the Root component for the DFE flow.
 * Everything in this component and in this component's folder shouldn't use anything from the legacy flow code.
 * You can think of this project as a plain new React project which we have to code from 0.
 * In the future, Hopefully, We're going to get out all of the files from this folder into the src and remove the legacy code.
 */
const Root: React.FC<PropsFromRedux> = (props: PropsFromRedux) => {
  const { spinner, setLogo } = props;

  const routes: Route<any, any>[] = [
    new PaymentConfirmation(),
    new FAQ(),
    new InsufficientFundsError(),
    new ContactUs(),
    new Landing(),
    new LandingError(),
    new Onboarding(),
    new AddCard(),
    new PaymentPlan(),
    new PaymentSuccess(),
    new PaymentFailure(),
  ];

  useEffect(() => {
    logger.error("DFE Flow is accessed on production", true);
    // This function should be called ONLY On the root component even if we create new routes somewhere else
    init();

    // Initing Analytics
    Analytics.initialize();
    // Initing Analytics links events
    document.addEventListener("click", e => {
      const target = e.target as HTMLElement;

      if (searchForParent(target, "a")) {
        Analytics.sendEvent(`Link ${target.innerHTML} clicked`);
      }
    });
  }, []);

  //TODO Remove after testing
  useEffect(() => {
    //! Faking getting data from BE
    setLogo(nikeLogo);
  }, []);

  return <>
    {/* TODO Design toastify */}
    <ToastContainer />
    <GlobalStyles />
    {spinner && <Spinner />}
    <Background backgroundImage={nikeBackground}>
      <Frame>
        <CancelButton />
        <Logo />
        <RouterContainer>
          <Router routes={routes} />
        </RouterContainer>
        <Footer />
      </Frame>
    </Background>
  </>;
}

export default connector(Root);
