import React, { useEffect, useState } from 'react';
import InputForm from 'components/InputForm';
import styled from 'styled-components';
import Button from 'components/Button';
import SelectForm from 'components/SelectForm';
import { validateEmail } from 'utils/strings';
import useEventListener from 'apps/UXFlow/utils/hooks/useEventListener';
import { AppComponentProps } from 'types/apps';
import { ParentProps } from '../../../types';
import useSubmitHandler from 'apps/UXFlow/utils/hooks/useSubmitHandler';
import flow from 'apps/UXFlow/store/flow';
import Text from 'components/Text';
import Error from 'apps/UXFlow/components/Error';
import flowAPI from 'api/flowAPI';
import useAsyncEffect from 'utils/hooks/useAsyncEffect';

const FlexColumn = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const TitleContainer = styled.div`
  margin-bottom: 20px;
`;

type Province = {
  name: string;
  code: string;
};

type City = {
  name: string;
};

type Code = {
  code: string;
};

type Locations = {
  provinces: Array<string>;
  cities: Array<string>;
  zipcodes: Array<string>;
};

type FieldsErrorType = 'email' | 'province' | 'city' | 'zipcode' | 'address';

function mapOptions(values: Array<string>): Array<{ value: string; content: string }> {
  return values.map(value => ({
    value,
    content: value,
  }));
}

const parseData = (data: any): Locations => {
  const provinces: Array<string> = data.provinces.map((p: Province) => p.code);
  const cities: Array<string> = data.cities.map((c: City) => c.name);
  const zipcodes: Array<string> = data.zipcodes.map((c: Code) => c.code);

  return {
    provinces,
    cities,
    zipcodes,
  };
};

const DefaultEMailAddressForm: React.FC<AppComponentProps & ParentProps> = (
  props: AppComponentProps & ParentProps
) => {
  const { data, errors } = flow;
  const { onSubmit, loading } = useSubmitHandler(props);

  const [locations, setLocations] = useState<Locations>({
    provinces: [],
    cities: [],
    zipcodes: [],
  });

  const [email, setEmail] = useState<string>(data?.email || '');
  const [province, setProvince] = useState<string>(
    (data?.province && data?.province.toUpperCase()) || ''
  );
  const [city, setCity] = useState<string>((data?.city && data?.city.toUpperCase()) || '');
  const [zipcode, setZipCode] = useState<string>(data?.zipcode || '');
  const [address, setAddress] = useState<string>(data?.address || '');

  const [fieldsErrors, setFieldsErrors] = useState<FieldsErrorType[]>([]);

  useAsyncEffect(async () => {
    const autocompleteData = await flowAPI.postFlow({
      province,
      city,
      getAutocomplete: true,
    });

    setLocations(parseData(autocompleteData.data.data));
  }, []);

  useAsyncEffect(async () => {
    if (locations.provinces.includes(province)) {
      setLocations({
        ...locations,
        cities: [],
        zipcodes: [],
      });

      const autocompleteData = await flowAPI.postFlow({
        province,
        getAutocomplete: true,
      });

      setCity((data?.city && data?.city.toUpperCase()) || '');
      setLocations(parseData(autocompleteData.data.data));
    }
  }, [province]);

  useAsyncEffect(async () => {
    if (locations.cities.includes(city)) {
      setLocations({
        ...locations,
        zipcodes: [],
      });

      const autocompleteData = await flowAPI.postFlow({
        province,
        city,
        getAutocomplete: true,
      });

      setZipCode(data?.zipcode || '');
      setLocations(parseData(autocompleteData.data.data));
    }
  }, [city]);

  useEffect(() => {
    if (errors.email) {
      setFieldsErrors(['email']);
    }
  }, [errors]);

  const onSubmitButton = () => {
    const errors: FieldsErrorType[] = [];

    // Validating fields
    validateEmail(email) || errors.push('email');
    locations.provinces.includes(province) || errors.push('province');
    locations.cities.includes(city) || errors.push('city');
    locations.zipcodes.includes(zipcode) || errors.push('zipcode');
    address.length > 1 || errors.push('address');

    setFieldsErrors(errors);

    // Submitting if no errors
    errors.length === 0 &&
      onSubmit({
        email,
        province,
        city,
        zipcode,
        address,
      });
  };

  useEventListener('keypress', onSubmit);

  return (
    <>
      <TitleContainer>
        <FlexColumn>
          <Text type="subtitle" messageId="addressEmailTitle1" />
          <Text type="subtitle" messageId="addressEmailTitle2" />
          <Error />
        </FlexColumn>
      </TitleContainer>
      <FlexColumn>
        <InputForm
          placeholderMessageId="emailPlaceholder"
          value={email}
          changeHandler={setEmail}
          error={fieldsErrors.includes('email') ? 'emailError' : ''}
          id="onboarding_email"
          type={'email'}
        />
        <SelectForm
          placeholderMsgId="provincePlaceholder"
          value={province}
          onChange={setProvince}
          options={mapOptions(locations.provinces)}
          isDisabled={!locations.provinces.length}
          errorMsgId="provinceError"
          errorStatus={fieldsErrors.includes('province')}
          maxLength={2}
          type={'search'}
          //TODO Errors and validations
        />
        <SelectForm
          placeholderMsgId="cityPlaceholder"
          value={city}
          onChange={setCity}
          isDisabled={!locations.cities.length}
          options={mapOptions(locations.cities)}
          errorMsgId="cityError"
          errorStatus={fieldsErrors.includes('city')}
          type={'search'}
        />
        <InputForm
          placeholderMessageId="addressPlaceholder"
          value={address}
          changeHandler={setAddress}
          error={fieldsErrors.includes('address') ? 'addressError' : ''}
          id={'onboarding_address'}
          type={'search'}
        />
        <SelectForm
          options={mapOptions(locations.zipcodes)}
          placeholderMsgId="zipcodePlaceholder"
          value={zipcode}
          onChange={setZipCode}
          isDisabled={!locations.zipcodes.length}
          errorMsgId="zipcodeError"
          errorStatus={fieldsErrors.includes('zipcode')}
          type={'search'}
        />
        <Button isLoading={loading} messageId="conferma" onClick={onSubmitButton} />
      </FlexColumn>
    </>
  );
};

export default DefaultEMailAddressForm;
