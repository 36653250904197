import { Route } from 'legacy/DFE/routing/RouterTypes';
import PaymentPlanComponent from './index.component';

export default class PaymentPlan extends Route {
  constructor() {
    super('PaymentPlan', '/paymentPlan');
  }

  component = PaymentPlanComponent;
}
