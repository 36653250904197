import Text from "components/Text";
import React from "react";
import styled from "styled-components";
import { Installment } from "../../types";
import InstallmentLine from "../InstallmentLine";
import { MdKeyboardArrowUp as ArrowUpIcon, MdKeyboardArrowDown as ArrowDownIcon } from 'react-icons/md';
import CheckBox from "../CheckBox";

const ClickableFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  cursor: pointer;
  align-items: baseline;
`;

const BorderContainer = styled.div<{ flexDirection?: string; isExpanded?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: inherit;

  @media screen and (max-width: 480px) {
    width: 300px;
  }

  padding: 5px;
  border-bottom: 1px solid #ededed;
  position: relative;
  ${props => (props.isExpanded ? 'color: #fff' : 'color: #000')}
  ${props => (props.isExpanded ? 'background-color: #525254' : '')}
`;

const PaymentsContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: #f3f3f3;
  padding: 6px 6px 16px 6px;

  & > * {
    margin-left: 24px;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    width: 300px;

    & > * {
      margin-left: 0;
    }
  }
`;

const PaymentsTooltip = styled(Text)`
  max-width: 35%;

  @media (max-width: 480px) {
    max-width: 100%;
    margin-bottom: 12px;
  }
`;

const Payments = styled.div`
  flex-direction: column;
`;

const InfoContainer = styled(Text) <{ chosen?: boolean }>`
  margin-right: 12px;
  color: inherit;
  ${props => (props.chosen ? 'color: #fff' : '')}
`;

const PlusContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 12px;
  cursor: pointer;
`;

type PlanType = {
  installments: Installment[];
  onChoosingPlan: (e: React.MouseEvent<HTMLElement>) => void;
  onExpandPlan: () => void;
  isExpanded: boolean;
  chosen: boolean;
  downPaymentAmount: string;
};

const Plan: React.FC<PlanType> = (props: PlanType) => {
  const {
    installments,
    onChoosingPlan,
    onExpandPlan,
    isExpanded,
    chosen,
    downPaymentAmount,
  } = props;

  const installmentsLines: any[] = [];

  installmentsLines.push(
    <InstallmentLine key={-1} amount={downPaymentAmount} date={'Paga Ora'} />
  );

  for (let i = 0; i < installments.length; i++) {
    const installment = installments[i];
    installmentsLines.push(
      <InstallmentLine
        key={i}
        amount={installment.amount}
        date={installment.date}
      />
    );
  }

  return (
    <>
      <BorderContainer isExpanded={isExpanded}>
        <ClickableFlexRow onClick={onExpandPlan}>
          <div onClick={onChoosingPlan}>
            <CheckBox chosen={chosen} />
            <InfoContainer type="subtitle" className="bold" content={`${installments.length + 1}`} /*className="inline bold fs-23"*/ />
            <InfoContainer type="content" content="rate da" /*className="inline fs-16"*/ />
            <InfoContainer
              type="subtitle"
              className="bold"
              content={`${installments[0].amount}`}
            //className="inline bold fs-23"
            />
          </div>
        </ClickableFlexRow>
        <PlusContainer onClick={onExpandPlan}>
          {isExpanded ? (
            <ArrowUpIcon size={18} color="#fff" />
          ) : (
            <ArrowDownIcon size={18} color="#525254" />
          )}
        </PlusContainer>
      </BorderContainer>
      {isExpanded && (
        <PaymentsContainer>
          <PaymentsTooltip content="Il tuo piano di pagamento" type="content" className="light" /*className="inline fs-14 gray"*/ />
          <Payments>{installmentsLines}</Payments>
        </PaymentsContainer>
      )}
    </>
  );
};

export default Plan;
