import { createSlice, createAction, PayloadAction } from 'redux-starter-kit';
import { ValidatedUser } from 'legacy/types/ValidatedUser';
import { initialUserValidationData, UserValidationData } from './state';

/** validate by phone number slice */
const phoneValidationData = createSlice({
  name: 'phoneValidationData',
  initialState: initialUserValidationData,
  reducers: {
    requestLoginByPhoneNumber: (state, { payload }: PayloadAction<string>): UserValidationData => ({
      ...state,
      pending: true,
      error: false,
    }),
    requestLoginForUserWithToken: (state): UserValidationData => ({
      ...state,
      pending: true,
    }),
    errorLogin: (state): UserValidationData => ({
      ...state,
      pending: false,
      error: true,
    }),
    successLogin: (state): UserValidationData => ({
      ...state,
      pending: false,
    }),
    setUser: (state, { payload: user }: PayloadAction<ValidatedUser>): UserValidationData => ({
      ...state,
      user,
    }),
    setIsLoggedInUser: (
      state,
      { payload: isLoggedIn }: PayloadAction<boolean>
    ): UserValidationData => ({
      ...state,
      isLoggedIn,
    }),
  },
});

export const { reducer } = phoneValidationData;

/** sagas actions */
const getLandingPageData = createAction('getLandingPageData');
const getLoginPageData = createAction('getLoginPageData');

export const actions = {
  ...phoneValidationData.actions,
  getLandingPageData,
  getLoginPageData,
};
