import { fork } from 'redux-saga/effects';
import { closePopupSaga } from './closePopupSaga';
import { cookieCheckSaga } from './cookieCheckSaga';
import { getTextSaga } from './getTextSaga';

export function* UISagas() {
  yield fork(cookieCheckSaga);
  yield fork(getTextSaga);
  yield fork(closePopupSaga);
}
