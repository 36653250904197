export const AUTH_TOKEN_KEY = 'authToken';
export const TOKEN_KEY = 'token';
export const USER_KEY = 'user';
export const CONSUMER_KEY = 'consumer';
export const ORDER_ID_KEY = 'orderId';
export const ORDER_AMOUNT = 'orderAmount';
export const TRANSACTION_ID_KEY = 'transactionId';
export const FAILED_TRANSACTION_ID_KEY = 'failedTransactionId';
export const CANCEL_ID_KEY = 'cancelId';
export const LANGUAGE_KEY = 'language';
export const POL_OTP_DELAY = '__simulatedDelayOnValidation';
export const REMEMBER_ME_KEY = 'isRememberMe';
export const REFERRER_KEY = 'referrerLink';
export const PAYMENT_SESSION_ID = 'paymentSessionId';
export const CHANNEL = 'channel';
export const TEXT_KEY = 'text';
export const NOOSA_SESSION_ID = 'NoosaSessionId';
export const NOOSA_MOBILE_AS_CAMERA_PHONE = 'NoosaMACPhoneMode';
