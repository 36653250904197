import { pushPageToHTML5 } from 'legacy/DFE/routing/utils';
import { createSlice, PayloadAction, createAction } from 'redux-starter-kit';
import { State } from '../state';
import { initialState, RouterState } from './state';

const Router = createSlice({
  name: 'router',
  initialState,
  reducers: {
    setPage: (state, { payload: page }: PayloadAction<string>): RouterState => {
      if (!page.startsWith('/')) {
        page = '/' + page;
      }

      state.history.push(page);
      // Setting this page on the url & allowing browser back button
      pushPageToHTML5(page);
      return state;
    },
    goBack: (state): RouterState => {
      const history = state.history;
      const prevPage = history[history.length - 2];

      history.push(prevPage);
      // Setting this page on the url & allowing browser back button
      pushPageToHTML5(prevPage);
      return state;
    },
    setSubmittedRoute: (
      state,
      { payload: submittedRoute }: PayloadAction<string>
    ): RouterState => ({
      ...state,
      submittedRoute,
    }),
  },
});

export const { reducer } = Router;

export const actions = {
  ...Router.actions,
};

/** selectors */
const getRouterState = (state: State): RouterState => state.Router;
const history = (state: State): string[] => getRouterState(state).history;
const currentPage = (state: State): string => {
  const _history = history(state);
  return _history[_history.length - 1];
};
const submittedRoute = (state: State): string => getRouterState(state).submittedRoute;

export const selectors = {
  getRouterState,
  currentPage,
  submittedRoute,
};
