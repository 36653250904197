import amplitude from 'amplitude-js';
import flow from 'apps/UXFlow/store/flow';
import { events } from './AnalyticsEvents';


export class Analytics {
    static consumerId: string;
    static campaignData: any;

    static initialize(): void {
        const amplitudeApiKey = process.env.REACT_APP_AMPLITUDE_API_KEY || '';
        amplitude.getInstance().init(amplitudeApiKey);
    }

    static identifyUser(userId: string): void {
        if (userId) {
            amplitude.getInstance().identify(new amplitude.Identify().set('ConsumerID', userId));
            amplitude.setUserId(userId);
        }
    }

    static async sendEvent(name: string, params?: any): Promise<void> {
        const eventName = events[name] || name;
        const amplitudeInstance  = await amplitude.getInstance();
        const userId =  amplitudeInstance.options.userId;
        const merchantId = flow.merchantId;
        const extension = flow.branch;
        const paymentProgram = flow.paymentProgram;
        
        amplitude.getInstance().logEvent(eventName, { source: "NPG", userId, merchantId, extension, paymentProgram, ...this.campaignData, ...params });
    }
}
