import { selectors } from "legacy/DFE/store/auth";
import { AbstractAPIHandler } from ".";

export class OrderAPIHandler extends AbstractAPIHandler {
    constructor() {
        super("paymentRequest")
    }

    public initPaymentSession(id: string): Promise<any> {
        return this.get("initPaymentSession", { id });
    };
    
    public async initLandingDetails(id: string) {
        const authToken = selectors.authToken();

        const { order } = authToken ? await this.get("getPaymentDetails", { id }) : await this.get("getLandingDetails", { id });

        return order;
    }
}