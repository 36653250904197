import ui from "store/ui";
import styled, { css } from "styled-components";
import styledObserver from "utils/styledObserver";
import { filterValidCSSProperties } from "utils/ui";

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export const Sidebar = styledObserver(styled.div`
    height: 100vh;
    position: sticky;
    top: 0;
    left: 0;
    width: auto;
    
    @media (min-width: 1280px) {
        ${() => css(filterValidCSSProperties(ui.styles.sidebarImage.biggest))}
    }

    @media (max-width: 1280px) {
        ${() => css(filterValidCSSProperties(ui.styles.sidebarImage.big))}
    }
    
    @media (max-width: 980px) {
        ${() => css(filterValidCSSProperties(ui.styles.sidebarImage.medium))}
    }
    
    @media (max-width: 768px) {
        display: none;
        ${() => css(filterValidCSSProperties(ui.styles.sidebarImage.small))}
    }
    
    ${() => css(filterValidCSSProperties(ui.styles.sidebarImage))}
`);

export const StyledImg = styled.img`
    width: 100%;
    height: 100%;
`;