import { ScrollManager } from 'legacy/components/Contract/scroll_manager';
import React, { lazy, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { State } from 'legacy/store/state';
import { actions, selectors } from 'legacy/store/UI';
import { Analytics } from 'legacy/utils/Analytics';
import { Logger } from 'legacy/utils/Logger';
import NewTermsOfUse from './NewTermsOfUse';
import PleaseWait from './PleaseWait';
import { PopupBackdrop, PopupInner, CrossIcon } from './popup.styles';
import RetryFailed from './RetryFailed';

const OnboardingPopup = lazy(() => import('./OnboardingPopup'));
const CardsPurposeInfo = lazy(() => import('./CardsPurposeInfo'));
const CardsDetailsPopup = lazy(() => import('./CardDetailsPopup'));
const TermsAndConditionsPopup = lazy(() => import('./TermsAndConditionsPopup'));
const ContactUs = lazy(() => import('./ContactUs'));
const ContactUsSuccess = lazy(() => import('./ContactUsSuccess'));
const CancelConfirmation = lazy(() => import('./CancelConfirmation'));
const RotatePhone = lazy(() => import('./RotatePhone'));
const LandingMoreInfo = lazy(() => import('./LandingMoreInfo'));
const NoosaCompassPrivacy = lazy(() => import('./NoosaCompassPrivacy'));
const LogoutConfirmation = lazy(() => import('./LogoutConfirmation'));

interface PopupItem {
  name: string;
  component: React.ReactNode;
  preventClose?: boolean;
}

const popups: Array<PopupItem> = [
  { name: 'complete-onboarding', component: <OnboardingPopup /> },
  { name: 'cards-purpose-info', component: <CardsPurposeInfo /> },
  { name: 'cards-details', component: <CardsDetailsPopup /> },
  { name: 'terms-and-conditions', component: <TermsAndConditionsPopup /> },
  { name: 'contact-us', component: <ContactUs /> },
  { name: 'contact-us-success', component: <ContactUsSuccess /> },
  { name: 'cancel-confirmation', component: <CancelConfirmation /> },
  { name: 'rotate-phone', component: <RotatePhone /> },
  { name: 'new-terms-of-use', component: <NewTermsOfUse /> },
  { name: 'landing-more-info', component: <LandingMoreInfo /> },
  { name: 'noosa-compass-privacy', component: <NoosaCompassPrivacy /> },
  { name: 'logout-confirmation', component: <LogoutConfirmation /> },
  { name: 'pleasewait', component: <PleaseWait />, preventClose: true },
  { name: 'retryfailed', component: <RetryFailed />, preventClose: true },
];

const mapStateToProps = (state: State) => ({
  openedPopup: selectors.openedPopupSelector(state),
  page: selectors.currentPage(state),
});

const mapDispatchToProps = {
  closePopup: actions.closePopup,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Popup: React.SFC<PropsFromRedux & { children?: React.ReactNode; maxWidth?: string }> = (
  props: PropsFromRedux & { children?: React.ReactNode; maxWidth?: string }
) => {
  const { openedPopup, closePopup, children, page } = props;

  useEffect(() => {
    if (openedPopup) {
      Analytics.sendEvent(`Popup - ${openedPopup} - visited`, { page })
    }
  }, [openedPopup]);

  if (!openedPopup) return null;

  const currentPopup = openedPopup === 'CUSTOM_COMPONENT'
    ? { name: '', component: children, preventClose: false }
    : popups.find((popup: PopupItem) => popup.name === openedPopup);

  if (!currentPopup) {
    Logger.error(`any popup found by '${openedPopup}' name`);
    return null;
  }

  const clickToPopupContent = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  const clickToOverlay = () => {
    currentPopup.preventClose || closePopup();
  };

  const classes = currentPopup.name === 'terms-and-conditions'
    ? 'adaptive-height'
    : '';

  let maxW = props.maxWidth || undefined;
  if (openedPopup === 'terms-and-conditions') {
    maxW = '800px';
  }

  return (
    <PopupBackdrop onClick={clickToOverlay}>
      <PopupInner maxWidth={maxW} className={classes} onClick={clickToPopupContent}>
        {currentPopup.preventClose || <CrossIcon onClick={() => {
          closePopup();
        }}>&#10005;</CrossIcon>}
        {currentPopup.component}
      </PopupInner>
    </PopupBackdrop>
  );
};

export default connector(Popup);
