import React from 'react';
import { CheckboxContainer } from './checkbox.styles';
import Text from 'components/Text';
import { CheckboxProps } from '../types';

const Checkbox: React.FC<CheckboxProps> = (props: CheckboxProps) => {
  const { labelMsgId, checked, onChange, id } = props;

  return (
    <CheckboxContainer>
      <input type="checkbox" checked={checked} onChange={onChange} id={id} className={labelMsgId} />
      <label htmlFor={id}>
        <Text messageId={labelMsgId} />
      </label>
    </CheckboxContainer>
  );
};

export default Checkbox;
