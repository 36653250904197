export interface DossierApprovalStatus {
  pending: boolean;
  error: boolean;
  success: boolean;
  terms: any[];
  version: string,
}

export const initialDossierApprovalStatus = {
  pending: false,
  error: false,
  success: false,
  terms: ([] as any[]),
  version: "",
};
