export const isIframe = () => window.location !== window.parent.location;

export const redirect = (url: string): void => {
  sessionStorage.clear();

  if (isIframe()) {
    window.parent.postMessage({ url: url }, '*');
    // @todo: log event (amplitude ?)
  } else {
    window.location.href = url;
  }
};
