import React from 'react';
import styled from 'styled-components';
import FlexColumn from 'components/FlexColumn';
import Text from 'components/Text';
import Error from 'apps/UXFlow/components/Error';

const TextContainer = styled.div`
  text-align: center;
`;

const ECPCardPage: React.FC = (props) => {
  return (
    <>
      <TextContainer>
        <FlexColumn>
          <Text messageId="title" type="subtitle" />
          <Text messageId="subtitle" type="content" className="bold" />
        </FlexColumn>
      </TextContainer>
      <Error />
      {props.children}
    </>
  );
};

export default ECPCardPage;
