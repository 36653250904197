import React, { useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import ui from 'store/ui';
import { DESIGN } from 'types/design';
import DefaultInput from './Default';
import DEInput from './DE';
import { BasicInputProps } from './types';
import { InputsDataService } from 'services/InputsDataService';

const Input: React.FC<BasicInputProps> = (props: BasicInputProps) => {
  const { placeholderMessageId, changeHandler, value, ...rest } = props;

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    InputsDataService.save(e.target.id, e.target.value);

    changeHandler && changeHandler(e.target.value);
  };

  useEffect(() => {
    if (!value && rest.id) {
      changeHandler?.(InputsDataService.get(rest.id) || "");
    }
  }, []);

  const placeholder: string = placeholderMessageId && ui.texts[placeholderMessageId];

  const InputComponents = {
    [DESIGN.DE]: DEInput,
    [DESIGN.default]: DefaultInput,
  };

  const Input = InputComponents[ui.design];

  return <Input {...rest} value={value} onChange={onInputChange} placeholder={placeholder} />;
};

Input.defaultProps = {
  changeHandler: () => null,
  placeholderMessageId: '',
  type: 'text',
  errorMsgId: '',
  fullWidth: false,
  textTransform: '',
  disabled: false,
} as Partial<BasicInputProps>;

export default Input;
