import Text from 'components/Text';
import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import error from 'assets/error.svg';

export const Icon = styled('img')`
  height: 16px;
`;
const ErrorText = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  margin-left: 5px;
`;
const ErrorContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;

type ErrorType = {
  messageId?: string;
  content?: string;
};

const Error: React.FC<ErrorType> = (props: ErrorType) => {
  return (
    <ErrorContainer>
      <Icon src={error} />
      <ErrorText {...props} type="content" className="error" />
    </ErrorContainer>
  );
};

export default observer(Error);
