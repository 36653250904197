import React from 'react';

import Text from 'components/Text';
import { Error, InputFormStyled, InputStyled, Placeholder, PlaceholderText, TextAreaStyled } from './input.styles';
import { InputProps } from '../types';

const Input: React.FC<InputProps> = (props: InputProps) => {
  const {
    placeholder,
    onChange,
    value,
    type,
    error,
    fullWidth,
    disabled,
    id,
    maxLength,
    style,
    autoFocus,
    textPlaceholder,
    className,
    errorBorder
  } = props;

  const Component: React.ElementType = type == 'textarea' ? TextAreaStyled : InputStyled;

  return (
    <InputFormStyled fullWidth={fullWidth} className={className}>
      {placeholder && (
        <Placeholder>
          <PlaceholderText content={placeholder} type="label" errorBorder={errorBorder}/>
        </Placeholder>
      )}

      <Component
        type={type}
        disabled={disabled}
        onChange={onChange}
        value={value}
        style={style}
        id={id}
        maxLength={maxLength}
        autoFocus={autoFocus}
        placeholder={textPlaceholder}
        max={type === 'date' ? '9999-12-31' : ''}
        errorBorder={errorBorder}
      />

      {error && (
        <Error className="error">
          <Text messageId={error} type="content" className="red" />
        </Error>
      )}
    </InputFormStyled>
  );
};

export default Input;
