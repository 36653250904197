import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PaymentPlan from 'components/PaymentPlan';
import PaymentPriceBox from 'components/PaymentPriceBox';
import Expired from './Expired';
import ui from 'store/ui';
import flow from './../../../store/flow';
import Checkbox from 'components/Checkbox';
import useEventListener from 'apps/UXFlow/utils/hooks/useEventListener';
import { MappedUXFlowErrors } from 'types/data';
import PageContainer from 'apps/UXFlow/components/PageContainer/DE';
import { AppProps } from 'types/apps';
import { Analytics } from 'utils/Analytics';

const ContentContainer = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0px 30px 0 30px;
`;

const FormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 421px;
  margin-top: 10px;
`;

const AgreementsBox = styled('div')``;

const Agreements = styled('div')`
  position: absolute;
  bottom: 70px;
  max-width: 421px;

  margin-top: 40px;
  margin-bottom: 21px;
  padding: 0 10px;

  .notLastAgreement {
    margin-bottom: 25px;
  }

  .description_popup {
    text-decoration: underline;
  }
`;

interface Props {
  data: any;
  errors: MappedUXFlowErrors;
  loading: boolean;
  handleSubmit: (data: any) => void;
  setComponentOnDemand: (component: string, options?: any) => void;
}

type RequiredTerm = {
  id: string;
  content: string;
};

type Term = Record<string, { text: string }>;

type FilteredTerm = {
  id: string;
  text: string;
  content?: string;
};

const getFilteredTerms = (
  allTerms: Term = {},
  requiredTerms: RequiredTerm[] = []
): FilteredTerm[] => {
  const termsArray = Object.keys(allTerms) || [];

  return termsArray
    .filter(key => requiredTerms.find(({ id }) => id === key))
    .map(key => ({
      id: key,
      text: allTerms[key].text,
      content: requiredTerms.find(({ id }) => id === key)?.content,
    }));
};

const DEPayment: React.FC<Props & AppProps> = (props: Props & AppProps) => {
  const { data, errors, loading, handleSubmit } = props;
  const [agreements, setAgreements] = useState<{ [key: string]: boolean }>(
    Object.keys(ui.texts.terms).reduce((previousValue, currentValue) => {
      previousValue[currentValue] = false;
      return previousValue;
    }, {})
  );

  const termsArray = Object.keys(ui.texts.terms) || [];
  const filteredTermsArray = getFilteredTerms(ui.texts.terms, data.requiredTerms);

  const onSubmit = (): void => {
    Analytics.sendEvent('PaymentConfirmationPageClickedConfirm');
    handleSubmit({ terms: agreements });
  };

  useEventListener('keypress', onSubmit);

  const openLegalDocument = (termId: string | null, messageId?: string | null) => {
    if (termId) {
      const content = filteredTermsArray.find(({ id }) => id === termId)?.content;

      if (content) {
        props.setComponentOnDemand('LegalDocuments', {
          data: { content, messageId },
        });
      }
    }
  };

  const clickEventHandler = (e: any) => {
    const target = e.target as HTMLElement;
    const t = target.closest('div.agreement-item');
    const id = t?.getAttribute('id') || '';

    if (id) {
      setAgreements({ ...agreements, [id]: !agreements[id] }); // NOT switch checkbox if user clicked on bold text
      openLegalDocument(id, target.textContent);
    }
  };

  useEffect(() => {
    Analytics.sendEvent('PaymentConfirmPageVisited');

    const elements = document.getElementsByClassName('description_popup');

    if (!elements || !elements.length) {
      return;
    }

    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener('click', clickEventHandler);
    }

    return () => {
      for (let i = 0; i < elements.length; i++) {
        elements[i].removeEventListener('click', clickEventHandler);
      }
    };
  }, []);

  const isDisabled =
    data.requiredTerms &&
    !data.requiredTerms.every(({ id }: { id: string; link: string }): boolean => agreements[id]);

  const errorProperty: string | undefined = (termsArray as (string | undefined)[]).reduce(
    (acc: undefined | string, agreementId: string | undefined): undefined | string => {
      if (acc) return acc;

      if (!!agreementId && !agreements[agreementId]) {
        return `term_${agreementId}`;
      }

      return undefined;
    },
    undefined
  );

  return data.isExpired || errors['ProcessExpiredError'] || errors['PaymentRequestExpired'] ? (
    <Expired
      url={data.failureURL}
      loading={loading}
      goBack={props.goBack}
      backBtnEnabled={props.backBtnEnabled}
      onClose={props.onClose}
    />
  ) : (
    <PageContainer
      titleMessageId="DEPaymentStepTitle"
      buttonMessageId="DEPaymentButtonTitle"
      buttonOnClick={onSubmit}
      buttonIsLoading={loading}
      errorProperty={errorProperty}
      goBack={props.goBack}
      backBtnEnabled={props.backBtnEnabled}
      onClose={props.onClose}
      buttonDisabled={isDisabled}
    >
      <PaymentPriceBox
        totalAmount="totalAmount"
        paymentDetails="paymentDetails"
        preMonthAmount="preMonthAmount"
      />

      <FormContainer>
        <ContentContainer>
          {data.plan && <PaymentPlan payments={[...data.plan]} />}
        </ContentContainer>

        <Agreements>
          {filteredTermsArray &&
            filteredTermsArray.map((term: FilteredTerm, i: number) => {
              return (
                <AgreementsBox
                  key={i}
                  id={term.id}
                  className={`agreement-item ${
                    i < filteredTermsArray.length - 1 ? 'notLastAgreement' : ''
                  }`}
                >
                  <Checkbox
                    isError={!!flow?.errors[`term_${term.id}`]}
                    checked={agreements[term.id]}
                    onChange={(): void => {
                      setAgreements({ ...agreements, [term.id]: !agreements[term.id] });
                      Analytics.sendEvent('PaymentConfirmationPageClickedOnCheckbox');
                    }}
                    content={term.text}
                    format="html"
                    id={i.toString()}
                  />
                </AgreementsBox>
              );
            })}
        </Agreements>
      </FormContainer>
    </PageContainer>
  );
};

export default DEPayment;
