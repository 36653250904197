import PaddingContainer from "legacy/DFE/components/PaddingContainer";
import Text from "legacy/DFE/components/Text";
import React from "react";
import styled from "styled-components";
import { connect, ConnectedProps } from "react-redux";
import { State } from "legacy/DFE/store/state";
import { selectors } from "legacy/DFE/store/order";
import { formatPrice } from "legacy/DFE/utils/ui";
import { RouteComponentType } from "legacy/DFE/routing/RouterTypes";
import { actions as AuthActions } from "legacy/DFE/store/auth";
import { actions as ConsumerActions } from "legacy/DFE/store/consumer";
import { ConsumersAPI } from "legacy/DFE/api/handlers";

const PaymantPlanContainer = styled("div")`
    height: 100px;
    display: flex;
    align-items: center;
`;

const mapStateToProps = (state: State) => ({
    order: selectors.order(state),
});

const mapDispatchProps = {
    setMobile: AuthActions.setMobile,
    setConsumer: ConsumerActions.setConsumer,
};

const connector = connect(mapStateToProps, mapDispatchProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type LoggedInComponentType = PropsFromRedux & RouteComponentType;

const LoggedInComponent: React.FC<LoggedInComponentType> = ({ order, setMobile, setConsumer, done, useSubmit }: LoggedInComponentType) => {
    useSubmit(() => {
        ConsumersAPI.getInformation().then(consumer => {
            setConsumer(consumer);
            // TODO We need to remove the mobile from the Auth store
            setMobile(consumer.mobile);
            done();
        });
    });

    return <>
        <Text fontSize="24px" className="bold"> Summary of your purchase </Text>
        <PaddingContainer padding="15px" display="inline-block">
            <Text fontSize="36px" className="inline thin"> Total: </Text>
            <Text fontSize="36px" className="inline bold"> {formatPrice(order?.totalAmount!)} </Text>
        </PaddingContainer>
        <Text fontSize="18px" className="thin"> In {order?.installmentsNumber} simple installments of {formatPrice(order?.installmentAmount!)} per month </Text>
        <PaymantPlanContainer>
            <Text className="thin"> + View your payment plan </Text>
        </PaymantPlanContainer>
    </>;
}

export default connector(LoggedInComponent);
