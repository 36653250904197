import { take, put, call } from 'redux-saga/effects';
import { actions as UIActions } from 'legacy/store/UI';
import { getConsumerInfo } from 'legacy/api';
import { history } from 'legacy/routing/history';
import { FLOW } from 'legacy/constants/pathnames';
import { notify } from 'legacy/helpers/notify';
import { actions } from '../index';
import { Analytics } from 'legacy/utils/Analytics';

/** handle request consumer info event */
export function* consumerInfoSaga() {
  while (true) {
    yield take(actions.requestConsumerInfo);
    try {
      yield put(UIActions.setIsLoading(true));

      const consumer = yield call(getConsumerInfo);
      yield put(actions.setConsumerInfo(consumer));

      Analytics.identifyUser(consumer.id);
    } catch (err) {
      if (err.statusCode !== 401) {
        notify('error', 'err-while-fetching-consumer');
        history.push(FLOW);
      }
    } finally {
      yield put(UIActions.setIsLoading(false));
    }
  }
}
