import { take, call } from 'redux-saga/effects';
import { PayloadAction } from 'redux-starter-kit';
import { ORDER_ID_KEY } from 'legacy/constants/storageKeys';
import { initPaymentSession } from 'legacy/api';
import { LocalStorageManager } from 'legacy/utils/LocalStorageManager';
import { actions as orderActions } from '../index';
import { Logger } from 'legacy/utils/Logger';

/** handle get order details event */
export function* initPaymentSessionSaga(): any {
  while (true) {
    const { payload: orderIdPathParam }: PayloadAction<string | undefined> = yield take(
      orderActions.initPaymentSession
    );

    try {
      const existedOrderId = yield call(LocalStorageManager.getItem, ORDER_ID_KEY);
      const id = orderIdPathParam || existedOrderId || null;

      yield call(initPaymentSession, id);
    } catch (err) {
      Logger.error(err ? err.message : "Unknown error during initPaymentSession");
    }
  }
}
