import { createSlice, PayloadAction, createAction } from 'redux-starter-kit';
import { State } from 'legacy/store/state';
import { initialContactUsState, ContactUsState } from './state';

/** consumer slice */
export const contactUsSlice = createSlice({
  name: 'contact us',
  initialState: initialContactUsState,
  reducers: {
    tryToSendEmail: (state, action: PayloadAction<any>): ContactUsState => ({
      ...state,
      pending: true,
      error: false,
    }),
    sendEmailSucceed: (state): ContactUsState => ({
      ...state,
      pending: false,
    }),
    sendEmailFailed: (state): ContactUsState => ({
      ...state,
      pending: false,
      error: true,
    }),
    changeFormType: (state, { payload }: PayloadAction<boolean>): ContactUsState => ({
      ...state,
      isAuthorizedForm: payload,
    }),
  },
});

export const { reducer } = contactUsSlice;

const loadContactUsPage = createAction('loadContactUsPage');

export const actions = {
  ...contactUsSlice.actions,
  loadContactUsPage,
};

/** selectors */
const contactUsStatusSelector = (state: State) => state.contactUs;

export const selectors = {
  contactUsStatusSelector,
};
