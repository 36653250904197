import { Sides } from 'legacy/containers/Onboarding/components/OCR/types';
import heic2any from 'heic2any';
import { store } from 'legacy/store/store';
import { cities } from './fiscalcode/cities';
import { countries } from './fiscalcode/countries';
import * as pdfjs from 'pdfjs-dist';
import { PDFPageProxy } from 'pdfjs-dist/types/src/display/api';

const places = {
  ...cities,
  ...countries,
};

export const SUPPORTED_IMAGE_FORMATS = '.pdf,.jpeg,.jpg,.png,.tiff,.heic,.heif';
export const SUPPORTED_IMAGE_MIME_TYPES =
  'image/jpeg, image/png, image/tiff, image/heic, image/heif'; //"application/pdf, image/jpeg, image/png, image/tiff, image/heic, image/heif"
export const cardRatioWidthToHeight = 1.585772508336421;

// Used to draw canvas "slowly" using several rounds.
// It's also taking also specific position (using the white rectangle on the screen) to crop the image.
export function drawCanvas(canvas: any, view: any, rectangle: any, rounds: number) {
  const rectanglePos = rectangle.getBoundingClientRect();
  let lastWidth = 0;

  for (let widthRound = 1; widthRound <= rounds; widthRound++) {
    let lastHeight = 0;

    for (let heightRound = 1; heightRound <= rounds; heightRound++) {
      canvas
        .getContext('2d')
        .drawImage(
          view,
          rectanglePos?.left + lastWidth,
          rectanglePos?.top + lastHeight,
          Math.round((rectanglePos?.width / rounds) * widthRound),
          Math.round((rectanglePos?.height / rounds) * heightRound),
          lastWidth,
          lastHeight,
          Math.round((rectanglePos?.width / rounds) * widthRound),
          Math.round((rectanglePos?.height / rounds) * heightRound)
        );

      lastHeight = Math.round((rectanglePos?.height / rounds) * heightRound);
    }

    lastWidth = Math.round((rectanglePos?.width / rounds) * widthRound);
  }
}

// BE only supports uploading Blob, so it has to become file from base64
export async function convertBase64ToFile(base64: string): Promise<File> {
  const fileImageBuffer = await (await fetch(base64)).arrayBuffer();
  return new File([fileImageBuffer], 'temp.jpg', { type: 'mime' });
}

// Used to save the stream and then re-use it whenever needed.
// Otherwise trying to get the stream again will cause an error
export class CameraStream {
  static stream: MediaStream;
}

// Copied from BE
function fiscalCodeName(name: string, isFirstName: boolean): string {
  const consonants = 'BCDFGHJKLMNPQRSTVWXYZ'.split('');
  const vowels = 'AEIOU'.split('');
  const letters = name.split('');

  // Fixed code for first names with more than 3 consonants
  let tmpS = letters.filter(l => consonants.includes(l));

  if (isFirstName && tmpS.length > 3) {
    delete tmpS[1];
    tmpS = tmpS.filter(l => l !== undefined);
  }

  let s = tmpS.slice(0, 3).join('');

  // If there were not enough consonats, adding vowels.
  if (s.length < 3) {
    s += letters
      .filter(l => vowels.includes(l))
      .slice(0, 3 - s.length)
      .join('');
  }

  // If there were not enough vowels, adding Xs.
  if (s.length < 3) {
    s = s.padEnd(3, 'X');
  }

  return s;
}

// Copied from BE
export function fiscalCodeOwnership(
  fiscalCode: string,
  firstName: string,
  lastName: string,
  birthDate?: Date,
  placeOfBirth?: string
): boolean {
  if (!(firstName && lastName)) {
    return false;
  }

  let result = true;

  fiscalCode = fiscalCode.toLocaleUpperCase();
  firstName = firstName.toLocaleUpperCase();
  lastName = lastName.toLocaleUpperCase();

  if (placeOfBirth) {
    placeOfBirth = placeOfBirth.toLocaleUpperCase();

    const extractedPlaceOfBirth = extractPlaceOfBirth(fiscalCode).name;

    result = result && !!extractedPlaceOfBirth && placeOfBirth === extractedPlaceOfBirth;
  }

  const months = 'ABCDEHLMPRST'.split('');

  result = result && fiscalCodeName(firstName, true) === fiscalCode.substr(3, 3);
  result = result && fiscalCodeName(lastName, false) === fiscalCode.substr(0, 3);

  if (birthDate) {
    try {
      // This will break after the year 9999.
      const year = birthDate
        .getFullYear()
        .toString()
        .substr(2, 2);
      const month = months[birthDate.getMonth()];

      result = result && `${year}${month}` === fiscalCode.substr(6, 3);
    } catch (err) {
      return false;
    }
  }

  return result;
}

// Reading file uploaded from OCR and returning its data
// If it's heic/heif file, converting it
export function readFileImage(file: File): Promise<string> {
  return new Promise(async resolve => {
    const reader = new FileReader();

    reader.onload = e => {
      const imageData = e.target?.result as string;
      resolve(imageData as string);
    };

    if (file.name.toUpperCase().endsWith('.HEIC') || file.name.toUpperCase().endsWith('.HEIF')) {
      const blob = await (await fetch(URL.createObjectURL(file))).blob();
      const res = await heic2any({
        blob,
      });

      reader.readAsDataURL(res as any);
    } else {
      reader.readAsDataURL(file);
    }
  });
}

// We can't upload both images at the same time because sagas is shit, so we need to wait till the first one finishes before starting the next one
export function waitForUpload(side: Sides): Promise<void> {
  const sideToCheck = side === 'front' ? 'back' : 'front';

  return new Promise(resolve => {
    const intervalId = setInterval(() => {
      // We need the updated state because closures stuff
      const updatedState = store.getState();

      if (!updatedState.UI.ocr.cardImageLoading[sideToCheck]) {
        clearInterval(intervalId);
        resolve();
      }
    }, 200);
  });
}

export function getImageDimensions(imageData: string): Promise<{ width: number; height: number }> {
  return new Promise(resolve => {
    const image = new Image();

    image.onload = () => {
      resolve({
        width: image.naturalWidth,
        height: image.naturalHeight,
      });
    };

    image.src = imageData;
  });
}

// We need to rezize image if they're too big (Requirements comes from BE)
export function resizeImageWithCanvas(
  imageData: string,
  canvas: HTMLCanvasElement,
  width: number,
  quality: number
): Promise<File> {
  return new Promise(resolve => {
    const image = new Image();

    // We need to wait for the image to load with CB because.... JS
    image.onload = () => {
      const imageDimensions = {
        width: image.naturalWidth,
        height: image.naturalHeight,
      };

      // Used to calculate the needed height of the image easily because canvases doesn't do it automatically
      const heightToWidth = imageDimensions.height / imageDimensions.width;

      canvas.width = width;
      canvas.height = width * heightToWidth;

      // Drawing the image on the canvas
      canvas.getContext('2d')?.drawImage(image, 0, 0, width, width * heightToWidth);

      // Converting it to file
      canvas.toBlob(
        res => {
          resolve(new File([res!], 'temp.jpg'));
        },
        'image/jpeg',
        quality / 100
      );
    };

    image.src = imageData;
  });
}

export function rotateImageWithCanvas(imageData: string): Promise<string> {
  const canvas = document.getElementById('worksCanvas') as HTMLCanvasElement;
  return new Promise(resolve => {
    const image = new Image();
    image.onload = () => {
      const imageDimensions = {
        width: image.naturalWidth,
        height: image.naturalHeight,
      };
      canvas.width = imageDimensions.height;
      canvas.height = imageDimensions.width;
      const canvasCtx = canvas.getContext('2d');
      canvasCtx?.rotate((90 * Math.PI) / 180);
      canvasCtx?.translate(0, -canvas.width);
      canvasCtx?.drawImage(image, 0, 0);

      resolve(canvas.toDataURL('image/jpeg', 100));
    };
    image.src = imageData;
  });
}

export type Place = {
  name: string;
  code: string;
};

export function extractPlaceOfBirth(fiscalCode: string): Place {
  const placeCode = fiscalCode.slice(11, 15).toLocaleUpperCase();

  return {
    code: placeCode,
    name: places[placeCode] ? places[placeCode].toLocaleUpperCase() : '',
  };
}

function formatDay(day: string | number): string {
  day = +day;
  day = day > 40 ? day - 40 : day;

  return day < 10 ? `0${day}` : `${day}`;
}

export function extractDateOfBirth(fiscalCode: string): Date | undefined {
  try {
    const months = 'ABCDEHLMPRST'.split('');
    const monthChar = fiscalCode[8];
    const month = months.findIndex(m => m == monthChar) + 1;

    const yearNumber = fiscalCode.substr(6, 2);
    const todaysYear = +`${new Date().getFullYear()}`.substr(2, 2);
    const year = +yearNumber > todaysYear ? `19${yearNumber}` : `20${yearNumber}`;

    const day = fiscalCode.substr(9, 2);

    return new Date(`${month}/${formatDay(day)}/${year}`);
  } catch (error) {
    return;
  }
}

// Copied from BE
export function formatDate(date: Date, format?: 'MM-DD-YYYY' | 'YYYY-MM-DD'): string {
  if (format === 'MM-DD-YYYY') {
    return `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
  }

  if (format === 'YYYY-MM-DD') {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  return `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
}

async function getImagesFromPage(page: PDFPageProxy) {
  const objs: string[] = [];

  const ops = await page.getOperatorList();

  // We're trying to extract the literal images from the pdf
  for (var i = 0; i < ops.fnArray.length; i++) {
    if (ops.fnArray[i] == pdfjs.OPS.paintJpegXObject) {
      objs.push(ops.argsArray[i][0].getAttribute('src'));
    }
  }

  // If it's not working, We're converting the pdf itself to images (every page is a different image)
  if (objs.length === 0) {
    const viewport = page.getViewport({
      scale: 1,
    });

    const canvas = document.getElementById('worksCanvas') as HTMLCanvasElement;
    const context = canvas.getContext('2d') as any;

    canvas.height = viewport.height;
    canvas.width = viewport.width;

    await page.render({
      canvasContext: context,
      viewport: viewport,
    }).promise;

    return [canvas.toDataURL('image/jpeg')];
  }

  return objs;
}

function extractImagesFromPDF(pdf: File): Promise<string[]> {
  // Documentation tells us to do it for some reason
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  return new Promise(resolve => {
    const fileReader = new FileReader();

    fileReader.onload = async e => {
      const doc = await pdfjs.getDocument(e.target?.result as any).promise;

      let images: string[] = [];

      for (let i = 1; i <= doc.numPages; i++) {
        images = [...images, ...(await getImagesFromPage(await doc.getPage(i)))];
      }

      // We can get null for some reason
      resolve(images.filter(obj => obj !== null) as string[]);
    };

    fileReader.readAsDataURL(pdf);
  });
}

export async function parseFileToImage(
  file: File
): Promise<{
  fileType: string;
  file: File;
}> {
  let fileType = file.name
    .split('.')
    .pop()
    ?.toLowerCase();

  if (fileType === 'pdf') {
    const images = await extractImagesFromPDF(file);

    file = await convertBase64ToFile(images[0]);
    fileType = 'jpg';
  }

  return {
    fileType: fileType || '',
    file,
  };
}

export async function tryToCompressImage(
  file: File,
  data: any
): Promise<{ file: File; imageData: string }> {
  // Reading imageData
  let imageData = await readFileImage(file);

  const imageDimensions = await getImageDimensions(imageData);
  const isDimensionExceedsLimit =
    imageDimensions.height > data?.settings?.maxImageDimension &&
    imageDimensions.width > data?.settings?.maxImageDimension;

  const compressingEnabled = true && !(document as any).DISABLE_COMPRESSING_OCR;
  // Decreasing image size if needed
  if (
    compressingEnabled &&
    (file.size > (data?.settings?.maxImageSizeBytes || 0) || isDimensionExceedsLimit)
  ) {
    const canvas = document.getElementById('worksCanvas') as HTMLCanvasElement;

    file = await resizeImageWithCanvas(
      imageData,
      canvas,
      data?.settings?.maxImageDimension || 1080,
      data?.settings?.maxImageQuality || 100
    );
    imageData = await readFileImage(file);
  }

  return { file, imageData };
}

const ANALYTICS_FILE_RANGES = [
  {
    kb: 1024,
    value: '1mb+',
  },
  {
    kb: 501,
    value: '501kb-1mb',
  },
  {
    kb: 0,
    value: '0-500kb',
  },
];

export function getAnalyticsSizeRange(file: File): string {
  const range = ANALYTICS_FILE_RANGES.find(range => file.size / 1024 > range.kb);

  return range?.value || ANALYTICS_FILE_RANGES[ANALYTICS_FILE_RANGES.length - 1].value;
}
