import axios from 'axios';
import { getApiUrl } from 'legacy/helpers/getApiUrl';
import { Payment } from 'legacy/types/PaymentProcessing';

const PAYMENT_URL = 'npg/v1/payments/';

export const validateOTP = (otp: number, dossierConsents: any, paymentId: string) => {
  return axios.post(getApiUrl(`${PAYMENT_URL}otp/validate`), {
    otp,
    terms: dossierConsents,
    paymentId,
  });
};

export const getPayment = (id: string): Promise<Payment> => {
  return axios.get(getApiUrl(`${PAYMENT_URL}state`, { id }));
};
