import React from 'react';
import styled from 'styled-components';

export default styled.div<{ chosen: boolean }>`
  position: relative;
  top: 4px;
  margin-right: 12px;
  content: '';
  display: inline-block;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #525254;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  background-color: #fff;
  color: inherit;

  ${props =>
    props.chosen
      ? `
        &:after {
          position: absolute;
          top: 2px;
          left: 2px;
          content: '';
          width: 18px;
          height: 18px;
          background-color: red;
        }`
      : ''}
`;
