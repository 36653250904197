import { take, put, call } from 'redux-saga/effects';
import { getConsumerInfo } from 'legacy/api';
import { actions } from 'legacy/store/login';
import { userValidationSaga } from './userValidationSaga';
import { AuthService } from 'legacy/utils/AuthService';

/** handle user with token validation */
export function* validateUserWithTokenSaga(): any {
  while (true) {
    yield take(actions.requestLoginForUserWithToken);
    try {
      const consumer = yield call(getConsumerInfo);
      yield call(userValidationSaga, consumer.mobile);
    } catch (err) {
      yield put(actions.errorLogin());
      yield call(AuthService.removeToken);
      yield put(actions.setIsLoggedInUser(false));
    }
  }
}
