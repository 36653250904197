import styled from 'styled-components';
import Text from 'components/Text';

export const InputFormStyled = styled('div')<{ fullWidth?: boolean }>`
  position: relative;
  max-width: ${props => (props.fullWidth ? '100%' : '340px')}
  width: 100%;
  height: 50px;
  margin-bottom: 14px;
`;

export const Placeholder = styled('div')`
  position: absolute;
  left: 12px;
  top: 3px;
`;

export const PlaceholderText = styled(Text) <{
  errorBorder?: boolean;
}>`
  color: #A5A5A5;
  padding: 0 12px 0 12px;
  ${props => props.errorBorder && 'color: #ff0000 !important;'}
`;

export const TextAreaStyled = styled('textarea')<{ value?: any, errorBorder?: boolean  }>`
  width: 100%;
  height: 100%;
  padding: 18px 24px 0 24px;
  box-sizing: border-box;
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;

  ${props => props.errorBorder ? 'border: solid 1px #ff0000;' : 'border: 1px solid #A5A5A5;'}

  :focus {
    ${props => props.errorBorder ? 'border: solid 1px #ff0000;' : 'border: 1px solid #000000;'}
    outline: none;
  }
`;

export const InputStyled = styled('input')<{ value?: any, errorBorder?: boolean }>`
  width: 100%;
  height: 100%;
  padding: 15px 24px 0 24px;
  box-sizing: border-box;
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  background-color: white;
  border-radius: 0;

  ${props => props.errorBorder ? 'border: solid 1px #ff0000;' : 'border: 1px solid #A5A5A5;'}

  :focus {
    ${props => props.errorBorder ? 'border: solid 1px #ff0000;' : 'border: 1px solid #000000;'}
    outline: none;
  }

  &[type='date'] {
    -webkit-appearance: none;
    -webkit-text-fill-color: ${props => (props.value ? 'black' : 'rgb(147, 147, 147)')};
  }

  &[type='date']:focus {
    -webkit-text-fill-color: black;
  }

  &[type='date']::-webkit-date-and-time-value {
    text-align: left;
  }

  &[type='date']::-webkit-inner-spin-button,
  &[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  /* Chrome, Safari, Edge, Opera */
  &[type='number']::-webkit-outer-spin-button,
  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const Error = styled('div')`
  white-space: normal;
  word-break: break-word;
  width: 100%;
  text-align: center;
  bottom: 20px;
`;
