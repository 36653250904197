export interface OtpApprovalStatus {
  pending: boolean;
  error: string;
}

export const initialOtpApprovalStatus: OtpApprovalStatus = {
  pending: false,
  error: "",
};

export interface ResendOtpStatus {
  pending: boolean;
  error: boolean;
}

export const initialResendOtpStatus: ResendOtpStatus = {
  pending: false,
  error: false,
};
