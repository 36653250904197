import { Route } from 'legacy/DFE/routing/RouterTypes';
import ContactUsComponent from './components/contactUs';

export default class ContactUs extends Route {
  constructor() {
    super('ContactUs', '/contact-us');
  }

  component = ContactUsComponent;
}
