import { take, call, select, put } from 'redux-saga/effects';
import { sendRecoverOtp } from 'legacy/api';
import { selectors as loginSelectors } from 'legacy/store/login';

import { notify } from 'legacy/helpers/notify';
import { actions } from '../index';

export function* resendOtpSaga() {
  while (true) {
    yield take(actions.requestToResendRecoverPinOtp);
    try {
      const { mobile } = yield select(loginSelectors.userSelector);
      yield call(sendRecoverOtp, mobile);

      notify('success', 'resent-sms-success');
    } catch (err) {
      notify('error', 'error-while-sending-otp');
    } finally {
      yield put(actions.finishResendRecoverPinOtp());
    }
  }
}
