import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Text from 'components/Text';
import PageContainer from 'apps/ChangeCard/components/PageContainer/DE';
import queryString from 'query-string';
import { AxiosError } from 'axios';
import cardLoader from 'assets/card-loader.gif';
import { AppComponentProps } from 'types/apps';
import useSubmitHandler from 'apps/ChangeCard/utils/hooks/useSubmitHandler';
import flowAPI from 'api/flowAPI';
import flow from 'apps/ChangeCard/store/flow';
import Error from 'components/Error';
import ui from 'store/ui';
import { ParentProps } from '../types';

const DescriptionBox = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  margin: 23px 40px 40px 40px;
  text-align: center;
`;

const Spacer = styled.div`
  height: 1em;
`;


const IFrameWrapper = styled('div')`
  text-align: center;
  height: 370px;
  min-height: 370px;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  iframe {
    height: 370px;
    min-height: 370px;
    min-width: 400px;
    width: 400px;
    padding: 0px 25px;
  }
`;

const LoaderWrapper = styled('div')``;

type PostedMessage = {
  data: string;
};

const EcpSuccess = '01';

const delay = (ms: number): Promise<void> =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, ms);
  });

const runPolling = async (): Promise<boolean> => {
  try {
    for (let i = 0; i < 15; i++) {
      const result = await flowAPI.getFlow({
        isPolling: true,
      });

      if (result.data.next !== 'DE_ECP_ChangeCard') {
        return true;
      }

      await delay(1000);
    }

    return false;
  } catch (err) {
    if (err instanceof Error) {
      throw err;
    }

    return false;
  }
};

const isECPCardMessage = (cardType?: string | string[] | null): boolean => {
  if (Array.isArray(cardType)) {
    return cardType.includes('ECP');
  }

  return cardType === 'ECP';
};

const isECPSuccess = (ecpResponse?: string | string[] | null): boolean => {
  if (Array.isArray(ecpResponse)) {
    return ecpResponse.includes(EcpSuccess);
  }

  return ecpResponse === EcpSuccess;
};

const Card: React.FC<AppComponentProps & ParentProps> = (
  props: AppComponentProps & ParentProps
) => {
  const { onGetFlow } = useSubmitHandler(props);
  const { data } = flow;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [URL, setURL] = useState<string>(data.URL);

  const [errorMessage, setErrorMessage] = useState<string>();
  const [showError, setShowError] = useState<boolean>(false);

  const receiveMessage = async (message: PostedMessage): Promise<void> => {
    const queryParams = queryString.parse(message.data);

    if (isECPCardMessage(queryParams.cardType)) {
      setShowError(false);
      setErrorMessage(undefined);

      const ecpResponse = queryParams.result;

      if (!ecpResponse || !isECPSuccess(ecpResponse)) {
        setShowError(true);
        setErrorMessage(ui.texts.CardCompletionFailed);

        await onGetFlow();

        setURL(flow.data.URL);
        setIsLoading(true);

        return;
      }

      try {
        const result = await runPolling();

        if (!result) {
          setShowError(true);
        }
      } catch (err) {
        if (err instanceof Error) {
          const pageExceptionError = ((err as AxiosError).response?.data?.errors || []).filter(
            (e: { code: string }) => e.code === 'ErrorPageException'
          );

          // don't show error on error page
          if (!pageExceptionError.length) {
            setShowError(true);
            setErrorMessage(ui.texts.CardCompletionFailed);
          }
        }
      }

      await onGetFlow();

      setURL(flow.data.URL);
      setIsLoading(true);
    }

    return;
  };

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);

    return (): void => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, []);

  const onLoadHandler = (): void => {
    setIsLoading(false);
  };

  const error = errorMessage || data.message || ui.texts.CardCompletionFailed;

  return (
    <PageContainer titleMessageId="title">
      <DescriptionBox>
        <Text messageId="subtitle" />
        <Spacer />
        <Text messageId="ECPCardProviderText" />
      </DescriptionBox>

      <IFrameWrapper>
        {isLoading && (
          <LoaderWrapper>
            <img src={cardLoader} alt="loader" width="30px" height="30px" />
          </LoaderWrapper>
        )}
        <iframe
          src={URL}
          id="cardDetailsIframe"
          onLoad={onLoadHandler}
          title="card-details"
          style={{ borderStyle: 'none', display: isLoading ? 'none' : 'block', minWidth: '100%' }}
        />
      </IFrameWrapper>

      {showError && <Error content={error} />}

    </PageContainer>
  );
};

export default Card;
