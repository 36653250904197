import React from "react";
import { debugEnabled } from "utils/env";
import ui from "store/ui";
import { StyledDiv } from "./style";

type DangerousHTMLContainerType = {
    messageId?: string;
    content?: string;
    className?: string;
    height?: string;
}

const DangerousHTMLContainer: React.FC<DangerousHTMLContainerType> = (props: DangerousHTMLContainerType) => {
    const {
        messageId,
        content,
        className,
        height,
    } = props;

    const html = (messageId ? ui.texts[messageId] : content) || (debugEnabled ? messageId : ""); // Fallback only when we want to debug (dev/test envs)

    return <StyledDiv style={{height}} className={className} dangerouslySetInnerHTML={{ __html: html }} />;
};

export default DangerousHTMLContainer;