import React, { Suspense } from 'react';
import { ConnectedProps } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import { Route, Switch } from 'react-router-dom';

// import Popup from 'legacy/components/Popup';
import EnvBanner from 'legacy/components/EnvBanner';
import {
  GlobalStyles,
  PageContainer,
  ContentContainer,
  Container,
  MainContent,
} from './root.styles';

// TODO: use Logo & Sidebar from components, refactor them to use UI theme instead of order info
import SideBar from 'legacy/ChangeCardPortal/containers/ChangeCard/components/Sidebar';
// import Footer from 'legacy/components/Footer';
import Header from 'legacy/components/Header';
import { ENV_PROD } from 'legacy/constants/index';

import connector from './connect';
import { mobileCheck } from 'legacy/utils/UI';

import ChangeCard from './containers/ChangeCard';

const AUTOCLOSE_TIME = 15000;

type PropsFromRedux = ConnectedProps<typeof connector>;

const Root: React.SFC<PropsFromRedux> = (props: PropsFromRedux) => {
  const { theme } = props;

  const isProduction = process.env.REACT_APP_BUILD_ENVIRONMENT === ENV_PROD;
  // const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'ON' ? true : false;

  return (
    <>
      <Suspense fallback={<div></div>}>
        <GlobalStyles font={theme.font} />
        <ToastContainer
          position={toast.POSITION.TOP_RIGHT}
          autoClose={AUTOCLOSE_TIME}
          className="toaster"
          newestOnTop={false}
          closeOnClick
          hideProgressBar
        />
        {!isProduction && <EnvBanner />}

        {/* <Popup /> */}
        <PageContainer>
          {!mobileCheck() && <SideBar />}
          <ContentContainer>
            <Header />
            <MainContent>
              {/* <Logo /> */}
              <Container>
                <Switch>
                  <Route exact path="/" component={ChangeCard} />
                  <Route exact path="/card/change/t/:token" component={ChangeCard} />
                  <Route component={ChangeCard} />
                </Switch>
              </Container>
            </MainContent>
            {/* <Footer /> */}
          </ContentContainer>
        </PageContainer>
      </Suspense>
    </>
  );
};

export default connector(Root);
