import * as pathName from 'legacy/constants/pathnames';

const unauthorizedFormPages = [
  // pathName.LANDING,
  pathName.OTP_VALIDATION,
  pathName.PIN_CODE_VALIDATION,
  pathName.ON_BOARDING,
  pathName.WELCOME,
  pathName.FAQ,
];

export const showAuthorizedForm = (pathname: string) => {
  return !unauthorizedFormPages.some(pageName => pathname.startsWith(pageName));
};
