import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, Route, RouteProps, RouteComponentProps } from 'react-router-dom';
import { actions } from 'legacy/store/UI';
import { Analytics } from 'legacy/utils/Analytics';
import { AuthService } from 'legacy/utils/AuthService';

const mapDispatchToProps = {
  setCurrentPage: actions.setCurrentPage,
};

const connector = connect(undefined, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface ProtectedRouteProps extends RouteProps {
  component: React.ComponentType<Pick<any, string>>;
}

const ProtectedRoute: React.FC<ProtectedRouteProps & PropsFromRedux> = ({
  component: Component,
  setCurrentPage,
  ...rest
}: (ProtectedRouteProps & PropsFromRedux)) => {
  const isAuthorized = AuthService.getToken();

  useEffect(() => {
    if (isAuthorized) {
      setCurrentPage(`${rest.path}`);
      Analytics.sendEvent(`${rest.path} visited`);
    }
  }, [rest.path]);


  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps) =>
        isAuthorized ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default connector(ProtectedRoute);
