import { fork } from 'redux-saga/effects';
import { requestRecoverPinSaga } from './requestRecoverPinSaga';
import { otpValidationSaga } from './otpValidationSaga';
import { resendOtpSaga } from './resendOtpSaga';
import { cardNumberValidationSaga } from './cardNumberValidationSaga';
import { setPinCodeSaga } from './setPinCodeSaga';

export function* recoverPinSagas() {
  yield fork(requestRecoverPinSaga);
  yield fork(otpValidationSaga);
  yield fork(resendOtpSaga);
  yield fork(cardNumberValidationSaga);
  yield fork(setPinCodeSaga);
}
