import React from 'react';
import { ConnectedProps } from 'react-redux';

import { SideBarStyled, SideBarImage } from './sideBar.styles';
import connector from './connect';

import img1 from 'legacy/assets/sidebar-images/1.jpg';
import img2 from 'legacy/assets/sidebar-images/2.jpg';
import img3 from 'legacy/assets/sidebar-images/3.jpg';
import img4 from 'legacy/assets/sidebar-images/4.jpg';
import img5 from 'legacy/assets/sidebar-images/5.jpg';

type PropsFromRedux = ConnectedProps<typeof connector>;

const SideBar: React.SFC<PropsFromRedux> = (props: PropsFromRedux) => {
  const defaultSidebarImages = [img1, img2, img3, img4, img5];
  const defaultImg = defaultSidebarImages[Math.floor(defaultSidebarImages.length * Math.random())];

  const src = props.order ? props.order?.merchantSidebarImage || defaultImg : '';

  return (
    <SideBarStyled>
      <SideBarImage src={src} alt="side-bar-img" />
    </SideBarStyled>
  );
};

export default connector(SideBar);
