export const countries = {
	Z100: "ALBANIA",
	Z101: "ANDORRA",
	Z102: "AUSTRIA",
	Z103: "BELGIO",
	Z104: "BULGARIA",
	Z105: "CECOSLOVACCHIA",
	Z106: "CITTA' DEL VATICANO",
	Z107: "DANIMARCA",
	Z108: "FAER OER (ISOLE)",
	Z109: "FINLANDIA",
	Z110: "FRANCIA",
	Z111: "GERMANIA REPUBBLICA DEMOCRATICA",
	Z112: "GERMANIA REPUBBLICA FEDERALE",
	Z113: "GIBILTERRA",
	Z114: "GRAN BRETAGNA E IRLANDA DEL NORD",
	Z115: "GRECIA",
	Z116: "IRLANDA = EIRE",
	Z117: "ISLANDA",
	Z118: "IUGOSLAVIA",
	Z119: "LIECHTENSTEIN",
	Z120: "LUSSEMBURGO",
	Z121: "MALTA",
	Z122: "MAN (ISOLA)",
	Z123: "MONACO",
	Z124: "NORMANNE (ISOLE)",
	Z125: "NORVEGIA",
	Z126: "PAESI BASSI",
	Z127: "POLONIA",
	Z128: "PORTOGALLO",
	Z129: "ROMANIA",
	Z130: "SAN MARINO",
	Z131: "SPAGNA",
	Z132: "SVEZIA",
	Z133: "SVIZZERA",
	Z134: "UNGHERIA",
	Z135: "URSS",
	Z254: "GEORGIA",
	Z252: "ARMENIA",
	Z138: "UCRAINA",
	Z139: "BIELORUSSIA",
	Z140: "MOLDAVIA",
	Z253: "AZERBAIGIAN",
	Z144: "ESTONIA",
	Z145: "LETTONIA",
	Z146: "LITUANIA",
	Z148: "MACEDONIA",
	Z149: "CROAZIA",
	Z150: "SLOVENIA",
	Z153: "BOSNIA ED ERZEGOVINA",
	Z154: "RUSSIA",
	Z155: "SLOVACCHIA",
	Z156: "CECA REPUBBLICA",
	Z200: "AFGHANISTAN",
	Z201: "ARABIA MERIDIONALE FEDERAZIONE",
	Z202: "ARABIA MERIDIONALE PROTETTORATO",
	Z203: "ARABIA SAUDITA",
	Z204: "BAHREIN",
	Z205: "BHUTAN",
	Z206: "BIRMANIA",
	Z207: "BRUNEI",
	Z208: "CAMBOGIA",
	Z209: "SRY LANKA O CEYLON",
	Z210: "CINA REPUBBLICA POPOLARE",
	Z211: "CIPRO",
	Z212: "COCOS (ISOLE)",
	Z213: "COREA DEL SUD",
	Z214: "COREA DEL NORD",
	Z215: "EMIRATI ARABI UNITI",
	Z216: "FILIPPINE",
	Z217: "TAIWAN O FORMOSA",
	Z218: "GAZA TERRITORIO DI",
	Z219: "GIAPPONE",
	Z220: "GIORDANIA",
	Z221: "HONG KONG",
	Z222: "INDIA",
	Z223: "INDONESIA",
	Z224: "IRAN",
	Z225: "IRAQ",
	Z226: "ISRAELE",
	Z227: "KUWAIT",
	Z228: "LAOS",
	Z229: "LIBANO",
	Z247: "MALAYSIA",
	Z231: "MACAO",
	Z232: "MALDIVE",
	Z233: "MONGOLIA",
	Z234: "NEPAL",
	Z235: "OMAN",
	Z236: "PAKISTAN",
	Z237: "QATAR",
	Z238: "RYUKYU (ISOLE)",
	Z239: "SIKKIM",
	Z240: "SIRIA",
	Z241: "THAILANDIA",
	Z242: "TIMOR (ISOLA)",
	Z243: "TURCHIA",
	Z244: "VIETNAM DEL SUD",
	Z245: "VIETNAM DEL NORD",
	Z246: "YEMEN",
	Z248: "SINGAPORE",
	Z249: "BANGLADESH",
	Z251: "VIETNAM",
	Z255: "KAZAKISTAN",
	Z256: "KIRGHIZISTAN",
	Z257: "TAGIKISTAN",
	Z258: "TURKMENISTAN",
	Z259: "UZBEKISTAN",
	Z300: "NAMIBIA O AFRICA DEL SUD-OVEST",
	Z301: "ALGERIA",
	Z302: "ANGOLA",
	Z303: "BASUTOLAND-SUD AFRICA BRITANNICO",
	Z304: "BECIUANIA-SUD AFRICA BRITANNICO",
	Z305: "BURUNDI",
	Z306: "CAMERUN",
	Z307: "CAPO VERDE (ISOLE)",
	Z308: "CENTRAFRICANA REPUBBLICA",
	Z309: "CIAD",
	Z310: "COMORE (ISOLE)",
	Z311: "CONGO BRAZZAVILLE",
	Z312: "ZAIRE EX CONGO LEOPOLDVILLE",
	Z313: "COSTA D'AVORIO",
	Z314: "DAHOMEY O BENIN",
	Z315: "ETIOPIA",
	Z316: "GABON",
	Z317: "GAMBIA",
	Z318: "GHANA",
	Z319: "GUINEA",
	Z320: "GUINEA BISSAU",
	Z321: "GUINEA EQUATORIALE",
	Z322: "KENYA",
	Z323: "IFNI",
	Z324: "LA REUNION (ISOLE)",
	Z325: "LIBERIA",
	Z326: "LIBIA",
	Z327: "MADAGASCAR",
	Z328: "MALAWI",
	Z329: "MALI",
	Z330: "MAROCCO",
	Z331: "MAURITANIA",
	Z332: "MAURIZIO",
	Z333: "MOZAMBICO",
	Z334: "NIGER",
	Z335: "NIGERIA",
	Z336: "EGITTO",
	Z337: "ZIMBABWE EX RHODESIA",
	Z338: "RUANDA",
	Z339: "SAHARA SPAGNOLO",
	Z340: "SANT'ELENA ISOLA",
	Z341: "SAO TOME' E PRINCIPE (ISOLE)",
	Z342: "SEICELLE (ISOLE)",
	Z343: "SENEGAL",
	Z344: "SIERRA LEONE",
	Z345: "SOMALIA",
	Z346: "SOMALIA FRANCESE",
	Z347: "SUDAFRICANA REPUBBLICA",
	Z348: "SUDAN",
	Z349: "SWAZILAND",
	Z350: "TANGANICA",
	Z351: "TOGO",
	Z352: "TUNISIA",
	Z353: "UGANDA",
	Z354: "VOLTA O ALTOVOLTA",
	Z355: "ZAMBIA",
	Z356: "ZANZIBAR",
	Z357: "TANZANIA",
	Z358: "BOTSWANA",
	Z359: "LESOTHO",
	Z361: "GIBUTI",
	Z364: "BOPHUTHATSWANA",
	Z367: "CISKEI",
	Z368: "ERITREA",
	Z400: "BERMUDE (ISOLE)",
	Z401: "CANADA",
	Z402: "GROENLANDIA",
	Z403: "SAINT PIERRE ET MIQUELON(ISOLE)",
	Z404: "STATI UNITI D'AMERICA",
	Z500: "ANTILLE BRITANNICHE",
	Z501: "ANTILLE OLANDESI",
	Z502: "BAHAMA",
	Z503: "COSTA RICA",
	Z504: "CUBA",
	Z505: "DOMINICANA REPUBLICA",
	Z506: "EL SALVADOR",
	Z507: "GIAMAICA",
	Z508: "GUADALUPA",
	Z509: "GUATEMALA",
	Z510: "HAITI",
	Z511: "HONDURAS",
	Z512: "BELIZE EX HONDURAS BRITANNICO",
	Z513: "MARTINICA",
	Z514: "MESSICO",
	Z515: "NICARAGUA",
	Z516: "PANAMA",
	Z517: "PANAMA ZONA DEL CANALE",
	Z518: "PUERTO RICO",
	Z519: "TURKS E CAICOS (ISOLE)",
	Z520: "VERGINI AMERICANE (ISOLE)",
	Z522: "BARBADOS",
	Z524: "GRENADA",
	Z527: "SAINT LUCIA",
	Z528: "SAINT VINCENT E GRANADINE",
	Z529: "ANGUILLA (ISOLA)",
	Z530: "CAYMAN (ISOLE)",
	Z531: "MONTSERRAT",
	Z532: "ANTIGUA E BARBUDA",
	Z533: "SAN KITTS E NEVIS",
	Z600: "ARGENTINA",
	Z601: "BOLIVIA",
	Z602: "BRASILE",
	Z603: "CILE",
	Z604: "COLOMBIA",
	Z605: "ECUADOR",
	Z606: "GUAYANA BRITANNICA",
	Z607: "GUAYANA FRANCESE",
	Z608: "SURINAME EX GUAYANA OLANDESE",
	Z609: "MALVINE O FALKLAND (ISOLE)",
	Z610: "PARAGUAY",
	Z611: "PERU'",
	Z612: "TRINIDAD E TOBAGO",
	Z613: "URUGUAY",
	Z614: "VENEZUELA",
	Z700: "AUSTRALIA",
	Z701: "CAROLINE (ISOLE)",
	Z702: "CHRISTMAS (ISOLA)",
	Z703: "COOK (ISOLE)",
	Z704: "FIGI O VITI (ISOLE)",
	Z705: "GILBERT E ELLICE (ISOLE)",
	Z706: "GUAM (ISOLA)",
	Z707: "IRIAN OCCIDENTALE",
	Z708: "MACQUARIE (ISOLE)",
	Z709: "MARCUS (ISOLE)",
	Z710: "MARIANNE (ISOLE)",
	Z711: "MARSHALL (ISOLE)",
	Z712: "MIDWAY (ISOLE)",
	Z713: "NAURU",
	Z714: "NIUE O SAVAGE (ISOLE)",
	Z715: "NORFOLK (ISOLE)",
	Z716: "NUOVA CALEDONIA E DIPENDENZE",
	Z717: "NUOVE EBRIDI CONDOM. FRANCO-INGL.",
	Z718: "NUOVA GUINEA",
	Z719: "NUOVA ZELANDA",
	Z720: "PAPUASIA",
	Z721: "PASQUA (ISOLA)",
	Z722: "PITCARIN E DIPENDENZE",
	Z723: "POLINESIA FRANCESE (ISOLE)",
	Z724: "SALOMONE",
	Z725: "SAMOA AMERICANE (ISOLE)",
	Z726: "SAMOA (ISOLE)",
	Z727: "TOKELAU O ISOLE DELL'UNIONE",
	Z728: "TONGA O ISOLE DEGLI AMICI",
	Z729: "WALLIS E FUTUNA (ISOLE)",
	Z731: "KIRIBATI",
	Z732: "TUVALU",
	Z733: "VANUATU",
	Z734: "PALAU",
	Z735: "MICRONESIA STATI FEDERATI",
	Z800: "DIPENDENZE CANADESI",
	Z801: "DIPENDENZE NORVEGESI (ARTICHE)",
	Z802: "DIPENDENZE RUSSE",
	Z900: "DIPENDENZE AUSTRALIANE",
	Z901: "DIPENDENZE BRITANNICHE",
	Z902: "DIPENDENZE FRANCESI",
	Z903: "DIPENDENZE NEOZELANDESI",
	Z904: "DIPENDEZE NORVEGESI (ANTARTICHE)",
	Z905: "DIPENDEZE STATUNITENSI",
	Z906: "DIPENDENZE SUDAFRICANE",
};
